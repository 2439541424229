/* @flow */

import * as React from "react";

import WithRestfulCollection from "./WithRestfulCollection";

import type { UnsavedFavorite, Favorite } from "./api";
import type { LoginResources } from "./WithUserResourceStore";

export type RenderProps = {
  favorites: Promise<Array<Favorite>>,
  onAddFavorite: (favorite: UnsavedFavorite) => Promise<Object>,
  onRemoveFavorite: (favoriteId: number) => Promise<Favorite>,
  onUpdateFavorite: (favorite: Favorite) => Promise<Favorite>,
  pendingFavorites: Array<UnsavedFavorite>
};

type Props = {
  loginResources: ?LoginResources,
  render: (?RenderProps) => React.Node
};

export default class WithFavorites extends React.Component<Props> {
  render() {
    return (
      <WithRestfulCollection
        initialState={userResources => userResources.favorites}
        loginResources={this.props.loginResources}
        resourceName="favorites"
        render={props => {
          if (props) {
            return this.props.render({
              favorites: props.records,
              onAddFavorite: props.onAddRecord,
              onRemoveFavorite: props.onRemoveRecord,
              onUpdateFavorite: props.onUpdateRecord,
              pendingFavorites: props.pendingRecords
            });
          } else {
            return this.props.render(null);
          }
        }}
      />
    );
  }
}
