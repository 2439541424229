/* @flow */

export const gutter = 30;

const bootstrapGridColumns = 12;

import styled from "styled-components";

export const breakpoints = {
  // Small devices (landscape phones, 576px and up)
  sm: "min-width: 576px",

  // Medium devices (tablets, 768px and up)
  md: "min-width: 768px",

  // Large devices (desktops, 992px and up)
  lg: "min-width: 992px",

  // Extra large devices (large desktops, 1200px and up)
  xl: "min-width: 1200px"
};

export const containerMaxWidths = {
  // Small devices (landscape phones, 576px and up)
  sm: "540px",

  // Medium devices (tablets, 768px and up)
  md: "720px",

  // Large devices (desktops, 992px and up)
  lg: "960px",

  // Extra large devices (large desktops, 1200px and up)
  xl: "1140px"
};

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -${gutter / 2}px;
`;

export const Column = styled.div`
  padding: 0 ${gutter / 2}px;
  box-sizing: border-box;
`;

export const ContainerBreakout = styled.div`
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  padding-left: calc(50vw - 50%);
  padding-right: calc(50vw - 50%);
`;

export const Container = styled.div`
  width: 100%;
  padding-right: ${gutter / 2}px;
  padding-left: ${gutter / 2}px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  ${Object.keys(breakpoints).map(
    breakpoint =>
      `@media (${breakpoints[breakpoint]}) { max-width: ${
        containerMaxWidths[breakpoint]
      }; }`
  )}
`;

export function flexForColumns(columns: number, totalColumns: number): string {
  const percentage = String((columns / totalColumns) * 100) + "%";
  return `flex: 0 0 ${percentage}; max-width: ${percentage};`;
}

function columnBreakpoints(props) {
  return Object.keys(breakpoints)
    .map(breakpoint =>
      breakpoint in props
        ? `@media (${breakpoints[breakpoint]}) { ${flexForColumns(
            props[breakpoint],
            bootstrapGridColumns
          )} }`
        : ""
    )
    .join("\n");
}

export const ColumnSpan = styled(Column)`
  ${props => flexForColumns(props.columns, bootstrapGridColumns)};
  ${columnBreakpoints}
`;

export const FullColumn = styled(Column)`
  ${flexForColumns(12, bootstrapGridColumns)}
`;

export const HalfColumn = styled(Column)`
  ${flexForColumns(6, bootstrapGridColumns)}
`;
