/* @flow */

import * as React from "react";

import { captureException } from "@sentry/browser";

import { getQuizAttempts, postQuizAttempt } from "./apiClient";

import type { QuizAttempt, UnsavedQuizAttempt } from "./api";

import type { LoginResources } from "./WithUserResourceStore";

type Props = {
  loginResources: ?LoginResources,
  render: RenderProps => React.Node
};

export type RenderProps = {
  quizAttempts: Promise<Array<QuizAttempt>>,
  pendingAttempt: ?UnsavedQuizAttempt,
  onAddQuizAttempt: UnsavedQuizAttempt => void
};

export default function WithQuizAttempts(props: Props) {
  const [
    quizAttempts: Promise<Array<QuizAttempt>>,
    setQuizAttempts
  ] = React.useState(() => Promise.resolve([]));

  const [
    pendingAttempt: ?UnsavedQuizAttempt,
    setPendingAttempt
  ] = React.useState(null);

  const withIdToken = props.loginResources
    ? props.loginResources.withIdToken
    : null;
  const userResources = props.loginResources
    ? props.loginResources.userResources
    : null;

  React.useEffect(() => {
    if (userResources) {
      setQuizAttempts(userResources.then(r => r.quizAttempts));
    } else {
      setQuizAttempts(Promise.resolve(getLocalQuizAttempts()));
    }
  }, [userResources]);

  function onAddQuizAttempt(attempt: UnsavedQuizAttempt) {
    if (withIdToken) {
      setPendingAttempt(attempt);

      postQuizAttempt(withIdToken, attempt)
        .then(() => {
          const promise = getQuizAttempts(withIdToken);
          setQuizAttempts(promise);
          return promise;
        })
        .then(
          () => {
            setPendingAttempt(null);
          },
          error => {
            setPendingAttempt(null);
            captureException(error);
          }
        );
    } else {
      setQuizAttempts(Promise.resolve(putLocalQuizAttempts(attempt)));
    }
  }

  return props.render({ quizAttempts, pendingAttempt, onAddQuizAttempt });
}

// This is a last resort for storing QuizAttempts in the event that sessionStorage isn't
// available and the user is not logged in.
let moduleStorage = [];

function getLocalQuizAttempts(): Array<QuizAttempt> {
  try {
    const value = sessionStorage.getItem("quizAttempts");
    if (value == null) {
      return [];
    } else {
      return JSON.parse(value);
    }
  } catch (e) {
    return moduleStorage;
  }
}

function putLocalQuizAttempts(attempt: UnsavedQuizAttempt): Array<QuizAttempt> {
  const oldValue = getLocalQuizAttempts();
  const newValue = oldValue.concat({
    ...attempt,
    id: oldValue.length,
    createdAt: new Date().toISOString(),
    free: true
  });

  try {
    sessionStorage.setItem("quizAttempts", JSON.stringify(newValue));
  } catch (e) {
    moduleStorage = newValue;
  }

  return newValue;
}
