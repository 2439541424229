/* @flow */

import * as React from "react";
import styled from "styled-components";

import { stringsForLocale } from "../../lang/web";
import { NativeLanguageSelect, TargetLanguageSelect } from "../LanguageSelect";
import { spacers } from "../theme";

import type { UserResourceStore } from "../WithUserResourceStore";
import type { SnackbarMessage } from "../useSnackbarQueue";

type Props = {
  nativeLang: string,
  targetLang: ?string,
  onChangeNativeLang: (lang: string) => void,
  onChangeTargetLang: (lang: string) => void,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  userResources: UserResourceStore,
  onAddSnackbarMessage: SnackbarMessage => void
};

export default function LanguageSettingsForm(props: Props) {
  const controlStyles = {
    borderRadius: 100
  };

  const strings = stringsForLocale(props.nativeLang);

  const handleNativeLangChange = (nativeLang: string) => {
    props.onChangeNativeLang(nativeLang);
    props.onAddSnackbarMessage({
      level: "message",
      body: strings.account_page.native_language_update_confirmation()
    });
  };

  const handleTargetLangChange = (targetLang: string) => {
    props.onChangeTargetLang(targetLang);
    props.onAddSnackbarMessage({
      level: "message",
      body: strings.account_page.target_language_update_confirmation()
    });
  };

  return (
    <Section>
      <h2>{strings.account_page.language_settings_header()}</h2>

      <label>{strings.account_page.native_language_header()}</label>
      <SelectWrapper style={{ marginBottom: spacers[5] }}>
        <NativeLanguageSelect
          instanceId="account-page-choose-native-lang"
          controlStyles={controlStyles}
          nativeLang={props.nativeLang}
          youtubeLanguages={props.youtubeLanguages}
          onChangeNativeLang={nativeLang => handleNativeLangChange(nativeLang)}
        />
      </SelectWrapper>

      <label>{strings.account_page.target_language_header()}</label>
      <SelectWrapper>
        <TargetLanguageSelect
          includeFeatured
          instanceId="account-page-choose-target-lang"
          controlStyles={controlStyles}
          nativeLang={props.nativeLang}
          targetLang={props.targetLang}
          youtubeLanguages={props.youtubeLanguages}
          onChange={targetLang => handleTargetLangChange(targetLang)}
        />
      </SelectWrapper>
    </Section>
  );
}

const Section = styled.div`
  label {
    display: block;
    font-weight: 500;
    margin-bottom: ${spacers[3]};
  }
`;

const SelectWrapper = styled.div`
  max-width: 400px;
`;
