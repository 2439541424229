/* @flow strict */

const strings = {
  // $FlowFixMe: yaml not supported
  ko: require('./ko'),
  // $FlowFixMe: yaml not supported
  en: require('./en'),
  // $FlowFixMe: yaml not supported
  es: require('./es'),
  // $FlowFixMe: yaml not supported
  ja: require('./ja'),
  // $FlowFixMe: yaml not supported
  fr: require('./fr'),

  // This is chinese simplified. We might want to do traditional as well
  // to support places like Hong Kong and Taiwan.
  // $FlowFixMe: yaml not supported
  zh: require('./zh')
};

const availableLocales/*: Array<string>*/ = Object.keys(strings);

/*::
import type { LocaleStringTypes } from "./LocaleStringTypes";
*/

function stringsForLocale(locale/*: string*/)/*: LocaleStringTypes */ {
  const lang = locale.split("-")[0];
  if (lang in strings) {
    return strings[lang];
  } else {
    return strings.en ;
  }
}

module.exports = { stringsForLocale, availableLocales };
