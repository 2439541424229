/* @flow */

import * as React from "react";
import styled from "styled-components";

import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";

// $FlowFixMe - markdown not supported by flow
import Tos from "./tos.md";
// $FlowFixMe - markdown not supported by flow
import PrivacyPolicy from "./privacy_policy.md";

import type { UserResourceStore } from "./WithUserResourceStore";

import * as geometry from "./geometry";

type Props = {
  onLogin: Function,
  onLogout: Function,
  isLoggedIn: boolean,
  nativeLang: string,
  targetLang: ?string,
  userResources: ?UserResourceStore
};

function LegalesePage(props: Props & { children: React.Node }) {
  return (
    <React.Fragment>
      <PageHeader
        onLogin={props.onLogin}
        onLogout={props.onLogout}
        nativeLang={props.nativeLang}
        targetLang={props.targetLang}
        isLoggedIn={props.isLoggedIn}
        userResources={props.userResources}
      />
      <PageContent>{props.children}</PageContent>
      <PageFooter nativeLang={props.nativeLang} />
    </React.Fragment>
  );
}

const PageContent = styled.div`
  padding: ${geometry.pageHeaderHeight}px 10px 0 10px;
  line-height: 1.5;
  margin: 0 auto;
  max-width: ${geometry.videoWidth}px;
`;

export function TermsOfServicePage(props: Props) {
  return (
    <LegalesePage {...props}>
      <Tos />
    </LegalesePage>
  );
}

export function PrivacyPolicyPage(props: Props) {
  return (
    <LegalesePage {...props}>
      <PrivacyPolicy />
    </LegalesePage>
  );
}
