/* @flow */

export default function fetchXML(url: string): Promise<Document> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      if (xhr.status == 200) {
        if (xhr.responseXML == null) {
          reject(new XMLHttpRequestParseError("responseXML is null", url));
        } else {
          const xml = xhr.responseXML;
          if (typeof xml.getElementsByTagName === "function") {
            resolve(xml);
          } else {
            // I've been seeing this error pop-up on Sentry and not sure why.
            // https://sentry.io/organizations/jonathan-baudanza/issues/1600939762/?project=1177889
            // https://sentry.io/organizations/jonathan-baudanza/issues/1653617231/?project=1177889
            reject(
              new XMLHttpRequestParseError(
                `missing getElementsByTagName on node ${xml.nodeName} (${
                  xml.nodeType
                })`,
                url
              )
            );
          }
        }
      } else {
        reject(new XMLHttpRequestStatusError(url, xhr.status));
      }
    };

    xhr.onerror = event => {
      // Some sort of network error occurred. There doesn't seem to be any more
      // information we can get about this error. There is no standard error
      // event, and Chrome just passes a ProgressEvent that doesn't contain
      // any helpful information.
      reject(new XMLHttpRequestNetworkError(url, event));
    };

    xhr.open("GET", url);
    xhr.send();
  });
}

class XMLHttpRequestParseError extends Error {
  constructor(message, url) {
    super(message);
    this.url = url;
    this.name = "XMLHttpRequestParseError";
  }
  url: string;
}

class XMLHttpRequestNetworkError extends Error {
  constructor(url, event) {
    super(`${url} failed with a network error`);
    this.url = url;
    this.event = event;
    this.name = "XMLHttpRequestNetworkError";
  }
  event: Event;
  url: string;
}

class XMLHttpRequestStatusError extends Error {
  constructor(url, status) {
    super(`${url} completed with status ${status}`);
    this.url = url;
    this.status = status;
    this.name = "XMLHttpRequestStatusError";
  }
  status: number;
  url: string;
}
