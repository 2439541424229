/* @flow */

import * as React from "react";
import { colors } from "./theme";

const radius = 45;
const circumference = 2 * Math.PI * radius;

type Props = {
  progress: number,
  strokeWidth?: number,
  text?: string,
  size: number,
  className?: string,
  fill?: string
};

export function ProgressCircle(props: Props) {
  const strokeWidth = props.strokeWidth == null ? 10 : props.strokeWidth;
  const strokeDashoffset = circumference * (1 - props.progress);

  return (
    <svg
      className={props.className}
      viewBox="0 0 100 100"
      width={props.size}
      height={props.size}
    >
      <circle
        cx="50"
        cy="50"
        r={radius}
        pathLength="10"
        fill="none"
        stroke="#ddd"
        strokeWidth={strokeWidth}
      />
      <circle
        cx="50"
        cy="50"
        transform="rotate(-90 50 50)"
        r={radius}
        fill={props.fill == null ? "none" : props.fill}
        stroke={colors.highlight}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
      />
      {props.text == null ? null : (
        <text
          x="50"
          y="50"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={String((100 / props.size) * 100) + "%"}
        >
          {props.text}
        </text>
      )}
    </svg>
  );
}

type CanvasProps = {
  progress: number,
  size: number
};

export function renderProgressCircle(
  ctx: CanvasRenderingContext2D,
  size: number,
  progress: number
) {
  const center = size / 2;

  ctx.fillStyle = "#fff";
  ctx.fillRect(0, 0, size, size);

  const radius = size * 0.45;

  const startAngle = Math.PI * 1.5;
  const progressInRadians = progress * Math.PI * 2;

  ctx.beginPath();
  ctx.arc(center, center, radius, 0, Math.PI * 2);
  ctx.strokeStyle = "#ddd";
  ctx.lineWidth = 1;
  ctx.stroke();

  ctx.beginPath();
  ctx.strokeStyle = colors.highlight;
  ctx.lineWidth = 2;
  ctx.arc(center, center, radius, startAngle, startAngle + progressInRadians);
  ctx.stroke();
}

export function CanvasProgressCircle(props: CanvasProps) {
  const canvasRef: { current: ?HTMLCanvasElement } = React.useRef();

  React.useEffect(() => {
    if (canvasRef.current == null) return;

    const ctx = canvasRef.current.getContext("2d");
    renderProgressCircle(ctx, props.size, props.progress);
  }, [props.progress, props.size]);

  return <canvas width={props.size} height={props.size} ref={canvasRef} />;
}
