/* @flow */

import * as React from "react";
import styled from "styled-components";

import featuredPlaylists from "./featuredPlaylists";
import featuredChannelLanguages from "./featuredChannelLanguages";
import { stringsForLocale } from "../lang/web";

import chromeSvg from "../assets/chrome.svg";

import {
  Row,
  ColumnSpan,
  Column,
  Container,
  HalfColumn,
  FullColumn,
  ContainerBreakout,
  gutter,
  breakpoints,
  flexForColumns
} from "./BootstrapGrid";

import { spacers, colors, typography, fontFamily, spacingCss } from "./theme";

import FilterByCitySelect from "./FilterByCitySelect";
import { matchLanguage, normalizeLanguage } from "./matchLanguage";
import { getRecommendations } from "./apiClient";
import { usePromiseValue } from "./useFlatPromise";
import { useIsPremiumPromise } from "./WithUserResourceStore";
import { thumbnailsForVideo, languageNamesFromResponse } from "./youtubeUtils";
import {
  getChannelSnippets,
  getSearchWithDetails
} from "./YoutubeScraperClient";
import LoadingTextPlaceholder, {
  ThumbnailPlaceholder,
  GreyRectangle
} from "./LoadingTextPlaceholder";
import { buttonStyles, buttonBackgroundColorWithHover } from "./Buttons";
import PageHeader from "./PageHeader";
import { CenteredSearchForm } from "./SearchPage";
import WithSearchForm from "./WithSearchForm";
import SearchField from "./SearchField";
import LinkWithState from "./LinkWithState";
import allPopularSearches from "./popularSearches.json";
import decodeIdToken from "./decodeIdToken";
import parseSearchString from "./parseSearchString";

import ToggleButton from "./ToggleButton";

import { urlForSearch, urlForVideo } from "./urlForRoute";

import {
  groupBy,
  sortBy,
  forEach,
  debounce,
  flatten,
  times,
  unescape,
  difference
} from "lodash";
import { searchPropsForUrl, channelIdFromUrl } from "./youtubeUtils";

import { Television } from "./SvgAssets";

type Props = {
  location: DocumentLocation,
  onLogin: Function,
  onLogout: Function,
  isLoggedIn: boolean,
  onNavigate: string => void,
  onReplaceLocation: (
    href: string,
    state: ?Object,
    currentPathname: string
  ) => void,
  onAddSnackbarMessage: SnackbarMessage => void,
  targetLang: string,
  nativeLang: string,
  searchProps: SearchProps,
  onChangeNativeLang: (lang: string) => void,
  onChangeTargetLang: (lang: string) => void,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  userResources: ?UserResourceStore,
  isInitialized: boolean,
  idToken: string | null,
  withIdToken: ?() => Promise<string>
};

import type { RecommendationList, Recommendation } from "./recommendations";
import type {
  YouTubeSnippet,
  YouTubeChannelWithDetails,
  YouTubePlaylistWithDetails
} from "./youtubeScraper";

import type { UserResourceStore } from "./WithUserResourceStore";
import type { SearchProps } from "./WithSearchResults";
import type { SnackbarMessage } from "./useSnackbarQueue";
import type { DocumentLocation } from "./useNavigation";

const PageContent = styled.div`
  margin: 0 auto;
  margin-top: 80px;

  h1 {
    ${typography.h1}
  }
  h2 {
    ${typography.h2}
  }
  h3 {
    ${typography.h3}
  }
  h4 {
    ${typography.h4}
  }
  h5 {
    ${typography.h5}
  }
  h6 {
    ${typography.h6}
  }

  ${typography.body1}

  input {
    fontfamily: ${fontFamily};
  }

  a {
    color: ${colors.textPrimary};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Spacing = styled.div`
  ${props => spacingCss(props)}
`;

const Group = styled(Spacing)`
  background-color: white;
  border: 1px solid ${colors.divider1};
  margin-bottom: ${spacers[4]};
`;

const GroupHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${spacers[2]} ${spacers[3]};

  height: 50px;

  h5 {
    flex: 1;
    margin: 0;
    line-height: 50px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  *:first-child:not(h5) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: ${spacers[3]};
    flex: 0 0 50px;
    width: 50px;
    box-sizing: border-box;
  }
`;

const LanguageToggleButton = styled(ToggleButton)`
  ${spacingCss({ mr: 2 })}
  ${typography.button}
`;

const ChannelLink = styled.a`
  flex-basis: 0 0 20%;
  text-align: right;
`;

const ChannelImageWrapper = styled(GreyRectangle).attrs({ aspectRatio: 1 })`
  border-radius: 100px;
  overflow: hidden;
`;

const ChannelCard = styled.div`
  text-align: center;
  margin-bottom: 1em;
  padding: 0.5em;
  overflow: hidden;

  a {
    color: #333;
    text-decoration: none;
    text-overflow: ellipsis;
    &:hover {
      text-decoration: underline;
    }
  }
  ${ChannelImageWrapper} {
    margin-bottom: 0.5em;
  }
`;

const ShowWhenScrolling = styled.div`
  position: relative;
  transition: top 150ms ease-in;
  top: ${props => (props.top ? "60px" : "0px")};
`;
const ShowOnTop = styled.div`
  position: relative;
  transition: top 150ms ease-in;
  top: ${props => (props.top ? "-60px" : "-120px")};
`;

type RecommendationGroups = {
  fullChannels: Array<Array<Recommendation>>,
  halfChannels: Array<Array<Recommendation>>,
  individualVideos: Array<Recommendation>
};

export default function RecommendationsPage(props: Props) {
  const [
    promise,
    setPromise
  ] = React.useState<Promise<RecommendationList> | null>(null);
  const result = usePromiseValue(promise);

  const targetLang = props.targetLang;
  const params = parseSearchString(props.location.search);
  let userId;
  if (params.userId) {
    userId = params.userId;
  } else if (props.idToken != null) {
    userId = decodeIdToken(props.idToken).sub;
  } else {
    userId = null;
  }

  const isPremiumPromise = useIsPremiumPromise(props.userResources);
  const isPremium = usePromiseValue(isPremiumPromise) || false;

  React.useEffect(() => {
    if (props.isInitialized) {
      setPromise(
        getRecommendations(
          props.withIdToken,
          props.nativeLang,
          targetLang,
          userId
        )
      );
    }
  }, [
    props.withIdToken,
    props.isInitialized,
    props.nativeLang,
    targetLang,
    userId
  ]);

  let initialPage;
  if (
    props.location.state == null ||
    typeof props.location.state.page !== "number"
  ) {
    initialPage = 1;
  } else {
    initialPage = props.location.state.page;
  }
  const [page, setPage] = React.useState(initialPage);
  const [onTop, setOnTop] = React.useState(true);

  React.useEffect(() => {
    const onBottom = debounce(
      () => {
        setPage(page => page + 1);
      },
      250,
      { leading: true }
    );

    function listener(event: Event) {
      if (
        document.body != null &&
        window.innerHeight + window.pageYOffset >= document.body.offsetHeight
      ) {
        onBottom();
      }
      setOnTop(window.pageYOffset < 100);
    }

    document.addEventListener("scroll", listener);

    return () => {
      document.removeEventListener("scroll", listener);
      onBottom.cancel();
    };
  }, []);

  const currentLocation =
    props.location.pathname + props.location.search + props.location.hash;
  const currentPathname = props.location.pathname;
  const { onReplaceLocation } = props;
  const replaceLocationState = React.useCallback(
    state => {
      onReplaceLocation(currentLocation, state, currentPathname);
    },
    [currentLocation, currentPathname, onReplaceLocation]
  );

  React.useEffect(() => {
    if (page !== initialPage) {
      replaceLocationState({ page });
    }
  }, [page, initialPage, replaceLocationState]);

  const languageNames = languageNamesFromResponse(props.youtubeLanguages);

  const nativeLanguageName = languageNames[props.nativeLang];
  const targetLanguageName = languageNames[targetLang];

  const recommendationGroups = React.useMemo<RecommendationGroups | null>(() => {
    if (result == null) return null;
    else return groupRecommendations(result);
  }, [result]);

  let popularSearches;
  if (props.targetLang && props.targetLang in allPopularSearches) {
    popularSearches = flatten(
      allPopularSearches[props.targetLang].map(item => item.searches)
    );
  }

  const strings = stringsForLocale(props.nativeLang);

  return (
    <React.Fragment>
      <PageHeader
        onLogin={props.onLogin}
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        nativeLang={props.nativeLang}
        targetLang={targetLang}
        userResources={props.userResources}
        hidePremiumButton={true}
      >
        <ShowWhenScrolling top={onTop}>
          <CenteredSearchForm
            onNavigate={props.onNavigate}
            nativeLang={props.nativeLang}
            targetLang={targetLang}
            languageNames={languageNames}
            defaultValue=""
          />
        </ShowWhenScrolling>
        <ShowOnTop top={onTop}>
          {strings.search_page.page_title({ TARGET_LANG: targetLanguageName })}
        </ShowOnTop>
      </PageHeader>
      <PageContent>
        {/*
        <Row>
          <FullColumn>
            <Group padding={3}>
              <h2>Preview feature</h2>
              <p>
                This page is a demo of a new video recommendation system for
                CaptionPop. It isn't live yet. Right now I'm just sharing this
                link with friends.
              </p>
              <p>
                It contains some placeholder buttons that don't work yet, and
                some information that is only used for debugging. It also
                probably has some bugs.
              </p>
              <p>User ID: {userId || "logged-out"}</p>
            </Group>
          </FullColumn>
        </Row>
      */}

        {/*
        <VideoGridContainer>
          {times(4, row => (
          <Row css={spacingCss({mb: 4})}>
            <VideoGridColumnSpan columns={1}><GreyRectangle css={{backgroundColor: '#eeaaaa'}}aspectRatio={16 / 9} /></VideoGridColumnSpan>
            <VideoGridColumnSpan columns={1}><GreyRectangle css={{backgroundColor: '#eeaaaa'}}aspectRatio={16 / 9} /></VideoGridColumnSpan>
            <VideoGridColumnSpan columns={1}><GreyRectangle css={{backgroundColor: '#eeaaaa'}}aspectRatio={16 / 9} /></VideoGridColumnSpan>
            <VideoGridColumnSpan columns={1}><GreyRectangle css={{backgroundColor: '#eeaaaa'}}aspectRatio={16 / 9} /></VideoGridColumnSpan>
            <VideoGridColumnSpan columns={1}><GreyRectangle css={{backgroundColor: '#eeaaaa'}}aspectRatio={16 / 9} /></VideoGridColumnSpan>
          </Row>
          ))}

        </VideoGridContainer>
      */}
        <Container>
          <WithSearchForm
            nativeLang={props.nativeLang}
            targetLang={props.targetLang}
            onNavigate={props.onNavigate}
            defaultValue={""}
            render={formProps => (
              <Row>
                <FullColumn>
                  <Group padding={3}>
                    <Spacing mb={2}>
                      <form onSubmit={formProps.onSubmit} role="search">
                        <SearchField
                          style={{ maxWidth: "none" }}
                          onNavigate={props.onNavigate}
                          nativeLang={props.nativeLang}
                          onChange={formProps.onChangeSearchString}
                          value={formProps.values.search}
                          disabled={formProps.disabled}
                          targetLanguageName={targetLanguageName}
                        />

                        {popularSearches && (
                          <ExampleSearches
                            nativeLang={props.nativeLang}
                            targetLang={targetLang}
                            links={popularSearches}
                          />
                        )}
                      </form>
                    </Spacing>
                    <Row>
                      <SearchFormHalfColumn>
                        <Spacing mb={2} css={typography.body2}>
                          {strings.search_form.filter_by_subtitle_header()}
                        </Spacing>
                        <div>
                          <LanguageToggleButton
                            label={nativeLanguageName}
                            on={
                              formProps.values.filterState === "both" ||
                              formProps.values.filterState === "native"
                            }
                            onChange={formProps.onToggleNative}
                          />
                          <LanguageToggleButton
                            label={targetLanguageName}
                            on={
                              formProps.values.filterState === "both" ||
                              formProps.values.filterState === "target"
                            }
                            onChange={formProps.onToggleTarget}
                          />
                          <a href="#choose-languages">
                            {strings.search_form.change_language_link()}
                          </a>
                        </div>
                      </SearchFormHalfColumn>

                      <SearchFormHalfColumn>
                        <Spacing mb={2} css={typography.body2}>
                          {strings.search_form.filter_by_city_header()}
                        </Spacing>
                        <FilterByCitySelect
                          nativeLang={props.nativeLang}
                          targetLang={targetLang}
                          //value={formProps.values.cityId}
                          onChange={formProps.onChangeCityId}
                        />
                      </SearchFormHalfColumn>
                    </Row>
                  </Group>
                </FullColumn>
              </Row>
            )}
          />
        </Container>

        {recommendationGroups == null ? (
          <LoadingView page={page} />
        ) : (
          <RecommendationsView
            {...props}
            targetLang={targetLang}
            recommendationGroups={recommendationGroups}
            languageNames={languageNames}
            page={page}
            isPremium={isPremium}
          />
        )}
      </PageContent>
    </React.Fragment>
  );
}

const SearchFormHalfColumn = styled(Column)`
  ${flexForColumns(12, 12)};
  &:first-child {
    margin-bottom: ${spacers[2]};
  }

  @media (${breakpoints.md}) {
    ${flexForColumns(6, 12)};
    margin-bottom: 0;
  }
`;

function groupRecommendations(list: RecommendationList): RecommendationGroups {
  const fullChannels: Array<Array<Recommendation>> = [];
  const halfChannels: Array<Array<Recommendation>> = [];
  const individualVideos: Array<Recommendation> = [];

  const sorted = sortBy(list, "weight").reverse();

  const groupedByChannnel = groupBy(sorted, recommendation =>
    channelIdFromUrl(recommendation.video.channel.href)
  );

  forEach(groupedByChannnel, recommendations => {
    if (recommendations.length === 1) {
      individualVideos.push(recommendations[0]);
    } else if (recommendations.length == 2) {
      halfChannels.push(recommendations);
    } else {
      fullChannels.push(recommendations.slice(0, 4));
    }
  });

  return { fullChannels, halfChannels, individualVideos };
}

function partitionRecommendations(groups: RecommendationGroups) {
  const personalRecs: RecommendationGroups = {
    fullChannels: [],
    halfChannels: [],
    individualVideos: []
  };

  const globalRecs: RecommendationGroups = {
    fullChannels: [],
    halfChannels: [],
    individualVideos: []
  };

  groups.fullChannels.forEach(recs => {
    if (recs.findIndex(rec => rec.neighborhood) === -1) {
      globalRecs.fullChannels.push(recs);
    } else {
      personalRecs.fullChannels.push(recs);
    }
  });

  groups.halfChannels.forEach(recs => {
    if (recs.findIndex(rec => rec.neighborhood) === -1) {
      globalRecs.halfChannels.push(recs);
    } else {
      personalRecs.halfChannels.push(recs);
    }
  });

  groups.individualVideos.forEach(rec => {
    if (rec.neighborhood) {
      personalRecs.individualVideos.push(rec);
    } else {
      globalRecs.individualVideos.push(rec);
    }
  });

  return [personalRecs, globalRecs];
}

function filterNull<T>(list: Array<T>): Array<$NonMaybeType<T>> {
  return list.filter(i => i != null);
}

function RecommendationsView(props: {
  recommendationGroups: RecommendationGroups,
  location: DocumentLocation,
  onLogin: Function,
  onLogout: Function,
  isLoggedIn: boolean,
  onNavigate: string => void,
  onReplaceLocation: (
    href: string,
    state: ?Object,
    currentPathname: string
  ) => void,
  onAddSnackbarMessage: SnackbarMessage => void,
  targetLang: string,
  nativeLang: string,
  searchProps: SearchProps,
  onChangeNativeLang: (lang: string) => void,
  onChangeTargetLang: (lang: string) => void,
  languageNames: { [string]: string },
  userResources: ?UserResourceStore,
  page: number,
  isPremium: boolean
}) {
  const strings = stringsForLocale(props.nativeLang);

  const langs = { nativeLang: props.nativeLang, targetLang: props.targetLang };
  const playlistIds = featuredItemsIdsForLanguagePair(langs, featuredPlaylists);
  const curatedChannelIds = featuredItemsIdsForLanguagePair(
    langs,
    featuredChannelLanguages
  );

  const [isExtensionInstalled, setExtentionInstalled] = React.useState(false);
  React.useEffect(() => {
    setExtentionInstalled(
      document.getElementById("captionpop-browser-extension-info") != null
    );
  }, []);

  const sections = buildSections(
    props.recommendationGroups,
    props.page,
    playlistIds,
    curatedChannelIds,
    props.isPremium,
    isExtensionInstalled
  );

  const channelIds = channelIdsFromSections(sections);

  const channelSnippets = useFetchBatch(channelIds, ids => {
    return getChannelSnippets(ids, true /* cache */);
  });

  const playlists = useFetchBatch(
    playlistIdsFromSections(sections),
    getPlaylistsWithDetails
  );

  const curatedChannelPreviewIds = filterNull(
    sections.map(s => (s.type === "curatedChannelPreview" ? s.channelId : null))
  );

  const curatedChannelPreviews = useFetchBatch(
    curatedChannelPreviewIds,
    getChannelsWithDetails
  );

  const targetLanguageName = props.languageNames[props.targetLang];

  const sectionElements = sections.map((section, i) => {
    switch (section.type) {
      case "end":
        return (
          <FullColumn key={`end-marker-${i}`}>
            <EndMarker />
          </FullColumn>
        );
      case "fullChannel":
        return (
          <FullChannel
            channelSnippets={channelSnippets}
            recommendations={section.recommendations}
            key={`full-channel-${i}`}
            nativeLang={props.nativeLang}
            targetLang={props.targetLang}
          />
        );
      case "halfChannel":
        return (
          <HalfChannel
            channelSnippets={channelSnippets}
            recommendations={section.recommendations}
            key={`half-channel-${i}`}
            nativeLang={props.nativeLang}
            targetLang={props.targetLang}
          />
        );
      case "playlist":
        return (
          <FullColumn key={"playlist-" + i}>
            {playlists[section.playlistId] ? (
              <Group>
                <GroupHeader>
                  <h5>{playlists[section.playlistId].details.snippet.title}</h5>
                  <a
                    href={urlForSearch(
                      { playlistId: section.playlistId },
                      props.nativeLang,
                      props.targetLang
                    )}
                  >
                    {strings.search_page.more_action()}
                  </a>
                </GroupHeader>
                <Row>
                  {playlists[section.playlistId].search.items
                    .slice(0, 4)
                    .map((video, j) => (
                      <ColumnSpan columns={6} lg={3} key={`playlist-item-${j}`}>
                        <Video
                          video={video}
                          large={false}
                          nativeLang={props.nativeLang}
                          targetLang={props.targetLang}
                        />
                      </ColumnSpan>
                    ))}
                </Row>
              </Group>
            ) : (
              <VideoGroupPlaceholder />
            )}
          </FullColumn>
        );
      case "individualVideos":
        return (
          <FullColumn key={`individualVideos-${i}`}>
            <Group>
              <Row>
                {section.recommendations.map((recommendation, i) => (
                  <ColumnSpan
                    columns={6}
                    lg={3}
                    key={recommendation.video.videoId + "-" + i}
                  >
                    <Video
                      large={false}
                      video={recommendation.video}
                      components={recommendation.components}
                      showChannel
                      nativeLang={props.nativeLang}
                      targetLang={props.targetLang}
                    />
                  </ColumnSpan>
                ))}
              </Row>
            </Group>
          </FullColumn>
        );
      case "curatedChannelPreview": {
        const channel = curatedChannelPreviews[section.channelId];
        // TODO: This channelSnippets thing is a hack. Just pass in the channelSnippet directly.
        return (
          <FullColumn key={i}>
            {channel == null ? (
              <VideoGroupPlaceholder />
            ) : (
              <Group>
                <ChannelHeader
                  nativeLang={props.nativeLang}
                  targetLang={props.targetLang}
                  channelSnippets={{
                    [section.channelId]: channel.details.snippet
                  }}
                  videos={channel.search.items}
                />
                <Row>
                  {channel.search.items.slice(0, 4).map((video, i) => (
                    <ColumnSpan
                      columns={6}
                      lg={3}
                      key={video.videoId + "-" + i}
                    >
                      <Video
                        large={false}
                        video={video}
                        nativeLang={props.nativeLang}
                        targetLang={props.targetLang}
                      />
                    </ColumnSpan>
                  ))}
                </Row>
              </Group>
            )}
          </FullColumn>
        );
      }
      case "curatedChannelPanel":
        return (
          <FullColumn key={i}>
            <Group>
              <GroupHeader>
                <h5>
                  {strings.search_form.featured_channels_header({
                    LANGUAGE: targetLanguageName
                  })}
                </h5>
              </GroupHeader>

              <Row>
                {section.channelIds.map((channelId, i) => {
                  const channelSnippet = channelSnippets[channelId];
                  const href = urlForSearch(
                    { channelId },
                    props.nativeLang,
                    props.targetLang
                  );

                  return (
                    <ColumnSpan columns={6} sm={4} md={3} lg={2} key={i}>
                      <ChannelCard>
                        <a href={href}>
                          <ChannelImage
                            size="large"
                            channelSnippet={channelSnippet}
                          />
                        </a>
                        <a href={href}>
                          {channelSnippet == null ? "" : channelSnippet.title}
                        </a>
                      </ChannelCard>
                    </ColumnSpan>
                  );
                })}
              </Row>
            </Group>
          </FullColumn>
        );

      case "tutorial":
        return (
          <TutorialRow
            key={`tutorial-row-${i}`}
            row="tutorials"
            nativeLang={props.nativeLang}
            targetLang={props.targetLang}
          />
        );
      case "browser-extension-cia":
        return (
          <BrowserExtensionCallToActionRow
            key={`browser-extension-cia-${i}`}
            nativeLang={props.nativeLang}
          />
        );
      case "premium-cia":
        return (
          <CallToActionRow key={`cia-${i}`} nativeLang={props.nativeLang} />
        );
    }
  });

  return (
    <Container>
      <Row>{sectionElements}</Row>
    </Container>
  );
}

const EndMarker = styled.div`
  height: 3px;
  border-radius: 10px;
  background-color: ${colors["black-60"]};
  max-width: 30%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${spacers[3]};
`;

type PageSection =
  | {
      type: "end"
    }
  | {
      type: "playlist",
      playlistId: string
    }
  | {
      type: "curatedChannelPreview",
      channelId: string
    }
  | {
      type: "curatedChannelPanel",
      channelIds: Array<string>
    }
  | {
      type: "fullChannel",
      recommendations: Array<Recommendation>
    }
  | {
      type: "halfChannel",
      recommendations: Array<Recommendation>
    }
  | {
      type: "individualVideos",
      recommendations: Array<Recommendation>
    }
  | {
      type: "tutorial"
    }
  | {
      type: "premium-cia"
    }
  | {
      type: "browser-extension-cia"
    };

function channelIdsFromRecommendations(
  groups: RecommendationGroups
): Array<string> {
  const channelIds: Array<string> = [];

  function grabChannelId(recs) {
    const channelId = channelIdFromUrl(recs[0].video.channel.href);
    if (channelId != null) {
      channelIds.push(channelId);
    }
  }

  groups.fullChannels.forEach(grabChannelId);
  groups.halfChannels.forEach(grabChannelId);

  return channelIds;
}

function buildSections(
  allRecs: RecommendationGroups,
  numPages: number,
  playlistIds: Array<string>,
  unfilteredChannelIds: Array<string>,
  isPremium: boolean,
  isExtensionInstalled: boolean
): Array<PageSection> {
  const callsToAction: Array<PageSection> = [{ type: "tutorial" }];
  if (!isPremium) {
    callsToAction.push({ type: "premium-cia" });
  }
  if ("chrome" in window && !isExtensionInstalled) {
    callsToAction.push({ type: "browser-extension-cia" });
  }

  const [personalRecs, globalRecs] = partitionRecommendations(allRecs);

  const channelIds = difference(
    unfilteredChannelIds,
    channelIdsFromRecommendations(allRecs)
  );

  const sections: Array<PageSection> = buildPersonalRecommendations(
    personalRecs
  );

  let i = 0; // index into fullChannels
  let j = 0; // index into halfChannels
  let k = 0; // index into individualVideos
  let l = 0; // index into playlistIds
  let m = 0; // index into channelIds

  let finished = false;

  for (let page = 0; page < numPages; page++) {
    let rowTypeIndex = 0;
    let rowsAdded = 0;

    if (finished) break;

    while (rowsAdded < 4) {
      if (
        i >= globalRecs.fullChannels.length &&
        j >= globalRecs.halfChannels.length &&
        k >= globalRecs.individualVideos.length &&
        l >= playlistIds.length &&
        m >= channelIds.length
      ) {
        finished = true;
        break;
      }

      switch (rowTypeIndex) {
        case 0: // fullChannels
          if (i < globalRecs.fullChannels.length) {
            const recommendations = globalRecs.fullChannels[i++];
            sections.push({
              type: "fullChannel",
              recommendations: recommendations
            });
            rowsAdded += 1;
          }
          break;
        case 1: // halfChannels
          if (j < globalRecs.halfChannels.length) {
            const recommendations = globalRecs.halfChannels[j++];

            sections.push({
              type: "halfChannel",
              recommendations: recommendations
            });

            if (j < globalRecs.halfChannels.length) {
              const recommendations = globalRecs.halfChannels[j++];
              sections.push({
                type: "halfChannel",
                recommendations: recommendations
              });
            }
            rowsAdded += 1;
          }
          break;
        case 2: // curated playlists or channels
          if (l < playlistIds.length) {
            const playlistId = playlistIds[l++];
            sections.push({
              type: "playlist",
              playlistId: playlistId
            });
            rowsAdded += 1;
          } else if (m < channelIds.length) {
            const channelId = channelIds[m++];
            sections.push({
              type: "curatedChannelPreview",
              channelId: channelId
            });
            rowsAdded += 1;
          }
          break;
        case 3:
          // individual videos or currated channel panel
          if (m < channelIds.length - 5) {
            const slice = channelIds.slice(m, m + 12);
            m += slice.length;
            sections.push({
              type: "curatedChannelPanel",
              channelIds: slice
            });
            rowsAdded += Math.floor(slice.length / 6);
          } else if (k < globalRecs.individualVideos.length) {
            const recommendations = globalRecs.individualVideos.slice(k, k + 8);

            k += 8;
            sections.push({
              type: "individualVideos",
              recommendations: recommendations
            });
            rowsAdded += Math.floor(recommendations.length / 4);
          }
          break;
      }

      rowTypeIndex = (rowTypeIndex + 1) % 4;
    }

    if (page % 3 === 0 && callsToAction.length > 0) {
      sections.push(callsToAction.shift());
    }
  }

  return sections;
}

function buildPersonalRecommendations(
  groups: RecommendationGroups
): Array<PageSection> {
  const sections: Array<PageSection> = [];
  let i = 0; // index into fullChannels
  let j = 0; // index into halfChannels
  let k = 0; // index into individualVideos

  let rowTypeIndex = 0;

  while (
    i < groups.fullChannels.length &&
    j < groups.halfChannels.length &&
    k < groups.individualVideos.length
  ) {
    switch (rowTypeIndex) {
      case 0: // fullChannels
        if (i < groups.fullChannels.length) {
          const recommendations = groups.fullChannels[i++];
          sections.push({
            type: "fullChannel",
            recommendations: recommendations
          });
        }
        break;
      case 1: // halfChannels
        if (j < groups.halfChannels.length) {
          const recommendations = groups.halfChannels[j++];

          sections.push({
            type: "halfChannel",
            recommendations: recommendations
          });

          if (j < groups.halfChannels.length) {
            const recommendations = groups.halfChannels[j++];
            sections.push({
              type: "halfChannel",
              recommendations: recommendations
            });
          }
        }
        break;
      case 2: // individualVideos
        if (k < groups.individualVideos.length) {
          const recommendations = groups.individualVideos.slice(k, k + 8);

          k += 8;
          sections.push({
            type: "individualVideos",
            recommendations: recommendations
          });
        }
        break;
    }

    rowTypeIndex = (rowTypeIndex + 1) % 3;
  }

  return sections;
}

function playlistIdsFromSections(sections: Array<PageSection>): Array<string> {
  const list = [];
  sections.forEach(section => {
    if (section.type === "playlist") {
      list.push(section.playlistId);
    }
  });
  return list;
}

function channelIdsFromSections(sections: Array<PageSection>): Array<string> {
  const list = [];
  sections.forEach(section => {
    if (section.type === "halfChannel" || section.type === "fullChannel") {
      const channelId = channelIdFromUrl(
        section.recommendations[0].video.channel.href
      );
      if (channelId != null) {
        list.push(channelId);
      }
    }

    if (section.type === "curatedChannelPanel") {
      list.push(...section.channelIds);
    }

    if (section.type === "individualVideos") {
      section.recommendations.forEach(recommendation => {
        const channelId = channelIdFromUrl(recommendation.video.channel.href);
        if (channelId != null) {
          list.push(channelId);
        }
      });
    }
  });
  return list;
}

function VideoPlaceholder(props) {
  return (
    <React.Fragment>
      <GreyRectangle aspectRatio={16 / 9} />

      <VideoMeta>
        <LoadingTextPlaceholder width={100} /> &nbsp;{" "}
        <LoadingTextPlaceholder width={50} />
      </VideoMeta>
    </React.Fragment>
  );
}

function VideoGroupPlaceholder(props) {
  return (
    <Group>
      <GroupHeader>
        <ChannelImageWrapper />
        <h5>
          <LoadingTextPlaceholder width={50} />
        </h5>
      </GroupHeader>
      <Row>
        {times(4, i => (
          <ColumnSpan columns={6} lg={3} key={`playlist-item-loading-${i}`}>
            <VideoPlaceholder />
          </ColumnSpan>
        ))}
      </Row>
    </Group>
  );
}

function Video(props) {
  const videoHref = urlForVideo(
    props.video.videoId,
    props.nativeLang,
    props.targetLang
  );

  const channelHref = urlForSearch(
    searchPropsForUrl(props.video.channel.href),
    props.nativeLang,
    props.targetLang
  );

  let img;
  if (props.large) {
    img = thumbnailsForVideo(props.video.videoId).maxres;
  } else {
    img = thumbnailsForVideo(props.video.videoId).medium;
  }

  // NEXT: Don't use medium
  return (
    <div>
      <a href={videoHref}>
        <ThumbnailPlaceholder {...img} />
      </a>

      <VideoMeta>
        <VideoTitle href={videoHref}>
          {/*
            It's very odd that this needs escaping. I think it's a bug in the YouTube data API,
            because other API endpoints don't need escaping.
          */
          unescape(props.video.title)}
        </VideoTitle>

        {props.showChannel && (
          <VideoChannel>
            <Television width="15" />
            <ChannelLink href={channelHref}>
              {props.video.channel.title}
            </ChannelLink>
          </VideoChannel>
        )}

        {/*props.components == null ? null : (
          <div style={{ wordBreak: "break-all" }}>
            {JSON.stringify(props.components)}
          </div>
        )*/}
      </VideoMeta>
    </div>
  );
}

const VideoTitle = styled.a`
  display: block;
  margin-bottom: 0.5em;
  color: #333;

  max-height: 72px; // typeography.body1.lineHeight * 3
  overflow: hidden;
`;

const VideoChannel = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  a {
    color: #888;
  }
  svg {
    fill: #888;
    margin-right: 1em;
  }
`;

const VideoMeta = styled.div`
  padding: 1em;
  ${typography.subtitle1}
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  svg {
    margin-right: 0.5em;
  }
`;

function FullChannel(props) {
  return (
    <FullColumn>
      <Group>
        <ChannelHeader
          nativeLang={props.nativeLang}
          targetLang={props.targetLang}
          channelSnippets={props.channelSnippets}
          videos={props.recommendations.map(r => r.video)}
        />
        <Row>
          {props.recommendations.map((recommendation, i) => (
            <ColumnSpan
              columns={6}
              lg={3}
              key={recommendation.video.videoId + "-" + i}
            >
              <Video
                large={false}
                video={recommendation.video}
                components={recommendation.components}
                nativeLang={props.nativeLang}
                targetLang={props.targetLang}
              />
            </ColumnSpan>
          ))}
        </Row>
      </Group>
    </FullColumn>
  );
}

function ChannelImage(props: { channelSnippet: ?YouTubeSnippet }) {
  let imgEl;
  if (props.channelSnippet == null) {
    imgEl = null;
  } else {
    imgEl = (
      <img
        alt={props.channelSnippet.title}
        src={props.channelSnippet.images[0]}
      />
    );
  }

  return <ChannelImageWrapper>{imgEl}</ChannelImageWrapper>;
}

function ChannelHeader(props) {
  const channelId = channelIdFromUrl(props.videos[0].channel.href);
  const href = urlForSearch(
    searchPropsForUrl(props.videos[0].channel.href),
    props.nativeLang,
    props.targetLang
  );

  let channelSnippet;
  if (channelId) {
    channelSnippet = props.channelSnippets[channelId];
  } else {
    channelSnippet = null;
  }

  const strings = stringsForLocale(props.nativeLang);

  return (
    <GroupHeader>
      <a href={href}>
        <ChannelImage channelSnippet={channelSnippet} />
      </a>
      <h5>{props.videos[0].channel.title}</h5>
      <ChannelLink href={href}>{strings.search_page.more_action()}</ChannelLink>
    </GroupHeader>
  );
}

function HalfChannel(props) {
  return (
    <ColumnSpan columns={12} lg={6}>
      <Group>
        <ChannelHeader
          nativeLang={props.nativeLang}
          targetLang={props.targetLang}
          channelSnippets={props.channelSnippets}
          videos={props.recommendations.map(r => r.video)}
        />
        <Row>
          {props.recommendations.map((recommendation, i) => (
            <HalfColumn key={recommendation.video.videoId + "-" + i}>
              <Video
                video={recommendation.video}
                components={recommendation.components}
                nativeLang={props.nativeLang}
                targetLang={props.targetLang}
              />
            </HalfColumn>
          ))}
        </Row>
      </Group>
    </ColumnSpan>
  );
}

function TutorialRow(props) {
  const tutorial1 = {
    title: "Introduction to CaptionPop",
    href: "/watch?v=_ygaIOsw_XE",
    videoId: "_ygaIOsw_XE",
    channel: {
      title: "CaptionPop",
      href: "/channel/UCPvHDtTJP26yGdF0J6LJASg"
    }
  };
  const tutorial2 = {
    title: "CaptionPop Flash Cards Tutorial",
    href: "/watch?v=P5453AQpsKc",
    videoId: "P5453AQpsKc",
    channel: {
      title: "CaptionPop",
      href: "/channel/UCPvHDtTJP26yGdF0J6LJASg"
    }
  };

  const strings = stringsForLocale(props.nativeLang);

  return (
    <FullColumn>
      <TutorialStyles>
        <h4>{strings.search_form.tutorials_header()}</h4>
        <TutoralCaption>
          {strings.search_form.tutorials_caption()}
        </TutoralCaption>

        <TwoVideos>
          <Video
            large={true}
            video={tutorial1}
            nativeLang={props.nativeLang}
            targetLang={props.targetLang}
          />

          <Video
            large={true}
            video={tutorial2}
            nativeLang={props.nativeLang}
            targetLang={props.targetLang}
          />
        </TwoVideos>
      </TutorialStyles>
    </FullColumn>
  );
}

const TwoVideos = styled.div`
  margin: 0 auto;
  display: flex;
  > div {
    flex: 1 0 0;
  }
  > div:first-child {
    margin-right: ${gutter}px;
  }
`;

const TutoralCaption = styled.div`
  ${typography.body1}
  margin-Bottom: ${spacers[3]};
`;

const TutorialStyles = styled(ContainerBreakout)`
  background-color: ${colors["purple-10"]};
  color: ${colors.textPrimary};
  text-align: center;
  padding-top: ${spacers[5]};
  padding-bottom: ${spacers[5]};
  margin-bottom: ${spacers[4]};

  h4 {
    margin-top: 0;
    margin-bottom: ${spacers[2]};
  }
`;

const RoundedButton = styled.a`
  ${buttonStyles}
  ${buttonBackgroundColorWithHover("white", false)}
  color: ${colors.black};
  border-radius: 25px;
  padding-left: ${spacers[4]};
  padding-right: ${spacers[4]};
  ${typography.button}
  &:hover {
    // The page default is to add underline to A tags
    text-decoration: none !important;
  }
`;

const CallToActionStyles = styled(ContainerBreakout)`
  background-color: ${colors.purple};
  color: white;
  padding-top: ${spacers[5]};
  padding-bottom: ${spacers[5]};
  margin-bottom: ${spacers[3]};

  display: flex;
  align-items: center;
  flex-direction: column;

  @media (${breakpoints.md}) {
    flex-direction: row;
    h3 {
      margin: 0;
      flex-basis: 70%;
      max-width: 70%;
    }
  }
`;

const ChromeExtensionStyles = styled(ContainerBreakout)`
  background-color: ${colors.purple};
  color: white;
  padding-top: ${spacers[5]};
  padding-bottom: ${spacers[5]};
  margin-bottom: ${spacers[3]};

  display: flex;

  p {
    ${typography.body1}
  }

  h3,
  p {
    margin: 16px 0;
  }
  h3 {
    margin-top: 0;
  }
`;

const LogoColumn = styled.div`
  flex: 0 0 10%;
  margin-right: ${gutter}px;

  max-width: 100px;

  img {
    width: 100%;
  }

  display: none;

  @media (${breakpoints.sm}) {
    display: block;
  }
`;

const TextColumn = styled.div`
  flex: 1 1 0;
`;

function recordOutboundClick(element) {
  function handler() {
    if (
      typeof gtag === "function" &&
      !document.location.origin.startsWith("http://localhost:")
    ) {
      const options = {};
      gtag("event", "Click Install Chrome Extension", options);
    }
  }

  // Using a standard React onclick handler won't work because the href interrupts React's event system
  if (element !== null) {
    element.addEventListener("click", handler);
  }
}

function BrowserExtensionCallToActionRow(props) {
  const strings = stringsForLocale(props.nativeLang).search_form;

  return (
    <FullColumn>
      <ChromeExtensionStyles>
        <LogoColumn>
          <img src={chromeSvg} />
        </LogoColumn>
        <TextColumn>
          <h3>{strings.chrome_extension_banner.header()}</h3>
          <p>{strings.chrome_extension_banner.line1()}</p>
          <p>{strings.chrome_extension_banner.line2()}</p>
          <RoundedButton
            ref={recordOutboundClick}
            href={
              "https://chrome.google.com/webstore/detail/captionpop/oghhiiieljkjlkiednhchfbpbgadkeam?hl=" +
              props.nativeLang
            }
          >
            {strings.chrome_extension_banner.install_action()}
          </RoundedButton>
        </TextColumn>
      </ChromeExtensionStyles>
    </FullColumn>
  );
}

function CallToActionRow(props) {
  const strings = stringsForLocale(props.nativeLang).search_form;

  return (
    <FullColumn>
      <CallToActionStyles>
        <h3>{strings.premium_cia()}</h3>

        <div>
          <RoundedButton href="/premium-splash">
            {strings.premium_button()}
          </RoundedButton>
        </div>
      </CallToActionStyles>
    </FullColumn>
  );
}

function getPlaylistsWithDetails(
  ids
): Promise<{ [string]: YouTubePlaylistWithDetails }> {
  return fetchMultipleById(ids, id => {
    return getSearchWithDetails(
      {
        searchType: "playlistItems",
        searchQuery: id,
        pageToken: null,
        maxResults: 4
      },
      true /* cache */
    ).then(results => {
      if (results.searchType === "playlistItems") {
        return results;
      } else {
        throw new Error("Expected playlistItems");
      }
    });
  });
}

function getChannelsWithDetails(
  ids
): Promise<{ [string]: YouTubeChannelWithDetails }> {
  return fetchMultipleById(ids, id => {
    return getSearchWithDetails(
      {
        searchType: "channelItems",
        searchQuery: id,
        pageToken: null,
        maxResults: 4
      },
      true /* cache */
    ).then(results => {
      if (results.searchType === "channelItems") {
        return results;
      } else {
        throw new Error("Expected channelItems");
      }
    });
  });
}

function fetchMultipleById<T>(
  ids: Array<string>,
  fetchSingleById: string => Promise<T>
): Promise<{ [string]: T }> {
  return Promise.all(
    ids.map(id => fetchSingleById(id).then(result => ({ [id]: result })))
  ).then(results => Object.assign({}, ...results));
}

function useFetchBatch<T>(
  ids: Array<string>,
  fetchBatch: (Array<string>) => Promise<{ [string]: T }>
): { [string]: T } {
  const callbackRef = React.useRef(fetchBatch);
  const ref = React.useRef({});
  const [results, setResults] = React.useState<{ [string]: T }>({});

  const [promises, setPromises] = React.useState([]);

  React.useEffect(() => {
    callbackRef.current = fetchBatch;
  }, [fetchBatch]);

  React.useEffect(() => {
    const batch = [];

    ids.forEach(id => {
      if (!ref.current[id]) {
        batch.push(id);
        ref.current[id] = true;
      }
    });

    if (batch.length > 0) {
      const promise = callbackRef.current(batch);
      setPromises(list => list.concat(promise));
    }
  }, [ids]);

  // TODO: Unsubscribe from promise? Handle errors?
  React.useEffect(() => {
    Promise.all(promises).then(results => {
      setResults(Object.assign({}, ...results));
    });
  }, [promises]);

  return results;
}

function LoadingView(props: { page: number }) {
  const elements = times(props.page * 5, page => (
    <FullColumn key={page}>
      <Group>
        <GroupHeader>
          <ChannelImageWrapper />
          <h5>
            <LoadingTextPlaceholder width={10} />
          </h5>
        </GroupHeader>
        <Row>
          {times(4, i => (
            <ColumnSpan columns={6} lg={3} key={"loading-" + i}>
              <VideoPlaceholder />
            </ColumnSpan>
          ))}
        </Row>
      </Group>
    </FullColumn>
  ));

  return (
    <Container>
      <Row>{elements}</Row>
    </Container>
  );
}

type LangPair = {
  nativeLang: string,
  targetLang: string
};

type LocaleList = Array<string>;

function featuredItemsIdsForLanguagePair(
  langs: LangPair,
  items: { [string]: { [string]: LocaleList } } // First string is target lang, second string is content Id
): Array<string> {
  const targetLang = normalizeLanguage(langs.targetLang);
  if (targetLang in items) {
    const list: { [string]: LocaleList } = items[targetLang];

    // If the user has the same target language and native language, then don't bother filtering based on
    // native language. This could happen with English/English.
    if (matchLanguage(langs.nativeLang, langs.targetLang)) {
      return Object.keys(list);
    } else {
      return Object.keys(list).filter(channelId =>
        list[channelId].find(lang => matchLanguage(lang, langs.nativeLang))
      );
    }
  } else {
    return [];
  }
}

function ExampleSearches(props: {
  links: Array<string>,
  nativeLang: string,
  targetLang: ?string
}) {
  return (
    <ExampleSearchList>
      {props.links.map((str, i) => (
        <LinkWithState
          key={str + "-" + String(i)}
          href={urlForSearch({ q: str }, props.nativeLang, props.targetLang)}
          state={{ featured: true }}
        >
          {str}
        </LinkWithState>
      ))}
    </ExampleSearchList>
  );
}

const ExampleSearchList = styled.div`
  margin-top: 5px;
  list-style-type: none;
  line-height: 175%;
  span {
    color: #777;
  }
  a {
    display: inline-block;
    color: #333;
    background-color: #fff;
    border: 1px solid ${colors.divider2};
    border-radius: 25px;
    text-decoration: none;
    padding: 0px ${spacers[3]};
    margin: 0.25em;
    ${typography.body2}
  }
`;
