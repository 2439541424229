/* @flow */

import * as React from "react";

import { fromEvent, of } from "rxjs";
import type { Observable } from "rxjs";
import { map, take, exhaustMap, debounceTime, startWith } from "rxjs/operators";

import { postTranslation } from "./apiClient";
import { withScope, captureException } from "@sentry/browser";
import type { APIError } from "./APIError";

type PopupTrigger = {
  targetEl: Range,
  text: string
};

const HTTP_NOT_AUTHORIZED = 403;

export function useTranslationPopupTrigger(): PopupTrigger | null {
  const [popupProps, setPopupProps] = React.useState<PopupTrigger | null>(null);

  React.useEffect(() => {
    const events$ = fromEvent(document, "selectionchange");

    // States:
    //   - NONE - default or popupPropsForSelection returns null
    //   - STARTING - When in NONE and event fires
    //   - FINISHED - When in START and debounce timer expires

    const popupProps$: Observable<PopupTrigger | null> = events$.pipe(
      exhaustMap(event => {
        const props = popupTriggerForSelection(document.getSelection());
        if (props == null) {
          return of(null); // State: None
        } else {
          // TODO: The way we resubscribe to event$ and re-inject the current event feels a little awkward,
          // but I can't find a better way right now.
          return events$.pipe(
            startWith(event),
            debounceTime(500),
            take(1),
            map(
              (event): PopupTrigger | null => {
                const props = popupTriggerForSelection(document.getSelection());

                // State: NONE
                if (props == null) return null;
                else return props; // State: FINISHED
              }
            ),
            startWith(null) // State: STARTING
          );
        }
      })
    );

    const subscription = popupProps$.subscribe(setPopupProps);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return popupProps;
}

function popupTriggerForSelection(selection: ?Selection): PopupTrigger | null {
  if (selection == null) return null;

  const text = selection.toString();
  const anchorNode = selection.anchorNode;

  if (
    text.trim() != "" &&
    anchorNode instanceof Text &&
    anchorNode.parentElement &&
    anchorNode.parentElement.closest(".subtitle-transcription") != null
  ) {
    const range = selection.getRangeAt(0);
    return { targetEl: range, text: text };
  } else {
    return null;
  }
}

function doApiCall(
  withIdToken: () => Promise<string>,
  text: string,
  nativeLang: string
): Promise<string> {
  return postTranslation(withIdToken, text, nativeLang).then(
    r => r.translation,
    (error: APIError) => {
      if (error.code !== HTTP_NOT_AUTHORIZED) {
        withScope(scope => {
          scope.setExtras({ text, nativeLang });
          captureException(error);
        });
      }
      return Promise.reject(error);
    }
  );
}

type ApiProps = {
  withIdToken: ?() => Promise<string>,
  nativeLang: string,
  popupTrigger: PopupTrigger | null
};

import type { PopupContent } from "./TranslationPopup";

export type TranslationPopupProps = {
  targetEl: Range,
  content: PopupContent
};

function useTranslationAPI(props: ApiProps): TranslationPopupProps | null {
  const [
    popupProps,
    setPopupProps
  ] = React.useState<TranslationPopupProps | null>(null);

  const text = props.popupTrigger == null ? null : props.popupTrigger.text;
  const targetEl =
    props.popupTrigger == null ? null : props.popupTrigger.targetEl;

  React.useEffect(() => {
    if (text == null || targetEl == null) {
      setPopupProps(null);
    } else {
      if (props.withIdToken == null) {
        setPopupProps({ targetEl, content: { authorized: false } });
      } else {
        const translation = doApiCall(
          props.withIdToken,
          text,
          props.nativeLang
        );
        setPopupProps({ targetEl, content: { authorized: true, translation } });
      }
    }
  }, [props.withIdToken, props.nativeLang, text, targetEl]);

  return popupProps;
}

type Props = {
  withIdToken: ?() => Promise<string>,
  nativeLang: string
};

export function useTranslationPopup(
  props: Props
): TranslationPopupProps | null {
  const popupTrigger = useTranslationPopupTrigger();

  const popupProps = useTranslationAPI({
    withIdToken: props.withIdToken,
    nativeLang: props.nativeLang,
    popupTrigger
  });

  return popupProps;
}
