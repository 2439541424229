/* @flow */

import * as React from "react";
import styled from "styled-components";
import LinkWithState from "./LinkWithState";

import type { YouTubeSnippet, YouTubeVideo } from "./youtubeScraper";
import { thumbnailsForVideo } from "./youtubeUtils";

import { urlForSearch, urlForVideo } from "./urlForRoute";

import {
  GreyText,
  GreyRectangle,
  ThumbnailPlaceholder
} from "./LoadingTextPlaceholder";

export const MediaCardGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  // TODO: See if you can use auto-fill to pick the number of columns
  // dynamically based on the device width
  // https://drafts.csswg.org/css-grid/#auto-repeat
  grid-template-columns: repeat(2, 1fr);

  @media only screen and (min-device-width: 640px) {
    grid-template-columns: repeat(4, 1fr);
  }
  width: 100%;
`;

export const MediaCard = styled.div`
  overflow: hidden;

  a {
    text-decoration: none;
    color: #333;
  }

  .card-body {
  }
`;

type PlaylistMediaCardProps = {
  playlistId: string,
  linkState: { [string]: string | boolean },
  nativeLang: string,
  targetLang: ?string,
  snippet: YouTubeSnippet | null
};

export function PlaylistMediaCard(props: PlaylistMediaCardProps) {
  return (
    <MediaCard>
      <LinkWithState
        href={urlForSearch(
          { playlistId: props.playlistId },
          props.nativeLang,
          props.targetLang
        )}
        state={props.linkState}
      >
        <GreyRectangle aspectRatio={320 / 180}>
          {props.snippet == null ? null : <img src={props.snippet.images[0]} />}
        </GreyRectangle>

        <div className="card-body">
          {props.snippet == null ? (
            <GreyText width={100} />
          ) : (
            <div>{props.snippet.title}</div>
          )}
        </div>
      </LinkWithState>
    </MediaCard>
  );
}

type ChannelMediaCardProps = {
  channelId: string,
  linkState: { [string]: string | boolean },
  nativeLang: string,
  targetLang: ?string,
  snippet: YouTubeSnippet | null
};

export function ChannelMediaCard(props: ChannelMediaCardProps) {
  return (
    <MediaCard>
      <LinkWithState
        href={urlForSearch(
          { channelId: props.channelId },
          props.nativeLang,
          props.targetLang
        )}
        state={props.linkState}
      >
        <GreyRectangle aspectRatio={1}>
          {props.snippet == null ? null : <img src={props.snippet.images[0]} />}
        </GreyRectangle>

        <div className="card-body">
          {props.snippet == null ? (
            <GreyText width={100} />
          ) : (
            <div>{props.snippet.title}</div>
          )}
        </div>
      </LinkWithState>
    </MediaCard>
  );
}

type VideoMediaCardProps = {
  videoId: string,
  snippet: YouTubeVideo | null,
  nativeLang: string,
  targetLang: ?string,
  linkState: { [string]: string | boolean }
};

export function VideoMediaCard(props: VideoMediaCardProps) {
  const thumbnails = thumbnailsForVideo(props.videoId);
  return (
    <MediaCard>
      <LinkWithState
        href={urlForVideo(props.videoId, props.nativeLang, props.targetLang)}
        state={props.linkState}
      >
        {props.snippet == null ? (
          <React.Fragment>
            <GreyRectangle aspectRatio={320 / 180} />
            <div className="card-body">
              <GreyText width={100} />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ThumbnailPlaceholder {...thumbnails.medium} />
            <div className="card-body">{props.snippet.title}</div>
          </React.Fragment>
        )}
      </LinkWithState>
    </MediaCard>
  );
}
