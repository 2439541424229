var en = function(n, ord
) {
  var s = String(n).split('.'), v0 = !s[1], t0 = Number(s[0]) == n,
      n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2);
  if (ord) return (n10 == 1 && n100 != 11) ? 'one'
      : (n10 == 2 && n100 != 12) ? 'two'
      : (n10 == 3 && n100 != 13) ? 'few'
      : 'other';
  return (n == 1 && v0) ? 'one' : 'other';
};
var number = function (value, name, offset) {
  if (!offset) return value;
  if (isNaN(value)) throw new Error("Can't apply offset:" + offset + ' to argument `' + name + '` with non-numerical value ' + JSON.stringify(value) + '.');
  return value - offset;
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

module.exports = {
  about_page: {
    headline: function(d) { return "CaptionPop est le meilleur moyen d'utiliser YouTube pour apprendre les langues."; },
    feature_list: {
      "0": function(d) { return "Visionnez avec plusieurs sous-titres simultanément."; },
      "1": function(d) { return "Répétez la dernière phrase en une seule touche."; },
      "2": function(d) { return "Ralentissez la vidéo."; },
      "3": function(d) { return "Marquez les sous-titres pour les étudier plus tard."; },
      "4": function(d) { return "Fonctionne avec n'importe quelle vidéo YouTube qui a des sous-titres."; }
    },
    get_started_header: function(d) { return "Démarrer"; },
    leave_feedback_header: function(d) { return "Gardez le contact !"; },
    leave_feedback_prompt: function(d) { return "Vous voulez être informé(e) des mises à jour ou laisser un commentaire ?"; },
    leave_feedback_action: function(d) { return "Dites bonjour !"; },
    find_videos_action: function(d) { return "Trouver des vidéos"; },
    closing_paragraph: function(d) { return "J'ai créé CaptionPop pour m'aider à améliorer ma compréhension  orale en coréen. Cela m’a beaucoup aidé et j’espère que cela vous aidera aussi."; },
    postscript: function(d) { return "PS: Envoyez-moi un mail à tout moment avec des questions, des commentaires ou des idées de fonctionnalités. J'aime recevoir les nouvelles des apprenants de langue."; },
    get_started: {
      target_select_prompt: function(d) { return "Vous ne voyez pas votre langue cible ?  CaptionPop fonctionne avec toutes les " + d.LENGTH + " langues prises en charge par YouTube. Sélectionnez une des options ci-dessous !"; },
      target_select_placeholder: function(d) { return "Sélectionnez n'importe quelle langue"; },
      my_native_language_is: function(d) { return "Ma langue maternelle est LANGUAGE et je veux apprendre :"; }
    }
  },
  choose_languages: {
    select_native_lang_prompt: function(d) { return "Quelle est votre langue maternelle ?"; },
    select_target_lang_prompt: function(d) { return "Quelle langue souhaitez-vous apprendre ?"; },
    update_action: function(d) { return "Mise à jour"; },
    featured_languages: function(d) { return "Langues principales"; },
    all_languages: function(d) { return "Toutes les langues"; },
    select_placeholder: function(d) { return "Sélectionner..."; }
  },
  play_videos_page: {
    add_to_library_action: function(d) { return "Ajouter à la vidéothèque"; },
    remove_from_library_action: function(d) { return "Retirer de la vidéothèque"; },
    library_icon_label: function(d) { return "Vidéothèque"; },
    no_available_subtitles: function(d) { return "Malheureusement, il n'y a pas de sous-titres disponibles pour cette vidéo."; },
    translation_upgrade_prompt: function(d) { return "Avec CaptionPop Premium tous les mots ou expressions que vous avez surlignes sont traduits instantanement avec Google Translate."; },
    translation_upgrade_call_to_action: function(d) { return "Passez au niveau superieur"; },
    blur_captions_label: function(d) { return "Brouiller les sous-titres"; },
    pause_after_caption_label: function(d) { return "Mettre en pause après chaque sous-titre"; },
    only_one_subtitle_language: function(d) { return "Les sous-titres pour cette vidéo ne sont disponibles qu'en " + d.LANGUAGE + "."; }
  },
  favorites_page: {
    blur_captions_label: function(d) { return "Brouiller les " + d.LANGUAGE + " sous-titres"; }
  },
  search_page: {
    page_title: function(d) { return "Rechercher des vidéos " + d.TARGET_LANG + " sur YouTube"; },
    meta_description: function(d) { return "Recherchez des vidéos sur YouTube avec les sous-titres en " + d.NATIVE_LANG + " et en " + d.TARGET_LANG + ". Regardez avec les deux sous-titres simultanément."; },
    meta_description_generic: function(d) { return "Recherchez sur YouTube des vidéos avec plusieurs sous-titres. Regardez avec les deux sous-titres simultanément."; },
    filter_header: function(d) { return "Filtrer par langue de sous-titres."; },
    show_all: function(d) { return "Tout afficher"; },
    more_action: function(d) { return "Plus"; },
    end_of_results: function(d) { return "Fin des résultats"; },
    empty_search_results: {
      no_videos: function(d) { return "Aucune vidéo n'a été trouvée."; },
      singular_video_dual_filter: function(d) { return "1 vidéo a été trouvée mais elle n'a pas de sous-titres en " + d.FILTER_LANGUAGE_A + " et " + d.FILTER_LANGUAGE_B + "."; },
      singular_video_single_filter: function(d) { return "1 vidéo a été trouvée mais elle n'a pas de sous-titres en " + d.FILTER_LANGUAGE + "."; },
      plural_videos_single_filter: function(d) { return d.VIDEO_COUNT + " vidéos ont été trouvées, mais aucune n'a de sous-titres en " + d.FILTER_LANGUAGE + "."; },
      plural_videos_dual_filter: function(d) { return d.VIDEO_COUNT + " vidéos ont été trouvées, mais aucune n'a de sous-titres en " + d.FILTER_LANGUAGE_A + " et " + d.FILTER_LANGUAGE_B + "."; }
    }
  },
  search_form: {
    search_action: function(d) { return "Rechercher"; },
    search_header: function(d) { return "Rechercher toutes les vidéos sur YouTube"; },
    filter_by_subtitle_header: function(d) { return "filtrer par sous-titre"; },
    filter_by_city_header: function(d) { return "filtrer par ville"; },
    tutorials_header: function(d) { return "Tutoriels CaptionPop"; },
    placeholder: function(d) { return "Rechercher le terme en " + d.LANGUAGE + " ou dans l'URL YouTube"; },
    change_language_link: function(d) { return "plus"; },
    featured_playlists_header: function(d) { return "Parcourir les listes de lecture en " + d.LANGUAGE + " sélectionnées"; },
    featured_channels_header: function(d) { return "Parcourir les chaînes en " + d.LANGUAGE + " sélectionnées"; },
    suggested_cities: function(d) { return "Villes suggérées"; },
    all_cities: function(d) { return "Toutes les villes"; },
    premium_button: function(d) { return "passez a Premium"; },
    tutorials_caption: function(d) { return "Besoin d'aide pour commencer ?"; },
    premium_cia: function(d) { return "Accélérez votre processus d'apprentissage avec CaptionPop Premium."; },
    chrome_extension_banner: {
      header: function(d) { return "Profitez de toute l'expérience CaptionPop en parcourant des vidéos sur YouTube.com."; },
      line1: function(d) { return "Installez l'extension de navigateur CaptionPop pour Chrome."; },
      line2: function(d) { return "Une fois installée, toutes les vidéos sur YouTube s’affichent avec la liste de sous-titres disponibles pour cette vidéo. Lorsque vous cliquez sur la liste de sous-titres, vous êtes redirigé vers le lecteur CaptionPop, ce qui vous permet de lire plusieurs sous-titres à la fois."; },
      install_action: function(d) { return "Installation"; }
    }
  },
  keyboard_shortcuts: {
    next_line_tooltip: function(d) { return "Ligne suivante"; },
    previous_line_tooltip: function(d) { return "Ligne précédente"; },
    toggle_playback_tooltip: function(d) { return "Lecture / Pause"; },
    show_translation_tooltip: function(d) { return "Afficher la traduction"; },
    repeat_tooltip: function(d) { return "Répéter"; }
  },
  page_header: {
    home_action: function(d) { return "Accueil"; },
    login_action: function(d) { return "Connexion"; },
    logout_action: function(d) { return "Déconnexion"; },
    favorites_link: function(d) { return "Favoris"; },
    premium_action: function(d) { return "Passez a Premium"; }
  },
  page_footer: {
    about_link: function(d) { return "À propos de"; },
    contact_link: function(d) { return "Contact"; },
    terms_of_service_link: function(d) { return "Conditions de service"; },
    privacy_policy_link: function(d) { return "Politique de confidentialité"; }
  },
  page_not_found: function(d) { return "Page introuvable"; },
  caption_popup_options: {
    edit: function(d) { return "Modifier le sous-titre"; },
    jump_to: function(d) { return "Passer à l'original"; },
    remove: function(d) { return "Supprimer des favoris"; }
  },
  edit_caption_form: {
    header: function(d) { return "Modifier le sous-titre"; },
    labels: {
      start_time: function(d) { return "heure de début"; },
      end_time: function(d) { return "heure de fin"; },
      transcription: function(d) { return "transcription"; },
      translations: function(d) { return "traductions"; }
    },
    actions: {
      save_changes: function(d) { return "Enregistrer les modifications"; },
      nevermind: function(d) { return "Ce n'est pas grave"; }
    }
  },
  form_validations: {
    required: function(d) { return "requis"; },
    too_big: function(d) { return "doit se trouver avant la fin de la vidéo"; },
    comes_after_start: function(d) { return "doit se trouver après l'heure de début"; }
  },
  navigation: {
    video_library_link: function(d) { return "Vidéothèque"; },
    favorite_captions_link: function(d) { return "Sous-titres favoris"; },
    account_link: function(d) { return "Compte"; },
    flash_cards_link: function(d) { return "Fiches interactives"; }
  },
  snackbar: {
    video_added_to_library: function(d) { return "Cette vidéo a été ajoutée à votre vidéothèque."; },
    video_removed_from_library: function(d) { return "Cette vidéo a été supprimée de votre vidéothèque."; },
    added_to_favorite_captions: function(d) { return "Le sous-titre a été ajouté à votre liste de sous-titres préférés."; },
    removed_from_favorite_captions: function(d) { return "Le sous-titre a été supprimé de votre liste de sous-titres préférés."; },
    updated_favorite_caption: function(d) { return "Vos modifications ont été enregistrées."; },
    network_error: function(d) { return "Vérifiez votre connexion Internet."; },
    playlist_added_to_library: function(d) { return "La playlist a été ajoutée à votre bibliothèque"; },
    playlist_removed_from_library: function(d) { return "La playlist a été retirée de votre bibliothèque"; },
    channel_added_to_library: function(d) { return "La chaîne a été ajoutée à votre bibliothèque"; },
    channel_removed_from_library: function(d) { return "La chaîne a été retirée de votre bibliothèque"; }
  },
  flash_cards: {
    days_count: function(d) { return plural(d.COUNT, 0, en, { one: "1 jour", other: number(d.COUNT, "COUNT") + " jours" }); },
    logged_out_warning: function(d) { return "Connectez-vous à CaptionPop pour enregistrer votre progression. Sinon, elle sera perdue lorsque vous fermerez votre navigateur."; },
    splash_page: {
      title: function(d) { return "CaptionPop: Flashcards interactives"; },
      introduction: function(d) { return "Améliorez vos compétences en compréhension orale avec des fiches interactives (flashcards)."; },
      feature_list: {
        "0": function(d) { return "Passez en revue les fiches interactives (flashcards) dont le contenu est fait de captures de vidéos YouTube."; },
        "1": function(d) { return "Des rétro-informations s’affichent lors de la frappe, selon un code de couleurs."; },
        "2": function(d) { return "Demander des indices pour surmonter des passages difficiles."; },
        "3": function(d) { return "Le score des fiches (flashcards) est calculé automatiquement."; },
        "4": function(d) { return "Un algorithme de répétition séquencée donne la date prévue pour une nouvelle révision de la carte."; }
      },
      calls_to_action: {
        header: function(d) { return "Prêt(e) à commencer ?"; },
        demo_decks_text: function(d) { return "Essaie l’un de ces paquets de fiches interactives en version démo."; },
        no_favorites: {
          text: function(d) { return "Ou crée ton propre paquet de fiches à partir des images trouvées sur YouTube."; },
          button: function(d) { return "Rechercher des Vidéos."; }
        },
        logged_in_with_favorites: {
          text: function(d) { return plural(d.COUNT, 0, en, { one: "1 Vous avez déjà effectué " + d.COUNT + " capture dans YouTube. Vous pouvez maintenant la retrouver sous la forme de fiche interactive (flashcard) !", other: "Vous avez déjà effectué " + number(d.COUNT, "COUNT") + " captures dans YouTube. Vous pouvez maintenant les retrouver sous la forme de fiches interactives (flashcards) !" }); },
          button: function(d) { return "Revoir les fiches"; }
        }
      }
    },
    instructions: {
      finish_pass: function(d) { return "Réessayez plus tard."; },
      finish_complete: function(d) { return "Bien joué !"; },
      playing_state: function(d) { return "Ecoutez très attentivement."; },
      quiz_state: function(d) { return "Saisissez au clavier ce que vous venez d’entendre."; },
      before_playback: function(d) { return "Appuyez sur « Play » et écoutez."; }
    },
    hint_tip: function(d) { return "Cliquez sur les cases grises pour obtenir des indices"; },
    actions: {
      skip: function(d) { return "Passer"; },
      finish: function(d) { return "Finir"; },
      more_to_review: function(d) { return plural(d.COUNT, 0, en, { one: "1 supplémentaire à revoir", other: number(d.COUNT, "COUNT") + " supplémentaires à revoir" }); },
      replay: function(d) { return "Rejouer"; }
    },
    previous_attempts_list: {
      header: function(d) { return "Nombre d’essais déjà réalisés"; },
      hints_used: function(d) { return "Indices utilisés"; },
      time_takes: function(d) { return "Durée"; },
      score: function(d) { return "Score"; }
    },
    finished: {
      edit_action: function(d) { return "Modifier"; },
      previous_attempts_header: function(d) { return "Nombre d’essais déjà réalisés"; },
      time_taken: function(d) { return "Durée"; },
      hints_used: function(d) { return "Indices utilisés"; },
      due_again: function(d) { return "A refaire"; },
      score: function(d) { return "Score"; }
    },
    dashboard: {
      header: function(d) { return "Fiches interactives (flashcards)"; },
      card_count: function(d) { return d.REVIEWED_COUNT + " sur " + plural(d.TOTAL_COUNT, 0, en, { one: "1 a été revue récemment.", other: number(d.TOTAL_COUNT, "TOTAL_COUNT") + " ont été revues récemment." }); },
      next_card_due: function(d) { return "La fiche suivante doit être revue dans " + d.TIMER + "."; },
      empty_deck: function(d) { return "Ce jeu est vide."; },
      actions: {
        review_random: function(d) { return "Revoir une fiche au hasard"; },
        create_custom_deck: function(d) { return "Créer un jeu sur mesure"; },
        edit_deck: function(d) { return "Afficher le jeu"; },
        review_all: function(d) { return "Revoir tout"; },
        review_deck: function(d) { return "Revoir"; },
        search: function(d) { return "Rechercher des videos"; }
      },
      saved_captions_deck: function(d) { return "Sous-titres recuperes dans YouTube"; },
      free_demo_deck_header: function(d) { return "Demonstration gratuite avec distribution de cartes"; },
      custom_deck_header: function(d) { return "Distribution de cartes au choix"; },
      tip_header: function(d) { return "Astuce"; },
      search_videos_tip: function(d) { return "Vous pouvez creer des flashcards a partir de n importe quelle video sur CaptionPop"; },
      custom_deck_tip: function(d) { return "Vous pouvez organiser vos sous-titres par distibution de cartes predeterminee"; },
      login_tip: function(d) { return "Connnectez-vous a votre compte CaptionPop pour crer des Flashcards a partir de n importe quelle video sur YouTube"; }
    },
    form: {
      create_header: function(d) { return "Créer un nouveau jeu de fiches interactives(flashcards)"; },
      update_header: function(d) { return "Rafraîchir votre jeu de fiches interactives"; },
      name_of_deck_header: function(d) { return "Nom du jeu de cartes"; },
      select_captions_header: function(d) { return "Sélectionner les captures"; },
      selected_count: function(d) { return plural(d.COUNT, 0, en, { one: "1 sélectionnée", other: number(d.COUNT, "COUNT") + " sélectionnées" }); },
      select_multiple_captions: function(d) { return "Sélectionner " + plural(d.COUNT, 0, en, { one: "1 fiche interactive", other: number(d.COUNT, "COUNT") + " fiches interactives" }) + " fiche interactive "; },
      unselect_multiple_captions: function(d) { return "Retirer " + plural(d.COUNT, 0, en, { one: "1 fiche interactive", other: number(d.COUNT, "COUNT") + " fiches interactives" }) + " fiche interactive"; },
      actions: {
        update: function(d) { return "Rafraîchir le jeu"; },
        create: function(d) { return "Créer un jeu"; },
        "delete": function(d) { return "Effacer le jeu"; }
      },
      confirmation: function(d) { return "Etes-vous certain de vouloir valider ?"; },
      snackbar: {
        created: function(d) { return "Votre nouveau jeu a été créé."; },
        deleted: function(d) { return "Votre jeu a été effacé."; },
        updated: function(d) { return "Votre jeu a été rafraîchi."; },
        error: function(d) { return "Erreur dans la sauvegarde de votre jeu."; }
      }
    },
    paywall_overlay: {
      header: function(d) { return "Votre essai gratuit est terminé pour aujourd’hui."; },
      text: function(d) { return "Attendez que la temporisation se réinitialise ou passez à CaptionPop Premium pour disposer d’un accès illimité."; },
      call_to_action: function(d) { return "Passez à CaptionPop Premium"; }
    },
    free_trial_banner: {
      flash_cards_remaining: function(d) { return "Il vous reste " + plural(d.COUNT, 0, en, { one: "1 fiche interactive (flashcard)", other: number(d.COUNT, "COUNT") + " fiches interactives (flashcards)" }) + " d’essai gratuit, aujourd’hui."; },
      upgrade_action: function(d) { return "Passez à CaptionPop Premium"; }
    },
    premium_subscription: {
      header: function(d) { return "Souscrivez à CaptionPop Premium Passez à CaptionPop Premium"; },
      product_description: function(d) { return "Améliorer votre niveau de compréhension orale avec des fiches (flashcards) interactives conçues à partir de vos vidéos YouTube favorites."; },
      form_headers: {
        plan: function(d) { return "Choisissez un plan de paiement"; },
        credit_card: function(d) { return "Cartes de crédit"; },
        create_an_account: function(d) { return "Créer un compte"; }
      },
      calls_to_action: {
        choose: function(d) { return "Choisissez"; },
        purchase: function(d) { return "Achat"; }
      },
      plans: {
        month: {
          title: function(d) { return "Mensuel"; },
          description: function(d) { return "Facturation mensuelle. Le prix le plus bas !"; },
          long_description: function(d) { return "Lorsque vous cliquez sur achat votre carte de credit sera debitee de " + d.AMOUNT + " aujourd hui, puis de " + d.AMOUNT + " tous les mois a la meme date. Vous pourrez vous desinscrire a tout moment, d un simple clic"; }
        },
        year: {
          title: function(d) { return "Annuel"; },
          description: function(d) { return "Facturation annuelle. Economisez " + d.PERCENT + "% par rapport à une facturation mensuelle !"; },
          long_description: function(d) { return "Lorsque vous cliquez sur achat votre carte de credit sera debitee de " + d.AMOUNT + " aujourd hui, puis de " + d.AMOUNT + " tous les ans a la meme date. Vous pourrez vous desinscrire a tout moment, d un simple clic"; }
        },
        lifetime: {
          title: function(d) { return "A vie"; },
          description: function(d) { return "Paiement en une seule fois. Utilisation à vie !"; },
          long_description: function(d) { return "Lorsque vous cliquez sur achat votre carte de credit sera debitee de " + d.AMOUNT + " aujourd hui, et ne le sera plus jamais ensuite"; }
        }
      },
      confirmation: {
        header: function(d) { return "Merci !"; },
        paragraph1: function(d) { return "Vous êtes inscrit(e) à <b>CaptionPop Premium !</b"; },
        paragraph2: function(d) { return "Vous disposez désormais d’un accès illimité aux fiches interactives (flashcards) à répétition séquencée de CaptionPop.  Cette fonctionnalité m’a personnellement aidé à améliorer mes compétences en compréhension orale et j’espère qu’il en ira de même pour vous. "; },
        paragraph3: function(d) { return "Vous pouvez annuler votre abonnement à tout moment en vous rendant sur la <a href=\"/account\">page d’accueil de votre compte</a>."; },
        creator_title: function(d) { return "Créateur de CaptionPop"; },
        call_to_action: function(d) { return "Fiches interactives (flashcards)"; },
        videos_action: function(d) { return "regardez les videos"; },
        flash_cards_action: function(d) { return "etudiez les flashcards"; }
      },
      available_currencies: function(d) { return "Également disponible en " + d.CURRENCY_LIST + "."; },
      errors: {
        banner_header: function(d) { return "Votre achat n’a pas pu aboutir"; },
        network_error: function(d) { return "Votre achat n’a pas pu aboutir en raison d’un problème de connexion internet."; },
        unexpected_error: function(d) { return "Un problème inattendu empêche de finaliser votre achat. Votre carte de crédit n’a pas été débitée. Le développeur de CaptionPop en a été informé. Merci de faire une nouvelle tentative ultérieurement."; }
      },
      account: {
        choose_password: function(d) { return "Choisissez un mot de passe"; },
        provider_signup: function(d) { return "ou inscrivez-vous avec…"; },
        already_have_account: function(d) { return "Vous avez déjà un compte ?"; },
        logged_in_as: function(d) { return "Vous êtes connecté en tant que " + d.EMAIL + "."; },
        not_you: function(d) { return "Ce n’est pas vous ?"; }
      }
    }
  },
  premium_splash_page: {
    page_title: function(d) { return "abonnez-vous a CaptionPop premium"; },
    header1: function(d) { return "Accelerez le processus d appentissage grace a ces fonctions avancees"; },
    free_column_header: function(d) { return "Gratuit"; },
    premium_column_header: function(d) { return "Premium"; },
    feature1: function(d) { return "Regardez avec des sous-titres multiples"; },
    feature2: function(d) { return "Filtrez vos recherches YouTube par langue de sous-titres"; },
    feature3: function(d) { return "Surlignez le texte  a traduire"; },
    feature4: function(d) { return "Flashcards interactives"; },
    free_flash_card_limit: function(d) { return "5 par jour"; },
    header2: function(d) { return "Surlignez le texte  a traduire."; },
    google_translate_description: function(d) { return "Surlignez n importe quel mot ou expression a traduire dans votre langue maternelle. Les trauctions sont faites par Google Translate."; },
    header3: function(d) { return "Nombre illimite de flashcards de comprehension."; },
    call_to_action: function(d) { return "passez a Premium"; }
  },
  login_form: {
    favorite_prompt: function(d) { return "Veuillez vous connecter pour pouvoir sauvegarder vos sous-titres préférés."; },
    placeholders: {
      email: function(d) { return "E-mail / adresse électronique"; },
      password: function(d) { return "Mot de Passe"; },
      current_password: function(d) { return "Mot de passe actuel"; },
      password_confirmation: function(d) { return "Confirmation du Mot de Passe"; }
    },
    forgot_password_link: function(d) { return "Mot de Passe oublié ?"; },
    or: function(d) { return "ou"; },
    actions: {
      login: function(d) { return "Connexion"; },
      signup: function(d) { return "Créer un compte"; },
      login_with_provider: function(d) { return "Se connecter avec " + d.PROVIDER; },
      signup_with_provider: function(d) { return "Ouvrir un compte avec " + d.PROVIDER; }
    },
    errors: {
      invalid_credentials: function(d) { return "E-mail ou Mot de Passe incorrect"; },
      email_conflict: function(d) { return "Un compte avec cet e-mail existe déjà."; },
      unexpected_login_api_error: function(d) { return "Un problème est survenu lors de la connexion."; },
      unexpected_signup_api_error: function(d) { return "Un problème est survenu lors de la création de votre compte."; },
      network_error: function(d) { return "Vérifiez votre connexion Internet."; }
    },
    library_prompt: function(d) { return "Merci de vous connecter pour sauvegarder des éléments dans votre bibliothèque"; }
  },
  password_reset_form: {
    instructions: function(d) { return "Merci de saisir votre e-mail. Vous recevrez des instructions pour récupérer l’accès à votre compte, par courrier électronique"; },
    actions: {
      send_email: function(d) { return "Envoyez un e-mail"; },
      ok: function(d) { return "OK"; }
    },
    finished_status: {
      instructions_sent: function(d) { return "Des instructions ont été envoyées à l’adresse suivante " + d.EMAIL_ADDRESS + "."; },
      rate_limited: function(d) { return "Des Instructions ont déjà été envoyées à l’adresse suivante " + d.EMAIL_ADDRESS + ". Merci d’attendre au moins une heure avant de présenter une nouvelle demande."; },
      network_error: function(d) { return "Un problème de connexion avec le serveur est survenu. Merci de verifier votre connexion Internet."; },
      delivery_error: function(d) { return "Problème rencontré lors de la transmission des instructions à  l’adresse suivante " + d.EMAIL_ADDRESS + ". Merci de confirmer qu’il s’agit d’une adresse mail valide."; }
    }
  },
  password_reset_invalid_page: {
    header: function(d) { return "Lien de redéfinition d’un mot de passe invalide"; },
    text: function(d) { return "Les liens de redéfinition de mots de passe ne sont valides que 24 heures"; },
    actions: {
      home: function(d) { return "Home / Accueil"; },
      request_new: function(d) { return "Réclamez un nouveau lien"; }
    }
  },
  change_password_page: {
    password_reset_instructions: function(d) { return "Merci de choisir un nouveau Mot de Passe pour accéder à nouveau à votre compte"; },
    change_password_header: function(d) { return "Modifiez votre Mot de Passe "; },
    change_password_action: function(d) { return "Changez de Mot de Passe"; },
    password_updated_confirmation: function(d) { return "Votre Mot de Passe a été mis à jour"; },
    network_error: function(d) { return "Votre Mot de Passe n’a pas été mis à jour en raison d’un problème de connexion Internet"; }
  },
  account_snackbar_messages: {
    logged_in: function(d) { return "Vous êtes connecté(e)"; },
    logged_out: function(d) { return "Vous êtes déconnecté(e)"; },
    signed_up: function(d) { return "Votre compte a été créé"; },
    subscription_cancelled: function(d) { return "Votre abonnement a été résilié"; },
    subscription_reactivated: function(d) { return "Votre abonnement a été réactivé"; }
  },
  library_page: {
    search_action: function(d) { return "Rechercher des videos"; },
    headers: {
      playlists: function(d) { return "Playlists"; },
      channels: function(d) { return "Canales"; },
      videos: function(d) { return "Vidéos"; }
    },
    empty_header: function(d) { return "Votre bibliothèque est vide"; },
    empty_description: function(d) { return "Vous pouvez sauvegarder vos vidéos, chaînes et vos playlists ici."; }
  },
  account_page: {
    page_header: function(d) { return "Mon compte"; },
    current_plan_header: function(d) { return "Plan actuel"; },
    free_plan: function(d) { return "Gratuit"; },
    monthly_plan_pricing: function(d) { return d.AMOUNT + " par mois"; },
    yearly_plan_pricing: function(d) { return d.AMOUNT + " par an"; },
    subscription_ends_on: function(d) { return "Votre abonnement se terminera le " + d.END_DATE; },
    subscription_renews_on: function(d) { return "Votre abonnement sera renouvelé automatiquement le " + d.RENEW_DATE + "."; },
    cancel_subscription_action: function(d) { return "Annuler l'abonnement"; },
    reactivate_subscription_action: function(d) { return "Réactiver l'abonnement"; },
    subscription_type_basic: function(d) { return "CaptionPop Basic"; },
    subscription_type_premium: function(d) { return "CaptionPop Premium"; },
    subscription_type_lifetime: function(d) { return "CaptionPop Lifetime"; },
    language_settings_header: function(d) { return "Paramètres linguistiques"; },
    native_language_header: function(d) { return "Langue maternelle"; },
    target_language_header: function(d) { return "Langue cible"; },
    native_language_update_confirmation: function(d) { return "Votre langue maternelle a été actualisée."; },
    target_language_update_confirmation: function(d) { return "Votre langue cible a été actualisée."; },
    payment_history_header: function(d) { return "Historique des paiements"; },
    receipt_link: function(d) { return "Reçu"; },
    credit: function(d) { return "Crédit " + d.AMOUNT; },
    credit_card_description: function(d) { return d.CREDIT_CARD_BRAND + " se terminant par " + d.LAST4; },
    credit_card_expires: function(d) { return "Expire " + d.MONTH + "/" + d.YEAR; },
    payment_method_header: function(d) { return "Mode de paiement"; },
    update_payment_method_action: function(d) { return "Mise à jour de la carte de crédit"; },
    payment_method_update_confirmation: function(d) { return "Votre mode de paiement a été actualisé."; },
    cancel_action: function(d) { return "Annuler"; },
    update_action: function(d) { return "Mise à jour"; },
    none: function(d) { return "Aucune"; }
  },
  cancel_subscription_page: {
    page_header: function(d) { return "Votre abonnement a été annulé"; },
    confirmation: function(d) { return "Merci ! Vos commentaires nous aident à améliorer CaptionPop\"."; },
    paragraph1: function(d) { return "Votre abonnement à CaptionPop Premium a été annulé. Vous aurez toujours accès à toutes les fonctionnalités de CaptionPop Premium jusqu'à la fin de votre période de facturation actuelle."; },
    paragraph2: function(d) { return "Si vous changez d'avis, vous pouvez réactiver votre abonnement à tout moment à partir de la page de votre compte."; },
    feedback_header: function(d) { return "Pourquoi avez-vous décidé d'annuler ?"; },
    feedback_prompt: function(d) { return "Aidez-nous à améliorer CaptionPop en nous faisant part de vos commentaires sur votre décision d'annuler."; },
    reason_too_expensive: function(d) { return "Le prix est trop élevé."; },
    reason_not_useful: function(d) { return "Je ne trouve plus CaptionPop Premium utile"; },
    reason_other: function(d) { return "Une autre raison"; },
    comments_header: function(d) { return "Avez-vous d'autres commentaires ou suggestions ?"; },
    cancel_action: function(d) { return "Non, je vous remercie."; },
    submit_action: function(d) { return "Soumettre un commentaire"; },
    reason_required: function(d) { return "Veuillez choisir une raison."; }
  }
}