
module.exports = {
  af: function(d) { return "Afrikaans"; },
  az: function(d) { return "Azərbaycan"; },
  id: function(d) { return "Indonesia"; },
  ms: function(d) { return "Melayu"; },
  bs: function(d) { return "Bosanski"; },
  ca: function(d) { return "Català"; },
  cs: function(d) { return "Čeština"; },
  da: function(d) { return "Dansk"; },
  de: function(d) { return "Deutsch"; },
  et: function(d) { return "Eesti"; },
  "en-GB": function(d) { return "English (United Kingdom)"; },
  en: function(d) { return "English"; },
  es: function(d) { return "Español (España)"; },
  "es-419": function(d) { return "Español (Latinoamérica)"; },
  "es-US": function(d) { return "Español (Estados Unidos)"; },
  eu: function(d) { return "Euskara"; },
  fil: function(d) { return "Filipino"; },
  fr: function(d) { return "Français"; },
  "fr-CA": function(d) { return "Français (Canada)"; },
  gl: function(d) { return "Galego"; },
  hr: function(d) { return "Hrvatski"; },
  zu: function(d) { return "Isizulu"; },
  is: function(d) { return "Íslenska"; },
  it: function(d) { return "Italiano"; },
  sw: function(d) { return "Kiswahili"; },
  lv: function(d) { return "Latviešu"; },
  lt: function(d) { return "Lietuvių"; },
  hu: function(d) { return "Magyar"; },
  nl: function(d) { return "Nederlands"; },
  no: function(d) { return "Norsk"; },
  uz: function(d) { return "O‘zbek"; },
  pl: function(d) { return "Polski"; },
  "pt-PT": function(d) { return "Português (Portugal)"; },
  pt: function(d) { return "Português (Brasil)"; },
  ro: function(d) { return "Română"; },
  sq: function(d) { return "Shqip"; },
  sk: function(d) { return "Slovenčina"; },
  sl: function(d) { return "Slovenščina"; },
  "sr-Latn": function(d) { return "Srpski (Latinica)"; },
  fi: function(d) { return "Suomi"; },
  sv: function(d) { return "Svenska"; },
  vi: function(d) { return "Tiếng Việt"; },
  tr: function(d) { return "Türkçe"; },
  be: function(d) { return "Беларуская"; },
  bg: function(d) { return "Български"; },
  ky: function(d) { return "Кыргызча"; },
  kk: function(d) { return "Қазақ Тілі"; },
  mk: function(d) { return "Македонски"; },
  mn: function(d) { return "Монгол"; },
  ru: function(d) { return "Русский"; },
  sr: function(d) { return "Српски"; },
  uk: function(d) { return "Українська"; },
  el: function(d) { return "Ελληνικά"; },
  hy: function(d) { return "Հայերեն"; },
  iw: function(d) { return "עברית"; },
  ur: function(d) { return "اردو"; },
  ar: function(d) { return "العربية"; },
  fa: function(d) { return "فارسی"; },
  ne: function(d) { return "नेपाली"; },
  mr: function(d) { return "मराठी"; },
  hi: function(d) { return "हिन्दी"; },
  bn: function(d) { return "বাংলা"; },
  pa: function(d) { return "ਪੰਜਾਬੀ"; },
  gu: function(d) { return "ગુજરાતી"; },
  ta: function(d) { return "தமிழ்"; },
  te: function(d) { return "తెలుగు"; },
  kn: function(d) { return "ಕನ್ನಡ"; },
  ml: function(d) { return "മലയാളം"; },
  si: function(d) { return "සිංහල"; },
  th: function(d) { return "ไทย"; },
  lo: function(d) { return "ລາວ"; },
  my: function(d) { return "မြန်မာ (ဗမာ)"; },
  ka: function(d) { return "ქართული"; },
  am: function(d) { return "አማርኛ"; },
  km: function(d) { return "ខ្មែរ"; },
  "zh-CN": function(d) { return "中文"; },
  "zh-TW": function(d) { return "中文（台灣）"; },
  "zh-HK": function(d) { return "中文（香港）"; },
  ja: function(d) { return "日本語"; },
  ko: function(d) { return "한국어"; }
}