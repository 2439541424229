module.exports = {
  ko: {
    // Blade Man
    "PLMf7VY8La5RF3sTccH9R8P3k-pri7I3wD": ["zh", "en", "id", "ms", "vi"],
    // Lovers of Music
    PLMf7VY8La5RFBoxHlm2hRYuoIITywYPRT: ["zh", "en", "id", "ms", "vi"],
    // Discovery of Love
    PLMf7VY8La5RHAudFunjeV_6tP8YjNFBw4: ["zh", "en", "id", "ms", "vi"],
    // Gunman In Joseon
    PLMf7VY8La5RHA7VzUEYaKF_xbx3BOlKGH: ["zh", "en", "id", "ms", "vi"]
  },

  // Telenovelas from Daphne
  "es-419": {
    // La Querida del Centauro Resumene
    PLlpTGFsKPu1klK2rTjbu4h1WjCoumMOqP: ["en"],

    // ¿Quien es Quien?
    PLlpTGFsKPu1nhlhhjN2pzZjp2sbLrxfMm: ["en"],

    // Avenida Brasil
    PLlpTGFsKPu1n3EIzPsxUZNk2izTFKlYLh: ["en"],

    // Bajo El Mismo Cielo
    "PLlpTGFsKPu1m_KT-bsm2TmjPx0MYIefSL": ["en"],

    // Tierra de Reyes
    PLlpTGFsKPu1mQ9qBovCFuCkC45boD9oix: ["en"],

    // Eva La Trailera
    PLlpTGFsKPu1lBu80P0zQg98LJKzn2nwaX: ["en"],

    // Señora Acero 2
    PLlpTGFsKPu1kLNs_xOXufbuNOj9Dqaiws: ["en"],

    // El Señor de los Cielos 3 Capitulos
    PLlpTGFsKPu1mZgODOj_Zed7feZXmzA4pc: ["en"],

    // La Querida del Centauro Capitulos
    PLlpTGFsKPu1nmFNAHjQPPzxHlaODlsNuO: ["en"],

    // El Señor de los Cielos 3 Resúmenes
    "PLlpTGFsKPu1monsYv18IhR-T5Bp0iqk0V": ["en"],

    // Not Sure Where I Got These From (Daphne):

    //El Señor de los Cielos 4
    PLlpTGFsKPu1l0RCCH5AruSevmF9fjfpfy: ["en"]
  }
};
