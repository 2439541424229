/* @flow strict */

import * as React from "react";

import { mapValues, every } from "lodash";

import { stringsForLocale } from "../lang/web";

export type ErrorMap<T> = $ObjMap<T, <V>(V) => Array<string>>;

type Props<T> = {
  values: T,
  validations: ValidationMap<T>,
  asyncValidationErrors?: $Shape<ErrorMap<T>>,
  locale: string,
  onSubmit: Event => void
};

export type Validation<T> = {
  validate: (value: string, key: $Keys<T>, values: T) => boolean,
  message: (locale: string) => string
};

export type ValidationMap<T> = $ObjMap<T, <V>(V) => Array<Validation<T>>>;

type VisitedMap<T> = $ObjMap<T, <V>(V) => boolean>;

type FormEventHandlers = {
  onBlur: Event => void,
  onFocus: Event => void,
  onSubmit: Event => void
};

/**
  Manages validation errors for a form.

  Types of errors:

  - Real time Errors
    Synchronous errors that occur as the user types
    These are calculated from the validation functions

  - Async Errors
    Errors that require a round trip to the server
    These errors are passed through unmodified from the caller
*/
export function useFormValidations<T: {}>(
  props: Props<T>
): [
  ErrorMap<T>,
  FormEventHandlers,
  ($Keys<T>, boolean) => void,
  (boolean) => void
] {
  const [visited, setVisited] = React.useState<VisitedMap<T>>({});
  const [focusedKey, setFocusedKey] = React.useState<string | null>(null);
  const [submitAttempted, setSubmitAttempted] = React.useState(false);

  const setVisitedKey = React.useCallback((key: $Keys<T>, value: boolean) => {
    setVisited(updateVisitedMap.bind(null, key, value));
  }, []);

  const onFocus = React.useCallback(
    (event: Event) => {
      // $FlowFixMe - I don't know how to refine a string into a $Keys string literal union.
      const key: $Keys<T> | null = nameFromEventTarget(event);
      // Here are some relevant links:
      // https://github.com/facebook/flow/issues/6748
      // https://stackoverflow.com/questions/54149371/how-to-create-a-flow-union-runtime-refinement-without-embedding-literals

      if (key != null) {
        setFocusedKey(key);
        setVisitedKey(key, true);
      }
    },
    [setVisitedKey]
  );

  const onBlur = React.useCallback((event: Event) => {
    const key = nameFromEventTarget(event);

    setFocusedKey(currentKey => {
      if (key != null && currentKey != null && key === currentKey) {
        return null;
      } else {
        return currentKey;
      }
    });
  }, []);

  const isValid =
    !anyValidationErrors(props.values, props.validations) &&
    every(props.asyncValidationErrors, value => value.length === 0);

  const propsSubmit = props.onSubmit;
  const onSubmit = React.useCallback(
    (event: Event) => {
      setSubmitAttempted(true);
      event.preventDefault();
      event.stopPropagation();

      if (isValid) {
        propsSubmit(event);
      }
    },
    [propsSubmit, isValid]
  );

  const unfiltered = applyValidations(
    props.values,
    props.validations,
    props.locale
  );

  function filterErrors(errorMap, fn: string => boolean) {
    return mapValues(errorMap, (value, key) => {
      if (fn(key)) {
        return value;
      } else {
        return [];
      }
    });
  }

  // Suppress live errors until a field has been visited or the form has been submitted
  const synchronousValidationErrors = filterErrors(
    unfiltered,
    key => submitAttempted || (visited[key] && focusedKey !== key)
  );

  const errorMap = {
    ...synchronousValidationErrors,
    ...props.asyncValidationErrors
  };

  const eventHandlers = { onBlur, onFocus, onSubmit };
  return [errorMap, eventHandlers, setVisitedKey, setSubmitAttempted];
}

function updateVisitedMap<T: {}>(
  key: $Keys<T>,
  value: boolean,
  currentMap: VisitedMap<T>
): VisitedMap<T> {
  return { ...currentMap, [key]: value };
}

function applyValidations<T: {}>(
  values: T,
  validations: ValidationMap<T>,
  locale: string
): ErrorMap<T> {
  return mapValues(values, (value, key) =>
    validations[key]
      .filter(validation => !validation.validate(value, key, values))
      .map(validation => validation.message(locale))
  );
}

function nameFromEventTarget(event: Event): string | null {
  if (event.target instanceof HTMLTextAreaElement) {
    return event.target.name;
  } else if (event.target instanceof HTMLInputElement) {
    return event.target.name;
  } else {
    return null;
  }
}

export const required: Validation<*> = {
  validate(input: string) {
    return input.trim() != "";
  },
  message(locale: string) {
    return stringsForLocale(locale).form_validations.required();
  }
};

export function confirmationOf(otherFieldName: string): Validation<*> {
  return {
    validate(input: string, key: string, values: {}) {
      return input == values[otherFieldName];
    },
    message(_locale: string) {
      // XXX: Localize Me
      return "should match";
    }
  };
}

export function anyValidationErrors<T: { [string]: string }>(
  values: T,
  validations: ValidationMap<T>
): boolean {
  return !every(values, (value, key) => isKeyValid(key, values, validations));
}

export function isKeyValid<T: { [string]: string }>(
  key: $Keys<T>,
  values: T,
  validations: ValidationMap<T>
): boolean {
  return every(validations[key], validation =>
    validation.validate(values[key], key, values)
  );
}
