/* @flow */

import * as React from "react";

import PageHeader from "./PageHeader";

import { Box, PageContent } from "./FormStyles";
import WithLoginRequiredPage from "./WithLoginRequiredPage";

import { useFormState, useFormUtils } from "./WithFormState";
import {
  useFormValidations,
  required,
  confirmationOf
} from "./useFormValidations";
import { FormField } from "./Forms";
import { SubmitButton } from "./Buttons";

import { putPasswordReset } from "./apiClient";
import { stringsForLocale } from "../lang/web";

import styled from "styled-components";
import type { UserResourceStore } from "./WithUserResourceStore";
import type { SnackbarMessage } from "./useSnackbarQueue";
import type { DocumentLocation } from "./useNavigation";
import SentryErrorPage from "./SentryErrorPage";
import parseSearchString from "./parseSearchString";

type Props = {
  isInitialized: boolean,
  onLogin: () => void,
  onLogout: () => void,
  nativeLang: string,
  targetLang: ?string,
  userResources: ?UserResourceStore,
  onNavigate: (href: string) => void,
  onAddSnackbarMessage: SnackbarMessage => void,
  location: DocumentLocation
};

const initialValues = {
  password: "",
  passwordConfirmation: ""
};

const validations = {
  password: [required],
  passwordConfirmation: [required, confirmationOf("password")]
};

export default function ChangePasswordPage(props: Props) {
  return (
    <WithLoginRequiredPage
      isInitialized={props.isInitialized}
      nativeLang={props.nativeLang}
      onLogin={props.onLogin}
      userResources={props.userResources}
      render={userResources => (
        <ChangePasswordPageController
          onLogin={props.onLogin}
          onLogout={props.onLogout}
          nativeLang={props.nativeLang}
          targetLang={props.targetLang}
          onAddSnackbarMessage={props.onAddSnackbarMessage}
          onNavigate={props.onNavigate}
          userResources={userResources}
          location={props.location}
        />
      )}
    />
  );
}

type ControllerProps = {
  onLogin: () => void,
  onLogout: () => void,
  nativeLang: string,
  targetLang: ?string,
  onNavigate: (href: string) => void,
  userResources: UserResourceStore,
  onAddSnackbarMessage: SnackbarMessage => void,
  location: DocumentLocation
};

function ChangePasswordPageController(props: ControllerProps) {
  const passwordReset =
    "password-reset" in parseSearchString(props.location.search);

  const api = usePutPassword({
    nativeLang: props.nativeLang,
    onNavigate: props.onNavigate,
    redirectPath: passwordReset ? "/" : "/account",
    withIdToken: props.userResources.withIdToken,
    onAddSnackbarMessage: props.onAddSnackbarMessage
  });

  if (api.error != null) {
    return (
      <SentryErrorPage
        nativeLang={props.nativeLang}
        eventId={api.error.eventId}
        error={api.error.error}
      />
    );
  } else {
    return (
      <ChangePasswordPageView
        {...props}
        passwordReset={passwordReset}
        inProgress={api.inProgress}
        onChangePassword={api.onChangePassword}
      />
    );
  }
}

import { captureException } from "@sentry/browser";
import { APIError } from "./APIError";

function usePutPassword(props: {
  nativeLang: string,
  onNavigate: (href: string) => void,
  withIdToken: () => Promise<string>,
  redirectPath: string,
  onAddSnackbarMessage: SnackbarMessage => void
}) {
  const [inProgress, setInProgress] = React.useState(false);
  const [error, setError] = React.useState<{
    eventId: string,
    error: Error
  } | null>(null);

  function onChangePassword(values: {
    password: string,
    passwordConfirmation: string
  }) {
    setInProgress(true);

    const promise = putPasswordReset(props.withIdToken, values);
    const strings = stringsForLocale(props.nativeLang).change_password_page;

    promise.then(
      () => {
        setInProgress(false);
        props.onNavigate(props.redirectPath);
        props.onAddSnackbarMessage({
          level: "message",
          body: strings.password_updated_confirmation()
        });
      },
      error => {
        setInProgress(false);

        if (error instanceof APIError) {
          const eventId = captureException(error);
          setError({ eventId, error });
        } else {
          props.onAddSnackbarMessage({
            level: "error",
            body: strings.network_error()
          });
        }
      }
    );
  }

  return { inProgress, onChangePassword, error };
}

type ViewProps = {
  onLogin: () => void,
  onLogout: () => void,
  nativeLang: string,
  targetLang: ?string,
  onChangePassword: (values: {
    password: string,
    passwordConfirmation: string
  }) => void,
  inProgress: boolean,
  passwordReset: boolean,
  userResources: UserResourceStore
};

export function ChangePasswordPageView(props: ViewProps) {
  const formProps = useFormState({ values: initialValues });
  const formUtils = useFormUtils(formProps);

  function onSubmit(event) {
    props.onChangePassword(formProps.values);
  }

  const strings = stringsForLocale(props.nativeLang).change_password_page;
  const placeholder_strings = stringsForLocale(props.nativeLang).login_form
    .placeholders;

  const [validationErrors, formEventHandlers] = useFormValidations({
    values: formProps.values,
    locale: props.nativeLang,
    validations,
    onSubmit
  });

  return (
    <React.Fragment>
      <PageHeader
        onLogin={props.onLogin}
        onLogout={props.onLogout}
        nativeLang={props.nativeLang}
        targetLang={props.targetLang}
        isLoggedIn={true}
        userResources={props.userResources}
      />
      <MyPageContent>
        <MyBox>
          <Header>{strings.change_password_header()}</Header>
          {props.passwordReset ? (
            <Instructions>{strings.password_reset_instructions()}</Instructions>
          ) : null}
          <Form {...formEventHandlers}>
            <MyFormField
              {...formUtils.propsForInput("password")}
              errorMessages={validationErrors.password}
              renderInput={props => (
                <input
                  {...props}
                  type="password"
                  placeholder={placeholder_strings.password()}
                />
              )}
            />

            <MyFormField
              {...formUtils.propsForInput("passwordConfirmation")}
              errorMessages={validationErrors.passwordConfirmation}
              renderInput={props => (
                <input
                  {...props}
                  type="password"
                  placeholder={placeholder_strings.password_confirmation()}
                />
              )}
            />

            <SubmitButton
              value={strings.change_password_action()}
              disabled={props.inProgress}
              primary
            />
          </Form>
        </MyBox>
      </MyPageContent>
    </React.Fragment>
  );
}

import * as geometry from "./geometry";

const MyPageContent = styled(PageContent)`
  margin-top: ${geometry.pageHeaderHeight + geometry.fontSize * 2}px;
`;

const MyBox = styled(Box)`
  padding: 2em 1em;
`;

const Form = styled.form`
  text-align: center;
`;

const Header = styled.div`
  color: #333;
  font-weight: bold;
  font-size: 120%;
  margin-bottom: 1em;
  text-align: center;
`;

const MyFormField = styled(FormField)`
  width: 320px;
  input {
    width: 100%;
  }
  margin-left: auto;
  margin-right: auto;
`;

const Instructions = styled.div`
  margin-bottom: 1em;
  text-align: center;
`;
