/* @flow */

import { stringify } from "querystring";

import fetch, { Headers } from "node-fetch";
import { handleAPIResponse } from "./APIError";

export function apiFetch(
  idToken: ?string,
  url: string,
  options: RequestOptions
): Promise<any> {
  const headers = new Headers(options.headers || {});
  headers.set("Accept", "application/json");
  if (idToken) {
    headers.set("Authorization", `Bearer ${idToken}`);
  }

  const method = options.method || "GET";

  return fetch(url, { ...options, headers }).then(r =>
    handleAPIResponse(method, r, "error")
  );
}

function sendJSONOptions(body) {
  return {
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body)
  };
}

export function getJSON(
  idToken: ?string,
  url: string,
  params?: Object,
  options?: RequestOptions
): Promise<any> {
  let fullUrl = url;
  if (params != null) {
    fullUrl += "?" + stringify(params);
  }

  return apiFetch(idToken, fullUrl, { method: "GET", ...options });
}

export function putJSON(
  idToken: ?string,
  url: string,
  body: Object,
  options?: RequestOptions
): Promise<any> {
  return apiFetch(idToken, url, {
    method: "PUT",
    ...sendJSONOptions(body),
    ...options
  });
}

export function postJSON(
  idToken: ?string,
  url: string,
  body: Object,
  options?: RequestOptions
): Promise<any> {
  return apiFetch(idToken, url, {
    method: "POST",
    ...sendJSONOptions(body),
    ...options
  });
}
