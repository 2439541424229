/* @flow */

export type Score = "correct" | "incorrect" | "missing";

import { flatten, sum, cloneDeep } from "lodash";

import DiffMatchPatch from "diff-match-patch";

type Diff = Array<[number, string]>;

const dmp = new DiffMatchPatch();

const { DIFF_DELETE, DIFF_INSERT, DIFF_EQUAL } = DiffMatchPatch;

// "goodness" is just a count of how much of the diff is "correct"
function goodness(input: Diff): number {
  return sum(
    input.map(chunk => {
      if (chunk[0] === DIFF_EQUAL) return chunk[1].length;
      else return 0;
    })
  );
}

type InputBackground = { character: string, correct: boolean };

export function inputBackgroundFromDiff(diff: Diff): Array<InputBackground> {
  return flatten(
    diff.map(([chunkType, string]) => {
      if (chunkType === DIFF_INSERT) {
        return [];
      } else {
        return Array.from(string).map(character => ({
          character,
          correct: chunkType === DIFF_EQUAL
        }));
      }
    })
  );
}

export function normalize(input: string): string {
  let step1;
  if (typeof input.normalize === "function") {
    /*
    From: https://stackoverflow.com/a/37511463/667069
      Two things are happening here:

      - normalize()ing to NFD Unicode normal form decomposes combined graphemes
        into the combination of simple ones. The è of Crème ends up expressed
         as e + ̀`.
      - Using a regex character class to match the U+0300 → U+036F range, it is
        now trivial to globally get rid of the diacritics, which the
        Unicode standard conveniently groups as the Combining Diacritical Marks
        Unicode block.
    From Jon:
      I added the normalize("NFC") call to recompose after we have filtered out
      diacritics. Without this, we won't be able to diff properly.

    This page is helpful in understanding whats going on:
      https://en.wikipedia.org/wiki/Combining_character
    */

    step1 = input
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .normalize("NFC");
  } else {
    step1 = input;
  }

  return step1
    .replace(/ß/g, 'B') // Otherwise, ß gets converted to 'SS' by toUpperCase (dimaugli@gmail.com)
    .replace(/[“”""]/g, '"') // normalize quotes
    .replace(/[`‘’]/g, "'") // normalize apostrophe
    .replace(/¡/g, "!") // normalize exclamation marks
    .toUpperCase();
}

export function diff(guess: string, answer: string): Diff {
  const regularDiff: Diff = dmp.diff_main(normalize(guess), normalize(answer));

  const cleanedDiff = cloneDeep(regularDiff);
  dmp.diff_cleanupSemantic(cleanedDiff);

  if (goodness(cleanedDiff) >= goodness(regularDiff)) {
    return cleanedDiff;
  } else {
    return regularDiff;
  }
}

export function judge(diff: Diff): Array<Score> {
  const results = [];
  function addResult(length, result) {
    results.push(Array(length).fill(result));
  }

  function matchTypePair(i, type1, type2) {
    return (
      diff.length > i + 1 && diff[i][0] === type1 && diff[i + 1][0] === type2
    );
  }

  for (let i = 0; i < diff.length; i++) {
    const [type, chunk] = diff[i];

    if (type === DIFF_EQUAL) {
      addResult(chunk.length, "correct");
    } else if (matchTypePair(i, DIFF_DELETE, DIFF_INSERT)) {
      const nextChunkLength = diff[i + 1][1].length;
      addResult(Math.min(chunk.length, nextChunkLength), "incorrect");
      if (nextChunkLength > chunk.length) {
        addResult(nextChunkLength - chunk.length, "missing");
      }
      // Increment the loop for one extra
      i++;
    } else if (type === DIFF_INSERT) {
      addResult(chunk.length, "missing");
    }
  }
  return flatten(results);
}
