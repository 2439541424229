/* @flow */
import * as React from "react";

import LoadingTextPlaceholder from "./LoadingTextPlaceholder";
import WithPromise from "./WithPromise";

type Props<T> = {
  promise: Promise<T> | null,
  then: T => string,
  width: number
};

export default function WithPromiseOfText<T>(props: Props<T>) {
  return (
    <WithPromise
      promise={props.promise}
      renderRejected={() => null}
      renderResolved={value => props.then(value)}
      renderPending={() => <LoadingTextPlaceholder width={props.width} />}
    />
  );
}

WithPromiseOfText.defaultProps = {
  then: x => x
};
