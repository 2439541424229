/* @flow */
import styled from "styled-components";

import { heightCss, allowPageHeaderOverlap } from "./playerGeometry";

import * as geometry from "./geometry";

/*
  This is just an empty div that is designed so that things can be layed out visibly below the
  player (which is fixed).
*/
export default styled.div`
  display: block;
  ${heightCss};
  padding-top: ${props => geometry.pageHeaderHeight + 10}px;

  @media ${allowPageHeaderOverlap} {
    padding-top: 10px;
  }
`;
