/* @flow */
// prettier-ignore-start

/*
DO NOT EDIT

This file was generated from routes.txt using the scripts/makeRoutes.js script.
*/

/*::
export type Route = {
    name: "root",
    params: {||},
} |
  {
    name: "show-video",
    params: {| videoId: string |},
} |
  {
    name: "show-favorites",
    params: {||},
} |
  {
    name: "edit-favorite",
    params: {| favoriteId: string |},
} |
  {
    name: "search",
    params: {||},
} |
  {
    name: "auth-callback",
    params: {||},
} |
  {
    name: "auth-error",
    params: {||},
} |
  {
    name: "about",
    params: {||},
} |
  {
    name: "terms-of-service",
    params: {||},
} |
  {
    name: "privacy-policy",
    params: {||},
} |
  {
    name: "video-library",
    params: {||},
} |
  {
    name: "account",
    params: {||},
} |
  {
    name: "change-password",
    params: {||},
} |
  {
    name: "premium-subscription",
    params: {||},
} |
  {
    name: "premium-splash",
    params: {||},
} |
  {
    name: "create-subtitle",
    params: {||},
} |
  {
    name: "cancel-subscription",
    params: {||},
} |
  {
    name: "search",
    params: {| targetLang: "ko" |},
} |
  {
    name: "search",
    params: {| targetLang: "ja" |},
} |
  {
    name: "search",
    params: {| targetLang: "en" |},
} |
  {
    name: "search",
    params: {| targetLang: "fr" |},
} |
  {
    name: "search",
    params: {| targetLang: "es" |},
} |
  {
    name: "search",
    params: {| targetLang: "es-419" |},
} |
  {
    name: "search",
    params: {| targetLang: "de" |},
} |
  {
    name: "search",
    params: {| targetLang: "zh-CN" |},
} |
  {
    name: "search",
    params: {| targetLang: "zh-HK" |},
} |
  {
    name: "search",
    params: {| targetLang: "it" |},
} |
  {
    name: "search",
    params: {| targetLang: "ru" |},
} |
  {
    name: "search",
    params: {| targetLang: "vi" |},
} |
  {
    name: "search",
    params: {| targetLang: "ar" |},
} |
  {
    name: "password-reset",
    params: {| code: string |},
} |
  {
    name: "oauth2-redirect",
    params: {| provider: string |},
} |
  {
    name: "blog-post",
    params: {| id: string |},
} |
  {
    name: "flash-cards-dashboard",
    params: {||},
} |
  {
    name: "flash-cards-splash",
    params: {||},
} |
  {
    name: "flash-cards-decks-new",
    params: {||},
} |
  {
    name: "flash-cards-decks-edit",
    params: {| id: string |},
} |
  {
    name: "flash-cards-review",
    params: {||},
} |
  {
    name: "admin",
    params: {||},
} |
  {
    name: "admin-playbacks",
    params: {||},
} |
  {
    name: "admin-curation-log",
    params: {||},
} |
  {
    name: "admin-demo-flash-cards",
    params: {||},
} |
  {
    name: "admin-favorite-captions",
    params: {||},
} |
  {
    name: "admin-library-items",
    params: {||},
} |
  {
    name: "admin-curated-content",
    params: {| language: string |},
} |
  {
    name: "admin-curated-content-details",
    params: {| channelId: string |},
} |
  {
    name: "screenshots",
    params: {||},
};
*/

function pathnameToRoute(path /*: string */) /*: (Route | null)*/ {
  let match;

  if ((match = path.match(/^\/(?:\/)?$/i))) {
    return { name: "root", params: (Object.freeze({}) /*: {||}*/) };
  }

  if ((match = path.match(/^\/videos\/([^\/]+?)(?:\/)?$/i))) {
    return { name: "show-video", params: { videoId: match[1] } };
  }

  if ((match = path.match(/^\/favorites(?:\/)?$/i))) {
    return { name: "show-favorites", params: (Object.freeze({}) /*: {||}*/) };
  }

  if ((match = path.match(/^\/favorites\/([^\/]+?)(?:\/)?$/i))) {
    return { name: "edit-favorite", params: { favoriteId: match[1] } };
  }

  if ((match = path.match(/^\/search(?:\/)?$/i))) {
    return { name: "search", params: (Object.freeze({}) /*: {||}*/) };
  }

  if ((match = path.match(/^\/callback(?:\/)?$/i))) {
    return { name: "auth-callback", params: (Object.freeze({}) /*: {||}*/) };
  }

  if ((match = path.match(/^\/authentication-error(?:\/)?$/i))) {
    return { name: "auth-error", params: (Object.freeze({}) /*: {||}*/) };
  }

  if ((match = path.match(/^\/about(?:\/)?$/i))) {
    return { name: "about", params: (Object.freeze({}) /*: {||}*/) };
  }

  if ((match = path.match(/^\/tos(?:\/)?$/i))) {
    return { name: "terms-of-service", params: (Object.freeze({}) /*: {||}*/) };
  }

  if ((match = path.match(/^\/privacy(?:\/)?$/i))) {
    return { name: "privacy-policy", params: (Object.freeze({}) /*: {||}*/) };
  }

  if ((match = path.match(/^\/video-library(?:\/)?$/i))) {
    return { name: "video-library", params: (Object.freeze({}) /*: {||}*/) };
  }

  if ((match = path.match(/^\/account(?:\/)?$/i))) {
    return { name: "account", params: (Object.freeze({}) /*: {||}*/) };
  }

  if ((match = path.match(/^\/change-password(?:\/)?$/i))) {
    return { name: "change-password", params: (Object.freeze({}) /*: {||}*/) };
  }

  if ((match = path.match(/^\/premium-subscription(?:\/)?$/i))) {
    return {
      name: "premium-subscription",
      params: (Object.freeze({}) /*: {||}*/)
    };
  }

  if ((match = path.match(/^\/premium-splash(?:\/)?$/i))) {
    return { name: "premium-splash", params: (Object.freeze({}) /*: {||}*/) };
  }

  if ((match = path.match(/^\/create-subtitle(?:\/)?$/i))) {
    return { name: "create-subtitle", params: (Object.freeze({}) /*: {||}*/) };
  }

  if ((match = path.match(/^\/cancel-subscription(?:\/)?$/i))) {
    return {
      name: "cancel-subscription",
      params: (Object.freeze({}) /*: {||}*/)
    };
  }

  if ((match = path.match(/^\/korean(?:\/)?$/i))) {
    return { name: "search", params: { targetLang: "ko" } };
  }

  if ((match = path.match(/^\/japanese(?:\/)?$/i))) {
    return { name: "search", params: { targetLang: "ja" } };
  }

  if ((match = path.match(/^\/english(?:\/)?$/i))) {
    return { name: "search", params: { targetLang: "en" } };
  }

  if ((match = path.match(/^\/french(?:\/)?$/i))) {
    return { name: "search", params: { targetLang: "fr" } };
  }

  if ((match = path.match(/^\/spanish(?:\/)?$/i))) {
    return { name: "search", params: { targetLang: "es" } };
  }

  if ((match = path.match(/^\/spanish-419(?:\/)?$/i))) {
    return { name: "search", params: { targetLang: "es-419" } };
  }

  if ((match = path.match(/^\/german(?:\/)?$/i))) {
    return { name: "search", params: { targetLang: "de" } };
  }

  if ((match = path.match(/^\/chinese(?:\/)?$/i))) {
    return { name: "search", params: { targetLang: "zh-CN" } };
  }

  if ((match = path.match(/^\/chinese-hk(?:\/)?$/i))) {
    return { name: "search", params: { targetLang: "zh-HK" } };
  }

  if ((match = path.match(/^\/italian(?:\/)?$/i))) {
    return { name: "search", params: { targetLang: "it" } };
  }

  if ((match = path.match(/^\/russian(?:\/)?$/i))) {
    return { name: "search", params: { targetLang: "ru" } };
  }

  if ((match = path.match(/^\/vietnamese(?:\/)?$/i))) {
    return { name: "search", params: { targetLang: "vi" } };
  }

  if ((match = path.match(/^\/arabic(?:\/)?$/i))) {
    return { name: "search", params: { targetLang: "ar" } };
  }

  if ((match = path.match(/^\/password-resets\/([^\/]+?)(?:\/)?$/i))) {
    return { name: "password-reset", params: { code: match[1] } };
  }

  if ((match = path.match(/^\/oauth2-redirect\/([^\/]+?)(?:\/)?$/i))) {
    return { name: "oauth2-redirect", params: { provider: match[1] } };
  }

  if ((match = path.match(/^\/blogs\/([^\/]+?)(?:\/)?$/i))) {
    return { name: "blog-post", params: { id: match[1] } };
  }

  if ((match = path.match(/^\/flash-cards(?:\/)?$/i))) {
    return {
      name: "flash-cards-dashboard",
      params: (Object.freeze({}) /*: {||}*/)
    };
  }

  if ((match = path.match(/^\/flash-cards\/splash(?:\/)?$/i))) {
    return {
      name: "flash-cards-splash",
      params: (Object.freeze({}) /*: {||}*/)
    };
  }

  if ((match = path.match(/^\/flash-cards\/decks\/new(?:\/)?$/i))) {
    return {
      name: "flash-cards-decks-new",
      params: (Object.freeze({}) /*: {||}*/)
    };
  }

  if ((match = path.match(/^\/flash-cards\/decks\/([^\/]+?)(?:\/)?$/i))) {
    return { name: "flash-cards-decks-edit", params: { id: match[1] } };
  }

  if ((match = path.match(/^\/flash-cards\/review(?:\/)?$/i))) {
    return {
      name: "flash-cards-review",
      params: (Object.freeze({}) /*: {||}*/)
    };
  }

  if ((match = path.match(/^\/admin(?:\/)?$/i))) {
    return { name: "admin", params: (Object.freeze({}) /*: {||}*/) };
  }

  if ((match = path.match(/^\/admin\/playbacks(?:\/)?$/i))) {
    return { name: "admin-playbacks", params: (Object.freeze({}) /*: {||}*/) };
  }

  if ((match = path.match(/^\/admin\/curation-log(?:\/)?$/i))) {
    return {
      name: "admin-curation-log",
      params: (Object.freeze({}) /*: {||}*/)
    };
  }

  if ((match = path.match(/^\/admin\/demo-flash-cards(?:\/)?$/i))) {
    return {
      name: "admin-demo-flash-cards",
      params: (Object.freeze({}) /*: {||}*/)
    };
  }

  if ((match = path.match(/^\/admin\/favorite-captions(?:\/)?$/i))) {
    return {
      name: "admin-favorite-captions",
      params: (Object.freeze({}) /*: {||}*/)
    };
  }

  if ((match = path.match(/^\/admin\/library-items(?:\/)?$/i))) {
    return {
      name: "admin-library-items",
      params: (Object.freeze({}) /*: {||}*/)
    };
  }

  if ((match = path.match(/^\/admin\/curated-content\/([^\/]+?)(?:\/)?$/i))) {
    return { name: "admin-curated-content", params: { language: match[1] } };
  }

  if (
    (match = path.match(
      /^\/admin\/curated-content\/details\/([^\/]+?)(?:\/)?$/i
    ))
  ) {
    return {
      name: "admin-curated-content-details",
      params: { channelId: match[1] }
    };
  }

  if ((match = path.match(/^\/__screenshots__(?:\/)?$/i))) {
    return { name: "screenshots", params: (Object.freeze({}) /*: {||}*/) };
  }

  return null;
}

module.exports = { pathnameToRoute };

// prettier-ignore-end
