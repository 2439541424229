/* @flow */

import * as React from "react";

type Props = {
  onKeyDown: KeyboardEvent => void
};

export default class WithDocumentEvents extends React.Component<Props> {
  constructor() {
    super();
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onKeyDown: KeyboardEvent => void;

  onKeyDown(event: KeyboardEvent) {
    this.props.onKeyDown(event);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown);
  }

  render() {
    return null;
  }
}
