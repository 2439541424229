/* @flow */

import * as React from "react";
import styled from "styled-components";

import * as geometry from "./geometry";
import { YouTubeIframeApiStatus } from "./useScriptTag";

import { defaults } from "lodash";

import {
  widthCss,
  heightCss,
  triggerAspectRatio,
  widthCssFromHeight,
  maxHeightPercentage,
  playerZIndex
} from "./playerGeometry";

type Props = {
  videoId: string,
  onPlayerStateChange?: YT$StateEvent => void,
  onPlayerReady: YT$Event => void,
  onPlayerDestroy?: () => void,
  playerVars: YT$PlayerVars,
  children?: React.Node,
  startSeconds?: number,
  endSeconds?: number,
  className?: string
};

export const domID = "player-container";

export function PlayerContainer(props: Props) {
  const [divEl, setDivEl] = React.useState<HTMLElement | null>(null);

  // Save refs to the latest callbacks
  const onPlayerReadyRef = React.useRef<(YT$StateEvent) => void>(
    props.onPlayerReady
  );
  const { onPlayerReady, onPlayerStateChange, onPlayerDestroy } = props;
  React.useEffect(() => {
    onPlayerReadyRef.current = onPlayerReady;
  }, [onPlayerReady]);

  // onPlayerStateChange ref
  const onPlayerStateChangeRef = React.useRef<?(YT$StateEvent) => void>(
    props.onPlayerStateChange
  );
  React.useEffect(() => {
    onPlayerStateChangeRef.current = onPlayerStateChange;
  }, [onPlayerStateChange]);

  // onPlayerDestroy ref
  const onPlayerDestroyRef = React.useRef<?() => void>(props.onPlayerDestroy);
  React.useEffect(() => {
    onPlayerDestroyRef.current = onPlayerDestroy;
  }, [onPlayerDestroy]);

  // Save a ref to the first player options
  const options = React.useRef(playerInitOptions(props));

  const apiStatus = React.useContext(YouTubeIframeApiStatus);

  React.useEffect(() => {
    if (divEl != null && apiStatus == "loaded") {
      // YTPlayer will convert this into an IFRAME.
      const iframeEl = document.createElement("div");
      divEl.appendChild(iframeEl);

      const events = {
        onStateChange: event => {
          if (onPlayerStateChangeRef.current != null) {
            onPlayerStateChangeRef.current(event);
          }
        },
        onReady: event => {
          onPlayerReadyRef.current(event);
        }
      };

      const optionsWithEvents = {
        ...options.current,
        events
      };

      const player = new YT.Player(iframeEl, optionsWithEvents);

      return () => {
        player.destroy();
        if (onPlayerDestroyRef.current != null) {
          onPlayerDestroyRef.current();
        }
      };
    }
  }, [divEl, apiStatus]);

  return (
    <div id={domID} ref={setDivEl} className={props.className}>
      {props.children}
    </div>
  );
}

function playerInitOptions(props: Props) {
  // For some reason, the timing vars in the constructor need to be
  // rounded to an integer, or they won't work.
  const timingVars = {};
  if (typeof props.startSeconds === "number") {
    timingVars.start = Math.floor(props.startSeconds);
    // The player doesn't seem to start if start is set to zero, so round up to 1.
    // For example, see favorite 896. _0AD9qtHQeo 0.777-1.535
    if (timingVars.start === 0) {
      timingVars.start = 1;
    }
  }
  if (typeof props.endSeconds === "number") {
    timingVars.end = Math.ceil(props.endSeconds);
  }

  let origin;
  if (typeof document == "object") {
    origin = document.location.origin;
  } else {
    // For the sake of server side rendering
    origin = "https://www.captionpop.com";
  }

  const playerVars = defaults(
    { origin },
    props.playerVars,
    defaultPlayerVars,
    timingVars
  );

  return {
    videoId: props.videoId,
    width: "100%",
    height: "100%",
    playerVars: playerVars
  };
}

const defaultPlayerVars = {
  //cc_load_policy: 0, // The only valid option for this is 1
  iv_load_policy: 3,
  controls: 1,
  autoplay: 0,
  modestbranding: 1,
  rel: 0,
  showinfo: 0,
  fs: 0,
  playsinline: 1,
  disablekb: 1
};

export const PlayerContainerWithScreenConstraints: typeof PlayerContainer = styled(
  PlayerContainer
)`
  ${widthCss} ${heightCss} box-shadow: 2px 2px 5px #888;
  background-color: white;
`;

export const FixedPlayerContainer: typeof PlayerContainer = styled(
  PlayerContainerWithScreenConstraints
)`
  z-index: ${playerZIndex};

  // In this case, the player takes up the entire width
  left: 0;

  // If we are constrained by height, we need to recalculate the width to
  // keep the aspect ratio
  @media (min-aspect-ratio: ${triggerAspectRatio}) {
    left: 50%;
    margin-left: ${widthCssFromHeight(`-${maxHeightPercentage}vh/2`)};
  }

  // If there is extra space, center the video using its natural width
  @media (min-width: ${geometry.videoWidth}px) and (min-height: ${geometry.videoHeight *
      (100 / maxHeightPercentage)}px) {
    left: 50%;
    margin-left: ${-geometry.videoWidth / 2}px;
  }
`;
