var en = function(n, ord
) {
  var s = String(n).split('.'), v0 = !s[1], t0 = Number(s[0]) == n,
      n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2);
  if (ord) return (n10 == 1 && n100 != 11) ? 'one'
      : (n10 == 2 && n100 != 12) ? 'two'
      : (n10 == 3 && n100 != 13) ? 'few'
      : 'other';
  return (n == 1 && v0) ? 'one' : 'other';
};
var number = function (value, name, offset) {
  if (!offset) return value;
  if (isNaN(value)) throw new Error("Can't apply offset:" + offset + ' to argument `' + name + '` with non-numerical value ' + JSON.stringify(value) + '.');
  return value - offset;
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

module.exports = {
  about_page: {
    headline: function(d) { return "CaptionPop es la mejor forma de experimentar con YouTube como aprendiz de idiomas."; },
    feature_list: {
      "0": function(d) { return "Observa el video con varios subtítulos a la vez."; },
      "1": function(d) { return "Repite la última oración con tan solo una tecla."; },
      "2": function(d) { return "Disminuye la velocidad del video."; },
      "3": function(d) { return "Referencia los subtítulos para que puedas examinarlos posteriormente."; },
      "4": function(d) { return "Compatible con cualquier video de YouTube que tenga subtítulos."; }
    },
    get_started_header: function(d) { return "Comenzar"; },
    leave_feedback_header: function(d) { return "¡Mantente en contacto!"; },
    leave_feedback_prompt: function(d) { return "¿Quieres que te notifiquemos las actualizaciones o dejar un comentario?"; },
    leave_feedback_action: function(d) { return "¡Di hola!"; },
    find_videos_action: function(d) { return "Encuentra videos"; },
    closing_paragraph: function(d) { return "Cree CaptionPop para ayudarme a mejorar mis propias habilidades de comprensión auditiva en coreano. Me ha ayudado mucho y espero que también te ayude a ti."; },
    postscript: function(d) { return "P.D.: si tienes alguna pregunta, comentario o si quieres solicitar alguna característica, envíame un correo electrónico a cualquier hora. Me gusta mucho conversar con personas que estudian algún idioma."; },
    get_started: {
      target_select_prompt: function(d) { return "¿No encuentra el idioma que desea aprender? CaptionPop funciona con cualquiera de los " + d.LENGTH + " idiomas usados en YouTube. ¡Seleccione uno a continuación!"; },
      target_select_placeholder: function(d) { return "Seleccione cualquier idioma"; },
      my_native_language_is: function(d) { return "Mi lengua materna es LANGUAGE y quiero aprender:"; }
    }
  },
  choose_languages: {
    select_native_lang_prompt: function(d) { return "¿Cuál es tu lengua materna?"; },
    select_target_lang_prompt: function(d) { return "¿Qué lenguaje deseas aprender?"; },
    update_action: function(d) { return "Actualización"; },
    featured_languages: function(d) { return "Idiomas principales"; },
    all_languages: function(d) { return "Todos los idiomas"; },
    select_placeholder: function(d) { return "Selecciona algún marcador..."; }
  },
  play_videos_page: {
    add_to_library_action: function(d) { return "Añade a la Librería"; },
    remove_from_library_action: function(d) { return "Quita de la Librería"; },
    library_icon_label: function(d) { return "Librería"; },
    no_available_subtitles: function(d) { return "Lamentablemente, no hay subtítulos disponibles para este video."; },
    translation_upgrade_prompt: function(d) { return "Con CaptionPop Premium, cualquier palabra o frase que resaltas va ser instataneamente traducido con Google Translate."; },
    translation_upgrade_call_to_action: function(d) { return "Actualizar"; },
    blur_captions_label: function(d) { return "Borrar subtítulos"; },
    pause_after_caption_label: function(d) { return "Pausar después de cada subtítulo"; },
    only_one_subtitle_language: function(d) { return "Este video solo presenta subtítulos en " + d.LANGUAGE + "."; }
  },
  favorites_page: {
    blur_captions_label: function(d) { return "Borrar " + d.LANGUAGE + " subtítulos"; }
  },
  search_page: {
    page_title: function(d) { return "Busca videos de YouTube en " + d.TARGET_LANG; },
    meta_description: function(d) { return "Busca videos de YouTube con subtítulos en " + d.NATIVE_LANG + " y " + d.TARGET_LANG + ". Ve ambos subtítulos al mismo tiempo."; },
    meta_description_generic: function(d) { return "Busca videos de YouTube con múltiples subtítulos. Ve ambos subtítulos al mismo tiempo."; },
    filter_header: function(d) { return "Filtrar según idioma del subtítulo. "; },
    show_all: function(d) { return "Mostrar todo"; },
    more_action: function(d) { return "Más"; },
    end_of_results: function(d) { return "Fin de los resultados"; },
    empty_search_results: {
      no_videos: function(d) { return "No se encontró ningún video."; },
      singular_video_dual_filter: function(d) { return "1 El video fue ubicado, pero no presenta subtítulos en " + d.FILTER_LANGUAGE_A + " y en " + d.FILTER_LANGUAGE_B + "."; },
      singular_video_single_filter: function(d) { return "1 El video fue ubicado, pero no presenta subtítulos en " + d.FILTER_LANGUAGE + "."; },
      plural_videos_single_filter: function(d) { return d.VIDEO_COUNT + " Los videos fueron ubicados, pero ninguno presenta subtítulos en " + d.FILTER_LANGUAGE + "."; },
      plural_videos_dual_filter: function(d) { return d.VIDEO_COUNT + " Los videos fueron ubicados, pero ninguno presenta subtítulos en " + d.FILTER_LANGUAGE_A + " y en " + d.FILTER_LANGUAGE_B + "."; }
    }
  },
  search_form: {
    search_action: function(d) { return "Buscar"; },
    change_language_link: function(d) { return "cambiar de idioma"; },
    search_header: function(d) { return "Buscar todos los vídeos en YouTube"; },
    filter_by_subtitle_header: function(d) { return "filtrar por subtítulo"; },
    filter_by_city_header: function(d) { return "filtrar por ciudad"; },
    tutorials_header: function(d) { return "Tutoriales de CaptionPop"; },
    placeholder: function(d) { return "Buscar término en " + d.LANGUAGE + " o URL de YouTube"; },
    featured_playlists_header: function(d) { return "Navegar listas de reproducción destacadas en " + d.LANGUAGE; },
    featured_channels_header: function(d) { return "Navegar canales destacados en " + d.LANGUAGE; },
    suggested_cities: function(d) { return "Ciudades sugeridas"; },
    all_cities: function(d) { return "Todas las ciudades"; },
    tutorials_caption: function(d) { return "¿Necesitas ayuda para empezar?"; },
    premium_cia: function(d) { return "Acelera tu proceso de aprendizaje con CaptionPop Premium."; },
    premium_button: function(d) { return "Registrate por Premium"; },
    chrome_extension_banner: {
      header: function(d) { return "Get the CaptionPop experience while browsing videos on YouTube.com."; },
      line1: function(d) { return "Install the CaptionPop browser extension for Chrome."; },
      line2: function(d) { return "After installing, each video on YouTube will be shown with a list of subtitles that are available for that video. Clicking on the subtitle list will link you to the CaptionPop player, so you can watch with multiple subtitles at once."; },
      install_action: function(d) { return "Install"; }
    }
  },
  keyboard_shortcuts: {
    next_line_tooltip: function(d) { return "Próxima línea"; },
    previous_line_tooltip: function(d) { return "Línea anterior"; },
    toggle_playback_tooltip: function(d) { return "Reproducir / Detener"; },
    show_translation_tooltip: function(d) { return "Mostrar traducción"; },
    repeat_tooltip: function(d) { return "Repetir"; }
  },
  page_header: {
    home_action: function(d) { return "Inicio"; },
    login_action: function(d) { return "Iniciar sesión"; },
    logout_action: function(d) { return "Cerrar sesión"; },
    premium_action: function(d) { return "Hacerse Premium"; }
  },
  page_footer: {
    about_link: function(d) { return "Acerca de"; },
    contact_link: function(d) { return "Contactar"; },
    terms_of_service_link: function(d) { return "Términos del servicio"; },
    privacy_policy_link: function(d) { return "Política de privacidad"; }
  },
  page_not_found: function(d) { return "Página no encontrada"; },
  caption_popup_options: {
    edit: function(d) { return "Modificar subtítulo"; },
    jump_to: function(d) { return "Saltar al original"; },
    remove: function(d) { return "Eliminar de Favoritos"; }
  },
  edit_caption_form: {
    header: function(d) { return "Modificar subtítulo"; },
    labels: {
      start_time: function(d) { return "tiempo inicial"; },
      end_time: function(d) { return "tiempo final"; },
      transcription: function(d) { return "transcripción"; },
      translations: function(d) { return "traducciones"; }
    },
    actions: {
      save_changes: function(d) { return "Guardar cambios"; },
      nevermind: function(d) { return "Revertir cambios"; }
    }
  },
  form_validations: {
    required: function(d) { return "obligatorio"; },
    too_big: function(d) { return "debe ser antes del final del vídeo"; },
    comes_after_start: function(d) { return "debe aparecer después del tiempo inicial"; }
  },
  navigation: {
    video_library_link: function(d) { return "Librería de Vídeos"; },
    favorite_captions_link: function(d) { return "Subtítulos Favoritos"; },
    account_link: function(d) { return "Cuenta"; },
    flash_cards_link: function(d) { return "Tarjetas de estudio"; }
  },
  snackbar: {
    video_added_to_library: function(d) { return "Este vídeo fue añadido a tu librería de vídeos."; },
    video_removed_from_library: function(d) { return "Este vídeo fue eliminado de tu librería de vídeos."; },
    added_to_favorite_captions: function(d) { return "El subtítulo fue añadido a tu lista de subtítulos favoritos."; },
    removed_from_favorite_captions: function(d) { return "El subtítulo fue eliminado de tu lista de subtítulos favoritos."; },
    updated_favorite_caption: function(d) { return "Tus cambios fueron guardados."; },
    network_error: function(d) { return "Revisa su connexión del internet."; },
    playlist_added_to_library: function(d) { return "Esta lista de reproducción ha sido añadido a su biblioteca."; },
    playlist_removed_from_library: function(d) { return "Esta lista de reproducción ha sido eliminado de su biblioteca."; },
    channel_added_to_library: function(d) { return "Este canal ha sido añadido a su biblioteca."; },
    channel_removed_from_library: function(d) { return "Este canal ha sido eliminado de su biblioteca."; }
  },
  flash_cards: {
    header: function(d) { return "Tarjetas de estudio"; },
    days_count: function(d) { return plural(d.COUNT, 0, en, { one: "1 día", other: number(d.COUNT, "COUNT") + " días" }); },
    logged_out_warning: function(d) { return "Inicia sesión en CaptionPop para guardar tu progreso. De lo contrario, este se perderá cuando cierres tu navegador."; },
    splash_page: {
      title: function(d) { return "CaptionPop: Tarjetas de estudio interactivas"; },
      introduction: function(d) { return "Mejore su comprensión auditiva con tarjetas interactivas."; },
      feature_list: {
        "0": function(d) { return "Revisa las tarjetas basadas en los subtítulos que has guardado de los videos de YouTube."; },
        "1": function(d) { return "Obtenga comentarios codificados por colores a medida que escribe."; },
        "2": function(d) { return "Pida pistas para guiarlo cuando se encuentre atascado."; },
        "3": function(d) { return "Las tarjetas se anotan automáticamente"; },
        "4": function(d) { return "Un algoritmo de repetición espaciado programará la tarjeta para ser revisada nuevamente en el futuro."; }
      },
      calls_to_action: {
        header: function(d) { return "¿Listo para comenzar?"; },
        demo_decks_text: function(d) { return "Prueba uno de estos mazos de tarjetas de estudio."; },
        no_favorites: {
          text: function(d) { return "O crea tu propio mazo a partir de subtítulos que encuentres en Youtube."; },
          button: function(d) { return "Buscar videos"; }
        },
        logged_in_with_favorites: {
          text: function(d) { return plural(d.COUNT, 0, en, { one: "Ya ha guardado " + number(d.COUNT, "COUNT") + " subtítulo de Youtube. ¡Puede revisarlo ahora como tarjeta interactiva!", other: "Ya ha guardado " + number(d.COUNT, "COUNT") + " subtítulos de Youtube. ¡Puede revisarlos ahora como tarjetas interactivas!" }); },
          button: function(d) { return "Revisar ahora"; }
        }
      }
    },
    instructions: {
      finish_pass: function(d) { return "Inténtalo más tarde."; },
      finish_complete: function(d) { return "¡Buen trabajo!"; },
      playing_state: function(d) { return "Escucha con atención."; },
      quiz_state: function(d) { return "Escribe lo que escuchaste."; },
      before_playback: function(d) { return "Dele play y escuche"; }
    },
    hint_tip: function(d) { return "Haga clic en los cuadros grises para obtener consejos"; },
    actions: {
      skip: function(d) { return "Saltar"; },
      finish: function(d) { return "Terminar"; },
      more_to_review: function(d) { return d.COUNT + " más para revisar"; },
      replay: function(d) { return "Repetir"; }
    },
    previous_attempts_list: {
      header: function(d) { return "Intentos anteriores"; },
      hints_used: function(d) { return "Pistas utilizadas"; },
      time_takes: function(d) { return "Tiempo "; },
      score: function(d) { return "Puntaje"; }
    },
    finished: {
      edit_action: function(d) { return "Modificar"; },
      previous_attempts_header: function(d) { return "Intentos anteriores"; },
      time_taken: function(d) { return "Tiempo"; },
      hints_used: function(d) { return "Pistas utilizadas"; },
      due_again: function(d) { return "Lista otra vez"; },
      score: function(d) { return "Puntaje"; }
    },
    dashboard: {
      header: function(d) { return "Tarjetas"; },
      card_count: function(d) { return d.REVIEWED_COUNT + " de " + plural(d.TOTAL_COUNT, 0, en, { one: "1 ha sido revisada", other: number(d.TOTAL_COUNT, "TOTAL_COUNT") + " han sido revisadas" }) + " recientemente."; },
      next_card_due: function(d) { return "La siguiente tarjeta estará lista para ser revisada en " + d.TIMER + "."; },
      empty_deck: function(d) { return "La baraja está vacía."; },
      actions: {
        review_all: function(d) { return "Revisar Todas"; },
        review_random: function(d) { return "Revise una tarjeta aleatoria"; },
        review_count: function(d) { return "Revisar " + d.COUNT; },
        create_custom_deck: function(d) { return "Crear una baraja personalizada"; },
        edit_deck: function(d) { return "Editar baraja"; },
        review_deck: function(d) { return "Revisar"; },
        search: function(d) { return "Busca nuevos vídeos"; }
      },
      saved_captions_deck: function(d) { return "Subtítulos guardados de YouTube"; },
      free_demo_deck_header: function(d) { return "Prueba gratis tarjetas de estudio"; },
      custom_deck_header: function(d) { return "Tarjetas de estudio personalizadas"; },
      tip_header: function(d) { return "Consejo"; },
      search_videos_tip: function(d) { return "Puedes crear tarjetas de estudio de cualquier video en CaptionPop."; },
      custom_deck_tip: function(d) { return "Puedes organizar sus subtitulos a cubiertas de estudios personalizados."; },
      login_tip: function(d) { return "Inicia su sesion de su CaptionPop cuenta para crear tarjetas de estudio de cualquier video en YouTube."; }
    },
    form: {
      create_header: function(d) { return "Crear una nueva baraja de tarjetas"; },
      update_header: function(d) { return "Actualizar la baraja de tarjetas"; },
      name_of_deck_header: function(d) { return "Nombre de la baraja"; },
      select_captions_header: function(d) { return "Seleccionar subtítulos"; },
      selected_count: function(d) { return plural(d.COUNT, 0, en, { one: "1 seleccionado", other: number(d.COUNT, "COUNT") + " seleccionados" }); },
      select_multiple_captions: function(d) { return "Seleccionar " + plural(d.COUNT, 0, en, { one: "1 subtítulo", other: number(d.COUNT, "COUNT") + " subtítulos" }); },
      unselect_multiple_captions: function(d) { return "Deseleccionar " + d.COUNT + " subtítulo / Deseleccionar " + d.COUNT + " subtítulos"; },
      actions: {
        update: function(d) { return "Actualizar baraja"; },
        create: function(d) { return "Crear baraja"; },
        "delete": function(d) { return "Eliminar baraja"; }
      },
      confirmation: function(d) { return "¿Está seguro?"; },
      snackbar: {
        created: function(d) { return "Su nueva baraja ha sido creada."; },
        deleted: function(d) { return "Su baraja ha sido eliminada."; },
        updated: function(d) { return "Su baraja se ha actualizado."; },
        error: function(d) { return "Hubo un error al actualizar su baraja."; }
      }
    },
    paywall_overlay: {
      header: function(d) { return "Su prueba gratuita ha terminado por hoy."; },
      text: function(d) { return "Espere a que el temporizador se reinicie o mejore a CaptionPop Premium para obtener acceso ilimitado."; },
      call_to_action: function(d) { return "Actualizar ahora"; }
    },
    free_trial_banner: {
      flash_cards_remaining: function(d) { return "Tiene " + plural(d.COUNT, 0, en, { one: "1 restante", other: number(d.COUNT, "COUNT") + " restantes" }) + " en su prueba gratis hoy."; },
      upgrade_action: function(d) { return "Mejorar"; }
    },
    premium_subscription: {
      header: function(d) { return "Suscribirse a CaptionPop Premium"; },
      available_currencies: function(d) { return "También disponible en"; },
      product_description: function(d) { return "Mejore sus habilidades de comprensión auditiva con tarjetas flash interactivas construidas a partir de sus videos favoritos de YouTube."; },
      form_headers: {
        plan: function(d) { return "Elija un plan"; },
        credit_card: function(d) { return "Tarjetas de crédito"; },
        create_an_account: function(d) { return "Crea una cuenta "; }
      },
      calls_to_action: {
        choose: function(d) { return "Elegir"; },
        purchase: function(d) { return "Comprar"; }
      },
      plans: {
        month: {
          title: function(d) { return "Mensual"; },
          description: function(d) { return "Facturado mensualmente. ¡El más barato!"; },
          long_description: function(d) { return "Cuando haces clic en su compra, su carta de credito va ser cobrada " + d.AMOUNT + " hoy,y luego " + d.AMOUNT + " cada mes en esta fecha. Puedes cancelar cuando quieras con un solo clic."; }
        },
        year: {
          title: function(d) { return "Anual"; },
          description: function(d) { return "Facturado anualmente. ¡Ahorre " + d.PERCENT + "% más sobre el mensual!"; },
          long_description: function(d) { return "Cuando haces clic en su compra, su carta de credito va ser cobrada " + d.AMOUNT + " hoy, y luego " + d.AMOUNT + " cada ano en esta fecha. Puedes cancelar cuando quieras con un solo clic."; }
        },
        lifetime: {
          title: function(d) { return "Toda la vida"; },
          description: function(d) { return "Facturado mensualmente. ¡El más barato!"; },
          long_description: function(d) { return "Cuando haces clic en su compra, su carta de credito va ser cobrada " + d.AMOUNT + " hoy, y luego nunca más."; }
        }
      },
      confirmation: {
        header: function(d) { return "¡Gracias!"; },
        paragraph1: function(d) { return "¡Se ha registrado a<b>CaptionPop Premium!</b>"; },
        paragraph2: function(d) { return "Ahora tiene acceso ilimitado a las tarjetas flash interactivas de repetición espaciada de CaptionPop. Esta función me ha ayudado personalmente a mejorar mi capacidad de comprensión auditiva, y espero que también lo ayude a usted."; },
        paragraph3: function(d) { return "Puede cancelar su suscripción en cualquier momento desde su <a href=\"/account\">cuenta</a>."; },
        creator_title: function(d) { return "Creador de CaptionPop"; },
        call_to_action: function(d) { return "Tarjetas"; },
        videos_action: function(d) { return "Mirar Videos"; },
        flash_cards_action: function(d) { return "Estudiar tarjetas de estudio"; }
      },
      errors: {
        banner_header: function(d) { return "Su compra no podía ser cumplida."; },
        network_error: function(d) { return "Su compra no podía ser cumplida por un problema con la conexión del internet."; },
        unexpected_error: function(d) { return "Había un problema inesperado cumpliendo su compra. Su tarjeta de credito no fue cobrada. El administrador de CaptionPop ha sido notificado. Por favor intentalo de nuevo más tarde."; }
      },
      account: {
        choose_password: function(d) { return "Elige una contraseña"; },
        provider_signup: function(d) { return "o registrate con "; },
        already_have_account: function(d) { return "¿Ya tiene una cuenta?"; },
        logged_in_as: function(d) { return "Estas conectado como " + d.EMAIL + ". "; },
        not_you: function(d) { return "Cuenta equivocada?"; }
      }
    }
  },
  premium_splash_page: {
    page_title: function(d) { return "Suscribase a CaptionPop Premium"; },
    header1: function(d) { return "Acelera su proceso de aprendizaje con estos características avanzadas!"; },
    free_column_header: function(d) { return "Gratis"; },
    premium_column_header: function(d) { return "Premium"; },
    feature1: function(d) { return "Mira con subtitulos multiples"; },
    feature2: function(d) { return "Filtra buscadas de Youtube por lenguaje de subtitulos"; },
    feature3: function(d) { return "Resalta el texto para traducir"; },
    feature4: function(d) { return "Tarjetas de estudio interactivas"; },
    free_flash_card_limit: function(d) { return "5 por dia"; },
    header2: function(d) { return "Resalta el texto para traducir."; },
    google_translate_description: function(d) { return "Resalta cualqier palabra o frase para traducirlo a su idioma materna. Traducciones estan hechas por Google Translate."; },
    header3: function(d) { return "Tarjetas de estudio de comprensión auditiva ilimitada."; },
    call_to_action: function(d) { return "Registrate por Premium"; }
  },
  login_form: {
    favorite_prompt: function(d) { return "Por favor inicia la sesión para que puedas guardar tus subtítulos favoritos."; },
    placeholders: {
      email: function(d) { return "Correo Electrónico"; },
      current_password: function(d) { return "Contraseña actual"; },
      password: function(d) { return "Contraseña"; },
      password_confirmation: function(d) { return "Confirmación de contraseña"; }
    },
    forgot_password_link: function(d) { return "Olvido su contraseña?"; },
    or: function(d) { return "O"; },
    actions: {
      login: function(d) { return "Iniciar Sesión"; },
      signup: function(d) { return "Regístrate"; },
      login_with_provider: function(d) { return "Iniciar Sesion con " + d.PROVIDER; },
      signup_with_provider: function(d) { return "Regístráte con " + d.PROVIDER; }
    },
    errors: {
      invalid_credentials: function(d) { return "Correo Electrónica o Contraseña Incorrecta."; },
      email_conflict: function(d) { return "Una cuenta con este correo electrónico ya existe."; },
      unexpected_login_api_error: function(d) { return "Había un problema iniciando la sesión."; },
      unexpected_signup_api_error: function(d) { return "Había un problema registrándose."; },
      network_error: function(d) { return "Revisa su connexión del internet."; }
    },
    library_prompt: function(d) { return "Por favor inicia su sesión para poder guardar articulos en su biblioteca."; }
  },
  password_reset_form: {
    instructions: function(d) { return "Por favor introduce su correo electrónico. Instrucciones para recuperar su cuenta van a ser enviadas a su correo electrónico."; },
    actions: {
      send_email: function(d) { return "Enviar correo electrónico"; },
      ok: function(d) { return "OK"; }
    },
    finished_status: {
      instructions_sent: function(d) { return "Las Instrucciones han sido enviadas a " + d.EMAIL_ADDRESS + "."; },
      rate_limited: function(d) { return "Las Instrucciones ya han sido enviadas a  " + d.EMAIL_ADDRESS + ". Por favor espere por lo menos una hora antes de probar otra ves."; },
      network_error: function(d) { return "Habia un problema conectando al servidor informático. Por favor revisa su conexión del internet."; },
      delivery_error: function(d) { return "Habia un problema enviando instrucciones a " + d.EMAIL_ADDRESS + ". Por favor confirma que este es un correo electrónico válido."; }
    }
  },
  password_reset_invalid_page: {
    header: function(d) { return "Contraseña invalido reinicia el enlace."; },
    text: function(d) { return "Enlaces para reiniciar su contraseña nomas son validos por 24 horas."; },
    actions: {
      home: function(d) { return "Página Principal"; },
      request_new: function(d) { return "Solicita por un nuevo enlace"; }
    }
  },
  change_password_page: {
    password_reset_instructions: function(d) { return "Por favor elige una contraseña nueva para recuperar acceso a su cuenta."; },
    change_password_header: function(d) { return "Cambia su contraseña."; },
    change_password_action: function(d) { return "Cambia contraseña."; },
    password_updated_confirmation: function(d) { return "Su contraseña ha sido actualizada."; },
    network_error: function(d) { return "Su contraseña no pudo ser actualizada por un problema con la conexión del internet."; }
  },
  account_snackbar_messages: {
    logged_in: function(d) { return "Estas conectado"; },
    logged_out: function(d) { return "Estas desconectado"; },
    signed_up: function(d) { return "Su cuenta ha sido creada."; },
    subscription_cancelled: function(d) { return "Su subscripcion ha sido cancelada."; },
    subscription_reactivated: function(d) { return "Su subscripcion ha sido reactivada."; }
  },
  library_page: {
    search_action: function(d) { return "Busca nuevos vídeos"; },
    headers: {
      playlists: function(d) { return "Listas de reproducción"; },
      channels: function(d) { return "Canales"; },
      videos: function(d) { return "Vídeos"; }
    },
    empty_header: function(d) { return "Su biblioteca esta vacía."; },
    empty_description: function(d) { return "Puede guardar sus videos favoritos, canales, y listas de reproducción aqui."; }
  },
  account_page: {
    page_header: function(d) { return "Mi Cuenta"; },
    current_plan_header: function(d) { return "Cuenta Actual"; },
    free_plan: function(d) { return "Gratis"; },
    monthly_plan_pricing: function(d) { return d.AMOUNT + " por mes"; },
    yearly_plan_pricing: function(d) { return d.AMOUNT + " por año"; },
    subscription_ends_on: function(d) { return "Su suscripción terminará en " + d.END_DATE; },
    subscription_renews_on: function(d) { return "Su suscripción se renovará automáticamente en " + d.RENEW_DATE; },
    cancel_subscription_action: function(d) { return "Cancelar suscripción"; },
    reactivate_subscription_action: function(d) { return "Reactivar suscripción"; },
    subscription_type_basic: function(d) { return "CaptionPop Básico"; },
    subscription_type_premium: function(d) { return "CaptionPop Premium"; },
    subscription_type_lifetime: function(d) { return "CaptionPop Por Vida"; },
    language_settings_header: function(d) { return "Configuración de Idioma"; },
    native_language_header: function(d) { return "Lengua Materna"; },
    target_language_header: function(d) { return "Idioma de Destino"; },
    native_language_update_confirmation: function(d) { return "Su lengua materna ha sido actualizada."; },
    target_language_update_confirmation: function(d) { return "Si idioma de destino ha sido actualizada."; },
    payment_history_header: function(d) { return "Historial de Pagos"; },
    receipt_link: function(d) { return "Recibo"; },
    credit: function(d) { return "Crédito " + d.AMOUNT; },
    credit_card_description: function(d) { return d.CREDIT_CARD_BRAND + " que termina en " + d.LAST4; },
    credit_card_expires: function(d) { return "Expira " + d.MONTH + "/" + d.YEAR; },
    payment_method_header: function(d) { return "Método de Pago"; },
    update_payment_method_action: function(d) { return "Actualizar Tarjeta de Crédito"; },
    payment_method_update_confirmation: function(d) { return "Su método de pago ha sido actualizada."; },
    cancel_action: function(d) { return "Cancelar"; },
    update_action: function(d) { return "Actualizar"; },
    none: function(d) { return "Ninguna"; }
  },
  cancel_subscription_page: {
    page_header: function(d) { return "Su suscripción ha sido cancelada"; },
    confirmation: function(d) { return "¡Gracias! Tus comentarios nos ayudarán a mejorar CaptionPop."; },
    paragraph1: function(d) { return "Su suscripción a CaptionPop Premium ha sido cancelada. Aún tendrás acceso a todas las funciones de CaptionPop Premium hasta el final de tu período de facturación actual."; },
    paragraph2: function(d) { return "Si cambia de opinión, puede reactivar su suscripción en cualquier momento en la página de tu cuenta."; },
    feedback_header: function(d) { return "¿Por qué decidistes cancelar?"; },
    feedback_prompt: function(d) { return "Proporciona tus comentarios sobre tu decisión de cancelar para ayudanos a mejorar CaptionPop."; },
    reason_too_expensive: function(d) { return "El precio es demasiado caro."; },
    reason_not_useful: function(d) { return "Ya no encuentro a CaptionPop Premium útil"; },
    reason_other: function(d) { return "Otra razón"; },
    comments_header: function(d) { return "¿Tiene algún otro comentario o sugerencia?"; },
    cancel_action: function(d) { return "No, gracias"; },
    submit_action: function(d) { return "Enviar comentarios"; },
    reason_required: function(d) { return "Por favor seleccione una razón."; }
  }
}