/* @flow */

import { parse, stringify } from "querystring";
import { omit, isEmpty } from "lodash";

type Params = { [string]: string };

import type { DocumentLocation } from "./useNavigation";

// This is like querystring.parse, but it removes the "?"
export default function parseSearchString(str: string): Params {
  // TODO: If a key appears twice in the search string, it will be parsed into an array.
  // This will probably break most of the clients of this function
  if (str && str.startsWith("?")) {
    return parse(str.substr(1));
  } else {
    return parse(str);
  }
}

export function updateLocation(
  location: DocumentLocation,
  update: Params
): string {
  return (
    location.pathname +
    "?" +
    stringify({ ...parseSearchString(location.search), ...update }) +
    location.hash
  );
}

export function removeFromSearch(search: string, key: string | Array<string>) {
  const update = omit(parseSearchString(search), key);
  if (isEmpty(update)) {
    return "";
  } else {
    return "?" + stringify(update);
  }
}

export function removeQueryParam(
  location: DocumentLocation,
  key: string | Array<string>
): string {
  const search = removeFromSearch(location.search, key);
  return location.pathname + search + location.hash;
}
