/* @flow */

import * as React from "react";

import WithLoginRequiredPage from "./WithLoginRequiredPage";
import { Box, PageContent } from "./FormStyles";
import { FormField, ValidationError } from "./Forms";

import styled from "styled-components";
import { stringsForLocale } from "../lang/web";
import useBoundedPlayback from "./useBoundedPlayback";
import { colors } from "./theme";

import ActionLink from "./ActionLink";
import { SubmitButton, ActionButton } from "./Buttons";
import LanguageSelect, { optionsForLanguageSelect } from "./LanguageSelect";
import { PlayerContainer } from "./PlayerContainer";
import PageHeader from "./PageHeader";

import { Television, PlayTriangle } from "./SvgAssets";
import { dataUrlForSvg } from "./dataUrlForSvg";

import urlParser from "js-video-url-parser/lib/base";
import "js-video-url-parser/lib/provider/youtube";

import { useFormState, useFormUtils, useFormNormalizer } from "./WithFormState";
import { useFormValidations, required, isKeyValid } from "./useFormValidations";

import type { FormState } from "./WithFormState";
import type { UserResourceStore } from "./WithUserResourceStore";
import type { UnsavedFavorite } from "./api";
import type { SnackbarMessage } from "./useSnackbarQueue";
import type { ValidationMap, ErrorMap } from "./useFormValidations";
import type { TimeBlock } from "./mergeSubtitles";

type Props = {
  nativeLang: string,
  targetLang: ?string,
  isLoggedIn: boolean,
  isInitialized: boolean,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  onAddSnackbarMessage: SnackbarMessage => void,
  onNavigate: string => void,
  userResources: ?UserResourceStore,
  onLogin: () => void,
  onLogout: () => void
};

import {
  isValidFormat,
  secondsToString,
  stringToSeconds
} from "./mediaTimestampFormat";
import {
  subtitleFromFormValues,
  validationsWithDuration,
  normalizeTimestamp
} from "./editSubtitle";

import type { FormValues as SubtitleFormValues } from "./editSubtitle";

type FormValues = {
  ...SubtitleFormValues,
  url: string,
  translationLang: string,
  transcriptionLang: string
};

export default function CreateSubtitlePage(props: Props) {
  const initialValues: FormValues = {
    url: "",
    start: "",
    end: "",
    transcription: "",
    translations: "",
    translationLang: "",
    transcriptionLang: ""
  };

  const formState = useFormState({
    values: initialValues,
    filterFunctions: {
      start: isValidFormat,
      end: isValidFormat
    }
  });

  const youTubeParams = urlParser.parse(formState.values.url);

  function onSubmit() {
    const subtitle = subtitleFromFormValues(formState.values);

    if (youTubeParams && youTubeParams.mediaType === "video") {
      const videoId = youTubeParams.id;

      const favorite: UnsavedFavorite = {
        videoId: videoId,
        transcriptionLang: formState.values.transcriptionLang,
        subtitle: subtitle,
        source: "custom"
      };

      if (!isBlank(formState.values.translationLang)) {
        favorite.translationLang = formState.values.translationLang;
      }

      if (props.userResources) {
        const promise = props.userResources.favorites.onAddFavorite(favorite);

        promise
          .then(
            () => {
              return {
                body: "Your caption has been saved",
                level: "message"
              };
            },
            error => {
              // LocalizeMe
              return {
                body: "There was an error saving the update to your caption.",
                level: "error"
              };
            }
          )
          .then(snackbarMsg => {
            props.onAddSnackbarMessage(snackbarMsg);
            props.onNavigate("/favorites");
          });
      }
    }
  }

  const onBlur = useFormNormalizer({
    values: formState.values,
    normalizeFunctions: {
      start: normalizeTimestamp,
      end: normalizeTimestamp
    },
    onChange: formState.onChange
  });

  // $FlowFixMe
  const validations: ValidationMap<FormValues2> = {
    ...validationsWithDuration(0),
    url: [required, validateYoutubeUrl],
    transcriptionLang: [required],
    translationLang: []
  };

  if (!isBlank(formState.values.translations)) {
    validations.translationLang = [required];
  }

  const [errorMessages, formEventHandlers] = useFormValidations({
    locale: props.nativeLang,
    values: formState.values,
    validations: validations,
    onSubmit
  });

  const formUtils = useFormUtils(formState);

  const currentStep = calculateCurrentStep(formState.values, validations);

  const urlFormFieldEl = (
    <React.Fragment>
      <StepHeader current={currentStep == 1}>YouTube Video URL</StepHeader>
      <FormField
        {...formUtils.propsForInput("url")}
        errorMessages={errorMessages["url"]}
        renderInput={inputProps => (
          <Input {...inputProps} type="text" size={10} />
        )}
      />
    </React.Fragment>
  );

  return (
    <WithLoginRequiredPage
      {...props}
      render={() => (
        <React.Fragment>
          <PageHeader
            onLogin={props.onLogin}
            onLogout={props.onLogout}
            isLoggedIn={props.isLoggedIn}
            nativeLang={props.nativeLang}
            targetLang={props.targetLang}
            userResources={props.userResources}
          />
          <PageContent>
            <h1>Create a new subtitle</h1>
            <MyBox>
              <form {...formEventHandlers} onBlur={onBlur}>
                {youTubeParams && youTubeParams.mediaType === "video" ? (
                  <WithVideoLoaded
                    urlFormFieldEl={urlFormFieldEl}
                    videoId={youTubeParams.id}
                    currentStep={currentStep}
                    formState={formState}
                    render={(beginSetterEl, endSetterEl, onPreview) => (
                      <SubtitleForm
                        currentStep={currentStep}
                        beginSetterEl={beginSetterEl}
                        endSetterEl={endSetterEl}
                        onPreview={onPreview}
                        nativeLang={props.nativeLang}
                        youtubeLanguages={props.youtubeLanguages}
                        formState={formState}
                        disabled={false}
                        errorMessages={errorMessages}
                      />
                    )}
                  />
                ) : (
                  <React.Fragment>
                    <StepWrapper>
                      <StepMarker first>
                        <StepCircle step={1} currentStep={currentStep} />
                      </StepMarker>
                      <StepContent>
                        {urlFormFieldEl}
                        <VideoPlaceholder aspectRatio={1920 / 1080}>
                          <Television width="38%" fill="#e5e5e5" />
                        </VideoPlaceholder>
                      </StepContent>
                    </StepWrapper>

                    <SubtitleForm
                      currentStep={currentStep}
                      beginSetterEl={null}
                      endSetterEl={null}
                      onPreview={() => {}}
                      nativeLang={props.nativeLang}
                      youtubeLanguages={props.youtubeLanguages}
                      formState={formState}
                      disabled={true}
                      errorMessages={errorMessages}
                    />
                  </React.Fragment>
                )}
              </form>
            </MyBox>
          </PageContent>
        </React.Fragment>
      )}
    />
  );
}

function lineSvgAsData(startY, endY) {
  const svgString = `<svg viewBox="0 0 1 1000" width="20" height="1000" xmlns="http://www.w3.org/2000/svg">
    <line x1="0" y1="${startY}" x2="0" y2="${endY}" stroke="#ddd" />
    </svg>`;

  return dataUrlForSvg(svgString);
}

const firstLine = lineSvgAsData(10, 1000);
const middleLine = lineSvgAsData(0, 1000);

function StepCircle(props: { step: number, currentStep: number }) {
  let colorProps;
  if (props.step === props.currentStep) {
    colorProps = {
      fill: colors.highlight,
      stroke: colors.highlight
    };
  } else if (props.step < props.currentStep) {
    colorProps = {
      fill: "#ddd",
      stroke: "#ddd"
    };
  } else {
    colorProps = {
      fill: "#fff",
      stroke: "#ddd"
    };
  }

  return (
    <svg viewBox="0 0 20 20" width="100%">
      <circle cx="50%" cy="50%" r="45%" strokeWidth={1} {...colorProps} />
    </svg>
  );
}

const StepWrapper = styled.div`
  display: flex;
`;

const StepContent = styled.div`
  flex-grow: 1;
  padding-bottom: 3em;
`;

const StepHeader = styled.div`
  font-weight: bold;
  margin-bottom: 0.5em;
`;

const StepMarker = styled.div`
  flex: 0 0 5%;
  max-width: 20px;
  margin-right: 1em;
  background-image: url(${props => (props.first ? firstLine : middleLine)});
  background-position-x: center;
  background-repeat: repeat-y;
  text-align: center;
`;

const MyBox = styled(Box)`
  padding-right: 2em;
`;

type Props2 = {
  videoId: string,
  formState: FormState<FormValues>,
  urlFormFieldEl: React.Node,
  currentStep: number,
  render: (
    beginSetterEl: React.Node,
    endSetterEl: React.Node,
    () => void
  ) => React.Node
};

function WithVideoLoaded(props: Props2) {
  const formState = props.formState;

  const timeBlock = timeBlockFromFormValues(formState.values);

  const playerProps = useBoundedPlayback({
    videoId: props.videoId,
    timeBlock
  });

  const onPreview = () => playerProps.onPlay(1.0);

  const getCurrentTime = playerProps.getCurrentTime;
  const [currentTime, setCurrentTime] = React.useState<number | null>(null);
  const playerState = playerProps.playerState;
  React.useEffect(() => {
    let intervalId = null;

    if (
      playerState === YT.PlayerState.PLAYING ||
      playerState === YT.PlayerState.BUFFERING ||
      playerState === YT.PlayerState.PAUSED
    ) {
      setCurrentTime(getCurrentTime());

      intervalId = setInterval(() => {
        setCurrentTime(getCurrentTime());
      }, 1000);
    } else {
      setCurrentTime(null);
    }

    return () => {
      if (intervalId != null) {
        clearInterval(intervalId);
      }
    };
  }, [playerState, getCurrentTime]);

  let beginSetterEl = null;
  let endSetterEl = null;

  if (currentTime != null) {
    const str = secondsToString(currentTime);

    if (isBlank(formState.values.start)) {
      beginSetterEl = (
        <TimestampSetter
          onActivated={() => formState.onChange("start", str)}
          timestamp={str}
        />
      );
    } else if (
      isBlank(formState.values.end) &&
      currentTime > stringToSeconds(formState.values.start)
    ) {
      endSetterEl = (
        <TimestampSetter
          onActivated={() => formState.onChange("end", str)}
          timestamp={str}
        />
      );
    }
  }

  return (
    <React.Fragment>
      <StepWrapper>
        <StepMarker>
          <StepCircle step={1} currentStep={props.currentStep} />
        </StepMarker>
        <StepContent>
          {props.urlFormFieldEl}

          <PlayerSizer aspectRatio={1920 / 1080}>
            <PlayerContainer
              {...playerProps.initParams}
              videoId={props.videoId}
              playerVars={{}}
            />
          </PlayerSizer>
        </StepContent>
      </StepWrapper>

      {props.render(beginSetterEl, endSetterEl, onPreview)}
    </React.Fragment>
  );
}

const PlayerSizer = styled(GreyRectangle)`
  box-shadow: 2px 2px 5px #888;

  iframe {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
`;

const TimestampSetterWrapper = styled.div`
  font-size: 75%;
  a {
    color: #333;
  }
`;

function TimestampSetter(props: {
  timestamp: string,
  onActivated: () => void
}) {
  return (
    <TimestampSetterWrapper>
      <ActionLink onActivated={props.onActivated}>
        <svg
          viewBox="0 0 100 100"
          width={10}
          height={10}
          fill={colors.highlight}
        >
          <circle cx="50" cy="50" r="35" />
        </svg>
        <span style={{ color: colors.highlight }}>&middot;</span>

        {LocalizeMe("Set to " + props.timestamp)}
      </ActionLink>
    </TimestampSetterWrapper>
  );
}

function LocalizeMe(str) {
  return str;
}

import { GreyRectangle } from "./LoadingTextPlaceholder";

const VideoPlaceholder = styled(GreyRectangle)`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  svg {
    position: absolute;
    margin-left: -19%;
    margin-top: -15%;
    left: 50%;
    top: 50%;
  }
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
`;
const Textarea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
`;

const MyLanguageSelect: typeof LanguageSelect = styled(LanguageSelect)`
  margin-top: 0.25em;
  max-width: 200px;
`;

const TimestampRow = styled.div`
  display: flex;

  > div {
    margin-right: 1em;
  }
`;

const validateYoutubeUrl = {
  validate(input: string) {
    const youTubeParams = urlParser.parse(input);

    return youTubeParams && youTubeParams.mediaType === "video";
  },
  message(_locale: string) {
    return LocalizeMe("Not a valid YouTube URL");
  }
};

function calculateCurrentStep(
  values: FormValues,
  validations: ValidationMap<FormValues>
): number {
  function hasErrors(key) {
    return !isKeyValid(key, values, validations);
  }

  if (hasErrors("url")) return 1;

  if (hasErrors("start") || hasErrors("end")) return 2;

  if (
    hasErrors("transcription") ||
    hasErrors("translations") ||
    hasErrors("translationLang") ||
    hasErrors("transcriptionLang")
  )
    return 3;

  return 4;
}

type Props3 = {
  formState: FormState<FormValues>,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  currentStep: number,
  errorMessages: ErrorMap<FormValues>,
  nativeLang: string,
  disabled: boolean,
  onPreview: () => void,
  beginSetterEl: React.Node,
  endSetterEl: React.Node
};

function SubtitleForm(props: Props3) {
  const formState = props.formState;
  const strings = stringsForLocale(props.nativeLang).edit_caption_form;

  const formUtils = useFormUtils(formState);

  const languageOptions = optionsForLanguageSelect(props.youtubeLanguages);
  const errorMessages = props.errorMessages;

  let previewButtonEl;
  const timeBlock = timeBlockFromFormValues(props.formState.values);
  if (timeBlock != null) {
    const durationString = Math.floor(timeBlock.duration * 100) / 100;

    previewButtonEl = (
      <PreviewButton
        onActivated={props.onPreview}
        disabled={
          props.disabled ||
          formState.values.start == "" ||
          formState.values.end == ""
        }
      >
        <PlayTriangle width={10} color="#333" />
        {/* LocalizeMe */}
        Preview {durationString}s
      </PreviewButton>
    );
  } else {
    previewButtonEl = null;
  }

  return (
    <React.Fragment>
      <StepWrapper>
        <StepMarker>
          <StepCircle step={2} currentStep={props.currentStep} />
        </StepMarker>
        <StepContent>
          <StepHeader current={props.currentStep == 2}>
            Select timestamps
          </StepHeader>

          <TimestampRow>
            <FormField
              label={strings.labels.start_time()}
              {...formUtils.propsForInput("start")}
              errorMessages={errorMessages["start"]}
              renderInput={inputProps => (
                <div>
                  <input
                    {...inputProps}
                    type="text"
                    size={10}
                    disabled={props.disabled}
                  />
                  {props.beginSetterEl}
                </div>
              )}
            />

            <FormField
              label={strings.labels.end_time()}
              {...formUtils.propsForInput("end")}
              renderInput={inputProps => (
                <div>
                  <input
                    {...inputProps}
                    type="text"
                    size={10}
                    disabled={props.disabled}
                  />
                  {props.endSetterEl}
                </div>
              )}
              errorMessages={errorMessages["end"]}
            />
          </TimestampRow>
          {previewButtonEl}
        </StepContent>
      </StepWrapper>

      <StepWrapper>
        <StepMarker>
          <StepCircle step={3} currentStep={props.currentStep} />
        </StepMarker>
        <StepContent>
          <StepHeader current={props.currentStep == 3}>Add text</StepHeader>
          <FormField
            label={strings.labels.transcription()}
            {...formUtils.propsForInput("transcription")}
            renderInput={inputProps => (
              <div>
                <Input {...inputProps} type="text" disabled={props.disabled} />

                <MyLanguageSelect
                  defaultOpen={false}
                  onChange={option => {
                    formState.onChange(
                      "transcriptionLang",
                      option ? option.value : ""
                    );
                  }}
                  disabled={props.disabled}
                  instanceId="transcription-lang"
                  options={languageOptions}
                  placeholder="Select language"
                  value={languageOptions.find(
                    o => o.value === formState.values.transcriptionLang
                  )}
                />

                {errorMessages["transcriptionLang"].length > 0 ? (
                  <ValidationError>
                    {errorMessages["transcriptionLang"][0]}
                  </ValidationError>
                ) : null}
              </div>
            )}
            errorMessages={errorMessages["transcription"]}
          />

          <FormField
            label={strings.labels.translations()}
            {...formUtils.propsForInput("translations")}
            renderInput={inputProps => (
              <div>
                <Textarea {...inputProps} disabled={props.disabled} />
                <MyLanguageSelect
                  defaultOpen={false}
                  onChange={option => {
                    formState.onChange(
                      "translationLang",
                      option ? option.value : ""
                    );
                  }}
                  disabled={props.disabled}
                  instanceId="translation-lang"
                  options={languageOptions}
                  placeholder="Select language"
                  value={languageOptions.find(
                    o => o.value === formState.values.translationLang
                  )}
                />

                {errorMessages["translationLang"].length > 0 ? (
                  <ValidationError>
                    {errorMessages["translationLang"][0]}
                  </ValidationError>
                ) : null}
              </div>
            )}
            errorMessages={errorMessages["translations"]}
          />
        </StepContent>
      </StepWrapper>

      <StepWrapper>
        <StepMarker style={{ backgroundImage: "none" }} />
        <StepContent>
          <SubmitButton
            primary={props.currentStep == 4}
            value={LocalizeMe("Save to Favorites")}
          />
        </StepContent>
      </StepWrapper>
    </React.Fragment>
  );
}

function isBlank(str) {
  return str.trim() == "";
}

function timeBlockFromFormValues(formValues: FormValues): TimeBlock | null {
  if (isBlank(formValues.start) || isBlank(formValues.end)) return null;

  const start = stringToSeconds(formValues.start);
  const end = stringToSeconds(formValues.end);

  if (end > start) {
    return {
      start: start,
      duration: end - start
    };
  } else {
    return null;
  }
}

const PreviewButton = styled(ActionButton)`
  svg {
    margin-right: 0.25em;
  }
`;
