/* @flow */

import * as React from "react";

import { captureException } from "@sentry/browser";

import styled from "styled-components";

import { StripeElementWrapper, Box } from "./FormStyles";
import LoadingPage from "./LoadingPage";

import { colors } from "./theme";

import {
  getPricingPlans,
  getGeocode,
  postAccount,
  postSubscription
} from "./apiClient";

import { stringsForLocale } from "../lang/web";

import SubscriptionConfirmationPage from "./SubscriptionConfirmationPage";
import ActionLink from "./ActionLink";
import { useStripeElementController } from "./useStripeElementController";
import { StripeApiStatus } from "./useScriptTag";
import { useEmailAvailabilityCheck } from "./useEmailAvailabilityCheck";

import { useFormState, useFormUtils } from "./WithFormState";
import type { FormState } from "./WithFormState";
import WithPromises from "./WithPromises";
import SentryErrorPage from "./SentryErrorPage";
import { SubmitButton, buttonStyles } from "./Buttons";
import { APIError } from "./APIError";

import TextWithBoldNumbers from "./TextWithBoldNumbers";
import formatCurrency from "./formatCurrency";

import { find, sortBy, uniq, includes } from "lodash";

import type { UserResourceStore } from "./WithUserResourceStore";
import type { SnackbarMessage } from "./useSnackbarQueue";
import { CredentialFields } from "./LoginOverlay";
import OAuth2Buttons from "./OAuth2Buttons";

import {
  useFormValidations,
  required,
  confirmationOf
} from "./useFormValidations";

type Props = {
  nativeLang: string,
  userResources: ?UserResourceStore,
  withIdToken: ?() => Promise<string>,
  isInitialized: boolean,
  onAddSnackbarMessage: SnackbarMessage => void,
  onLogin: () => void,
  onLogout: () => void,
  onIdToken: string => void
};

type CountryCode = string;

import type { PricingPlan } from "./api";

export default function PremiumSubscriptionPage(props: Props) {
  const [
    activePromise,
    setActivePromise
  ] = React.useState<Promise<boolean> | null>(null);

  const pricingPlansPromise = usePromiseEffect(getPricingPlans);
  const countryCodePromise = usePromiseEffect(getCountryCode);

  //
  // This effect reads the email and subscription.active out of
  // userResources (if the user is logged in)
  //
  let subscriptionPromise;
  let identityPromise;
  if (props.userResources) {
    subscriptionPromise = props.userResources.premiumSubscription.subscription;
    identityPromise = props.userResources.identity;
  } else {
    subscriptionPromise = null;
    identityPromise = null;
  }
  React.useEffect(() => {
    if (subscriptionPromise == null) {
      setActivePromise(Promise.resolve(false));
    } else {
      setActivePromise(subscriptionPromise.then(sub => sub.active));
    }
  }, [subscriptionPromise]);

  React.useEffect(() => {
    if (identityPromise == null) {
      setEmail(null);
    } else {
      identityPromise.then(
        value => setEmail(value.email),
        () => setEmail(null)
      );
    }
  }, [identityPromise]);

  //
  // Mixpanel effect
  //
  React.useEffect(() => {
    if (countryCodePromise != null) {
      countryCodePromise.then(country => {
        mixpanel.track("Viewed Checkout Page", { country });
      });
    }
  }, [countryCodePromise]);

  const [submitError, setSubmitError] = React.useState<null>(null);

  const [
    submitActivated,
    setSubmitActivated
  ] = React.useState<SubmitValues | null>(null);
  const [email, setEmail] = React.useState<string | null>(null);

  const onSubmit = React.useCallback((values: SubmitValues) => {
    setSubmitActivated(values);
  }, []);

  // Create an account, if necessary
  const { withIdToken, onIdToken } = props;
  React.useEffect(() => {
    if (submitActivated != null && withIdToken == null) {
      setSubmitError(null);

      const body = {
        email: submitActivated.email || "",
        password: submitActivated.password || ""
      };

      postAccount(body, {}).then(
        r => onIdToken(r.token),
        error => {
          setSubmitActivated(null);
          setSubmitError(error);
          captureException(error);
        }
      );
    }
  }, [submitActivated, withIdToken, onIdToken]);

  // Post the purchase to the origin server
  const purchasedPlan = submitActivated == null ? null : submitActivated.plan;
  const onPurchase =
    props.userResources == null
      ? null
      : props.userResources.premiumSubscription.onPurchase;
  const stripeTokenPromise =
    submitActivated == null ? null : submitActivated.tokenPromise;
  React.useEffect(() => {
    if (
      purchasedPlan != null &&
      onPurchase != null &&
      stripeTokenPromise != null &&
      withIdToken != null
    ) {
      setSubmitError(null);

      stripeTokenPromise
        .then(stripeToken =>
          postSubscription(withIdToken, purchasedPlan, stripeToken)
        )
        .then(
          response => onPurchase(response),
          error => {
            setSubmitActivated(null);
            setSubmitError(error);
            captureException(error);
          }
        );
    }
  }, [withIdToken, onPurchase, purchasedPlan, stripeTokenPromise]);

  const notification =
    submitError == null
      ? null
      : notificationForError(submitError, props.nativeLang);

  const snackbarWarning =
    notification && notification.type === "snackbar"
      ? notification.message
      : null;
  const { onAddSnackbarMessage } = props;
  React.useEffect(() => {
    if (snackbarWarning != null) {
      onAddSnackbarMessage({
        body: snackbarWarning,
        level: "error"
      });
    }
  }, [snackbarWarning, onAddSnackbarMessage]);

  const checkoutError =
    notification != null && notification.type === "banner"
      ? notification.message
      : null;

  return (
    <WithPromises
      promises={[pricingPlansPromise, countryCodePromise, activePromise]}
      renderRejected={error => (
        <SentryErrorPage
          error={error}
          eventId={error.eventId}
          nativeLang={props.nativeLang}
        />
      )}
      renderPending={() => <LoadingPage />}
      renderResolved={([pricingPlans, countryCode, active]) => {
        if (active) {
          return <SubscriptionConfirmationPage nativeLang={props.nativeLang} />;
        } else {
          if (submitActivated == null) {
            return (
              <PremiumSubscriptionView
                onSubmit={onSubmit}
                onLogin={props.onLogin}
                onLogout={props.onLogout}
                pricingPlans={pricingPlans}
                countryCode={countryCode}
                nativeLang={props.nativeLang}
                email={email}
                inProgress={submitActivated != null}
                checkoutError={checkoutError}
              />
            );
          } else {
            return <LoadingPage />;
          }
        }
      }}
    />
  );
}

type ErrorNotification = {
  type: "snackbar" | "banner",
  message: string
};

const HTTP_STRIPE_CARD_ERROR = 402;

function notificationForError(
  error: any,
  nativeLang: string
): ErrorNotification | null {
  const strings = stringsForLocale(nativeLang);

  //
  // Stripe errors
  //
  if (error.type == "api_connection_error") {
    return { type: "snackbar", message: error.message };
  } else if (error.type == "validation_error") {
    return { type: "banner", message: error.message };

    //
    // APIError
    //
  } else if (error instanceof APIError) {
    if (error.code === HTTP_STRIPE_CARD_ERROR) {
      return { type: "banner", message: error.message };
    } else if (error.code === "email-conflict") {
      return {
        type: "banner",
        message: strings.flash_cards.premium_subscription.errors.unexpected_error()
      };
    } else {
      return {
        type: "banner",
        message: strings.flash_cards.premium_subscription.errors.unexpected_error()
      };
    }
    //
    // Other errors
    //
  } else if (error instanceof TypeError) {
    return {
      type: "snackbar",
      message: strings.flash_cards.premium_subscription.errors.network_error()
    };
  } else {
    return {
      type: "banner",
      message: strings.flash_cards.premium_subscription.errors.unexpected_error()
    };
  }
}

function getCountryCode(): Promise<CountryCode | null> {
  return getGeocode().then(
    r => r.country,
    error => {
      captureException(error);
      return null;
    }
  );
}

function usePromiseEffect<T>(effectFn: () => Promise<T>): Promise<T> | null {
  const [promise, setPromise] = React.useState<Promise<T> | null>(null);

  // This saves the first value of effectFn. I'm assuming that effectFn
  // never changes.
  // Alternatively:
  //    - Add effectFn to the dependency array
  //    - inline useEffect to the calling component. This should allow
  //      the eslint plugin to see that effectFn never changes
  const effectRef = React.useRef(effectFn);

  React.useEffect(() => {
    const result = effectRef.current();
    result.catch(error => {
      error.eventId = captureException(error);
      return Promise.reject(error);
    });
    setPromise(result);
  }, []);

  return promise;
}

type SubmitValues = {
  email: string | null,
  password: string | null,
  plan: string,
  tokenPromise: Promise<StripeToken>
};

type ViewProps = {
  onSubmit: (values: SubmitValues) => void,
  onLogin: () => void,
  onLogout: () => void,
  pricingPlans: Array<PricingPlan>,
  countryCode: CountryCode | null,
  nativeLang: string,
  checkoutError: string | null,
  inProgress: boolean,
  email: ?string,
  initialPlanId?: string // Only used by storybook
};

type FormValues = {
  plan: string,
  currency: string,
  email: string,
  password: string,
  token: string,
  passwordConfirmation: string
};

export function PremiumSubscriptionView(props: ViewProps) {
  const strings = stringsForLocale(props.nativeLang).flash_cards
    .premium_subscription;
  const strings2 = stringsForLocale(props.nativeLang);

  const initialPlanId = props.initialPlanId != null ? props.initialPlanId : "";

  const formProps: FormState<FormValues> = useFormState({
    values: {
      plan: initialPlanId,
      currency: defaultCurrency(
        props.countryCode,
        uniqCurrencies(props.pricingPlans)
      ),
      email: "",
      password: "",
      token: "",
      passwordConfirmation: ""
    }
  });

  let validations;
  if (props.email == null) {
    validations = {
      plan: [required],
      currency: [],
      email: [required],
      password: [required],
      passwordConfirmation: [confirmationOf("password")],
      token: []
    };
  } else {
    validations = {
      plan: [required],
      currency: [],
      email: [],
      password: [],
      passwordConfirmation: [],
      token: []
    };
  }

  const stripeProps = useStripeElementController();

  const onSubmit = stripeProps.createToken;

  const { email, password, plan } = formProps.values;
  const isLoggedIn = props.email != null;
  const propsOnSubmit = props.onSubmit;
  React.useEffect(() => {
    if (stripeProps.tokenPromise) {
      if (isLoggedIn) {
        propsOnSubmit({
          plan,
          email: null,
          password: null,
          tokenPromise: stripeProps.tokenPromise
        });
      } else {
        propsOnSubmit({
          plan,
          email,
          password,
          tokenPromise: stripeProps.tokenPromise
        });
      }
    }
  }, [
    isLoggedIn,
    stripeProps.tokenPromise,
    email,
    password,
    plan,
    propsOnSubmit
  ]);

  const emailAvailable = useEmailAvailabilityCheck(formProps.values.email);

  const asyncValidationErrors = buildAsyncValidationErrors(
    emailAvailable,
    stripeProps.validationError,
    props.nativeLang
  );

  const [
    validationErrors,
    formEventHandlers,
    setVisitedKey
  ] = useFormValidations({
    values: formProps.values,
    locale: props.nativeLang,
    asyncValidationErrors,
    onSubmit,
    validations
  });

  const formUtils = useFormUtils(formProps);
  const apiStatus = React.useContext(StripeApiStatus);

  let purchaseDescription = null;
  if (formProps.values.plan) {
    const pricingPlan = props.pricingPlans.find(
      plan => plan.id === formProps.values.plan
    );
    if (pricingPlan != null) {
      purchaseDescription = longDescriptionForPricingPlan(
        pricingPlan,
        props.nativeLang
      );
    }
  }

  if (apiStatus === "pending") {
    return <LoadingPage />;
  } else if (apiStatus === "error") {
    return (
      <SentryErrorPage
        error="Failed to load Stripe.js"
        eventId={null}
        nativeLang={props.nativeLang}
      />
    );
  }

  const placeholder = "CURRENCY-LIST-PLACEHOLDER";
  const parts = strings
    .available_currencies({ CURRENCY_LIST: placeholder })
    .split(placeholder);
  const currencyListPreText = parts[0] || "";
  const currencyListPostText = parts[1] || "";

  return (
    <PageContent>
      <MainHeader>{strings.header()}</MainHeader>
      {props.checkoutError == null ? null : (
        <CheckoutError>
          <ErrorTitle>{strings.errors.banner_header()}</ErrorTitle>
          <ErrorDescription>{props.checkoutError}</ErrorDescription>
        </CheckoutError>
      )}

      {props.email == null ? null : (
        <LoggedInText>
          {strings.account.logged_in_as({ EMAIL: props.email })}{" "}
          <ActionLink onActivated={props.onLogout}>
            {strings.account.not_you()}
          </ActionLink>
        </LoggedInText>
      )}

      <MyBox>
        <StripeElementWrapper>
          <form {...formEventHandlers}>
            <FormSection>
              {props.email == null ? (
                <React.Fragment>
                  <Header>{strings.form_headers.create_an_account()}</Header>

                  <AccountSection>
                    <CredentialsColumn>
                      <SubHeader>{strings.account.choose_password()}</SubHeader>
                      <CredentialFields
                        nativeLang={props.nativeLang}
                        formProps={formProps}
                        validationErrors={validationErrors}
                        includeConfirmation={true}
                      />
                    </CredentialsColumn>

                    <OAuth2Column>
                      <SubHeader>{strings.account.provider_signup()}</SubHeader>
                      <OAuth2Buttons
                        textFn={provider => provider}
                        nativeLang={props.nativeLang}
                        location={location}
                        theme="light"
                      />
                    </OAuth2Column>
                  </AccountSection>
                  <CenteredText>
                    {strings.account.already_have_account()}{" "}
                    <ActionLink onActivated={props.onLogin}>
                      {strings2.page_header.login_action()}
                    </ActionLink>
                  </CenteredText>
                </React.Fragment>
              ) : null}

              <Header>{strings.form_headers.plan()}</Header>

              <PricingPlanGroup errors={validationErrors.plan}>
                {sortBy(
                  filteredPlans(props.pricingPlans, formProps.values.currency),
                  p => p.amount
                ).map((plan, i, filteredList) => (
                  <PricingOption
                    key={plan.id}
                    nativeLang={props.nativeLang}
                    savings={
                      plan.interval === "year"
                        ? yearlySavings(filteredList)
                        : null
                    }
                    pricingPlan={plan}
                    inputProps={formUtils.propsForRadio("plan", plan.id)}
                  />
                ))}
              </PricingPlanGroup>
              <CurrencySelectText>
                {currencyListPreText}{" "}
                {uniqCurrencies(props.pricingPlans).map((currency, i, list) => (
                  <React.Fragment key={currency}>
                    <CurrencyOption
                      checked={
                        formUtils.propsForRadio("currency", currency).checked
                      }
                      onActivated={() => {
                        formProps.onChange("plan", "");
                        setVisitedKey("plan", false);
                        formProps.onChange("currency", currency);
                      }}
                    >
                      {currency}
                    </CurrencyOption>
                    {i < list.length - 1 ? ", " : null}
                  </React.Fragment>
                ))}
                {currencyListPostText}
              </CurrencySelectText>
            </FormSection>

            <FormSection>
              <Header>{strings.form_headers.credit_card()}</Header>

              <div ref={stripeProps.setStripeElementsRef} />
              <ValidationError errors={validationErrors.token} />

              <CallToAction>
                <SubmitButton
                  primary
                  value={strings.calls_to_action.purchase()}
                  disabled={props.inProgress}
                />
              </CallToAction>

              {purchaseDescription != null ? (
                <PurchaseDescription text={purchaseDescription} />
              ) : null}
            </FormSection>
          </form>
        </StripeElementWrapper>
      </MyBox>
    </PageContent>
  );
}

function buildAsyncValidationErrors(
  emailAvailable: boolean,
  stripeError: StripeValidationError | null,
  nativeLang: string
) {
  const strings = stringsForLocale(nativeLang).login_form;

  return {
    email: emailAvailable ? [] : [strings.errors.email_conflict()],
    token: stripeError == null ? [] : [stripeError.message]
  };
}

const PageContent = styled.div`
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
`;

const MainHeader = styled.div`
  font-size: 30px;
  font-weight: normal;
  text-align: center;
  margin: 1rem 0;
`;

const Header = styled.h2`
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 20px;
`;

const MyBox = styled(Box)`
  padding-top: 0;
  padding-bottom: 1em;
`;

const LoggedInText = styled.p`
  text-align: center;
  color: #999;
`;

const CenteredText = styled.p`
  text-align: center;
`;

const SubHeader = styled.div`
  margin-bottom: 0.5rem;
`;

const AccountSection = styled.div`
  // I picked 425 by testing with the "responsive" option in Chrome.
  @media (min-width: 425px) {
    display: flex;

    &:before {
      order: 1;
      content: " ";
      width: 1px;
      flex-basis: 1px;
      flex-shrink: 0;
      flex-grow: 0;
      height: 9em;
      background-color: #ddd;
      display: block;
      margin: 2.25em 2em 0 2em;
    }
  }
`;
const CredentialsColumn = styled.div`
  order: 0;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
`;
const OAuth2Column = styled.div`
  order: 2;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
`;

const CheckoutError = styled.div`
  border: 2px solid ${colors.warning};
  background-color: white;
  margin-bottom: 1em;
`;

const ErrorTitle = styled.div`
  background-color: ${colors.warning};
  color: white;
  padding: 1em;
`;
const ErrorDescription = styled.div`
  padding: 1em;
`;

const PurchaseDescription: typeof TextWithBoldNumbers = styled(
  TextWithBoldNumbers
).attrs({ elementName: "div" })`
  margin: 1em;
`;

const FormSection = styled.div`
  margin-bottom: 1em;
  &:last-child {
    margin-bottom: 0;
  }
`;

const CallToAction = styled.div`
  margin-top: 1em;
  input {
    width: 100%;
  }
`;

const CurrencySelectText = styled.p`
  text-align: center;
  color: #999;
`;

const CurrencyOption: typeof ActionLink = styled(ActionLink)`
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }
  //text-transform: ${props => (props.checked ? "uppercase" : "none")};
  color: ${props => (props.checked ? "#333" : "#999")};
  font-weight: ${props => (props.checked ? "bold" : "normal")};
`;

function titleForPricingPlan(plan: PricingPlan, nativeLang: string): string {
  const strings = stringsForLocale(nativeLang).flash_cards.premium_subscription
    .plans;

  switch (plan.interval) {
    case "month":
      return strings.month.title();
    case "year":
      return strings.year.title();
    case "lifetime":
      return strings.lifetime.title();
    default:
      return "";
  }
}

function descriptionForPricingPlan(
  plan: PricingPlan,
  nativeLang: string,
  savings: ?number
): string {
  const strings = stringsForLocale(nativeLang).flash_cards.premium_subscription
    .plans;

  switch (plan.interval) {
    case "month":
      return strings.month.description();
    case "year":
      return strings.year.description({
        PERCENT: String(Math.round(savings || 0))
      });
    case "lifetime":
      return strings.lifetime.description();
    default:
      return "";
  }
}

function longDescriptionForPricingPlan(
  plan: PricingPlan,
  nativeLang: string
): string | null {
  const strings = stringsForLocale(nativeLang).flash_cards.premium_subscription
    .plans;

  const amount = formatCurrency(plan.amount, plan.currency);

  switch (plan.interval) {
    case "month":
      return strings.month.long_description({ AMOUNT: amount });
    case "year":
      return strings.year.long_description({ AMOUNT: amount });
    case "lifetime":
      return strings.lifetime.long_description({ AMOUNT: amount });
    default:
      return null;
  }
}

function uniqCurrencies(plans: Array<PricingPlan>): Array<string> {
  return uniq(plans.map(p => p.currency));
}

function filteredPlans(allPlans, currency) {
  const filtered = allPlans.filter(p => p.currency === currency);
  return sortBy(filtered, p => p.amount).reverse();
}

function yearlySavings(plans: Array<PricingPlan>): ?number {
  const yearly: ?PricingPlan = find(plans, plan => plan.interval === "year");

  const monthly: ?PricingPlan = find(plans, plan => plan.interval === "month");

  if (yearly == null || monthly == null) return null;

  let monthlyTotalCost;
  if (monthly) {
    monthlyTotalCost = monthly.amount * 12;
  } else {
    monthlyTotalCost = 0;
  }

  return (1 - yearly.amount / monthlyTotalCost) * 100;
}

function PricingOption(props: {
  nativeLang: string,
  pricingPlan: PricingPlan,
  savings: ?number,
  inputProps: Object,
  children?: React.Node
}) {
  const strings = stringsForLocale(props.nativeLang).flash_cards
    .premium_subscription;

  return (
    <PricingPlanOptionStyles checked={props.inputProps.checked}>
      <div className="plan-content">
        <div className="plan-title">
          {titleForPricingPlan(props.pricingPlan, props.nativeLang)}
        </div>

        <div className="price">
          {formatCurrency(props.pricingPlan.amount, props.pricingPlan.currency)}
        </div>
        <div className="description">
          {descriptionForPricingPlan(
            props.pricingPlan,
            props.nativeLang,
            props.savings
          )}
        </div>
        {props.children}

        <SelectPlanButton>
          <input type="radio" {...props.inputProps} />
          {strings.calls_to_action.choose()}
        </SelectPlanButton>
      </div>
    </PricingPlanOptionStyles>
  );
}

const SelectPlanButton = styled.div`
  ${buttonStyles};
  width: 60%;
  margin: 0 auto;
`;

function PricingPlanGroup(props: {
  errors: Array<string>,
  children: React.Node
}) {
  const highlight = props.errors.length > 0;

  return (
    <React.Fragment>
      <PricingPlanGroupStyles highlight={highlight}>
        {props.children}
      </PricingPlanGroupStyles>
      <ValidationError errors={props.errors} />
    </React.Fragment>
  );
}

const PricingPlanGroupStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  border: 1px solid ${props => (props.highlight ? colors.warning : "none")};
  border-radius: 4px;
`;

function ValidationError(props: { errors: Array<string> }) {
  if (props.errors.length > 0) {
    return <ValidationErrorStyles>{props.errors[0]}</ValidationErrorStyles>;
  } else {
    return null;
  }
}

const ValidationErrorStyles = styled.div`
  color: ${colors.warning};
`;

const PricingPlanOptionStyles = styled.label`
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  max-width: 190px;
  margin-bottom: 1em;

  .price {
    font-weight: bold;
    font-size: 150%;
  }

  display: block;

  .plan-title {
    text-align: center;
    font-weight: bold;
  }

  .plan-content {
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: ${props => (props.checked ? "2px 2px 5px #efefef" : "none")};
    background-color: white;
    padding: 1em;
  }

  input {
    margin-right: 0.25em;
  }
  .description {
    color: #999;
    margin-bottom: 0.5em;
  }
`;

function currencyForCountryCode(countryCode: CountryCode): string | null {
  // https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
  switch (countryCode.toUpperCase()) {
    case "AD": // Andorra
    case "AT": // Austria
    case "BE": // Belgium
    case "CY": // Cyprus
    case "EE": // Estonia
    case "FI": // Finland
    case "FR": // France
    case "DE": // Germany
    case "GR": // Greece
    case "IE": // Ireland
    case "IT": // Italy
    case "XK": // Kosovo
    case "LV": // Latvia
    case "LT": // Lithuania
    case "LU": // Luxembourg
    case "MT": // Malta
    case "MC": // Monaco
    case "ME": // Montenegro
    case "NL": // the Netherlands
    case "PT": // Portugal
    case "SM": // San Marino
    case "SK": // Slovakia
    case "SI": // Slovenia
    case "ES": // Spain
    case "VA": // Vatican City
    case "EU":
      return "eur";

    case "CA": // Canada
      return "cad";
    case "KR": // Korea
      return "krw";
    case "GB": // Great Britain
      return "gbp";
    case "JP": // Japan
      return "jpy";
    case "CN": // China
      return "cny";

    case "US":
      return "usd";
  }

  return null;
}

function defaultCurrency(
  countryCode: CountryCode | null,
  availableCurrencies
): string {
  const defaultCurrency = "usd";

  if (countryCode == null) return defaultCurrency;

  const currency = currencyForCountryCode(countryCode);
  if (currency == null) return defaultCurrency;

  if (includes(availableCurrencies, currency)) {
    return currency;
  } else {
    return defaultCurrency;
  }
}
