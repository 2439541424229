/* @flow */

import * as React from "react";

type Props = {
  enabled: boolean,
  scrollTo: number => void
};

type AutoScroller = {
  setActiveElement: (element: ?HTMLElement) => void
};

import { domID as playerContainerID } from "./PlayerContainer";

export default function useAutoScroller(props: Props): AutoScroller {
  // TODO: Not sure if this belongs in state. You might consider putting this and
  // isPlaying into an Observable instead, to avoid unnecessary renders
  const [to, setTo] = React.useState<number | null>(null);

  const { scrollTo, enabled } = props;

  React.useEffect(() => {
    if (to != null && enabled) {
      scrollTo(to);
    }
  }, [to, enabled, scrollTo]);

  const setActiveElement = React.useCallback((element: ?HTMLElement) => {
    setTo(element == null ? null : scrollPositionForElement(element));
  }, []);

  return { setActiveElement };
}

function scrollPositionForElement(element: HTMLElement): number {
  const playerEl = document.getElementById(playerContainerID);

  let idealViewPosition;
  if (playerEl) {
    const rect = playerEl.getBoundingClientRect();
    idealViewPosition = rect.top + rect.height;
  } else {
    // This should never happen, because the player should always exist
    idealViewPosition = 430;
  }

  // First try to scroll the active element so that the previous element is
  // still visible.
  let to;
  if (element.previousElementSibling instanceof HTMLElement) {
    to = element.previousElementSibling.offsetTop;
  } else {
    to = element.offsetTop;
  }
  to -= idealViewPosition;

  // If this scroll position would scroll the active element off the page,
  // correct this so the active element is at the bottom.
  if (element.offsetTop + element.offsetHeight > to + window.innerHeight) {
    to = element.offsetTop + element.offsetHeight - window.innerHeight;
  }

  return to;
}
