/* @flow */

/*::

import type { Route } from "./router";
import type { RouteWithResources } from "./fetchRouteResources";
*/

const { getVideo } = require("./YoutubeScraperClient");

function fetchRouteResources(
  route /*: Route */,
  _nativeLang /*: string*/
) /*: RouteWithResources */ {
  switch (route.name) {
    case "show-video": {
      const promise = getVideo(route.params.videoId);

      return {
        type: "youtube-video",
        route: { name: "show-video", params: route.params },
        promise
      };
    }
    // TODO: case "search":
    // Copy the code out of SearchPage /ChannelTitle, PlaylistTitle, etc..
    default:
      return { type: "simple-route", route };
  }
}

module.exports = { fetchRouteResources };
