/* @flow */

import * as React from "react";

type Props<T> = {
  interval: number,
  sample: () => T
};

export default function WithInterval<T>(
  props: Props<T> & { render: T => React.Node }
) {
  const value = useSampledInterval(props);
  return props.render(value);
}

export function useSampledInterval<T>(props: Props<T>): T {
  const [value, setValue] = React.useState(() => props.sample());

  useInterval(() => {
    setValue(props.sample());
  }, props.interval);

  return value;
}

export function useInterval(callback: Function, interval: number): void {
  const callbackRef: { current: Function } = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      callbackRef.current();
    }, interval);
    return () => {
      clearInterval(intervalId);
    };
  }, [interval]);
}

export function useAnimationFrame(callback: number => void) {
  const callbackRef: { current: Function } = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  React.useEffect(() => {
    let requestId: AnimationFrameID = requestAnimationFrame(onFrame);

    function onFrame(timestamp) {
      callbackRef.current(timestamp);
      requestId = requestAnimationFrame(onFrame);
    }

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, []);
}

export function useThrottledAnimationFrame(
  callback: number => void,
  timeBetweenFrames: number
) {
  const timestampRef: { current: ?number } = React.useRef(null);

  useAnimationFrame(timestamp => {
    if (
      timestampRef.current == null ||
      timestamp - timestampRef.current > timeBetweenFrames
    ) {
      timestampRef.current = timestamp;
      callback(timestamp);
    }
  });
}
