/* @flow */

import * as React from "react";

type Props = {
  href: string,
  onNavigate: string => void,
  children: React.Node
};

export default class WithRedirect extends React.Component<Props> {
  componentDidMount() {
    this.props.onNavigate(this.props.href);
  }
  render() {
    return this.props.children;
  }
}
