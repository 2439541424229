/* @flow */

import * as React from "react";
import styled, { css } from "styled-components";

import PageHeader from "./PageHeader";
import NotFoundPage from "./NotFoundPage";
import { captureException } from "@sentry/browser";

import * as geometry from "./geometry";
import { colors } from "./theme";

import { SubmitButton } from "./Buttons";

import type { Favorite } from "./api";
import type { MergedText } from "./mergeSubtitles";

import { stringsForLocale } from "../lang/web";

import useBoundedPlayback from "./useBoundedPlayback";
import { FixedPlayerContainer } from "./PlayerContainer";
import ActionLink from "./ActionLink";
import { useFormState, useFormUtils, useFormNormalizer } from "./WithFormState";
import WithLoginRequiredPage from "./WithLoginRequiredPage";
import WithPromise from "./WithPromise";
import { useFormValidations, isKeyValid } from "./useFormValidations";
import { FormField } from "./Forms";
import type { FormEvent } from "./Forms";
import type { UserResourceStore } from "./WithUserResourceStore";
import type { DocumentLocation } from "./useNavigation";
import type { ValidationMap } from "./useFormValidations";
import { PlayTriangle } from "./SvgAssets";

import { isValidFormat } from "./mediaTimestampFormat";
import {
  subtitleFromFormValues,
  formValuesFromSubtitle,
  validationsWithDuration,
  normalizeTimestamp
} from "./editSubtitle";

type Props = {
  location: DocumentLocation,
  onLogin: Function,
  onLogout: Function,
  isLoggedIn: boolean,
  onNavigate: string => void,
  nativeLang: string,
  targetLang: ?string,
  onChangeNativeLang: (lang: string) => void,
  onChangeTargetLang: (lang: string) => void,
  onAddSnackbarMessage: SnackbarMessage => void,
  onLogout: () => void,
  onLogin: () => void,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  favoriteId: string,
  userResources: ?UserResourceStore,
  isInitialized: boolean
};

import type { SnackbarMessage } from "./useSnackbarQueue";

export default function EditFavoritePage(props: Props) {
  const [promise, setPromise] = React.useState<Promise<{}> | null>(null);

  const onUpdateFavorite = props.userResources
    ? props.userResources.favorites.onUpdateFavorite
    : null;
  const onSubmit = React.useCallback(
    (favorite: Favorite) => {
      if (onUpdateFavorite) {
        setPromise(onUpdateFavorite(favorite));
      }
    },
    [onUpdateFavorite]
  );

  const { onAddSnackbarMessage, onNavigate } = props;

  const snackbarMsg = stringsForLocale(
    props.nativeLang
  ).snackbar.updated_favorite_caption();

  React.useEffect(() => {
    if (promise) {
      let subscribed = true;

      promise.catch(error => {
        if (subscribed) {
          captureException(error);
        }
      });

      promise
        .then(
          () => {
            return {
              body: snackbarMsg,
              level: "message"
            };
          },
          error => {
            // LocalizeMe
            return {
              body: "There was an error saving the update to your caption.",
              level: "error"
            };
          }
        )
        .then(snackbarMsg => {
          if (subscribed) {
            onAddSnackbarMessage(snackbarMsg);
            onNavigate("/favorites");
          }
        });

      return () => {
        subscribed = false;
      };
    }
  }, [promise, onAddSnackbarMessage, onNavigate, snackbarMsg]);

  return (
    <WithLoginRequiredPage
      onLogin={props.onLogin}
      userResources={props.userResources}
      isInitialized={props.isInitialized}
      nativeLang={props.nativeLang}
      render={userResources => (
        <React.Fragment>
          <PageHeader
            onLogin={props.onLogin}
            onLogout={props.onLogout}
            nativeLang={props.nativeLang}
            targetLang={props.targetLang}
            isLoggedIn={props.isLoggedIn}
            userResources={props.userResources}
          >
            {stringsForLocale(props.nativeLang).edit_caption_form.header()}
          </PageHeader>
          <WithPromise
            promise={userResources.favorites.favorites}
            renderRejected={() => null}
            renderPending={() => null}
            renderResolved={favorites => {
              const favoriteId = parseInt(props.favoriteId);
              const favorite = favorites.find(o => o.id === favoriteId);

              if (favorite) {
                return (
                  <EditFavoriteForm
                    favorite={favorite}
                    nativeLang={props.nativeLang}
                    isPending={promise != null}
                    onSubmit={subtitle => {
                      onSubmit({ ...favorite, subtitle });
                    }}
                  />
                );
              } else {
                return (
                  <NotFoundPage
                    onLogin={props.onLogin}
                    onLogout={props.onLogout}
                    isLoggedIn={props.isLoggedIn}
                    nativeLang={props.nativeLang}
                    targetLang={props.targetLang}
                    userResources={props.userResources}
                  />
                );
              }
            }}
          />
        </React.Fragment>
      )}
    />
  );
}

type EditFavoriteFormProps = {
  favorite: Favorite,
  onSubmit: (subtitle: MergedText) => void,
  isPending: boolean,
  nativeLang: string
};

function EditFavoriteForm(props: EditFavoriteFormProps) {
  const values = formValuesFromSubtitle(props.favorite.subtitle);

  const formState = useFormState({
    values: values,
    filterFunctions: {
      start: isValidFormat,
      end: isValidFormat
    }
  });

  const formUtils = useFormUtils(formState);

  const onBlur = useFormNormalizer({
    values: formState.values,
    normalizeFunctions: {
      start: normalizeTimestamp,
      end: normalizeTimestamp
    },
    onChange: formState.onChange
  });

  const subtitle = subtitleFromFormValues(formState.values);

  const playerProps = useBoundedPlayback({
    timeBlock: subtitle,
    videoId: props.favorite.videoId
  });

  const onSubmit = (event: Event) => {
    props.onSubmit(subtitleFromFormValues(formState.values));
  };

  const [errorMessages, formEventHandlers] = useFormValidations({
    locale: props.nativeLang,
    values: formState.values,
    validations: validationsWithDuration(playerProps.duration),
    onSubmit
  });

  const validations = validationsWithDuration(playerProps.duration);
  const strings = stringsForLocale(props.nativeLang).edit_caption_form;

  return (
    <React.Fragment>
      <MyPlayerContainer
        {...playerProps.initParams}
        fixed={false}
        key={props.favorite.id}
        playerVars={{ controls: 0 }}
      />

      <Form {...formEventHandlers}>
        <TimestampWrapper onBlur={onBlur}>
          <TimestampFormField
            label={strings.labels.start_time()}
            {...formUtils.propsForInput("start")}
            errorMessages={errorMessages["start"]}
            renderInput={inputProps => (
              <InputWithPlayButton
                {...inputProps}
                buttonDisabled={shouldDisablePreview(
                  formState.values,
                  validations,
                  playerProps.playerState
                )}
                inputDisabled={props.isPending}
                onActivated={() => playerProps.onPlay(1.0)}
              />
            )}
          />
          <TimestampFormField
            label={strings.labels.end_time()}
            {...formUtils.propsForInput("end")}
            renderInput={inputProps => (
              <input
                {...inputProps}
                type="text"
                size={10}
                disabled={props.isPending}
              />
            )}
            errorMessages={errorMessages["end"]}
          />
        </TimestampWrapper>
        <FormField
          label={strings.labels.transcription()}
          {...formUtils.propsForInput("transcription")}
          renderInput={inputProps => (
            <input {...inputProps} type="text" disabled={props.isPending} />
          )}
          errorMessages={errorMessages["transcription"]}
        />
        <FormField
          label={strings.labels.translations()}
          {...formUtils.propsForInput("translations")}
          renderInput={inputProps => (
            <textarea {...inputProps} disabled={props.isPending} />
          )}
          errorMessages={errorMessages["translations"]}
        />

        <ActionBar>
          <a href="/favorites">{strings.actions.nevermind()}</a>
          <SubmitButton
            disabled={!formUtils.changed || props.isPending}
            value={strings.actions.save_changes()}
          />
        </ActionBar>
      </Form>
    </React.Fragment>
  );
}

const ActionBar = styled.div`
  text-align: center;

  a {
    color: ${colors.dark};
    text-decoration: none;
    display: inline-block;
    margin-right: 10px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

type InputWithPlayButtonProps = {
  id: string,
  onChange: (event: FormEvent) => void,
  onActivated: () => void,
  buttonDisabled: boolean,
  inputDisabled: boolean,
  value: string,
  name: string,
  onFocus?: FormEvent => void,
  onBlur?: FormEvent => void,
  placeholder?: string,
  className?: string
};

function InputWithPlayButton(props: InputWithPlayButtonProps) {
  return (
    <InputWithPlayButtonStyles buttonDisabled={props.buttonDisabled}>
      <ActionLink
        primary
        disabled={props.buttonDisabled}
        onActivated={props.onActivated}
      >
        <PlayTriangle width={10} color="white" />
      </ActionLink>

      <input
        type="text"
        disabled={props.inputDisabled}
        placeholder={props.placeholder}
        id={props.id}
        size={10}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        name={props.name}
      />
    </InputWithPlayButtonStyles>
  );
}

import { buttonHeight } from "./Buttons";

const InputWithPlayButtonStyles = styled.div`
  display: flex;

  input,
  a {
    border-radius: 4px;
    width: ${buttonHeight}px;
    box-sizing: border-box;
  }
  input {
    border: 1px solid #dedede;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  a {
    display: block;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;

    svg {
      position: absolute;
      left: 16px;
      top: 13px;
    }

    ${props =>
      props.buttonDisabled
        ? css`
            background-color: #ddd;
            cursor: default;
          `
        : css`
            background-color: ${colors.highlight};
          `};
  }
`;

const MyPlayerContainer: typeof FixedPlayerContainer = styled(
  FixedPlayerContainer
)`
  && {
    margin-top: ${geometry.pageHeaderHeight + 10}px;
    margin-left: auto;
    margin-right: auto;
  }
`;

function shouldDisablePreview<T: { [string]: string }>(
  values: T,
  validations: ValidationMap<T>,
  playerState: number
): boolean {
  return (
    playerState === 1 || // PLAYING
    playerState === 3 || // BUFFERING
    !isKeyValid("start", values, validations) ||
    !isKeyValid("end", values, validations)
  );
}

const TimestampWrapper = styled.div`
  display: flex;
`;

const TimestampFormField: typeof FormField = styled(FormField)`
  width: 50%;
  input {
    width: auto !important;
  }
`;

const Form = styled.form`
  margin: 0 auto;
  margin-top: 25px;
  max-width: 380px;

  input[type="text"],
  textarea {
    width: 100%;
    box-sizing: border-box;
  }
`;
