/* @flow */

import Overlay from "./Overlay";
import React, { useState, useCallback, useEffect } from "react";
import type { Node } from "react";
import styled from "styled-components";
import ActionLink from "./ActionLink";
import OAuth2Buttons from "./OAuth2Buttons";

import { SubmitButton, ButtonLink } from "./Buttons";

import { safeSessionStorage } from "./safeStorage";
import { useFormState, useFormUtils, useFormNormalizer } from "./WithFormState";
import type { FormState } from "./WithFormState";

import { useEmailAvailabilityCheck } from "./useEmailAvailabilityCheck";
import { useFlatPromise } from "./useFlatPromise";
import type { FlatPromise } from "./useFlatPromise";

import {
  useFormValidations,
  required,
  confirmationOf,
  anyValidationErrors
} from "./useFormValidations";
import { FormField, InputWithIcon } from "./Forms";
import { colors } from "./theme";

import { APIError } from "./APIError";
import { captureException } from "@sentry/browser";

import type { PasswordResetResponse } from "./passwordReset";
import type { SnackbarMessage } from "./useSnackbarQueue";
import type { DocumentLocation } from "./useNavigation";

import { postAccount, postSession, postPasswordReset } from "./apiClient";
import { stringsForLocale } from "../lang/web";

type Props = {
  onClose: string,
  enter: boolean,
  nativeLang: string,
  onAddSnackbarMessage: (message: SnackbarMessage) => void,
  onNavigate: string => void,
  onIdToken: string => void,
  location: DocumentLocation
};

type Tab = "login" | "signup";

function usePasswordAuth(
  tab: Tab
): [FlatPromise<string> | null, (string, string) => void] {
  const signal = useAbortController(tab);
  const [promise, setPromise] = React.useState<Promise<Object> | null>(null);

  // Reset the error state when the tab changes
  useEffect(() => {
    setPromise(null);
  }, [tab]);

  const flatPromise = useFlatPromise(promise);

  const onSubmit = useCallback(
    (email: string, password: string) => {
      const apiFunction = tab === "login" ? postSession : postAccount;

      const promise = apiFunction({ email, password }, { signal }).then(
        response => response.token,
        error => {
          // Report any non-expected errors to Sentry
          const errorString = interpretError(error, tab);
          if (
            errorString === "unexpected_signup_api_error" ||
            errorString === "unexpected_login_api_error"
          ) {
            captureException(error);
          }
          return Promise.reject(error);
        }
      );
      setPromise(promise);
    },
    [tab, signal]
  );

  return [flatPromise, onSubmit];
}

function useAbortController(tab) {
  const [controller, setController] = React.useState<AbortController | null>(
    null
  );

  React.useEffect(() => {
    if ("AbortController" in window) {
      const controller = new AbortController();

      setController(controller);

      return () => {
        controller.abort();
      };
    }
  }, [tab]);

  if (controller == null) {
    return null;
  } else {
    return controller.signal;
  }
}

export default function LoginOverlay(props: Props) {
  return (
    <LoginOverlayStyles
      enter={props.enter}
      onClose={props.onClose}
      orientation="center"
    >
      <AccountForm
        location={props.location}
        nativeLang={props.nativeLang}
        onIdToken={props.onIdToken}
        onNavigate={props.onNavigate}
        onClose={props.onClose}
        onAddSnackbarMessage={props.onAddSnackbarMessage}
      />
    </LoginOverlayStyles>
  );
}

export function ForgotPasswordOverlay(props: Props) {
  return (
    <LoginOverlayStyles
      enter={props.enter}
      onClose={props.onClose}
      orientation="center"
    >
      <ForgotPasswordForm
        nativeLang={props.nativeLang}
        onClose={props.onClose}
      />
    </LoginOverlayStyles>
  );
}

type ErrorState =
  | "invalid_credentials"
  | "email_conflict"
  | "unexpected_login_api_error"
  | "unexpected_signup_api_error"
  | "network_error";

function interpretError(error: Error, tab: Tab): ErrorState {
  if (error instanceof APIError) {
    if (tab === "login" && error.code === 403) {
      return "invalid_credentials";
    }

    if (tab === "signup") {
      if (error.code === 409) {
        return "email_conflict";
      } else {
        return "unexpected_signup_api_error";
      }
    }

    // Assumed that tab === login
    if (error.code === 403) return "invalid_credentials";
    else return "unexpected_login_api_error";
  } else {
    return "network_error";
  }
}

const loginValidations = {
  email: [required],
  password: [required],
  passwordConfirmation: []
};

const signupValidations = {
  email: [required],
  password: [required],
  passwordConfirmation: [confirmationOf("password")]
};

const LoginError = styled.div`
  color: #333;
  background-color: #fac6c6;
  padding: 0.5em;
  margin: 0 -2em 1em;
`;

const FlashMessage = styled.div`
  font-weight: bold;
  margin-bottom: 2em;
  line-height: 150%;
`;

const DividerText = styled.div`
  margin: 1em 0;
  position: relative;

  &:after {
    right: 0;
  }
  &:before {
    left: 0;
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    width: calc(50% - 24px);
    height: 1px;
    background-color: #ddd;
    top: 8px;
  }
`;

const ForgotPasswordBlock = styled.div`
  margin: 0;
  margin-bottom: 1.5em;
  a {
    color: #858585;
  }
`;

const StyledFormField: typeof FormField = styled(FormField)`
  input {
    width: 100%;
  }
`;

const LoginOverlayContent = styled.div`
  padding: 4em 3em;
  text-align: center;
`;

const Tabs = styled.div`
  display: flex;
`;

const TabLink: typeof ActionLink = styled(ActionLink)`
  &:first-child {
    border-right: 1px solid #eee;
  }

  &::after {
    display: block;
    height: 3px;
    width: 62px;
    content: " ";
    margin-left: 57px;
    background-color: ${colors.highlight};
    visibility: ${props => (props.active ? "visible" : "hidden")};
  }

  text-decoration: none;
  color: #333;
  width: 50%;
  text-align: center;
  display: block;
  height: 60px;
  line-height: 60px;

  font-weight: ${props => (props.active ? "bold" : "normal")};
  color: ${props => (props.active ? "#333" : "#aaa")};
`;

export const LoginOverlayStyles: typeof Overlay = styled(Overlay)`
  .content {
    max-width: 355px;
    padding: 0;
  }
`;

// Font-Awesome: Key
const keySvg = (
  <svg width="1792" height="1792" viewBox="0 0 1792 1792">
    <path d="M832 512q0-80-56-136t-136-56-136 56-56 136q0 42 19 83-41-19-83-19-80 0-136 56t-56 136 56 136 136 56 136-56 56-136q0-42-19-83 41 19 83 19 80 0 136-56t56-136zm851 704q0 17-49 66t-66 49q-9 0-28.5-16t-36.5-33-38.5-40-24.5-26l-96 96 220 220q28 28 28 68 0 42-39 81t-81 39q-40 0-68-28l-671-671q-176 131-365 131-163 0-265.5-102.5t-102.5-265.5q0-160 95-313t248-248 313-95q163 0 265.5 102.5t102.5 265.5q0 189-131 365l355 355 96-96q-3-3-26-24.5t-40-38.5-33-36.5-16-28.5q0-17 49-66t66-49q13 0 23 10 6 6 46 44.5t82 79.5 86.5 86 73 78 28.5 41z" />
  </svg>
);

// Font-Awesome: At
const atSvg = (
  <svg width="1792" height="1792" viewBox="0 0 1792 1792">
    <path d="M1100 775q0-108-53.5-169t-147.5-61q-63 0-124 30.5t-110 84.5-79.5 137-30.5 180q0 112 53.5 173t150.5 61q96 0 176-66.5t122.5-166 42.5-203.5zm564 121q0 111-37 197t-98.5 135-131.5 74.5-145 27.5q-6 0-15.5.5t-16.5.5q-95 0-142-53-28-33-33-83-52 66-131.5 110t-173.5 44q-161 0-249.5-95.5t-88.5-269.5q0-157 66-290t179-210.5 246-77.5q87 0 155 35.5t106 99.5l2-19 11-56q1-6 5.5-12t9.5-6h118q5 0 13 11 5 5 3 16l-120 614q-5 24-5 48 0 39 12.5 52t44.5 13q28-1 57-5.5t73-24 77-50 57-89.5 24-137q0-292-174-466t-466-174q-130 0-248.5 51t-204 136.5-136.5 204-51 248.5 51 248.5 136.5 204 204 136.5 248.5 51q228 0 405-144 11-9 24-8t21 12l41 49q8 12 7 24-2 13-12 22-102 83-227.5 128t-258.5 45q-156 0-298-61t-245-164-164-245-61-298 61-298 164-245 245-164 298-61q344 0 556 212t212 556z" />
  </svg>
);

type AccountFormProps = {
  location: DocumentLocation,
  onIdToken: string => void,
  nativeLang: string,
  onClose: string,

  onNavigate: string => void,
  onAddSnackbarMessage: (message: SnackbarMessage) => void
};

type FormValues = {
  email: string,
  password: string,
  passwordConfirmation: string
};

function AccountForm(props: AccountFormProps) {
  const [tab, setTab] = useState<Tab>("login");

  const strings = stringsForLocale(props.nativeLang).account_snackbar_messages;

  const snackbarText =
    tab === "signup" ? strings.signed_up() : strings.logged_in();

  const [flatPromise, submitPassword] = usePasswordAuth(tab);

  const idToken =
    flatPromise != null && flatPromise.state === "resolved"
      ? flatPromise.value
      : null;
  const isPending = flatPromise != null && flatPromise.state === "pending";
  const loginError =
    flatPromise != null && flatPromise.state === "rejected"
      ? flatPromise.error
      : null;

  const { onNavigate, onClose, onAddSnackbarMessage, onIdToken } = props;
  React.useEffect(() => {
    if (idToken != null) {
      onNavigate(onClose);
      onAddSnackbarMessage({ body: snackbarText, level: "message" });
      onIdToken(idToken);
    }
  }, [
    idToken,
    snackbarText,
    onNavigate,
    onClose,
    onAddSnackbarMessage,
    onIdToken
  ]);

  const formProps = useFormState({
    values: {
      email: "",
      password: "",
      passwordConfirmation: ""
    }
  });

  const flashMessage = useFlashMessage();

  return (
    <AccountFormView
      formProps={formProps}
      tab={tab}
      setTab={setTab}
      nativeLang={props.nativeLang}
      onSubmitPassword={submitPassword}
      flashMessage={flashMessage}
      error={loginError ? interpretError(loginError, tab) : null}
      isPending={isPending}
    />
  );
}

type AccountFormViewProps = {
  tab: Tab,
  setTab: Tab => void,
  formProps: FormState<FormValues>,
  error: ErrorState | null,
  flashMessage: string | null,
  nativeLang: string,
  onSubmitPassword: (email: string, password: string) => void,
  isPending: boolean
};

export function AccountFormView(props: AccountFormViewProps) {
  const strings = stringsForLocale(props.nativeLang).login_form;

  function propsForTabLink(tab: Tab) {
    return {
      onActivated: () => props.setTab(tab),
      active: props.tab === tab,
      role: "tab",
      "aria-selected": props.tab === tab ? "true" : "false"
    };
  }

  return (
    <React.Fragment>
      <Tabs>
        <TabLink {...propsForTabLink("login")}>
          {strings.actions.login()}
        </TabLink>
        <TabLink {...propsForTabLink("signup")}>
          {strings.actions.signup()}
        </TabLink>
      </Tabs>
      <LoginOverlayContent>
        <FlashMessage>{props.flashMessage}</FlashMessage>

        <AuthenticationForm
          key={props.tab}
          tab={props.tab}
          formProps={props.formProps}
          nativeLang={props.nativeLang}
          onSubmitPassword={props.onSubmitPassword}
          error={props.error}
          isPending={props.isPending}
        />
      </LoginOverlayContent>
    </React.Fragment>
  );
}

type AuthenticationFormProps = {
  tab: Tab,
  nativeLang: string,
  formProps: FormState<FormValues>,
  error: ErrorState | null,
  isPending: boolean,
  onSubmitPassword: (email: string, password: string) => void
};

function AuthenticationForm(props: AuthenticationFormProps) {
  const validations =
    props.tab === "login" ? loginValidations : signupValidations;

  function onSubmit(event: Event) {
    event.preventDefault();
    props.onSubmitPassword(
      props.formProps.values.email,
      props.formProps.values.password
    );
  }

  const strings = stringsForLocale(props.nativeLang).login_form;

  const emailAvailable = useEmailAvailabilityCheck(
    props.formProps.values.email
  );
  let asyncValidationErrors;
  if (props.tab === "signup" && !emailAvailable) {
    asyncValidationErrors = { email: [strings.errors.email_conflict()] };
  } else {
    asyncValidationErrors = {};
  }

  const [validationErrors, formEventHandlers] = useFormValidations({
    values: props.formProps.values,
    locale: props.nativeLang,
    asyncValidationErrors,
    onSubmit,
    validations
  });

  function oAuth2Text(provider: string): string {
    if (props.tab === "signup") {
      return strings.actions.signup_with_provider({ PROVIDER: provider });
    } else {
      return strings.actions.login_with_provider({ PROVIDER: provider });
    }
  }

  return (
    <form {...formEventHandlers}>
      <OAuth2Buttons
        textFn={oAuth2Text}
        nativeLang={props.nativeLang}
        theme="full"
        location={location}
      />

      <DividerText>{strings.or()}</DividerText>

      {props.error ? (
        <LoginError>{strings.errors[props.error]()}</LoginError>
      ) : null}

      <CredentialFields
        includeConfirmation={props.tab === "signup"}
        formProps={props.formProps}
        nativeLang={props.nativeLang}
        validationErrors={validationErrors}
      />

      {props.tab === "login" ? (
        <ForgotPasswordBlock>
          <a href="#forgot-password">{strings.forgot_password_link()}</a>
        </ForgotPasswordBlock>
      ) : null}

      <SubmitButton
        primary
        disabled={props.isPending}
        value={
          props.tab === "signup"
            ? strings.actions.signup()
            : strings.actions.login()
        }
      />
    </form>
  );
}

import type { ErrorMap } from "./useFormValidations";

type CredentialFieldsProps<T> = {
  includeConfirmation: boolean,
  formProps: FormState<T>,
  validationErrors: ErrorMap<T>,
  nativeLang: string
};

function trimString(string) {
  return string.trim();
}

export function CredentialFields<
  T: { email: string, password: string, passwordConfirmation: string }
>(props: CredentialFieldsProps<T>) {
  const strings = stringsForLocale(props.nativeLang).login_form;

  const formUtils = useFormUtils(props.formProps);
  const onBlur = useFormNormalizer({
    values: props.formProps.values,
    onChange: props.formProps.onChange,
    normalizeFunctions: {
      email: trimString
    }
  });

  return (
    <div onBlur={onBlur}>
      <StyledFormField
        {...formUtils.propsForInput("email")}
        errorMessages={props.validationErrors.email}
        renderInput={props => (
          <InputWithIcon
            {...props}
            type="text"
            placeholder={strings.placeholders.email()}
            icon={atSvg}
          />
        )}
      />
      <StyledFormField
        {...formUtils.propsForInput("password")}
        errorMessages={props.validationErrors.password}
        renderInput={props => (
          <InputWithIcon
            {...props}
            type="password"
            placeholder={strings.placeholders.password()}
            icon={keySvg}
          />
        )}
      />
      {props.includeConfirmation ? (
        <StyledFormField
          {...formUtils.propsForInput("passwordConfirmation")}
          errorMessages={props.validationErrors.passwordConfirmation}
          renderInput={props => (
            <InputWithIcon
              {...props}
              type="password"
              placeholder={strings.placeholders.password_confirmation()}
              icon={keySvg}
            />
          )}
        />
      ) : null}
    </div>
  );
}

function useFlashMessage() {
  const [flashMessage, setFlashMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    const message = safeSessionStorage.getItem(flashMessageStorageKey);
    if (message != null) {
      setFlashMessage(message);
      safeSessionStorage.removeItem(flashMessageStorageKey);
    }
  }, []);

  return flashMessage;
}

type ForgotPasswordFormProps = {
  onClose: string,
  nativeLang: string
};

export function ForgotPasswordForm(props: ForgotPasswordFormProps) {
  const strings = stringsForLocale(props.nativeLang);

  const formProps = useFormState({
    values: { email: "" }
  });

  const formUtils = useFormUtils(formProps);

  const [
    passwordResetResponse,
    setPasswordResetResponse
  ] = React.useState<PasswordResetResponse | null>(null);

  const [promise, setPromise] = React.useState<Promise<Object> | null>(null);
  const validations = {
    email: [required]
  };

  function onSubmit(event) {
    const promise = postPasswordReset(formProps.values.email, props.nativeLang);
    promise.catch(error => captureException(error));
    setPromise(promise);
  }

  const flatPromise = useFlatPromise(promise);

  const status = interpretPasswordResetStatus(flatPromise);

  const [validationErrors, formEventHandlers] = useFormValidations({
    values: formProps.values,
    locale: props.nativeLang,
    onSubmit,
    validations
  });

  if (status) {
    return (
      <PasswordResetFinished
        emailAddress={formProps.values.email}
        status={status}
        onClose="#"
        nativeLang={props.nativeLang}
      />
    );
  } else {
    return (
      <LoginOverlayContent>
        <form onSubmit={onSubmit} {...formEventHandlers}>
          <PasswordResetText>
            {strings.password_reset_form.instructions()}
          </PasswordResetText>

          <StyledFormField
            {...formUtils.propsForInput("email")}
            errorMessages={validationErrors.email}
            renderInput={props => (
              <InputWithIcon
                {...props}
                type="text"
                placeholder={strings.login_form.placeholders.email()}
                inputmode="email"
                icon={atSvg}
              />
            )}
          />

          <SubmitButton
            primary
            disabled={promise != null}
            value={strings.password_reset_form.actions.send_email()}
          />
        </form>
      </LoginOverlayContent>
    );
  }
}

type PasswordResetFinishedProps = {
  status: PasswordResetStatus,
  nativeLang: string,
  emailAddress: string,
  onClose: string
};

import type { EmailResult } from "./passwordReset";
type PasswordResetStatus = "delivery_error" | "network_error" | EmailResult;

export function PasswordResetFinished(props: PasswordResetFinishedProps) {
  const strings = stringsForLocale(props.nativeLang).password_reset_form;

  const text =
    props.status === "network_error"
      ? strings.finished_status.network_error()
      : strings.finished_status[props.status]({
          EMAIL_ADDRESS: props.emailAddress
        });

  return (
    <LoginOverlayContent>
      <PasswordResetText>{text}</PasswordResetText>
      <ButtonLink href={props.onClose}>{strings.actions.ok()}</ButtonLink>
    </LoginOverlayContent>
  );
}

const PasswordResetText = styled.div`
  line-height: 150%;
  margin-bottom: 1em;
`;

function interpretPasswordResetStatus(
  response: FlatPromise<PasswordResetResponse> | null
): PasswordResetStatus | null {
  if (response != null) {
    if (response.state === "rejected") {
      if (response.error instanceof APIError) {
        return "delivery_error";
      } else {
        return "network_error";
      }
    } else if (response.state === "resolved") {
      return response.value.result;
    }
  }

  return null;
}

const flashMessageStorageKey = "LoginOverlay-flashMessage";

export function setFlashMessage(message: string) {
  safeSessionStorage.setItem(flashMessageStorageKey, message);
}
