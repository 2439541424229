
module.exports = {
  about_page: {
    headline: function(d) { return "CaptionPopは、語学学習者向けにYoutubeを最も効果的に活用したとっても便利なサービスです。"; },
    feature_list: {
      "0": function(d) { return "複数の字幕つき動画を同時に視聴。"; },
      "1": function(d) { return "ワンクリックのキー操作で前のフレーズをリピート再生。"; },
      "2": function(d) { return "動画をゆっくり再生。"; },
      "3": function(d) { return "お気に入りのフレーズをブックマークして後で学習。"; },
      "4": function(d) { return "字幕つきYouTube動画と連携。"; }
    },
    get_started_header: function(d) { return "さあ、始めましょう！"; },
    leave_feedback_header: function(d) { return "お問い合わせ"; },
    leave_feedback_prompt: function(d) { return "最新情報の通知をご希望の方、またはご意見・ご感想がある方は是非お聞かせください。"; },
    leave_feedback_action: function(d) { return "お問い合わせ"; },
    find_videos_action: function(d) { return "動画を検索"; },
    closing_paragraph: function(d) { return "私は、自身の韓国語のリスニング力を向上させるために、CaptionPopを立ち上げました。CaptionPopは、私のリスニング力向上に大いに役立ちました。皆さまにもCaptionPopをご活用いただけましたら幸いです。"; },
    postscript: function(d) { return "追伸：ご質問、コメント、必要な機能のご要望がございましたら、いつでも私宛てにメールをお送りください。語学学習者の皆さまからのお便りを楽しみにしています。"; },
    get_started: {
      target_select_prompt: function(d) { return "学びたい言語が見つかりませんか？CaptionPopでは、YouTubeがサポートしている" + d.LENGTH + "の言語を扱っています。以下から学びたい言語を選んでください！"; },
      target_select_placeholder: function(d) { return "言語を選ぶ"; },
      my_native_language_is: function(d) { return "私の母語はLANGUAGEで、学びたい言語は："; }
    }
  },
  choose_languages: {
    select_native_lang_prompt: function(d) { return "あなたの母語は何ですか?"; },
    select_target_lang_prompt: function(d) { return "学びたい言語は何ですか?"; },
    update_action: function(d) { return "更新"; },
    featured_languages: function(d) { return "注目の言語"; },
    all_languages: function(d) { return "すべての言語"; },
    select_placeholder: function(d) { return "選択してください..."; }
  },
  play_videos_page: {
    add_to_library_action: function(d) { return "ライブラリに追加"; },
    remove_from_library_action: function(d) { return "ライブラリから削除"; },
    no_available_subtitles: function(d) { return "申し訳ないのですが、この動画にはご利用いただける字幕がございません。"; },
    library_icon_label: function(d) { return "ライブラリ"; },
    translation_upgrade_prompt: function(d) { return "CaptionPop Premiumでは、ハイライトした単語やフレーズがGoogle Translateを通して即座に翻訳されます。"; },
    translation_upgrade_call_to_action: function(d) { return "アップグレード"; },
    blur_captions_label: function(d) { return "キャプションをぼかす"; },
    pause_after_caption_label: function(d) { return "キャプションごとに一時停止"; },
    only_one_subtitle_language: function(d) { return "このビデオの字幕は" + d.LANGUAGE + "のみ利用可能です。"; }
  },
  favorites_page: {
    blur_captions_label: function(d) { return d.LANGUAGE + "キャプションをぼかす"; }
  },
  search_page: {
    page_title: function(d) { return "YouTubeで" + d.TARGET_LANG + "の動画を検索"; },
    meta_description: function(d) { return "YouTubeで" + d.NATIVE_LANG + "と" + d.TARGET_LANG + "の両方の字幕付きの動画を検索。二つの字幕を同時に表示させながらの視聴が可能。"; },
    meta_description_generic: function(d) { return "YouTube動画の中から、複数の字幕が設定されているものを検索。二つの字幕を同時に表示させながらの視聴が可能。"; },
    filter_header: function(d) { return "字幕言語でフィルターにかけましょう。"; },
    show_all: function(d) { return "すべて表示"; },
    more_action: function(d) { return "もっと見る"; },
    end_of_results: function(d) { return "検索結果の最後"; },
    empty_search_results: {
      no_videos: function(d) { return "動画が見つかりませんでした。"; },
      singular_video_dual_filter: function(d) { return "1件の動画が見つかりましたが、 " + d.FILTER_LANGUAGE_A + "と" + d.FILTER_LANGUAGE_B + "の字幕が付いていません。"; },
      singular_video_single_filter: function(d) { return "1件の動画が見つかりましたが、" + d.FILTER_LANGUAGE + "の字幕が付いていません。"; },
      plural_videos_single_filter: function(d) { return d.VIDEO_COUNT + "件の動画が見つかりましたが、いずれも" + d.FILTER_LANGUAGE + "の字幕が付いていません。"; },
      plural_videos_dual_filter: function(d) { return d.VIDEO_COUNT + "件の動画が見つかりましたが、 いずれも" + d.FILTER_LANGUAGE_A + "と" + d.FILTER_LANGUAGE_B + "の字幕が付いていません"; }
    }
  },
  search_form: {
    search_action: function(d) { return "検索"; },
    search_header: function(d) { return "YouTube のすべての動画を検索"; },
    tutorials_header: function(d) { return "CaptionPopのチュートリアル"; },
    filter_by_subtitle_header: function(d) { return "字幕で絞り込む"; },
    filter_by_city_header: function(d) { return "都市で絞り込む"; },
    placeholder: function(d) { return " " + d.LANGUAGE + " の検索語または YouTube の URL "; },
    change_language_link: function(d) { return "その他の言語"; },
    featured_playlists_header: function(d) { return "おすすめの " + d.LANGUAGE + " プレイリストを見る"; },
    featured_channels_header: function(d) { return "おすすめの " + d.LANGUAGE + " チャンネルを見る"; },
    suggested_cities: function(d) { return "推奨される都市"; },
    all_cities: function(d) { return "すべての都市"; },
    premium_button: function(d) { return "Premiumにサインアップ"; },
    tutorials_caption: function(d) { return "始めるのにお手伝いが必要ですか？"; },
    premium_cia: function(d) { return "CaptionPopプレミアムで学習を効率化しましょう。"; },
    chrome_extension_banner: {
      header: function(d) { return "YouTube.com.の動画を視聴しながらCaptionPopを体感しよう"; },
      line1: function(d) { return "Chrome用のCaptionPopブラウザ拡張機能をインストールして下さい。"; },
      line2: function(d) { return "インストール後、YouTubeの各動画には、その動画で利用可能な字幕リストが表示されます。字幕リストをクリックするとCaptionPopプレーヤーにリンクされるので、一度に複数の字幕言語で視聴することができます。"; },
      install_action: function(d) { return "インストール"; }
    }
  },
  keyboard_shortcuts: {
    next_line_tooltip: function(d) { return "次の行"; },
    previous_line_tooltip: function(d) { return "前の行"; },
    toggle_playback_tooltip: function(d) { return "再生 / 一時停止"; },
    show_translation_tooltip: function(d) { return "翻訳を表示"; },
    repeat_tooltip: function(d) { return "繰り返し"; }
  },
  page_header: {
    home_action: function(d) { return "ホーム"; },
    login_action: function(d) { return "ログイン"; },
    logout_action: function(d) { return "ログアウト"; },
    favorites_link: function(d) { return "お気に入り"; },
    premium_action: function(d) { return "Premiumにアップグレード"; }
  },
  page_footer: {
    about_link: function(d) { return "会社概要"; },
    contact_link: function(d) { return "お問い合わせ"; },
    terms_of_service_link: function(d) { return "サービス利用規約"; },
    privacy_policy_link: function(d) { return "個人情報保護方針"; }
  },
  page_not_found: function(d) { return "ページが見つかりません"; },
  caption_popup_options: {
    edit: function(d) { return "字幕を編集する"; },
    jump_to: function(d) { return "原語に切り替える"; },
    remove: function(d) { return "お気に入りから削除する"; }
  },
  edit_caption_form: {
    header: function(d) { return "字幕を編集する"; },
    labels: {
      start_time: function(d) { return "開始時間"; },
      end_time: function(d) { return "終了時間"; },
      transcription: function(d) { return "文字起こし"; },
      translations: function(d) { return "翻訳"; }
    },
    actions: {
      save_changes: function(d) { return "変更を保存する"; },
      nevermind: function(d) { return "キャンセル"; }
    }
  },
  form_validations: {
    required: function(d) { return "必須"; },
    too_big: function(d) { return "動画終了時間より前にして下さい"; },
    comes_after_start: function(d) { return "開始時間より後にして下さい"; }
  },
  navigation: {
    video_library_link: function(d) { return "動画ライブラリ"; },
    favorite_captions_link: function(d) { return "お気に入りのキャプション"; },
    account_link: function(d) { return "アカウント"; },
    flash_cards_link: function(d) { return "フラッシュカード"; }
  },
  snackbar: {
    video_added_to_library: function(d) { return "この動画をあなたの動画ライブラリーに追加しました。"; },
    video_removed_from_library: function(d) { return "この動画をあなたの動画ライブラリーから削除しました。"; },
    added_to_favorite_captions: function(d) { return "あなたのお気に入り字幕リストにこの字幕を追加しました。"; },
    removed_from_favorite_captions: function(d) { return "あなたのお気に入り字幕リストからこの字幕を削除しました。"; },
    updated_favorite_caption: function(d) { return "変更内容が保存されました。"; },
    network_error: function(d) { return "インターネットの接続状況をご確認下さい。"; },
    playlist_added_to_library: function(d) { return "こちらのプレイリストは、お客様のライブラリーに追加されています。"; },
    playlist_removed_from_library: function(d) { return "こちらのプレイリストは、お客様のライブラリーから削除されています。"; },
    channel_added_to_library: function(d) { return "こちらのチャンネルは、お客様のライブラリーに追加されています。"; },
    channel_removed_from_library: function(d) { return "こちらのチャンネルは、お客様のライブラリーから削除されています。"; }
  },
  flash_cards: {
    days_count: function(d) { return d.COUNT + " 日"; },
    logged_out_warning: function(d) { return "CaptionPopにログインして進捗を記録するデータを保存してください。ログインせずにブラウザを閉じるとデータは失われます。"; },
    splash_page: {
      title: function(d) { return "CaptionPop: インタラクティブ・フラッシュカード"; },
      introduction: function(d) { return "インタラクティブなフラッシュカードでリスニング力を向上させましょう。"; },
      feature_list: {
        "0": function(d) { return "YouTube動画で保存したフレーズをもとに、フラッシュカードで復習しましょう。"; },
        "1": function(d) { return "タイプすると、誤りがカラーで表示されるのでチェックしましょう。"; },
        "2": function(d) { return "難しい場合はヒントを参考にしましょう。"; },
        "3": function(d) { return "フラッシュカードは自動で採点されます。"; },
        "4": function(d) { return "反復練習方法として、同じフラッシュカードが後に再表示されます。"; }
      },
      calls_to_action: {
        header: function(d) { return "用意はいいですか？"; },
        demo_decks_text: function(d) { return "以下のデモ用フラッシュカードをお試しください。"; },
        no_favorites: {
          text: function(d) { return "またはYouTubeで見つけたフレーズをもとに、自分だけのオリジナルフラッシュカードを作成してください。"; },
          button: function(d) { return "動画を検索"; }
        },
        logged_in_with_favorites: {
          text: function(d) { return "あなたはすでに" + d.COUNT + " の字幕をYouTubeから保存しています。これらをインターアクティブなフラッシュカードとしてレビューできます！"; },
          button: function(d) { return "レビューする"; }
        }
      }
    },
    instructions: {
      finish_pass: function(d) { return "また試してください。"; },
      finish_complete: function(d) { return "よくできました! "; },
      playing_state: function(d) { return "よく聴いてください。"; },
      quiz_state: function(d) { return "聴いたことをタイプしてください。"; },
      before_playback: function(d) { return "プレイを押して聴いてください。"; }
    },
    hint_tip: function(d) { return "グレイボックスをクリックしてヒントを参照。"; },
    actions: {
      skip: function(d) { return "スキップ。"; },
      finish: function(d) { return "終了。"; },
      more_to_review: function(d) { return d.COUNT + "個 レビューが残っています。"; },
      replay: function(d) { return "リプレイ"; }
    },
    previous_attempts_list: {
      header: function(d) { return "以前のトライ"; },
      hints_used: function(d) { return "使用されたヒントか"; },
      time_takes: function(d) { return "かった時間"; },
      score: function(d) { return "スコア"; }
    },
    finished: {
      edit_action: function(d) { return "編集する"; },
      previous_attempts_header: function(d) { return "以前のトライ"; },
      time_taken: function(d) { return "かかった時間"; },
      hints_used: function(d) { return "使用されたヒント"; },
      due_again: function(d) { return "再度締め切り"; },
      score: function(d) { return "スコア"; }
    },
    dashboard: {
      header: function(d) { return "フラッシュカード"; },
      card_count: function(d) { return "合計" + d.TOTAL_COUNT + "個のうち " + d.REVIEWED_COUNT + " 個が最近レビューされました。"; },
      next_card_due: function(d) { return "次のカードのレビュー締切まで " + d.TIMER + " このデッキは空です。"; },
      empty_deck: function(d) { return "すべてをレビューする。"; },
      actions: {
        review_random: function(d) { return "ランダムにカードをレビューする。 "; },
        create_custom_deck: function(d) { return "カスタムデッキを作る。"; },
        edit_deck: function(d) { return "デッキを編集する。"; },
        review_all: function(d) { return "全て復習"; },
        review_deck: function(d) { return "復習"; },
        search: function(d) { return "動画検索"; }
      },
      saved_captions_deck: function(d) { return "YouTubeで保存したフレーズ"; },
      free_demo_deck_header: function(d) { return "無料お試し版フラッシュカード"; },
      custom_deck_header: function(d) { return "カスタムフラッシュカード"; },
      tip_header: function(d) { return "お役立ち情報"; },
      search_videos_tip: function(d) { return "CaptionPopでは、どんな動画も全てフラッシュカードの作成ができます。"; },
      custom_deck_tip: function(d) { return "カスタムスタデイで自分だけのフレーズをまとめることもできます。"; },
      login_tip: function(d) { return "CaptionPopアカウントにログインして、YouTube動画でフラッシュカードを作成しましょう！"; }
    },
    form: {
      create_header: function(d) { return "フラッシュカードのデッキを作成する。"; },
      update_header: function(d) { return "フラッシュカードのデッキを更新する。"; },
      name_of_deck_header: function(d) { return "デッキの名前"; },
      select_captions_header: function(d) { return "字幕を選択する。"; },
      selected_count: function(d) { return d.COUNT + " 個選択されました。"; },
      select_multiple_captions: function(d) { return d.COUNT + " 個の字幕を選択する。"; },
      unselect_multiple_captions: function(d) { return d.COUNT + "個の字幕の選択しない。"; },
      actions: {
        update: function(d) { return "デッキを更新する。"; },
        create: function(d) { return "デッキを作成する。"; },
        "delete": function(d) { return "デッキを消去する。"; }
      },
      confirmation: function(d) { return "本当にこの動作を行いますか? "; },
      snackbar: {
        created: function(d) { return "新しいデッキが作成されました。"; },
        deleted: function(d) { return "デッキが消去されました。"; },
        updated: function(d) { return "デッキが更新作成されました。"; },
        error: function(d) { return "デッキの保存時にエラーが発生しました。"; }
      }
    },
    paywall_overlay: {
      header: function(d) { return "本日の無料トライアルが終了。"; },
      text: function(d) { return "タイマーのリセットを待つか、無制限のアクセスができるようCaptionPopプレミアムにアップグレードしてください。"; },
      call_to_action: function(d) { return "アップグレードする。"; }
    },
    free_trial_banner: {
      flash_cards_remaining: function(d) { return "あと" + d.COUNT + "が本日のフリートライアルで 使用できる残りの数です。"; },
      upgrade_action: function(d) { return "アップグレードする。"; }
    },
    premium_subscription: {
      header: function(d) { return "CaptionPopプレミアムプランに申し込む。"; },
      product_description: function(d) { return "自分のお気に入りのYouTube動画を利用して作ったインターアクティブフラッシュカードで、リスニング力をアップしましょう。"; },
      form_headers: {
        plan: function(d) { return "プランを選択してください。"; },
        credit_card: function(d) { return "クレジットカード 選択する。"; },
        create_an_account: function(d) { return "アカウント作成"; }
      },
      calls_to_action: {
        choose: function(d) { return "購入 "; },
        purchase: function(d) { return "月額 "; }
      },
      plans: {
        month: {
          title: function(d) { return "毎月請求いたします。最低価格! "; },
          description: function(d) { return "年間 "; },
          long_description: function(d) { return "「購入」をクリックすると、クレジットカードに" + d.AMOUNT + "が本日付けで請求されます。その後、毎月同日に" + d.AMOUNT + "が引き落としされます。解除をご希望の場合、ワンクリックでいつでもキャンセルすることができます。"; }
        },
        year: {
          title: function(d) { return "毎年一度請求いたします。 "; },
          description: function(d) { return d.PERCENT + "% 月額よりお得になります! "; },
          long_description: function(d) { return "「購入」をクリックすると、クレジットカードに" + d.AMOUNT + "が本日付けで請求されます。その後、毎年同日に" + d.AMOUNT + "が引き落としされます。解除をご希望の場合、ワンクリックでいつでもキャンセルすることができます。"; }
        },
        lifetime: {
          title: function(d) { return "生涯 "; },
          description: function(d) { return "毎月請求いたします。最低価格! "; },
          long_description: function(d) { return "購入をクリックすると、クレジットカードに本日付けで" + d.AMOUNT + "が請求されます。その後の請求は一切なく、生涯自由にお楽しみ頂けます！"; }
        }
      },
      confirmation: {
        header: function(d) { return "ありがとうございます! "; },
        paragraph1: function(d) { return "あなたは、<b>CaptionPop プレミアム!</bプランに加入しました。"; },
        paragraph2: function(d) { return "これであなたはCaptionPop's の自動で繰り返すインターアクティブフラッシュカードを無制限に使用できるようになりました。これは私個人のリスニング力をつけてくれたもので、あなたも同じような成果をあげられることを願っています。定期購入のプランはいつでもキャンセルすることができます。"; },
        paragraph3: function(d) { return "キャンセルする場合は、この<a href=\"/account\">account page</a>ページからどうぞ。"; },
        creator_title: function(d) { return "CaptionPopの作者より　"; },
        call_to_action: function(d) { return "フラッシュカード"; },
        videos_action: function(d) { return "動画検索"; },
        flash_cards_action: function(d) { return "フラッシュカードを勉強"; }
      },
      available_currencies: function(d) { return d.CURRENCY_LIST + " でもご利用いただけます。"; },
      errors: {
        banner_header: function(d) { return "購入が完了できませんでした。"; },
        network_error: function(d) { return "インターネット接続に問題が生じたため、購入が完了できませんでした。"; },
        unexpected_error: function(d) { return "クレジットカードへの請求は完了しておりません。 CaptionPopの開発者に通知されました。再度お試しください。"; }
      },
      account: {
        choose_password: function(d) { return "パスワードを選択してください。"; },
        provider_signup: function(d) { return "または以下のアカウントを通してサインアップを行ってください。"; },
        already_have_account: function(d) { return "すでにアカウントをお持ちですか？"; },
        logged_in_as: function(d) { return "お客様は " + d.EMAIL + " でログインしています。"; },
        not_you: function(d) { return "ご本人でない場合"; }
      }
    }
  },
  premium_splash_page: {
    page_title: function(d) { return "CaptionPop Premiumを購読する"; },
    header1: function(d) { return "高度な学習機能を利用して学習プロセスを加速しましょう！"; },
    free_column_header: function(d) { return "無料"; },
    premium_column_header: function(d) { return "Premium"; },
    feature1: function(d) { return "複数字幕で視聴"; },
    feature2: function(d) { return "字幕言語でYouTube検索を絞り込む"; },
    feature3: function(d) { return "翻訳するテキストをハイライトで表示する"; },
    feature4: function(d) { return "インタラクティブ・フラッシュカード"; },
    free_flash_card_limit: function(d) { return "1日5回"; },
    header2: function(d) { return "翻訳するテキストをハイライトで表示する。"; },
    google_translate_description: function(d) { return "母語で翻訳する単語やフレーズをハイライト表示。翻訳はGoogle Translateを通して行われます。"; },
    header3: function(d) { return "リスニング力アップに絶大に効果のあるフラッシュカードを使いたい放題。"; },
    call_to_action: function(d) { return "Premiumにサインアップ"; }
  },
  login_form: {
    favorite_prompt: function(d) { return "お気に入りの字幕が保存できるようログインしてください。"; },
    placeholders: {
      email: function(d) { return "メールアドレス"; },
      password: function(d) { return "パスワード"; },
      current_password: function(d) { return "現在のパスワード"; },
      password_confirmation: function(d) { return "パスワードの確認"; }
    },
    forgot_password_link: function(d) { return "パスワードをお忘れですか？"; },
    or: function(d) { return "または"; },
    actions: {
      login: function(d) { return "ログイン"; },
      signup: function(d) { return "サインアップ"; },
      login_with_provider: function(d) { return d.PROVIDER + " でログイン"; },
      signup_with_provider: function(d) { return d.PROVIDER + " でサインアップ"; }
    },
    errors: {
      invalid_credentials: function(d) { return "メールアドレスまたはパスワードが異なります。"; },
      email_conflict: function(d) { return "こちらのメールアドレスで登録されたアカウントがすでに存在しています。"; },
      unexpected_login_api_error: function(d) { return "ログインに問題が生じました。"; },
      unexpected_signup_api_error: function(d) { return "アカウント作成に問題が生じました。"; },
      network_error: function(d) { return "インターネットの接続状況をご確認下さい。"; }
    },
    library_prompt: function(d) { return "ログインしてください。そうすると、ライブラリーにアイテムを保存できます。"; }
  },
  password_reset_form: {
    instructions: function(d) { return "メールアドレスを入力して下さい。パスワード再設定メールがお客様に送信されます。"; },
    actions: {
      send_email: function(d) { return "メールを送信"; },
      ok: function(d) { return "OK"; }
    },
    finished_status: {
      instructions_sent: function(d) { return "パスワード再設定メールは " + d.EMAIL_ADDRESS + "に送信されました。"; },
      rate_limited: function(d) { return "パスワード再設定メールは既に " + d.EMAIL_ADDRESS + "に送信されました。メール申請を再度ご希望の場合、1時間以上お待ちになってからお試しください。"; },
      network_error: function(d) { return "サーバーへの接続に問題が生じました。インターネットの接続状況をご確認下さい。"; },
      delivery_error: function(d) { return d.EMAIL_ADDRESS + "にパスワード再設定メールが送信できませんでした。入力したメールアドレスが有効であることをご確認下さい。"; }
    }
  },
  password_reset_invalid_page: {
    header: function(d) { return "無効なパスワード再設定リンク"; },
    text: function(d) { return "パスワード再設定リンクは、24時間のみ有効です。"; },
    actions: {
      home: function(d) { return "ホーム"; },
      request_new: function(d) { return "新しい再設定リンクを申請"; }
    }
  },
  change_password_page: {
    password_reset_instructions: function(d) { return "アカウントにアクセスを行うため、新しいパスワードを入力してください。"; },
    change_password_header: function(d) { return "パスワードを変更する"; },
    change_password_action: function(d) { return "パスワードを変更"; },
    password_updated_confirmation: function(d) { return "パスワードが更新されました。"; },
    network_error: function(d) { return "インターネットの接続状況に問題が生じ、パスワードが更新できませんでした。"; }
  },
  account_snackbar_messages: {
    logged_in: function(d) { return "ログインしました"; },
    logged_out: function(d) { return "ログアウトしました"; },
    signed_up: function(d) { return "アカウント作成が完了しました。"; },
    subscription_cancelled: function(d) { return "購読が解除されました。"; },
    subscription_reactivated: function(d) { return "再購読されました。"; }
  },
  library_page: {
    search_action: function(d) { return "動画検索"; },
    headers: {
      playlists: function(d) { return "再生リスト"; },
      channels: function(d) { return "チャンネル"; },
      videos: function(d) { return "動画"; }
    },
    empty_header: function(d) { return "お客様のライブラリーは空っぽです。"; },
    empty_description: function(d) { return "お気に入りの動画やチャンネル、プレイリストをここで保存できます。"; }
  },
  account_page: {
    page_header: function(d) { return "マイアカウント"; },
    current_plan_header: function(d) { return "現在のプラン"; },
    free_plan: function(d) { return "無料"; },
    monthly_plan_pricing: function(d) { return d.AMOUNT + " /1ヶ月"; },
    yearly_plan_pricing: function(d) { return d.AMOUNT + " /1年間"; },
    subscription_ends_on: function(d) { return "お客様の購読は" + d.END_DATE + "で終了します"; },
    subscription_renews_on: function(d) { return "お客様の購読は自動的に" + d.RENEW_DATE + "に更新されます"; },
    cancel_subscription_action: function(d) { return "購読を解約"; },
    reactivate_subscription_action: function(d) { return "再購読する"; },
    subscription_type_basic: function(d) { return "CaptionPop Basic"; },
    subscription_type_premium: function(d) { return "CaptionPop Premium"; },
    subscription_type_lifetime: function(d) { return "CaptionPop Life time"; },
    language_settings_header: function(d) { return "言語設定"; },
    native_language_header: function(d) { return "母国語"; },
    target_language_header: function(d) { return "ターゲット言語"; },
    native_language_update_confirmation: function(d) { return "母国語が更新されました。"; },
    target_language_update_confirmation: function(d) { return "ターゲット言語が更新されました。"; },
    payment_history_header: function(d) { return "決済履歴"; },
    receipt_link: function(d) { return "領収書"; },
    credit: function(d) { return "金額" + d.AMOUNT; },
    credit_card_description: function(d) { return d.CREDIT_CARD_BRAND + " 下4桁の番号: " + d.LAST4; },
    credit_card_expires: function(d) { return "有効期限: " + d.MONTH + "/" + d.YEAR; },
    payment_method_header: function(d) { return "決済方法"; },
    update_payment_method_action: function(d) { return "クレジットカードを更新"; },
    payment_method_update_confirmation: function(d) { return "決済方法が更新されました。"; },
    cancel_action: function(d) { return "キャンセル"; },
    update_action: function(d) { return "更新"; },
    none: function(d) { return "なし"; }
  },
  cancel_subscription_page: {
    page_header: function(d) { return "購読は解約されました"; },
    confirmation: function(d) { return "どうもありがとうございます。ユーザーの皆様のフィードバックは、CaptionPopのサービスの向上に役立ちます。"; },
    paragraph1: function(d) { return "CaptionPop Premiumの購読はキャンセルされました。お客様の現在の請求期間が終了するまで、CaptionPop Premiumのすべての機能にアクセスすることができます。"; },
    paragraph2: function(d) { return "気が変わった方は、アカウントページからいつでも再購読することができます。"; },
    feedback_header: function(d) { return "解約した理由を教えてください。"; },
    feedback_prompt: function(d) { return "CaptionPopのサービスの向上のため、お客様が解約を決めた理由の共有にご協力ください。"; },
    reason_too_expensive: function(d) { return "価格が高すぎる"; },
    reason_not_useful: function(d) { return "CaptionPop Premiumは便利ではない"; },
    reason_other: function(d) { return "その他の理由"; },
    comments_header: function(d) { return "他に何かご意見やご提案はございますか？"; },
    cancel_action: function(d) { return "いいえ、ありません"; },
    submit_action: function(d) { return "フィードバックを送信"; },
    reason_required: function(d) { return "理由をお選びください。"; }
  }
}