/* @flow */
/*eslint no-console: ["error", { allow: ["warn"] }] */

// These utility functions are used to safely access localStorage and sessionStorage. They will
// fail gracefully and silently if they are blocked by an extension or browser setting.

export function isLocalStorageAvailable(functionName: string): boolean {
  return (
    window.localStorage != null &&
    typeof localStorage[functionName] === "function"
  );
}

export function isSessionStorageAvailable(functionName: string): boolean {
  return (
    window.sessionStorage != null &&
    typeof sessionStorage[functionName] === "function"
  );
}

export const safeLocalStorage = {
  getItem(key: string): ?string {
    try {
      if (isLocalStorageAvailable("getItem")) {
        return localStorage.getItem(key);
      } else {
        return null;
      }
    } catch (error) {
      console.warn(error);
      return null;
    }
  },

  removeItem(key: string) {
    try {
      if (isLocalStorageAvailable("removeItem")) {
        return localStorage.removeItem(key);
      }
    } catch (error) {
      console.warn(error);
    }
  },

  setItem(key: string, value: string) {
    try {
      if (isLocalStorageAvailable("setItem")) {
        return localStorage.setItem(key, value);
      }
    } catch (error) {
      console.warn(error);
    }
  }
};

export const safeSessionStorage = {
  getItem(key: string): ?string {
    try {
      if (isSessionStorageAvailable("getItem")) {
        return sessionStorage.getItem(key);
      } else {
        return null;
      }
    } catch (error) {
      console.warn(error);
      return null;
    }
  },

  removeItem(key: string) {
    try {
      if (isSessionStorageAvailable("removeItem")) {
        return sessionStorage.removeItem(key);
      }
    } catch (error) {
      console.warn(error);
    }
  },

  setItem(key: string, value: string) {
    try {
      if (isSessionStorageAvailable("setItem")) {
        return sessionStorage.setItem(key, value);
      }
    } catch (error) {
      console.warn(error);
    }
  }
};
