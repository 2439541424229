/* @flow */

import Overlay from "./Overlay";
import * as React from "react";
import styled from "styled-components";

import { stringsForLocale } from "../lang/web";

import ChooseLanguagesForm from "./ChooseLanguagesForm";

type Props = {
  onClose?: string,
  targetLang: ?string,
  nativeLang: string,
  onChangeNativeLang: (lang: string) => void,
  onChangeTargetLang: (lang: string) => void,
  onNavigate: string => void,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  enter: boolean
};

export default class ChooseLanguagesOverlay extends React.Component<Props> {
  onChangeTargetLang(targetLang: string) {
    // It's possible that onChangeTargetLang will trigger a navigation, so we want to do that on a different tick.
    setTimeout(() => {
      this.props.onChangeTargetLang(targetLang);
    }, 0);

    if (this.props.onClose) this.props.onNavigate(this.props.onClose);
  }

  render() {
    const strings = stringsForLocale(this.props.nativeLang);

    // This is a hack to get defaultOpen out of props without adding it to the flow definitions. This is
    // only used by storybook.
    const defaultOpen =
      typeof (this.props: Object).defaultOpen === "boolean"
        ? (this.props: Object).defaultOpen
        : false;

    return (
      <StyledOverlay
        onClose={this.props.onClose}
        enter={this.props.enter}
        orientation="center"
      >
        <ChooseLanguagesForm
          targetLang={this.props.targetLang}
          nativeLang={this.props.nativeLang}
          onChangeTargetLang={this.onChangeTargetLang.bind(this)}
          onChangeNativeLang={this.props.onChangeNativeLang}
          youtubeLanguages={this.props.youtubeLanguages}
          submitLabel={strings.choose_languages.update_action()}
          defaultOpen={defaultOpen}
        />
      </StyledOverlay>
    );
  }
}

const StyledOverlay: typeof Overlay = styled(Overlay)`
  select {
    display: block;
  }
`;
