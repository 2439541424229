/* @flow */

import * as React from "react";

/*
  this is a first pass at a stongly typed compose function for React render
  props. 
  TODO:
    - I don't like that you have to annotate the final render function
       - One solution would be to just make a bunch of wrapper functions like
          WithComposed1, WithComposed2, WithComposed3
    - Consider name: WithRenderPipe
    - Consider removing input prop and requiring functions to pass through things
*/

type RenderFunction<INPUT, OUTPUT> = ({
  input: INPUT,
  render: OUTPUT => React.Node
}) => React.Node;

export default function WithComposed<A, B, C, D, E, F>(
  props:
    | Props1<A>
    | Props2<A, B>
    | Props3<A, B, C>
    | Props4<A, B, C, D>
    | Props5<A, B, C, D, E>
    | Props6<A, B, C, D, E, F>
): React.Node {
  return recurse({
    input: [],
    list: props.list,
    render: props.render
  });
}

export function WithComposed1<A>(props: Props1<A>): React.Node {
  return recurse({
    input: [],
    list: props.list,
    render: props.render
  });
}

export function WithComposed2<A, B>(props: Props2<A, B>): React.Node {
  return recurse({
    input: [],
    list: props.list,
    render: props.render
  });
}

export function WithComposed3<A, B, C>(props: Props3<A, B, C>): React.Node {
  return recurse({
    input: [],
    list: props.list,
    render: props.render
  });
}

export function WithComposed4<A, B, C, D>(
  props: Props4<A, B, C, D>
): React.Node {
  return recurse({
    input: [],
    list: props.list,
    render: props.render
  });
}

export function WithComposed5<A, B, C, D, E>(
  props: Props5<A, B, C, D, E>
): React.Node {
  return recurse({
    input: [],
    list: props.list,
    render: props.render
  });
}

export function WithComposed6<A, B, C, D, E, F>(
  props: Props6<A, B, C, D, E, F>
): React.Node {
  return recurse({
    input: [],
    list: props.list,
    render: props.render
  });
}

export function WithComposed7<A, B, C, D, E, F, G>(
  props: Props7<A, B, C, D, E, F, G>
): React.Node {
  return recurse({
    input: [],
    list: props.list,
    render: props.render
  });
}

export function WithComposed8<A, B, C, D, E, F, G, H>(
  props: Props8<A, B, C, D, E, F, G, H>
): React.Node {
  return recurse({
    input: [],
    list: props.list,
    render: props.render
  });
}

type RecurseProps = {
  input: $ReadOnlyArray<any>,
  list: $ReadOnlyArray<(any) => React.Node>,
  render: any => React.Node
};

function recurse(props: RecurseProps): React.Node {
  if (props.list.length == 0) {
    return props.render(props.input);
  } else {
    const render = props.list[0];

    return render({
      input: props.input,
      render: output =>
        recurse({
          input: props.input.concat([output]),
          list: props.list.slice(1),
          render: props.render
        })
    });
  }
}

type Props1<A> = {
  list: [RenderFunction<[], A>],
  render: ([A]) => React.Node
};

type Props2<A, B> = {
  list: [RenderFunction<[], A>, RenderFunction<[A], B>],
  render: ([A, B]) => React.Node
};

type Props3<A, B, C> = {
  list: [
    RenderFunction<[], A>,
    RenderFunction<[A], B>,
    RenderFunction<[A, B], C>
  ],
  render: ([A, B, C]) => React.Node
};

type Props4<A, B, C, D> = {
  list: [
    RenderFunction<[], A>,
    RenderFunction<[A], B>,
    RenderFunction<[A, B], C>,
    RenderFunction<[A, B, C], D>
  ],
  render: ([A, B, C, D]) => React.Node
};

type Props5<A, B, C, D, E> = {
  list: [
    RenderFunction<[], A>,
    RenderFunction<[A], B>,
    RenderFunction<[A, B], C>,
    RenderFunction<[A, B, C], D>,
    RenderFunction<[A, B, C, D], E>
  ],
  render: ([A, B, C, D, E]) => React.Node
};

type Props6<A, B, C, D, E, F> = {
  list: [
    RenderFunction<[], A>,
    RenderFunction<[A], B>,
    RenderFunction<[A, B], C>,
    RenderFunction<[A, B, C], D>,
    RenderFunction<[A, B, C, D], E>,
    RenderFunction<[A, B, C, D, E], F>
  ],
  render: ([A, B, C, D, E, F]) => React.Node
};

type Props7<A, B, C, D, E, F, G> = {
  list: [
    RenderFunction<[], A>,
    RenderFunction<[A], B>,
    RenderFunction<[A, B], C>,
    RenderFunction<[A, B, C], D>,
    RenderFunction<[A, B, C, D], E>,
    RenderFunction<[A, B, C, D, E], F>,
    RenderFunction<[A, B, C, D, E, F], G>
  ],
  render: ([A, B, C, D, E, F, G]) => React.Node
};

type Props8<A, B, C, D, E, F, G, H> = {
  list: [
    RenderFunction<[], A>,
    RenderFunction<[A], B>,
    RenderFunction<[A, B], C>,
    RenderFunction<[A, B, C], D>,
    RenderFunction<[A, B, C, D], E>,
    RenderFunction<[A, B, C, D, E], F>,
    RenderFunction<[A, B, C, D, E, F], G>,
    RenderFunction<[A, B, C, D, E, F, G], H>
  ],
  render: ([A, B, C, D, E, F, G, H]) => React.Node
};
