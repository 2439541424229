/* @flow */

import * as React from "react";

import { WithComposed2 } from "./WithComposed";
import WithUserResourceStore from "./WithUserResourceStore";
import { useLocale } from "./useLocale";
import useUserUpdater from "./useUserUpdater";
import WithSearchResults from "./WithSearchResults";
import useYoutubeLanguages from "./useYoutubeLanguages";
import WithTransitions from "./WithTransitions";
import useSnackbarQueue from "./useSnackbarQueue";
import useNavigation from "./useNavigation";
import useGoogleAnaltics from "./useGoogleAnalytics";
import { pathnameToRoute } from "./router";

import useReferralSource from "./useReferralSource";
import useAuthentication from "./useAuthentication";
import useMixpanelIdentity from "./useMixpanelIdentity";
import {
  useStripeApi,
  StripeApiStatus,
  useYouTubeIframeApi,
  YouTubeIframeApiStatus
} from "./useScriptTag";

import NotFoundPage from "./NotFoundPage";
import PageComponentForRoute from "./PageComponentForRoute";
import NavigationOverlay from "./NavigationOverlay";
import ChooseLanguagesOverlay from "./ChooseLanguagesOverlay";
import LoginOverlay, { ForgotPasswordOverlay } from "./LoginOverlay";
import { OverlayShadow } from "./Overlay";
import SentryErrorPage from "./SentryErrorPage";
import WithSentryErrorBoundary from "./WithSentryErrorBoundary";
import Snackbar from "./Snackbar";

import GlobalStyles from "./GlobalStyles";

import type { ServerSideProps } from "./sendSPA";
import type { DocumentLocation, InitialLocation } from "./useNavigation";

type Props = {
  serverSideProps: ServerSideProps,
  initialLocation: InitialLocation,
  serverLang: string
};

export default function App(props: Props) {
  const navigationProps = useNavigation(props.initialLocation);

  useGoogleAnaltics(navigationProps.location);

  const [referralSource, referralSourceLoaded] = useReferralSource({
    location: navigationProps.location,
    onReplaceLocation: navigationProps.onReplaceLocation
  });

  const localeProps = useLocale({
    serverLang: props.serverLang,
    onReplaceLocation: navigationProps.onReplaceLocation,
    location: navigationProps.location
  });

  const youtubeLanguages = useYoutubeLanguages(localeProps.nativeLang);

  const snackbarProps = useSnackbarQueue();

  const authenticationProps = useAuthentication({
    ...navigationProps,
    onAddSnackbarMessage: snackbarProps.addMessage,
    serverSideProps: props.serverSideProps,
    nativeLang: localeProps.nativeLang
  });

  useUserUpdater({
    withIdToken: authenticationProps.withIdToken,
    onLogout: authenticationProps.onLogout,
    nativeLang: localeProps.nativeLang,
    targetLang: localeProps.targetLang,
    referralSource: referralSource,
    loaded: referralSourceLoaded
  });

  // Stripe recommends that we keep the stripe api loaded on every page to
  // help better predict fraudulent transactions.
  const stripeApiStatus = useStripeApi();
  const youTubeIframeApiStatus = useYouTubeIframeApi();

  return (
    <WithComposed2
      list={[
        ({ input: _, render }) => (
          <WithSearchResults
            location={navigationProps.location}
            {...localeProps}
            render={render}
          />
        ),

        ({ input: _, render }) => (
          <WithUserResourceStore
            withIdToken={authenticationProps.withIdToken}
            render={render}
          />
        )
      ]}
      render={renderProps => (
        <StripeApiStatus.Provider value={stripeApiStatus}>
          <YouTubeIframeApiStatus.Provider value={youTubeIframeApiStatus}>
            <AppInner
              authenticationProps={authenticationProps}
              navigationProps={navigationProps}
              renderProps={renderProps}
              serverSideProps={props.serverSideProps}
              snackbarProps={snackbarProps}
              localeProps={localeProps}
              referralSource={referralSource}
              youtubeLanguages={youtubeLanguages}
            />
          </YouTubeIframeApiStatus.Provider>
        </StripeApiStatus.Provider>
      )}
    />
  );
}

function AppInner(props) {
  const {
    navigationProps,
    authenticationProps,
    snackbarProps,
    youtubeLanguages,
    localeProps,
    referralSource
  } = props;

  const [
    searchProps,
    [userResourceProps, quizAttemptsProps]
  ] = props.renderProps;

  useMixpanelIdentity(
    userResourceProps == null ? null : userResourceProps.identity
  );

  const location = navigationProps.location;
  const route = pathnameToRoute(location.pathname);

  const pageProps = {
    location: location,
    referralSource: referralSource,
    onNavigate: navigationProps.onNavigate,
    onReplaceLocation: navigationProps.onReplaceLocation,
    searchProps: searchProps,
    youtubeLanguages: youtubeLanguages,
    userResources: userResourceProps,
    onAddSnackbarMessage: snackbarProps.addMessage,
    quizAttemptsProps: quizAttemptsProps,
    serverSideProps: props.serverSideProps,
    ...authenticationProps,
    ...localeProps
  };

  return (
    <div onClick={navigationProps.onClick}>
      <WithSentryErrorBoundary
        pathname={location.pathname}
        renderError={errorProps => (
          <SentryErrorPage
            nativeLang={localeProps.nativeLang}
            {...errorProps}
          />
        )}
      >
        {route == null ? (
          <NotFoundPage {...pageProps} />
        ) : (
          <PageComponentForRoute
            route={route}
            nativeLang={localeProps.nativeLang}
            pageProps={pageProps}
          />
        )}
      </WithSentryErrorBoundary>

      <GlobalStyles route={route} />

      <Snackbar message={snackbarProps.message} />

      <WithTransitions
        contentKey={overlayContentKey(location.hash)}
        render={(contentKey, transitionProps) => {
          const OverlayComponent =
            contentKey === "shadow" ? OverlayShadow : overlayMap[contentKey];

          if (route == null || OverlayComponent == null) return null;

          return (
            <OverlayComponent
              userResources={userResourceProps}
              onClose={location.pathname + location.search}
              onNavigate={navigationProps.onNavigate}
              onAddSnackbarMessage={snackbarProps.addMessage}
              quizAttemptsProps={quizAttemptsProps}
              restricted={props.serverSideProps["restricted"] == "true"}
              youtubeLanguages={youtubeLanguages}
              location={location}
              route={route}
              {...authenticationProps}
              {...transitionProps}
              {...localeProps}
            />
          );
        }}
      />
    </div>
  );
}

import type { Route } from "./router";
import type { UserResourceStore } from "./WithUserResourceStore";
import type { SnackbarMessage } from "./useSnackbarQueue";
import type { RenderProps as QuizAttemptRenderProps } from "./WithQuizAttempts";

export type OverlayProps = {
  location: DocumentLocation,
  route: Route,
  onLogin: (flashMessage?: string) => void,
  onLogout: () => void,
  withIdToken: ?() => Promise<string>,
  idToken: string | null,
  onIdToken: string => void,
  onNavigate: (href: string) => void,
  onAddSnackbarMessage: SnackbarMessage => void,
  targetLang: ?string,
  nativeLang: string,
  isLoggedIn: boolean,
  onChangeNativeLang: (lang: string) => void,
  onChangeTargetLang: (lang: string) => void,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  quizAttemptsProps: QuizAttemptRenderProps,
  userResources: ?UserResourceStore,
  onClose: string,
  enter: boolean,
  restricted: boolean
};

const overlayMap: { [string]: React.ComponentType<OverlayProps> } = {
  "#navigation": NavigationOverlay,
  "#choose-languages": ChooseLanguagesOverlay,
  "#login": LoginOverlay,
  "#forgot-password": ForgotPasswordOverlay
};

// The purpose of this function is to make sure non-overlay related strings make it into WithTransitions.
// This includes random stuff from oauth2 or youtube timestamps.
function overlayContentKey(hash: string): string | null {
  if (hash in overlayMap) {
    return hash;
  } else return null;
}
