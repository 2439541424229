/* @flow */

import * as React from "react";

import { makeCancellable } from "./Cancellable";
import type { Cancellable } from "./Cancellable";

type Props<T> = {
  promise: Promise<T> | null,
  renderPending: (lastValue: ?T) => React.Node,
  renderResolved: (value: T) => React.Node,
  renderRejected: any => React.Node
};

type State<T> = {
  promiseState: PromiseState<T>
};

type PromiseState<T> =
  | {
      type: "resolved",
      value: T
    }
  | {
      type: "pending",
      value: ?T
    }
  | {
      type: "rejected",
      value: any
    };

export default class WithPromise<T> extends React.Component<
  Props<T>,
  State<T>
> {
  constructor(props: Props<T>) {
    super(props);

    this.state = {
      promiseState: {
        type: "pending",
        value: undefined
      }
    };

    this.cancellable = null;
  }

  cancellable: Cancellable<T> | null;

  componentDidMount() {
    this.subscribeToPromise();
  }

  componentWillUnmount() {
    if (this.cancellable != null) {
      this.cancellable.cancel();
    }
  }

  componentDidUpdate(prevProps: Props<T>) {
    if (prevProps.promise !== this.props.promise) {
      if (this.cancellable != null) {
        this.cancellable.cancel();
        this.cancellable = null;
      }

      if (this.props.promise) {
        const lastValue: ?T =
          this.state.promiseState.type === "rejected"
            ? undefined
            : this.state.promiseState.value;

        this.setState({
          promiseState: {
            type: "pending",
            value: lastValue
          }
        });

        this.subscribeToPromise();
      }
    }
  }

  subscribeToPromise() {
    if (this.props.promise != null) {
      this.cancellable = makeCancellable(this.props.promise);

      this.cancellable.promise.then(
        value => {
          this.setState({
            promiseState: { type: "resolved", value: value }
          });
        },
        error => {
          if (!error.isCancelled) {
            this.setState({
              promiseState: { type: "rejected", value: error }
            });
          }
        }
      );
    }
  }

  render() {
    switch (this.state.promiseState.type) {
      case "resolved":
        return this.props.renderResolved(this.state.promiseState.value);
      case "rejected":
        return this.props.renderRejected(this.state.promiseState.value);
      case "pending":
        return this.props.renderPending(this.state.promiseState.value);
      default:
        return null;
    }
  }
}

type WithMostRecentPromiseProps<T> = {
  promise: Promise<T> | null,
  renderPending: () => React.Node,
  renderResolved: (value: T) => React.Node,
  renderRejected: any => React.Node
};

export function WithMostRecentPromise<T>(props: WithMostRecentPromiseProps<T>) {
  return (
    <WithPromise
      promise={props.promise}
      renderRejected={props.renderRejected}
      renderResolved={value => props.renderResolved(value)}
      renderPending={lastValue =>
        lastValue ? props.renderResolved(lastValue) : props.renderPending()
      }
    />
  );
}
