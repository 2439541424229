/* @flow */
import * as React from "react";

import { putUser } from "./apiClient";
import type { ReferralSource } from "./useReferralSource";

import { captureException, withScope } from "@sentry/browser";
import { APIError } from "./APIError";

type Props = {
  withIdToken: ?() => Promise<string>,
  onLogout: () => void,
  nativeLang: string,
  referralSource: ReferralSource | null,
  targetLang: ?string,
  loaded: boolean
};

export default function useUserUpdater(props: Props) {
  const { withIdToken, nativeLang, targetLang, loaded, onLogout } = props;

  const referralSourceJSON = JSON.stringify(props.referralSource);

  React.useEffect(() => {
    if (withIdToken && loaded) {
      const body = {
        nativeLang,
        targetLang,
        referralSource: JSON.parse(referralSourceJSON)
      };
      putUser(withIdToken, body).catch(error => {
        if (error instanceof APIError && error.code == 401) {
          onLogout();
        } else {
          withScope(scope => {
            scope.setExtra("nativeLang", nativeLang);
            scope.setExtra("targetLang", targetLang || "");
            captureException(error);
          });
        }
      });
    }
  }, [
    onLogout,
    withIdToken,
    nativeLang,
    targetLang,
    referralSourceJSON,
    loaded
  ]);
}
