/* @flow */

import * as React from "react";

import { getLanguages } from "./YoutubeScraperClient";

import { captureException } from "@sentry/browser";

import youtubeLanguagesEn from "./youtubeLanguagesEn";

export default function useYoutubeLanguages(
  nativeLang: string
): YouTube$i18nLanguageListResponse {
  // Ship with the english language names, otherwise the query will slow down
  // the page render

  const [
    languages,
    setLanguages
  ] = React.useState<YouTube$i18nLanguageListResponse>(youtubeLanguagesEn);

  React.useEffect(() => {
    const promise = getLanguages(nativeLang);
    let subscribed = true;

    promise.then(
      value => {
        if (subscribed) {
          setLanguages(value);
        }
      },
      error => {
        captureException(error);
        if (subscribed) {
          setLanguages(youtubeLanguagesEn);
        }
      }
    );

    return () => {
      subscribed = false;
    };
  }, [nativeLang]);

  return languages;
}
