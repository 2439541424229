/* @flow */

import * as React from "react";

import urlParser from "js-video-url-parser/lib/base";
import "js-video-url-parser/lib/provider/youtube";

import { stringify as qsStringify } from "querystring";

type Props = {
  nativeLang: string,
  targetLang: ?string,
  onNavigate: string => void,
  defaultValue?: string,
  render: (props: RenderProps) => React.Node
};

type RenderProps = {
  onSubmit: (event: Event) => void,
  values: FormValues,
  onChangeSearchString: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  onChangeCityId: (option?: { value: string }) => void,
  onToggleNative: () => void,
  onToggleTarget: () => void,
  disabled: boolean
};

type FormValues = {
  search: string,
  filterState: FilterState,
  cityId: ?string
};

export type FilterState = "both" | "disabled" | "native" | "target";

type State = FormValues;

export default class WithSearchForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      search: props.defaultValue || "",
      cityId: null,
      filterState: "both"
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeSearchString = this.onChangeSearchString.bind(this);
    this.onChangeCityId = this.onChangeCityId.bind(this);
  }

  onSubmit: Event => void;
  onChangeSearchString: (event: SyntheticInputEvent<HTMLInputElement>) => void;
  onChangeCityId: (option?: { value: string }) => void;

  componentDidUpdate(prevProps: Props) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({ search: this.props.defaultValue || "" });
    }
  }

  isDisabled(): boolean {
    return this.state.search.trim() === "";
  }

  onToggleNative() {
    this.setState(state => {
      switch (state.filterState) {
        case "both":
          return { filterState: "target" };
        case "target":
          return { filterState: "both" };
        case "disabled":
          return { filterState: "native" };
        case "native":
          return { filterState: "disabled" };
        default:
          return null;
      }
    });
  }

  onToggleTarget() {
    this.setState(state => {
      switch (state.filterState) {
        case "both":
          return { filterState: "native" };
        case "target":
          return { filterState: "disabled" };
        case "disabled":
          return { filterState: "target" };
        case "native":
          return { filterState: "both" };
        default:
          return null;
      }
    });
  }

  onSubmit(event: Event) {
    event.preventDefault();

    if (this.isDisabled()) return;

    this.props.onNavigate(
      searchUrl(this.state, this.props.nativeLang, this.props.targetLang)
    );
  }

  onChangeSearchString(event: SyntheticInputEvent<HTMLInputElement>) {
    this.setState({ search: event.target.value });
  }

  onChangeCityId(option?: { value: string }) {
    if (option) {
      this.setState({ cityId: option.value });
    } else {
      this.setState({ cityId: null });
    }
  }

  render() {
    return this.props.render({
      onSubmit: this.onSubmit,
      values: this.state,
      onChangeSearchString: this.onChangeSearchString,
      onChangeCityId: this.onChangeCityId,
      onToggleTarget: this.onToggleTarget.bind(this),
      onToggleNative: this.onToggleNative.bind(this),
      disabled: this.isDisabled()
    });
  }
}

export function searchUrl(
  values: FormValues,
  nativeLang: string,
  targetLang: ?string
): string {
  const langs: { [string]: string } = {
    nl: nativeLang
  };
  if (targetLang != null) {
    langs.tl = targetLang;
  }

  const params: { [string]: string } = {
    ...langs,
    filter: values.filterState
  };

  if (values.cityId != null) {
    params.cityId = values.cityId;
  }

  const youTubeParams = urlParser.parse(values.search);
  if (youTubeParams) {
    if (youTubeParams.mediaType === "video") {
      return "/videos/" + youTubeParams.id + "?" + qsStringify(langs);
    } else if (youTubeParams.mediaType === "channel") {
      if (youTubeParams.id) {
        params.channelId = youTubeParams.id;
      } else if (youTubeParams.name) {
        params.username = youTubeParams.name;
      }
    } else if (youTubeParams.mediaType === "playlist") {
      params.playlistId = youTubeParams.list;
    }
  } else {
    params.q = values.search;
  }

  return "/search?" + qsStringify(params);
}
