/* @flow */

import * as React from "react";

import type { DocumentLocation } from "./useNavigation";

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export default function useGoogleAnalytics(documentLocation: DocumentLocation) {
  const page_path = documentLocation.pathname;
  const page_location =
    documentLocation.pathname + documentLocation.search + documentLocation.hash;

  React.useEffect(() => {
    if (
      typeof gtag === "function" &&
      !document.location.origin.startsWith("http://localhost:")
    ) {
      const options = {
        page_path,
        page_location: document.location.origin + page_location
      };

      gtag("config", process.env["GA_TRACKING_ID"], options);
    }
  }, [page_path, page_location]);
}
