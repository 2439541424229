/* @flow */

import styled from "styled-components";
import * as React from "react";

type Props = {
  // This accepts any object, but ideally should be an Error object
  error: any
};

let ErrorDetails;

if (process.env["NODE_ENV"] === "development") {
  const ErrrorDetailStyles = styled.div`
    text-align: left;
    pre {
      border: 1px solid #eee;
      padding: 1em;
      overflow-x: scroll;
    }
    b {
      font-weight: bold;
    }
  `;

  function makeString(input: any) {
    try {
      const string = JSON.stringify(input);
      if (string == null) return String(input);
      else return string;
    } catch (e) {
      return String(input);
    }
  }

  ErrorDetails = (props: Props) => {
    if (props.error == null) {
      return null;
    } else if (props.error instanceof Error) {
      return (
        <ErrrorDetailStyles>
          <b>{props.error.message}</b>
          <pre>{props.error.stack}</pre>
        </ErrrorDetailStyles>
      );
    } else {
      return (
        <ErrrorDetailStyles>
          <b>non-Error object</b>
          <pre>{makeString(props.error)}</pre>
        </ErrrorDetailStyles>
      );
    }
  };
} else {
  ErrorDetails = (props: Props) => {
    return null;
  };
}

export default ErrorDetails;
