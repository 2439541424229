/* @flow */

import * as React from "react";

import WithFavorites from "./WithFavorites";
import WithVideoLibrary from "./WithVideoLibrary";
import WithPremiumSubscription from "./WithPremiumSubscription";
import WithQuizAttempts from "./WithQuizAttempts";

import { getUserResources } from "./apiClient";

import type { RenderProps as FavoritesRenderProps } from "./WithFavorites";
import type { RenderProps as VideoLibraryRenderProps } from "./WithVideoLibrary";
import type { RenderProps as PremiumSubscriptionRenderProps } from "./WithPremiumSubscription";
import type { RenderProps as QuizAttemptsRenderProps } from "./WithQuizAttempts";

import { captureException } from "@sentry/browser";

import { WithComposed4 } from "./WithComposed";

type Props = {
  withIdToken: ?() => Promise<string>,
  render: RenderProps => React.Node
};

type RenderProps = [?UserResourceStore, QuizAttemptsRenderProps];

export type UserResourceStore = {
  identity: Promise<UserIdentity>,
  favorites: FavoritesRenderProps,
  videoLibrary: VideoLibraryRenderProps,
  premiumSubscription: PremiumSubscriptionRenderProps,
  withIdToken: () => Promise<string>
};

import type { UserResources, UserIdentity } from "./api";

export type LoginResources = {
  withIdToken: () => Promise<string>,
  userResources: Promise<UserResources>
};

export default function WithUserResourceStore(props: Props) {
  const [
    loginResources,
    setLoginResources
  ] = React.useState<LoginResources | null>(null);

  React.useEffect(() => {
    if (props.withIdToken) {
      const withIdToken = props.withIdToken;
      const userResources = getUserResources(withIdToken);

      userResources.catch(error => captureException(error));

      setLoginResources({ withIdToken, userResources });
    } else {
      setLoginResources(null);
    }
  }, [props.withIdToken]);

  const identity = React.useMemo(() => {
    if (loginResources) {
      return loginResources.userResources.then(r => r.identity);
    } else {
      return null;
    }
  }, [loginResources]);

  return (
    <WithComposed4
      list={[
        ({ render }) => (
          <WithFavorites loginResources={loginResources} render={render} />
        ),
        ({ render }) => (
          <WithVideoLibrary loginResources={loginResources} render={render} />
        ),
        ({ render }) => (
          <WithPremiumSubscription
            loginResources={loginResources}
            render={render}
          />
        ),
        ({ render }) => (
          <WithQuizAttempts loginResources={loginResources} render={render} />
        )
      ]}
      render={([
        favorites,
        videoLibrary,
        premiumSubscription,
        quizAttempts
      ]) => {
        // In practice, these should all be null or non-null together.
        if (
          identity &&
          props.withIdToken &&
          favorites &&
          videoLibrary &&
          premiumSubscription
        ) {
          return props.render([
            {
              identity: identity,
              withIdToken: props.withIdToken,
              favorites,
              videoLibrary,
              premiumSubscription
            },
            quizAttempts
          ]);
        } else {
          return props.render([null, quizAttempts]);
        }
      }}
    />
  );
}

// This is a convenience hook to determine if the current user has a premium subscription
export function useIsPremiumPromise(
  userResources: ?UserResourceStore
): Promise<boolean> {
  const promise = React.useMemo<Promise<boolean>>(() => {
    if (userResources == null) {
      return Promise.resolve(false);
    } else {
      return userResources.premiumSubscription.subscription.then(
        subscription => subscription.active
      );
    }
  }, [userResources]);

  return promise;
}
