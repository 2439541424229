/* @flow */

import * as React from "react";

type Props = {
  href: string,
  state: Object,
  children: React.Node
};

export function encodeStateForAnchor(state: Object) {
  return { "data-state": JSON.stringify(state) };
}

export function decodeStateFromAnchor(element: HTMLElement): ?Object {
  const state = element.getAttribute("data-state");
  if (state) {
    return JSON.parse(state);
  } else {
    return null;
  }
}

export default function LinkWithState(props: Props) {
  return (
    <a href={props.href} {...encodeStateForAnchor(props.state)}>
      {props.children}
    </a>
  );
}
