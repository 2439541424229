/* @flow */

const get = require("lodash/get");

function handleAPIResponse(
  method /*:string*/,
  response /*: Response */,
  errorMessageField /*: string*/
) /*: Promise<any> */ {
  const contentType = response.headers.get("content-type");

  // Attempts to parse error codes out of HTML cloudflare pages.
  // https://support.cloudflare.com/hc/en-us/articles/360029779472-Troubleshooting-Cloudflare-1XXX-errors
  const cloudflareProxyErrors = [409, 520, 530, 403, 429];
  if (
    contentType &&
    contentType.split(";")[0] !== "text/html" &&
    cloudflareProxyErrors.indexOf(response.status) !== -1 &&
    response.headers.get("server") == "cloudflare"
  ) {
    return response.text().then(text => {
      const match = text.match(/Error 1\d\d\d/);
      let message;
      if (match) {
        message = "Cloudflare error: " + match[0];
      } else {
        message = "Unexpected html document from cloudflare server";
      }
      return Promise.reject(
        new APIError(
          message,
          method,
          response.url,
          response.status,
          response.headers,
          null
        )
      );
    });
  }

  if (contentType && contentType.split(";")[0] !== "application/json") {
    return Promise.reject(
      new APIError(
        `Unexpected content type: ${contentType}`,
        method,
        response.url,
        response.status,
        response.headers,
        null
      )
    );
  }

  if (response.ok) {
    return response.json();
  } else {
    return response
      .json()
      .then(json =>
        Promise.reject(
          new APIError(
            get(json, errorMessageField),
            method,
            response.url,
            response.status,
            response.headers,
            json
          )
        )
      );
  }
}

class APIError extends Error {
  constructor(
    message /*: string*/,
    method /*:string*/,
    url /*: string*/,
    code /*: number*/,
    responseHeaders /*: Headers */,
    jsonBody /*: Object | null */
  ) {
    // The only case where this message is shown to the user is when there
    // is a Stripe card error.
    // TODO: Consider adding a userDescription field to this.
    super(message);
    //super(`(HTTP ${code}): ${message}`);

    this.code = code;
    this.url = url;
    this.name = "APIError";
    this.method = method;
    this.jsonBody = jsonBody;
    this.responseHeaders = responseHeaders;

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }
  }

  /*::
  method: string;
  code: number;
  url: string;
  responseHeaders: Headers;
  jsonBody: Object | null;
  */
}

module.exports = {
  APIError,
  handleAPIResponse
};
