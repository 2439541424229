/* @flow */

import * as React from "react";

type TransitionProps = {|
  enter: boolean,
  key: string
|};

type Props = {
  contentKey: ?string,
  render: (contentKey: string, transitionProps: TransitionProps) => React.Node
};

const TRANSITION_MS = 200;

/*
Possible states of a component:

  State             Props           Description
  =====             =====           ===========
- TRANSITION_IN     enter: false    First state after mounting
- IN                enter: true     0ms after TRANSITION_IN
- TRANSITION_OUT    enter: false    Stays here for TRANSITION_MS ms, then removed from DOM
*/
type TransitionState = "TRANSITION_IN" | "IN" | "TRANSITION_OUT";

type ContentKey = string;

type TransitionItem = {
  contentKey: string,
  transitionState: TransitionState
};

type State = {
  contentKey: ContentKey | null,
  transitionItems: Array<TransitionItem>
};

function propsForComponent(item: TransitionItem): TransitionProps {
  return { key: item.contentKey, enter: item.transitionState === "IN" };
}

function calculateTransitionItems(
  contentKey: ContentKey | null,
  lastState: State
): Array<TransitionItem> {
  const lastContentKey = lastState.contentKey;

  if (contentKey == null) {
    if (lastContentKey == null) {
      return [];
    } else {
      return [
        {
          contentKey: lastContentKey,
          transitionState: "TRANSITION_OUT"
        },
        {
          contentKey: "shadow",
          transitionState: "TRANSITION_OUT"
        }
      ];
    }
  } else {
    if (lastContentKey == null) {
      return [
        {
          contentKey: contentKey,
          transitionState: "TRANSITION_IN"
        },
        {
          contentKey: "shadow",
          transitionState: "TRANSITION_IN"
        }
      ];
    } else {
      if (lastContentKey == contentKey) {
        return lastState.transitionItems;
      } else {
        return [
          {
            contentKey: contentKey,
            transitionState: "TRANSITION_IN"
          },
          {
            contentKey: lastContentKey,
            transitionState: "TRANSITION_OUT"
          },
          {
            contentKey: "shadow",
            transitionState: "IN"
          }
        ];
      }
    }
  }
}

function calculateState(
  contentKey: ContentKey | null,
  lastState: State
): State {
  return {
    contentKey: contentKey,
    transitionItems: calculateTransitionItems(contentKey, lastState)
  };
}

function transitionIn(transitionState: TransitionState) {
  return transitionState === "TRANSITION_IN" ? "IN" : transitionState;
}

function onTransitionIn(
  transitionItems: Array<TransitionItem>
): Array<TransitionItem> {
  return transitionItems.map(item => ({
    contentKey: item.contentKey,
    transitionState: transitionIn(item.transitionState)
  }));
}

function onTransitionOut(
  transitionItems: Array<TransitionItem>
): Array<TransitionItem> {
  return transitionItems.filter(
    item => item.transitionState !== "TRANSITION_OUT"
  );
}

export default class WithTransitions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.transitionInTimeoutId = null;
    this.transitionOutTimeoutId = null;

    this.state = {
      contentKey: null,
      transitionItems: []
    };
  }

  transitionInTimeoutId: ?TimeoutID;
  transitionOutTimeoutId: ?TimeoutID;

  componentWillUnmount() {
    this.clearTimers();
  }

  clearTimers() {
    if (this.transitionInTimeoutId) {
      clearTimeout(this.transitionInTimeoutId);
      this.transitionInTimeoutId = null;
    }

    if (this.transitionOutTimeoutId) {
      clearTimeout(this.transitionOutTimeoutId);
      this.transitionOutTimeoutId = null;
    }
  }

  static getDerivedStateFromProps(
    nextProps: Props,
    prevState: State
  ): State | null {
    const contentKey =
      nextProps.contentKey == null || nextProps.contentKey == ""
        ? null
        : nextProps.contentKey;
    const lastContentKey = prevState.contentKey;

    if (contentKey === lastContentKey) {
      return null;
    } else {
      return calculateState(contentKey, prevState);
    }
  }

  // This sets timers in response to what happened in getDerivedStateFromProps
  componentDidUpdate() {
    const anyItems = (transitionState: TransitionState) => {
      return (
        this.state.transitionItems.findIndex(
          item => item.transitionState === transitionState
        ) !== -1
      );
    };

    // Transition in
    if (anyItems("TRANSITION_IN")) {
      this.transitionInTimeoutId = setTimeout(() => {
        this.transitionInTimeoutId = null;
        this.setState(state => ({
          transitionItems: onTransitionIn(state.transitionItems)
        }));
      }, 0);
    }

    // Transition in
    if (anyItems("TRANSITION_OUT")) {
      this.transitionOutTimeoutId = setTimeout(() => {
        this.transitionOutTimeoutId = null;
        this.setState(state => ({
          transitionItems: onTransitionOut(state.transitionItems)
        }));
      }, TRANSITION_MS);
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.transitionItems.map(item =>
          this.props.render(item.contentKey, propsForComponent(item))
        )}
      </React.Fragment>
    );
  }
}
