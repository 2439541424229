/* @flow */

import * as React from "react";
import styled from "styled-components";

import { darken } from "polished";

type Props = {
  childAspectRatio: number,
  children: React.Node,
  maxWidth: number,
  className?: string,
  id?: string
};

const circleRadius = 3;
const headerHeight = 20;
const circleColors = ["#EE2B2B", "#FFD145", "#6DD344"];
const borderRadius = 5;

import { loadingPlaceholderColor } from "./LoadingTextPlaceholder";

export default function BrowserChrome(props: Props) {
  const width = 405 + 2;
  const height = width * props.childAspectRatio + headerHeight;

  const headerHeightInPercentage = (headerHeight / height) * 100 + "%";

  return (
    <Wrapper
      id={props.id}
      className={props.className}
      maxWidth={props.maxWidth}
    >
      <svg width="100%" viewBox={`0 0 ${width} ${height}`}>
        <rect x="0" y="0" width="100%" height="100%" fill="#DEDEDE" />
        {circleColors.map((color, i) => (
          <circle
            key={i}
            cx={13 + i * (circleRadius * 2 + 3)}
            cy={headerHeight / 2}
            r={circleRadius}
            strokeWidth="1"
            stroke={darken(0.1, color)}
            fill={color}
          />
        ))}
      </svg>
      <ChildrenWrapper top={headerHeightInPercentage}>
        {props.children}
      </ChildrenWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: ${props => props.maxWidth}px;
  border-radius: ${borderRadius}px;
  overflow: hidden;
  box-shadow: 5px 5px 5px rgb(204, 204, 204);

  > svg {
    display: block;
  }
`;

const ChildrenWrapper = styled.div`
  position: absolute;
  // TODO: I'm guessing that 8% feels right. Really, this should be derived from
  // the headerHeight constant and the aspect ratio.
  top: ${props => props.top};
  left: 1px;
  right: 1px;
  bottom: 0px;
  overflow: hidden;
  border-bottom-right-radius: ${borderRadius}px;
  border-bottom-left-radius: ${borderRadius}px;
  background-color: ${loadingPlaceholderColor};
`;
