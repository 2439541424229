/* @flow */

import { groupBy, sortBy, last, sum } from "lodash";

import type { Favorite, QuizAttempt, UnsavedQuizAttempt } from "./api";

import type { SuperMemoState } from "./supermemo";
import { superMemoDefault, iterateSuperMemo } from "./supermemo";

const ONE_DAY = 60 * 60 * 24 * 1000;

export function findDueCards(
  now: number,
  favorites: Array<Favorite>,
  history: Array<QuizAttempt>
): Array<Favorite> {
  const groups = groupBy(history, "favoriteId");

  const withDueDates = favorites.map(favorite => [
    favorite,
    dueDateForCard(Date.parse(favorite.createdAt), groups[favorite.id] || [])
  ]);

  const filtered = withDueDates.filter(([_favorite, dueDate]) => now > dueDate);

  const sorted = sortBy(filtered, ([_favorite, dueDate]) => dueDate);

  return sorted.map(([favorite, _dueDate]) => favorite);
}

export function stateForCard(attempts: Array<QuizAttempt>): SuperMemoState {
  return attempts.reduce(
    (acc, attempt) => iterateSuperMemo(acc, qualityOfAttempt(attempt)),
    superMemoDefault
  );
}

export function dueDateForCard(
  createdAt: number,
  attempts: Array<QuizAttempt>
): number {
  const sorted = sortBy(attempts, "createdAt");

  // If the card has never been attempted, then it is due right away. Otherwise, use supermemo
  // to determine the next due date.
  if (sorted.length === 0) {
    return createdAt;
  } else {
    const state = stateForCard(attempts);

    const lastReviewed = Date.parse(last(sorted).createdAt);
    return lastReviewed + state.interval * ONE_DAY;
  }
}

export function nextCardDueAt(
  favorites: Array<Favorite>,
  history: Array<QuizAttempt>
): number {
  const groups = groupBy(history, "favoriteId");

  const cards = sortBy(favorites, favorite =>
    dueDateForCard(Date.parse(favorite.createdAt), groups[favorite.id] || [])
  );

  if (cards.length > 0) {
    return dueDateForCard(
      Date.parse(cards[0].createdAt),
      groups[cards[0].id] || []
    );
  } else return 0;
}

// Returns a number between 0-5, inclusive
export function qualityOfAttempt(attempt: UnsavedQuizAttempt): number {
  /*
  From the SM-2 docs:
    5 - perfect response
    4 - correct response after a hesitation
    3 - correct response recalled with serious difficulty
    2 - incorrect response; where the correct one seemed easy to recall
    1 - incorrect response; the correct one remembered
    0 - complete blackout.
  */

  const maxDuration = 120 * 1000;
  const maxHints = 5;
  const best = 5.0;

  if (attempt.result === "pass") {
    return 0;
  } else {
    const scores = [
      ((maxHints - Math.min(maxHints, attempt.hints.length)) / maxHints) * best,
      ((maxDuration - Math.min(maxDuration, attempt.duration)) / maxDuration) *
        best
    ];
    return sum(scores) / scores.length;
  }
}
