/* @flow */

import * as React from "react";

type Props = {
  children: React.Node
};

type State = {
  timeoutReached: boolean
};

export default class DelayedRender extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = { timeoutReached: false };
  }
  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.timeoutId = null;
      this.setState({ timeoutReached: true });
    }, 75);
  }

  timeoutId: TimeoutID | null;

  componentWillUnmount() {
    if (this.timeoutId != null) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    if (this.state.timeoutReached) {
      return this.props.children;
    } else {
      return null;
    }
  }
}
