/* @flow */

import * as React from "react";
import { stringsForLocale } from "../lang/web";
import countriesForLanguage from "./countriesForLanguage";

import cities from "./cities";

import Select from "react-select";

import { sortBy, isEmpty, pickBy, map } from "lodash";

type Props = {
  nativeLang: string,
  targetLang: string,
  onChange: ({ value: string }) => void
};

export default function FilterByCitySelect(props: Props) {
  const cityOptions = optionsForCountrySelect(
    props.nativeLang,
    props.targetLang
  );

  return (
    <Select
      //value={formProps.values.cityId}
      options={cityOptions}
      instanceId="city-select"
      onChange={props.onChange}
    />
  );
}

function joinRegions(regions: Array<string>, locale: string): string {
  const lang = locale.split("-")[0];
  switch (lang) {
    case "ko":
    case "ja":
    case "zh":
      return regions.reverse().join(" ");
    case "en":
    case "fr":
    case "es":
    default:
      return regions.join(", ");
  }
}

type City = [string, string, string, string];
type SelectOption = {
  value: string,
  label: string
};
type OptionGroup = {
  label: string,
  options: Array<SelectOption>
};

function mapCitiesToOptions(cities: { [string]: City }): Array<SelectOption> {
  const options = map(cities, (record, key) => ({
    value: key,
    label: joinRegions([record[0], record[3]], "en")
  }));

  return sortBy(options, option => option.label);
}

function optionsForCountrySelect(
  nativeLang: string,
  targetLang: string
): Array<OptionGroup> | Array<SelectOption> {
  const targetCountries: Array<string> = countriesForLanguage[targetLang];
  const strings = stringsForLocale(nativeLang).search_form;

  function isNotSuggested(city: City) {
    return (
      targetCountries.findIndex(
        i => i.toUpperCase() === city[3].toUpperCase()
      ) === -1
    );
  }

  function isSuggested(city: City) {
    return !isNotSuggested(city);
  }

  if (targetCountries) {
    const suggestedCities: { [string]: City } = pickBy(cities, isSuggested);
    const otherCities: { [string]: City } = pickBy(cities, isNotSuggested);

    if (isEmpty(suggestedCities)) {
      return mapCitiesToOptions(otherCities);
    } else {
      const groups: Array<OptionGroup> = [
        {
          label: strings.suggested_cities(),
          options: mapCitiesToOptions(suggestedCities)
        },
        {
          label: strings.all_cities(),
          options: mapCitiesToOptions(otherCities)
        }
      ];
      return groups;
    }
  } else {
    return mapCitiesToOptions(cities);
  }
}
