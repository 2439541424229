/* @flow */

import * as React from "react";
import styled from "styled-components";

import { colors } from "./theme";

import type { MergedText } from "./mergeSubtitles";

import { secondsToString } from "./mediaTimestampFormat";

import * as geometry from "./geometry";
import ActionLink from "./ActionLink";
import SubtitleText from "./SubtitleText";
import { GreyText } from "./LoadingTextPlaceholder";
import { urlForVideo } from "./urlForRoute";

import { widthCss, maxHeightPercentage } from "./playerGeometry";

const SubtitleListWrapper = styled.div`
  ${widthCss} margin: 0 auto;
`;

type Props = {
  children: React.Node
};

const ListItemAction = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
`;

export default function SubtitleScroller(props: Props) {
  return (
    <SubtitleListWrapper>
      <SubtitleList>{props.children}</SubtitleList>
    </SubtitleListWrapper>
  );
}

const SubtitleHeaderStyles = styled.li`
  position: relative;
  padding: 0 ${geometry.padding}px;
  margin-top: 5em;
  &:first-child {
    margin-top: 0;
  }
  img {
    opacity: 0.5;
  }
  img:hover {
    opacity: 1;
  }

  margin-bottom: 1em;
  p {
    font-weight: bold;
  }
`;

export function SubtitleGroupHeader(props: { videoId: string, title: string }) {
  return (
    <SubtitleHeaderStyles>
      <div>
        <a href={urlForVideo(props.videoId)}>
          <img
            height="90"
            src={`https://img.youtube.com/vi/${props.videoId}/mqdefault.jpg`}
          />
        </a>
        <p>{props.title}</p>
      </div>
    </SubtitleHeaderStyles>
  );
}

const SubtitleList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: left;
`;

type SubtileListItemProps = {
  active: boolean,
  onSeek: () => void,
  subtitle: MergedText,
  showTranscription: boolean,
  showTranslation: boolean,
  innerRef: ?Function,
  actionElement: React.Node
};

export function SubtitleListItem(props: SubtileListItemProps) {
  return (
    <SubtitleListItemStyles
      ref={props.innerRef}
      className={props.active ? "active" : null}
    >
      <Timestamp>
        <ActionLink onActivated={props.onSeek}>
          {secondsToString(Math.floor(props.subtitle.start))}
        </ActionLink>
      </Timestamp>
      <Highlighter active={props.active} />
      <SubtitleText
        subtitle={props.subtitle}
        showTranslation={props.showTranslation}
        showTranscription={props.showTranscription}
      />
      <ListItemAction>{props.actionElement}</ListItemAction>
    </SubtitleListItemStyles>
  );
}

const SubtitlePlaceholder = styled(GreyText)`
  margin-bottom: 1.5em;
`;

export function PendingSubtitleListItem(props: {}) {
  return (
    <SubtitleListItemStyles>
      <Timestamp>
        <GreyText width={25} />
      </Timestamp>

      <div className="subtitle-text">
        <SubtitlePlaceholder width={450} />
        <SubtitlePlaceholder width={255} />
      </div>
    </SubtitleListItemStyles>
  );
}

const SubtitleListItemStyles = styled.li`
  font-size: 125%;

  // To position the "favorites" button
  position: relative;

  .subtitle-text {
    opacity: 0.5;
    line-height: 1.5;
  }

  &.active .subtitle-text {
    opacity: 1;
  }

  a {
    color: #0366d6;
  }

  padding: 0 ${geometry.padding}px;

  p {
    margin: 0;
    margin-bottom: 1em;
  }
`;

// The screen width where we should trigger outside timestamps
const triggerWidth = geometry.videoWidth + geometry.timestampWidth * 2;

// The aspect ratio that will trigger outside timestamp
const triggerAspectRatio = `${(triggerWidth * maxHeightPercentage) / 100}/${
  geometry.videoHeight
}`;

const mediaQueryForOutsideTimestamps = `min-width: ${triggerWidth}px), (min-aspect-ratio: ${triggerAspectRatio}`;

const Timestamp = styled.p`
  @media (${mediaQueryForOutsideTimestamps}) {
    position: absolute;
    top: 0;
    left: ${-geometry.timestampWidth}px;
    width: ${geometry.timestampWidth}px;
    text-align: center;
    margin: 0;
  }
`;

const Highlighter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  ${props =>
    props.active
      ? `
    border-left: ${geometry.accentBorderWidth}px solid ${colors.highlight};
  `
      : null} @media (${mediaQueryForOutsideTimestamps}) {
    left: ${-geometry.timestampWidth}px;
  }
`;
