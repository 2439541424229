/* @flow */

import * as React from "react";
import styled from "styled-components";
import { darken } from "polished";

import { BehaviorSubject, fromEvent, merge, combineLatest, NEVER } from "rxjs";
import type { Observable, Subscription } from "rxjs";
import {
  filter,
  mapTo,
  startWith,
  map,
  distinctUntilChanged
} from "rxjs/operators";

import WithObservable from "./WithObservable";

import { stringsForLocale } from "../lang/web";

function fromDocument(event) {
  if (typeof document == "object") return fromEvent(document, event);
  else return NEVER;
}

const documentKeyUp$ = fromDocument("keyup");
const documentKeyDown$ = fromDocument("keydown");

const keyboardColor = "#f5f5f5";
const activeKeyboardColor = darken(0.15, keyboardColor);

const keyboardShortcutListWidth = 200;

const KeyboardButton = styled.a`
  flex-shrink: 0;
  display: block;

  height: 25px;
  line-height: 25px;
  text-align: center;
  width: ${props => (props.wide ? "50px" : "25px")};
  text-decoration: none;

  border: 1px solid #dbdbdb;
  border-radius: 3px;
  box-shadow: 0 1px 0 hsla(0, 0%, 4%, 0.2), inset 0 0 0 1px #fff;

  font-family: monospace;
  font-weight: 400;
  padding: 0 0.25em;

  kbd {
    font-family: monospace;
    font-weight: 400;
  }

  color: #333;
  background: ${props => (props.active ? activeKeyboardColor : keyboardColor)};
  &:active {
    background: ${activeKeyboardColor};
  }
`;

const KeyboardKeyStyles = styled.div`
  display: flex;

  .tooltip {
    font-family: monospace;
    width: 0px;
    overflow: hidden;
    transition: width 100ms;
    white-space: nowrap;
    margin-left: 5px;
  }

  &:hover .tooltip {
    width: ${keyboardShortcutListWidth - 60}px;
  }
`;

type KeyboardKeyProps = {
  label: string,
  wide: boolean,
  active: boolean,
  tooltip: string,
  onClick?: Function,
  onActivated?: Function,
  onReleased?: Function
};

class KeyboardKey extends React.Component<KeyboardKeyProps> {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
  }

  onClick: (event: MouseEvent) => void;
  onMouseDown: (event: MouseEvent) => void;
  mouseUpHandler: ?MouseEventHandler;

  onClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  onMouseDown(event) {
    if (this.props.onActivated) {
      this.props.onActivated();
      event.preventDefault();

      this.removeMouseUpHandler();
      this.mouseUpHandler = this.onMouseUp.bind(this);
      document.addEventListener("mouseup", this.mouseUpHandler);
    }
  }

  onMouseUp(event) {
    this.removeMouseUpHandler();
    if (this.props.onReleased) {
      this.props.onReleased();
      event.preventDefault();
    }
  }

  removeMouseUpHandler() {
    if (this.mouseUpHandler) {
      document.removeEventListener("mouseup", this.mouseUpHandler);
      delete this.mouseUpHandler;
    }
  }

  componentWillUnmount() {
    this.removeMouseUpHandler();
  }

  render() {
    return (
      <KeyboardKeyStyles>
        <KeyboardButton
          wide={this.props.wide}
          active={this.props.active}
          href="#"
          onClick={this.onClick}
          onMouseDown={this.onMouseDown}
        >
          {this.props.label}
        </KeyboardButton>
        <div className="tooltip">{this.props.tooltip}</div>
      </KeyboardKeyStyles>
    );
  }
}

const StyledKeyboardShortcuts = styled.ul`
  position: absolute;
  top: 0;
  right: -${keyboardShortcutListWidth + 10}px;
  width: ${keyboardShortcutListWidth}px;

  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: left;

  li {
    line-height: 25px;
    margin-bottom: 5px;
  }
`;

function isKeyDown(code, keyCode): Observable<boolean> {
  function filterFn(e) {
    return (
      (e.code === code || e.keyCode === keyCode) &&
      !e.metaKey &&
      !e.ctrlKey &&
      !e.altKey &&
      !e.repeat
    );
  }
  const filterOp = filter(filterFn);

  return merge(
    documentKeyDown$.pipe(
      filterOp,
      mapTo(true)
    ),
    documentKeyUp$.pipe(
      filterOp,
      mapTo(false)
    )
  ).pipe(startWith(false));
}

const isSpacebarDown$ = isKeyDown("Space", 32);
const isKeyRDown$ = isKeyDown("KeyR", 82);
const isKeyTDown$ = isKeyDown("KeyT", 84);
const isKeyUpDown$ = isKeyDown("ArrowUp", 38);
const isKeyDownDown$ = isKeyDown("ArrowDown", 40);

type Props = {
  onTogglePlayback: () => void,
  onToggleTranslation: boolean => void,
  nativeLang: string,
  onClickUp: Function,
  onClickDown: Function,
  onClickRepeat: Function
};

export default class KeyboardShortcuts extends React.Component<Props> {
  constructor() {
    super();
  }

  subscription: Subscription;
  isTButtonPressed: BehaviorSubject<boolean>;

  componentDidMount() {
    this.isTButtonPressed = new BehaviorSubject(false);

    // Combine the keyboard and mouse events for the translation shortcut
    const translationActivated$ = combineLatest(
      this.isTButtonPressed.pipe(map(value => (value ? 1 : 0))),
      isKeyTDown$.pipe(map(value => (value ? 1 : 0))),
      (mouse, key) => mouse + key
    ).pipe(
      map(value => value > 0),
      distinctUntilChanged()
    );

    this.subscription = translationActivated$.subscribe(
      this.props.onToggleTranslation
    );
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  render() {
    const props = this.props;
    const strings = stringsForLocale(props.nativeLang);

    return (
      <StyledKeyboardShortcuts>
        <li>
          <WithObservable
            observable={isSpacebarDown$}
            renderValue={active => (
              <KeyboardKey
                label="Space"
                active={active}
                wide
                tooltip={strings.keyboard_shortcuts.toggle_playback_tooltip()}
                onClick={props.onTogglePlayback}
              />
            )}
          />
        </li>

        <li>
          <WithObservable
            observable={isKeyTDown$}
            renderValue={active => (
              <KeyboardKey
                label="T"
                wide={false}
                active={active}
                tooltip={strings.keyboard_shortcuts.show_translation_tooltip()}
                onActivated={() => this.isTButtonPressed.next(true)}
                onReleased={() => this.isTButtonPressed.next(false)}
              />
            )}
          />
        </li>

        <li>
          <WithObservable
            observable={isKeyRDown$}
            renderValue={active => (
              <KeyboardKey
                label="R"
                wide={false}
                active={active}
                tooltip={strings.keyboard_shortcuts.repeat_tooltip()}
                onClick={props.onClickRepeat}
              />
            )}
          />
        </li>

        <li>
          <WithObservable
            observable={isKeyUpDown$}
            renderValue={active => (
              <KeyboardKey
                label={String.fromCharCode(0x2191)}
                wide={false}
                active={active}
                tooltip={strings.keyboard_shortcuts.previous_line_tooltip()}
                onClick={props.onClickUp}
              />
            )}
          />
        </li>

        <li>
          <WithObservable
            observable={isKeyDownDown$}
            renderValue={active => (
              <KeyboardKey
                label={String.fromCharCode(0x2193)}
                wide={false}
                active={active}
                tooltip={strings.keyboard_shortcuts.next_line_tooltip()}
                onClick={props.onClickDown}
              />
            )}
          />
        </li>
      </StyledKeyboardShortcuts>
    );
  }
}
