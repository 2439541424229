/* @flow */

const fetch = require("node-fetch");
const querystring = require("querystring");

const API_BASE_URL = "https://www.googleapis.com/youtube/v3/";

const { handleAPIResponse } = require("./APIError");
const chunk = require("lodash/chunk");
const fromPairs = require("lodash/fromPairs");
const assign = require("lodash/assign");

function fetchApi(
  endpoint /*: string */,
  params /*: Object*/,
  options /*: Object */ = {}
) /*: Promise<Object>*/ {
  const API_KEY = process.env.YOUTUBE_DATA_API_KEY;

  const url =
    API_BASE_URL +
    endpoint +
    "?" +
    querystring.stringify({
      ...params,
      key: API_KEY
    });

  // Right now we only use the GET method.
  const method = options.method || "GET";

  console.log(`[Youtube API] ${endpoint} ${querystring.stringify(params)}`);

  return fetch(url, options).then(r =>
    handleAPIResponse(method, r, "error.message")
  );
}

function getCaptions(
  params /*: {
    videoId: string,
    part: string
  }*/,
  options /*: Object = {}*/
) /*: Promise<YouTube$CaptionListResponse> */ {
  return fetchApi("captions", params, options);
}

function languagesForVideo(videoId /*: string*/) /*: Promise<Array<Object>>*/ {
  return fetchApi("captions", { videoId, part: "snippet" }).then(r =>
    r.items
      .filter(
        o => o.kind === "youtube#caption" && o.snippet.trackKind === "standard"
      )
      .map(o => o.snippet.language)
  );
}

function getVideos(
  params /*: Object*/
) /*: Promise<YouTube$VideoListResponse> */ {
  return fetchApi("videos", params);
}

function getChannels(
  params /*: Object*/
) /*: Promise<YouTube$ChannelListResponse> */ {
  return fetchApi("channels", params);
}

function getMostPopular() /*: Promise<Array<YouTube$Video>>*/ {
  return getVideos({
    part: "snippet,contentDetails",
    chart: "mostPopular",
    regionCode: "kr",
    videoCategoryId: 10,
    maxResults: 50
  }).then(response =>
    response.items.filter(item => item.contentDetails.caption)
  );
}

function getVideoCategories(
  params /*: Object */
) /*: Promise<YouTube$VideoCategoryListResponse> */ {
  return fetchApi("videoCategories", params);
}

function downloadCaptions(id /*: string*/) /*: Object */ {
  return fetchApi("captions/" + id, { tfmt: "vtt", id: id });
}

function getVideosInChannel(
  channelId /*: string */
) /*: Promise<Array<YouTube$SearchResult>> */ {
  const params = {
    part: "snippet",
    type: "video",
    videoCaption: "closedCaption",
    channelId: channelId,
    maxResults: 50
  };

  return fetchApi("search", params).then(
    (r /*: YouTube$SearchListResponse*/) => r.items
  );
}

function search(
  params /*: Object*/
) /*: Promise<YouTube$SearchListResponse> */ {
  return fetchApi("search", params);
}

function getLanguages(params /*: Object */) /*: Promise<Object> */ {
  return fetchApi("i18nLanguages", params);
}

function getPlaylists(
  params /*: Object*/
) /*: Promise<YouTube$PlaylistListResponse> */ {
  return fetchApi("playlists", params);
}

function getPlaylistItems(
  params /*: YouTube$PlaylistItemListRequest*/
) /*: Promise<YouTube$PlaylistItemListResponse> */ {
  return fetchApi("playlistItems", params);
}

function stripLocale(locale /*: string*/) /*: string */ {
  return locale.split("-")[0];
}

function getVideo(params /*: Object*/) /*: Promise<YouTube$Video> */ {
  return getVideos(params).then(r => {
    if (r.items != null && r.items.length > 0) {
      return r.items[0];
    } else {
      return Promise.reject(
        new YouTubeItemNotFound("Video not found: " + params.id)
      );
    }
  });
}

function getChannel(
  params /*: Object*/
) /*: Promise<YouTube$ChannelResource> */ {
  return getChannels(params).then(r => {
    if (r.items != null && r.items.length > 0) {
      return r.items[0];
    } else {
      return Promise.reject(
        new YouTubeItemNotFound(
          "Channel not found: " + (params.id || params.forUsername)
        )
      );
    }
  });
}

function getPlaylist(
  params /*: Object*/
) /*: Promise<YouTube$PlaylistResponse> */ {
  return getPlaylists(params).then(response => {
    if (response.items.length === 0)
      return Promise.reject(
        new YouTubeItemNotFound("Playlist not found: " + params.id)
      );
    else return response.items[0];
  });
}

function fetchVideoTitles(
  videoIds /*: Array<string>*/
) /*: Promise<{ [string]: string }> */ {
  if (videoIds.length == 0) {
    return Promise.resolve({});
  } else {
    return getVideos({
      part: "snippet",
      fields: "items/id,items/snippet/title",
      id: videoIds.join(",")
    }).then(r => fromPairs(r.items.map(item => [item.id, item.snippet.title])));
  }
}

function fetchChunkedVideoTitles(
  videoIds /*: Array<string> */
) /*: Promise<{ [string]: string }> */ {
  // The API only allows 50 videos at a time.
  return Promise.all(
    chunk(videoIds, 50).map(group => fetchVideoTitles(group))
  ).then(result => assign({}, ...result));
}

class YouTubeItemNotFound extends Error {
  constructor(message) {
    super(message);
    this.name = "YouTubeItemNotFound";

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, YouTubeItemNotFound);
    }
  }
}

module.exports = {
  fetchApi,
  getCaptions,
  languagesForVideo,
  getVideo,
  getVideos,
  getChannel,
  getChannels,
  getMostPopular,
  getVideoCategories,
  search,
  getLanguages,
  getPlaylists,
  getPlaylist,
  getPlaylistItems,
  stripLocale,
  fetchVideoTitles,
  fetchChunkedVideoTitles
};
