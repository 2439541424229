/* @flow */

import * as React from "react";

import styled from "styled-components";

import WithFlashCardDecks from "./WithFlashCardDecks";
import WithLoginRequiredPage from "./WithLoginRequiredPage";
import { WithMostRecentPromises } from "./WithPromises";
import WithDerivedState from "./WithDerivedState";

import NotFoundPage from "./NotFoundPage";
import { colors } from "./theme";

import { PageContent } from "./FormStyles";

import { putJSON, apiFetch } from "./apiFetch";

import type { UserResourceStore } from "./WithUserResourceStore";
import type { UnsavedFlashCardDeck, Favorite, FlashCardDeck } from "./api";
import type { SnackbarMessage } from "./useSnackbarQueue";

import FlashCardsDeckForm from "./FlashCardsDeckForm";
import { SubmitButton, ActionButton } from "./Buttons";

import { stringsForLocale } from "../lang/web";

import { getVideoTitles } from "./YoutubeScraperClient";

type Props = {
  userResources: ?UserResourceStore,
  isInitialized: boolean,
  nativeLang: string,
  targetLang: ?string,
  onLogin: () => void,
  onLogout: () => void,
  isLoggedIn: boolean,
  id: string,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  onAddSnackbarMessage: SnackbarMessage => void,
  onNavigate: string => void
};

type State = {
  isSubmitted: boolean
};

export default class EditFlashCardsDeckPage extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSubmitted: false
    };
  }

  onSubmit(
    withIdToken: () => Promise<string>,
    deckId: number,
    deck: UnsavedFlashCardDeck
  ) {
    const strings = stringsForLocale(this.props.nativeLang).flash_cards.form;

    this.setState({ isSubmitted: true });

    withIdToken().then(idToken =>
      putJSON(idToken, "/api/flash-cards/decks/" + deckId, deck).then(
        () => {
          this.props.onAddSnackbarMessage({
            level: "message",
            body: strings.snackbar.updated()
          });
          this.props.onNavigate("/flash-cards");
        },
        error => {
          this.props.onAddSnackbarMessage({
            level: "error",
            body: strings.snackbar.error()
          });
          this.setState({ isSubmitted: false });
        }
      )
    );
  }

  onDelete(withIdToken: () => Promise<string>, deckId: number) {
    const strings = stringsForLocale(this.props.nativeLang).flash_cards.form;

    const result = window.confirm(strings.confirmation());
    if (!result) {
      return;
    }

    this.setState({ isSubmitted: true });

    withIdToken().then(idToken =>
      apiFetch(idToken, "/api/flash-cards/decks/" + deckId, {
        method: "DELETE"
      }).then(
        () => {
          this.props.onAddSnackbarMessage({
            level: "message",
            body: strings.snackbar.deleted()
          });
          this.props.onNavigate("/flash-cards");
        },
        error => {
          this.props.onAddSnackbarMessage({
            level: "error",
            body: strings.snackbar.error()
          });
          this.setState({ isSubmitted: false });
        }
      )
    );
  }

  render() {
    return (
      <WithLoginRequiredPage
        onLogin={this.props.onLogin}
        userResources={this.props.userResources}
        isInitialized={this.props.isInitialized}
        nativeLang={this.props.nativeLang}
        render={userResources => (
          <WithFlashCardDecks
            withIdToken={userResources.withIdToken}
            render={flashCardDecksPromise => (
              <WithMostRecentPromises
                promises={[
                  flashCardDecksPromise,
                  userResources.favorites.favorites
                ]}
                renderPending={() => null}
                renderRejected={() => null}
                renderResolved={([flashCardDecks, favorites]) => {
                  const id = parseInt(this.props.id);
                  const deck = flashCardDecks.find(deck => deck.id === id);

                  if (deck) {
                    return (
                      <WithDerivedState
                        controllerProps={favorites.map(f => f.videoId)}
                        controller={getVideoTitles}
                        render={videoTitlesPromise => (
                          <EditFlashCardsDeckView
                            deck={deck}
                            nativeLang={this.props.nativeLang}
                            favorites={favorites}
                            isSubmitted={this.state.isSubmitted}
                            youtubeLanguages={this.props.youtubeLanguages}
                            videoTitlesPromise={videoTitlesPromise}
                            onDelete={this.onDelete.bind(
                              this,
                              userResources.withIdToken,
                              deck.id
                            )}
                            onSubmit={this.onSubmit.bind(
                              this,
                              userResources.withIdToken,
                              deck.id
                            )}
                          />
                        )}
                      />
                    );
                  } else {
                    return (
                      <NotFoundPage
                        onLogin={this.props.onLogin}
                        onLogout={this.props.onLogout}
                        isLoggedIn={this.props.isLoggedIn}
                        nativeLang={this.props.nativeLang}
                        targetLang={this.props.targetLang}
                        userResources={this.props.userResources}
                      />
                    );
                  }
                }}
              />
            )}
          />
        )}
      />
    );
  }
}

type ViewProps = {
  nativeLang: string,
  deck: FlashCardDeck,
  onSubmit: UnsavedFlashCardDeck => void,
  onDelete: () => void,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  favorites: Array<Favorite>,
  videoTitlesPromise: Promise<{ [string]: string }>,
  isSubmitted: boolean
};

class EditFlashCardsDeckView extends React.Component<ViewProps> {
  render() {
    const strings = stringsForLocale(this.props.nativeLang).flash_cards.form;

    return (
      <PageContent>
        <h1>{strings.update_header()}</h1>
        <FlashCardsDeckForm
          onSubmit={this.props.onSubmit}
          favorites={this.props.favorites}
          youtubeLanguages={this.props.youtubeLanguages}
          videoTitlesPromise={this.props.videoTitlesPromise}
          nativeLang={this.props.nativeLang}
          initialValue={this.props.deck}
          isSubmitted={this.props.isSubmitted}
          renderActions={isSubmitDisabled => (
            <React.Fragment>
              <DeleteButton
                disabled={this.props.isSubmitted}
                onActivated={this.props.onDelete}
              >
                {strings.actions.delete()}
              </DeleteButton>
              <SubmitButton
                disabled={isSubmitDisabled}
                primary
                value={strings.actions.update()}
              />
            </React.Fragment>
          )}
        />
      </PageContent>
    );
  }
}

const DeleteButton: typeof ActionButton = styled(ActionButton)`
  color: ${colors.warning};
  border-color: ${colors.warning};
  margin-right: 0.5em;
`;
