/* @flow strict */

import * as React from "react";

type Props = {
  onActivated: () => void,
  children: React.Node,
  disabled?: boolean,
  className?: string,
  role?: string,
  "aria-selected"?: string,
  "aria-controls"?: string
};

export default function ActionLink(props: Props) {
  const { onActivated, disabled } = props;
  const onClick = React.useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (disabled != null) {
        if (disabled) return;
      }

      onActivated();
    },
    [disabled, onActivated]
  );

  return (
    <a
      href="#"
      onClick={onClick}
      className={props.className}
      role={props.role}
      aria-selected={props["aria-selected"]}
      aria-controls={props["aria-controls"]}
    >
      {props.children}
    </a>
  );
}
