/* @flow */

import type { PlayerAction } from "./WithPlayerController";

export default function playerActionForKeyboardEvent(
  event: KeyboardEvent
): ?PlayerAction {
  // None of our commands use modifier keys
  if (event.metaKey || event.ctrlKey || event.altKey) return null;

  if (
    event.target instanceof Node &&
    (event.target.nodeName === "INPUT" || event.target.nodeName === "TEXTAREA")
  )
    return null;

  // TODO: Youtube has some other keyboard shortcuts we should emulate:
  // https://sites.google.com/a/umich.edu/u-m-google-170816/accessibility/google-keyboard-shortcuts---youtube

  if (
    event.code === "Space" ||
    event.keyCode === 32 ||
    event.code === "KeyK" ||
    event.keyCode === 75
  ) {
    return "toggle-playback";
  }

  if (event.code === "KeyR" || event.keyCode === 82) {
    return "repeat";
  }

  if (event.code === "ArrowUp" || event.keyCode === 38) {
    return "previous";
  }

  if (event.code === "ArrowDown" || event.keyCode === 40) {
    return "next";
  }

  return null;
}
