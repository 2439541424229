/* @flow */

import * as React from "react";
import styled from "styled-components";

import { stringsForLocale } from "../lang/web";

export default function PageFooter(props: { nativeLang: string }): React.Node {
  const strings = stringsForLocale(props.nativeLang);
  return (
    <PageFooterStyles>
      <a href="/about">{strings.page_footer.about_link()}</a>
      <a target="_blank" href="https://goo.gl/forms/230G2KZO6xBA8R6H3">
        {strings.page_footer.contact_link()}
      </a>
      <a href="/blogs/learn-korean-with-kpop-from-4minute">Blog</a>
      <a href="/tos">{strings.page_footer.terms_of_service_link()}</a>
      <a href="/privacy">{strings.page_footer.privacy_policy_link()}</a>
    </PageFooterStyles>
  );
}

const PageFooterStyles = styled.div`
  padding: 20px;
  text-align: center;

  a {
    color: #aaa;
    text-decoration: none;
    white-space: nowrap;
    display: block;
    line-height: 2;
    text-align: center;
  }

  @media only screen and (min-device-width: 640px) {
    a {
      display: inline;
      margin-left: 2em;
    }
    a:first-child {
      margin-left: 0;
    }
  }

  a:hover {
    text-decoration: underline;
  }
`;
