/* @flow */
import * as React from "react";
import styled from "styled-components";

import ActionLink from "./ActionLink";
import { FixedPlayerContainer } from "./PlayerContainer";
import KeyboardShortcuts from "./KeyboardShortcuts";
import PlayVideoPageHeader from "./PlayVideoPageHeader";
import PositioningWrapper from "./PositioningWrapper";
import { WithMostRecentPromise } from "./WithPromise";
import SubtitleConfigForm from "./SubtitleConfigForm";

import { Star } from "./SvgAssets";

import type { TimedTextTrack } from "./timedtext2";
import type { UnsavedFavorite, Favorite } from "./api";

import SubtitleScroller, {
  SubtitleListItem,
  PendingSubtitleListItem
} from "./SubtitleScroller";
import PlayerSpacer from "./PlayerSpacer";

import WithPlaybackTracker from "./WithPlaybackTracker";

import type { MergedText } from "./mergeSubtitles";

import { times, pick, isEqual } from "lodash";

import useAutoScroller from "./useAutoScroller";
import useDynamicScrollPosition from "./useDynamicScrollPosition";

import type { DocumentLocation } from "./useNavigation";
import type { UserResourceStore } from "./WithUserResourceStore";
import type { ReferralSource } from "./useReferralSource";
import type { UnsavedLibraryItem } from "./libraryItems";
import type { YouTubeVideo } from "./youtubeScraper";

const PlaybackWrapper = styled.div`
  padding-top: 20px;
`;

type Props = {
  nativeLang: string,
  targetLang: ?string,
  referralSource: ReferralSource | null,
  videoId: string,
  location: DocumentLocation,
  onLogin: (flashMessage?: string) => void,
  onLogout: () => void,
  isLoggedIn: boolean,
  userResources: ?UserResourceStore,
  withIdToken: ?() => Promise<string>,
  pageResources: Promise<YouTubeVideo>,
  onSwapLanguages: Function,
  onChangeTranscription: TimedTextTrack => void,
  onChangeTranslation: TimedTextTrack => void,
  pendingLibraryItems: Array<UnsavedLibraryItem>,
  onAddToVideoLibrary: Function,
  onRemoveFromVideoLibrary: Function,
  onPlayerReady: YT$Event => void,
  onPlayerStateChange: YT$StateEvent => void,
  onTogglePlayback: () => void,
  onPrevious: () => void,
  onNext: () => void,
  onRepeat: () => void,
  onSeek: number => void,
  onAddFavorite: Object => void,
  onRemoveFavorite: (time: number) => void,
  onToggleTranslation: boolean => void,
  pauseAfterSubtitles: boolean,
  onChangePauseAfterSubtitles: boolean => void,
  transcriptionEntry: ?TimedTextTrack,
  translationEntry: ?TimedTextTrack,
  timedTextList: ?Array<TimedTextTrack>,
  activeSubtitleIndex: number,
  showTranslation: boolean,
  subtitles: ?Array<MergedText>,
  playbackRate: number,
  availablePlaybackRates: Array<number>,
  blurTranslation: boolean,
  blurTranscription: boolean,
  onChangeBlurTranslation: boolean => void,
  onChangeBlurTranscription: boolean => void,
  restricted: boolean
};

export default function PlayVideoPageView(props: Props) {
  const scrollProps = useDynamicScrollPosition();

  // TODO: I don't know if it makes sense to store this in state, since it's not being rendered
  // into the DOM.
  const [isPlaying, setIsPlaying] = React.useState(false);

  const onPlayerStateChange = React.useCallback((event: YT$StateEvent) => {
    setIsPlaying(event.data === YT.PlayerState.PLAYING);
  }, []);

  const { setActiveElement } = useAutoScroller({
    enabled: isPlaying,
    scrollTo: scrollProps.scrollTo
  });

  const subtitleListProps = {
    activeSubtitleIndex: props.activeSubtitleIndex,
    subtitles: props.subtitles,
    showTranslation: props.showTranslation,
    onSeek: props.onSeek,
    pendingFavorites: props.userResources
      ? props.userResources.favorites.pendingFavorites
      : [],
    onAddFavorite: props.onAddFavorite,
    onRemoveFavorite: props.onRemoveFavorite,
    setActiveElement: setActiveElement,
    blurTranscription: props.blurTranscription,
    blurTranslation: props.blurTranslation
  };

  let subtitleConfigFormEl = null;
  if (props.timedTextList != null) {
    subtitleConfigFormEl = (
      <SubtitleConfigForm
        transcriptionEntry={props.transcriptionEntry}
        translationEntry={props.translationEntry}
        blurTranslation={props.blurTranslation}
        blurTranscription={props.blurTranscription}
        timedTextList={props.timedTextList}
        onChangeTranslation={props.onChangeTranslation}
        onChangeTranscription={props.onChangeTranscription}
        onChangeBlurTranslation={props.onChangeBlurTranslation}
        onChangeBlurTranscription={props.onChangeBlurTranscription}
        pauseAfterSubtitles={props.pauseAfterSubtitles}
        onChangePauseAfterSubtitles={props.onChangePauseAfterSubtitles}
        onSwapLanguages={props.onSwapLanguages}
        nativeLang={props.nativeLang}
      />
    );
  }

  return (
    <PlaybackWrapper>
      <PositioningWrapper headerRetracted={scrollProps.headerRetracted}>
        <PlayVideoPageHeader
          onLogin={props.onLogin}
          onLogout={props.onLogout}
          nativeLang={props.nativeLang}
          targetLang={props.targetLang}
          isLoggedIn={props.isLoggedIn}
          onSwapLanguages={props.onSwapLanguages}
          onAddToVideoLibrary={props.onAddToVideoLibrary}
          onRemoveFromVideoLibrary={props.onRemoveFromVideoLibrary}
          pendingLibraryItems={props.pendingLibraryItems}
          videoPromise={props.pageResources}
          libraryItems={
            props.userResources
              ? props.userResources.videoLibrary.libraryItems
              : null
          }
          videoId={props.videoId}
        />

        <WithPlaybackTracker
          withIdToken={props.withIdToken}
          videoPromise={props.pageResources}
          referralSource={props.referralSource}
          targetLang={props.targetLang}
          nativeLang={props.nativeLang}
          linkSource={props.location.state}
          render={trackerProps => {
            return (
              <PlayerContainer
                videoId={props.videoId}
                onPlayerReady={props.onPlayerReady}
                onPlayerStateChange={event => {
                  props.onPlayerStateChange(event);
                  trackerProps.onPlayerStateChange(event);
                  onPlayerStateChange(event);
                }}
                playerVars={{}}
              >
                {props.restricted ? null : (
                  <KeyboardShortcuts
                    onTogglePlayback={props.onTogglePlayback}
                    onClickUp={props.onPrevious}
                    onClickDown={props.onNext}
                    onClickRepeat={props.onRepeat}
                    onToggleTranslation={props.onToggleTranslation}
                    nativeLang={props.nativeLang}
                  />
                )}
              </PlayerContainer>
            );
          }}
        />
      </PositioningWrapper>

      <PlayerSpacer />
      {props.restricted ? null : (
        <React.Fragment>
          {subtitleConfigFormEl}
          <SubtitleScroller>
            <WithMostRecentPromise
              promise={
                props.userResources
                  ? props.userResources.favorites.favorites
                  : promiseToEmptyArray
              }
              renderRejected={() => (
                <SubtitleList {...subtitleListProps} favorites={[]} />
              )}
              renderPending={() => (
                <SubtitleList {...subtitleListProps} favorites={[]} />
              )}
              renderResolved={favorites => (
                <SubtitleList {...subtitleListProps} favorites={favorites} />
              )}
            />
          </SubtitleScroller>
        </React.Fragment>
      )}
    </PlaybackWrapper>
  );
}

const promiseToEmptyArray: Promise<Array<Favorite>> = Promise.resolve([]);

type SubtitleListProps = {
  subtitles: ?Array<MergedText>,
  activeSubtitleIndex: number,
  showTranslation: boolean,
  onSeek: number => void,
  pendingFavorites: Array<UnsavedFavorite>,
  favorites: Array<Favorite>,
  onAddFavorite: (subtitle: MergedText) => void,
  onRemoveFavorite: number => void,
  setActiveElement: (?HTMLElement) => void,
  blurTranslation: boolean,
  blurTranscription: boolean
};

class SubtitleList extends React.Component<SubtitleListProps> {
  // Making this a normal component tends to peg the CPU.
  shouldComponentUpdate(nextProps: SubtitleListProps) {
    const propsToCompare = [
      "subtitles",
      "activeSubtitleIndex",
      "showTranslation",
      "pendingFavorites",
      "favorites",
      "blurTranscription",
      "blurTranslation"
    ];
    return !isEqual(
      pick(nextProps, propsToCompare),
      pick(this.props, propsToCompare)
    );
  }

  render() {
    const props = this.props;
    if (props.subtitles) {
      const subtitles = props.subtitles;

      // TODO: props.showTranslation should probably be renamed to "unblur" or something else,
      // since bluring is no longer
      return subtitles.map((o, i) => (
        <SubtitleListItem
          key={`${o.start}-${i}`}
          subtitle={o}
          onSeek={props.onSeek.bind(null, i)}
          showTranscription={
            !props.blurTranscription ||
            (i === props.activeSubtitleIndex && props.showTranslation)
          }
          showTranslation={
            !props.blurTranslation ||
            (i === props.activeSubtitleIndex && props.showTranslation)
          }
          active={i === props.activeSubtitleIndex}
          innerRef={
            i === props.activeSubtitleIndex ? props.setActiveElement : null
          }
          actionElement={
            <StarButton
              subtitle={o}
              pending={isPending(o, props.pendingFavorites)}
              favoriteId={findFavoriteId(o, props.favorites)}
              onAddFavorite={props.onAddFavorite}
              onRemoveFavorite={props.onRemoveFavorite}
            />
          }
        />
      ));
    } else {
      return times(10).map(i => <PendingSubtitleListItem key={i} />);
    }
  }
}

function findFavoriteId(
  subtitle: MergedText,
  favorites: Array<Favorite>
): ?number {
  if (favorites == null) return null;

  const favorite = favorites.find(
    i =>
      i.original.start == subtitle.start &&
      i.original.duration == subtitle.duration
  );

  if (favorite) return favorite.id;
  else return null;
}

function isPending(subtitle, pendingFavorites): boolean {
  return !!pendingFavorites.find(
    i =>
      i.subtitle.start == subtitle.start &&
      i.subtitle.duration == subtitle.duration
  );
}

function StarButton(props) {
  return (
    <ActionLink
      onActivated={
        props.favoriteId == null
          ? props.onAddFavorite.bind(null, props.subtitle)
          : props.onRemoveFavorite.bind(null, props.favoriteId)
      }
    >
      <Star on={props.favoriteId != null} pending={props.pending} width={16} />
    </ActionLink>
  );
}

const PlayerContainer = styled(FixedPlayerContainer)`
  position: absolute;
`;
