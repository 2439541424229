/* @flow */

import * as React from "react";
import styled from "styled-components";

import WithPromise from "../WithPromise";
import formatCurrency from "../formatCurrency";
import { colors, spacers } from "../theme";
import { stringsForLocale } from "../../lang/web";

import type { StripeInvoice, StripePaymentMethodDetails } from "../api";

type Props = {
  invoices: Promise<Array<StripeInvoice>>,
  nativeLang: string
};

export default function PaymentHistorySection(props: Props) {
  const dateFormat = new Intl.DateTimeFormat();

  const strings = stringsForLocale(props.nativeLang);

  return (
    <React.Fragment>
      <h2>{strings.account_page.payment_history_header()}</h2>

      <WithPromise
        promise={props.invoices}
        renderResolved={invoices => (
          <PaymentHistoryList>
            <table>
              <thead>
                <tr>
                  <td>Date</td>
                  <td>Amount</td>
                  <td>Payment Details</td>
                  <td />
                </tr>
              </thead>
              <tbody>
                {invoices.map(invoice => (
                  <tr key={invoice.id}>
                    <td>{dateFormat.format(new Date(invoice.date * 1000))}</td>

                    <td>
                      {formatCurrency(invoice.amount_paid, invoice.currency)}
                    </td>

                    <td>
                      {invoice.pre_payment_credit_notes_amount != 0
                        ? strings.account_page.credit({
                            AMOUNT: formatCurrency(
                              invoice.pre_payment_credit_notes_amount,
                              invoice.currency
                            )
                          })
                        : stringForPaymentMethodDetails(
                            invoice.charge.payment_method_details,
                            strings
                          )}
                    </td>

                    <td>
                      <a href={invoice.invoice_pdf}>
                        {strings.account_page.receipt_link()}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </PaymentHistoryList>
        )}
        renderRejected={() => null}
        renderPending={() => null}
      />
    </React.Fragment>
  );
}

function stringForPaymentMethodDetails(
  details: StripePaymentMethodDetails,
  strings
): string {
  if (details.type == "card" && details.card != null) {
    return strings.account_page.credit_card_description({
      CREDIT_CARD_BRAND: details.card.brand,
      LAST4: details.card.last4
    });
  } else {
    return details.type;
  }
}

const PaymentHistoryList = styled.div`
  overflow-x: auto;

  table {
    min-width: 100%;
    border-collapse: collapse;
    border: 1px solid #e5e7eb;

    thead {
      color: ${colors["black-60"]};
      font-weight: bold;

      tr {
        border-bottom: 1px solid #e5e7eb;
      }

      td {
        background: #f4f5f7;
      }
    }

    tr:nth-child(even) {
      background: #f9fafb;
    }

    tr:nth-child(odd) {
      background: #ffffff;
    }

    td {
      padding: ${spacers[3]} ${spacers[4]};
    }

    td:last-child {
      text-align: right;
    }

    a {
      color: ${colors.blue};
    }
  }
`;
