/* @flow */

import * as React from "react";

import { stringsForLocale } from "../lang/web";
import { ButtonLink } from "./Buttons";

import styled from "styled-components";

import { Box, PageContent } from "./FormStyles";

import { colors } from "./theme";

type Props = {
  nativeLang: string
};

export default function SubscriptionConfirmationPage(props: Props) {
  const strings = stringsForLocale(props.nativeLang).flash_cards
    .premium_subscription.confirmation;

  return (
    <PageContent>
      <MyBox>
        <Header>{strings.header()}</Header>
        <CheckboxWrapper>
          <svg
            viewBox="0 0 847 862"
            x="0px"
            y="0px"
            width="75"
            fillRule="evenodd"
            clipRule="evenodd"
          >
            <path
              fill={colors.highlight}
              d="M600 256c16,16 33,33 49,49 -78,79 -157,158 -236,236 -16,17 -33,33 -49,50 -17,-17 -33,-33 -50,-50 -39,-39 -78,-77 -117,-116 17,-17 33,-33 50,-50 39,39 78,78 117,117 78,-79 157,-157 236,-236zm-177 -251c231,0 418,187 418,418 0,231 -187,418 -418,418 -231,0 -418,-187 -418,-418 0,-231 187,-418 418,-418zm0 57c200,0 362,162 362,361 0,200 -162,362 -362,362 -199,0 -361,-162 -361,-362 0,-199 162,-361 361,-361z"
            />
          </svg>
        </CheckboxWrapper>

        <p dangerouslySetInnerHTML={{ __html: strings.paragraph1() }} />
        <p dangerouslySetInnerHTML={{ __html: strings.paragraph2() }} />
        <p dangerouslySetInnerHTML={{ __html: strings.paragraph3() }} />
        <p>
          Thank you,
          <br />
          Jonathan
          <br />
          {strings.creator_title()}
        </p>

        <CallToAction>
          <ButtonLink href="/flash-cards">
            {strings.flash_cards_action()}
          </ButtonLink>
          <ButtonLink href="/search">{strings.videos_action()}</ButtonLink>
        </CallToAction>
      </MyBox>
    </PageContent>
  );
}

const MyBox = styled(Box)`
  padding: 2em 3em;
  p {
    line-height: 1.5em;
  }
`;

const CallToAction = styled.div`
  border-top: 1px solid #eee;
  text-align: center;
  margin-top: 2em;
  a {
    margin: 0 0.5em;
    margin-top: 2em;
  }
`;

const Header = styled.div`
  text-align: center;
  font-weight: bold;
  margin-bottom: 1em;
`;

const CheckboxWrapper = styled.div`
  text-align: center;
`;
