/* @flow */

import * as React from "react";

import type { LoginResources } from "./WithUserResourceStore";

type Props<Resource, State> = {
  loginResources: ?LoginResources,
  buildResource: (
    LoginResources,
    state: State,
    setState: (updater: (State) => $Shape<State>) => void
  ) => Resource,
  initializeState: LoginResources => State,
  render: (?Resource) => React.Node
};

export default class WithAPIResource<
  Resource,
  State: {}
> extends React.Component<Props<Resource, State>, State> {
  constructor(props: Props<Resource, State>) {
    super(props);

    if (props.loginResources) {
      this.state = props.initializeState(props.loginResources);
    }
  }

  componentDidUpdate(prevProps: Props<Resource, State>) {
    if (this.props.loginResources != null && prevProps.loginResources == null) {
      this.setState(this.props.initializeState(this.props.loginResources));
    }
  }

  render() {
    if (this.props.loginResources && this.state) {
      return this.props.render(
        this.props.buildResource(
          this.props.loginResources,
          this.state,
          this.setState.bind(this)
        )
      );
    } else {
      return this.props.render(null);
    }
  }
}
