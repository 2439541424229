/* @flow */

import youtubeLanguagesEn from "./youtubeLanguagesEn";

// This is the algorithm we use to decide whether a subtitle should show up for a given target language.
export function matchLanguage(
  desiredLang: string,
  candidateLang: string
): boolean {
  return (
    normalizeLanguage(desiredLang).toLowerCase() ===
    normalizeLanguage(candidateLang).toLowerCase()
  );
}

export function normalizeLanguage(lang: string): string {
  // Note: We still require exact matches for the following:
  //   pt     Portuguese (brazil)
  //   pt-PT  Portuguese (portugal)
  //   es     Spanish (Spain)
  //   es-419 Spanish (Latin America)
  //   es-US  Spanish (US)
  //   zh-HK  Chinese (Hong Kong)
  switch (lang.toLowerCase()) {
    case "zh":
    case "zh-cn":
    case "zh-hans":
      return "zh-CN";

    case "zh-tw":
    case "zh-hant":
      return "zh-TW";

    case "sr-latn":
      return "sr";

    case "fr-ca":
      return "fr";

    case "en-us":
    case "en-gb":
      return "en";

    default:
      return lang;
  }
}

function isYouTubeLocale(locale: string) {
  return (
    youtubeLanguagesEn.items.findIndex(
      i => i.id.toLowerCase() === locale.toLowerCase()
    ) !== -1
  );
}

export function closestYouTubeLocale(locale: string): string {
  if (isYouTubeLocale(locale)) return locale;

  const stripped = locale.split("-")[0];
  if (stripped === "zh") {
    return "zh-CN"; // There is no base "zh" locale, so just go with China.
  } else {
    if (isYouTubeLocale(stripped)) {
      return stripped;
    } else {
      return "en";
    }
  }
}
