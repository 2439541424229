/* @flow */

import * as React from "react";
import { isEqual } from "lodash";

type Props<A, B> = {
  controller: A => B,
  controllerProps: A,
  render: B => React.Node
};

type State<B> = {
  value: B
};

export default class WithDerivedState<A, B> extends React.Component<
  Props<A, B>,
  State<B>
> {
  constructor(props: Props<A, B>) {
    super(props);
    this.state = {
      value: props.controller(props.controllerProps)
    };
  }

  componentDidUpdate(prevProps: Props<A, B>) {
    if (
      prevProps.controller !== this.props.controller ||
      !isEqual(prevProps.controllerProps, this.props.controllerProps)
    ) {
      this.setState({
        value: this.props.controller(this.props.controllerProps)
      });
    }
  }

  render() {
    return this.props.render(this.state.value);
  }
}
