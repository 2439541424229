/* @flow */

import * as React from "react";

import styled from "styled-components";

import type { Favorite } from "./api";
import type { UnsavedFlashCardDeck } from "./api";
import type { MergedText } from "./mergeSubtitles";

import CloseLink from "./CloseLink";
import { Box } from "./FormStyles";

import WithPromise from "./WithPromise";

import LoadingTextPlaceholder from "./LoadingTextPlaceholder";

import { normalizeLanguage } from "./matchLanguage";
import { languageNameFromResponse } from "./youtubeUtils";
import { secondsToString } from "./mediaTimestampFormat";

import {
  groupBy,
  mapValues,
  map,
  sortBy,
  omit,
  every,
  isEqual,
  uniq
} from "lodash";

import { stringsForLocale } from "../lang/web";

type Props = {
  onSubmit: UnsavedFlashCardDeck => void,
  isSubmitted: boolean,
  favorites: Array<Favorite>,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  videoTitlesPromise: Promise<{ [string]: string }>,
  nativeLang: string,
  initialValue?: UnsavedFlashCardDeck,
  renderActions: boolean => React.Node
};

type State = {
  favoriteIds: NumberSet,
  name: string,
  isStuck: boolean
};

function isStuck(offset) {
  return offset > 130;
}

export default class NewFlashDeckForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let name, favoriteIds;
    if (props.initialValue) {
      name = props.initialValue.name;
      favoriteIds = props.initialValue.favoriteIds;
    } else {
      name = "";
      favoriteIds = [];
    }

    this.state = {
      favoriteIds: new NumberSet(favoriteIds),
      name: name,
      isStuck: isStuck(window.pageYOffset)
    };
    this.onScroll = this.onScroll.bind(this);
  }

  onScroll: (event: Event) => void;

  onScroll(event: Event) {
    const value = isStuck(window.pageYOffset);
    if (value != this.state.isStuck) {
      this.setState({ isStuck: value });
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  isChecked(favoriteId: number): boolean {
    return this.state.favoriteIds.has(favoriteId);
  }

  onChangeFavorite(ids: Array<number>, event: Event) {
    if (event.target instanceof HTMLInputElement) {
      const checked = event.target.checked;
      this.setState(state => ({
        favoriteIds: checked
          ? state.favoriteIds.add(ids)
          : state.favoriteIds.remove(ids)
      }));
    }
  }

  onChangeName(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      this.setState({ name: event.target.value });
    }
  }

  onSubmit(event: Event) {
    event.preventDefault();

    if (this.isSubmitDisabled()) return;

    const newDeck: UnsavedFlashCardDeck = {
      name: this.state.name,
      favoriteIds: this.state.favoriteIds.toArray()
    };

    this.props.onSubmit(newDeck);
  }

  isSubmitDisabled(): boolean {
    return this.state.name == "" || this.props.isSubmitted;
  }

  getDeck(): UnsavedFlashCardDeck {
    return {
      name: this.state.name,
      favoriteIds: this.state.favoriteIds.toArray()
    };
  }

  onAdd(ids: Array<number>) {
    this.setState(state => ({
      favoriteIds: state.favoriteIds.add(ids)
    }));
  }

  onRemove(ids: Array<number>) {
    this.setState(state => ({
      favoriteIds: state.favoriteIds.remove(ids)
    }));
  }

  render() {
    const strings = stringsForLocale(this.props.nativeLang).flash_cards.form;

    return (
      <form onSubmit={this.onSubmit.bind(this)}>
        <CloseLink href="/flash-cards" />
        <StickyBox stuck={this.state.isStuck}>
          <h2>{strings.name_of_deck_header()}</h2>
          <Input
            value={this.state.name}
            onChange={this.onChangeName.bind(this)}
          />

          <ActionBar>
            <SelectedCount>
              {strings.selected_count({
                COUNT: this.state.favoriteIds.count()
              })}
            </SelectedCount>

            <Actions>
              {this.props.renderActions(this.isSubmitDisabled())}
            </Actions>
          </ActionBar>
        </StickyBox>
        <SmoothBox>
          <h2>{strings.select_captions_header()}</h2>

          <LanguageTOC
            favorites={this.props.favorites}
            nativeLang={this.props.nativeLang}
            youtubeLanguages={this.props.youtubeLanguages}
          />

          <FavoriteListInputs
            favorites={this.props.favorites}
            videoTitlesPromise={this.props.videoTitlesPromise}
            nativeLang={this.props.nativeLang}
            youtubeLanguages={this.props.youtubeLanguages}
            onAdd={this.onAdd.bind(this)}
            onRemove={this.onRemove.bind(this)}
            selectedIds={this.state.favoriteIds}
          />
        </SmoothBox>
      </form>
    );
  }
}

const Actions = styled.div`
  float: right;
`;
const ActionBar = styled.div`
  margin-top: 1em;
  overflow: hidden;
`;
const SelectedCount = styled.div`
  float: left;
  line-height: 36px;
`;

function AsyncVideoTitle(props: {
  videoId: string,
  videoTitles: Promise<{ [string]: string }>
}) {
  return (
    <WithPromise
      promise={props.videoTitles}
      renderRejected={error => null}
      renderPending={() => <LoadingTextPlaceholder width={25} />}
      renderResolved={videoTitles => <div>{videoTitles[props.videoId]}</div>}
    />
  );
}

function VideoThumbnail(props: { videoId: string }) {
  return (
    <img
      height="90"
      src={`https://img.youtube.com/vi/${props.videoId}/mqdefault.jpg`}
    />
  );
}

function SubtitleText(props: {
  translations: Array<string>,
  transcription: string
}) {
  return (
    <TextColumn>
      <p
        dangerouslySetInnerHTML={{
          __html: props.transcription
        }}
      />
      {props.translations.map((translation, i: number) => (
        <p
          key={i}
          dangerouslySetInnerHTML={{
            __html: translation
          }}
        />
      ))}
    </TextColumn>
  );
}

function SingleCaptionAndLanguageVideoRow(props: {
  onChange: Event => void,
  checked: boolean,
  title: React.Node,
  videoId: string,
  nativeLang: string,
  languageName: string,
  subtitle: MergedText,
  id: string
}) {
  return (
    <Row id={props.id}>
      <CheckBoxColumn>
        <input
          type="checkbox"
          onChange={props.onChange}
          checked={props.checked}
        />
      </CheckBoxColumn>

      <ThumbnailColumn>
        <GroupHeader>{props.languageName}</GroupHeader>
        <GroupHeader>{props.title}</GroupHeader>
        <VideoThumbnail videoId={props.videoId} />
      </ThumbnailColumn>

      <TimestampColumn>
        {secondsToString(Math.floor(props.subtitle.start))}
      </TimestampColumn>

      <SubtitleText
        translations={props.subtitle.translations}
        transcription={props.subtitle.transcription}
      />
    </Row>
  );
}

function SingleCaptionVideoRow(props: {
  onChange: Event => void,
  checked: boolean,
  title: React.Node,
  videoId: string,
  nativeLang: string,
  subtitle: MergedText
}) {
  return (
    <Row>
      <CheckBoxColumn>
        <input
          type="checkbox"
          onChange={props.onChange}
          checked={props.checked}
        />
      </CheckBoxColumn>

      <ThumbnailColumn>
        <GroupHeader>{props.title}</GroupHeader>
        <VideoThumbnail videoId={props.videoId} />
      </ThumbnailColumn>

      <TimestampColumn>
        {secondsToString(Math.floor(props.subtitle.start))}
      </TimestampColumn>

      <SubtitleText
        translations={props.subtitle.translations}
        transcription={props.subtitle.transcription}
      />
    </Row>
  );
}

function GroupRow(props: {
  count: number,
  onChange: Event => void,
  checked: boolean,
  nativeLang: string,
  title: React.Node,
  children?: React.Node,
  id?: string
}) {
  const strings = stringsForLocale(props.nativeLang).flash_cards.form;

  return (
    <Row id={props.id}>
      <CheckBoxColumn>
        <input
          type="checkbox"
          onChange={props.onChange}
          checked={props.checked}
        />
      </CheckBoxColumn>

      <ThumbnailColumn>
        <GroupHeader>{props.title}</GroupHeader>
        {props.children}
        {props.checked
          ? strings.unselect_multiple_captions({ COUNT: props.count })
          : strings.select_multiple_captions({ COUNT: props.count })}
      </ThumbnailColumn>
    </Row>
  );
}

type GroupedFavorites = Array<
  [
    string,
    Array<{
      videoId: string,
      favorites: Array<Favorite>
    }>
  ]
>;

function groupFavorites(favorites: Array<Favorite>): GroupedFavorites {
  // TODO: This should really be typed as {[string]: Array<Favorite>}, but then flow
  // gets stuck with the mapValues call
  const groupedByLang: Object = groupBy(favorites, favorite =>
    normalizeLanguage(favorite.transcriptionLang || "")
  );

  const groupedByVideo = mapValues(groupedByLang, list =>
    map(groupBy(list, favorite => favorite.videoId), (favorites, videoId) => ({
      videoId,
      favorites
    }))
  );

  const asArray = sortBy(
    map(groupedByVideo, (v, k) => [k, v]),
    list => list[0]
  );

  return asArray;
}

type LanguageTOCProps = {
  favorites: Array<Favorite>,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  nativeLang: string
};

function domIdForLanguage(lang) {
  return "lang-" + lang;
}

const LanguageTOCStyles = styled.div`
  margin-bottom: 2em;
`;

function LanguageTOC(props: LanguageTOCProps) {
  const langs = uniq(
    filterNull(props.favorites.map(f => f.transcriptionLang)).map(
      normalizeLanguage
    )
  ).sort();

  if (langs.length < 2) {
    return null;
  } else {
    return (
      <LanguageTOCStyles>
        {map(langs, (lang, i: number) => (
          <React.Fragment key={lang}>
            <a href={"#" + domIdForLanguage(lang)} data-skip-navigation={true}>
              {languageNameFromResponse(lang, props.youtubeLanguages)}
            </a>
            {i === langs.length - 1 ? null : <span>{", "}</span>}
          </React.Fragment>
        ))}
      </LanguageTOCStyles>
    );
  }
}

const GroupHeader = styled.div`
  font-weight: bold;
`;

const rowBorder = "1px solid #eee";

const RowList = styled.div`
  border: ${rowBorder};
`;

const Row = styled.label`
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: repeat(8, 1fr);
  background-color: white;
  padding: 1em;

  img {
    display: block;
    margin-bottom: 1em;
  }

  border-bottom: ${rowBorder};
  &:last-child {
    border-bottom: none;
  }
`;

const ThumbnailColumn = styled.div`
  grid-column-start: 2;
  grid-column-end: 9;
`;

const TimestampColumn = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
`;

const CheckBoxColumn = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  text-align: center;
`;

const TextColumn = styled.div`
  grid-column-start: 3;
  grid-column-end: 9;
  p {
    margin: 0;
  }
`;

const StickyBox = styled(Box)`
  position: sticky;
  top: 5px;
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  transition: box-shadow 150ms ease-in-out;
  box-shadow: ${props => (props.stuck ? "0px 3px 3px #ddd" : "none")};
`;

const SmoothBox = styled(Box)`
  border-top: none;
  border-top-left-radius: 0;
  border-top-left-radius: 0;
  h2 {
    margin-top: 0;
  }
`;

import { inputStyle } from "./Forms";

const Input = styled.input`
  ${inputStyle};
  width: 100%;
  box-sizing: border-box;
`;

function filterNull<T>(list: Array<T>): Array<$NonMaybeType<T>> {
  return list.filter(i => i != null);
}

class NumberSet {
  constructor(input: Array<number>) {
    this.items = {};
    input.forEach(i => {
      this.items[i] = true;
    });
  }

  items: { [number]: boolean };

  add(items: Array<number>): NumberSet {
    return new NumberSet(this.toArray().concat(items));
  }

  remove(items: Array<number>): NumberSet {
    return new NumberSet(this.toArray().filter(i => !items.includes(i)));
  }

  has(i: number): boolean {
    return !!this.items[i];
  }

  isEqual(other: NumberSet) {
    if (other === this) return true;

    return isEqual(this.toArray(), other.toArray());
  }

  count() {
    return Object.keys(this.items).length;
  }

  toArray(): Array<number> {
    return Object.keys(this.items).map(str => parseInt(str));
  }
}

type FavoriteListInputsProps = {
  favorites: Array<Favorite>,
  videoTitlesPromise: Promise<{ [string]: string }>,
  nativeLang: string,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  onAdd: (ids: Array<number>) => void,
  onRemove: (ids: Array<number>) => void,
  selectedIds: NumberSet
};

class FavoriteListInputs extends React.Component<FavoriteListInputsProps> {
  // The render method actually seems pretty quick (~1-2ms) but I think all
  // the reconciliation inside of React is slow. You can tell when you try to
  // type fast inside of the input (with a dev build)
  shouldComponentUpdate(nextProps: FavoriteListInputsProps) {
    if (!this.props.selectedIds.isEqual(nextProps.selectedIds)) return true;

    const omitProps = ["onAdd", "onRemove", "selectedIds"];

    return !isEqual(omit(this.props, omitProps), omit(nextProps, omitProps));
  }

  onChangeFavorite(ids: Array<number>, event: Event) {
    if (event.target instanceof HTMLInputElement) {
      if (event.target.checked) {
        this.props.onAdd(ids);
      } else {
        this.props.onRemove(ids);
      }
    }
  }

  propsForGroupCheckbox(favorites: Array<Favorite>) {
    const checked = every(favorites, favorite =>
      this.props.selectedIds.has(favorite.id)
    );

    const onChange = (event: Event) => {
      this.onChangeFavorite(favorites.map(f => f.id), event);
    };

    const count = favorites.length;

    return { checked, onChange, count };
  }

  favoritesForLang(langCode: string): Array<Favorite> {
    return this.props.favorites.filter(
      f =>
        f.transcriptionLang &&
        normalizeLanguage(f.transcriptionLang) === normalizeLanguage(langCode)
    );
  }

  render() {
    return (
      <RowList>
        {groupFavorites(this.props.favorites).map(([langCode, videoList]) => (
          <React.Fragment key={langCode}>
            {videoList.length === 1 && videoList[0].favorites.length == 1 ? (
              <SingleCaptionAndLanguageVideoRow
                id={domIdForLanguage(langCode)}
                key={videoList[0].videoId}
                title={
                  <AsyncVideoTitle
                    videoTitles={this.props.videoTitlesPromise}
                    videoId={videoList[0].videoId}
                  />
                }
                checked={this.props.selectedIds.has(
                  videoList[0].favorites[0].id
                )}
                onChange={this.onChangeFavorite.bind(this, [
                  videoList[0].favorites[0].id
                ])}
                videoId={videoList[0].videoId}
                nativeLang={this.props.nativeLang}
                subtitle={videoList[0].favorites[0].subtitle}
                languageName={languageNameFromResponse(
                  langCode,
                  this.props.youtubeLanguages
                )}
              />
            ) : (
              <React.Fragment>
                <GroupRow
                  id={domIdForLanguage(langCode)}
                  title={languageNameFromResponse(
                    langCode,
                    this.props.youtubeLanguages
                  )}
                  nativeLang={this.props.nativeLang}
                  {...this.propsForGroupCheckbox(
                    this.favoritesForLang(langCode)
                  )}
                />

                {map(videoList, item =>
                  item.favorites.length === 1 ? (
                    <SingleCaptionVideoRow
                      key={item.videoId}
                      title={
                        <AsyncVideoTitle
                          videoTitles={this.props.videoTitlesPromise}
                          videoId={item.videoId}
                        />
                      }
                      checked={this.props.selectedIds.has(item.favorites[0].id)}
                      onChange={this.onChangeFavorite.bind(this, [
                        item.favorites[0].id
                      ])}
                      videoId={item.videoId}
                      nativeLang={this.props.nativeLang}
                      subtitle={item.favorites[0].subtitle}
                    />
                  ) : (
                    <React.Fragment key={item.videoId}>
                      <GroupRow
                        title={
                          <AsyncVideoTitle
                            videoTitles={this.props.videoTitlesPromise}
                            videoId={item.videoId}
                          />
                        }
                        nativeLang={this.props.nativeLang}
                        {...this.propsForGroupCheckbox(item.favorites)}
                      >
                        <VideoThumbnail videoId={item.videoId} />
                      </GroupRow>

                      {item.favorites.map(favorite => (
                        <Row key={favorite.id}>
                          <CheckBoxColumn>
                            <input
                              type="checkbox"
                              checked={this.props.selectedIds.has(favorite.id)}
                              onChange={this.onChangeFavorite.bind(this, [
                                favorite.id
                              ])}
                            />
                          </CheckBoxColumn>

                          <TimestampColumn>
                            {secondsToString(
                              Math.floor(favorite.subtitle.start)
                            )}
                          </TimestampColumn>

                          <SubtitleText
                            transcription={favorite.subtitle.transcription}
                            translations={favorite.subtitle.translations}
                          />
                        </Row>
                      ))}
                    </React.Fragment>
                  )
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        ))}
      </RowList>
    );
  }
}
