/* @flow */

import * as React from "react";
import styled from "styled-components";

import { stringsForLocale } from "../lang/web";
import * as geometry from "./geometry";

import { SubmitButton } from "./Buttons";

import { inputStyle } from "./Forms";

type Props = {
  nativeLang: string,
  value: string,
  onChange: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  disabled: boolean,
  targetLanguageName: string,
  style?: Object
};

export default class SearchField extends React.Component<Props> {
  render() {
    const strings = stringsForLocale(this.props.nativeLang);
    return (
      <Field style={this.props.style}>
        <SearchInput
          type="text"
          value={this.props.value}
          placeholder={strings.search_form.placeholder({
            LANGUAGE: this.props.targetLanguageName
          })}
          onChange={this.props.onChange}
        />
        <SubmitButton
          value={strings.search_form.search_action()}
          disabled={this.props.disabled}
        />
      </Field>
    );
  }
}

const Field = styled.div`
  margin: 11px auto;
  display: flex;
  max-width: ${geometry.videoWidth}px;

  input[type="text"] {
    flex: 1;
  }
  input[type="submit"] {
    flex: 0;
  }
`;

const SearchInput = styled.input`
  margin-right: 5px;
  ${inputStyle}
  width: 530px;
`;
