/* @flow */

import * as React from "react";
import { colors } from "./theme";
import styled from "styled-components";

import { Popcorn } from "./SvgAssets";

type Props = {
  size: number,
  className?: string
};

export default function Branding(props: Props): React.Node {
  return (
    <Styles size={props.size} className={props.className}>
      <Popcorn height={1.25 * props.size} />
      CaptionPop
    </Styles>
  );
}

const Styles = styled.div`
  font-family: Foco, sans-serif;
  font-weight: bold;
  font-size: ${props => props.size}px;
  color: ${colors.dark};

  text-decoration: none;

  svg {
    border-style: none;
    display: inline-block;
    fill: ${colors.dark};
    position: relative;
    top: 3px;
  }
`;
