/* @flow */
import * as React from "react";
import styled from "styled-components";

import { Arrow } from "./SvgAssets";
import { playerZIndex } from "./playerGeometry";

export type PopupTarget = Element | Range;

type Props = {
  targetEl: PopupTarget,
  children: React.Node,
  defaultWidth: number,
  defaultHeight: number,
  color: string,
  zIndex?: number,
  className?: string,
  orientation?: PopupOrientation
};

export type Rect = {
  top: number,
  left: number,
  width: number,
  height: number
};

// These are use to transform the targetRef from viewport coodinates to page coordinates
type TranslationOffsets = {
  x: number,
  y: number
};

export default function Popup(props: Props) {
  const [measurements, setMeasurements] = React.useState<Measurements | null>(
    null
  );
  const contentRef = React.useRef<HTMLElement | null>(null);

  const updateMeasurements = React.useCallback(() => {
    setMeasurements(measureDOM(props.targetEl, contentRef.current));
  }, [props.targetEl]);

  React.useEffect(() => {
    updateMeasurements();

    window.addEventListener("resize", updateMeasurements);

    return () => {
      window.removeEventListener("resize", updateMeasurements);
    };
  }, [updateMeasurements]);

  const mutationObserver = React.useRef<MutationObserver | null>(null);

  const setPopupContent = React.useCallback(
    (element: ?HTMLElement) => {
      if (element) {
        contentRef.current = element;

        if (mutationObserver.current) {
          mutationObserver.current.disconnect();
        }

        updateMeasurements();

        const observer = new MutationObserver(updateMeasurements);
        const config = {
          attributes: true,
          childList: true,
          subtree: true,
          characterData: true
        };
        observer.observe(element, config);
      } else {
        contentRef.current = null;
        setMeasurements(null);

        if (mutationObserver.current) {
          mutationObserver.current.disconnect();
          mutationObserver.current = null;
        }
      }
    },
    [updateMeasurements]
  );

  if (measurements == null) {
    return null;
  } else {
    const positioning = calculatePosition(
      measurements,
      props.defaultWidth,
      props.defaultHeight,
      props.orientation || null
    );

    let zIndex;
    if (typeof props.zIndex === "number") {
      zIndex = props.zIndex;
    } else {
      // Position downward facing popups above the player. This is because downward facing popups
      // are more likely to be obscured by the the player.
      if (positioning.orientation === "above") {
        zIndex = playerZIndex + 1;
      } else {
        zIndex = playerZIndex - 1;
      }
    }

    const style = {
      ...positioning.content,
      zIndex,
      backgroundColor: props.color
    };

    return (
      <PopupContent
        className={props.className}
        style={style}
        ref={setPopupContent}
      >
        <Arrow
          direction={positioning.orientation === "above" ? "down" : "up"}
          color={props.color}
          style={{ ...positioning.arrow, position: "absolute" }}
        />
        {props.children}
      </PopupContent>
    );
  }
}

type WindowSize = {
  width: number,
  height: number
};

type PopupOrientation = "above" | "below";

type AbsoluteCoordinates =
  | { left: number, top: number }
  | { left: number, bottom: number };

type PopupPositioning = {
  content: AbsoluteCoordinates,
  arrow: AbsoluteCoordinates,
  orientation: PopupOrientation
};

type Measurements = {
  // The rect for targetEl, in Viewport coordinates
  targetRect: Rect,

  // The rect for the PopupContent, in Viewport coordinates
  contentRect: Rect | null,

  // These are the offsets that should be used to translate from Viewport coordinates into
  // the coordinates that can be absolutely positioned using the same offsetParent
  // as the targetEl.
  translationOffsets: TranslationOffsets,

  windowSize: WindowSize
};

function measureDOM(
  targetEl: Element | Range,
  contentEl: HTMLElement | null
): Measurements {
  const targetRect = targetEl.getBoundingClientRect();

  let contentRect, translationOffsets;

  if (contentEl == null) {
    contentRect = null;
    translationOffsets = { x: 0, y: 0 };
  } else {
    contentRect = contentEl.getBoundingClientRect();

    const offsetParent = contentEl.offsetParent;

    translationOffsets;
    if (offsetParent == null) {
      // I don't think we ever hit this condition, because elements by default seem to have
      // the document.body as the offsetParent. If we ever decided that popups should be located
      // outside of any relative parents (ie: direct children of <body>), we could just always
      // use the scroll position for translation offsets.
      translationOffsets = { x: window.scrollX, y: window.scrollY };
    } else {
      const rect = offsetParent.getBoundingClientRect();
      translationOffsets = { x: -rect.left, y: -rect.top };
    }
  }

  return {
    targetRect,
    contentRect,
    translationOffsets,
    windowSize: {
      width: window.innerWidth,
      height: window.innerHeight
    }
  };
}

// This is the closest to the edge of the screen we will render the menu
const edgeBuffer = 10;

function calculatePosition(
  measurements: Measurements,
  defaultWidth: number,
  defaultHeight: number,
  requestedOrientation: PopupOrientation | null
): PopupPositioning {
  const {
    contentRect,
    targetRect,
    translationOffsets,
    windowSize
  } = measurements;

  const arrowHeight = 10;
  const arrowWidth = 20;

  const contentHeight = contentRect ? contentRect.height : defaultHeight;
  const contentWidth = contentRect ? contentRect.width : defaultWidth;

  const targetRectBottom = targetRect.top + targetRect.height;

  let orientation;
  if (requestedOrientation == null) {
    if (windowSize.height - targetRectBottom > contentHeight + arrowHeight) {
      orientation = "below";
    } else {
      orientation = "above";
    }
  } else {
    orientation = requestedOrientation;
  }

  // Constain the value of left to fit on the screen
  let left = targetRect.left + targetRect.width / 2 - contentWidth / 2;

  if (left < edgeBuffer) {
    left = edgeBuffer;
  }

  if (left + contentWidth + edgeBuffer > windowSize.width) {
    left = windowSize.width - contentWidth - edgeBuffer;
  }

  let top;
  if (orientation === "above") {
    top = targetRect.top - (contentHeight + arrowHeight);
  } else {
    top = targetRectBottom + arrowHeight;
  }

  // Position the arrow in the center of the targetRect.
  const arrowLeft =
    targetRect.left + targetRect.width / 2 - arrowWidth / 2 - left;

  // Translate into absolute coordinations
  left += translationOffsets.x;
  top += translationOffsets.y;

  let arrowPosition;
  if (orientation === "above") {
    arrowPosition = {
      left: arrowLeft,
      bottom: -arrowHeight
    };
  } else {
    arrowPosition = {
      left: arrowLeft,
      top: -arrowHeight
    };
  }

  return {
    orientation,
    content: { left, top },
    arrow: arrowPosition
  };
}

const PopupContent = styled.div`
  position: absolute;
  box-shadow: 2px 2px 5px #ccc;
  border-radius: 4px;
  max-width: calc(100vw - ${edgeBuffer * 2}px);
`;
