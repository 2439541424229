/* @flow */
//
// YouTube changed the timedtext API on Nov 9, 2021. Requests now require signatures
//

import { getInitialPlayerResponse } from "./YoutubeScraperClient";

import fetchXML from "./fetchXML";
import { parseTimedTextXMLDoc } from "./timedtext";

export type TimedText = {
  start: number,
  duration: number,
  text: string
};

export type TimedTextTrack = {
  baseUrl: string,
  isTranslatable: boolean,
  languageCode: string,
  name: { simpleText: string },
  vssId: string
};

export function fetchTimedTextList(
  videoId: string,
  _params: { hl?: string } = {}
): Promise<Array<TimedTextTrack>> {
  return getInitialPlayerResponse(videoId).then(r => {
    if (
      typeof r === "object" &&
      typeof r.captions === "object" &&
      typeof r.captions.playerCaptionsTracklistRenderer === "object" &&
      typeof r.captions.playerCaptionsTracklistRenderer.captionTracks ===
        "object" &&
      Array.isArray(r.captions.playerCaptionsTracklistRenderer.captionTracks)
    ) {
      return r.captions.playerCaptionsTracklistRenderer.captionTracks;
    } else {
      throw new Error(
        "Unable to parse captions.playerCaptionsTracklistRenderer.captionTracks from initialPlayerResponse"
      );
    }
  });
}

export function fetchTimedText(baseUrl: string): Promise<Array<TimedText>> {
  // XXX: This needs to do the origin server fallback
  return fetchXML(baseUrl).then(parseTimedTextXMLDoc);
}
