/* @flow */

import * as React from "react";

import Overlay, { OverlayShadow } from "./Overlay";

import styled from "styled-components";

import { ButtonLink } from "./Buttons";
import { ProgressCircle } from "./ProgressCircle";

import { secondsToString } from "./mediaTimestampFormat";

import { stringsForLocale } from "../lang/web";

type Props = {
  startOfDay: number,
  now: number,
  nativeLang: string
};

const duration = 24 * 60 * 60 * 1000; // 24 hours

export default class PayWallOverlay extends React.Component<Props> {
  render() {
    const endOfDay = this.props.startOfDay + duration;
    const strings = stringsForLocale(this.props.nativeLang).flash_cards
      .paywall_overlay;

    if (this.props.now >= endOfDay) {
      return null;
    } else {
      return (
        <React.Fragment>
          <OverlayShadow enter={true} />
          <OverlayStyles orientation="center" enter={true}>
            <Header>{strings.header()}</Header>
            <ProgressCircle
              size={100}
              progress={(this.props.now - this.props.startOfDay) / duration}
              text={secondsToString(
                Math.floor((endOfDay - this.props.now) / 1000)
              )}
            />
            <Text>{strings.text()}</Text>

            <div>
              <ButtonLink href="/premium-subscription" primary>
                {strings.call_to_action()}
              </ButtonLink>
            </div>
          </OverlayStyles>
        </React.Fragment>
      );
    }
  }
}

const Header = styled.div`
  margin-bottom: 1em;
  font-weight: bold;
`;

const Text = styled.div`
  margin: 1em 0;
`;

const OverlayStyles = styled(Overlay)`
  text-align: center;
`;
