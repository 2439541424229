/* @flow */

import * as React from "react";
import styled from "styled-components";

import WithDerivedState from "./WithDerivedState";
import PageHeader from "./PageHeader";

import ChangePasswordForm from "./Account/ChangePasswordForm";
import LanguageSettingsForm from "./Account/LanguageSettingsForm";
import CurrentPlanSection from "./Account/CurrentPlanSection";
import PaymentMethodSection from "./Account/PaymentMethodSection";
import PaymentHistorySection from "./Account/PaymentHistorySection";

import WithLoginRequiredPage from "./WithLoginRequiredPage";

import { Container } from "./BootstrapGrid";
import { spacers, typography } from "./theme";

import { pageHeaderHeight, fontSize } from "./geometry";
import { stringsForLocale } from "../lang/web";

import type { UserResourceStore } from "./WithUserResourceStore";
import type { SnackbarMessage } from "./useSnackbarQueue";
import type { RenderProps as SubscriptionProps } from "./WithPremiumSubscription";

import type { StripeInvoice } from "./api";
import { getInvoices } from "./apiClient";
import decodeIdToken from "./decodeIdToken";

type Props = {
  userResources: ?UserResourceStore,
  isInitialized: boolean,
  onLogin: () => void,
  onLogout: () => void,
  isLoggedIn: boolean,
  nativeLang: string,
  targetLang: ?string,
  onChangeNativeLang: (lang: string) => void,
  onChangeTargetLang: (lang: string) => void,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  idToken: string | null,
  onAddSnackbarMessage: SnackbarMessage => void,
  onNavigate: string => void
};

export default function AccountPage(props: Props) {
  const hasPassword =
    props.idToken != null &&
    decodeIdToken(props.idToken).authentication === "password";

  return (
    <WithLoginRequiredPage
      onLogin={props.onLogin}
      userResources={props.userResources}
      isInitialized={props.isInitialized}
      nativeLang={props.nativeLang}
      render={userResources => (
        <WithDerivedState
          controller={getInvoices}
          controllerProps={userResources.withIdToken}
          render={invoicePromise => (
            <AccountPageView
              onLogin={props.onLogin}
              onLogout={props.onLogout}
              nativeLang={props.nativeLang}
              targetLang={props.targetLang}
              onChangeNativeLang={props.onChangeNativeLang}
              onChangeTargetLang={props.onChangeTargetLang}
              youtubeLanguages={props.youtubeLanguages}
              hasPassword={hasPassword}
              userResources={userResources}
              onAddSnackbarMessage={props.onAddSnackbarMessage}
              subscriptionProps={userResources.premiumSubscription}
              invoices={invoicePromise}
              onNavigate={props.onNavigate}
            />
          )}
        />
      )}
    />
  );
}

type ViewProps = {
  onLogin: () => void,
  onLogout: () => void,
  nativeLang: string,
  targetLang: ?string,
  onChangeNativeLang: (lang: string) => void,
  onChangeTargetLang: (lang: string) => void,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  hasPassword: boolean,
  userResources: UserResourceStore,
  subscriptionProps: SubscriptionProps,
  onAddSnackbarMessage: SnackbarMessage => void,
  invoices: Promise<Array<StripeInvoice>>,
  onNavigate: string => void
};

export function AccountPageView(props: ViewProps) {
  const strings = stringsForLocale(props.nativeLang);

  return (
    <React.Fragment>
      <PageHeader
        onLogin={props.onLogin}
        onLogout={props.onLogout}
        nativeLang={props.nativeLang}
        targetLang={props.targetLang}
        isLoggedIn={true}
        enableMiniHeader={true}
        userResources={props.userResources}
      />
      <PageWrapper>
        <Container>
          <h1>{strings.account_page.page_header()}</h1>

          <Box>
            <LanguageSettingsForm
              nativeLang={props.nativeLang}
              targetLang={props.targetLang}
              onChangeNativeLang={props.onChangeNativeLang}
              onChangeTargetLang={props.onChangeTargetLang}
              youtubeLanguages={props.youtubeLanguages}
              userResources={props.userResources}
              onAddSnackbarMessage={props.onAddSnackbarMessage}
            />
          </Box>

          {props.hasPassword ? (
            <Box>
              <ChangePasswordForm
                nativeLang={props.nativeLang}
                userResources={props.userResources}
                onAddSnackbarMessage={props.onAddSnackbarMessage}
              />
            </Box>
          ) : null}

          <Box>
            <CurrentPlanSection
              nativeLang={props.nativeLang}
              subscriptionProps={props.subscriptionProps}
              onAddSnackbarMessage={props.onAddSnackbarMessage}
              onNavigate={props.onNavigate}
            />
          </Box>

          <Box>
            <PaymentMethodSection
              subscriptionProps={props.subscriptionProps}
              onAddSnackbarMessage={props.onAddSnackbarMessage}
              nativeLang={props.nativeLang}
            />
          </Box>

          <Box>
            <PaymentHistorySection
              invoices={props.invoices}
              nativeLang={props.nativeLang}
            />
          </Box>
        </Container>
      </PageWrapper>
    </React.Fragment>
  );
}

const Box = styled.div`
  padding: ${spacers[5]};
  margin-bottom: ${spacers[4]};
  background-color: white;
  border-radius: 20px;

  h2 {
    ${typography.h5}
    margin-top: 0;
    margin-bottom: ${spacers[5]};
  }
`;

const PageWrapper = styled.div`
  margin-top: ${pageHeaderHeight + fontSize}px;
`;
