/* @flow */

import * as React from "react";

import styled from "styled-components";

// See https://codepen.io/vkjgr/pen/gbPaVx
const LoadingDotsStyles = styled.div`
  width: 12px;
  animation: dots 1s steps(5, end) infinite;

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: ${props => props.color};
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.25em 0 0 ${props => props.color},
        0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.25em 0 0 ${props => props.color},
        0.5em 0 0 ${props => props.color};
    }
  }
`;

function LoadingDots(props: { color: string, className?: string }) {
  let className = "loading-dots";
  if (props.className != null) {
    className += " " + props.className;
  }

  return (
    <LoadingDotsStyles color={props.color} className={className}>
      {"  ."}
    </LoadingDotsStyles>
  );
}

export default LoadingDots;
