/* @flow */

import * as React from "react";
import { isEqual, omit } from "lodash";

import { apiFetch, postJSON, putJSON } from "./apiFetch";

import { captureException } from "@sentry/browser";

import type { UserResources } from "./api";
import WithAPIResource from "./WithAPIResource";

import type { LoginResources } from "./WithUserResourceStore";

type CollectionResource<PendingRecord, SavedRecord> = {
  records: Promise<Array<SavedRecord>>,
  onAddRecord: (record: PendingRecord) => Promise<Object>,
  onRemoveRecord: (id: number) => Promise<SavedRecord>,
  onUpdateRecord: (record: SavedRecord) => Promise<SavedRecord>,
  pendingRecords: Array<PendingRecord>
};

type Props<PendingRecord, SavedRecord> = {
  resourceName: string,
  initialState: UserResources => Array<SavedRecord>,
  loginResources: ?LoginResources,
  render: (?CollectionResource<PendingRecord, SavedRecord>) => React.Node
};

type State<PendingRecord, SavedRecord> = {
  records: Promise<Array<SavedRecord>>,
  pendingRecords: Array<PendingRecord>
};

function buildResource<PendingRecord: {}, SavedRecord: { id: number }>(
  resourceName: string,
  loginResources: LoginResources,
  state: State<PendingRecord, SavedRecord>,
  setState: (
    updater: (
      State<PendingRecord, SavedRecord>
    ) => $Shape<State<PendingRecord, SavedRecord>>
  ) => void
): CollectionResource<PendingRecord, SavedRecord> {
  function uriForRecord(id: number) {
    return `/api/${resourceName}/${id}`;
  }

  return {
    records: state.records,
    pendingRecords: state.pendingRecords,

    onAddRecord(record: PendingRecord): Promise<Object> {
      setState(state => ({
        pendingRecords: state.pendingRecords.concat(record)
      }));

      const uri = "/api/" + resourceName;

      const responsePromise = loginResources
        .withIdToken()
        .then(idToken => postJSON(idToken, uri, record));

      setState(
        recordsUpdater(responsePromise, (list, response) =>
          list.concat(response.record)
        )
      );

      // TODO: Is isEqual the best way to compare these? Maybe pendingRecords
      // should be some data structure that's not an array
      responsePromise.then(
        _response => {
          setState(state => ({
            pendingRecords: state.pendingRecords.filter(
              i => !isEqual(i, record)
            )
          }));
        },

        error => {
          captureException(error);
        }
      );

      return responsePromise;
    },

    onRemoveRecord(id: number): Promise<SavedRecord> {
      const uri = uriForRecord(id);

      const requestPromise = loginResources.withIdToken().then(idToken =>
        apiFetch(idToken, uri, {
          method: "DELETE"
        })
      );

      setState(
        recordsUpdater(requestPromise, (list, deleted) =>
          list.filter(item => item.id != deleted.id)
        )
      );

      requestPromise.catch(error => {
        captureException(error);
      });

      return requestPromise;
    },

    onUpdateRecord(record: SavedRecord): Promise<SavedRecord> {
      return loginResources.withIdToken().then(idToken => {
        // TODO: This doesn't feel like the right place to filter this.
        const body = omit(record, "id", "createdAt", "original");

        const uri = uriForRecord(record.id);
        const promise = putJSON(idToken, uri, body);

        promise.catch(error => {
          captureException(error);
        });

        setState(
          recordsUpdater(promise, (list, response) =>
            list.map(item => {
              if (item.id === response.id) {
                return response;
              } else {
                return item;
              }
            })
          )
        );

        return promise;
      });
    }
  };
}

type RecordsState<SavedRecord> = { records: Promise<Array<SavedRecord>> };
type RecordsStateUpdator<SavedRecord> = (
  RecordsState<SavedRecord>
) => RecordsState<SavedRecord>;

function recordsUpdater<SavedRecord>(
  promise: Promise<SavedRecord>,
  combinator: (Array<SavedRecord>, SavedRecord) => Array<SavedRecord>
): RecordsStateUpdator<SavedRecord> {
  return (state: RecordsState<SavedRecord>) => {
    const oldPromise = state.records || Promise.resolve([]);

    const combined = Promise.all([oldPromise, promise]).then(
      ([list, result]) => combinator(list, result),
      () => {
        return oldPromise;
      }
    );

    return { records: combined };
  };
}

export default function WithRestfulCollection<
  PendingRecord: {},
  SavedRecord: { id: number }
>(props: Props<PendingRecord, SavedRecord>) {
  function intializeState(loginResources) {
    const records = loginResources.userResources.then(props.initialState);
    return { pendingRecords: [], records };
  }

  return (
    <WithAPIResource
      initializeState={intializeState}
      buildResource={buildResource.bind(null, props.resourceName)}
      loginResources={props.loginResources}
      render={props.render}
    />
  );
}
