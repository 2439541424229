/* @flow */

import * as React from "react";
import { makeCancellable } from "./Cancellable";
import { captureException } from "@sentry/browser";

import type { RouteWithResources } from "./fetchRouteResources";
import type { DocumentLocation } from "./useNavigation";
import { titleForRoute } from "./headConfiguration";

export function useHeadTitle(
  resources: RouteWithResources,
  location: DocumentLocation,
  languageNames: Promise<YouTube$i18nLanguageListResponse>,
  nativeLang: string
) {
  const queryString = location.search;

  React.useEffect(() => {
    const cancellable = makeCancellable(
      titleForRoute(resources, queryString, languageNames, nativeLang)
    );

    cancellable.promise.then(
      result => {
        document.title = result;
      },
      error => {
        if (!error.isCancelled) {
          captureException(error);
        }
      }
    );

    return () => {
      cancellable.cancel();
    };
  }, [resources, queryString, nativeLang, languageNames]);
}
