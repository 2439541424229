/* @flow */

import * as React from "react";

import WithPromise from "./WithPromise";
import type AdminPage from "./AdminPage";

type Props = React.ElementProps<Class<AdminPage>>;

type State = {
  promise: Promise<{ default: Class<AdminPage> }>
};

export default class AdminPageLoader extends React.Component<Props, State> {
  constructor() {
    super();

    this.state = {
      promise: import("./AdminPage")
    };
  }
  render() {
    return (
      <WithPromise
        promise={this.state.promise}
        renderPending={() => <div>...</div>}
        renderRejected={error => (
          <div>
            <h2>Failed to load Admin bundle</h2>
            <p>{error.toString()}</p>
          </div>
        )}
        renderResolved={loaded => {
          const AdminPage = loaded.default;
          return <AdminPage {...this.props} />;
        }}
      />
    );
  }
}
