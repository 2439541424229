/* @flow */

const { parse, stringify } = require("querystring");
const { isEmpty, omit } = require("lodash");

const blogPostMetadata = require("./blogPostMetadata");

const { pathnameToRoute } = require("./router");
const { urlForSearch } = require("./urlForRoute");

// TODO: duplicated in parseSearchString.js
function parseSearchString(str) {
  // TODO: If a key appears twice in the search string, it will be parsed into an array.
  // This will probably break most of the clients of this function
  if (str && str.startsWith("?")) {
    return parse(str.substr(1));
  } else {
    return parse(str);
  }
}

/*::
import type { RouteWithResources } from "./fetchRouteResources";
import type { YouTubeVideo } from "./youtubeScraper";
import type { InitialLocation } from "./useNavigation";

type MetaProperties = {[string]: string | Array<string>};

*/

const { stringsForLocale, availableLocales } = require("../lang/web");
const { map } = require("lodash");

const notFoundTitle = "Video not found;";
const { thumbnailsForVideo } = require("./youtubeUtils");

function titleForRoute(
  resources /*: RouteWithResources */,
  search /*: string */,
  languageNamesPromise /*: Promise<YouTube$i18nLanguageListResponse>*/,
  nativeLang /*: string*/
) /*:Promise<string>*/ {
  if (resources.type === "youtube-video") {
    return resources.promise.then(
      item => titleFromVideoItem(item),
      error => {
        if (error.name === "YouTubeItemNotFound") {
          return notFoundTitle;
        } else {
          return Promise.reject(error);
        }
      }
    );
  } else {
    const strings = stringsForLocale(nativeLang);

    if (resources.route.name === "flash-cards-splash") {
      return Promise.resolve(strings.flash_cards.splash_page.title());
    } else if (resources.route.name === "premium-splash") {
      return Promise.resolve(strings.premium_splash_page.page_title());
    } else if (resources.route.name === "blog-post") {
      if (blogPostMetadata[resources.route.params.id]) {
        return Promise.resolve(
          blogPostMetadata[resources.route.params.id].title
        );
      } else {
        return Promise.resolve("Page not found");
      }
    } else if (resources.route.name === "search") {
      const query = parseSearchString(search);

      if ("q" in query) {
        return Promise.resolve(query["q"] + " - CaptionPop");
      } else if ("tl" in query) {
        return languageNamesPromise.then(function(languageNames) {
          const targetLangName = findLanguageName(query["tl"], languageNames);
          if (targetLangName == null) {
            return Promise.resolve("CaptionPop");
          } else {
            return Promise.resolve(
              strings.search_page.page_title({ TARGET_LANG: targetLangName }) +
                " - CaptionPop"
            );
          }
        });
      } else {
        return Promise.resolve("CaptionPop");
      }
    } else {
      return Promise.resolve("CaptionPop");
    }
  }
}

function canonicalLinkForLocation(
  origin /*: string */,
  location /*: InitialLocation */,
  nativeLang /*: string */
) {
  if (location.pathname === "/blogs/learn-korean-with-kpop-from-4minute") {
    return origin + location.pathname;
  } else {
    const route = pathnameToRoute(location.pathname);
    if (route != null && route.name === "search") {
      const params = parseSearchString(location.search);
      let targetLang;
      if (typeof route.params.targetLang === "string") {
        targetLang = route.params.targetLang;
      } else {
        targetLang = params.tl;
      }
      return origin + urlForSearch(params, nativeLang, targetLang);
    } else {
      return alternateLinkForLanguage(origin, location, nativeLang);
    }
  }
}

function alternateLinkForLanguage(
  origin /*: string */,
  location /*: InitialLocation */,
  lang /*: string */
) {
  const query = parseSearchString(location.search);

  const update = {
    ...query,
    nl: lang
  };

  return stringifyUrlWithQuery(origin, location, update);
}

function alternateLinksForLocationHelper(
  origin /*: string */,
  location /*: InitialLocation */,
  availableLocales /*: Array<string> */
) /*: Array<[string, string]> */ {
  const alternateLinks = availableLocales.map(lang => [
    lang,
    alternateLinkForLanguage(origin, location, lang)
  ]);
  alternateLinks.push(["x-default", defaultLinkForLanguage(origin, location)]);
  return alternateLinks;
}

function alternateLinksForLocation(
  origin /*: string */,
  location /*: InitialLocation */
) /*: Array<[string, string]> */ {
  if (location.pathname === "/blogs/learn-korean-with-kpop-from-4minute") {
    return alternateLinksForLocationHelper(origin, location, ["en"]);
  } else {
    return alternateLinksForLocationHelper(origin, location, availableLocales);
  }
}

function defaultLinkForLanguage(
  origin /*: string */,
  location /*: InitialLocation */
) {
  const query = parseSearchString(location.search);
  return stringifyUrlWithQuery(origin, location, omit(query, "nl"));
}

function stringifyUrlWithQuery(
  origin /*: string */,
  location /*: InitialLocation */,
  query /*: Object */
) {
  const str = origin + location.pathname;

  if (isEmpty(query)) {
    return str;
  } else {
    return str + "?" + stringify(query);
  }
}

function metaDescriptionForRoute(
  resources /*: RouteWithResources */,
  search /*: string */,
  languageNames /*: Promise<YouTube$i18nLanguageListResponse>*/,
  nativeLang /*: string*/
) /*:Promise<string>*/ {
  const strings = stringsForLocale(nativeLang);

  if (resources.type === "youtube-video") {
    return resources.promise.then(item => item.description);
  } else if (resources.route.name === "search") {
    const query = parseSearchString(search);

    if ("tl" in query) {
      return languageNames.then(languages => {
        const targetLang = query["tl"];
        const nativeLangName = findLanguageName(nativeLang, languages);
        const targetLangName = findLanguageName(targetLang, languages);

        if (targetLangName != null && nativeLangName != null) {
          return strings.search_page.meta_description({
            TARGET_LANG: targetLangName,
            NATIVE_LANG: nativeLangName
          });
        } else {
          return strings.search_page.meta_description_generic();
        }
      });
    } else {
      return Promise.resolve(strings.search_page.meta_description_generic());
    }
  } else if (resources.route.name === "blog-post") {
    if (blogPostMetadata[resources.route.params.id]) {
      return Promise.resolve(
        blogPostMetadata[resources.route.params.id].description
      );
    } else {
      return Promise.resolve("Page not found");
    }
  } else {
    let string;
    if (resources.route.name === "flash-cards-splash") {
      string =
        strings.flash_cards.splash_page.introduction() +
        " " +
        map(strings.flash_cards.splash_page.feature_list, str => str()).join(
          " "
        );
    } else if (resources.route.name === "premium-splash") {
      string =
        strings.premium_splash_page.header1() +
        " " +
        strings.premium_splash_page.header2() +
        " " +
        strings.premium_splash_page.header3();
    } else {
      string =
        strings.about_page.headline() +
        " " +
        map(strings.about_page.feature_list, str => str()).join(" ");
    }
    return Promise.resolve(string);
  }
}

// Helper function to parse the youtubeLanguages object
function findLanguageName(
  lang /*: string */,
  youtubeLanguages /*: YouTube$i18nLanguageListResponse*/
) /*: string | null*/ {
  const match = youtubeLanguages.items.find(item => item.id === lang);
  if (match) {
    return match.snippet.name;
  } else {
    return null;
  }
}

function metaPropertiesForRoute(
  resources /*: RouteWithResources */,
  origin /*: string */,
  location /*: InitialLocation */,
  languageNames /*: Promise<YouTube$i18nLanguageListResponse>*/,
  nativeLang /*: string*/
) /*:Promise<MetaProperties>*/ {
  const descriptionPromise = metaDescriptionForRoute(
    resources,
    location.search,
    languageNames,
    nativeLang
  );
  const titlePromise = titleForRoute(
    resources,
    location.search,
    languageNames,
    nativeLang
  );

  let promise;
  if (resources.type === "youtube-video") {
    promise = resources.promise.then(
      item => ({
        ...thumbnailTags(item),
        "og:title": titleFromVideoItem(item),
        "og:type": "video.other"
      }),
      error => {
        if (error.name === "YouTubeItemNotFound") {
          return { "og:type": notFoundTitle };
        } else {
          return Promise.reject(error);
        }
      }
    );
  } else {
    if (resources.route.name === "blog-post") {
      if (blogPostMetadata[resources.route.params.id]) {
        const meta = blogPostMetadata[resources.route.params.id];
        promise = Promise.resolve({
          "og:type": "article",
          "og:image": meta.image.url,
          "og:image:width": meta.image.width,
          "og:image:height": meta.image.height,
          "og:image:alt": meta.image.alt,
          "og:locale": meta.locale
        });
      } else {
        // Not found
        promise = Promise.resolve({});
      }
    } else {
      promise = Promise.resolve({
        "og:type": "website",
        "og:image": "https://www.captionpop.com/logo-square.png",
        "og:image:width": "300",
        "og:image:height": "300",
        "og:image:alt": "CaptionPop Logo",
        "og:locale": nativeLang,
        "og:locale:alternate": availableLocales
      });
    }
  }

  const robots = robotsForRoute(resources.route);

  return Promise.all([promise, descriptionPromise, titlePromise]).then(
    ([rest, description, title]) => ({
      ...rest,
      robots: robots,
      description: description,
      "og:site_name": "CaptionPop",
      "fb:app_id": process.env.FACEBOOK_OAUTH2_CLIENT_ID || "",
      "og:description": description,
      "og:title": title,
      "og:url": canonicalLinkForLocation(origin, location, nativeLang)
    })
  );
}

function robotsForRoute(route) {
  // Sometimes bot seem to be requesting the oauth2 callback. Hopefully this will convince them not to.
  if (route.name === "oauth2-redirect") {
    return "noindex, follow";
  } else {
    // This is just the default
    return "index, follow";
  }
}

function thumbnailTags(item) {
  const thumbnails = thumbnailsForVideo(item.videoId);

  const thumbnail = thumbnails.maxres || thumbnails.high || thumbnails.default;
  if (thumbnail) {
    return {
      "og:image": thumbnail.url,
      "og:image:width": String(thumbnail.width),
      "og:image:height": String(thumbnail.height)
    };
  }

  return {};
}

function titleFromVideoItem(item /*: YouTubeVideo */) {
  return item.title;
}

module.exports = {
  titleForRoute,
  alternateLinksForLocation,
  canonicalLinkForLocation,
  metaPropertiesForRoute
};
