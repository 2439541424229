/* @flow */

import * as React from "react";

import { putPaymentSource, putSubscriptionCancellation } from "./apiClient";

import { captureException } from "@sentry/browser";

type Props = {
  render: (RenderProps | null) => React.Node,
  loginResources: ?LoginResources
};

export type RenderProps = {
  subscription: Promise<PremiumSubscription>,
  paymentSource: Promise<?PaymentSource>,
  updateSubscription: (body: Object) => Promise<PremiumSubscription>,
  updatePaymentSource: StripeToken => Promise<PaymentSource>,
  onPurchase: PremiumSubscription => void
};

import type { LoginResources } from "./WithUserResourceStore";
import type { PremiumSubscription, PaymentSource } from "./api";

export default function WithPremiumSubscription(props: Props) {
  const renderProps = usePremiumSubscription(props.loginResources);
  return props.render(renderProps);
}

// This binds the callbacks in a state where loginResources is never null
function bindCallbacks(
  loginResources: LoginResources,
  setSubscription,
  setPaymentSource
) {
  function setStateFromPromise(promise: Promise<PremiumSubscription>) {
    const caughtPromise = promise.catch(error => {
      const eventId = captureException(error);
      error.eventId = eventId;
      return Promise.reject(error);
    });

    setSubscription(caughtPromise);
    setPaymentSource(caughtPromise.then(p => p.source));
  }

  return {
    onPurchase(response: PremiumSubscription) {
      setStateFromPromise(Promise.resolve(response));
    },

    updateSubscription(body: Object): Promise<PremiumSubscription> {
      const promise = putSubscriptionCancellation(
        loginResources.withIdToken,
        body
      );
      setStateFromPromise(promise);
      return promise;
    },

    updatePaymentSource(token: StripeToken): Promise<PaymentSource> {
      const promise = putPaymentSource(loginResources.withIdToken, token.id);
      setPaymentSource(promise);

      return promise;
    }
  };
}

function usePremiumSubscription(
  loginResources: ?LoginResources
): RenderProps | null {
  // TODO: These types are very confusing.
  const [
    subscription,
    setSubscription
  ] = React.useState<Promise<PremiumSubscription> | null>(null);
  const [
    paymentSource,
    setPaymentSource
  ] = React.useState<Promise<PaymentSource | null> | null>(null);

  React.useEffect(() => {
    if (loginResources) {
      const promise = loginResources.userResources.then(r => r.subscription);
      setSubscription(promise);
      setPaymentSource(promise.then(p => p.source));
    } else {
      setSubscription(null);
      setPaymentSource(null);
    }
  }, [loginResources]);

  const callbacks = React.useMemo(() => {
    if (loginResources) {
      return bindCallbacks(loginResources, setSubscription, setPaymentSource);
    } else {
      return null;
    }
  }, [loginResources]);

  if (subscription && paymentSource && callbacks) {
    return {
      subscription: subscription,
      paymentSource: paymentSource,
      updateSubscription: callbacks.updateSubscription,
      updatePaymentSource: callbacks.updatePaymentSource,
      onPurchase: callbacks.onPurchase
    };
  } else {
    return null;
  }
}
