/* @flow */

import * as React from "react";

type RenderProps = {
  location: DocumentLocation,
  onNavigate: (href: string, state: ?Object) => void,
  onReplaceLocation: (
    href: string,
    state: ?LinkState,
    currentLocation: ?string
  ) => void,
  onClick: KeyboardEvent => void
};

import createHistory from "history/createBrowserHistory";
import { decodeStateFromAnchor } from "./LinkWithState";
import { findWrappingLink } from "./domutils";

// It may be possible to store more complex state, but this is all we need.
export type LinkState = {
  [string]: string | number | boolean
};

// The object that the history npm is a subset of document.location
export type DocumentLocation = {
  pathname: string,
  search: string,
  hash: string,
  state: LinkState | null
};

// This is like DocumentLocation, except it doesn't include hash and state,
// which can't be used for server-side rendering.
export type InitialLocation = {
  pathname: string,
  search: string
};

export default function useNavigation(
  initialLocation: InitialLocation
): RenderProps {
  const [location, setLocation] = React.useState<DocumentLocation>({
    ...initialLocation,
    hash: "",
    state: null
  });

  const historyRef = React.useRef(null);

  React.useEffect(() => {
    const history = createHistory();
    historyRef.current = history;
    setLocation(history.location);

    // listen returns an unlisten function
    return history.listen(location => setLocation(location));
  }, []);

  // Since updates to the state are done asynchronously, it's possible that a call to onReplaceLocation
  // will happen on a pathname that has already changed. For this reason, currentPathname can optionally
  // be passed here. If the currentPath doesn't equal the actual current path, the operation is aborted.
  // This is necessary for things that perform updates to the pathname, like the nl/tl params, or removing
  // utc parameters.
  const onReplaceLocation = React.useCallback(
    (href: string, state: ?LinkState, currentPathname: ?string) => {
      if (historyRef.current != null) {
        if (
          currentPathname == null ||
          historyRef.current.location.pathname == currentPathname
        ) {
          historyRef.current.replace(href, state);
        }
      }
    },
    []
  );

  const onNavigate = React.useCallback((href: string, state: ?Object) => {
    const local = document.location.protocol + "//" + document.location.host;
    if (
      (href.startsWith("https://") || href.startsWith("http://")) &&
      !href.startsWith(local)
    ) {
      document.location.href = href;
    } else if (historyRef.current != null) {
      historyRef.current.push(href, state);
    }
  }, []);

  const onClick = React.useCallback(
    (event: KeyboardEvent) => {
      return onClickImpl(event, onNavigate);
    },
    [onNavigate]
  );

  return { location, onNavigate, onReplaceLocation, onClick };
}

function onClickImpl(event: KeyboardEvent, onNavigate: Function) {
  // If the user is using a modifier key, just let the browser do it's
  // default behavior. They are probably trying to open a new browser or tab.
  if (event.metaKey || event.ctrlKey || event.altKey) return;

  const node = event.target;

  if (node instanceof Node) {
    const clickable = findWrappingLink(node);

    if (clickable && clickable.host === document.location.host) {
      let href = clickable.getAttribute("href");
      if (
        href &&
        href != "#" &&
        !clickable.getAttribute("data-skip-navigation")
      ) {
        if (href.startsWith("#")) {
          href = location.pathname + location.search + href;
        }

        onNavigate(href, decodeStateFromAnchor(clickable));

        window.scrollTo(0, 0);

        event.preventDefault();
        event.stopPropagation();
      }
    }
  }
}
