import { init, captureException } from "@sentry/browser";
import sentryConfig from "./sentryConfig";

// Initialize Sentry
if (!document.location.origin.startsWith("http://localhost:")) {
  const scriptEl = document.getElementById("script-main");
  const release = scriptEl ? scriptEl.getAttribute("src") : null;

  init({ ...sentryConfig, release });
}

import "whatwg-fetch";

import ReactDOM from "react-dom";
import * as React from "react";

import App from "./App";

function main(element, initialLocation, serverLang, hydrate) {
  try {
    const app = (
      <App
        serverSideProps={element.dataset}
        initialLocation={initialLocation}
        serverLang={serverLang}
      />
    );

    if (hydrate) {
      ReactDOM.hydrate(app, element);
    } else {
      ReactDOM.render(app, element);
    }
  } catch (error) {
    captureException(error);
  }
}

window.main = main;
