/* @flow */

import * as React from "react";

export function Popcorn(props: Object) {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 90 90"
      enableBackground="new 0 0 90 90"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <path d="M39,88.7c-2.4,0-4.6-1.8-4.9-4.3l-7.3-50c-0.4-2.7,1.5-5.3,4.2-5.7c2.7-0.4,5.3,1.5,5.7,4.2l7.3,50   c0.4,2.7-1.5,5.3-4.2,5.7C39.5,88.6,39.2,88.7,39,88.7z" />
        <path d="M51.6,88.7c-0.2,0-0.5,0-0.7-0.1c-2.7-0.4-4.6-2.9-4.2-5.7l7.3-50c0.4-2.7,2.9-4.6,5.7-4.2c2.7,0.4,4.6,2.9,4.2,5.7   l-7.3,50C56.2,86.9,54,88.7,51.6,88.7z" />
        <path d="M17.5,37.7c-1.8,0-3.5-1-4.4-2.6c-1-1.9-1.6-4.1-1.6-6.3c0-5.9,3.8-10.9,9.1-12.7c1.8-5.3,6.8-9.1,12.7-9.1   c0.9,0,1.9,0.1,2.8,0.3c2.8-4.4,7.8-7.3,13.3-7.3c4.4,0,8.4,1.8,11.4,4.9C68,5.1,73.7,11,73.7,18.2c0,2.8-2.2,5-5,5s-5-2.2-5-5   c0-1.9-1.5-3.4-3.4-3.4c-0.2,0-0.5,0-0.7,0.1c-2,0.4-4.1-0.5-5.3-2.2c-1.1-1.7-2.9-2.7-4.9-2.7c-2.8,0-5.2,2-5.7,4.9   c-0.3,1.8-1.5,3.3-3.2,3.9c-1.7,0.6-3.6,0.3-5-0.9c-0.5-0.4-1.2-0.9-2.2-0.9c-1.9,0-3.4,1.5-3.4,3.4c0,2.8-2.2,5-5,5   c-1.9,0-3.4,1.5-3.4,3.4c0,0.6,0.1,1.1,0.4,1.6c1.3,2.4,0.4,5.5-2.1,6.8C19.1,37.5,18.3,37.7,17.5,37.7z" />
        <path d="M56.1,90.1H34.6c-4.3,0-11.9-1.8-14.8-13.7c0-0.1,0-0.2-0.1-0.3l-7.5-42.5c-0.3-1.5,0.1-2.9,1.1-4.1c1-1.1,2.4-1.8,3.8-1.8   h56.3c1.5,0,2.9,0.7,3.8,1.8c1,1.1,1.4,2.6,1.1,4.1L70.9,76c0,0.2-0.1,0.4-0.1,0.5C67.4,88.3,60.2,90.1,56.1,90.1z M29.6,74.1   c1.4,5.9,3.9,5.9,5,5.9h21.6c0.8,0,3.2,0,5-6l6.4-36.4H23.1L29.6,74.1z" />
      </g>
    </svg>
  );
}

export function Television(props: Object) {
  return (
    <svg viewBox="0 0 640 512" {...props}>
      <path d="M592 0H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h245.1v32h-160c-17.7 0-32 14.3-32 32s14.3 32 32 32h384c17.7 0 32-14.3 32-32s-14.3-32-32-32h-160v-32H592c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-16 352H64V64h512v288z" />
    </svg>
  );
}

export function Filter(props: Object) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z" />
    </svg>
  );
}

export function PlayTriangle(props: { color: string, width: number }) {
  return (
    <svg viewBox="0 0 80 100" width={props.width}>
      <polygon
        stroke={props.color}
        fill={props.color}
        points="0,0 0,100 80,50"
      />
    </svg>
  );
}

declare class SVGAnimateTransformElement extends Element {
  beginElement(): void;
}

type ThreeDotsProps = {
  color: string,
  size: number,
  orientation: "horizontal" | "vertical"
};

// TODO: It might be cool to animate the transitions between horizontal and vertical. I tried doing this
// with SVG animations but couldn't get it 100%. You might have better luck with CSS transitions.
// Check out commit 0d23177d
export class ThreeDots extends React.Component<ThreeDotsProps> {
  render() {
    const transform =
      this.props.orientation === "vertical"
        ? "rotate(90 50 50)"
        : "rotate(0 50 50)";
    return (
      <svg
        viewBox="0 0 100 100"
        width={this.props.size}
        height={this.props.size}
        fill={this.props.color}
      >
        <g transform={transform}>
          <circle cx="15" cy="50" r="10" />
          <circle cx="50" cy="50" r="10" />
          <circle cx="85" cy="50" r="10" />
        </g>
      </svg>
    );
  }
}

export function Star(props: { on: boolean, pending: boolean, width: number }) {
  return (
    <svg viewBox="0 0 576 512" width={props.width} height={props.width}>
      {props.on ? (
        <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" />
      ) : (
        <path d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z">
          {props.pending ? (
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 288 256"
              to="360 288 256"
              dur="4s"
              repeatCount="indefinite"
            />
          ) : null}
        </path>
      )}
    </svg>
  );
}

export function Pencil(props: {
  color: string,
  size: number,
  className: string
}) {
  return (
    <svg
      viewBox="0 0 13 13"
      width={props.size}
      height={props.size}
      className={props.className}
    >
      <path
        d="M11.599 1.997l-.702.7-.742-.743.7-.702c.297-.103.595-.076.69.022.163.162.255.523.054.723zM9.332 1.23l-.737.75-8.477 8.598c-.088.09-.145 1.786-.105 2.289.74-.015 1.453-.061 2.2-.075l8.659-8.537.749-.737.89-.878c.17-.167.355-1.061.328-1.293-.059-.397-.285-.69-.48-.886-.457-.456-1.315-.704-2.148-.121l-.879.89zm.72 2.306L1.75 11.8c-.239-.003-.479-.007-.687.02-.003-.239-.01-.718.048-.776L9.315 2.8l.737.737z"
        fill={props.color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function Close(props: {
  color: string,
  size: number,
  className: string
}) {
  return (
    <svg
      viewBox="0 0 20 20"
      width={props.size}
      height={props.size}
      className={props.className}
    >
      <line x1="0" y1="0" x2="20" y2="20" stroke={props.color} />
      <line x1="20" y1="0" x2="0" y2="20" stroke={props.color} />
    </svg>
  );
}

export function PlayFilm(props: {
  color: string,
  size: number,
  className: string
}) {
  return (
    <svg
      viewBox="0 0 91 91"
      width={props.size}
      height={props.size}
      className={props.className}
    >
      <path d="M59.152,43.576L39.348,32.14c-0.989-0.573-2.265-0.574-3.269,0.002c-1.003,0.582-1.627,1.666-1.627,2.826v22.867   c0,1.164,0.626,2.245,1.627,2.821c0.493,0.288,1.059,0.44,1.636,0.44c0.578,0,1.147-0.152,1.632-0.438l19.808-11.433   c1.004-0.582,1.63-1.666,1.63-2.825C60.786,45.235,60.16,44.152,59.152,43.576z M40.98,52.179V40.621l10.01,5.78L40.98,52.179z" />
      <path d="M3.531,83.917h83.944c1.797,0,3.26-1.465,3.26-3.264V12.146c0-1.798-1.463-3.263-3.26-3.263H3.531   c-1.801,0-3.264,1.465-3.264,3.263v68.507C0.267,82.452,1.729,83.917,3.531,83.917z M33.671,77.389H23.064v-7.794h10.607V77.389z    M50.804,77.389H40.197v-7.794h10.607V77.389z M67.941,77.389H57.334v-7.794h10.607V77.389z M74.469,15.413h9.739v7.792h-9.739   V15.413z M57.334,15.413h10.607v7.792H57.334V15.413z M40.197,15.413h10.607v7.792H40.197V15.413z M23.064,15.413h10.607v7.792   H23.064V15.413z M6.794,15.413h9.744v7.792H13.78c-1.797,0-3.264,1.465-3.264,3.264c0,1.798,1.467,3.263,3.264,3.263h70.428v47.657   h-9.739v-7.794h3.948c1.801,0,3.266-1.464,3.266-3.266c0-1.797-1.465-3.262-3.266-3.262H6.794V15.413z M6.794,69.595h9.744v7.794   H6.794V69.595z" />
    </svg>
  );
}

export function Clock(props: Object) {
  return (
    <svg width="1792" height="1792" viewBox="0 0 1792 1792" {...props}>
      <path d="M1024 544v448q0 14-9 23t-23 9h-320q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h224v-352q0-14 9-23t23-9h64q14 0 23 9t9 23zm416 352q0-148-73-273t-198-198-273-73-273 73-198 198-73 273 73 273 198 198 273 73 273-73 198-198 73-273zm224 0q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z" />
    </svg>
  );
}

type ArrowDirection = "up" | "down" | "left" | "right";

export function Arrow(props: {
  direction: ArrowDirection,
  color: string,
  style: Object
}) {
  let width: number;
  let height: number;
  switch (props.direction) {
    case "up":
    case "down":
      width = 20;
      height = 10;
      break;
    case "left":
    case "right":
      width = 10;
      height = 20;
      break;
    default:
      width = 0; // For the sake of flow
      height = 0;
  }

  const points = {
    up: "0,10 10,0 20,10",
    down: "0,0 10,10 20,0",
    right: "0,0 0,20 10,10",
    left: "10,0 10,20 0,10"
  };

  const borderColor = "#ccc";

  return (
    <svg
      style={props.style}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <polygon fill={props.color} points={points[props.direction]} />
      {props.direction === "down" ? (
        <React.Fragment>
          {/*
            This is meant to simulate a drop shadow, which is why it is
            only applied in one direction
          */}
          <line x1="0" y1="0" x2="10" y2="10" stroke={borderColor} />
          <line x1="10" y1="10" x2="20" y2="0" stroke={borderColor} />
        </React.Fragment>
      ) : null}
    </svg>
  );
}

export function Subtitles(props: { size: number, className?: string }) {
  return (
    <svg
      width={props.size}
      height={props.size}
      className={props.className}
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
    >
      <path d="M51.6,64.6l-6.5,17.9c-0.9,2.5-3.6,3.8-6.1,2.9l0,0c-2.5-0.9-3.8-3.6-2.9-6.1l6.5-17.9  c0.9-2.5,3.6-3.8,6.1-2.9l0,0C51.2,59.3,52.5,62.1,51.6,64.6z" />
      <path d="M94.6,23.9H62.2c-1.6,0-2.9-1.3-2.9-2.9v-3.8c0-1.6,1.3-2.9,2.9-2.9h32.4c1.6,0,2.9,1.3,2.9,2.9V21  C97.5,22.6,96.2,23.9,94.6,23.9z" />
      <path d="M94.6,38.5H43.1c-1.6,0-2.9-1.3-2.9-2.9v-3.8c0-1.6,1.3-2.9,2.9-2.9h51.5c1.6,0,2.9,1.3,2.9,2.9v3.8  C97.5,37.2,96.2,38.5,94.6,38.5z" />
      <path d="M52.1,23.9H5.4c-1.6,0-2.9-1.3-2.9-2.9v-3.8c0-1.6,1.3-2.9,2.9-2.9h46.8c1.6,0,2.9,1.3,2.9,2.9V21  C55,22.6,53.7,23.9,52.1,23.9z" />
      <path d="M33.5,67.7H5.4c-1.6,0-2.9-1.3-2.9-2.9V61c0-1.6,1.3-2.9,2.9-2.9h28.2c1.6,0,2.9,1.3,2.9,2.9v3.8  C36.4,66.4,35.1,67.7,33.5,67.7z" />
      <path d="M9.2,67.7H5.4c-1.6,0-2.9-1.3-2.9-2.9V17.2c0-1.6,1.3-2.9,2.9-2.9h3.8c1.6,0,2.9,1.3,2.9,2.9v47.6  C12,66.4,10.8,67.7,9.2,67.7z" />
      <path d="M94.6,67.7H62.2c-1.6,0-2.9-1.3-2.9-2.9V61c0-1.6,1.3-2.9,2.9-2.9h32.4c1.6,0,2.9,1.3,2.9,2.9v3.8  C97.5,66.4,96.2,67.7,94.6,67.7z" />
      <path d="M36.1,82.5l-6.5-17.9c-0.9-2.5,0.4-5.2,2.9-6.1l0,0c2.5-0.9,5.2,0.4,6.1,2.9l6.5,17.9  c0.9,2.5-0.4,5.2-2.9,6.1h0C39.8,86.3,37,85,36.1,82.5z" />
      <path d="M52.6,67.7h-5.9c-1.6,0-2.9-1.3-2.9-2.9V61c0-1.6,1.3-2.9,2.9-2.9h5.9c1.6,0,2.9,1.3,2.9,2.9v3.8  C55.5,66.4,54.2,67.7,52.6,67.7z" />
      <path d="M94.6,53.1H43.1c-1.6,0-2.9-1.3-2.9-2.9v-3.8c0-1.6,1.3-2.9,2.9-2.9h51.5c1.6,0,2.9,1.3,2.9,2.9v3.8  C97.5,51.8,96.2,53.1,94.6,53.1z" />
    </svg>
  );
}

export function SwapArrows(props: { size: number, className?: string }) {
  return (
    <svg
      x="0px"
      y="0px"
      width={props.size}
      height={props.size}
      className={props.className}
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
    >
      <path d="M75.43,33.408c-0.014-0.065-0.036-0.125-0.058-0.187c-0.011-0.031-0.017-0.063-0.028-0.093  c-0.029-0.069-0.066-0.133-0.105-0.196c-0.012-0.02-0.02-0.042-0.033-0.062c-0.055-0.082-0.117-0.158-0.187-0.228L62.527,20.151  c-0.586-0.586-1.535-0.586-2.121,0c-0.586,0.585-0.586,1.536,0,2.121l9.932,9.932H26.625c-0.828,0-1.5,0.671-1.5,1.5  s0.672,1.5,1.5,1.5h43.713l-9.934,9.933c-0.586,0.585-0.586,1.535,0,2.121c0.293,0.293,0.677,0.439,1.061,0.439  s0.768-0.146,1.061-0.439l12.493-12.492c0.069-0.069,0.132-0.146,0.187-0.228c0.014-0.02,0.021-0.042,0.033-0.062  c0.039-0.063,0.076-0.127,0.105-0.196c0.012-0.03,0.018-0.063,0.028-0.093c0.021-0.062,0.044-0.122,0.058-0.187  c0.02-0.097,0.029-0.196,0.029-0.296S75.449,33.504,75.43,33.408z M73.375,64.796H29.662l9.934-9.933  c0.586-0.585,0.586-1.535,0-2.121s-1.535-0.586-2.121,0L24.981,65.234c-0.069,0.069-0.132,0.146-0.187,0.228  c-0.014,0.021-0.023,0.045-0.036,0.066c-0.037,0.062-0.074,0.124-0.103,0.191c-0.013,0.031-0.019,0.064-0.029,0.097  c-0.021,0.06-0.043,0.12-0.056,0.183c-0.02,0.097-0.03,0.196-0.03,0.297s0.011,0.2,0.03,0.297c0.013,0.063,0.035,0.123,0.056,0.183  c0.011,0.032,0.017,0.065,0.029,0.097c0.028,0.067,0.065,0.129,0.103,0.191c0.013,0.021,0.022,0.045,0.036,0.066  c0.055,0.082,0.117,0.158,0.187,0.228l12.491,12.491c0.293,0.293,0.677,0.439,1.061,0.439s0.768-0.146,1.061-0.439  c0.586-0.585,0.586-1.536,0-2.121l-9.932-9.932h43.713c0.828,0,1.5-0.671,1.5-1.5S74.203,64.796,73.375,64.796z" />
    </svg>
  );
}

export function Hamburger(props: {}) {
  const lineHeight = 10;
  const lineProps = {
    x: 0,
    rx: 5,
    ry: 5,
    width: "100%",
    height: lineHeight
  };

  const size = 100;
  const padding = 15;

  const firstY = padding;
  const lastY = size - padding - lineHeight;
  const middleY = firstY + (lastY - firstY) / 2;

  return (
    <svg
      x="0px"
      y="0px"
      viewBox={`0 0 ${size} ${size}`}
      width="25px"
      height="25px"
    >
      <rect y={firstY} {...lineProps} />
      <rect y={middleY} {...lineProps} />
      <rect y={lastY} {...lineProps} />
    </svg>
  );
}
