/* @flow */

import * as React from "react";

import WithPromise, { WithMostRecentPromise } from "./WithPromise";
import LoadingTextPlaceholder from "./LoadingTextPlaceholder";

import * as geometry from "./geometry";
import { colors } from "./theme";
import styled from "styled-components";

import ChannelLink from "./ChannelLink";
import { Television, Hamburger } from "./SvgAssets";

import type { YouTubeVideo } from "./youtubeScraper";

import LibraryToggleButton, {
  toggleButtonState,
  compactedMediaQuery
} from "./LibraryToggleButton";

import { domID } from "./PageHeader";

import type { LibraryItem, UnsavedLibraryItem } from "./libraryItems";

type Props = {
  onLogin: () => void,
  onLogout: () => void,
  nativeLang: string,
  videoPromise: Promise<YouTubeVideo>,
  targetLang: ?string,
  videoId: string,
  pendingLibraryItems: Array<UnsavedLibraryItem>,
  onAddToVideoLibrary: () => void,
  onRemoveFromVideoLibrary: () => void,
  libraryItems: ?Promise<Array<LibraryItem>>,
  onSwapLanguages: () => void
};

export default function PlayVideoPageHeader(props: Props) {
  return (
    <Wrapper id={domID}>
      <LeftContent>
        <a href="#navigation" className="hamburger-link">
          <Hamburger />
        </a>
      </LeftContent>

      <CenteredContent>
        <WithPromise
          promise={props.videoPromise}
          renderRejected={() => null}
          renderPending={() => (
            <React.Fragment>
              <TitleLine>
                <LoadingTextPlaceholder width={250} />
              </TitleLine>
              <TitleLine>
                <Television width="15px" />
                <LoadingTextPlaceholder width={25} />
              </TitleLine>
            </React.Fragment>
          )}
          renderResolved={item => (
            <React.Fragment>
              <TitleLine>{item.title}</TitleLine>
              <TitleLine>
                <Television width="15px" />
                <ChannelLink
                  title={item.channel.title}
                  href={item.channel.href}
                  nativeLang={props.nativeLang}
                  targetLang={props.targetLang}
                />
              </TitleLine>
            </React.Fragment>
          )}
        />
      </CenteredContent>
      <RightContent>
        <WithMostRecentPromise
          promise={props.libraryItems || promiseToEmptyList}
          renderRejected={() => null}
          renderPending={() => (
            <div>
              <LibraryToggleButton
                buttonState={"pending"}
                onRemoveFromVideoLibrary={props.onRemoveFromVideoLibrary}
                onAddToVideoLibrary={props.onAddToVideoLibrary}
                nativeLang={props.nativeLang}
              />
            </div>
          )}
          renderResolved={libraryItems => (
            <div>
              {/* This div exists to be vertically centered. If we vertically center the button
              directly, then the flex layout will stretch the width.
              */}
              <LibraryToggleButton
                buttonState={toggleButtonState(
                  props.videoId,
                  "video",
                  libraryItems,
                  props.pendingLibraryItems
                )}
                onRemoveFromVideoLibrary={props.onRemoveFromVideoLibrary}
                onAddToVideoLibrary={props.onAddToVideoLibrary}
                nativeLang={props.nativeLang}
              />
            </div>
          )}
        />
      </RightContent>
    </Wrapper>
  );
}

const promiseToEmptyList = Promise.resolve([]);

const Wrapper = styled.div`
  height: ${geometry.pageHeaderHeight}px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  box-shadow: 1px 1px 2px #ccc;
  display: flex;
`;

const EdgeContent = styled.div`
  flex-shrink: 0;
  flex-grow: 1;

  flex-basis: 0;

  min-width: 125px;

  @media ${compactedMediaQuery} {
    min-width: 45px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LeftContent = styled(EdgeContent)`
  text-align: left;
  svg {
    fill: ${colors.dark};
  }
`;

const RightContent = styled(EdgeContent)`
  text-align: right;
`;

const CenteredContent = styled.div`
  flex: 10;
  overflow: hidden;
`;

const Line = styled.div`
  height: 30px;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleLine = styled(Line)`
  //max-width: calc(100vw - 100px); // Gives room to the hamburger and dots
  margin: 0 auto;
  text-align: center;
  svg {
    fill: rgb(153, 153, 153);
    margin-right: 10px;
  }
`;
