/* @flow */

import { getJSON } from "./apiFetch";

import { uniq } from "lodash";

import type {
  YouTubeSearch,
  YouTubeSearchWithDetails,
  YouTubeSnippets2,
  YouTubeVideoExt,
  SearchParams,
  YouTubeVideos
} from "./youtubeScraper";

export function getChannelSnippets(
  ids: Array<string>,
  cache: boolean = false
): Promise<YouTubeSnippets2> {
  const params = {};
  params.ids = ids.join(",");
  if (cache) {
    params.cache = "1";
  }
  return getJSON(null, "/api/youtube/channel-snippets-2", params);
}

export function getPlaylistSnippets(
  ids: Array<string>,
  cache: boolean = false
): Promise<YouTubeSnippets2> {
  const params = {};
  params.ids = ids.join(",");
  if (cache) {
    params.cache = "1";
  }

  return getJSON(null, "/api/youtube/playlist-snippets-2", params);
}

/*
// Deprecating these. Use search endpoint instead
export function getChannel(id: string): Promise<YouTubeChannel> {
  return getJSON(null, "/api/youtube/channels/" + id);
}

export function getUsername(username: string): Promise<YouTubeChannel> {
  return getJSON(null, "/api/youtube/usernames/" + username);
}

export function getPlaylist(id: string): Promise<YouTubePlaylist> {
  return getJSON(null, "/api/youtube/playlists/" + id);
}
*/

export function getLanguages(
  hl: string
): Promise<YouTube$i18nLanguageListResponse> {
  return getJSON(null, "/api/youtube/languages", { hl });
}

export function getSearchWithDetails(
  params: SearchParams,
  cache: boolean = false
): Promise<YouTubeSearchWithDetails> {
  const cacheParams = {};
  if (cache) {
    cacheParams.cache = "1";
  }

  return getJSON(null, "/api/youtube/search-with-details", {
    ...params,
    ...cacheParams
  });
}

export function getSearch(params: SearchParams): Promise<YouTubeSearch> {
  return getJSON(null, "/api/youtube/search", params);
}

export function getVideo(videoId: string): Promise<YouTubeVideoExt> {
  return getJSON(null, "/api/youtube/videos/" + videoId);
}

export function getVideos(ids: Array<string>): Promise<YouTubeVideos> {
  return getJSON(null, "/api/youtube/videos-2", { ids: ids.join(",") });
}

export function getInitialPlayerResponse(videoId: string): Promise<Object> {
  return getJSON(
    null,
    "/api/youtube/videos/" + videoId + "/initial-player-response"
  );
}

export function getVideoTitles(
  ids: Array<string>
): Promise<{ [string]: string }> {
  const uniqIds = uniq(ids);
  return getVideos(uniqIds).then(response => {
    const result = {};
    uniqIds.forEach(id => {
      if (response[id] != null) {
        result[id] = response[id].title;
      }
    });
    return result;
  });
}
