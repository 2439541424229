/* @flow */

import * as React from "react";

import { last, flatten, isEqual } from "lodash";
import { captureException } from "@sentry/browser";

import { getSearch, getSearchWithDetails } from "./YoutubeScraperClient";
import type {
  YouTubeVideo,
  YouTubeSearch,
  YouTubeSearchWithDetails,
  SearchParams
} from "./youtubeScraper";

import parseSearchString from "./parseSearchString";

import type { DocumentLocation } from "./useNavigation";
import { pathnameToRoute } from "./router";

import WithPromise from "./WithPromise";
import WithTimedTextResponses from "./WithTimedTextResponses";
import type { TimedTextResponses } from "./WithTimedTextResponses";
import type { UnsavedLibraryItem } from "./libraryItems";

export type SearchDescription =
  | {
      resource: "search",
      params: {|
        q: string,
        cityId: string
      |}
    }
  | {
      resource: "playlist",
      params: {| playlistId: string |}
    }
  | {
      resource: "channel",
      params: {| channelId: string |}
    }
  | {
      resource: "username",
      params: {| username: string |}
    };

export type SearchProps = {
  searchDescription: ?SearchDescription,
  searchTitle: Promise<string> | null,
  libraryItem: Promise<UnsavedLibraryItem> | null,
  searchListResponses: Promise<Array<SearchResult>>,
  timedTextResponses: TimedTextResponses,
  onFetchMore: Function
};

type Props = {
  location: DocumentLocation,
  render: SearchProps => React.Node,
  nativeLang: string,
  targetLang: ?string
};

type State = {
  searchListResponses: Promise<Array<SearchResult>>,
  searchTitle: Promise<string> | null,
  libraryItem: Promise<UnsavedLibraryItem> | null,
  mostRecentSearch: ?string
};

export type SearchResult = {
  items: Array<YouTubeVideo>,
  params: SearchParams,
  nextPageToken?: string,
  pageInfo: YouTube$PageInfo
};

export default class WithSearchResults extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchListResponses: Promise.resolve([]),
      mostRecentSearch: null,
      searchTitle: null,
      libraryItem: null
    };
  }

  componentDidMount() {
    this.checkSearchProps();
  }

  componentDidUpdate() {
    this.checkSearchProps();
  }

  checkSearchProps() {
    const route = pathnameToRoute(this.props.location.pathname);
    if (
      route != null &&
      route.name === "search" &&
      !compareSearchStrings(
        this.state.mostRecentSearch,
        this.props.location.search
      )
    ) {
      this.startSearch();
    }
  }

  startSearch() {
    this.setState({
      mostRecentSearch: this.props.location.search
    });

    this.executeSearch(null);
  }

  onFetchMore() {
    this.state.searchListResponses.then(searchListResponses => {
      this.executeSearch(searchListResponses);
    });
  }

  executeSearch(previousResults: ?Array<SearchResult>) {
    const searchDescription = searchDescriptionForLocation(
      this.props.location.search
    );
    if (!searchDescription) return;

    let lastResponse = null;
    if (previousResults) {
      lastResponse = last(previousResults);
    }

    let newItems;

    // Extend a previous search
    if (lastResponse && lastResponse.nextPageToken) {
      const params = {
        ...lastResponse.params,
        pageToken: lastResponse.nextPageToken
      };

      newItems = getSearch(params).then(convertYouTubeSearch);
    } else {
      // Start a new search

      let searchTitle = null;
      let libraryItem = null;
      let request;

      switch (searchDescription.resource) {
        case "search": {
          const searchParams = {
            searchType: "query",
            searchQuery: searchDescription.params.q,
            cityId: searchDescription.params.cityId,
            relevanceLanguage: this.props.targetLang || null,
            pageToken: null,
            maxResults: null
          };

          request = getSearchWithDetails(searchParams);
          searchTitle = Promise.resolve(searchDescription.params.q);
          newItems = request.then(res => convertYouTubeSearch(res.search));

          break;
        }
        case "channel": {
          const searchParams = {
            searchType: "channelItems",
            searchQuery: searchDescription.params.channelId,
            pageToken: null,
            maxResults: null
          };

          request = getSearchWithDetails(searchParams);
          newItems = request.then(res => convertYouTubeSearch(res.search));
          searchTitle = request.then(titleFromResponse);
          libraryItem = Promise.resolve({
            mediaType: "channel",
            mediaId: searchDescription.params.channelId,
            nativeLang: this.props.nativeLang,
            targetLang: this.props.targetLang
          });
          break;
        }
        case "username": {
          const searchParams = {
            searchType: "username",
            searchQuery: searchDescription.params.username,
            cityId: null,
            relevanceLanguage: null,
            pageToken: null,
            maxResults: null
          };

          request = getSearchWithDetails(searchParams);
          newItems = request.then(res => convertYouTubeSearch(res.search));
          searchTitle = request.then(titleFromResponse);
          libraryItem = request.then(response =>
            this.libraryItemFromSearch(response)
          );

          break;
        }
        case "playlist": {
          const searchParams = {
            searchType: "playlistItems",
            searchQuery: searchDescription.params.playlistId,
            pageToken: null,
            maxResults: null
          };

          request = getSearchWithDetails(searchParams);
          newItems = request.then(res => convertYouTubeSearch(res.search));
          searchTitle = request.then(titleFromResponse);
          libraryItem = request.then(response =>
            this.libraryItemFromSearch(response)
          );

          break;
        }
        default:
          // This will force a type error if this code path ever becomes reachable
          (searchDescription.resource: empty);
          throw new Error("unknown resource");
      }

      this.setState({
        searchTitle,
        libraryItem
      });
    }

    // Chain the item promises together
    const newPromise = newItems.then(newResult => {
      if (previousResults) return previousResults.concat(newResult);
      else return [newResult];
    });

    this.setState({ searchListResponses: newPromise });

    newItems.catch(error => {
      captureException(error);
    });
  }

  libraryItemFromSearch(search: YouTubeSearchWithDetails): UnsavedLibraryItem {
    switch (search.searchType) {
      case "query":
        throw new Error("Search queries can't be added to the library");
      case "playlistItems":
        return {
          mediaType: "playlist",
          mediaId: search.details.id,
          nativeLang: this.props.nativeLang,
          targetLang: this.props.targetLang
        };
      case "channelItems":
        return {
          mediaType: "channel",
          mediaId: search.details.id,
          nativeLang: this.props.nativeLang,
          targetLang: this.props.targetLang
        };
      default:
        // This will force a type error if this code path ever becomes reachable
        (search.searchType: empty);
        throw new Error("unknown resource");
    }
  }

  render() {
    const InnerRender = (videoIds: Array<string>) => (
      <WithTimedTextResponses
        videoIds={videoIds}
        render={timedTextResponses => {
          return this.props.render({
            searchDescription: searchDescriptionForLocation(
              this.props.location.search
            ),
            searchTitle: this.state.searchTitle,
            libraryItem: this.state.libraryItem,
            searchListResponses: this.state.searchListResponses,
            onFetchMore: this.onFetchMore.bind(this),
            timedTextResponses: timedTextResponses
          });
        }}
      />
    );

    return (
      <WithPromise
        promise={this.state.searchListResponses}
        renderRejected={() => InnerRender([])}
        renderPending={lastResponse =>
          lastResponse
            ? InnerRender(videoIdsFromSearchResults(lastResponse))
            : InnerRender([])
        }
        renderResolved={responses =>
          InnerRender(videoIdsFromSearchResults(responses))
        }
      />
    );
  }
}

function titleFromResponse(response: YouTubeSearchWithDetails): string {
  if (response.details == null) {
    return "";
  } else {
    return response.details.snippet.title;
  }
}

function videoIdsFromSearchResults(
  results: Array<SearchResult>
): Array<string> {
  return flatten(results.map(r => r.items.map(i => i.videoId)));
}

function searchDescriptionForLocation(search: string): ?SearchDescription {
  const params = parseSearchString(search);

  if (params.q) {
    return {
      resource: "search",
      params: { q: params.q, cityId: params.cityId }
    };
  } else if (params.username) {
    return {
      resource: "username",
      params: { username: params.username }
    };
  } else if (params.channelId) {
    return {
      resource: "channel",
      params: { channelId: params.channelId }
    };
  } else if (params.playlistId) {
    return {
      resource: "playlist",
      params: { playlistId: params.playlistId }
    };
  } else {
    return null;
  }
}

// TODO: Is it still necessary to have both of these types? They seem to do the same thing.
function convertYouTubeSearch(search: YouTubeSearch): SearchResult {
  const pageInfo: YouTube$PageInfo = {
    totalResults: search.items.length,
    resultsPerPage: search.items.length
  };

  return {
    items: search.items,
    pageInfo,
    params: search.params,
    nextPageToken: search.nextPageToken || ""
  };
}

function compareSearchStrings(
  lastSearch: ?string,
  thisSearch: string
): boolean {
  if (lastSearch == null) return false;

  return isEqual(
    searchDescriptionForLocation(lastSearch),
    searchDescriptionForLocation(thisSearch)
  );
}
