/* @flow */

import * as React from "react";
import { map, groupBy, sortBy, omit } from "lodash";
import styled from "styled-components";

import { getVideoTitles } from "./YoutubeScraperClient";

import PageHeader from "./PageHeader";
import { WithMostRecentPromise } from "./WithPromise";
import { postJSON } from "./apiFetch";

import isAdmin from "./isAdmin";
import decodeIdToken from "./decodeIdToken";
import useLocalStorage from "./useLocalStorage";

import { widthCss } from "./playerGeometry";
import KeyboardShortcuts from "./KeyboardShortcuts";
import { FixedPlayerContainer } from "./PlayerContainer";
import WithPlayerController from "./WithPlayerController";
import WithDerivedState from "./WithDerivedState";
import WithDocumentEvents from "./WithDocumentEvents";
import WithLoginRequiredPage from "./WithLoginRequiredPage";
import { encodeStateForAnchor } from "./LinkWithState";
import PositioningWrapper from "./PositioningWrapper";
import PlayerSpacer from "./PlayerSpacer";

import useAutoScroller from "./useAutoScroller";
import useDynamicScrollPosition from "./useDynamicScrollPosition";

import type { UserResourceStore } from "./WithUserResourceStore";

import { urlForVideo } from "./urlForRoute";

import TranslationPopup from "./TranslationPopup";
import { useTranslationPopup } from "./useTranslationPopup";

import { ThreeDots, Pencil, Close, PlayFilm } from "./SvgAssets";
import PopupMenuTrigger from "./PopupMenuTrigger";

import type { Favorite } from "./api";
import type { PlayerAction } from "./WithPlayerController";

import focusHack from "./focusHack";

import { stringsForLocale } from "../lang/web";

import playerActionForKeyboardEvent from "./playerActionForKeyboardEvent";

import SubtitleScroller, {
  SubtitleGroupHeader,
  SubtitleListItem
} from "./SubtitleScroller";

type Props = {
  isLoggedIn: boolean,
  onLogin: Function,
  onLogout: Function,
  userResources: ?UserResourceStore,
  isInitialized: boolean,
  nativeLang: string,
  targetLang: ?string,
  onAddSnackbarMessage: SnackbarMessage => void,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  idToken: string | null
};

import type { SnackbarMessage } from "./useSnackbarQueue";

// This is a good reference for Youtube thumbnail URLS
// https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api

export default function ShowFavoritesPage(props: Props) {
  React.useEffect(() => {
    mixpanel.track("Viewed Favorites Page");
  }, []);

  function onRemoveFavorite(id: number) {
    if (props.userResources) {
      props.userResources.favorites.onRemoveFavorite(id).then(() => {
        props.onAddSnackbarMessage({
          body: stringsForLocale(
            props.nativeLang
          ).snackbar.removed_from_favorite_captions(),
          level: "message"
        });
      });
    }
  }

  function onAddDemoFlashCard(caption: Favorite) {
    const body = omit(caption, "id", "createdAt", "original");

    if (props.userResources) {
      props.userResources
        .withIdToken()
        .then(token => postJSON(token, "/api/demo-flash-cards", body))
        .then(
          () => {
            props.onAddSnackbarMessage({
              body: "This caption was saved to a demo flash card.",
              level: "message"
            });
          },
          () => {
            props.onAddSnackbarMessage({
              body: "There was an error saving this caption a demo flash card.",
              level: "error"
            });
          }
        );
    }
  }

  const pageProps = {
    onLogin: props.onLogin,
    onLogout: props.onLogout,
    nativeLang: props.nativeLang,
    targetLang: props.targetLang,
    isLoggedIn: props.isLoggedIn,
    idToken: props.idToken,
    userResources: props.userResources,
    youtubeLanguages: props.youtubeLanguages
  };

  let onAddDemoFlashCardOrNull;
  if (isAdminToken(props.idToken)) {
    onAddDemoFlashCardOrNull = onAddDemoFlashCard;
  } else {
    onAddDemoFlashCardOrNull = null;
  }

  return (
    <WithLoginRequiredPage
      onLogin={props.onLogin}
      userResources={props.userResources}
      isInitialized={props.isInitialized}
      nativeLang={props.nativeLang}
      render={userResources => (
        <WithMostRecentPromise
          promise={userResources.favorites.favorites}
          renderPending={() => null}
          renderRejected={() => null}
          renderResolved={favorites => (
            <ShowFavoritesPageContent
              {...pageProps}
              withIdToken={userResources.withIdToken}
              favorites={favorites}
              onRemoveFavorite={onRemoveFavorite}
              onAddDemoFlashCard={onAddDemoFlashCardOrNull}
            />
          )}
        />
      )}
    />
  );
}

function isAdminToken(idToken: string | null) {
  return idToken != null && isAdmin(decodeIdToken(idToken).sub);
}

type PageContentProps = {
  favorites: Array<Favorite>,
  onLogin: Function,
  onLogout: Function,
  isLoggedIn: boolean,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  userResources: ?UserResourceStore,
  onRemoveFavorite: (favoriteId: number) => void,
  onAddDemoFlashCard: ?(caption: Favorite) => void,
  withIdToken: () => Promise<string>,
  nativeLang: string,
  targetLang: ?string
};

function ShowFavoritesPageContent(props: PageContentProps) {
  const [blurMapJSON, setBlurMapJSON] = useLocalStorage("blur-favorites");
  const blurMap = blurMapJSON == null ? {} : JSON.parse(blurMapJSON);

  function onChangeBlur(langCode, value) {
    const string = JSON.stringify({ ...blurMap, [langCode]: value });
    setBlurMapJSON(string);
  }

  const [showTranslation, setShowTranslation] = React.useState(false);
  const popupProps = useTranslationPopup({
    nativeLang: props.nativeLang,
    withIdToken: props.withIdToken
  });

  const popupEl =
    popupProps != null ? (
      <TranslationPopup {...popupProps} nativeLang={props.nativeLang} />
    ) : null;

  // TODO: I don't know if it makes sense to store this in state, since it's not being rendered
  // into the DOM.
  const [isPlaying, setIsPlaying] = React.useState(false);

  const onPlayerStateChange = React.useCallback((event: YT$StateEvent) => {
    setIsPlaying(event.data === YT.PlayerState.PLAYING);
  }, []);

  const scrollProps = useDynamicScrollPosition();
  const { setActiveElement } = useAutoScroller({
    enabled: isPlaying,
    scrollTo: scrollProps.scrollTo
  });

  // TODO: If this happens, it's because the user is logged out, or they don't have
  // any favorites. We should handle this more gracefully.
  if (props.favorites.length === 0) return null;

  const groupedByVideoId: Array<[string, Array<Favorite>]> = map(
    groupBy(props.favorites, i => i.videoId),
    (list, videoId) => [videoId, sortBy(list, i => i.subtitle.start)]
  );

  const strings = stringsForLocale(props.nativeLang);

  const videoIds = groupedByVideoId.map(tuple => tuple[0]);

  return (
    <WithPlayerController
      playlist={groupedByVideoId.map(i => [i[0], i[1].map(j => j.subtitle)])}
      render={playerProps => (
        <WithDerivedState
          controller={getVideoTitles}
          controllerProps={videoIds}
          render={promise => (
            <WithMostRecentPromise
              promise={promise}
              renderRejected={() => null}
              renderPending={() => null}
              renderResolved={videoTitles => {
                return (
                  <React.Fragment>
                    <WithDocumentEvents
                      onKeyDown={onKeyDown.bind(null, playerProps.onAction)}
                    />
                    {popupEl}

                    <PositioningWrapper
                      headerRetracted={scrollProps.headerRetracted}
                    >
                      <PageHeader
                        onLogin={props.onLogin}
                        onLogout={props.onLogout}
                        isLoggedIn={props.isLoggedIn}
                        nativeLang={props.nativeLang}
                        targetLang={props.targetLang}
                        userResources={props.userResources}
                        fixed={false}
                      >
                        {// TODO: Maybe this deserves its own entry in the yaml file
                        strings.navigation.favorite_captions_link()}
                      </PageHeader>

                      <PlayerContainer
                        {...playerProps.initParams}
                        onPlayerStateChange={event => {
                          focusHack();
                          playerProps.initParams.onPlayerStateChange(event);
                          onPlayerStateChange(event);
                        }}
                        playerVars={{ controls: 0 }}
                      >
                        <KeyboardShortcuts
                          onTogglePlayback={playerProps.onAction.bind(
                            null,
                            "toggle-playback"
                          )}
                          onClickUp={playerProps.onAction.bind(
                            null,
                            "previous"
                          )}
                          onClickDown={playerProps.onAction.bind(null, "next")}
                          onClickRepeat={playerProps.onAction.bind(
                            null,
                            "repeat"
                          )}
                          onToggleTranslation={setShowTranslation}
                          nativeLang={props.nativeLang}
                        />
                      </PlayerContainer>
                    </PositioningWrapper>

                    <PlayerSpacer />

                    <OptionsForm>
                      {collectLanguages(props.favorites).map(langCode => (
                        <label key={langCode}>
                          <input
                            type="checkbox"
                            checked={blurMap[langCode]}
                            onChange={e => {
                              onChangeBlur(langCode, e.target.checked);
                            }}
                          />
                          {strings.favorites_page.blur_captions_label({
                            LANGUAGE:
                              findLanguageName(
                                langCode,
                                props.youtubeLanguages
                              ) || langCode
                          })}
                        </label>
                      ))}
                    </OptionsForm>

                    <SubtitleScroller>
                      {map(
                        groupedByVideoId,
                        (
                          [videoId: string, list: Array<Favorite>],
                          videoIndex: number
                        ) =>
                          [
                            <SubtitleGroupHeader
                              key={"header-" + videoId}
                              videoId={videoId}
                              title={videoTitles[videoId]}
                            />
                          ].concat(
                            list.map((item, i) => {
                              const isCurrent =
                                videoIndex === playerProps.cursor.videoIndex &&
                                i === playerProps.cursor.subtitleIndex;

                              const shouldUnblur = isCurrent && showTranslation;

                              return (
                                <SubtitleListItem
                                  key={`${videoId}-${i}`}
                                  subtitle={item.subtitle}
                                  active={isCurrent}
                                  innerRef={isCurrent ? setActiveElement : null}
                                  onSeek={playerProps.onSeek.bind(null, {
                                    videoIndex,
                                    subtitleIndex: i
                                  })}
                                  showTranslation={
                                    (item.translationLang != null &&
                                      !blurMap[item.translationLang]) ||
                                    shouldUnblur
                                  }
                                  showTranscription={
                                    (item.transcriptionLang != null &&
                                      !blurMap[item.transcriptionLang]) ||
                                    shouldUnblur
                                  }
                                  actionElement={popupMenuForFavorite(
                                    item,
                                    props.onRemoveFavorite,
                                    props.onAddDemoFlashCard
                                      ? props.onAddDemoFlashCard.bind(
                                          null,
                                          item
                                        )
                                      : null,
                                    props.nativeLang
                                  )}
                                />
                              );
                            })
                          )
                      )}
                    </SubtitleScroller>
                  </React.Fragment>
                );
              }}
            />
          )}
        />
      )}
    />
  );
}

function collectLanguages(favorites: Array<Favorite>): Array<string> {
  const langs: { [string]: true } = {};

  for (let favorite of favorites) {
    if (favorite.transcriptionLang != null) {
      langs[favorite.transcriptionLang] = true;
    }

    if (favorite.translationLang != null) {
      langs[favorite.translationLang] = true;
    }
  }
  return Object.keys(langs);
}

function onKeyDown(onAction: PlayerAction => void, event: KeyboardEvent) {
  const action = playerActionForKeyboardEvent(event);
  if (action == null) return;

  event.preventDefault();
  event.stopPropagation();

  onAction(action);
}

function popupMenuForFavorite(
  favorite: Favorite,
  onRemoveFavorite: (favoriteId: number) => void,
  onAddDemoFlashCard: ?() => void,
  locale: string
) {
  const strings = stringsForLocale(locale);

  const popupOptions = [
    [
      PlayFilm,
      strings.caption_popup_options.jump_to(),
      {
        href:
          urlForVideo(
            favorite.videoId,
            favorite.translationLang,
            favorite.transcriptionLang
          ) +
          "#" +
          String(favorite.subtitle.start)
      }
    ],
    [
      Pencil,
      strings.caption_popup_options.edit(),
      { href: "/favorites/" + favorite.id }
    ],
    [
      Close,
      strings.caption_popup_options.remove(),
      {
        ...encodeStateForAnchor({ source: "favorite-captions" }),
        href: "#",
        onClick: event => {
          event.preventDefault();
          onRemoveFavorite(favorite.id);
        }
      }
    ]
  ];

  if (onAddDemoFlashCard) {
    const callback = onAddDemoFlashCard;
    popupOptions.push([
      CircleIcon,
      "Add to demo",
      {
        href: "#",
        onClick: event => {
          event.preventDefault();
          callback();
        }
      }
    ]);
  }

  return (
    <PopupMenuTrigger options={popupOptions} className="">
      <ThreeDots orientation="horizontal" size={20} color="#333" />
    </PopupMenuTrigger>
  );
}

function CircleIcon(props) {
  return (
    <svg {...props} viewBox="0 0 20 20" width={props.size} height={props.size}>
      <circle cx="10" cy="10" r="9" stroke="#333" fill="none" />
    </svg>
  );
}

// Helper function to parse the youtubeLanguages object
function findLanguageName(
  lang /*: string */,
  youtubeLanguages /*: YouTube$i18nLanguageListResponse*/
) /*: string | null*/ {
  const match = youtubeLanguages.items.find(item => item.id === lang);
  if (match) {
    return match.snippet.name;
  } else {
    return null;
  }
}

const PlayerContainer = styled(FixedPlayerContainer)`
  margin-top: 10px;
  position: absolute;
`;

const OptionsForm = styled.form`
  ${widthCss}
  margin: 0 auto;
  margin-top: 1em;

  padding: 0 0.5em;
  box-sizing: border-box;

  input[type="checkbox"] {
    margin-right: 0.5em;
  }

  display: flex;
  flex-wrap: wrap;

  label {
    flex-basis: 50%;
    display: block;
    margin-bottom: 1em;
  }
`;
