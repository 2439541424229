/* @flow */

import * as React from "react";

import WithRestfulCollection from "./WithRestfulCollection";
import WithDerivedState from "./WithDerivedState";

export type RenderProps = {
  libraryItems: Promise<Array<LibraryItem>>,
  pendingItems: Array<UnsavedLibraryItem>,
  onRemoveFromVideoLibrary: (string, string) => Promise<LibraryItem>,
  onAddToVideoLibrary: UnsavedLibraryItem => Promise<LibraryItem>
};

import type { LibraryItem, UnsavedLibraryItem } from "./libraryItems";
import type { LoginResources } from "./WithUserResourceStore";

type Props = {
  loginResources: ?LoginResources,
  render: (?RenderProps) => React.Node
};

export default function WithVideoLibrary(props: Props) {
  return (
    <WithRestfulCollection
      initialState={userResources => userResources.libraryItems}
      loginResources={props.loginResources}
      resourceName="library-items"
      render={restProps => (
        <WithDerivedState
          controller={controller}
          controllerProps={restProps}
          render={props.render}
        />
      )}
    />
  );
}

function controller(props): ?RenderProps {
  if (props) {
    function onAddToVideoLibrary(record: UnsavedLibraryItem) {
      return props.onAddRecord(record);
    }

    function onRemoveFromVideoLibrary(mediaId: string, mediaType: string) {
      return props.records.then(records => {
        const match = records.find(
          iter => iter.mediaId === mediaId && iter.mediaType == mediaType
        );
        if (match) {
          return props.onRemoveRecord(match.id);
        } else {
          return Promise.reject(new Error("This record doesn't exist"));
        }
      });
    }

    return {
      libraryItems: props.records,
      onAddToVideoLibrary,
      onRemoveFromVideoLibrary,
      pendingItems: props.pendingRecords
    };
  } else {
    return null;
  }
}
