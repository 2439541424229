/* @flow */

import * as React from "react";
import styled from "styled-components";

import { colors } from "./theme";

import ChannelLink from "./ChannelLink";

import { Television } from "./SvgAssets";

import ActionLink from "./ActionLink";
import { urlForVideo } from "./urlForRoute";
import { partition, unescape } from "lodash";

import { thumbnailsForVideo } from "./youtubeUtils";
import { ThumbnailPlaceholder } from "./LoadingTextPlaceholder";

import type { TimedTextResponses } from "./WithTimedTextResponses";
import type { YouTubeVideo } from "./youtubeScraper";

import LinkWithState from "./LinkWithState";

import { stripLocale } from "./youtube";

type SubtitleLanguage = {
  code: string,
  label: string
};

export default function VideoGrid(props: {
  results: Array<YouTubeVideo>,
  timedTextResponses: TimedTextResponses,
  languageNames: { [string]: string },
  linkState: Object,
  targetLang: ?string,
  nativeLang: string
}) {
  return (
    <GridStyles>
      {props.results.map((o, i) => (
        <VideoResult
          key={o.videoId + "-" + i}
          item={o}
          linkState={props.linkState}
          targetLang={props.targetLang}
          nativeLang={props.nativeLang}
          languages={languagesForVideo(
            o.videoId,
            props.timedTextResponses,
            props.languageNames
          )}
        />
      ))}
    </GridStyles>
  );
}

type VideoResultProps = {
  item: YouTubeVideo,
  languages: Array<SubtitleLanguage>,
  linkState: Object,
  targetLang: ?string,
  nativeLang: string
};

function VideoResult(props: VideoResultProps) {
  const url = urlForVideo(
    props.item.videoId,
    props.nativeLang,
    props.targetLang
  );
  const thumbnails = thumbnailsForVideo(props.item.videoId);

  return (
    <VideoResultStyles>
      <LinkWithState href={url} state={props.linkState}>
        <ThumbnailPlaceholder {...thumbnails.medium} />
      </LinkWithState>
      <div className="video-metadata">
        <LinkWithState href={url} state={props.linkState} className="title">
          {/*
            It's very odd that this needs escaping. I think it's a bug in the YouTube data API,
            because other API endpoints don't need escaping.
          */}
          {unescape(props.item.title)}
        </LinkWithState>

        <SubtitleLanguageList
          targetLang={props.targetLang}
          nativeLang={props.nativeLang}
          languages={props.languages}
        />

        <div className="video-metadata-section">
          <Television width="15px" />
          <div className="section-content">
            <ChannelLink
              nativeLang={props.nativeLang}
              targetLang={props.targetLang}
              title={props.item.channel.title}
              href={props.item.channel.href}
            />
          </div>
        </div>
      </div>
    </VideoResultStyles>
  );
}

type SubtitleLanguageListProps = {
  targetLang: ?string,
  nativeLang: string,
  languages: Array<SubtitleLanguage>
};

type SubtitleLanguageListState = {
  isExpanded: boolean
};

class SubtitleLanguageList extends React.Component<
  SubtitleLanguageListProps,
  SubtitleLanguageListState
> {
  constructor() {
    super();
    this.state = { isExpanded: false };
  }
  render() {
    let visibleItems;
    let hiddenItems;
    if (this.state.isExpanded || this.props.targetLang == null) {
      visibleItems = this.props.languages;
      hiddenItems = [];
    } else {
      [visibleItems, hiddenItems] = partition(
        this.props.languages,
        l =>
          stripLocale(l.code) === stripLocale(this.props.targetLang || "") ||
          stripLocale(l.code) === stripLocale(this.props.nativeLang)
      );
    }

    if (visibleItems.length > 0) {
      return (
        <div className="video-metadata-section">
          <ClosedCaptioningSvg width="15px" />
          <div className="subtitles section-content">
            {visibleItems.map(l => l.label).join(", ")}
            {hiddenItems.length > 0 ? (
              <ActionLink
                onActivated={() => this.setState({ isExpanded: true })}
              >
                ...
              </ActionLink>
            ) : null}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

const GridStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
`;

const VideoResultStyles = styled.div`
  width: ${320 - 15 * 2}px;
  margin-right: 15px;
  margin-bottom: 15px;
  background-color: white;
  box-shadow: 1px 1px 2px #ccc;

  a.title {
    display: block;
    color: ${colors.black};
    text-decoration: none;
    font-weight: bold;
  }

  .video-metadata {
    padding: 15px;
  }

  .video-metadata-section {
    margin-top: 5px;
    overflow: hidden;

    svg {
      float: left;
      margin-left: 5px;
      margin-top: 2px;
      fill: #999;
    }

    .section-content {
      margin-left: 30px;
    }
  }

  .subtitles {
    color: #999;
  }
`;

function ClosedCaptioningSvg(props) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM218.1 287.7c2.8-2.5 7.1-2.1 9.2.9l19.5 27.7c1.7 2.4 1.5 5.6-.5 7.7-53.6 56.8-172.8 32.1-172.8-67.9 0-97.3 121.7-119.5 172.5-70.1 2.1 2 2.5 3.2 1 5.7l-17.5 30.5c-1.9 3.1-6.2 4-9.1 1.7-40.8-32-94.6-14.9-94.6 31.2.1 48 51.1 70.5 92.3 32.6zm190.4 0c2.8-2.5 7.1-2.1 9.2.9l19.5 27.7c1.7 2.4 1.5 5.6-.5 7.7-53.5 56.9-172.7 32.1-172.7-67.9 0-97.3 121.7-119.5 172.5-70.1 2.1 2 2.5 3.2 1 5.7L420 222.2c-1.9 3.1-6.2 4-9.1 1.7-40.8-32-94.6-14.9-94.6 31.2 0 48 51 70.5 92.2 32.6z" />
    </svg>
  );
}

function languagesForVideo(
  videoId: string,
  timedTextResponses: TimedTextResponses,
  languageNames: { [string]: string }
): Array<SubtitleLanguage> {
  const item = timedTextResponses[videoId];
  if (item == null) {
    return [];
  } else {
    return item.map(code => ({
      label: languageNames[code.languageCode] || code.name.simpleText,
      code: code.languageCode
    }));
  }
}
