/* @flow */

import * as React from "react";
import styled from "styled-components";
import { captureException } from "@sentry/browser";

import PageHeader from "./PageHeader";
import { Container } from "./BootstrapGrid";
import { colors, spacers, typography } from "./theme";
import { TransparentRoundedButton } from "./Buttons";
import { pageHeaderHeight, fontSize } from "./geometry";

import WithLoginRequiredPage from "./WithLoginRequiredPage";

import { useFormState, useFormUtils } from "./WithFormState";
import { useFormValidations, required } from "./useFormValidations";
import { FormField } from "./Forms";

import { stringsForLocale } from "../lang/web";
import { postCancellationFeedback } from "./apiClient";
import { APIError } from "./APIError";

import type { UserResourceStore } from "./WithUserResourceStore";
import type { SnackbarMessage } from "./useSnackbarQueue";

type Props = {
  userResources: ?UserResourceStore,
  isInitialized: boolean,
  onLogin: () => void,
  onLogout: () => void,
  isLoggedIn: boolean,
  nativeLang: string,
  targetLang: ?string,
  onChangeNativeLang: (lang: string) => void,
  onChangeTargetLang: (lang: string) => void,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  onAddSnackbarMessage: SnackbarMessage => void,
  onNavigate: string => void,
  idToken: string | null
};

export default function CancelSubscriptionPage(props: Props) {
  const [inProgress, setInProgress] = React.useState(false);

  const initialValues = {
    reason: "",
    other: ""
  };

  const validations = {
    reason: [required],
    other: []
  };

  const formProps = useFormState({ values: initialValues });
  const formUtils = useFormUtils(formProps);

  const [validationErrors, formEventHandlers] = useFormValidations({
    values: formProps.values,
    locale: props.nativeLang,
    validations,
    onSubmit
  });

  const strings = stringsForLocale(props.nativeLang);

  function onSubmit(event): void {
    setInProgress(true);
    const promise = postCancellationFeedback(props.idToken, formProps.values);

    promise.then(
      () => {
        setInProgress(false);
        props.onNavigate("/account");

        props.onAddSnackbarMessage({
          level: "message",
          body: strings.cancel_subscription_page.confirmation()
        });
      },
      error => {
        setInProgress(false);
        props.onNavigate("/account");

        if (error instanceof APIError) {
          captureException(error);
        } else {
          props.onAddSnackbarMessage({
            level: "error",
            body: strings.snackbar.network_error()
          });
        }
      }
    );
  }

  return (
    <WithLoginRequiredPage
      onLogin={props.onLogin}
      userResources={props.userResources}
      isInitialized={props.isInitialized}
      nativeLang={props.nativeLang}
      render={userResources => (
        <React.Fragment>
          <PageHeader
            onLogin={props.onLogin}
            onLogout={props.onLogout}
            nativeLang={props.nativeLang}
            targetLang={props.targetLang}
            isLoggedIn={true}
            enableMiniHeader={true}
            userResources={userResources}
          />
          <PageWrapper>
            <Container>
              <Box>
                <h2>{strings.cancel_subscription_page.page_header()}</h2>
                <CancelSubscriptionNote>
                  <div>{strings.cancel_subscription_page.paragraph1()}</div>

                  <div>{strings.cancel_subscription_page.paragraph2()}</div>
                </CancelSubscriptionNote>

                <CancelSubscriptionFormSection>
                  <h2>{strings.cancel_subscription_page.feedback_header()}</h2>

                  <div>
                    {strings.cancel_subscription_page.feedback_prompt()}
                  </div>

                  <CancelSubscriptionForm {...formEventHandlers}>
                    {validationErrors.reason.length !== 0 && (
                      <ErrorMessage>
                        {strings.cancel_subscription_page.reason_required()}
                      </ErrorMessage>
                    )}

                    <ReasonOption
                      reason={strings.cancel_subscription_page.reason_too_expensive()}
                      inputProps={formUtils.propsForRadio(
                        "reason",
                        strings.cancel_subscription_page.reason_too_expensive()
                      )}
                    />

                    <ReasonOption
                      reason={strings.cancel_subscription_page.reason_not_useful()}
                      inputProps={formUtils.propsForRadio(
                        "reason",
                        strings.cancel_subscription_page.reason_not_useful()
                      )}
                    />

                    <ReasonOption
                      reason={strings.cancel_subscription_page.reason_other()}
                      inputProps={formUtils.propsForRadio(
                        "reason",
                        strings.cancel_subscription_page.reason_other()
                      )}
                    />

                    <FormField
                      {...formUtils.propsForInput("other")}
                      label={strings.cancel_subscription_page.comments_header()}
                      renderInput={props => <textarea {...props} rows={5} />}
                    />

                    <FormActions>
                      <a href="/account">
                        {strings.cancel_subscription_page.cancel_action()}
                      </a>
                      <TransparentRoundedButton disabled={inProgress}>
                        {strings.cancel_subscription_page.submit_action()}
                      </TransparentRoundedButton>
                    </FormActions>
                  </CancelSubscriptionForm>
                </CancelSubscriptionFormSection>
              </Box>
            </Container>
          </PageWrapper>
        </React.Fragment>
      )}
    />
  );
}

function ReasonOption(props: { reason: string, inputProps: Object }) {
  return (
    <RadioButton>
      <input type="radio" {...props.inputProps} />
      <label htmlFor={props.inputProps.id}>
        <span>{props.reason}</span>
      </label>
    </RadioButton>
  );
}

const PageWrapper = styled.div`
  margin-top: ${pageHeaderHeight + fontSize}px;
`;

const Box = styled.div`
  padding: ${spacers[5]};
  background-color: white;
  border-radius: 20px;

  h2 {
    ${typography.h5}
    margin-top: 0;
    margin-bottom: ${spacers[4]};
  }
`;

const CancelSubscriptionNote = styled.div`
  margin-bottom: ${spacers[5]};

  div {
    margin-bottom: ${spacers[4]};
  }
`;

const CancelSubscriptionFormSection = styled.div`
  margin-bottom: ${spacers[5]};

  div {
    margin-bottom: ${spacers[4]};
  }
`;

const ErrorMessage = styled.div`
  color: ${colors.warning};
`;

const CancelSubscriptionForm = styled.form`
  textarea {
    width: 100%;
    box-sizing: border-box;
  }
`;

const RadioButton = styled.div`
  label {
    margin-left: ${spacers[2]};
  }
`;

const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-left: ${spacers[3]};
  }
`;
