/* @flow */
import * as React from "react";

import styled from "styled-components";

import Popup from "./Popup";

import WithPromise from "./WithPromise";
import { ButtonLink } from "./Buttons";

import { stringsForLocale } from "../lang/web";

import LoadingDots from "./LoadingDots";

export type PopupContent =
  | {
      authorized: true,
      translation: Promise<string>
    }
  | {
      authorized: false
    };

type Props = {
  targetEl: Range,
  content: PopupContent,
  nativeLang: string,
  zIndex?: number
};

const HTTP_NOT_AUTHORIZED = 403;

export default function TranslationPopup(props: Props) {
  // IOS launches a popup above text when you select it, so we need to force our popup
  // to always launch below the text.
  const isIOS = useIOSTest();
  const orientation = isIOS ? { orientation: "below" } : {};

  return (
    <Popup
      targetEl={props.targetEl}
      defaultWidth={200}
      defaultHeight={200}
      color="#000"
      zIndex={props.zIndex}
      {...orientation}
    >
      <Content>
        {props.content.authorized ? (
          <LoggedInContent
            nativeLang={props.nativeLang}
            translation={props.content.translation}
          />
        ) : (
          <TranslationUnauthorized nativeLang={props.nativeLang} />
        )}
      </Content>
    </Popup>
  );
}

function useIOSTest(): boolean {
  const [userAgent, setUserAgent] = React.useState<string>("");
  React.useEffect(() => {
    setUserAgent(navigator.userAgent);
  }, []);

  return /(iPhone|iPod|iPad)/.test(userAgent);
}

type LoggedInProps = {
  translation: Promise<string>,
  nativeLang: string
};

function LoggedInContent(props: LoggedInProps) {
  return (
    <WithPromise
      promise={props.translation}
      renderResolved={response => response}
      renderRejected={error => {
        if (error.code === HTTP_NOT_AUTHORIZED) {
          return <TranslationUnauthorized nativeLang={props.nativeLang} />;
        } else {
          return null;
        }
      }}
      renderPending={() => <TranslationPending />}
      width={200}
    />
  );
}

function TranslationUnauthorized(props: { nativeLang: string }) {
  const strings = stringsForLocale(props.nativeLang);

  return (
    <TranslationUnauthorizedStyles>
      <p>{strings.play_videos_page.translation_upgrade_prompt()}</p>
      <ButtonLink href="/premium-splash" primary>
        {strings.play_videos_page.translation_upgrade_call_to_action()}
      </ButtonLink>
    </TranslationUnauthorizedStyles>
  );
}

const TranslationPending = styled(LoadingDots).attrs({ color: "white" })`
  margin: 0 1em;
`;

const TranslationUnauthorizedStyles = styled.div`
  max-width: 25em;
  line-height: 200%;
  padding: 0 1em;
  text-align: center;
  p {
    margin: 0 0 1em 0;
  }
`;

const Content = styled.div`
  padding: 1em;
  color: #fff;
`;
