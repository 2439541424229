/* @flow */

import * as React from "react";

import { showReportDialog } from "@sentry/browser";
import ErrorDetails from "./ErrorDetails";

import { ButtonLink, ActionButton } from "./Buttons";
import ErrorPage, { ActionBar, Header } from "./ErrorPage";

import { stringsForLocale } from "../lang/web";

type Props = {
  nativeLang: string,
  error: any,
  eventId: ?string
};

export default class SentryErrorPage extends React.Component<Props> {
  onClickReport() {
    // https://docs.sentry.io/learn/user-feedback/?platform=javascript
    showReportDialog({
      eventId: this.props.eventId,
      lang: this.props.nativeLang
    });
  }

  render() {
    return (
      <ErrorPage nativeLang={this.props.nativeLang}>
        <Header color="#888">Sorry, something broke!</Header>

        <p>
          A notification has been sent to Jon (the maker of CaptionPop) and he
          will investigate.
        </p>

        <p>
          You can try again later, or fill out crash report to help Jon resolve
          the issue faster.
        </p>

        <ErrorDetails error={this.props.error} />

        <ActionBar>
          <ButtonLink href="/">
            {stringsForLocale(this.props.nativeLang).page_header.home_action()}
          </ButtonLink>

          <ActionButton primary onActivated={this.onClickReport.bind(this)}>
            File a report
          </ActionButton>
        </ActionBar>
      </ErrorPage>
    );
  }
}
