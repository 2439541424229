/* @flow */

import * as React from "react";

import styled from "styled-components";

import { Close } from "./SvgAssets";

type Props = {
  href: string,
  className?: string
};

const CloseLinkStyles = styled.a`
  position: fixed;
  top: 15px;
  right: 45px;
  display: block;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

export default function CloseLink(props: Props) {
  return (
    <CloseLinkStyles className={props.className} href={props.href} size={25}>
      <Close color="#333" size={25} className="" />
    </CloseLinkStyles>
  );
}
