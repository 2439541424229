/* @flow */

import * as React from "react";

import AboutPage from "./AboutPage";

import type { SearchProps } from "./WithSearchResults";
import type { UserResourceStore } from "./WithUserResourceStore";
import type { DocumentLocation } from "./useNavigation";
import type { SnackbarMessage } from "./useSnackbarQueue";
import type { ServerSideProps } from "./sendSPA";

import { urlForSearch } from "./urlForRoute";

type Props = {
  isLoggedIn: boolean,
  searchProps: SearchProps,
  location: DocumentLocation,
  onLogin: Function,
  onLogout: Function,
  onNavigate: string => void,
  nativeLang: string,
  targetLang: ?string,
  serverSideProps: ServerSideProps,
  onChangeNativeLang: (lang: string) => void,
  onChangeTargetLang: (lang: string) => void,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  onAddSnackbarMessage: SnackbarMessage => void,
  userResources: ?UserResourceStore
};

export default function HomePage(props: Props) {
  const { onNavigate } = props;

  let navitateTo = null;
  if (props.isLoggedIn && props.targetLang != null) {
    navitateTo = urlForSearch({}, props.nativeLang, props.targetLang);
  }

  React.useEffect(() => {
    if (navitateTo != null) {
      onNavigate(navitateTo);
    }
  }, [onNavigate, navitateTo]);

  return <AboutPage {...props} />;
}
