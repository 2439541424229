/* @flow */

import * as React from "react";
import styled from "styled-components";

import { flatten, last, forEach } from "lodash";

import * as geometry from "./geometry";
import { colors } from "./theme";

import { Television, Filter } from "./SvgAssets";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";

import { ActionButton } from "./Buttons";
import SearchField from "./SearchField";
import YouTubeErrorPage from "./YouTubeErrorPage";
import ChooseLanguagesForm from "./ChooseLanguagesForm";
import VideoGrid from "./VideoGrid";
import RecommendationsPage from "./RecommendationsPage";

import WithSearchForm from "./WithSearchForm";
import WithPromise from "./WithPromise";
import WithPromiseOfText from "./WithPromiseOfText";

import { matchLanguage } from "./matchLanguage";
import parseSearchString, { updateLocation } from "./parseSearchString";
import spinnerUrl from "../assets/spinner.svg";
import { usePromiseValue } from "./useFlatPromise";
import { languageNamesFromResponse } from "./youtubeUtils";

import type { DocumentLocation } from "./useNavigation";
import type { TimedTextTrack } from "./timedtext2";
import type { SearchProps, SearchResult } from "./WithSearchResults";
import type { TimedTextResponses } from "./WithTimedTextResponses";
import type { FilterState } from "./WithSearchForm";

import type { UserResourceStore } from "./WithUserResourceStore";
import type { SnackbarMessage } from "./useSnackbarQueue";
import type { YouTubeVideo } from "./youtubeScraper";

import LibraryToggleButton, { toggleButtonState } from "./LibraryToggleButton";

import { stringsForLocale } from "../lang/web";

type Props = {
  location: DocumentLocation,
  onLogin: Function,
  onLogout: Function,
  isLoggedIn: boolean,
  onNavigate: string => void,
  onReplaceLocation: (
    href: string,
    linkState: ?Object,
    currentPathname: string
  ) => void,
  onAddSnackbarMessage: SnackbarMessage => void,
  targetLang: ?string,
  nativeLang: string,
  searchProps: SearchProps,
  onChangeNativeLang: (lang: string) => void,
  onChangeTargetLang: (lang: string) => void,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  withIdToken: ?() => Promise<string>,
  isInitialized: boolean,
  idToken: string | null,
  userResources: ?UserResourceStore
};

export default function SearchPage(props: Props) {
  const libraryItem = usePromiseValue(props.searchProps.libraryItem);

  const strings = stringsForLocale(props.nativeLang);

  const targetLang = props.targetLang;

  const withSnackbarResponse = (promise, successMessage) => {
    promise
      .then(
        () => ({
          level: "message",
          body: successMessage
        }),
        () => ({
          level: "error",
          body: strings.snackbar.network_error()
        })
      )
      .then(msg => props.onAddSnackbarMessage(msg));
  };

  if (!targetLang) {
    return (
      <React.Fragment>
        <PageHeader
          onLogin={props.onLogin}
          onLogout={props.onLogout}
          isLoggedIn={props.isLoggedIn}
          nativeLang={props.nativeLang}
          targetLang={props.targetLang}
          userResources={props.userResources}
        />
        <PageContent>
          <Box>
            {strings.search_page.meta_description_generic()}

            <ChooseLanguagesForm
              nativeLang={props.nativeLang}
              targetLang={props.targetLang}
              onChangeTargetLang={props.onChangeTargetLang}
              onChangeNativeLang={props.onChangeNativeLang}
              submitLabel={strings.about_page.find_videos_action()}
              youtubeLanguages={props.youtubeLanguages}
            />
          </Box>
        </PageContent>
        <PageFooter nativeLang={props.nativeLang} />
      </React.Fragment>
    );
  }

  const languageNames = languageNamesFromResponse(props.youtubeLanguages);

  if (props.searchProps.searchDescription) {
    const description = props.searchProps.searchDescription;
    const params = props.searchProps.searchDescription.params;
    let searchHeader;
    let headerActionEl = null;

    if (description.resource === "search") {
      searchHeader = (
        <CenteredSearchForm
          onNavigate={props.onNavigate}
          nativeLang={props.nativeLang}
          targetLang={targetLang}
          languageNames={languageNames}
          defaultValue={description.params.q}
        />
      );
    }

    if (
      description.resource === "channel" ||
      description.resource === "username"
    ) {
      searchHeader = <ChannelTitle promise={props.searchProps.searchTitle} />;
    } else if (description.resource === "playlist") {
      searchHeader = (
        <WithPromiseOfText
          promise={props.searchProps.searchTitle}
          width={100}
        />
      );
    }

    // Doing this with an object spread causes a bunch of flow errors
    const linkState = Object.assign(
      {},
      { source: "search" },
      params,
      props.location.state
    );

    if (libraryItem != null) {
      if (props.userResources) {
        const videoLibrary = props.userResources.videoLibrary;
        const libraryItem2 = libraryItem;

        const onAddToVideoLibrary = () => {
          withSnackbarResponse(
            videoLibrary.onAddToVideoLibrary(libraryItem2),
            successfulMessageForAddOperation(
              props.nativeLang,
              libraryItem2.mediaType
            )
          );
        };

        const onRemoveFromVideoLibrary = () => {
          withSnackbarResponse(
            videoLibrary.onRemoveFromVideoLibrary(
              libraryItem2.mediaId,
              libraryItem2.mediaType
            ),
            successfulMessageForRemoveOperation(
              props.nativeLang,
              libraryItem2.mediaType
            )
          );
        };

        headerActionEl = (
          <WithPromise
            promise={props.userResources.videoLibrary.libraryItems}
            renderResolved={libraryItems => {
              const buttonState = toggleButtonState(
                libraryItem2.mediaId,
                libraryItem2.mediaType,
                libraryItems,
                videoLibrary.pendingItems
              );
              return (
                <LibraryToggleButton
                  buttonState={buttonState}
                  onRemoveFromVideoLibrary={onRemoveFromVideoLibrary}
                  onAddToVideoLibrary={onAddToVideoLibrary}
                  nativeLang={props.nativeLang}
                />
              );
            }}
            renderPending={() => null}
            renderRejected={() => null}
          />
        );
      } else {
        // TODO: It would be nice if the add function was triggered after login, like is done on the
        // PlayVideoPage
        const onAddToVideoLibrary = () => {
          props.onLogin(
            stringsForLocale(props.nativeLang).login_form.library_prompt()
          );
        };

        headerActionEl = (
          <LibraryToggleButton
            buttonState="out"
            onRemoveFromVideoLibrary={() => {}}
            onAddToVideoLibrary={onAddToVideoLibrary}
            nativeLang={props.nativeLang}
          />
        );
      }
    }

    return (
      <WithPromise
        promise={props.searchProps.searchListResponses}
        renderRejected={error => (
          <YouTubeErrorPage
            onLogin={props.onLogin}
            onLogout={props.onLogout}
            isLoggedIn={props.isLoggedIn}
            nativeLang={props.nativeLang}
            targetLang={props.targetLang}
            userResources={props.userResources}
            error={error}
          />
        )}
        renderPending={previousResponses => {
          return (
            <React.Fragment>
              <PageHeader
                onLogin={props.onLogin}
                onLogout={props.onLogout}
                isLoggedIn={props.isLoggedIn}
                nativeLang={props.nativeLang}
                targetLang={props.targetLang}
                userResources={props.userResources}
                hidePremiumButton={headerActionEl != null}
                action={headerActionEl}
              >
                {searchHeader}
              </PageHeader>
              <SearchPageInner
                linkState={linkState}
                searchListResponses={previousResponses || []}
                nativeLang={props.nativeLang}
                targetLang={targetLang}
                timedTextResponses={props.searchProps.timedTextResponses}
                onFetchMore={props.searchProps.onFetchMore}
                youtubeLanguages={props.youtubeLanguages}
                onReplaceLocation={props.onReplaceLocation}
                pending={true}
                location={props.location}
              />
            </React.Fragment>
          );
        }}
        renderResolved={searchListResponses => {
          return (
            <React.Fragment>
              <PageHeader
                onLogin={props.onLogin}
                onLogout={props.onLogout}
                isLoggedIn={props.isLoggedIn}
                nativeLang={props.nativeLang}
                targetLang={props.targetLang}
                userResources={props.userResources}
                hidePremiumButton={headerActionEl != null}
                action={headerActionEl}
              >
                {searchHeader}
              </PageHeader>
              <SearchPageInner
                linkState={linkState}
                searchListResponses={searchListResponses}
                nativeLang={props.nativeLang}
                targetLang={targetLang}
                timedTextResponses={props.searchProps.timedTextResponses}
                onFetchMore={props.searchProps.onFetchMore}
                youtubeLanguages={props.youtubeLanguages}
                onReplaceLocation={props.onReplaceLocation}
                pending={false}
                location={props.location}
              />
            </React.Fragment>
          );
        }}
      />
    );
  } else {
    return <RecommendationsPage {...props} targetLang={targetLang} />;
  }
}

function successfulMessageForAddOperation(
  nativeLang: string,
  mediaType
): string {
  const strings = stringsForLocale(nativeLang).snackbar;

  if (mediaType === "video") {
    return strings.video_added_to_library();
  } else if (mediaType === "channel") {
    return strings.channel_added_to_library();
  } else if (mediaType === "playlist") {
    return strings.playlist_added_to_library();
  } else {
    // This should never happen. For the sake of flow
    return "";
  }
}

function successfulMessageForRemoveOperation(
  nativeLang: string,
  mediaType
): string {
  const strings = stringsForLocale(nativeLang).snackbar;

  if (mediaType === "video") {
    return strings.video_removed_from_library();
  } else if (mediaType === "channel") {
    return strings.channel_removed_from_library();
  } else if (mediaType === "playlist") {
    return strings.playlist_removed_from_library();
  } else {
    // This should never happen. For the sake of flow
    return "";
  }
}

type InnerProps = {
  targetLang: string,
  nativeLang: string,
  onReplaceLocation: (
    href: string,
    state: ?Object,
    currentPathname: string
  ) => void,
  timedTextResponses: TimedTextResponses,
  onFetchMore: Function,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  searchListResponses: Array<SearchResult>,
  pending: boolean,
  linkState: Object,
  location: DocumentLocation
};

class SearchPageInner extends React.Component<InnerProps> {
  filterState(): FilterState {
    const params = parseSearchString(this.props.location.search);
    if (
      "filter" in params &&
      (params.filter === "both" ||
        params.filter === "disabled" ||
        params.filter === "native" ||
        params.filter === "target")
    ) {
      return params.filter;
    } else {
      return "both";
    }
  }

  filteredLanguagesAsText(): Array<string> {
    const languageNames = languageNamesFromResponse(
      this.props.youtubeLanguages
    );

    const list = this.filteredLanguages();
    if (list == null) {
      return [];
    } else {
      return list.map(code => languageNames[code]);
    }
  }

  filteredLanguages(): ?Array<string> {
    switch (this.filterState()) {
      case "both":
        if (this.props.targetLang)
          return [this.props.nativeLang, this.props.targetLang];
        else return [this.props.nativeLang];
      case "native":
        return [this.props.nativeLang];
      case "target":
        if (this.props.targetLang) return [this.props.targetLang];
        else return null;
      default:
        return null;
    }
  }

  shouldShowVideo(filterState: FilterState, item: YouTubeVideo): boolean {
    if (filterState === "disabled") return true;

    const filteredLanguages = this.filteredLanguages();
    if (filteredLanguages == null) return true;

    const timedTexts = this.props.timedTextResponses[item.videoId];
    if (timedTexts) {
      const langCodes = timedTexts.map(t => t.languageCode);
      return (
        filteredLanguages.findIndex(
          requiredLang =>
            langCodes.findIndex(langCode =>
              matchLanguage(requiredLang, langCode)
            ) === -1
        ) === -1
      );
    } else {
      return false;
    }
  }

  onChangeFilterState(filter: FilterState) {
    this.props.onReplaceLocation(
      updateLocation(this.props.location, { filter }),
      null,
      this.props.location.pathname
    );
  }

  render() {
    const strings = stringsForLocale(this.props.nativeLang);

    const counts = countVideos(
      this.props.timedTextResponses,
      this.props.nativeLang,
      this.props.targetLang
    );

    const allItems = flatten(this.props.searchListResponses.map(r => r.items));

    const filterState = this.filterState();
    const filteredItems = allItems.filter(item =>
      this.shouldShowVideo(filterState, item)
    );

    const languageNames = languageNamesFromResponse(
      this.props.youtubeLanguages
    );

    return (
      <PageContent>
        {this.props.searchListResponses.length > 0 ? (
          <React.Fragment>
            <FilterSection
              filterState={this.filterState()}
              onChangeFilterState={this.onChangeFilterState.bind(this)}
              targetAndNativeCount={counts.targetAndNative}
              nativeCount={counts.native}
              targetCount={counts.target}
              allCount={allItems.length}
              targetLang={this.props.targetLang}
              nativeLang={this.props.nativeLang}
              languageNames={languageNames}
            />

            {filteredItems.length > 0 ? (
              <VideoGrid
                results={filteredItems}
                languageNames={languageNames}
                nativeLang={this.props.nativeLang}
                targetLang={this.props.targetLang}
                linkState={this.props.linkState}
                timedTextResponses={this.props.timedTextResponses}
              />
            ) : (
              <NoResultsMessage
                nativeLang={this.props.nativeLang}
                count={allItems.length}
                languages={this.filteredLanguagesAsText()}
              />
            )}
          </React.Fragment>
        ) : null}

        {this.props.pending ? (
          <LoadingMore />
        ) : isMoreAvailable(this.props.searchListResponses) ? (
          <FetchMoreLink onActivated={this.props.onFetchMore}>
            {strings.search_page.more_action()}
          </FetchMoreLink>
        ) : filteredItems.length > 0 ? (
          <FetchProgress>{strings.search_page.end_of_results()}</FetchProgress>
        ) : null}
      </PageContent>
    );
  }
}

function isMoreAvailable(searchListResponses) {
  const lastResponse = last(searchListResponses);
  return lastResponse && lastResponse.nextPageToken;
}

const LoadingMoreStyles = styled.div`
  text-align: center;
  margin: 10px auto;
`;

function NoResultsMessage(props: {
  count: number,
  languages: Array<string>,
  nativeLang: string
}): React.Node {
  const strings = stringsForLocale(props.nativeLang).search_page
    .empty_search_results;

  let message;
  if (props.count === 0) {
    message = strings.no_videos();
  } else if (props.count === 1) {
    if (props.languages.length === 2) {
      message = strings.singular_video_dual_filter({
        FILTER_LANGUAGE_A: props.languages[0],
        FILTER_LANGUAGE_B: props.languages[1]
      });
    } else {
      message = strings.singular_video_single_filter({
        FILTER_LANGUAGE: props.languages[0]
      });
    }
  } else {
    if (props.languages.length === 2) {
      message = strings.plural_videos_dual_filter({
        VIDEO_COUNT: props.count,
        FILTER_LANGUAGE_A: props.languages[0],
        FILTER_LANGUAGE_B: props.languages[1]
      });
    } else {
      message = strings.plural_videos_single_filter({
        VIDEO_COUNT: props.count,
        FILTER_LANGUAGE: props.languages[0]
      });
    }
  }

  return <NoResultsMessageStyle>{message}</NoResultsMessageStyle>;
}

const NoResultsMessageStyle = styled.div`
  margin: 20px;
`;

function LoadingMore(props) {
  return (
    <LoadingMoreStyles>
      <img src={spinnerUrl} width="64px" />
      <div>Loading videos...</div>
    </LoadingMoreStyles>
  );
}

const FetchMoreLink: typeof ActionButton = styled(ActionButton)`
  margin: 10px auto;
  width: 250px;
  display: block;
`;

const FetchProgress = styled.div`
  margin-top: 10px;
  text-align: center;
  color: #333;
`;

const PageContent = styled.div`
  padding-top: ${geometry.pageHeaderHeight}px;
`;

function countVideos(
  timedTextResponses: TimedTextResponses,
  nativeLang: string,
  targetLang: ?string
) {
  let nativeCount = 0,
    targetCount = 0,
    targetAndNativeCount = 0;

  forEach(timedTextResponses, (langs: Array<TimedTextTrack>) => {
    function hasLang(langCode: string): boolean {
      return (
        langs.findIndex(i => matchLanguage(langCode, i.languageCode)) !== -1
      );
    }

    const hasNative = hasLang(nativeLang);
    const hasTarget = targetLang ? hasLang(targetLang) : false;

    if (hasNative) nativeCount++;

    if (hasTarget) targetCount++;

    if (hasTarget && hasNative) targetAndNativeCount++;
  });

  return {
    native: nativeCount,
    target: targetCount,
    targetAndNative: targetAndNativeCount
  };
}

const Box = styled.div`
  padding: 1em;
  background-color: #fff;
  border: 1px solid rgba(178, 183, 189, 0.23999999463558197);
  border-radius: 4px;

  text-align: center;
  max-width: ${geometry.videoWidth}px;
  margin: 2em auto;

  select {
    width: 200px;
    float: right;
    margin: 0 1em;
  }
`;

const FilterSectionStyles = styled.div`
  margin: 20px;
  color: #555;

  .filter-header {
    margin-bottom: 3px;
  }

  svg {
    margin-right: 5px;
    fill: #555;
  }
`;

type FilterBoxProps = {
  on: boolean,
  count: number,
  onClick: Function,
  label: string,
  style?: Object
};

function FilterBox(props: FilterBoxProps) {
  const anchorProps: Object = {
    on: props.on,
    style: props.style,
    href: "#",
    onClick: onClick
  };

  function onClick(event) {
    event.preventDefault();
    props.onClick();
  }

  return (
    <FilterBoxStyles {...anchorProps}>
      {props.label}
      <div className="count">{props.count}</div>
    </FilterBoxStyles>
  );
}

type ChannelTitleProps = {
  promise: Promise<string> | null
};

function ChannelTitle(props: ChannelTitleProps) {
  return (
    <div>
      <Television width="15px" />{" "}
      <b>
        <WithPromiseOfText promise={props.promise} width={100} />
      </b>
    </div>
  );
}

type CenteredSearchFormProps = {
  nativeLang: string,
  targetLang: string,
  onNavigate: string => void,
  defaultValue: string,
  languageNames: { [string]: string }
};

export function CenteredSearchForm(props: CenteredSearchFormProps) {
  return (
    <WithSearchForm
      nativeLang={props.nativeLang}
      targetLang={props.targetLang}
      onNavigate={props.onNavigate}
      defaultValue={props.defaultValue}
      render={formProps => (
        <CenteredSearchFormStyles onSubmit={formProps.onSubmit} role="search">
          <SearchField
            onNavigate={props.onNavigate}
            nativeLang={props.nativeLang}
            onChange={formProps.onChangeSearchString}
            value={formProps.values.search}
            disabled={formProps.disabled}
            targetLanguageName={props.languageNames[props.targetLang]}
          />
        </CenteredSearchFormStyles>
      )}
    />
  );
}

const CenteredSearchFormStyles = styled.form`
  margin: 8px auto;
`;

const FilterBoxStyles = styled.a`
  display: inline-block;
  border: 2px solid ${props => (props.on ? colors.highlight : "#eee")};
  height: 40px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 5px;
  margin-right: 5px;
  text-decoration: none;
  padding: 5px 15px;
  text-align: center;
  color: ${props => (props.on ? colors.highlight : "#999")};

  background-color: ${props => (props.on ? "#fff" : "auto")};
  .count {
    color: ${props => (props.on ? colors.highlight : "#999")};
  }
`;

type FilterSectionProps = {
  filterState: FilterState,
  onChangeFilterState: FilterState => void,
  targetAndNativeCount: number,
  nativeCount: number,
  targetCount: number,
  allCount: number,
  nativeLang: string,
  targetLang: ?string,
  languageNames: { [string]: string }
};

function FilterSection(props: FilterSectionProps) {
  const strings = stringsForLocale(props.nativeLang);
  const nativeLang = props.languageNames[props.nativeLang];

  const nativeFilterBoxEl = (
    <FilterBox
      key="native"
      on={props.filterState === "native"}
      label={nativeLang}
      onClick={() => props.onChangeFilterState("native")}
      count={props.nativeCount}
    />
  );

  let filterBoxes;

  if (props.targetLang) {
    const targetLang = props.languageNames[props.targetLang];

    filterBoxes = [
      <FilterBox
        key="both"
        on={props.filterState === "both"}
        label={`${nativeLang} + ${targetLang}`}
        onClick={() => props.onChangeFilterState("both")}
        count={props.targetAndNativeCount}
      />,

      nativeFilterBoxEl,

      <FilterBox
        key="target"
        on={props.filterState === "target"}
        label={targetLang}
        onClick={() => props.onChangeFilterState("target")}
        count={props.targetCount}
      />
    ];
  } else {
    filterBoxes = [nativeFilterBoxEl];
  }

  return (
    <FilterSectionStyles>
      <div className="filter-header">
        <Filter width="10px" />
        {strings.search_page.filter_header()}{" "}
        <a href="#choose-languages">
          {strings.search_form.change_language_link()}
        </a>
      </div>

      <FilterBox
        on={props.filterState === "disabled"}
        label={strings.search_page.show_all()}
        onClick={() => props.onChangeFilterState("disabled")}
        count={props.allCount}
      />

      {filterBoxes}
    </FilterSectionStyles>
  );
}
