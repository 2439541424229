/* @flow */
import * as React from "react";

import {
  Observable,
  Subscription,
  fromEvent,
  timer,
  concat,
  of,
  NEVER,
  EMPTY,
  Subject
} from "rxjs";
import {
  scan,
  map,
  filter,
  take,
  skipUntil,
  switchMap,
  delay,
  startWith,
  withLatestFrom
} from "rxjs/operators";
import { filterNull } from "./operators";

const SCROLL_DURATION = 50;

type ScrollProps = {
  scrollTo: (to: number) => void,
  headerRetracted: boolean
};

type AnimationState =
  | {
      value: number,
      inProgress: true
    }
  | { inProgress: false };

function scrollWindowTo(value: number) {
  window.scrollTo(0, value);
}

function animatedValues(
  from: number,
  to: number,
  duration: number
): Observable<number> {
  const difference = to - from;

  return animation(duration).pipe(
    map(progress => from + difference * progress)
  );
}

// This hook does two things
//   - Provides a way to animate the scroll position
//   - Manages the headerRetractedState
export default function useDynamicScrollPosition(): ScrollProps {
  const [scrollToAction$: Observable<number>, scrollTo] = useObservedCallback();
  const [headerRetracted, setHeaderRetracted] = React.useState(false);

  React.useEffect(() => {
    const scrollState$ = scrollToAction$.pipe(
      switchMap(mapActionToAnimationState),
      startWith({ inProgress: false })
    );

    // Filter out any gestures that are generated by scroll animations
    const filteredHeaderRetracted$ = headerRetracted$.pipe(
      withLatestFrom(scrollState$, (value, scrollState) =>
        scrollState.inProgress ? null : value
      ),
      filterNull()
    );

    const scrollPos$ = scrollState$.pipe(
      filter(state => state.inProgress),
      map(state => state.value)
    );

    const subscription = new Subscription();

    subscription.add(scrollPos$.subscribe(scrollWindowTo));
    subscription.add(filteredHeaderRetracted$.subscribe(setHeaderRetracted));

    return () => {
      subscription.unsubscribe();
    };
  }, [scrollToAction$]);

  return { scrollTo, headerRetracted };
}

// Maps a user action to start scrolling to an observable of animated scroll positions
function mapActionToAnimationState(to: number): Observable<AnimationState> {
  return concat(
    animatedValues(window.scrollY, to, SCROLL_DURATION).pipe(
      map(value => ({ value, inProgress: true }))
    ),

    // Consider animations in progress until 50ms after they finish. We want a little bit of time
    // to allow for onScroll eventts to be generated.
    of({ inProgress: false }).pipe(delay(50))
  );
}

function useObservedCallback() {
  // TODO: Would it be better to just put this in state, rather than memo'ing it?
  // It would be nice useRef took an initializer function like useState
  const ref = React.useRef(new Subject());

  return React.useMemo(
    () => [ref.current.asObservable(), value => ref.current.next(value)],
    []
  );
}

const scrollTrigger = 100;

type ScrollDirection = "up" | "down";

type ScrollState = {
  // The value of scrollY the last time scrolling changed directions
  base: number,

  // The most recent sampled value of window.scrollY
  scrollY: number,

  // The most recent sampled value of document.body.clientHeight
  clientHeight: number
};

type ScrollGesture = {
  direction: ScrollDirection,
  base: number,
  first: boolean
};

export type VerticalPositioning = {
  position: "fixed" | "absolute",
  top: number
};

function animation(duration: number): Observable<number> {
  if (duration <= 0) {
    return EMPTY;
  }

  return Observable.create(observer => {
    const animationStartAt = performance.now();
    let animationId = null;

    function scheduleNextFrame() {
      animationId = requestAnimationFrame(frame);
    }

    function frame(now) {
      animationId = null;

      const progress = (now - animationStartAt) / duration;

      if (progress >= 1.0) {
        observer.next(1.0);
        observer.complete();
      } else {
        if (progress >= 0) {
          observer.next(progress);
        }
        scheduleNextFrame();
      }
    }

    scheduleNextFrame();

    return function unsubscribe() {
      if (animationId != null) {
        cancelAnimationFrame(animationId);
      }
    };
  });
}

function scrollDirection(start: number, current: number): ?ScrollDirection {
  if (start > current) {
    return "up";
  } else if (start < current) {
    return "down";
  } else {
    return null; // No change in scroll position
  }
}

function detectScrollGesture(
  prevGesture: ?ScrollGesture,
  scrollState: ScrollState
): ?ScrollGesture {
  const amount = Math.abs(scrollState.base - scrollState.scrollY);

  if (amount > scrollTrigger) {
    const base = scrollState.base;
    const direction = scrollDirection(base, scrollState.scrollY) || "down"; // OR for the sake of flow
    const first =
      prevGesture == null ||
      prevGesture.direction !== direction ||
      prevGesture.base !== base;

    return { direction, base, first };
  } else {
    return null;
  }
}

function updateScrollState(
  prevState: ScrollState | null,
  scrollY: number
): ScrollState {
  const clientHeight = document.body ? document.body.clientHeight : 0;

  if (prevState == null || prevState.clientHeight !== clientHeight) {
    // Initial value
    return { base: scrollY, scrollY, clientHeight };
  } else {
    const previousDirection = scrollDirection(
      prevState.base,
      prevState.scrollY
    );
    const currentDirection = scrollDirection(prevState.scrollY, scrollY);

    let base;
    if (previousDirection === currentDirection) {
      base = prevState.base;
    } else {
      base = prevState.scrollY;
    }

    return { base, scrollY, clientHeight };
  }
}

const windowScroll$ =
  typeof window === "object" ? fromEvent(window, "scroll") : NEVER;

const scrollY$: Observable<number> = windowScroll$.pipe(
  map(event => window.scrollY),
  skipUntil(timer(1000).pipe(take(1)))
);

const scrollState$: Observable<ScrollState> = scrollY$.pipe(
  scan(updateScrollState, null),
  filterNull() // null never gets emitted downstream. This is for the sake of Flow
);

const scrollGesture$: Observable<ScrollGesture> = scrollState$.pipe(
  scan(detectScrollGesture, null),
  filter(gesture => gesture != null)
);

const headerRetracted$: Observable<boolean> = scrollGesture$.pipe(
  map(scrollGesture => scrollGesture.direction === "down")
);
