/* @flow */

import * as React from "react";

import { getAccountEmailAvailability } from "./apiClient";
import { captureException } from "@sentry/browser";

import type { AccountEmailAvailability } from "./authentication";

import { usePromiseValue } from "./useFlatPromise";

export function useEmailAvailabilityCheck(email: string): boolean {
  const trimmedEmail = email.trim();

  const debouncedEmail = useDebounce(trimmedEmail, 800);
  const [
    promise,
    setPromise
  ] = React.useState<Promise<AccountEmailAvailability> | null>(null);

  React.useEffect(() => {
    const abortController =
      "AbortController" in window ? new AbortController() : null;

    if (debouncedEmail != null && debouncedEmail != "") {
      const options = abortController ? { signal: abortController.signal } : {};
      const promise = getAccountEmailAvailability(debouncedEmail, options);
      promise.catch(error => captureException(error));
      setPromise(promise);

      return () => {
        if (abortController) {
          abortController.abort();
        }
      };
    }
  }, [debouncedEmail]);

  const apiResponse = usePromiseValue(promise);

  if (apiResponse == null || apiResponse.email !== trimmedEmail) return true;
  else return apiResponse.available;
}

function useDebounce(value: string, delay: number): string | null {
  const [debouncedValue, setDebouncedValue] = React.useState<string | null>(
    value
  );

  React.useEffect(() => {
    setDebouncedValue(null);

    const timeoutID = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [value, delay]);

  return debouncedValue;
}
