/* @flow */
/*eslint no-console: ["error", { allow: ["warn"] }] */

// Interface for YouTube's unofficial timedtext API
//
// THIS API NO LONGER WORKS! See timedtext2.js
//
// https://stackoverflow.com/questions/23665343/get-closed-caption-cc-for-youtube-video
// https://github.com/ableplayer/ableplayer/issues/251
//
//

import { stringify as qsStringify } from "querystring";

import fetchXML from "./fetchXML";

type TimedTextTrack = {
  id: string,
  langCode: string,
  langOriginal: string,
  langTranslated: string,
  langDefault: boolean,
  name: string,
  kind?: string
};

type TimedText = {
  start: number,
  duration: number,
  text: string
};

function duration(element, nextElement) {
  // Some older YouTube captions are missing duration field. So we try to
  // guess a reasonable duration by looking at the nextElement. The SRT
  // generator for YouTube seems to do the same thing
  // For example: https://www.youtube.com/api/timedtext?lang=es-MX&name=es-MX&v=UcEhY9khsak
  if (element.hasAttribute("dur")) {
    return parseFloat(element.getAttribute("dur"));
  } else if (nextElement && nextElement.getAttribute("start")) {
    return (
      parseFloat(nextElement.getAttribute("start")) -
      parseFloat(element.getAttribute("start"))
    );
  } else {
    return 1.0;
  }
}

export function parseTimedTextXMLDoc(xmlDoc: Document): Array<TimedText> {
  const elements = xmlDoc.getElementsByTagName("text");
  return Array.prototype.map.call(elements, (el, i) => ({
    start: parseFloat(el.getAttribute("start")),
    duration: duration(el, elements[i + 1]),
    text: el.textContent
  }));
}

function parseTimedTextListXMLDoc(xmlDoc: Document): Array<TimedTextTrack> {
  const elements = xmlDoc.getElementsByTagName("track");
  return Array.prototype.map.call(elements, el => ({
    id: el.getAttribute("id"),
    name: el.getAttribute("name"),
    langCode: el.getAttribute("lang_code"),
    langOriginal: el.getAttribute("lang_original"),
    langTranslated: el.getAttribute("lang_translated"),
    langDefault: el.getAttribute("lang_translated") === "true"
  }));
}

type TimedTextParams = {
  hl?: string,
  type?: string,
  name?: string
};

function fetchAPI(videoId: string, params: TimedTextParams): Promise<Object> {
  const qs = qsStringify({ ...params, v: videoId });

  /**
    April 4, 2020
    I believe that some network administrators can use G-Suite to add the "youtube-restrict" header
    to all requests to youtube domains from Chrome.

    See the email thread with jeanphilippe.bolle@cnddinant.be for an example

    This is google's recommendation for how to configure this in g-suide
      https://support.google.com/a/answer/6214622?hl=en

    For this reason, if the first request failed we fall back to proxying through the origin server.

    The https://video.google.com/ server is still a valid entry point, but it's not setup for CORS.
  */
  const corsBase = "https://www.youtube.com";
  const endpoint = "/api/timedtext?" + qs;

  // Try a CORS request to youtube first, then fallback to the captionpop proxy.
  return fetchXML(corsBase + endpoint).catch(error => {
    // These errors are only raised in fetchXML-browser, not the node version.
    if (
      error.name === "XMLHttpRequestNetworkError" &&
      document.location.origin === "https://www.captionpop.com"
    ) {
      console.warn(
        "Network request for timedtext failed, falling back to proxy"
      );
      return fetchXML(endpoint);
    } else {
      return Promise.reject(error);
    }
  });
}

export function fetchTimedText(
  videoId: string,
  params: TimedTextParams
): Promise<Array<TimedText>> {
  return fetchAPI(videoId, params).then(parseTimedTextXMLDoc);
}

export function fetchTimedTextList(
  videoId: string,
  params: { hl?: string } = {}
): Promise<Array<TimedTextTrack>> {
  return fetchAPI(videoId, { ...params, type: "list" }).then(
    parseTimedTextListXMLDoc
  );
}
