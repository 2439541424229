/* @flow */

import * as React from "react";

import { isLocalStorageAvailable } from "./safeStorage";
import { postJSON, putJSON } from "./apiFetch";
import { captureException } from "@sentry/browser";

import PlaybackInterval from "./PlaybackInterval";

import { debounce } from "lodash";
import type { LinkState } from "./useNavigation";
import type { ReferralSource } from "./useReferralSource";
import type { YouTubeVideo } from "./youtubeScraper";
import { channelIdFromUrl } from "./youtubeUtils";

type Props = {
  withIdToken: ?() => Promise<string>,
  videoPromise: Promise<YouTubeVideo>,
  targetLang: ?string,
  nativeLang: string,
  linkSource: LinkState | null,
  referralSource: ReferralSource | null,
  render: RenderProps => React.Node
};

type TrackerState = {
  trackerURL: string,
  videoItem: YouTubeVideo
};

type RenderProps = {
  onPlayerStateChange: (event: YT$StateEvent) => void
};

export default class WithPlaybackTracker extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.assignPlaybackTrackerURL();
    this.lastReportedDuration = 0;

    // TODO: It might be nice for this interval to start at 5s, 10s, 20s, and then top out at 30s
    this.playbackInterval = new PlaybackInterval(
      this.updatePlaybackTracker.bind(this),
      30000
    );

    this.debouncedUpdate = debounce(update, 1500, {
      leading: true,
      trailing: true
    });
  }

  debouncedUpdate: (idToken: ?string, url: string, params: Object) => void;

  playbackTrackerState: Promise<?TrackerState>;
  playbackInterval: PlaybackInterval;
  playbackDurationTracker: PlaybackDurationTracker;
  lastReportedDuration: number;

  componentDidUpdate(prevProps: Props) {
    if (this.props.videoPromise !== prevProps.videoPromise) {
      this.assignPlaybackTrackerURL();
    }

    // If login status has changed
    if ((this.props.withIdToken == null) !== (prevProps.withIdToken == null)) {
      this.updatePlaybackTracker();
    }
  }

  assignPlaybackTrackerURL() {
    const idTokenPromise = this.maybeIdToken();

    this.playbackTrackerState = this.props.videoPromise.then(
      videoItem => {
        return idTokenPromise.then(idToken =>
          postJSON(
            idToken,
            "/api/playbacks",
            this.paramsForPlaybackTracker(videoItem)
          ).then(
            response => ({ trackerURL: response.location, videoItem }),
            error => {
              if (
                !(
                  typeof error.jsonBody === "object" &&
                  error.jsonBody.code == "crawler"
                )
              ) {
                captureException(error);
              }
              return null;
            }
          )
        );
      },
      error => null
    );

    this.playbackDurationTracker = new PlaybackDurationTracker();
  }

  componentWillUnmount() {
    this.updatePlaybackTracker();

    this.playbackInterval.destroy();

    // $FlowFixMe: The lodash debounce definitions don't include the flush function
    this.debouncedUpdate.flush();

    // This is just to compensate for the fixme above.
    (this.debouncedUpdate: Function);
  }

  updatePlaybackTracker() {
    Promise.all([this.playbackTrackerState, this.maybeIdToken()]).then(
      ([trackerState, idToken]) => {
        if (trackerState) {
          const params = this.paramsForPlaybackTracker(trackerState.videoItem);

          if (
            params.duration > 0 &&
            params.duration != this.lastReportedDuration
          ) {
            this.lastReportedDuration = params.duration;
            this.debouncedUpdate(idToken, trackerState.trackerURL, params);
          }
        }
      }
    );
  }

  paramsForPlaybackTracker(item: YouTubeVideo) {
    return {
      videoId: item.videoId,
      channelId: channelIdFromUrl(item.channel.href),
      targetLang: this.props.targetLang,
      nativeLang: this.props.nativeLang,
      linkSource: this.props.linkSource,
      referralSource: this.props.referralSource,
      referrer: document.referrer,
      duration: this.playbackDurationTracker.getCurrentDuration(),
      deviceId: getDeviceId()
    };
  }

  maybeIdToken(): Promise<?string> {
    if (this.props.withIdToken) {
      return this.props.withIdToken().catch(() => null);
    } else {
      return Promise.resolve(null);
    }
  }

  onPlayerStateChange(event: YT$StateEvent) {
    this.playbackDurationTracker.onPlayerStateChange(event);
    this.playbackInterval.onPlayerStateChange(event);
  }

  render() {
    return this.props.render({
      onPlayerStateChange: this.onPlayerStateChange.bind(this)
    });
  }
}

function update(idToken: ?string, url: string, params: Object) {
  putJSON(idToken, url, params).catch(e => captureException(e));
}

class PlaybackDurationTracker {
  constructor() {
    this.playbackStartedAt = null;
    this.playbackDuration = 0;
  }

  playbackStartedAt: ?number;
  playbackDuration: number;

  getCurrentDuration(): number {
    if (this.playbackStartedAt == null) {
      return this.playbackDuration;
    } else {
      const playbackStartedAt = this.playbackStartedAt;
      return this.playbackDuration + (Date.now() - playbackStartedAt);
    }
  }

  onPlayerStateChange(event: YT$StateEvent) {
    if (this.playbackStartedAt == null) {
      if (event.data === YT.PlayerState.PLAYING) {
        this.playbackStartedAt = Date.now();
      }
    } else {
      if (event.data !== YT.PlayerState.PLAYING) {
        this.playbackDuration = this.getCurrentDuration();
        this.playbackStartedAt = null;
      }
    }
  }
}

const deviceIdMax = Math.floor((Math.pow(2, 32) - 1) / 2);

function validDeviceId(deviceId: string): boolean {
  const parsed = parseInt(deviceId);
  return parsed >= 0 && parsed < deviceIdMax;
}

function getDeviceId(): ?number {
  try {
    if (isLocalStorageAvailable("getItem")) {
      const previousDeviceId = window.localStorage.getItem("deviceId");

      if (validDeviceId(previousDeviceId)) {
        return parseInt(previousDeviceId);
      } else {
        const newDeviceId = Math.floor(Math.random() * Math.floor(deviceIdMax));

        window.localStorage.setItem("deviceId", newDeviceId);
        return newDeviceId;
      }
    } else {
      return null;
    }
  } catch {
    return null;
  }
}
