/* @flow */

import { sortBy } from "lodash";

import type { TimedText } from "./timedtext2";

export type MergedText = {
  start: number,
  duration: number,
  translations: Array<string>,
  transcription: string
};

function sortSubtitles<T: { start: number }>(list: Array<T>): Array<T> {
  return sortBy(list, i => i.start);
}

function prepareSubtitles(list) {
  return sortSubtitles(list).filter(i => i.text.trim() != "");
}

// This identifies the common time attributes for MergedText and TimedText
export type TimeBlock = {
  start: number,
  duration: number
};

function overlapPercentage(text1: TimeBlock, text2: TimeBlock): number {
  const durationOverlap =
    Math.min(text1.start + text1.duration, text2.start + text2.duration) -
    Math.max(text1.start, text2.start);

  return durationOverlap / Math.min(text1.duration, text2.duration);
}

/**
  If a translation has an overlap duration > 50% with any translation, connect it.
  If not, give it it's own chunk.
 */
export default function mergeSubtitles(
  transcriptions: Array<TimedText>,
  translations: Array<TimedText>
): Array<MergedText> {
  const orphanTranslations: Array<MergedText> = [];

  const step1: Array<MergedText> = prepareSubtitles(transcriptions).map(
    text => ({
      start: text.start,
      duration: text.duration,
      transcription: text.text,
      translations: []
    })
  );

  prepareSubtitles(translations).forEach(text => {
    const foundIndex = step1.findIndex(
      item => overlapPercentage(item, text) > 0.5
    );

    if (foundIndex === -1) {
      // Display the translation in the transcription field, because I think
      // having an empty transcription will look weird, and hopefully this is
      // a rare thing.
      orphanTranslations.push({
        start: text.start,
        duration: text.duration,
        transcription: text.text,
        translations: []
      });
    } else {
      step1[foundIndex].translations.push(text.text);
    }
  });

  return sortSubtitles(step1.concat(orphanTranslations));
}

// subtitles should be sorted by startTime.
export function findActiveSubtitle(
  startIndex: number,
  subtitles: Array<MergedText>,
  currentTime: number
): number {
  let index = startIndex;

  for (; index < subtitles.length; index++) {
    if (subtitles[index].start > currentTime) {
      break;
    }
  }

  return index > 0 ? index - 1 : 0;
}
