/* @flow */

const providers = {
  // https://developers.google.com/identity/protocols/oauth2/web-server
  google: {
    label: "Google",
    authorizationUrl: "https://accounts.google.com/o/oauth2/v2/auth",
    tokenUrl: "https://oauth2.googleapis.com/token",
    scope: "profile email",
    errorMessageField: "error_description",
    client_id: process.env.GOOGLE_OAUTH2_CLIENT_ID || "",
    client_secret: process.env.GOOGLE_OAUTH2_CLIENT_SECRET || ""
  },
  // https://developers.facebook.com/docs/facebook-login/manually-build-a-login-flow/
  facebook: {
    label: "Facebook",
    authorizationUrl: "https://www.facebook.com/v4.0/dialog/oauth",
    tokenUrl: "https://graph.facebook.com/v4.0/oauth/access_token",
    scope: "email",
    errorMessageField: "error.message",
    client_id: process.env.FACEBOOK_OAUTH2_CLIENT_ID || "",
    client_secret: process.env.FACEBOOK_OAUTH2_CLIENT_SECRET || ""
  },
  // https://auth0.com/blog/what-is-sign-in-with-apple-a-new-identity-provider/
  apple: {
    label: "Apple",
    authorizationUrl: "https://appleid.apple.com/auth/authorize",
    tokenUrl: "https://appleid.apple.com/auth/token",
    scope: "",
    errorMessageField: "error.message",
    client_id: process.env.APPLE_OAUTH2_CLIENT_ID || "",
    client_secret: process.env.APPLE_OAUTH2_CLIENT_SECRET || ""
  }
};

/*::
export type OAuth2Provider = $Keys<typeof providers>;
*/

function castToProviderName(providerName /*: string*/) /*: OAuth2Provider*/ {
  // TODO: We shouldn't have to list every oauth provider here, but I don't think flow
  // supports a more dynamic type refinement.
  if (
    providerName === "google" ||
    providerName === "facebook" ||
    providerName === "apple"
  ) {
    return providerName;
  } else {
    throw new Error(
      `${providerName} is not a recognized authentication provider`
    );
  }
}

module.exports = {
  providers,
  castToProviderName
};
