/* @flow */

// 0..5
export const spacers: Array<string> = [0, 0.25, 0.5, 1, 1.5, 3].map(
  i => `${i * 16}px`
);

export const colors = {
  purple: "#6937FF",
  "purple-10": "#F0E8FF",
  "purple-20": "#D7C7FE",
  "purple-30": "#BCA1FF",
  "purple-40": "#9F79FF",
  "purple-50": "#8558FF",

  black: "#121212",
  "black-5": "#F9F9FA",
  "black-10": "#E8E8E8",
  "black-20": "#DBDDE0",
  "black-40": "#CACCCF",
  "black-60": "#A0A4A8",
  "black-80": "#52575C",

  textPrimary: "#121212",
  textSecondary: "#424242",

  inactive: "#757575",
  textField: "#BDBDBD",

  background: "#F9F9FA",

  divider1: "#E8EDF4",
  divider2: "#e8e8e8",

  // Colors taken from Figma designs.
  blue: "#886cff",
  orange: "#f2994a",

  // Color names moved from old colors.js file.
  highlight: "#18BC9C",
  warning: "#f05f74",
  paleGrey: "#dbdde9",
  greyishBrown: "#434343",
  dark: "#1f233d",
  pageBackground: "#fafafa"
};

export const fontFamily = "Inter, sans-serif";

export const typography = {
  h1: {
    fontWeight: 400,
    fontSize: "52px",
    lineHeight: "64px",
    letterSpacing: "0.2px",
    fontFamily: fontFamily
  },
  h2: {
    fontWeight: 500,
    fontSize: "44px",
    lineHeight: "54px",
    letterSpacing: "0.0px",
    fontFamily: fontFamily
  },
  h3: {
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "40px",
    letterSpacing: "0.1px",
    fontFamily: fontFamily
  },
  h4: {
    fontWeight: 500,
    fontSize: "26px",
    lineHeight: "32px",
    letterSpacing: "0.2px",
    fontFamily: fontFamily
  },
  h5: {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "26px",
    letterSpacing: "0.2px",
    fontFamily: fontFamily
  },
  h6: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0.2px",
    fontFamily: fontFamily
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    fontFamily: fontFamily
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.1px",
    fontFamily: fontFamily
  },
  body1: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    fontFamily: fontFamily
  },
  body2: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.1px",
    fontFamily: fontFamily
  },
  small1: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    fontFamily: fontFamily
  },
  small2: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    fontFamily: fontFamily
  },
  small3: {
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "14px",
    letterSpacing: "0.2px",
    fontFamily: fontFamily
  },
  button: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    fontFamily: fontFamily
  },
  initials: {
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    fontFamily: fontFamily
  }
};

export function spacingCss(props: {
  mb?: number,
  mt?: number,
  mr?: number,
  ml?: number,
  pt?: number,
  pb?: number,
  pl?: number,
  pr?: number,
  padding?: number,
  margin?: number
}): string {
  let cssString = "";

  const styleMap = {
    mb: "margin-bottom",
    mt: "margin-top",
    mr: "margin-right",
    ml: "margin-left",
    pt: "padding-top",
    pb: "padding-bottom",
    pl: "padding-left",
    pr: "padding-right",
    padding: "padding",
    margin: "margin"
  };

  Object.keys(styleMap).forEach(key => {
    const spaceIndex = props[key];
    if (spaceIndex != null) {
      cssString += `${styleMap[key]}: ${spacers[spaceIndex]};`;
    }
  });

  return cssString;
}
