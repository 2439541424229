/* @flow */

import * as React from "react";
import { useState } from "react";
import { lighten } from "polished";
import { providers as oauth2Providers } from "./oauth2Providers";
import { stringify as qsStringify } from "qs";
import styled, { css } from "styled-components";
import {
  buttonBackgroundColorWithHover,
  borderRadius,
  vertPadding,
  horPadding,
  buttonHeight
} from "./Buttons";

type Props = {
  nativeLang: string,
  textFn: (provider: string) => string,
  theme: "full" | "light",
  location: Location
};

export default function OAuth2Buttons(props: Props): React.Node {
  let redirectTo;
  if (props.location.pathname.startsWith("/oauth2-redirect")) {
    // If the user is hitting the retry on the oauth error page, we don't want to redirect there
    redirectTo = "/";
  } else {
    redirectTo = props.location.pathname + props.location.search;
  }

  return ["google", "facebook"].map(providerName => (
    <OAuth2Button
      textFn={props.textFn}
      key={providerName}
      nativeLang={props.nativeLang}
      providerName={providerName}
      theme={props.theme}
      state={redirectTo}
    />
  ));
}

const backgroundColors = {
  facebook: "#4267B2",
  google: "#4285F4",
  apple: "#333"
};

const IconWrapper = styled.div`
  display: block;
  background-color: ${props => props.color};
  float: left;
  padding: ${vertPadding}px 0;
  width: ${buttonHeight}px; // Should be a square

  text-align: center;
  svg {
    display: inline;
    vertical-align: text-top;
  }
`;

const lightTheme = css`
  border: 2px solid ${props => props.color};
  ${props => buttonBackgroundColorWithHover("#fff", false)}
  color: #333;
  max-width: 10em;
`;

const fullTheme = css`
  ${props => buttonBackgroundColorWithHover(props.color, false)}
  color: #fff;
  ${IconWrapper} {
    border-right: 1px solid ${props => lighten(0.1, props.color)};
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const OAuth2ButtonStyles = styled.a`
  display: block;
  font-size: 1rem;
  line-height: 1.5;

  margin-bottom: 1.25em;
  box-sizing: border-box;

  ${props => (props.theme === "full" ? fullTheme : lightTheme)}
  border: 1px solid ${props => props.color};

  border-radius: ${borderRadius}px;
  overflow: hidden; // hidden to keep children from bleeding over border-radius

  text-decoration: none;
  text-align: left;
  span {
    display: inline-block;
    padding: ${vertPadding}px ${horPadding}px;
  }
`;

function redirectUriForProdiver(location: Location, providerName: string) {
  return (
    location.protocol +
    "//" +
    location.host +
    "/oauth2-redirect/" +
    providerName
  );
}

function OAuth2Button(props: {
  nativeLang: string,
  textFn: (provider: string) => string,
  providerName: string,
  theme: "full" | "light",
  state: string
}) {
  // Note: The Location object that comes from App is unusable because it doesn't
  // contain the protocol and host
  const [locationWithHost] = useState<Location>(() => document.location);

  const provider = oauth2Providers[props.providerName];

  const href =
    provider.authorizationUrl +
    "?" +
    qsStringify({
      client_id: provider.client_id,
      redirect_uri: redirectUriForProdiver(
        locationWithHost,
        props.providerName
      ),
      response_type: "code",
      scope: provider.scope,
      state: props.state
    });

  const color = backgroundColors[props.providerName];
  return (
    <OAuth2ButtonStyles theme={props.theme} href={href} color={color}>
      <IconWrapper color={color}>{icons[props.providerName]}</IconWrapper>

      <span>{props.textFn(provider.label)}</span>
    </OAuth2ButtonStyles>
  );
}

const iconProps = {
  width: 16,
  height: 16,
  fill: "#fff"
};

// https://github.com/encharm/Font-Awesome-SVG-PNG/tree/master/black/svg
const icons = {
  apple: (
    <svg {...iconProps} viewBox="0 0 1792 1792">
      <path d="M1585 1215q-39 125-123 250-129 196-257 196-49 0-140-32-86-32-151-32-61 0-142 33-81 34-132 34-152 0-301-259-147-261-147-503 0-228 113-374 113-144 284-144 72 0 177 30 104 30 138 30 45 0 143-34 102-34 173-34 119 0 213 65 52 36 104 100-79 67-114 118-65 94-65 207 0 124 69 223t158 126zm-376-1173q0 61-29 136-30 75-93 138-54 54-108 72-37 11-104 17 3-149 78-257 74-107 250-148 1 3 2.5 11t2.5 11q0 4 .5 10t.5 10z" />
    </svg>
  ),
  facebook: (
    <svg {...iconProps} viewBox="0 0 1792 1792">
      <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z" />
    </svg>
  ),
  google: (
    <svg {...iconProps} viewBox="0 0 1792 1792">
      <path d="M896 786h725q12 67 12 128 0 217-91 387.5t-259.5 266.5-386.5 96q-157 0-299-60.5t-245-163.5-163.5-245-60.5-299 60.5-299 163.5-245 245-163.5 299-60.5q300 0 515 201l-209 201q-123-119-306-119-129 0-238.5 65t-173.5 176.5-64 243.5 64 243.5 173.5 176.5 238.5 65q87 0 160-24t120-60 82-82 51.5-87 22.5-78h-436v-264z" />
    </svg>
  )
};
