/* @flow */

function beforeSend(event /*: any */, hint /*: any*/) /*: any*/ {
  const error = hint.originalException;
  if (error) {
    if (error.name === "AbortError") {
      return null;
    }

    // This is an error causing my Google Translate, that we aren't able to filter from the sentry side.
    //   https://sentry.io/organizations/jonathan-baudanza/issues/1516474207/events/?cursor=0%3A300%3A0&project=1177889&sort=freq&statsPeriod=14d
    //   https://medium.com/@amir.harel/a-b-target-classname-indexof-is-not-a-function-at-least-not-mine-8e52f7be64ca
    //   https://github.com/getsentry/sentry-javascript/issues/2418
    if (
      typeof error.message === "string" &&
      error.message.indexOf(
        "a[b].target.className.indexOf is not a function."
      ) != -1
    )
      return null;

    if (error.name === "YouTubeParseError") {
      event.fingerprint = ["YouTubeParseError", error.fieldName, error.url];

      event.extra = {
        ...event.extra,
        fieldName: error.fieldName,
        url: error.url,
        html: error.html
      };

      return event;
    }

    if (error.name === "APIError") {
      event.fingerprint = [
        "APIError",
        error.method,
        error.url,
        String(error.code)
      ];

      // Convert the Headers object into something serializable
      const responseHeaders = Array.from(error.responseHeaders);

      event.extra = {
        ...event.extra,
        url: error.url,
        code: error.code,
        method: error.method,
        responseHeaders
      };

      if (error.jsonBody != null) {
        event.extra.jsonBody = error.jsonBody;
      }
      return event;
    }
  }
  return event;
}

module.exports = {
  dsn: process.env["SENTRY_DSN"],
  beforeSend: beforeSend
};
