/* @flow */

import * as React from "react";
import styled from "styled-components";

import PageFooter from "./PageFooter";

type Props = {
  nativeLang: string,
  children: React.Node,
  withHeader?: boolean
};

export default function ErrorPage(props: Props) {
  return (
    <React.Fragment>
      <PageContent withHeader={props.withHeader}>{props.children}</PageContent>
      <PageFooter nativeLang={props.nativeLang} />
    </React.Fragment>
  );
}

const PageContent = styled.div`
  text-align: center;
  max-width: 640px;
  margin: 0 auto;
  margin-top: ${props => (props.withHeader ? 85 : 25)}px;
  background-color: white;
  padding: 15px;
  border: 1px solid #efefef;
  color: #333;
`;

export const Header = styled.div`
  color: ${props => props.color};
  font-size: 150%;
  margin-bottom: 15px;
`;

export const ActionBar = styled.div`
  a {
    margin-left: 10px;
  }
`;
