/* @flow */

import * as React from "react";

export default function usePromise<T>(
  callback: () => T,
  conditions: Array<any>
): T | null {
  const ref = React.useRef(callback);
  React.useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const [promise, setPromise] = React.useState<T | null>(null);

  React.useEffect(() => {
    setPromise(ref.current());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, conditions);
  return promise;
}
