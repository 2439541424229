/* @flow */

import * as React from "react";

type Props = { text: string, elementName?: string, className?: string };

// This is a cheap way to wrap <B> tags around variables in MessageFormat strings.
// Ideally, we should have a more React friendly MessageFormat tool.
// NOTE: This doesn't do any HTML escaping on the input strings.
export default function TextWithBoldNumbers(props: Props) {
  const elementName = props.elementName == null ? "span" : props.elementName;

  return React.createElement(elementName, {
    className: props.className,
    dangerouslySetInnerHTML: {
      __html: props.text.replace(/[$:\d]+/g, makeBold)
    }
  });
}

function makeBold(innerText: string) {
  return `<b>${String(innerText)}</b>`;
}
