/* @flow */
import * as React from "react";
import styled from "styled-components";

import DelayedRender from "./DelayedRender";

export const loadingPlaceholderColor = "#efefef";

export const GreyText = styled.span`
  display: inline-block;
  background-color: ${loadingPlaceholderColor};
  vertical-align: sub;
  width: ${props => props.width}px;
  height: 1em;
`;

export const GreyRectangle = styled.div`
  &:before {
    content: "";
    display: block;
    padding-top: ${props => 100 / props.aspectRatio}%;
  }
  width: 100%;

  background-color: ${loadingPlaceholderColor};

  position: relative;
  img,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

type ThumbnailProps = {
  width: number,
  height: number,
  url: string,
  className?: string
};

export function ThumbnailPlaceholder(props: ThumbnailProps): React.Node {
  return (
    <GreyRectangle
      aspectRatio={props.width / props.height}
      className={props.className}
    >
      <img src={props.url} />
    </GreyRectangle>
  );
}

export default function LoadingTextPlaceholder(props: { width: number }) {
  return (
    <DelayedRender>
      <GreyText width={props.width} />
    </DelayedRender>
  );
}
