/* @flow */

import * as React from "react";
import styled from "styled-components";

import WithPromise from "../WithPromise";
import LoadingTextPlaceholder from "../LoadingTextPlaceholder";
import { stringsForLocale } from "../../lang/web";
import formatCurrency from "../formatCurrency";
import { TransparentRoundedActionButton } from "../Buttons";
import { spacers, typography, colors } from "../theme";
import { Row, Column } from "../BootstrapGrid";

import type { PremiumSubscription } from "../api";
import type { SnackbarMessage } from "../useSnackbarQueue";
import type { RenderProps as SubscriptionProps } from "../WithPremiumSubscription";

type Props = {
  nativeLang: string,
  subscriptionProps: SubscriptionProps,
  onAddSnackbarMessage: SnackbarMessage => void,
  onNavigate: string => void
};

export default function CurrentPlanSection(props: Props) {
  const dateFormat = new Intl.DateTimeFormat();
  const strings = stringsForLocale(props.nativeLang);

  return (
    <React.Fragment>
      <h2>{strings.account_page.current_plan_header()}</h2>

      <WithPromise
        promise={props.subscriptionProps.subscription}
        renderRejected={error => null}
        renderPending={() => <LoadingTextPlaceholder width={200} />}
        renderResolved={subscription => (
          <SubscriptionDetails>
            {stringForSubscriptionDetails(subscription, strings)}
          </SubscriptionDetails>
        )}
      />
      <WithPromise
        promise={props.subscriptionProps.subscription}
        renderRejected={error => null}
        renderPending={() => (
          <React.Fragment>
            <div>
              <LoadingTextPlaceholder width={180} />
            </div>
            <div>
              <LoadingTextPlaceholder width={200} />
            </div>
            <div>
              <LoadingTextPlaceholder width={160} />
            </div>
          </React.Fragment>
        )}
        renderResolved={subscription => {
          if (subscription.subscription) {
            const period = subscription.subscription;
            const periodEndStr = dateFormat.format(
              new Date(period.current_period_end * 1000)
            );

            const onCancel = () => {
              props.subscriptionProps
                .updateSubscription({
                  cancel_at_period_end: true
                })
                .then(() => {
                  props.onNavigate("/cancel-subscription");
                });
            };

            const onReactivate = () => {
              props.subscriptionProps
                .updateSubscription({
                  cancel_at_period_end: false
                })
                .then(() => {
                  props.onAddSnackbarMessage({
                    body: strings.account_snackbar_messages.subscription_reactivated(),
                    level: "message"
                  });
                });
            };

            const formattedPrice = formatCurrency(
              period.plan.amount,
              period.plan.currency
            );
            let planPriceString;
            switch (period.plan.interval) {
              case "month":
                planPriceString = strings.account_page.monthly_plan_pricing({
                  AMOUNT: formattedPrice
                });
                break;
              case "year":
                planPriceString = strings.account_page.yearly_plan_pricing({
                  AMOUNT: formattedPrice
                });
                break;
              default:
                planPriceString = "";
            }

            return (
              <React.Fragment>
                <Row>
                  <Column>{planPriceString}</Column>

                  {period.cancel_at_period_end ? (
                    <SubscriptionInfoColumn>
                      <div>
                        {strings.account_page.subscription_ends_on({
                          END_DATE: periodEndStr
                        })}
                      </div>
                    </SubscriptionInfoColumn>
                  ) : (
                    <SubscriptionInfoColumn>
                      <div>
                        {strings.account_page.subscription_renews_on({
                          RENEW_DATE: periodEndStr
                        })}
                      </div>
                    </SubscriptionInfoColumn>
                  )}
                </Row>

                <FormActions>
                  {period.cancel_at_period_end ? (
                    <TransparentRoundedActionButton onActivated={onReactivate}>
                      {strings.account_page.reactivate_subscription_action}
                    </TransparentRoundedActionButton>
                  ) : (
                    <TransparentRoundedActionButton onActivated={onCancel}>
                      {strings.account_page.cancel_subscription_action}
                    </TransparentRoundedActionButton>
                  )}
                </FormActions>
              </React.Fragment>
            );
          } else {
            return <div>{strings.account_page.free_plan()}</div>;
          }
        }}
      />
    </React.Fragment>
  );
}

function stringForSubscriptionDetails(subscription, strings): string {
  if (subscription.lifetime) {
    return strings.account_page.subscription_type_lifetime();
  } else if (subscription.active) {
    return strings.account_page.subscription_type_premium();
  } else {
    return strings.account_page.subscription_type_basic();
  }
}

const SubscriptionDetails = styled.div`
  ${typography.subtitle1}
  font-weight: 600;
  color: ${colors.blue};
  margin-bottom: ${spacers[3]};
`;

const SubscriptionInfoColumn = styled(Column)`
  color: ${colors.orange};
  ${typography.subtitle2}
`;

const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
