/* @flow */

import { getJSON, postJSON, putJSON } from "./apiFetch";

import { uniq } from "lodash";

import type {
  FlashCardDeck,
  Favorite,
  PremiumSubscription,
  PaymentSource,
  QuizAttempt,
  UnsavedQuizAttempt,
  TranslationResponse,
  UserResources,
  StripeInvoice,
  PricingPlan,
  Geocode
} from "./api";

import type { PasswordResetResponse } from "./passwordReset";
import type { AccountEmailAvailability } from "./authentication";
import type { RecommendationList } from "./recommendations";

type WithIdToken = () => Promise<string>;

export function putUser(withIdToken: WithIdToken, body: {}) {
  return withIdToken().then(idToken => putJSON(idToken, "/api/user", body));
}

export function getDemoFlashCards(): Promise<Array<Favorite>> {
  return getJSON(null, "/api/demo-flash-cards");
}

function filterNull<T>(list: Array<T>): Array<$NonMaybeType<T>> {
  return list.filter(i => i != null);
}

export function getAccountEmailAvailability(
  email: string,
  options: RequestOptions
): Promise<AccountEmailAvailability> {
  return getJSON(
    null,
    "/api/account/availability/" + encodeURIComponent(email),
    null,
    options
  );
}

export function getDemoDeckLanguages(): Promise<Array<string>> {
  return getDemoFlashCards().then(decks =>
    uniq(filterNull(decks.map(deck => deck.transcriptionLang)))
  );
}

export function getFlashCardDecks(
  withIdToken: WithIdToken
): Promise<Array<FlashCardDeck>> {
  return withIdToken().then(idToken =>
    getJSON(idToken, "/api/flash-cards/decks")
  );
}

export function getUserResources(
  withIdToken: WithIdToken
): Promise<UserResources> {
  return withIdToken().then(idToken => getJSON(idToken, "/api/user-resources"));
}

const subscriptionEndpoint = "/api/premium-subscription";

export function getPremiumSubscription(
  withIdToken: WithIdToken
): Promise<PremiumSubscription> {
  return withIdToken().then(idToken => getJSON(idToken, subscriptionEndpoint));
}

export function getPricingPlans(): Promise<Array<PricingPlan>> {
  return getJSON(null, subscriptionEndpoint + "/pricing");
}

export function getGeocode(): Promise<Geocode> {
  return getJSON(null, "/api/geocode");
}

export function putPaymentSource(
  withIdToken: WithIdToken,
  token: string
): Promise<PaymentSource> {
  return withIdToken().then(idToken =>
    putJSON(idToken, subscriptionEndpoint + "/source", { token })
  );
}

export function postSubscription(
  withIdToken: WithIdToken,
  plan: string,
  token: StripeToken
): Promise<PremiumSubscription> {
  return withIdToken().then(idToken =>
    postJSON(idToken, subscriptionEndpoint, { token: token.id, plan: plan })
  );
}

export function putSubscriptionCancellation(
  withIdToken: WithIdToken,
  body: Object
): Promise<PremiumSubscription> {
  return withIdToken().then(idToken =>
    putJSON(idToken, subscriptionEndpoint + "/cancellation", body)
  );
}

export function putPasswordUpdate(
  withIdToken: WithIdToken,
  values: {
    currentPassword: string,
    password: string,
    passwordConfirmation: string
  }
): Promise<{}> {
  return withIdToken().then(idToken =>
    putJSON(idToken, "/api/account", values)
  );
}

export function putPasswordReset(
  withIdToken: WithIdToken,
  values: {
    password: string,
    passwordConfirmation: string
  }
): Promise<{}> {
  return withIdToken().then(idToken =>
    putJSON(idToken, "/api/account", values)
  );
}

export function getQuizAttempts(
  withIdToken: WithIdToken
): Promise<Array<QuizAttempt>> {
  return withIdToken().then(idToken => getJSON(idToken, "/api/quiz-attempts"));
}

export function postQuizAttempt(
  withIdToken: WithIdToken,
  attempt: UnsavedQuizAttempt
): Promise<Object> {
  return withIdToken().then(idToken =>
    postJSON(idToken, "/api/quiz-attempts", attempt)
  );
}

export function postTranslation(
  withIdToken: WithIdToken,
  text: string,
  target: string
): Promise<TranslationResponse> {
  return withIdToken().then(idToken =>
    postJSON(idToken, "/api/translations", { text, target })
  );
}

export function postPasswordReset(
  emailAddress: string,
  nativeLang: string
): Promise<PasswordResetResponse> {
  return postJSON(null, "/api/password-resets", { emailAddress, nativeLang });
}

export function getInvoices(
  withIdToken: WithIdToken
): Promise<Array<StripeInvoice>> {
  return withIdToken().then(idToken =>
    getJSON(idToken, subscriptionEndpoint + "/invoices")
  );
}

export function postAccount(
  body: { email: string, password: string },
  options: RequestOptions
): Promise<{ token: string }> {
  return postJSON(null, "/api/account", body, options);
}

export function postSession(
  body: { email: string, password: string },
  options: RequestOptions
): Promise<{ token: string }> {
  return postJSON(null, "/api/sessions", body, options);
}

export function getRecommendations(
  withIdToken: ?WithIdToken,
  nativeLang: string,
  targetLang: string,
  userId: ?string
): Promise<RecommendationList> {
  const params: Object = { targetLang, nativeLang };
  if (userId != null) {
    params.userId = userId;
  }

  if (withIdToken == null) {
    return getJSON(null, "/api/recommendations", params);
  } else {
    return withIdToken().then(idToken => {
      return getJSON(idToken, "/api/recommendations", params);
    });
  }
}

export function postCancellationFeedback(
  idToken: string | null,
  values: { reason: string, other: string }
): Promise<{}> {
  return postJSON(idToken, "/api/cancellation-feedback", values);
}
