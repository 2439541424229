/* @flow */

import { darken } from "polished";
import styled, { css } from "styled-components";

import ActionLink from "./ActionLink";

import { colors, typography } from "./theme";

export const borderRadius = 4;

export const buttonHeight = 38;
const fontSize = 16;
const lineHeight = 1.5;
const borderSize = 1;
// Vertical padding is calculated to match the buttonHeight
export const vertPadding =
  (buttonHeight - fontSize * lineHeight - borderSize * 2) / 2;
export const horPadding = vertPadding * 2;

export const buttonSize = css`
  padding: ${vertPadding}px ${horPadding}px;
  font-size: ${fontSize}px;
  line-height: ${lineHeight};
`;

export const buttonStyles = css`
  ${buttonSize}
  border: none;
  text-decoration: none;
  text-align: center;
  border-radius: ${borderRadius}px;
  word-break: keep-all; /* Prevent Korean, Japanese, and Chinese from wrapping */

  ${props =>
    props.disabled
      ? css`
          opacity: 0.7;
          cursor: default;
        `
      : css`
          opacity: 1;
          cursor: pointer;
        `} ${props =>
    props.primary
      ? css`
          border: ${borderSize}px solid ${colors.highlight};
          ${buttonBackgroundColorWithHover(colors.highlight, props.disabled)};
          color: white;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        `
      : css`
          ${buttonBackgroundColorWithHover("#fff", props.disabled)} color: #333;
          border: ${borderSize}px solid #ddd;
        `};
`;

export function buttonBackgroundColorWithHover(
  color: string,
  disabled: boolean
): string {
  let hoverCss;
  if (disabled) {
    hoverCss = "";
  } else {
    hoverCss = `
      transition: background-color linear 150ms;
      &:hover {
        background-color: ${darken(0.05, color)};
      }
    `;
  }

  return `
    background-color: ${color};
    ${hoverCss}
  `;
}

export const SubmitButton = styled.input.attrs({ type: "submit" })`
  ${buttonStyles};
`;

export const ButtonLink = styled.a`
  display: inline-block;
  ${buttonStyles};
`;

export const ActionButton: typeof ActionLink = styled(ActionLink)`
  display: inline-block;
  ${buttonStyles};
`;

const RoundedActionButton = styled(ActionButton)`
  cursor: pointer;
  border-radius: 100px;
  padding: 10px 25px;
  ${typography.body1}
`;

export const TransparentRoundedActionButton = styled(RoundedActionButton)`
  background: none;
  border: 1px solid #bdbdbd;
  color: ${colors.blue};
`;

const RoundedButton = styled.button.attrs({ type: "submit" })`
  ${typography.body1}
  ${buttonStyles}
  cursor: pointer;
  border-radius: 100px;
  padding: 10px 25px;
`;

export const TransparentRoundedButton = styled(RoundedButton)`
  background: none;
  border: 1px solid #bdbdbd;
  color: ${colors.blue};
`;
