import styled from "styled-components";

import * as geometry from "./geometry";

export const PageContent = styled.div`
  margin-top: ${geometry.pageHeaderHeight + geometry.fontSize}px;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;

  h1 {
    color: #3a3a3a;
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
  }

  h2 {
    color: #b2b7bd;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    text-transform: uppercase;
  }
`;

export const Box = styled.div`
  background-color: #fafafa;
  border: 1px solid rgba(178, 183, 189, 0.23999999463558197);
  border-radius: 4px;
  padding: 1em;

  // clearfix
  &::after {
    content: "";
    clear: both;
    display: table;
  }
`;

export const StripeElementWrapper = styled.div`
  .StripeElement {
    background-color: white;
    height: 40px;
    padding: 10px 12px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`;
