
module.exports = {
  about_page: {
    headline: function(d) { return "CaptionPop은 YouTube로 언어를 배우기에 최적의 학습 도구입니다."; },
    feature_list: {
      "0": function(d) { return "동시에 여러 자막으로 시청할 수 있습니다."; },
      "1": function(d) { return "한 번의 키 입력으로 마지막 문장을 반복할 수 있습니다."; },
      "2": function(d) { return "동영상을 천천히 재생할 수 있습니다."; },
      "3": function(d) { return "자막을 즐겨찾기에 추가하고 나중에 공부할 수 있습니다."; },
      "4": function(d) { return "자막이 있는 모든 YouTube 동영상에 사용할 수 있습니다."; }
    },
    get_started_header: function(d) { return "시작하기"; },
    leave_feedback_header: function(d) { return "연락하세요!"; },
    leave_feedback_prompt: function(d) { return "업데이트에 대해 알림을 받거나 의견을 남기고 싶으세요?"; },
    leave_feedback_action: function(d) { return "안녕하세요"; },
    find_videos_action: function(d) { return "동영상 찾기"; },
    closing_paragraph: function(d) { return "저는 한국어 듣기 능력을 향상시키려는 목적으로 캡션팝을 개발했습니다. 정말 큰 도움이 되었으며, 여러분에게도 도움이 되었으면 하는 바람입니다."; },
    postscript: function(d) { return "PS: 질문, 의견 또는 기능 요청이 있을 경우 언제든 저에게 이메일을 보내주세요. 언어 학습자의 의견은 대환영입니다."; },
    get_started: {
      target_select_prompt: function(d) { return "배우려는 언어가 보이지 않으세요? CaptionPop에서는 YouTube가 지원하는 " + d.LENGTH + "개 언어를 모두 배울 수 있습니다. 아래에서 하나를 선택하세요!"; },
      target_select_placeholder: function(d) { return "언어를 선택하세요"; },
      my_native_language_is: function(d) { return "저의 모국어는 LANGUAGE이며 다음 언어를 배우고 싶습니다:"; }
    }
  },
  search_page: {
    page_title: function(d) { return "YouTube에서 " + d.TARGET_LANG + " 동영상을 검색해보세요"; },
    meta_description: function(d) { return "YouTube에서 " + d.NATIVE_LANG + "와 " + d.TARGET_LANG + " 자막이 모두 있는 동영상을 검색해보세요. 동시에 두 개의 자막으로 시청하세요."; },
    meta_description_generic: function(d) { return "YouTube에서 여러 자막이 있는 동영상을 검색해보세요. 동시에 두 개의 자막으로 시청하세요."; },
    filter_header: function(d) { return "자막 언어로 필터링하십시오."; },
    show_all: function(d) { return "모두 보기"; },
    more_action: function(d) { return "더 많이"; },
    end_of_results: function(d) { return "검색 결과의 끝"; },
    empty_search_results: {
      no_videos: function(d) { return "아무 동영상도 찾지 못했습니다."; },
      singular_video_dual_filter: function(d) { return "1 동영상을 찾았습니다, 하지만 " + d.FILTER_LANGUAGE_A + " 와(과) " + d.FILTER_LANGUAGE_B + " 의 자막은 없습니다."; },
      singular_video_single_filter: function(d) { return "1 동영상을 찾았습니다, 하지만 " + d.FILTER_LANGUAGE + " 의 자막은 없습니다."; },
      plural_videos_single_filter: function(d) { return d.VIDEO_COUNT + " 개의 동영상을 찾았습니다, 하지만 " + d.FILTER_LANGUAGE + " 의 동영상 언어자막은 없습니다."; },
      plural_videos_dual_filter: function(d) { return d.VIDEO_COUNT + " 개의 동영상을 찾았습니다, 하지만 " + d.FILTER_LANGUAGE_A + " 와(과) " + d.FILTER_LANGUAGE_B + " 의 동영상 언어자막은 없습니다."; }
    }
  },
  play_videos_page: {
    add_to_library_action: function(d) { return "라이브러리에 추가하기"; },
    remove_from_library_action: function(d) { return "라이브러리에서 제거하기"; },
    library_icon_label: function(d) { return "라이브러리"; },
    no_available_subtitles: function(d) { return "안타깝게도 이 영상에 이용할 수 있는 자막이 없습니다."; },
    translation_upgrade_prompt: function(d) { return "캡션팝 프리미엄 서비스는 하이라이트한 단어나 구를 구글 번역으로 바로 번역해 드리는 기능을 제공합니다."; },
    translation_upgrade_call_to_action: function(d) { return "업그레이드"; },
    blur_captions_label: function(d) { return "흐릿한 캡션"; },
    only_one_subtitle_language: function(d) { return "이 동영상의 자막은 " + d.LANGUAGE + "로만 제공됩니다."; },
    pause_after_caption_label: function(d) { return "한 캡션마다 정지"; }
  },
  favorites_page: {
    blur_captions_label: function(d) { return "흐릿한 " + d.LANGUAGE + " 캡션"; }
  },
  search_form: {
    search_header: function(d) { return "YouTube의 모든 동영상을 검색"; },
    filter_by_subtitle_header: function(d) { return "자막으로 필터링"; },
    filter_by_city_header: function(d) { return "도시로 필터링"; },
    tutorials_header: function(d) { return "캡션팝 튜토리얼"; },
    placeholder: function(d) { return d.LANGUAGE + " 검색어 또는 YouTube URL"; },
    change_language_link: function(d) { return "더 보기"; },
    featured_playlists_header: function(d) { return "추천 " + d.LANGUAGE + " 재생목록 탐색"; },
    featured_channels_header: function(d) { return "추천 " + d.LANGUAGE + " 채널 탐색"; },
    suggested_cities: function(d) { return "제안 도시"; },
    all_cities: function(d) { return "모든 도시"; },
    search_action: function(d) { return "검색"; },
    premium_button: function(d) { return "프리미엄 가입하기"; },
    tutorials_caption: function(d) { return "시작하는 데 도움이 필요하세요?"; },
    premium_cia: function(d) { return "캡션팝 프리미엄으로 학습 과정을 가속화하세요"; },
    chrome_extension_banner: {
      header: function(d) { return "YouTube.com에서 동영상을 둘러보며 캡션팝의 기능을 사용해 보세요."; },
      line1: function(d) { return "캡션팝 Chrome 확장 프로그램을 설치합니다."; },
      line2: function(d) { return "확장 프로그램을 설치하면 유튜브의 동영상에 해당 동영상에서 제공하는 자막 목록이 함께 표시됩니다. 자막 목록을 클릭하면 캡션팝 플레이어에 연결되어 동시에 여러 자막으로 동영상을 시청하실 수 있습니다."; },
      install_action: function(d) { return "설치하기"; }
    }
  },
  keyboard_shortcuts: {
    next_line_tooltip: function(d) { return "다음 행"; },
    previous_line_tooltip: function(d) { return "이전 행"; },
    toggle_playback_tooltip: function(d) { return "재생 / 일시정지"; },
    show_translation_tooltip: function(d) { return "번역 보기"; },
    repeat_tooltip: function(d) { return "반복"; }
  },
  page_header: {
    home_action: function(d) { return "홈"; },
    login_action: function(d) { return "로그인"; },
    logout_action: function(d) { return "로그아웃"; },
    favorites_link: function(d) { return "즐겨찾기"; },
    premium_action: function(d) { return "프리미엄 전환"; }
  },
  choose_languages: {
    select_native_lang_prompt: function(d) { return "당신의 모국어는 무엇입니까?"; },
    select_target_lang_prompt: function(d) { return "어떤 언어를 배우고 싶습니까?"; },
    update_action: function(d) { return "업데이트"; },
    featured_languages: function(d) { return "추천 언어"; },
    all_languages: function(d) { return "모든 언어"; },
    select_placeholder: function(d) { return "선택하기..."; }
  },
  page_footer: {
    about_link: function(d) { return "소개"; },
    contact_link: function(d) { return "문의"; },
    terms_of_service_link: function(d) { return "서비스 약관"; },
    privacy_policy_link: function(d) { return "개인정보보호 정책"; }
  },
  page_not_found: function(d) { return "페이지를 찾을 수 없습니다."; },
  caption_popup_options: {
    edit: function(d) { return "자막 수정"; },
    jump_to: function(d) { return "원본으로 돌아가기"; },
    remove: function(d) { return "즐겨찾기에서 삭제"; }
  },
  edit_caption_form: {
    header: function(d) { return "자막 수정"; },
    labels: {
      start_time: function(d) { return "대사 시작 시간"; },
      end_time: function(d) { return "대사 종료 시간"; },
      transcription: function(d) { return "대사"; },
      translations: function(d) { return "번역"; }
    },
    actions: {
      save_changes: function(d) { return "변경 내용 저장"; },
      nevermind: function(d) { return "취소"; }
    }
  },
  form_validations: {
    required: function(d) { return "필수 입력 사항"; },
    too_big: function(d) { return "동영상의 길이를 넘었습니다"; },
    comes_after_start: function(d) { return "대사 시작 시간을 먼저 입력해야 합니다"; }
  },
  navigation: {
    video_library_link: function(d) { return "비디오 라이브러리"; },
    favorite_captions_link: function(d) { return "즐겨찾는 자막"; },
    account_link: function(d) { return "계정"; },
    flash_cards_link: function(d) { return "플래시 카드들"; }
  },
  snackbar: {
    video_added_to_library: function(d) { return "이 영상을 영상 라이브러리에 추가하였습니다."; },
    video_removed_from_library: function(d) { return "이 영상이 영상 라이브러리에서 제거되었습니다."; },
    added_to_favorite_captions: function(d) { return "이 자막이 자막 즐겨찾기 리스트에 추가되었습니다."; },
    removed_from_favorite_captions: function(d) { return "이 자막이 자막 즐겨찾기 리스트에서 제거되었습니다."; },
    updated_favorite_caption: function(d) { return "변경사항이 저장되었습니다."; },
    network_error: function(d) { return "인터넷 연결을 확인해 주세요."; },
    playlist_added_to_library: function(d) { return "이 재생 목록이 라이브러리에 추가되었습니다."; },
    playlist_removed_from_library: function(d) { return "이 재생 목록이 라이브러리에서 삭제되었습니다."; },
    channel_added_to_library: function(d) { return "이 채널이 라이브러리에 추가되었습니다."; },
    channel_removed_from_library: function(d) { return "이 채널이 라이브러리에서 삭제되었습니다."; }
  },
  flash_cards: {
    days_count: function(d) { return d.COUNT + "일"; },
    logged_out_warning: function(d) { return "캡션팝에 로그인하여 진행 상황을 저장하세요. 그렇지 않으면, 브라우저를 닫을 때, 손실됩니다."; },
    splash_page: {
      title: function(d) { return "캡션팝: 인터랙티브 플래시 카드"; },
      introduction: function(d) { return "인터랙티브 플래시 카드를 사용해서 듣기 능력을 향상시켜 보세요."; },
      feature_list: {
        "0": function(d) { return "유튜브 동영상에서 저장한 자막으로 만든 플래시 카드와 함께 단어를 복습해보세요."; },
        "1": function(d) { return "입력하는 동시에 색색의 피드백을 받아보세요."; },
        "2": function(d) { return "어려운 지점이 있다면 힌트를 사용해서 헤쳐나가보세요."; },
        "3": function(d) { return "플래시 카드들의 점수는 자동으로 매겨집니다."; },
        "4": function(d) { return "주기적 반복 알고리즘이 나중에도 카드들을 복습할 수 있게 도와줄 겁니다."; }
      },
      calls_to_action: {
        header: function(d) { return "시작하실 준비가 되셨나요?"; },
        demo_decks_text: function(d) { return "이 데모 플래시 카드 덱 중 하나를 시도해 보세요."; },
        no_favorites: {
          text: function(d) { return "아니면 YouTube에서 발견하는 캡션으로 자신의 덱을 만드세요."; },
          button: function(d) { return "동영상 찾기"; }
        },
        logged_in_with_favorites: {
          text: function(d) { return "벌써 " + d.COUNT + "개의 자막을 저장하셨군요! 이제 자막의 내용을 플래시 카드로 상호작용하며 복습하실 수 있습니다."; },
          button: function(d) { return "지금 복습하기"; }
        }
      }
    },
    instructions: {
      finish_pass: function(d) { return "나중에 다시 시도해주세요."; },
      finish_complete: function(d) { return "잘하셨습니다!"; },
      playing_state: function(d) { return "잘 들어주세요."; },
      quiz_state: function(d) { return "방금 들으신 내용을 입력해보세요."; },
      before_playback: function(d) { return "들으시려면 재생 버튼을 눌러주세요."; }
    },
    hint_tip: function(d) { return "힌트를 보시려면 회색 박스를 클릭하세요"; },
    actions: {
      skip: function(d) { return "건너뛰기"; },
      finish: function(d) { return "마치기"; },
      more_to_review: function(d) { return d.COUNT + "개 더 복습 가능"; },
      replay: function(d) { return "다시 재생하기"; }
    },
    previous_attempts_list: {
      header: function(d) { return "시도한 횟수"; },
      hints_used: function(d) { return "사용한 힌트"; },
      time_takes: function(d) { return "걸린 시간"; },
      score: function(d) { return "점수"; }
    },
    finished: {
      edit_action: function(d) { return "수정"; },
      previous_attempts_header: function(d) { return "시도한 횟수"; },
      time_taken: function(d) { return "걸린 시간"; },
      hints_used: function(d) { return "사용한 힌트"; },
      due_again: function(d) { return "복습할 날짜"; },
      score: function(d) { return "점수"; }
    },
    dashboard: {
      header: function(d) { return "플래시 카드들"; },
      card_count: function(d) { return d.TOTAL_COUNT + "개 중 " + d.REVIEWED_COUNT + "개의 플래시 카드들이 최근 리뷰되었습니다."; },
      next_card_due: function(d) { return "다음 복습 예정까지 " + d.TIMER; },
      empty_deck: function(d) { return "이 덱은 비어있습니다."; },
      actions: {
        review_all: function(d) { return "전체 복습하기"; },
        review_random: function(d) { return "무작위 카드 하나 복습하기"; },
        review_count: function(d) { return d.COUNT + "개 복습"; },
        create_custom_deck: function(d) { return "사용자 지정 덱 만들기"; },
        edit_deck: function(d) { return "덱 수정하기"; },
        review_deck: function(d) { return "복습하기"; },
        search: function(d) { return "동영상 검색하기"; }
      },
      saved_captions_deck: function(d) { return "저장한 유튜브 자막"; },
      free_demo_deck_header: function(d) { return "플래시 카드 덱 무료 체험판"; },
      custom_deck_header: function(d) { return "커스텀 플래시 카드 덱"; },
      tip_header: function(d) { return "팁"; },
      search_videos_tip: function(d) { return "캡션팝의 모든 동영상으로 플래시 카드를 만들 수 있습니다."; },
      custom_deck_tip: function(d) { return "저장한 자막으로 나만의 학습 덱을 만들 수 있습니다."; },
      login_tip: function(d) { return "캡션팝 계정에 로그인하셔서 유튜브 동영상으로 플래시 카드를 만들어 보세요."; }
    },
    form: {
      create_header: function(d) { return "새로운 플래시 카드 덱 만들기"; },
      update_header: function(d) { return "플래시 카드 덱 수정하기"; },
      name_of_deck_header: function(d) { return "덱의 이름"; },
      select_captions_header: function(d) { return "자막 선택"; },
      selected_count: function(d) { return d.COUNT + "개 선택됨"; },
      select_multiple_captions: function(d) { return d.COUNT + "개의 자막 선택"; },
      unselect_multiple_captions: function(d) { return d.COUNT + "개의 자막 선택 해제"; },
      actions: {
        update: function(d) { return "덱 수정하기"; },
        create: function(d) { return "덱 새로 만들기"; },
        "delete": function(d) { return "덱 지우기"; }
      },
      confirmation: function(d) { return "확실하신가요?"; },
      snackbar: {
        created: function(d) { return "새로운 덱이 만들어졌습니다."; },
        deleted: function(d) { return "덱이 지워졌습니다."; },
        updated: function(d) { return "덱이 수정되었습니다."; },
        error: function(d) { return "덱을 저장하는 데에 문제가 발생했습니다."; }
      }
    },
    paywall_overlay: {
      header: function(d) { return "오늘의 무료 체험이 끝났습니다."; },
      text: function(d) { return "타이머가 끝나기를 기다려주시거나 캡션팝 프리미엄으로 업그레이드하여 무제한으로 이용하세요."; },
      call_to_action: function(d) { return "지금 업그레이드하기"; }
    },
    free_trial_banner: {
      flash_cards_remaining: function(d) { return "오늘 무료로 체험 가능한 카드 " + d.COUNT + "개 남음"; },
      upgrade_action: function(d) { return "업그레이드하기"; }
    },
    premium_subscription: {
      header: function(d) { return "캡션팝 프리미엄 구매하기"; },
      product_description: function(d) { return "가장 좋아하는 유튜브 동영상으로 만든 플래시 카드로 청취 이해력을 키워보세요!"; },
      available_currencies: function(d) { return "결제 가능 통화: "; },
      form_headers: {
        plan: function(d) { return "플랜을 선택해주세요."; },
        credit_card: function(d) { return "신용 카드"; },
        create_an_account: function(d) { return "계정 만들기"; }
      },
      calls_to_action: {
        choose: function(d) { return "선택"; },
        purchase: function(d) { return "구매하기"; }
      },
      plans: {
        month: {
          title: function(d) { return "매월"; },
          description: function(d) { return "매월 결제됩니다. 가장 싼 가격에요!"; },
          long_description: function(d) { return "결제하기를 클릭하시면 오늘 " + d.AMOUNT + "이(가) 결제되며, 매월 같은 날에 " + d.AMOUNT + "이(가) 결제됩니다. 단 한 번의 클릭으로 언제든지 해지하실 수 있습니다."; }
        },
        year: {
          title: function(d) { return "매년"; },
          description: function(d) { return "매년 결제됩니다. 월간 결제보다 " + d.PERCENT + "% 저렴한 가격!"; },
          long_description: function(d) { return "결제하기를 클릭하시면 오늘 " + d.AMOUNT + "이(가) 결제되며, 매년 같은 날에 " + d.AMOUNT + "이(가) 결제됩니다. 단 한 번의 클릭으로 언제든지 해지하실 수 있습니다."; }
        },
        lifetime: {
          title: function(d) { return "영구"; },
          description: function(d) { return "한 번 결제로 평생 사용!"; },
          long_description: function(d) { return "결제하기를 클릭하시면 오늘 " + d.AMOUNT + "이(가) 결제되며, 다시는 청구되지 않습니다."; }
        }
      },
      confirmation: {
        header: function(d) { return "고맙습니다!"; },
        paragraph1: function(d) { return "<b>캡션팝 프리미엄</b>에 오신 것을 환영합니다!"; },
        paragraph2: function(d) { return "이제 캡션팝의 주기적 반복 알고리즘이 적용된 플래시 카드들과 상호작용하며 무제한으로 복습하실 수 있습니다. 캡션팝이 제 청취 이해력에 많은 도움을 줬던 것처럼, 고객님에게 또한 도움이 되었으면 하는 바램입니다."; },
        paragraph3: function(d) { return "언제든 <a href=\"/account\">계정 페이지</a>에서 캡션팝 프리미엄을 취소하실 수 있습니다."; },
        creator_title: function(d) { return "캡션팝 제작자 올림"; },
        call_to_action: function(d) { return "플래시 카드"; },
        videos_action: function(d) { return "동영상 보기"; },
        flash_cards_action: function(d) { return "플래시 카드 학습하기"; }
      },
      errors: {
        banner_header: function(d) { return "구매가 완료되지 않았습니다."; },
        network_error: function(d) { return "인터넷 연결 문제로 구매가 완료되지 않았습니다."; },
        unexpected_error: function(d) { return "구매 진행 중 예기치 못한 문제가 발생했습니다. 귀하의 신용 카드는 결제되지 않았습니다. 캡션팝 개발자에게 해당 내용을 전달했습니다. 나중에 다시 시도해 주세요."; }
      },
      account: {
        choose_password: function(d) { return "비밀번호 설정"; },
        provider_signup: function(d) { return "다음 계정으로 가입"; },
        already_have_account: function(d) { return "이미 계정이 있으신가요?"; },
        logged_in_as: function(d) { return d.EMAIL + "로 로그인되어 있습니다."; },
        not_you: function(d) { return "본인이 아니신가요?"; }
      }
    }
  },
  premium_splash_page: {
    page_title: function(d) { return "캡션팝 프리미엄 서비스를 이용해 보세요."; },
    header1: function(d) { return "더욱 다양한 기능으로 학습 효율을 높여 보세요!"; },
    free_column_header: function(d) { return "무료"; },
    premium_column_header: function(d) { return "프리미엄"; },
    feature1: function(d) { return "복수의 자막으로 동영상 시청"; },
    feature2: function(d) { return "자막 언어별로 유튜브 검색"; },
    feature3: function(d) { return "하이라이트 번역 기능"; },
    feature4: function(d) { return "인터랙티브 플래시 카드"; },
    free_flash_card_limit: function(d) { return "하루 5개"; },
    header2: function(d) { return "하이라이트 번역 기능."; },
    google_translate_description: function(d) { return "하이라이트한 단어나 구가 모국어로 번역됩니다. 번역은 구글 번역을 사용합니다."; },
    header3: function(d) { return "무제한 듣기 플래시 카드."; },
    call_to_action: function(d) { return "프리미엄 가입하기"; }
  },
  login_form: {
    favorite_prompt: function(d) { return "로그인하여 좋아하는 자막을 저장할 수 있습니다."; },
    placeholders: {
      email: function(d) { return "이메일"; },
      password: function(d) { return "비밀번호"; },
      current_password: function(d) { return "현재 비밀번호"; },
      password_confirmation: function(d) { return "비밀번호 재입력"; }
    },
    forgot_password_link: function(d) { return "비밀번호 찾기"; },
    or: function(d) { return "또는"; },
    actions: {
      login: function(d) { return "로그인"; },
      signup: function(d) { return "가입하기"; },
      login_with_provider: function(d) { return d.PROVIDER + " 계정으로 로그인하기"; },
      signup_with_provider: function(d) { return d.PROVIDER + " 계정으로 가입하기"; }
    },
    errors: {
      invalid_credentials: function(d) { return "이메일 또는 비밀번호가 일치하지 않습니다."; },
      email_conflict: function(d) { return "이미 가입한 이메일입니다."; },
      unexpected_login_api_error: function(d) { return "로그인하는 중 문제가 발생했습니다."; },
      unexpected_signup_api_error: function(d) { return "계정을 만드는 중 문제가 발생했습니다."; },
      network_error: function(d) { return "인터넷 연결을 확인해 주세요."; }
    },
    library_prompt: function(d) { return "라이브러리에 항목을 저장할 수 있도록 로그인해 주세요."; }
  },
  password_reset_form: {
    instructions: function(d) { return "이메일을 입력해 주세요. 계정 복구 안내가 이메일로 전송됩니다."; },
    actions: {
      send_email: function(d) { return "이메일 보내기"; },
      ok: function(d) { return "확인"; }
    },
    finished_status: {
      instructions_sent: function(d) { return "안내가 " + d.EMAIL_ADDRESS + "(으)로 발송되었습니다."; },
      rate_limited: function(d) { return "안내가 이미 " + d.EMAIL_ADDRESS + "(으)로 발송되었습니다. 한 시간 뒤 다시 시도해 주세요."; },
      network_error: function(d) { return "서버 연결 중 문제가 발생했습니다. 인터넷 연결을 확인해 주세요."; },
      delivery_error: function(d) { return d.EMAIL_ADDRESS + "(으)로 안내를 발송하는 중 문제가 발생했습니다. 유효한 이메일 주소인지 확인해 주세요."; }
    }
  },
  password_reset_invalid_page: {
    header: function(d) { return "유효하지 않은 비밀번호 재설정 링크"; },
    text: function(d) { return "비밀번호 재설정 링크는 24시간 동안만 유효합니다."; },
    actions: {
      home: function(d) { return "홈"; },
      request_new: function(d) { return "새 링크 요청하기"; }
    }
  },
  change_password_page: {
    password_reset_instructions: function(d) { return "계정에 접속하시려면 새 비밀번호를 설정해 주세요."; },
    change_password_header: function(d) { return "비밀번호 변경하기"; },
    change_password_action: function(d) { return "비밀번호 변경"; },
    password_updated_confirmation: function(d) { return "비밀번호가 업데이트되었습니다."; },
    network_error: function(d) { return "인터넷 연결에 문제가 발생하여 비밀번호를 업데이트할 수 없습니다."; }
  },
  account_snackbar_messages: {
    logged_in: function(d) { return "로그인되었습니다."; },
    logged_out: function(d) { return "로그아웃되었습니다."; },
    signed_up: function(d) { return "계정이 생성되었습니다."; },
    subscription_cancelled: function(d) { return "구독이 취소되었습니다."; },
    subscription_reactivated: function(d) { return "구독이 다시 활성화되었습니다."; }
  },
  library_page: {
    search_action: function(d) { return "동영상 검색하기"; },
    headers: {
      playlists: function(d) { return "재생목록"; },
      channels: function(d) { return "채널"; },
      videos: function(d) { return "동영상"; }
    },
    empty_header: function(d) { return "라이브러리가 비어 있습니다."; },
    empty_description: function(d) { return "즐겨 찾는 동영상, 채널 및 재생 목록을 이곳에 저장할 수 있습니다."; }
  },
  account_page: {
    page_header: function(d) { return "내 계정"; },
    current_plan_header: function(d) { return "현재 플랜"; },
    free_plan: function(d) { return "무료"; },
    monthly_plan_pricing: function(d) { return "매월 " + d.AMOUNT; },
    yearly_plan_pricing: function(d) { return "매년 " + d.AMOUNT; },
    subscription_ends_on: function(d) { return d.END_DATE + "에 구독이 종료됩니다."; },
    subscription_renews_on: function(d) { return d.REENW_DATE + "에 구독이 자동으로 갱신됩니다."; },
    cancel_subscription_action: function(d) { return "구독 취소"; },
    reactivate_subscription_action: function(d) { return "구독 재활성화"; },
    subscription_type_basic: function(d) { return "캡션팝 베이식"; },
    subscription_type_premium: function(d) { return "캡션팝 프리미엄"; },
    subscription_type_lifetime: function(d) { return "캡션팝 평생 이용"; },
    language_settings_header: function(d) { return "언어 설정"; },
    native_language_header: function(d) { return "모국어"; },
    target_language_header: function(d) { return "목표어"; },
    native_language_update_confirmation: function(d) { return "모국어가 업데이트되었습니다."; },
    target_language_update_confirmation: function(d) { return "목표어가 업데이트되었습니다."; },
    payment_history_header: function(d) { return "결제 내역"; },
    receipt_link: function(d) { return "영수증"; },
    credit: function(d) { return "금액 " + d.AMOUNT; },
    credit_card_description: function(d) { return d.LAST4 + "(으)로 끝나는 " + d.CREDIT_CARD_BRAND; },
    credit_card_expires: function(d) { return "유효 기간 " + d.MONTH + "/" + d.YEAR; },
    payment_method_header: function(d) { return "결제 수단"; },
    update_payment_method_action: function(d) { return "신용 카드 업데이트"; },
    payment_method_update_confirmation: function(d) { return "결제 수단이 업데이트되었습니다."; },
    cancel_action: function(d) { return "취소"; },
    update_action: function(d) { return "업데이트"; },
    none: function(d) { return "없음"; }
  },
  cancel_subscription_page: {
    page_header: function(d) { return "구독이 취소되었습니다."; },
    confirmation: function(d) { return "감사합니다! 여러분의 피드백은 캡션팝을 개선하는 데 도움이 됩니다."; },
    paragraph1: function(d) { return "캡션팝 프리미엄 구독이 취소되었습니다. 현재 청구 주기가 끝날 때까지 캡션팝 프리미엄의 모든 기능을 이용하실 수 있습니다."; },
    paragraph2: function(d) { return "나중에 마음이 바뀌면 계정 페이지에서 언제든지 구독을 다시 활성화하실 수 있습니다."; },
    feedback_header: function(d) { return "구독을 취소하신 이유는 무엇인가요?"; },
    feedback_prompt: function(d) { return "취소 결정에 대한 피드백을 통해 캡션팝을 개선할 수 있도록 도와주세요."; },
    reason_too_expensive: function(d) { return "가격이 너무 비쌉니다."; },
    reason_not_useful: function(d) { return "캡션팝 프리미엄이 도움이 되지 않습니다."; },
    reason_other: function(d) { return "다른 이유"; },
    comments_header: function(d) { return "다른 의견이나 제안 사항이 있으신가요?"; },
    cancel_action: function(d) { return "아니요, 없습니다."; },
    submit_action: function(d) { return "피드백 제출"; },
    reason_required: function(d) { return "이유를 선택해 주세요."; }
  }
}