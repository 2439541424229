/* @flow */

import * as React from "react";
import * as geometry from "./geometry";
import { colors } from "./theme";
import styled from "styled-components";
import { Hamburger } from "./SvgAssets";

import ActionLink from "./ActionLink";
import { buttonStyles } from "./Buttons";
import WithPromise from "./WithPromise";
import Branding from "./Branding";
import { urlForHome } from "./urlForRoute";
import { useIsPremiumPromise } from "./WithUserResourceStore";

import type { UserResourceStore } from "./WithUserResourceStore";

export const domID = "page-header";

import { stringsForLocale } from "../lang/web";

import { playerZIndex } from "./playerGeometry";
export const pageHeaderZIndex = playerZIndex + 1;

type Props = {
  onLogin: () => void,
  onLogout: () => void,
  isLoggedIn: boolean,
  nativeLang: string,
  targetLang: ?string,
  hidePremiumButton?: boolean,
  userResources: ?UserResourceStore,
  fixed?: boolean,
  children?: React.Node,
  action?: React.Node
};

export default function PageHeader(props: Props): React.Node {
  let titleEl;
  if (props.children) {
    titleEl = props.children;
  } else {
    titleEl = <Branding size={16} className="branding-short" />;
  }

  const strings = stringsForLocale(props.nativeLang);
  const fixed = "fixed" in props ? props.fixed : true;

  const hamburgerLink = (
    <a href="#navigation" className="hamburger-link">
      <Hamburger />
    </a>
  );

  const isPremium = useIsPremiumPromise(props.userResources);

  return (
    <PageHeaderFull
      id={domID}
      fixed={fixed}
      hidePremiumButton={props.hidePremiumButton}
    >
      {hamburgerLink}

      <div className="branding-long">
        <a
          className="logo"
          href={urlForHome(props.nativeLang, props.targetLang)}
        >
          <Branding size={16} />
        </a>
      </div>

      <div className="title">{titleEl}</div>
      <div className="header-actions">
        {props.action}
        <WithPromise
          promise={isPremium}
          renderRejected={() => null}
          renderPending={() => null}
          renderResolved={isPremium =>
            isPremium || props.hidePremiumButton ? null : (
              <PremiumLink href="/premium-splash" primary>
                {strings.page_header.premium_action()}
              </PremiumLink>
            )
          }
        />
      </div>

      <div className="wide-viewport-only">
        <LoginButton {...props} className="login" />
      </div>
    </PageHeaderFull>
  );
}

type LoginButtonProps = {
  onLogin: Function,
  onLogout: Function,
  isLoggedIn: boolean,
  nativeLang: string,
  className?: string
};

export function LoginButton(props: LoginButtonProps) {
  const strings = stringsForLocale(props.nativeLang);

  if (props.isLoggedIn) {
    return (
      <ActionLink onActivated={props.onLogout} className={props.className}>
        {strings.page_header.logout_action()}
      </ActionLink>
    );
  } else {
    return (
      <ActionLink onActivated={props.onLogin} className={props.className}>
        {strings.page_header.login_action()}
      </ActionLink>
    );
  }
}

const hamburgerWidth = 75;

const fixedCss = `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${pageHeaderZIndex};
`;

const wideMediaQuery = "only screen and (min-device-width: 640px)";

const PageHeaderFull = styled.div`
  display: flex;
  background-color: white;
  box-shadow: 1px 1px 2px #ccc;
  height: ${geometry.pageHeaderHeight}px;
  line-height: ${geometry.pageHeaderHeight}px;
  padding-right: 10px;

  ${props => (props.fixed ? fixedCss : null)}

  a.login, a.logo {
    color: ${colors.dark};
    text-decoration: none;
    display: inline-block;
  }

  a.hamburger-link {
    display: block;
    width: ${hamburgerWidth}px;
    flex-shrink: 0;
    flex-grow: 0;

    svg {
      display: block;
      margin: 15px auto;
      fill: ${colors.dark};
    }
  }

  .logo {
    transition: opacity 250ms;
    &:hover {
      text-decoration: none;
      opacity: 0.8;
    }
  }

  .branding-short {
    display: block;
  }

  .branding-long {
    display: none;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .title {
    flex-grow: 1;
    flex-shrink: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .wide-viewport-only {
    display: none;
    flex-grow: 0;
    flex-shrink: 0;

    a.login {
      &:hover {
        text-decoration: underline;
      }
      margin: 0 20px;
    }
  }

  .premium-link {
    color: ${colors.highlight};
  }

  @media ${wideMediaQuery} {
    .wide-viewport-only {
      display: block;
    }

    .branding-long {
      display: block;
    }

    .branding-short {
      display: none;
    }
    .title {
      text-align: center;
    }
  }
`;

const PremiumLink = styled.a`
  ${buttonStyles}
  border-width: 2px;
  background-color: white;
  font-size: 75%;
  color: ${colors.highlight};
  white-space: nowrap;

  position: relative;
  top: -2px;

  @media ${wideMediaQuery} {
    ${buttonStyles}
    top: 0px;
  }
`;
