/* @flow */

import * as React from "react";

import styled from "styled-components";

import LoadingDots from "./LoadingDots";

import {
  ActionButton,
  horPadding as buttonPadding,
  borderRadius,
  buttonHeight
} from "./Buttons";

import { stringsForLocale } from "../lang/web";
import type { LibraryItem, UnsavedLibraryItem } from "./libraryItems";

type ToggleButtonState = "pending" | "in" | "out";

type Props = {
  buttonState: ToggleButtonState,
  onAddToVideoLibrary: () => void,
  onRemoveFromVideoLibrary: () => void,
  nativeLang: string
};

export default function LibraryToggleButton(props: Props) {
  const strings = stringsForLocale(props.nativeLang);
  const label = strings.play_videos_page.library_icon_label();

  let iconEl;
  let buttonProps;
  switch (props.buttonState) {
    case "in":
      buttonProps = {
        onActivated: props.onRemoveFromVideoLibrary
      };
      iconEl = (
        <IconWrapper on={true}>
          <CheckIcon />
        </IconWrapper>
      );
      break;
    case "pending":
      buttonProps = {
        onActivated: () => {},
        disabled: true
      };
      iconEl = (
        <IconWrapper on={false}>
          <PendingIcon />
        </IconWrapper>
      );
      break;
    case "out":
    default:
      buttonProps = {
        onActivated: props.onAddToVideoLibrary
      };

      iconEl = (
        <IconWrapper on={false}>
          <PlusIcon />
        </IconWrapper>
      );

      break;
  }

  return (
    <ToggleButtonStyles {...buttonProps}>
      {iconEl}
      <ButtonLabel>{label}</ButtonLabel>
    </ToggleButtonStyles>
  );
}

function includesMedia(
  mediaId: string,
  mediaType: string,
  list: Array<UnsavedLibraryItem>
) {
  return (
    list.findIndex(
      iter => mediaId === iter.mediaId && mediaType === iter.mediaType
    ) !== -1
  );
}

export function toggleButtonState(
  mediaId: string,
  mediaType: string,
  savedItems: Array<LibraryItem>,
  pendingItems: Array<UnsavedLibraryItem>
): ToggleButtonState {
  if (includesMedia(mediaId, mediaType, pendingItems)) {
    return "pending";
  } else if (includesMedia(mediaId, mediaType, savedItems.map(i => i))) {
    // This weird map is for flow's sake
    return "in";
  } else {
    return "out";
  }
}

const iconWrapperWidth = buttonHeight;

const ButtonLabel = styled.div``;

const IconWrapper = styled.div`
  ${props =>
    props.on
      ? "background-color: #ddd;"
      : "border-right: 1px solid #ddd;"} position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${iconWrapperWidth}px;
  border-top-left-radius: ${borderRadius}px;
  border-bottom-left-radius: ${borderRadius}px;

  // Center icon vertically
  display: flex;
  justify-content: center;
  flex-direction: column;

  // Center icon horizontally
  svg {
    margin: 0 auto;
  }

  // This is kind of a hacky way of centering the LoadingDots animation, because the animation
  // is this hacky css thing that doesn't really have a valid box model.
  .loading-dots {
    position: relative;
    top: -3px;
    left: 9px;
    margin: 0;
  }
`;

function PlusIcon(props) {
  return (
    <svg width="12px" height="12px" viewBox="0 0 12 12">
      <g stroke="#333333">
        <path d="M0,5 L10,5" />
        <path d="M5,0 L5,10" />
      </g>
    </svg>
  );
}

const PendingIcon = styled(LoadingDots).attrs({ color: "#333" })`
  margin: 0 auto;
`;

function CheckIcon(props) {
  return (
    <svg width="11px" height="7px" viewBox="0 0 11 7">
      <g stroke="#333">
        <polyline
          transform="translate(7.153846, 2.500000) scale(-1, 1) translate(-7.153846, -2.500000) "
          points="4.15384615 0 7.20172153 2.53989615 10.1538462 5"
        />
        <polyline points="0.692307692 2.5 2.21624538 4.02393769 3.69230769 5.5" />
      </g>
    </svg>
  );
}

// Width of iPhone 6/7/8 Plus
export const compactedMediaQuery = "(max-width: 414px)";

const ToggleButtonStyles: typeof ActionButton = styled(ActionButton)`
  position: relative;
  padding-left: ${buttonPadding + iconWrapperWidth}px;

  @media ${compactedMediaQuery} {
    padding: 0;

    ${ButtonLabel} {
      display: none;
    }
    ${IconWrapper} {
      position: static;
      height 2em;
      border: none;
      border-radius: ${borderRadius}px;
    }
  }
`;
