/* @flow */

import { tap, filter } from "rxjs/operators";

export function debug<T>(label: string): rxjs$MonoTypeOperatorFunction<T> {
  return tap({
    next(value) {
      console.log(label, value);
    },
    error(error) {
      console.error(label, error);
    },
    complete() {
      console.log(label, "complete");
    }
  });
}

export function filterNull<T>(): rxjs$OperatorFunction<T, $NonMaybeType<T>> {
  return filter(i => i != null);
}
