/* @flow */

import Overlay from "./Overlay";
import * as React from "react";
import styled from "styled-components";

import { stringsForLocale } from "../lang/web";

import ActionLink from "./ActionLink";
import Branding from "./Branding";
import { WithMostRecentPromise } from "./WithPromise";
import { WithMostRecentPromises } from "./WithPromises";
import { findDueCards } from "./quizAttempts";
import { urlForHome } from "./urlForRoute";
import { usePromiseValue } from "./useFlatPromise";

import { ButtonLink } from "./Buttons";
import type { Route } from "./router";

import type { UserResourceStore } from "./WithUserResourceStore";
import type { RenderProps as QuizAttemptRenderProps } from "./WithQuizAttempts";

type Props = {
  onClose?: string,
  targetLang: ?string,
  nativeLang: string,
  onNavigate: string => void,
  restricted: boolean,
  userResources: ?UserResourceStore,
  quizAttemptsProps: QuizAttemptRenderProps,
  restricted: boolean,
  onLogin: Function,
  onLogout: Function,
  route: Route,
  enter: boolean
};

export default function NavigationOverlay(props: Props) {
  const [now] = React.useState<number>(() => Date.now());

  const strings = stringsForLocale(props.nativeLang);

  const isPremiumPromise = React.useMemo<Promise<boolean>>(() => {
    if (props.userResources == null) {
      return Promise.resolve(false);
    } else {
      return props.userResources.premiumSubscription.subscription.then(
        subscription => subscription.active
      );
    }
  }, [props.userResources]);

  const isPremium = usePromiseValue(isPremiumPromise);

  let list;
  const flashCardsLabel = strings.navigation.flash_cards_link();

  const commonLinks = (
    <React.Fragment>
      <a href="/about">{strings.page_footer.about_link()}</a>
      <a target="_blank" href="mailto:jon@captionpop.com">
        {strings.page_footer.contact_link()}
      </a>
      <a href="/tos">{strings.page_footer.terms_of_service_link()}</a>
      <a href="/privacy">{strings.page_footer.privacy_policy_link()}</a>
    </React.Fragment>
  );

  if (props.userResources) {
    const userResources = props.userResources;

    list = (
      <NavigationActions>
        <a href={urlForHome(props.nativeLang, props.targetLang)}>
          {strings.page_header.home_action()}
        </a>
        <a href="/account">{strings.navigation.account_link()}</a>

        {props.restricted ? null : (
          <WithMostRecentPromise
            promise={userResources.favorites.favorites}
            renderRejected={() => null}
            renderPending={() => null}
            renderResolved={favorites => (
              <LinkWithCount
                href="/favorites"
                label={strings.navigation.favorite_captions_link()}
                count={favorites.length}
              />
            )}
          />
        )}

        <WithMostRecentPromise
          promise={userResources.videoLibrary.libraryItems}
          renderRejected={() => null}
          renderPending={() => null}
          renderResolved={libraryItems => (
            <LinkWithCount
              href="/video-library"
              label={strings.navigation.video_library_link()}
              count={libraryItems.length}
            />
          )}
        />

        <WithMostRecentPromises
          promises={[
            props.quizAttemptsProps.quizAttempts,
            userResources.favorites.favorites
          ]}
          renderPending={() => (
            <a href={flashCardsLink(0, 0)}>{flashCardsLabel}</a>
          )}
          renderResolved={([list, favorites]) => (
            <LinkWithCount
              href={flashCardsLink(list.length, favorites.length)}
              label={flashCardsLabel}
              count={findDueCards(now, favorites, list).length}
            />
          )}
          renderRejected={() => (
            <a href={flashCardsLink(0, 0)}>{flashCardsLabel}</a>
          )}
        />

        <ActionLink onActivated={props.onLogout}>
          {strings.page_header.logout_action()}
        </ActionLink>

        <hr />

        {commonLinks}
      </NavigationActions>
    );
  } else {
    list = (
      <NavigationActions>
        <a href="/">{strings.page_header.home_action()}</a>
        <a href={flashCardsLink(0, 0)}>{flashCardsLabel}</a>

        <ActionLink onActivated={props.onLogin}>
          {strings.page_header.login_action()}
        </ActionLink>

        <hr />
        {commonLinks}
      </NavigationActions>
    );
  }

  const upgradeCallToActionEl = isPremium ? null : (
    <UpgradeCallToAction>
      <ButtonLink href="/premium-splash" primary>
        {strings.page_header.premium_action()}
      </ButtonLink>
    </UpgradeCallToAction>
  );

  return (
    <Overlay {...props} orientation="left" scroll>
      <TopSection>
        <SubSection>
          <Branding size={16} />
        </SubSection>
      </TopSection>
      {list}
      {upgradeCallToActionEl}
    </Overlay>
  );
}

const UpgradeCallToAction = styled.div`
  padding: 1.5em;
  text-align: center;
`;

function flashCardsLink(quizAttemptCount, favoritesCount) {
  if (quizAttemptCount === 0 && favoritesCount === 0) {
    return "/flash-cards/splash";
  } else {
    return "/flash-cards";
  }
}

const padding = "1.5em";

const SubSection = styled.div`
  margin-bottom: 1em;
  padding: 0 ${padding};
`;

const TopSection = styled.div`
  padding-top: 1em;
  border-bottom: 1px solid #eee;
  a {
    color: #333;
  }
`;

const NavigationActions = styled.div`
  padding-top: 0.5em;

  a {
    display: block;
    text-decoration: none;
    line-height: 2.5em;
    padding-left: ${padding};
    color: #333;
  }

  .count {
    float: right;
    color: #aaa;
    margin-right: 20px;
  }

  a:hover {
    background-color: #efefef;
  }

  hr {
    border: 1px solid #efefef;
    border-bottom: none;
    margin-left: 1.5em;
    margin-right: 1em;
  }
`;

function LinkWithCount(props: { label: string, count: number, href: string }) {
  return (
    <a href={props.href}>
      {props.label}
      <span className="count">{props.count}</span>
    </a>
  );
}
