/* @flow */

import * as React from "react";
import type { DocumentLocation } from "./useNavigation";

// We can't use the useLocalStorage hook, because we need to differentiate
// between the key being missing and the key not being loaded yet.
// TODO: Actually, this probably can use useLocalStorage since I added the 4th boolean
// value to the return value.
import { safeLocalStorage } from "./safeStorage";
import { pick } from "lodash";

import parseSearchString, { removeFromSearch } from "./parseSearchString";

type Props = {
  location: DocumentLocation,
  onReplaceLocation: (string, ?Object, ?string) => void
};

// https://support.google.com/analytics/answer/1033863?hl=en
const relevantQueryParams = [
  "ref",
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content"
];

const storageKey = "referral-source";

export type ReferralSource = {
  ts: number,
  query: { [string]: string },
  referrer: string
};

export default function useReferralSource(
  props: Props
): [ReferralSource | null, boolean] {
  const query = pick(
    parseSearchString(props.location.search),
    relevantQueryParams
  );

  const [referralSource, setReferralSource] = React.useState<string | null>(
    null
  );

  // Take a sample of the timestamp
  const [ts, setTs] = React.useState<number | null>(null);
  React.useEffect(() => {
    setTs(Date.now());
  }, []);

  // Take a sample of document.referrer
  const [referrer, setReferrer] = React.useState<string | null>(null);
  React.useEffect(() => {
    setReferrer(document.referrer);
  }, []);

  // Take a sample of the document.location
  const [landingPage, setLandingPage] = React.useState<string | null>(null);
  React.useEffect(() => {
    setLandingPage(document.location.toString());
  }, []);

  let json;
  if (referrer != null && ts != null && landingPage != null) {
    json = JSON.stringify({ ts, referrer, query, landingPage });
  } else {
    json = null;
  }

  // This is used to make sure the location isn't cleaned until it's been
  // synced to localstorage.
  const [synced, setSynced] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (json == null) return;

    const existingValue = safeLocalStorage.getItem(storageKey);
    let referralSource;

    if (existingValue == null) {
      safeLocalStorage.setItem(storageKey, json);
      referralSource = json;
    } else {
      referralSource = existingValue;
    }
    setReferralSource(referralSource);
    setSynced(true);
  }, [json]);

  // This effect will clean the location of any tracking parameters.
  const newSearch = removeFromSearch(
    props.location.search,
    relevantQueryParams
  );
  let cleanedLocation = null;
  if (newSearch != props.location.search) {
    cleanedLocation = props.location.pathname + newSearch + props.location.hash;
  }

  const { onReplaceLocation } = props;
  const currentPathname = props.location.pathname;
  React.useEffect(() => {
    if (synced && cleanedLocation != null) {
      onReplaceLocation(cleanedLocation, currentPathname);
    }
  }, [synced, currentPathname, cleanedLocation, onReplaceLocation]);

  if (referralSource == null) {
    return [null, synced];
  } else {
    return [JSON.parse(referralSource), synced];
  }
}
