/* @flow */

const querystring = require("querystring");
const { isEmpty, clone, omit } = require("lodash");

function urlForHome(nativeLang /*: string */, targetLang /*: ?string */) {
  if (targetLang == null) {
    return "/?nl=" + nativeLang;
  } else {
    return urlForSearch({}, nativeLang, targetLang);
  }
}

// NOTE: If you update this, routes.txt should also be updated
const featuredLanguagePaths = {
  ko: "/korean",
  ja: "/japanese",
  en: "/english",
  fr: "/french",
  es: "/spanish",
  "es-419": "/spanish-419",
  de: "/german",
  "zh-CN": "/chinese",
  "zh-HK": "/chinese-hk",
  it: "/italian",
  ru: "/russian",
  ar: "/arabic"
};

function urlForSearch(
  params /*: Object */,
  nativeLang /*: ?string */,
  targetLang /*: ?string */
) /*: string */ {
  const filtered = omit(params, "nl", "tl");

  if (targetLang != null && targetLang in featuredLanguagePaths) {
    return (
      featuredLanguagePaths[targetLang] +
      searchString(filtered, nativeLang, null)
    );
  } else {
    return "/search" + searchString(filtered, nativeLang, targetLang);
  }
}

function urlForVideo(
  id /*: string */,
  nativeLang /*: ?string */,
  targetLang /*: ?string */
) /*: string */ {
  return `/videos/${id}` + searchString({}, nativeLang, targetLang);
}

function searchString(
  params /*: Object */,
  nativeLang /*: ?string */,
  targetLang /*: ?string */
) /*: string*/ {
  const q = clone(params);
  if (nativeLang) {
    q["nl"] = nativeLang;
  }
  if (targetLang) {
    q["tl"] = targetLang;
  }

  if (isEmpty(q)) {
    return "";
  } else {
    return "?" + querystring.stringify(q);
  }
}

module.exports = {
  urlForSearch,
  urlForVideo,
  urlForHome,
  featuredLanguagePaths
};
