/* @flow */

import type { IdTokenPayload } from "./authentication";
import { castToProviderName } from "./oauth2Providers";

export default function decodeIdToken(token: string): IdTokenPayload {
  const parts = token.split(".");
  if (parts.length !== 3) {
    throw new Error(`Expected 3 parts, got ${parts.length}`);
  }

  const json = atob(parts[1]);
  const payload = JSON.parse(json);

  let authentication /*: $PropertyType<IdTokenPayload, 'authentication'> */;
  if (payload.authentication === "password") {
    authentication = "password";
  } else {
    authentication = castToProviderName(payload.authentication);
  }

  if (
    typeof payload.authentication === "string" &&
    typeof payload.id === "number" &&
    typeof payload.sub === "string"
  ) {
    return {
      authentication: authentication,
      id: payload.id,
      sub: payload.sub
    };
  } else {
    throw new Error("unrecognized idtoken: " + json);
  }
}
