/* @flow */

import * as React from "react";
import styled from "styled-components";

import type { MergedText } from "./mergeSubtitles";

type Props = {
  subtitle: MergedText,
  showTranscription: boolean,
  showTranslation: boolean
};

export default function SubtitleText(props: Props) {
  return (
    <div className="subtitle-text">
      <BlurredText
        className="subtitle-transcription"
        blurred={!props.showTranscription}
        dangerouslySetInnerHTML={{ __html: props.subtitle.transcription }}
      />
      {props.subtitle.translations[0] !== props.subtitle.transcription ? (
        <BlurredText
          blurred={!props.showTranslation}
          dangerouslySetInnerHTML={{
            __html: props.subtitle.translations.join("<br/>")
          }}
        />
      ) : null}
    </div>
  );
}

const BlurredText = styled.p`
  filter: ${props => (props.blurred ? "blur(5px)" : "none")};

  &:hover {
    filter: none;
  }
`;
