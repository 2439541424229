/* @flow */

import * as React from "react";

import type { TimedTextTrack } from "./timedtext2";

export type TimedTextResponses = { [string]: Array<TimedTextTrack> };

import { makeCancellable } from "./Cancellable";
import type { Cancellable } from "./Cancellable";

import { captureException } from "@sentry/browser";

import { difference, omit, forEach } from "lodash";

import * as TimedText from "./timedtext2";

type Props = {
  videoIds: Array<string>,
  render: TimedTextResponses => React.Node
};

type State = {
  responses: TimedTextResponses
};

export default class WithTimedTextResponses extends React.Component<
  Props,
  State
> {
  constructor() {
    super();
    this.state = {
      responses: {}
    };

    this.subscriptions = {};
  }

  subscriptions: { [string]: Cancellable<Array<TimedTextTrack>> };

  componentDidMount() {
    this.syncSubscriptions();
  }

  componentWillUnmount() {
    forEach(this.subscriptions, subscription => {
      subscription.cancel();
    });
  }

  componentDidUpdate() {
    this.syncSubscriptions();
  }

  onResolved(videoId: string, value: Array<TimedTextTrack>) {
    this.setState(state => ({
      responses: {
        ...state.responses,
        [videoId]: value
      }
    }));
  }

  syncSubscriptions() {
    const subscribedIds = Object.keys(this.subscriptions);
    const idsToUnsubscribe = difference(subscribedIds, this.props.videoIds);
    const idsToSubscribe = difference(this.props.videoIds, subscribedIds);

    idsToSubscribe.forEach(videoId => {
      const subscription = makeCancellable(
        TimedText.fetchTimedTextList(videoId)
      );

      this.subscriptions[videoId] = subscription;

      subscription.promise.then(
        value => {
          this.onResolved(videoId, value);
        },
        error => {
          if (!error.isCancelled) {
            captureException(error);
          }
        }
      );
    });

    idsToUnsubscribe.forEach(videoId => {
      this.setState(state => ({
        responses: omit(state.responses, videoId)
      }));

      this.subscriptions[videoId].cancel();
      delete this.subscriptions[videoId];
    });
  }

  render() {
    return this.props.render(this.state.responses);
  }
}
