/* @flow */
import * as React from "react";

import { captureException } from "@sentry/browser";

type LoadingState = "pending" | "loaded" | "error";

export function useScriptTag(src: string): LoadingState {
  const [loadingState, setLoadingState] = React.useState<LoadingState>(
    "pending"
  );

  React.useEffect(() => {
    const scriptEl = document.createElement("script");
    scriptEl.src = src;
    scriptEl.async = true;

    if (document.head != null) {
      document.head.appendChild(scriptEl);
    }

    function onLoad(event: Event) {
      setLoadingState("loaded");
    }

    function onError(event: Event) {
      captureException(new Error("SCRIPT tag failed to load: " + src));
      setLoadingState("error");
    }

    scriptEl.addEventListener("load", onLoad);
    scriptEl.addEventListener("error", onError);

    return () => {
      scriptEl.removeEventListener("load", onLoad);
      scriptEl.removeEventListener("error", onError);
    };
  }, [src]);

  return loadingState;
}

export function useYouTubeIframeApi(): LoadingState {
  const [apiReady, setApiReady] = React.useState(false);

  React.useEffect(() => {
    window.onYouTubeIframeAPIReady = function() {
      setApiReady(true);
    };
  }, []);

  const loadingState = useScriptTag("https://www.youtube.com/iframe_api");

  if (loadingState === "loaded") {
    return apiReady ? "loaded" : "pending";
  } else {
    return loadingState;
  }
}

export function useStripeApi(): LoadingState {
  return useScriptTag("https://js.stripe.com/v3/");
}

export const StripeApiStatus = React.createContext<LoadingState>("pending");
export const YouTubeIframeApiStatus = React.createContext<LoadingState>(
  "pending"
);
