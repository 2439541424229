/* @flow */

import * as React from "react";

import { times } from "lodash";

const starSize = 32;
const starCount = 5;
const starPadding = 5;

export default function StarsSVG(props: { height: number, score: number }) {
  const list = scoreToStars(props.score);
  const viewWidth = starSize * starCount + starPadding * (starCount - 1);
  const viewHeight = starSize;

  const width = (props.height / viewHeight) * viewWidth;

  return (
    <svg
      width={width}
      height={props.height}
      viewBox={`0 0 ${viewWidth} ${viewHeight}`}
    >
      {list.map((fullness, i) => (
        <StarPath key={i} i={i} fullness={fullness} />
      ))}
    </svg>
  );
}

function scoreToStars(score: number): Array<number> {
  const start = times(Math.floor(score), () => 1);
  const end = times(Math.floor(starCount - score), () => 0);

  const middle = score - Math.floor(score);

  if (middle === 0) {
    return start.concat(end);
  } else {
    return start.concat(middle, end);
  }
}

function positionForStar(i: number) {
  if (i === 0) {
    return 0;
  } else {
    return i * starSize + (starPadding * i - 1);
  }
}

function StarPath(props: { i: number, fullness: number }) {
  let defs;
  let fill;

  if (props.fullness === 1) {
    defs = null;
    fill = "#888";
  } else if (props.fullness === 0) {
    defs = null;
    fill = "white";
  } else {
    const percent = props.fullness * 100 + "%";
    const id = "star-gradient-" + props.fullness;
    fill = `url(#${id})`;
    defs = (
      <defs>
        <linearGradient id={id}>
          <stop offset={percent} stopColor="#888" />
          <stop offset={percent} stopColor="white" />
        </linearGradient>
      </defs>
    );
  }

  return (
    <React.Fragment>
      {defs}

      <path
        fill={fill}
        transform={`translate(${positionForStar(props.i)} 0)`}
        stroke="#888"
        d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118
      l11.547-1.2L16.026,0.6L20.388,10.918z"
      />
    </React.Fragment>
  );
}
