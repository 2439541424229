/* @flow */

// This is an implementation of the SM-2 algorithm as defined here:
//   https://www.supermemo.com/english/ol/sm2.htm
//   https://stackoverflow.com/questions/49047159/spaced-repetition-algorithm-from-supermemo-sm-2/49047160#49047160

export type SuperMemoState = {
  interval: number,
  easiness: number,
  repetitions: number
};

export const superMemoDefault: SuperMemoState = {
  interval: 1,
  easiness: 2.5,
  repetitions: 0
};

export function iterateSuperMemo(
  prevState: SuperMemoState,
  quality: number
): SuperMemoState {
  const easiness = Math.max(
    1.3,
    prevState.easiness + incrementForQuality(quality)
  );
  const repetitions = quality < 3 ? 0 : prevState.repetitions + 1;

  let interval;
  if (repetitions <= 1) {
    interval = 1;
  } else if (repetitions === 2) {
    interval = 6;
  } else {
    interval = Math.round(prevState.interval * easiness);
  }

  return { easiness, interval, repetitions };
}

function incrementForQuality(quality: number): number {
  const inverse = 5.0 - quality;
  return 0.1 - inverse * (0.08 + inverse * 0.02);
}
