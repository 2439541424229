/* @flow */

function base64encode(string) {
  if ("btoa" in global) {
    return btoa(string);
  } else if ("Buffer" in global) {
    return new Buffer.from(string, "utf8").toString("base64");
  } else {
    return "";
  }
}

export function dataUrlForSvg(svgString: string): string {
  const contentType = "image/svg+xml";
  return `data:${contentType};base64,` + base64encode(svgString);
}
