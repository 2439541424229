/* @flow */

import * as React from "react";
import { ActionButton } from "./Buttons";

import type { UserResourceStore } from "./WithUserResourceStore";
import ErrorPage, { ActionBar } from "./ErrorPage";

import LoadingPage from "./LoadingPage";

import { stringsForLocale } from "../lang/web";

type Props = {
  nativeLang: string,
  isInitialized: boolean,
  userResources: ?UserResourceStore,
  onLogin: () => void,
  loggedOutView?: React.Node,
  render: (userResources: UserResourceStore) => React.Node
};

export default function WithLoginRequiredPage(props: Props) {
  const strings = stringsForLocale(props.nativeLang);

  if (props.userResources) {
    return props.render(props.userResources);
  } else {
    if (props.isInitialized) {
      if (props.loggedOutView) {
        return props.loggedOutView;
      } else {
        return (
          <ErrorPage nativeLang={props.nativeLang}>
            <p>{LocalizeMe("Login is required to access this page")}</p>

            <ActionBar>
              <ActionButton onActivated={props.onLogin}>
                {strings.page_header.login_action()}
              </ActionButton>
            </ActionBar>
          </ErrorPage>
        );
      }
    } else {
      return <LoadingPage />;
    }
  }
}

function LocalizeMe(string) {
  return string;
}
