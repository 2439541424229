/* @flow */
import * as React from "react";

import styled from "styled-components";
import { colors } from "./theme";

import { Close } from "./SvgAssets";
import WithSentryErrorBoundary from "./WithSentryErrorBoundary";

import { pageHeaderZIndex } from "./PageHeader";
const shadowIndex = pageHeaderZIndex + 1;

type OrientationType = "left" | "center";

type Props = {
  orientation: OrientationType,
  onClose?: string,
  className?: string,
  enter: boolean,
  children: React.Node,
  scroll?: boolean
};

class Overlay extends React.Component<Props> {
  _keyDownHandler: Function;
  ref: (?HTMLElement) => void;
  rootEl: ?HTMLElement;

  constructor() {
    super();
    this.ref = this.ref.bind(this);
  }

  ref(el: ?HTMLElement) {
    this.rootEl = el;
  }

  componentDidMount() {
    this._keyDownHandler = this.onKeyDown.bind(this);
    document.addEventListener("keydown", this._keyDownHandler);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this._keyDownHandler);
  }

  onKeyDown(event: KeyboardEvent) {
    if (event instanceof KeyboardEvent && event.keyCode === 27) {
      // escape
      event.preventDefault();
      if (this.rootEl) {
        const closeLinkEl = this.rootEl.querySelector("a.close-link");
        if (closeLinkEl) {
          closeLinkEl.click();
        }
      }
    }
  }

  render() {
    let closeLinkEl;
    if (this.props.onClose) {
      closeLinkEl = (
        <CloseLink href={this.props.onClose} className="close-link">
          <Close color="#bcbcbc" size={20} className="" />
        </CloseLink>
      );
    }

    let scroll;
    if ("scroll" in this.props) {
      scroll = this.props.scroll;
    } else {
      scroll = false;
    }

    const Content =
      this.props.orientation === "left" ? LeftAlignedContent : CenteredContent;

    const transition = { enter: this.props.enter };
    return (
      <StyledOverlay className={this.props.className} ref={this.ref}>
        <Content {...transition} scroll={scroll} className="content">
          {closeLinkEl}
          <WithSentryErrorBoundary
            // pathname is only used to reset the error state when the user navigates. We don't need
            // this functionality for Overlays
            pathname="/"
            renderError={() => "This overlay encountered an unexpected error"}
          >
            {this.props.children}
          </WithSentryErrorBoundary>
        </Content>
      </StyledOverlay>
    );
  }
}

const OverlayContent = styled.div`
  z-index: ${shadowIndex + 1};
  opacity: 1;
  background-color: white;
  color: ${colors.black};
  ${props => (props.scroll ? "overflow-y: scroll;" : null)};
`;

const transitionDuration = "100ms";

export const CenteredContent = styled(OverlayContent)`
  position: relative;
  border-radius: 6px;
  padding: 2em;
  width: 600px;

  transition: opacity ${transitionDuration} linear;
  opacity: ${props => (props.enter ? 1 : 0)};

  // This is kind of neat but it currently messed up the forgot password transition
  //transition: top ${transitionDuration} ease-out;
  //top: ${props => (props.enter ? 0 : 200)}px;
`;

export const LeftAlignedContent = styled(OverlayContent)`
  width: 240px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${props => (props.enter ? 0 : -240)}px;
  transition: left ${transitionDuration} ease-out;
  height: 100%;
`;

const StyledOverlay = styled.div`
  z-index: 999999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseLink = styled.a`
  display: block;
  text-align: center;
  color: #999;
  position: absolute;
  right: 17px;
  top: 17px;
`;

export const ShadowDiv = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  z-index: ${shadowIndex};

  opacity: ${props => (props.enter ? 0.85 : 0)};
  transition: opacity ${transitionDuration} linear;
`;

export function OverlayShadow(props: { enter: boolean }) {
  return <ShadowDiv enter={props.enter} />;
}

export default Overlay;
