/* @flow */

import * as React from "react";
import { isEqual } from "lodash";

import type { FormEvent } from "./Forms";

type Props<T> = {
  values: T,
  filterFunctions?: $Shape<$ObjMap<T, <V>(V) => FilterFunction>>
};

export type FormState<T> = {
  values: T,
  initialValues: T,
  onChange: (key: $Keys<T>, value: string) => void,
  onReset: () => void
};

export type FormUtils<T> = {
  changed: boolean,
  onChangeEvent: (key: $Keys<T>, event: FormEvent) => void,
  propsForInput: (key: $Keys<T>) => $Exact<PropsForInput>,
  // TODO: This should probably be $Exact too, but Flow is giving me a hassle now
  propsForRadio: (key: $Keys<T>, value: string) => PropsForRadio
};

type RenderFn<T> = (FormState<T> & FormUtils<T>) => React.Node;

type PropsForInput = {
  value: string,
  onChange: (event: FormEvent) => void,
  name: string,
  id: string
};

type PropsForRadio = PropsForInput & { checked: boolean };

type NormalizeFunction = string => string;
type FilterFunction = string => boolean;

type FormValues = { [string]: string };

let uniqueFormCounter = 0;

// This legacy render props is only used by the Admin tools
export default function WithFormState<T: FormValues>(
  props: Props<T> & {
    render: RenderFn<T>
  }
) {
  const formState = useFormState(props);
  const formUtils = useFormUtils(formState);

  return props.render({
    values: formState.values,
    initialValues: formState.initialValues,
    onChange: formState.onChange,
    onReset: formState.onReset,
    changed: formUtils.changed,
    onChangeEvent: formUtils.onChangeEvent,
    propsForInput: formUtils.propsForInput,
    propsForRadio: formUtils.propsForRadio
  });
}

// XXX: Duplicated in WithFormValidations
function nameFromEventTarget(event: Event): ?string {
  if (event.target instanceof HTMLTextAreaElement) {
    return event.target.name;
  } else if (event.target instanceof HTMLInputElement) {
    return event.target.name;
  } else {
    return null;
  }
}

export function useFormState<T: FormValues>(props: Props<T>): FormState<T> {
  // TODO: Type these better
  const [values, setValues] = React.useState(props.values);

  function onChange(key: $Keys<T>, value: string) {
    if (props.filterFunctions && props.filterFunctions[key]) {
      if (props.filterFunctions[key](value)) {
        setValue(key, value);
      }
    } else {
      setValue(key, value);
    }
  }

  function setValue(key: $Keys<T>, value: string) {
    setValues(oldValues => ({ ...oldValues, [key]: value }));
  }

  function onReset() {
    setValues(props.values);
  }

  return {
    initialValues: props.values,
    values: values,
    onChange: onChange,
    onReset: onReset
  };
}

// Provides utilities for rendering a form and wiring up event handlers. This doesn't
// actually track any form state.
export function useFormUtils<T: FormValues>(
  formProps: FormState<T>
): FormUtils<T> {
  const [uniqueFormId] = React.useState(() => uniqueFormCounter++);

  function onChangeEvent(key: $Keys<T>, event: FormEvent) {
    formProps.onChange(key, event.target.value);
  }

  function propsForInput(key: $Keys<T>): $Exact<PropsForInput> {
    return {
      onChange: onChangeEvent.bind(null, key),
      name: key,
      value: formProps.values[key],
      id: `${key}_${uniqueFormId}`
    };
  }

  function propsForRadio(key: $Keys<T>, value: string): PropsForRadio {
    return {
      checked: value === formProps.values[key],
      onChange: onChangeEvent.bind(null, key),
      name: key,
      value: value,
      id: `${key}_${value}_${uniqueFormId}`
    };
  }

  const changed = !isEqual(formProps.values, formProps.initialValues);

  return { onChangeEvent, propsForInput, propsForRadio, changed };
}

type NormalizerProps<T> = {
  values: T,
  normalizeFunctions?: $ObjMap<T, <V>(V) => NormalizeFunction>,
  onChange: (key: $Keys<T>, value: string) => void
};

export function useFormNormalizer<T: FormValues>(props: NormalizerProps<T>) {
  return (event: Event) => {
    const name = nameFromEventTarget(event);
    if (!name) return;

    if (props.normalizeFunctions && props.normalizeFunctions[name]) {
      props.onChange(name, props.normalizeFunctions[name](props.values[name]));
    }
  };
}
