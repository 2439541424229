/* @flow */

import * as React from "react";

import SentryErrorPage from "./SentryErrorPage";

import parseSearchString from "./parseSearchString";
import type { DocumentLocation } from "./useNavigation";

export function AuthCallbackPage() {
  return <div>authenticating..</div>;
}

export function AuthErrorPage(props: {
  nativeLang: string,
  location: DocumentLocation
}) {
  const params = parseSearchString(location.search);
  return (
    <SentryErrorPage
      nativeLang={props.nativeLang}
      eventId={params.eventId}
      error={null}
    />
  );
}
