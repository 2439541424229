/* @flow */

import * as React from "react";
import styled from "styled-components";

import { map } from "lodash";

import ActionLink from "./ActionLink";
import BrowserChrome from "./BrowserChrome";
import { ButtonLink } from "./Buttons";
import { Box, PageContent } from "./FormStyles";
import { ProgressCircle } from "./ProgressCircle";
import PageHeader from "./PageHeader";
import WithPromise from "./WithPromise";
import { hrefForFilter } from "./FlashCardsDashboardPage";
import { languageNameFromResponse } from "./youtubeUtils";
import { useThrottledAnimationFrame } from "./WithInterval";
import { dataUrlForSvg } from "./dataUrlForSvg";

import { getDemoDeckLanguages } from "./apiClient";
import { stringsForLocale } from "../lang/web";

import usePromise from "./usePromise";

import type { UserResourceStore } from "./WithUserResourceStore";

import PositioningWrapper from "./PositioningWrapper";
import useDynamicScrollPosition from "./useDynamicScrollPosition";

import mp4Url from "../assets/flash-card-demo.mp4";
import webmUrl from "../assets/flash-card-demo.webm";

type Props = {
  nativeLang: string,
  targetLang: ?string,
  onLogin: () => void,
  onLogout: () => void,
  userResources: ?UserResourceStore,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  isLoggedIn: boolean
};

export default function FlashCardsSplashPage(props: Props) {
  React.useEffect(() => {
    mixpanel.track("Viewed Flash Card Splash");
  }, []);
  const demoFlashCardsPromise = usePromise(() => getDemoDeckLanguages(), []);
  const favoritesCount = useFavoritesCount();

  return (
    <FlashCardsSplashView
      {...props}
      favoritesCount={favoritesCount}
      demoLanguages={demoFlashCardsPromise}
      youtubeLanguages={props.youtubeLanguages}
    />
  );
}

export function useFavoritesCount(
  userResources: ?UserResourceStore
): Promise<number> | null {
  return usePromise(() => countFavorites(userResources), [userResources]);
}

function countFavorites(userResources: ?UserResourceStore): Promise<number> {
  if (userResources) {
    return userResources.favorites.favorites.then(list => list.length);
  } else {
    return Promise.resolve(0);
  }
}

type ViewProps = {
  nativeLang: string,
  targetLang: ?string,
  onLogin: () => void,
  onLogout: () => void,
  favoritesCount: Promise<number> | null,
  demoLanguages: Promise<Array<string>> | null,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  userResources: ?UserResourceStore,
  isLoggedIn: boolean
};

export function FlashCardsSplashView(props: ViewProps) {
  const videoProps = useVideoPosition();

  const strings = stringsForLocale(props.nativeLang).flash_cards.splash_page;

  const scrollProps = useDynamicScrollPosition();

  // https://en.wikipedia.org/wiki/Spaced_repetition
  // https://ko.wikipedia.org/wiki/%EA%B0%84%EA%B2%A9_%EB%B0%98%EB%B3%B5
  // https://ja.wikipedia.org/wiki/%E9%96%93%E9%9A%94%E5%8F%8D%E5%BE%A9
  // https://zh.wikipedia.org/wiki/%E9%97%B4%E9%9A%94%E9%87%8D%E5%A4%8D
  // https://es.wikipedia.org/wiki/Repaso_espaciado
  // https://fr.wikipedia.org/wiki/R%C3%A9p%C3%A9tition_espac%C3%A9e

  return (
    <React.Fragment>
      <PositioningWrapper headerRetracted={scrollProps.headerRetracted}>
        <PageHeader
          onLogin={props.onLogin}
          onLogout={props.onLogout}
          nativeLang={props.nativeLang}
          targetLang={props.targetLang}
          isLoggedIn={props.isLoggedIn}
          userResources={props.userResources}
          enableMiniHeader={true}
          fixed={false}
        />
      </PositioningWrapper>
      <PageContent>
        <StyledBox>
          <Section>
            <h1>{strings.introduction()}</h1>
            <DemoVideo ref={videoProps.ref} />
          </Section>
          <Section>
            <FeatureList
              sampleTime={videoProps.sampleTime}
              onSeek={videoProps.onSeek}
              nativeLang={props.nativeLang}
              backgroundColor="#fff"
            />
          </Section>

          <Section>
            <h2>{strings.calls_to_action.header()}</h2>

            <CallsToAction
              nativeLang={props.nativeLang}
              youtubeLanguages={props.youtubeLanguages}
              favoritesCount={props.favoritesCount}
              demoLanguages={props.demoLanguages}
            />
          </Section>
        </StyledBox>
      </PageContent>
    </React.Fragment>
  );
}

export function _DemoVideo(props: {}, ref: React.Ref<"video">) {
  return (
    <BrowserChrome childAspectRatio={0.5625} maxWidth={405}>
      <video width="100%" autoPlay muted loop playsInline ref={ref}>
        <source src={mp4Url} type='video/mp4;codecs="avc1.64002A"' />
        <source src={webmUrl} type='video/webm;codecs="vp8"' />
      </video>
    </BrowserChrome>
  );
}

export const DemoVideo = React.forwardRef<{}, HTMLVideoElement>(_DemoVideo);

function DemoFlashCardDecks(props: {
  demoLanguages: Promise<Array<string>> | null,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  nativeLang: string
}) {
  const strings = stringsForLocale(props.nativeLang).flash_cards.splash_page;

  return (
    <WithPromise
      promise={props.demoLanguages}
      renderPending={() => null}
      renderRejected={() => null}
      renderResolved={demoLanguages => (
        <React.Fragment>
          <ActionText>{strings.calls_to_action.demo_decks_text()}</ActionText>
          <DemoLanguages>
            {demoLanguages.map(lang => (
              <li key={lang}>
                <a href={hrefForFilter({ demo: lang })}>
                  {languageNameFromResponse(lang, props.youtubeLanguages)}
                </a>
              </li>
            ))}
          </DemoLanguages>
        </React.Fragment>
      )}
    />
  );
}

type CallsToActionProps = {
  nativeLang: string,
  searchHref?: string,
  favoritesCount: Promise<number> | null,
  demoLanguages: Promise<Array<string>> | null,
  youtubeLanguages: YouTube$i18nLanguageListResponse
};

export function CallsToAction(props: CallsToActionProps) {
  const strings = stringsForLocale(props.nativeLang).flash_cards.splash_page;

  const searchLinkProps =
    props.searchHref == null
      ? { href: "/search" }
      : { href: props.searchHref, "data-skip-navigation": true };

  return (
    <WithPromise
      promise={props.favoritesCount}
      renderRejected={() => null}
      renderPending={() => null}
      renderResolved={count => {
        if (count === 0) {
          return (
            <React.Fragment>
              <DemoFlashCardDecks
                youtubeLanguages={props.youtubeLanguages}
                demoLanguages={props.demoLanguages}
                nativeLang={props.nativeLang}
              />
              <ActionText>
                {strings.calls_to_action.no_favorites.text()}
              </ActionText>
              <CallToAction>
                <ButtonLink {...searchLinkProps} primary>
                  {strings.calls_to_action.no_favorites.button()}
                </ButtonLink>
              </CallToAction>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              <ActionText>
                {strings.calls_to_action.logged_in_with_favorites.text({
                  COUNT: count
                })}
              </ActionText>

              <CallToAction>
                <ButtonLink href={hrefForFilter({})} primary>
                  {strings.calls_to_action.logged_in_with_favorites.button()}
                </ButtonLink>
              </CallToAction>
            </React.Fragment>
          );
        }
      }}
    />
  );
}

const DemoLanguages = styled.ul`
  margin-top: 0em;
  margin-bottom: 2em;
  padding: 0;
  list-style-type: none;

  > li {
    margin: 0 0.5em;
  }
  a {
    color: #333;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: row;
`;

const ActionText = styled.div`
  margin: 1em 0;
`;

const CallToAction = styled.div``;

function lineSvgAsData(startY, endY) {
  const svgString = `<svg viewBox="0 0 20 100" width="20" height="100" xmlns="http://www.w3.org/2000/svg">
    <line x1="10" y1="${startY}" x2="10" y2="${endY}" stroke="#ddd" />
    </svg>`;

  return dataUrlForSvg(svgString);
}

const firstLine = lineSvgAsData(10, 100);
const middleLine = lineSvgAsData(0, 100);
const lastLine = lineSvgAsData(0, 20);

const FeatureListItem = styled.li`
  list-style-type: none;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  padding-bottom: 1em;

  ${props => {
    switch (props.positioning) {
      case "first":
        return `
          background-image: url(${firstLine});
          background-repeat: no-repeat;
        `;
      case "last":
        return `
          background-image: url(${lastLine});
          background-repeat: no-repeat;
        `;
      case "middle":
      default:
        return `
        background-image: url(${middleLine});
        background-repeat: repeat-y;
      `;
    }
  }} a {
    text-decoration: none;
    color: ${props => (props.active ? "#333" : "#888")};
  }
  a:hover {
    color: #333;
  }

  position: relative;
  svg {
    position: absolute;
    top: 3px;
    left: 0px;
  }
  span {
    margin-left: 50px;
    display: block;
  }
`;

const FeatureListStyles = styled.div`
  max-width: 400px;
  margin: 0 auto;
  ul {
    text-align: left;
    margin: 0;
    padding: 0;
  }
`;

function FeatureCircle(props: { finished: boolean, backgroundColor: string }) {
  const colorProps = props.finished
    ? { fill: "#979797", stroke: "#979797" }
    : { fill: props.backgroundColor, stroke: "#ddd" };

  return (
    <svg viewBox="0 0 20 20" width="20px" height="20px">
      <circle cx="10" cy="10" r="9" strokeWidth={1} {...colorProps} />
    </svg>
  );
}

const featureTimestamps: Array<number> = [
  // Review flash cards based on clips you’ve saved from YouTube videos.
  0,
  // Get color-coded feedback as you type.
  5,
  // Ask for hints to guide you through sticking points.
  35,
  // Flash cards are automatically scored.
  46,
  // A spaced repetition algorithm will schedule the card to be reviewed again in the future.
  51.5,
  // end of video
  56
];

function featureIndexForTimestamp(timestamp: number): number {
  const found = featureTimestamps.findIndex(t => t > timestamp);
  if (found === -1) {
    return featureTimestamps.length - 1;
  } else {
    return found - 1;
  }
}

export function FeatureList(props: {
  sampleTime: () => number,
  onSeek: number => void,
  backgroundColor: string,
  nativeLang: string
}) {
  const strings = stringsForLocale(props.nativeLang).flash_cards.splash_page;

  const [currentTime, setCurrentTime] = React.useState(props.sampleTime);

  const fps = 20;
  useThrottledAnimationFrame(() => {
    setCurrentTime(props.sampleTime());
  }, 1000 / fps);

  const { activeIndex, progress } = animationPropsForTime(currentTime);

  return (
    <FeatureListStyles>
      <ul>
        {
          // strings.feature_lists should be an Array, but instead
          // it's an Object with numeric keys. It's not worth diving into
          // messagepack-loader to fix, so I'm just using the lodash
          // mapper function
        }
        {map(strings.feature_list, (text, indexString, list) => {
          const i = Number(indexString);
          return (
            <FeatureListItem
              key={indexString}
              active={i === activeIndex}
              positioning={positioningOfItem(i, Object.keys(list).length)}
            >
              <ActionLink
                onActivated={props.onSeek.bind(null, featureTimestamps[i])}
              >
                {i === activeIndex ? (
                  <ProgressCircle
                    size={20}
                    strokeWidth={10}
                    progress={progress}
                    fill={props.backgroundColor}
                  />
                ) : (
                  <FeatureCircle
                    finished={activeIndex > i}
                    backgroundColor={props.backgroundColor}
                  />
                )}

                <span>{text()}</span>
              </ActionLink>
            </FeatureListItem>
          );
        })}
      </ul>
    </FeatureListStyles>
  );
}

function positioningOfItem(i, length) {
  if (i === 0) return "first";
  else if (i === length - 1) return "last";
  else return "middle";
}

const Section = styled.div`
  margin: 3em 0;
  &:first-child {
    margin-top: 0;
  }
  text-align: center;
  h2 {
    color: #333;
    font-size: 1.5em;
    font-weight: bold;
    line-height: inherit;
    text-transform: none;
  }
`;

const StyledBox = styled(Box)`
  background-color: white;
`;

type VideoPositionProps = {
  onSeek: number => void,
  sampleTime: () => number,
  ref: { current: HTMLVideoElement | null }
};

export function useVideoPosition(): VideoPositionProps {
  const ref: { current: HTMLVideoElement | null } = React.useRef(null);

  const onSeek = React.useCallback((time: number) => {
    if (ref.current) {
      ref.current.currentTime = time;
    }
  }, []);

  const sampleTime = React.useCallback(() => {
    if (ref.current) {
      return ref.current.currentTime;
    } else {
      return 0;
    }
  }, []);

  return { ref, onSeek, sampleTime };
}

type VideoAnimationProps = {
  activeIndex: number,
  progress: number,
  duration: number
};

function animationPropsForTime(currentTime: number): VideoAnimationProps {
  const activeIndex = featureIndexForTimestamp(currentTime);

  const currentTimestamp = featureTimestamps[activeIndex];
  const nextTimestamp = featureTimestamps[activeIndex + 1];
  const progress =
    (currentTime - currentTimestamp) / (nextTimestamp - currentTimestamp);

  const duration = nextTimestamp - currentTime;

  return { activeIndex, progress, duration };
}
