/* @flow */

import * as React from "react";

const messageDuration = 5 * 1000;

type SnackbarQueueProps = {
  message: ?SnackbarMessage,
  addMessage: (message: SnackbarMessage) => void
};

export type SnackbarMessage = {
  body: string,
  level: "message" | "error"
};

export default function useSnackbarQueue(): SnackbarQueueProps {
  const [messageQueue, setMessageQueue] = React.useState<
    Array<SnackbarMessage>
  >([]);
  const runInterval = messageQueue.length > 0;
  React.useEffect(() => {
    if (runInterval) {
      const intervalId = setInterval(() => {
        setMessageQueue(current => current.slice(1));
      }, messageDuration);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [runInterval]);

  const addMessage = React.useCallback((message: SnackbarMessage) => {
    setMessageQueue(current => current.concat(message));
  }, []);

  return { addMessage, message: messageQueue[0] };
}
