/* @flow */

import * as React from "react";

import { captureException, withScope } from "@sentry/browser";

type Props = {
  children: React.Node,
  pathname: string,
  renderError: ErrorProps => React.Node
};

type State = {
  error: ?ErrorProps
};

type ErrorProps = {|
  error: Error,
  eventId: string
|};

export default class App extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = { error: null };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.pathname !== prevProps.pathname) {
      this.setState({ error: null });
    }
  }

  componentDidCatch(error: Error, info: Object) {
    withScope(scope => {
      scope.setExtras(info);
      const eventId = captureException(error);
      this.setState({ error: { error, eventId } });
    });
  }

  render() {
    if (this.state.error) {
      return this.props.renderError(this.state.error);
    } else {
      return this.props.children;
    }
  }
}
