/* @flow */

import * as React from "react";
import { captureException, addBreadcrumb } from "@sentry/browser";
import { StripeApiStatus } from "./useScriptTag";

type RenderProps = {
  setStripeElementsRef: (element: HTMLElement | null) => void,
  cardFormComplete: boolean,
  tokenPromise: Promise<StripeToken> | null,
  validationError: StripeValidationError | null,
  createToken: () => void
};

const stripeFonts = [
  {
    cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
  }
];

const stripeStyle = {
  base: {
    color: "#32325d",
    fontFamily: '"Roboto", Helvetica, sans-serif',
    fontSize: "16px"
  }
};

/*
  The token response is a little odd, because even errors are sent through
  the resolve callback.

  This function will separate out errors from valid token responses.

  I actually don't think promises are ever natively rejected by createToken.
  However, by normalizing the error handling, we will be prepared if they do.

  Here are some sample errors from the token:

  Validation errors:
  {
    code: "incomplete_number"
    message: "Your card number is incomplete."
    type: "validation_error"
  }

  Connection Error:
  {
    type: "api_connection_error",
    message: "We are experiencing issues connecting..."
  }
*/
export function normalizeStripeTokenPromise(
  promise: Promise<StripeTokenResult>
): Promise<StripeToken> {
  // I actually don't think the promise ever throws errors in this way. But we should have a handler just in case
  return promise
    .then(result => {
      if (result.token) {
        return result.token;
      } else {
        return Promise.reject(result.error);
      }
    })
    .catch(error => {
      // I think it's unlikely the token function will generate any other errors,
      // but just in case we should track it.
      if (
        !(
          error.type === "api_connection_error" ||
          error.type === "validation_error"
        )
      ) {
        captureException(error);
      }
      throw error;
    });
}

export function useStripeElementController(): RenderProps {
  // Initialize the Stripe API
  const apiStatus = React.useContext(StripeApiStatus);
  const [stripe, setStripe] = React.useState<Stripe$instance | null>(null);

  React.useEffect(() => {
    if (apiStatus === "loaded") {
      setStripe(window.Stripe(process.env["STRIPE_API_PUBLISHABLE_KEY"] || ""));
    }
  }, [apiStatus]);

  const cardRef = React.useRef<StripeElement | null>(null);
  const [
    stripeElementsRef,
    setStripeElementsRef
  ] = React.useState<HTMLElement | null>(null);

  const [
    tokenPromise,
    setTokenPromise
  ] = React.useState<Promise<StripeToken> | null>(null);

  const [cardFormComplete, setCardFormComplete] = React.useState(false);
  const [
    validationError,
    setValidationError
  ] = React.useState<StripeValidationError | null>(null);

  // Initialize StripeElement
  React.useEffect(() => {
    if (stripeElementsRef != null && stripe != null) {
      const elements = stripe.elements({ fonts: stripeFonts });
      const card = elements.create("card", { style: stripeStyle });
      card.mount(stripeElementsRef);
      cardRef.current = card;

      function onChange(event: StripeElementChangeEvent) {
        setValidationError(event.error || null);
        setCardFormComplete(event.complete);
      }

      card.addEventListener("change", onChange);

      return () => {
        card.destroy();
        cardRef.current = null;
      };
    }
  }, [stripeElementsRef, stripe]);

  // Sentry breadcrumb
  React.useEffect(() => {
    if (cardFormComplete) {
      addBreadcrumb({
        category: "stripe",
        message: "Completed Stripe Element",
        level: "info"
      });
    }
  }, [cardFormComplete]);

  const createToken = React.useCallback(() => {
    if (cardRef.current != null && stripe != null) {
      const promise = normalizeStripeTokenPromise(
        stripe.createToken(cardRef.current)
      );

      // Wait for the promise to resolve or reject before allowing it to
      // leave this hook. This is because the fetch function depends on the
      // DOM remaining mounted.
      promise.then(
        () => {
          addBreadcrumb({
            category: "stripe",
            message: "Generated Stripe token",
            level: "info"
          });
          setTokenPromise(promise);
        },
        () => {
          setTokenPromise(promise);
        }
      );
    }
  }, [stripe]);

  return {
    setStripeElementsRef,
    cardFormComplete,
    validationError,
    tokenPromise,
    createToken
  };
}
