/* @flow */

const { fromPairs } = require("lodash");

/*::
type Thumbnail = {
  url: string, width: number, height: number 
};
*/

function videoUrl(videoId, kind) {
  return `https://i.ytimg.com/vi/${videoId}/${kind}.jpg`;
}

function thumbnailsForVideo(
  videoId /*: string*/
) /*: { [string]: Thumbnail } */ {
  // This mimics the structure returned by the YouTube Data API.
  // https://developers.google.com/youtube/v3/docs/thumbnails
  //
  // Not all videos will have a maxres version. I used this hack on stackoverflow
  // to force the flash card tutorial to have a maxres thumbnail.
  // https://stackoverflow.com/a/48178125
  return {
    // 4:3
    default: {
      url: videoUrl(videoId, "default"),
      width: 120,
      height: 90
    },
    // 4:3
    high: {
      url: videoUrl(videoId, "hqdefault"),
      width: 480,
      height: 360
    },
    // 4:3 - Not available on all videos
    standard: {
      url: videoUrl(videoId, "sddefault.jpg"),
      width: 640,
      height: 480
    },
    // 16:9
    medium: {
      url: videoUrl(videoId, "mqdefault"),
      width: 320,
      height: 180
    },
    // 16:9 - Not available for all videos
    maxres: {
      url: videoUrl(videoId, "maxresdefault"),
      width: 1280,
      height: 720
    }
  };
}

function videoIdFromUrl(href /*: string*/) /*: (string | null) */ {
  let match;
  if ((match = href.match(/video_id=([\w-]+)/))) {
    return match[1];
  }

  if ((match = href.match(/watch\?v=([\w-]+)/))) {
    return match[1];
  }

  return null;
}

function channelIdFromUrl(url /*: string*/) /*: (string | null) */ {
  let match = url.match(/channel\/([\w-]+)/);
  if (match) {
    return match[1];
  } else {
    return null;
  }
}

function usernameFromUrl(url /*: string*/) /*: (string | null) */ {
  let match = url.match(/user\/([\w-]+)/);
  if (match) {
    return match[1];
  } else {
    return null;
  }
}

function searchPropsForUrl(
  url /*: string*/
) /*: { username: string } | {channelId: string} | null */ {
  const channelId = channelIdFromUrl(url);
  if (channelId != null) {
    return { channelId };
  }

  const username = usernameFromUrl(url);
  if (username != null) {
    return { username };
  }

  return null;
}

function languageNamesFromResponse(
  youtubeLanguages /*: YouTube$i18nLanguageListResponse*/
) /*: { [string]: string } */ {
  return fromPairs(
    youtubeLanguages.items.map(item => [item.id, item.snippet.name])
  );
}

function languageNameFromResponse(
  lang /*: string*/,
  youtubeLanguages /*: YouTube$i18nLanguageListResponse*/
) /*: string */ {
  const item = youtubeLanguages.items.find(item => item.id === lang);
  if (item) return item.snippet.name;
  else return lang;
}

module.exports = {
  searchPropsForUrl,
  thumbnailsForVideo,
  videoIdFromUrl,
  channelIdFromUrl,
  usernameFromUrl,
  languageNamesFromResponse,
  languageNameFromResponse
};
