/* @flow */

import * as React from "react";

import type { DocumentLocation } from "./useNavigation";

type Props = {
  serverSideProps: ServerSideProps,
  onNavigate: string => void,
  onReplaceLocation: string => void,
  onAddSnackbarMessage: (message: SnackbarMessage) => void,
  nativeLang: string,
  location: DocumentLocation
};

type AuthenticationProps = {|
  onLogin: (flashMessage?: string) => void,
  onLogout: () => void,
  idToken: string | null,
  withIdToken: ?() => Promise<string>,
  isLoggedIn: boolean,
  isInitialized: boolean,

  // Called from the oauth2 callback or login overlay
  onIdToken: (idToken: string) => void
|};

import type { InstallTokenOnMount } from "./authentication";
import type { ServerSideProps } from "./sendSPA";
import type { SnackbarMessage } from "./useSnackbarQueue";

import { setFlashMessage } from "./LoginOverlay";
import useLocalStorage from "./useLocalStorage";

import { stringsForLocale } from "../lang/web";

const storageKey = "captionpop-idtoken";

export default function useAuthentication(props: Props): AuthenticationProps {
  const [
    currentIdToken,
    setIdToken,
    clearIdToken,
    isInitialized
  ] = useLocalStorage(storageKey);

  const onLogin = (flashMessage?: string) => {
    if (flashMessage != null) {
      setFlashMessage(flashMessage);
    }

    // If you don't include the base path, it seems to strip out the search query.
    const base = location.pathname + location.search;
    props.onNavigate(base + "#login");
  };

  const { nativeLang, onAddSnackbarMessage } = props;
  const onLogout = React.useCallback(() => {
    const strings = stringsForLocale(nativeLang).account_snackbar_messages;
    onAddSnackbarMessage({
      body: strings.logged_out(),
      level: "message"
    });
    clearIdToken();
  }, [nativeLang, onAddSnackbarMessage, clearIdToken]);

  //
  // Handle installTokenOnMount in serverSideProps
  //
  const { idToken, redirectPath, result } = props.serverSideProps;
  const { onReplaceLocation } = props;
  React.useEffect(() => {
    function onToken(installTokenOnMount: InstallTokenOnMount) {
      if (result === "success") {
        setIdToken(installTokenOnMount.idToken);
        onReplaceLocation(installTokenOnMount.redirectPath);
      } else {
        // The user denied access to their OAuth account. Redisplay the login dialog.
        if (installTokenOnMount.redirectPath.indexOf("#") === -1) {
          onReplaceLocation(installTokenOnMount.redirectPath + "#login");
        } else {
          onReplaceLocation(installTokenOnMount.redirectPath);
        }
      }
    }

    if ((idToken != null && redirectPath != null, result != null)) {
      onToken({ idToken, redirectPath, result });
    }
  }, [idToken, redirectPath, setIdToken, onReplaceLocation, result]);

  // TODO: I think it's unlikely this needs to be a function.
  // It's probably good enough to pass the idtoken directly.
  const withIdToken = React.useMemo(() => {
    if (currentIdToken == null) {
      return null;
    } else {
      return () => Promise.resolve(currentIdToken);
    }
  }, [currentIdToken]);

  return {
    onLogin: onLogin,
    onLogout: onLogout,
    idToken: currentIdToken,
    isLoggedIn: currentIdToken != null,
    withIdToken: withIdToken,
    isInitialized: isInitialized,
    onIdToken: setIdToken
  };
}
