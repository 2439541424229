/* @flow */

export function serially<IN, OUT>(
  items: Array<IN>,
  processFn: IN => Promise<OUT>
): Promise<Array<OUT>> {
  return new Promise((resolve, reject) => {
    let results: Array<OUT> = [];
    let i = 0;

    function iterate() {
      if (i == items.length) {
        resolve(results);
      } else {
        processFn(items[i]).then(value => {
          results.push(value);
          iterate();
        }, reject);
        i++;
      }
    }

    iterate();
  });
}
