/* @flow */

import styled from "styled-components";

import { pageHeaderHeight } from "./geometry";
import { playerZIndex } from "./playerGeometry";

export default styled.div`
  position: fixed;
  left: 0;
  right: 0;

  // This is necessary to stop popups from the subtitle section from overlaying the player.
  // It's not enough to set the zIndex on the player-container itself.
  z-index: ${playerZIndex};

  top: ${props => (props.headerRetracted ? -(pageHeaderHeight + 10) : 0)}px;
  // I just grabbed this curve off of YouTube's mobile header
  transition: top 225ms cubic-bezier(0, 0, 0.2, 1);
`;
