/* @flow */

import * as React from "react";

import { urlForSearch } from "./urlForRoute";
import { searchPropsForUrl } from "./youtubeUtils";

export default function ChannelLink(props: {
  href: string,
  title: string,
  nativeLang: string,
  targetLang: ?string
}): React.Node {
  const searchProps = searchPropsForUrl(props.href);

  if (searchProps == null) {
    return <span>{props.title}</span>;
  } else {
    return (
      <a href={urlForSearch(searchProps, props.nativeLang, props.targetLang)}>
        {props.title}
      </a>
    );
  }
}
