
module.exports = {
  about_page: {
    headline: function(d) { return "CaptionPop是将YouTube作为语言学习手段进行体验的最佳方式。"; },
    feature_list: {
      "0": function(d) { return "同时观看多个字幕。"; },
      "1": function(d) { return "用一个按键重复最后一句句子。"; },
      "2": function(d) { return "放慢视频。"; },
      "3": function(d) { return "将字幕添加到稍后研究。"; },
      "4": function(d) { return "适用于任何带有字幕的YouTube视频。"; }
    },
    get_started_header: function(d) { return "开始使用"; },
    leave_feedback_header: function(d) { return "保持联系！"; },
    leave_feedback_prompt: function(d) { return "想要收到更新通知或留下一些反馈意见吗？"; },
    leave_feedback_action: function(d) { return "向我们打声招呼吧！"; },
    find_videos_action: function(d) { return "查找视频"; },
    closing_paragraph: function(d) { return "我建立了 CaptionPop 来帮助自己提高韩语听力理解能力。它对我帮助很大，我希望它也会对你有所帮助。"; },
    postscript: function(d) { return "附言：有关询问、评论或功能请求请随时给我发电子邮件。我喜欢听语言学习者的意见。"; },
    get_started: {
      target_select_prompt: function(d) { return "沒看到您想学的语言？CaptionPop 支援" + d.LENGTH + "种 Youtube 提供的语言。 从下方选一种语言吧!"; },
      target_select_placeholder: function(d) { return "选取一门语言"; },
      my_native_language_is: function(d) { return "我的母语是 LANGUAGE 而我想学的语言是:"; }
    }
  },
  choose_languages: {
    select_native_lang_prompt: function(d) { return "您的母语是什么？"; },
    select_target_lang_prompt: function(d) { return "您想学哪门语言？"; },
    update_action: function(d) { return "更新"; },
    featured_languages: function(d) { return "精选语言"; },
    all_languages: function(d) { return "所有语言"; },
    select_placeholder: function(d) { return "选择……"; }
  },
  play_videos_page: {
    login_on_favorite_prompt: function(d) { return "请登录，以便保存您最喜欢的字幕。"; },
    add_to_library_action: function(d) { return "添加至视频库"; },
    remove_from_library_action: function(d) { return "从视频库中移除"; },
    no_available_subtitles: function(d) { return "很遗憾，这部视频目前没有可用的字幕。"; },
    library_icon_label: function(d) { return "视频库"; },
    translation_upgrade_prompt: function(d) { return "拥有 CaptionPop 高级版后，你选中的任何字和词将立即展示谷歌翻译的结果。"; },
    translation_upgrade_call_to_action: function(d) { return "升级"; },
    blur_captions_label: function(d) { return "模糊字幕"; },
    only_one_subtitle_language: function(d) { return "此视频的字幕只有 " + d.LANGUAGE + "."; },
    pause_after_caption_label: function(d) { return "播完每行字幕后暂停"; }
  },
  favorites_page: {
    blur_captions_label: function(d) { return "模糊" + d.LANGUAGE + "字幕"; }
  },
  search_page: {
    page_title: function(d) { return "搜索 Youtube 上" + d.TARGET_LANG + "的视频"; },
    meta_description: function(d) { return "搜索 Youtube 上有" + d.NATIVE_LANG + "和" + d.TARGET_LANG + "字幕的视频。 同時覌看双字幕。"; },
    meta_description_generic: function(d) { return "搜索 Youtube 上有多种字幕的视频。同時覌看双字幕。"; },
    filter_header: function(d) { return "按字幕语言进行筛选。"; },
    show_all: function(d) { return "显示所有"; },
    more_action: function(d) { return "更多"; },
    end_of_results: function(d) { return "搜索结果结束"; },
    empty_search_results: {
      no_videos: function(d) { return "没有找到视频。"; },
      singular_video_dual_filter: function(d) { return "发现1个视频，但它没有" + d.FILTER_LANGUAGE_A + " 和 " + d.FILTER_LANGUAGE_B + "字幕。"; },
      singular_video_single_filter: function(d) { return "找到1个视频，但它没有" + d.FILTER_LANGUAGE + "字幕。"; },
      plural_videos_single_filter: function(d) { return "找到" + d.VIDEO_COUNT + "个视频，但全部都没有  " + d.FILTER_LANGUAGE + "字幕。"; },
      plural_videos_dual_filter: function(d) { return "找到" + d.VIDEO_COUNT + " 个视频，但全部都没有 " + d.FILTER_LANGUAGE_A + " 和 " + d.FILTER_LANGUAGE_B + "字幕。"; }
    }
  },
  search_form: {
    search_action: function(d) { return "搜索"; },
    search_header: function(d) { return "搜索 YouTube 上的所有视频"; },
    filter_by_subtitle_header: function(d) { return "按字幕筛选"; },
    tutorials_header: function(d) { return "CaptionPop 教程"; },
    filter_by_city_header: function(d) { return "按城市筛选"; },
    placeholder: function(d) { return "搜索" + d.LANGUAGE + "字词或 YouTube 视频的URL"; },
    change_language_link: function(d) { return "更多"; },
    featured_playlists_header: function(d) { return "浏览精选" + d.LANGUAGE + "播放列表"; },
    featured_channels_header: function(d) { return "浏览精选" + d.LANGUAGE + "频道"; },
    suggested_cities: function(d) { return "推荐的城市"; },
    all_cities: function(d) { return "所有城市"; },
    premium_button: function(d) { return "注册高级版"; },
    tutorials_caption: function(d) { return "需要我们帮助您开始吗？"; },
    premium_cia: function(d) { return "通过CaptionPop会员，加快您的学习进程。"; },
    chrome_extension_banner: {
      header: function(d) { return "Get the CaptionPop experience while browsing videos on YouTube.com."; },
      line1: function(d) { return "Install the CaptionPop browser extension for Chrome."; },
      line2: function(d) { return "After installing, each video on YouTube will be shown with a list of subtitles that are available for that video. Clicking on the subtitle list will link you to the CaptionPop player, so you can watch with multiple subtitles at once."; },
      install_action: function(d) { return "Install"; }
    }
  },
  keyboard_shortcuts: {
    next_line_tooltip: function(d) { return "下一行"; },
    previous_line_tooltip: function(d) { return "上一行"; },
    toggle_playback_tooltip: function(d) { return "播放 / 暂停"; },
    show_translation_tooltip: function(d) { return "显示翻译"; },
    repeat_tooltip: function(d) { return "重复"; }
  },
  page_header: {
    home_action: function(d) { return "主页"; },
    login_action: function(d) { return "登录"; },
    logout_action: function(d) { return "登出"; },
    premium_action: function(d) { return "升级至高级版"; }
  },
  page_footer: {
    about_link: function(d) { return "关于"; },
    contact_link: function(d) { return "联系"; },
    terms_of_service_link: function(d) { return "服务条款"; },
    privacy_policy_link: function(d) { return "隐私政策"; }
  },
  page_not_found: function(d) { return "未找到页面"; },
  caption_popup_options: {
    edit: function(d) { return "编辑字幕"; },
    jump_to: function(d) { return "跳转至原始版本"; },
    remove: function(d) { return "从收藏中移除"; }
  },
  edit_caption_form: {
    header: function(d) { return "编辑字幕"; },
    labels: {
      start_time: function(d) { return "开始时间"; },
      end_time: function(d) { return "结束时间"; },
      transcription: function(d) { return "原文"; },
      translations: function(d) { return "译文"; }
    },
    actions: {
      save_changes: function(d) { return "保存更改"; },
      nevermind: function(d) { return "取消"; }
    }
  },
  form_validations: {
    required: function(d) { return "必填"; },
    too_big: function(d) { return "所填时间点应在视频结束之前"; },
    comes_after_start: function(d) { return "所填时间点应在开始时间之后"; }
  },
  navigation: {
    video_library_link: function(d) { return "视频库"; },
    favorite_captions_link: function(d) { return "收藏的字幕"; },
    account_link: function(d) { return "帐户"; },
    flash_cards_link: function(d) { return "字卡"; }
  },
  snackbar: {
    video_added_to_library: function(d) { return "该视频已添加到您的视频库中。"; },
    video_removed_from_library: function(d) { return "该视频已从您的视频库中删除。"; },
    added_to_favorite_captions: function(d) { return "该字幕已添加到您最喜欢的字幕列表中。"; },
    removed_from_favorite_captions: function(d) { return "该字幕已从您最喜欢的字幕列表中删除。"; },
    updated_favorite_caption: function(d) { return "您的更改已保存。"; },
    network_error: function(d) { return "检查您的网络连接。"; },
    playlist_added_to_library: function(d) { return "此播放列表已添加到您的媒体库。"; },
    playlist_removed_from_library: function(d) { return "此播放列表已从您的媒体库中删除。"; },
    channel_added_to_library: function(d) { return "此频道已添加到您的媒体库。"; },
    channel_removed_from_library: function(d) { return "此频道已从您的媒体库中删除。"; }
  },
  flash_cards: {
    days_count: function(d) { return d.COUNT + "天"; },
    logged_out_warning: function(d) { return "登录 CaptionPop 以保存您的进度。不然的话，您的进度将于您关闭浏览器后丢失。"; },
    splash_page: {
      title: function(d) { return "CaptionPop: 互动字卡"; },
      introduction: function(d) { return "利用“互动字卡”来提高你的听力理解能力。"; },
      feature_list: {
        "0": function(d) { return "根据你从YouTube视频中保存的字幕来查看字卡。"; },
        "1": function(d) { return "当你输入文字时，可以看到不同颜色的反馈信息。"; },
        "2": function(d) { return "寻求一些提示来引导你克服难点。"; },
        "3": function(d) { return "字卡能够自动记分。"; },
        "4": function(d) { return "通过一种间隔重复性算法，系统会安排再次查看字卡。"; }
      },
      calls_to_action: {
        header: function(d) { return "准备好了吗?"; },
        demo_decks_text: function(d) { return "试试这些演示用的字卡中的一套。"; },
        no_favorites: {
          text: function(d) { return "或者根据您在 YouTube 上找到的标题创建一套您自己的字卡。"; },
          button: function(d) { return "搜索视频"; }
        },
        logged_in_with_favorites: {
          text: function(d) { return "你已经在YouTube上保存了" + d.COUNT + "字幕，现在你可以通过“互动字卡”的形式来查看它们!"; },
          button: function(d) { return "立即查看"; }
        }
      }
    },
    instructions: {
      finish_pass: function(d) { return "请稍后再试"; },
      finish_complete: function(d) { return "真棒！"; },
      playing_state: function(d) { return "请仔细听"; },
      quiz_state: function(d) { return "请输入你听到的内容"; },
      before_playback: function(d) { return "点击播放键，以收听。"; }
    },
    hint_tip: function(d) { return "请点击灰色框，以获取提示。"; },
    actions: {
      skip: function(d) { return "跳过"; },
      finish: function(d) { return "完成 "; },
      more_to_review: function(d) { return "再查看" + d.COUNT + " "; },
      replay: function(d) { return "重新播放"; }
    },
    previous_attempts_list: {
      header: function(d) { return "之前的尝试"; },
      hints_used: function(d) { return "已使用的提示"; },
      time_takes: function(d) { return "花费的时间"; },
      score: function(d) { return "分数"; }
    },
    finished: {
      edit_action: function(d) { return "编辑"; },
      previous_attempts_header: function(d) { return "之前的尝试"; },
      time_taken: function(d) { return "花费的时间"; },
      hints_used: function(d) { return "已使用的提示"; },
      due_again: function(d) { return "再次到期時間"; },
      score: function(d) { return "分数"; }
    },
    dashboard: {
      header: function(d) { return "字卡"; },
      card_count: function(d) { return " " + d.TOTAL_COUNT + " 中的" + d.REVIEWED_COUNT + " 已于最近查看"; },
      next_card_due: function(d) { return "下一张字卡将于" + d.TIMER + "后到期"; },
      empty_deck: function(d) { return "这个字卡组是空的"; },
      actions: {
        review_random: function(d) { return "随机查看字卡"; },
        create_custom_deck: function(d) { return "创建一个自定义字卡组"; },
        edit_deck: function(d) { return "编辑字卡组"; },
        review_all: function(d) { return "查看全部"; },
        review_deck: function(d) { return "查看"; },
        search: function(d) { return "搜索视频"; }
      },
      saved_captions_deck: function(d) { return "你已保存的YouTube视频字幕"; },
      free_demo_deck_header: function(d) { return "免费的演示字卡组"; },
      custom_deck_header: function(d) { return "自定义字卡组"; },
      tip_header: function(d) { return "小提示"; },
      search_videos_tip: function(d) { return "利用CaptionPop，你可从任何视频中创建字卡。"; },
      custom_deck_tip: function(d) { return "你可以把你的字幕归类存入自定义的学习组。"; },
      login_tip: function(d) { return "登录你的 CaptionPop 账户，即可从Youtube的任何视频中创建字卡。"; }
    },
    form: {
      create_header: function(d) { return "创建一个新的字卡组"; },
      update_header: function(d) { return "更新你的字卡组"; },
      name_of_deck_header: function(d) { return "字卡组的名称"; },
      select_captions_header: function(d) { return "选择字幕"; },
      selected_count: function(d) { return d.COUNT + " 已选择"; },
      select_multiple_captions: function(d) { return "选择 " + d.COUNT + " 字幕"; },
      unselect_multiple_captions: function(d) { return "取消选择 " + d.COUNT + " 字幕"; },
      actions: {
        update: function(d) { return "更新字卡组"; },
        create: function(d) { return "创建字卡组"; },
        "delete": function(d) { return "删除字卡组"; }
      },
      confirmation: function(d) { return "你确定吗？"; },
      snackbar: {
        created: function(d) { return "你的新字卡组已创建"; },
        deleted: function(d) { return "你的字卡组已删除"; },
        updated: function(d) { return "你的字卡组已更新"; },
        error: function(d) { return "保存你的字卡组时出现错误"; }
      }
    },
    paywall_overlay: {
      header: function(d) { return "你今天的免费试用时间已结束"; },
      text: function(d) { return "等待计时器重置，或升级至CaptionPop高级版，以获得无限制的使用权。"; },
      call_to_action: function(d) { return "立即升级"; }
    },
    free_trial_banner: {
      flash_cards_remaining: function(d) { return "在今天的免费试用时间中，你还剩 " + d.COUNT + " 免费字卡。"; },
      upgrade_action: function(d) { return "升级"; }
    },
    premium_subscription: {
      errors: {
        banner_header: function(d) { return "你的购买手续失敗"; },
        network_error: function(d) { return "由於网路問题,您的购买手续失敗."; },
        unexpected_error: function(d) { return "由於不明原因,無法完成您的购买手续.我门已取消付款並已通知開發者. 请稍后再尝试."; }
      },
      header: function(d) { return "订购CaptionPop 高级版"; },
      product_description: function(d) { return "利用从你最喜欢的YouTube视频中创建的“互动字卡”来提高你的听力理解能力。"; },
      available_currencies: function(d) { return "也接受其他货币 " + d.CURRENCY_LIST + "."; },
      account: {
        choose_password: function(d) { return "请设定密码"; },
        provider_signup: function(d) { return "以其他方式登入"; },
        already_have_account: function(d) { return "已經有帳戶了嗎"; },
        logged_in_as: function(d) { return "您已登入 " + d.EMAIL + "."; },
        not_you: function(d) { return "这不是你嗎?"; }
      },
      form_headers: {
        create_an_account: function(d) { return "建立新帐号"; },
        plan: function(d) { return "选择一个付费方案"; },
        credit_card: function(d) { return "信用卡"; }
      },
      calls_to_action: {
        choose: function(d) { return "选择"; },
        purchase: function(d) { return "购买"; }
      },
      plans: {
        month: {
          title: function(d) { return "月付"; },
          description: function(d) { return "按月结算，实惠的价格！"; },
          long_description: function(d) { return "点击购买后， 你的信用卡将于今天被收取 " + d.AMOUNT + " , 之后每月的这一天都将被收取" + d.AMOUNT + "。只需一个点击，你就可以随时取消。"; }
        },
        year: {
          title: function(d) { return "年付"; },
          description: function(d) { return "按年结算，比月付节省 " + d.PERCENT + "%！"; },
          long_description: function(d) { return "点击购买后， 你的信用卡将于今天被收取 " + d.AMOUNT + " , 之后每年的这一天都将被收取" + d.AMOUNT + "。只需一个点击，你就可以随时取消。"; }
        },
        lifetime: {
          title: function(d) { return "使用期"; },
          description: function(d) { return "一次付费，终身使用！"; },
          long_description: function(d) { return "点击购买后， 你的信用卡将于今天被收取" + d.AMOUNT + "，之后不再收取任何费用。"; }
        }
      },
      confirmation: {
        header: function(d) { return "谢谢你！"; },
        paragraph1: function(d) { return "你已注册 <b>CaptionPop高级账户!</b"; },
        paragraph2: function(d) { return "现在你可以无限制访问CaptionPop的“间隔重复性互动字卡”。 通过这个功能，我个人的听力理解能力得到了很大的提高，希望它对你也有帮助。"; },
        paragraph3: function(d) { return "在你的 <a href=\"/account\">账户页面</a>，你可以随时取消订购。"; },
        creator_title: function(d) { return "CaptionPop开发者"; },
        call_to_action: function(d) { return "互动字卡"; },
        videos_action: function(d) { return "观看视频"; },
        flash_cards_action: function(d) { return "学习字卡"; }
      }
    }
  },
  premium_splash_page: {
    page_title: function(d) { return "订购 CaptionPop 高级版"; },
    header1: function(d) { return "通过这些高级功能来加快你的学习进程！"; },
    free_column_header: function(d) { return "免费版"; },
    premium_column_header: function(d) { return "高级版"; },
    feature1: function(d) { return "观看多条字幕"; },
    feature2: function(d) { return "通过字幕语言来筛选 YouTube 搜索"; },
    feature3: function(d) { return "选中文字即可显示翻译结果"; },
    feature4: function(d) { return "互动字卡"; },
    free_flash_card_limit: function(d) { return "每天5个"; },
    header2: function(d) { return "选中文字即可显示翻译结果。"; },
    google_translate_description: function(d) { return "选中任何字或词，即可自动翻译至你的母语。翻译是通过谷歌翻译进行的。"; },
    header3: function(d) { return "无限制的听力理解字卡。"; },
    call_to_action: function(d) { return "注册高级版"; }
  },
  login_form: {
    favorite_prompt: function(d) { return "请登录，以便保存您最喜欢的字幕。"; },
    placeholders: {
      email: function(d) { return "电子邮件"; },
      password: function(d) { return "密码"; },
      current_password: function(d) { return "当前密码"; },
      password_confirmation: function(d) { return "确认密码"; }
    },
    forgot_password_link: function(d) { return "忘记密码？"; },
    or: function(d) { return "或者"; },
    actions: {
      login: function(d) { return "登陆"; },
      signup: function(d) { return "注册"; },
      login_with_provider: function(d) { return "用 " + d.PROVIDER + " 账号登陆"; },
      signup_with_provider: function(d) { return "用 " + d.PROVIDER + " 账号注册"; }
    },
    errors: {
      invalid_credentials: function(d) { return "电子邮件或密码错误。"; },
      email_conflict: function(d) { return "该电子邮件账户已存在。"; },
      unexpected_login_api_error: function(d) { return "登陆出错。"; },
      unexpected_signup_api_error: function(d) { return "创建账号出错。"; },
      network_error: function(d) { return "检查您的网络连接。"; }
    },
    library_prompt: function(d) { return "请登录，以便将项目保存到媒体库。"; }
  },
  password_reset_form: {
    instructions: function(d) { return "请输入您的邮件地址。您会收到一封关于密码重置的邮件。"; },
    actions: {
      send_email: function(d) { return "发送邮件"; },
      ok: function(d) { return "确认"; }
    },
    finished_status: {
      instructions_sent: function(d) { return "密码重置邮件已发送至：" + d.EMAIL_ADDRESS + "。"; },
      rate_limited: function(d) { return "密码重置邮件已发送至：" + d.EMAIL_ADDRESS + "。请等待至少一小时再发起新的请求。"; },
      network_error: function(d) { return "连接服务器失败。请检查您的网络连接。"; },
      delivery_error: function(d) { return "密码重置邮件无法发送至：" + d.EMAIL_ADDRESS + "。请确认邮箱地址是否准确。"; }
    }
  },
  password_reset_invalid_page: {
    header: function(d) { return "密码重置链接无效"; },
    text: function(d) { return "密码重置链接仅在 24 小时内有效。"; },
    actions: {
      home: function(d) { return "主页"; },
      request_new: function(d) { return "获取新的链接"; }
    }
  },
  change_password_page: {
    password_reset_instructions: function(d) { return "请设置新密码并恢复账号。"; },
    change_password_header: function(d) { return "修改您的密码"; },
    change_password_action: function(d) { return "修改密码"; },
    password_updated_confirmation: function(d) { return "您的密码已更新"; },
    network_error: function(d) { return "网络连接有问题，无法更新您的密码。"; }
  },
  account_snackbar_messages: {
    logged_in: function(d) { return "您已登录。"; },
    logged_out: function(d) { return "您已退出。"; },
    signed_up: function(d) { return "您的账号创建成功。"; },
    subscription_cancelled: function(d) { return "您的订阅已取消。"; },
    subscription_reactivated: function(d) { return "您的订阅已重新激活。"; }
  },
  library_page: {
    search_action: function(d) { return "搜索视频"; },
    headers: {
      playlists: function(d) { return "播放列表"; },
      channels: function(d) { return "频道"; },
      videos: function(d) { return "视频"; }
    },
    empty_header: function(d) { return "您的媒体库是空的。"; },
    empty_description: function(d) { return "您可以在此处保存自己喜欢的视频、频道和播放列表。"; }
  },
  account_page: {
    page_header: function(d) { return "My Account"; },
    current_plan_header: function(d) { return "Current Plan"; },
    free_plan: function(d) { return "Free"; },
    monthly_plan_pricing: function(d) { return d.AMOUNT + " per month"; },
    yearly_plan_pricing: function(d) { return d.AMOUNT + " per year"; },
    subscription_ends_on: function(d) { return "Your subscription will end on " + d.END_DATE; },
    subscription_renews_on: function(d) { return "Your subscription will renew automatically on " + d.RENEW_DATE; },
    cancel_subscription_action: function(d) { return "Cancel subscription"; },
    reactivate_subscription_action: function(d) { return "Re-Activate subscription"; },
    subscription_type_basic: function(d) { return "CaptionPop Basic"; },
    subscription_type_premium: function(d) { return "CaptionPop Premium"; },
    subscription_type_lifetime: function(d) { return "CaptionPop Lifetime"; },
    language_settings_header: function(d) { return "Language Settings"; },
    native_language_header: function(d) { return "Native Language"; },
    target_language_header: function(d) { return "Target Language"; },
    native_language_update_confirmation: function(d) { return "Your native language has been updated."; },
    target_language_update_confirmation: function(d) { return "Your target language has been updated."; },
    payment_history_header: function(d) { return "Payment History"; },
    receipt_link: function(d) { return "Receipt"; },
    credit: function(d) { return "Credit " + d.AMOUNT; },
    credit_card_description: function(d) { return d.CREDIT_CARD_BRAND + " ending in " + d.LAST4; },
    credit_card_expires: function(d) { return "Expires " + d.MONTH + "/" + d.YEAR; },
    payment_method_header: function(d) { return "Payment Method"; },
    update_payment_method_action: function(d) { return "Update Credit Card"; },
    payment_method_update_confirmation: function(d) { return "Your payment method has been updated."; },
    cancel_action: function(d) { return "Cancel"; },
    update_action: function(d) { return "Update"; },
    none: function(d) { return "None"; }
  },
  cancel_subscription_page: {
    page_header: function(d) { return "Your subscription has been cancelled"; },
    confirmation: function(d) { return "Thank you! Your feedback will help us make CaptionPop better.\""; },
    paragraph1: function(d) { return "Your subscription to CaptionPop Premium has been cancelled. You will still have access to all of CaptionPop Premium's features until the end of your current billing period."; },
    paragraph2: function(d) { return "If you change your mind, you can re-activate your subscription at any time from your Account page."; },
    feedback_header: function(d) { return "Why did you decide to cancel?"; },
    feedback_prompt: function(d) { return "Help us make CaptionPop better by providing some feedback on your decision to cancel."; },
    reason_too_expensive: function(d) { return "The price is too expensive."; },
    reason_not_useful: function(d) { return "I don't find CaptionPop Premium useful anymore"; },
    reason_other: function(d) { return "Another reason"; },
    comments_header: function(d) { return "Do you have any other comments or suggestions?"; },
    cancel_action: function(d) { return "No, thank you"; },
    submit_action: function(d) { return "Submit Feedback"; },
    reason_required: function(d) { return "Please choose a reason."; }
  }
}