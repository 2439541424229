/* @flow */

import * as React from "react";
import styled from "styled-components";

import WithPromise from "../WithPromise";
import LoadingTextPlaceholder from "../LoadingTextPlaceholder";
import { Row, ColumnSpan } from "../BootstrapGrid";
import { StripeElementWrapper } from "../FormStyles";
import { ActionButton, TransparentRoundedActionButton } from "../Buttons";
import { spacers } from "../theme";
import { useStripeElementController } from "../useStripeElementController";
import { usePromiseSubscription } from "../useFlatPromise";
import { stringsForLocale } from "../../lang/web";

import type { SnackbarMessage } from "../useSnackbarQueue";
import type { RenderProps as SubscriptionProps } from "../WithPremiumSubscription";
import type { PaymentSource as StripePaymentSource } from "../api";

type Props = {
  nativeLang: string,
  subscriptionProps: SubscriptionProps,
  onAddSnackbarMessage: SnackbarMessage => void
};

export default function PaymentMethodSection(props: Props) {
  const [
    showUpdatePaymentMethodForm,
    setShowUpdatePaymentMethodForm
  ] = React.useState(false);

  const strings = stringsForLocale(props.nativeLang);

  return (
    <React.Fragment>
      <h2>{strings.account_page.payment_method_header()}</h2>
      {showUpdatePaymentMethodForm ? (
        <UpdatePaymentMethodForm
          onUpdate={token => {
            props.subscriptionProps.updatePaymentSource(token).then(() => {
              props.onAddSnackbarMessage({
                body: strings.account_page.payment_method_update_confirmation(),
                level: "message"
              });
            });

            setShowUpdatePaymentMethodForm(false);
          }}
          onError={message => {
            props.onAddSnackbarMessage({
              body: message,
              level: "error"
            });
          }}
          onCancel={() => setShowUpdatePaymentMethodForm(false)}
          nativeLang={props.nativeLang}
        />
      ) : (
        <WithPromise
          promise={props.subscriptionProps.paymentSource}
          renderRejected={error => null}
          renderPending={() => (
            <React.Fragment>
              <div>
                <LoadingTextPlaceholder width={180} />
              </div>
              <div>
                <LoadingTextPlaceholder width={200} />
              </div>
              <div>
                <LoadingTextPlaceholder width={160} />
              </div>
            </React.Fragment>
          )}
          renderResolved={paymentSource => (
            <PaymentSource
              nativeLang={props.nativeLang}
              source={paymentSource}
              updatePaymentSource={() => setShowUpdatePaymentMethodForm(true)}
            />
          )}
        />
      )}
    </React.Fragment>
  );
}

type UpdatePaymentMethodFormProps = {
  nativeLang: string,
  onUpdate: StripeToken => void,
  onCancel: () => void,
  onError: string => void
};

function UpdatePaymentMethodForm(props: UpdatePaymentMethodFormProps) {
  const stripeProps = useStripeElementController();

  const strings = stringsForLocale(props.nativeLang);

  usePromiseSubscription(
    stripeProps.tokenPromise,
    props.onUpdate,
    props.onError
  );

  return (
    <UpdatePaymentFormStyles>
      <StripeElement ref={stripeProps.setStripeElementsRef} />

      <ActionButton onActivated={props.onCancel}>
        {strings.account_page.cancel_action()}
      </ActionButton>

      <ActionButton
        onActivated={stripeProps.createToken}
        primary
        disabled={
          !stripeProps.cardFormComplete && !stripeProps.cardFormComplete
        }
      >
        {strings.account_page.update_action()}
      </ActionButton>
    </UpdatePaymentFormStyles>
  );
}

function PaymentSource(props: {
  source: ?StripePaymentSource,
  updatePaymentSource: Function,
  nativeLang: string
}) {
  const strings = stringsForLocale(props.nativeLang);

  if (props.source) {
    const source = props.source;
    return (
      <React.Fragment>
        <PaymentSourceRow style={{ marginBottom: 16 }}>
          <ColumnSpan columns={6} lg={3}>
            {strings.account_page.credit_card_description({
              CREDIT_CARD_BRAND: source.brand,
              LAST4: source.last4
            })}
          </ColumnSpan>
          <ColumnSpan columns={6} lg={3}>
            {strings.account_page.credit_card_expires({
              MONTH: String(source.exp_month),
              YEAR: String(source.exp_year)
            })}
          </ColumnSpan>
        </PaymentSourceRow>

        <FormActions>
          <TransparentRoundedActionButton
            onActivated={props.updatePaymentSource}
          >
            {strings.account_page.update_payment_method_action()}
          </TransparentRoundedActionButton>
        </FormActions>
      </React.Fragment>
    );
  } else {
    return <div>{strings.account_page.none()}</div>;
  }
}

const StripeElement = styled.div`
  flex-grow: 1;
`;

const UpdatePaymentFormStyles: typeof StripeElementWrapper = styled(
  StripeElementWrapper
)`
  display: flex;

  a {
    margin-left: 5px;
  }
`;

const PaymentSourceRow = styled(Row)`
  margin-bottom: ${spacers[3]};
`;

const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
