/* @flow */

import * as React from "react";
import styled, { css } from "styled-components";

import { colors } from "./theme";
import { map } from "lodash";

import PageFooter from "./PageFooter";
import { ButtonLink } from "./Buttons";
import Branding from "./Branding";

import { primaryLanguageForCountry } from "./chooseLocale";
import BrowserChrome from "./BrowserChrome";
import { LoginButton } from "./PageHeader";
import { dataUrlForSvg } from "./dataUrlForSvg";
import { urlForSearch, featuredLanguagePaths } from "../js/urlForRoute";
import { languageNamesFromResponse } from "./youtubeUtils";

import { GreyRectangle } from "./LoadingTextPlaceholder";

import {
  Container,
  Row,
  ColumnSpan,
  breakpoints,
  containerMaxWidths
} from "./BootstrapGrid";

// $FlowFixMe - YAML isn't supported
import nativeLanguageNames from "../lang/nativeLanguageNames";

import { stringsForLocale } from "../lang/web";
import {
  useVideoPosition,
  useFavoritesCount,
  DemoVideo,
  FeatureList as FlashCardFeatureList,
  CallsToAction as FlashCardCallsToAction
} from "./FlashCardsSplashPage";

import { getDemoDeckLanguages } from "./apiClient";

import usePromise from "./usePromise";

import jonProfileUrl from "../assets/jon-profile.jpg";
import demoScreenshotUrl from "../assets/demo-screenshot.jpg";

import { NativeLanguageSelect, TargetLanguageSelect } from "./LanguageSelect";

// $FlowFixMe: Flow doesn't support require.context
const languageSquares = require.context("../assets/language-squares/");

import type { UserResourceStore } from "./WithUserResourceStore";
import type { DocumentLocation } from "./useNavigation";
import type { ServerSideProps } from "./sendSPA";

type Props = {
  location: DocumentLocation,
  onLogin: Function,
  onLogout: Function,
  isLoggedIn: boolean,
  onNavigate: string => void,
  nativeLang: string,
  targetLang: ?string,
  serverSideProps: ServerSideProps,
  onChangeNativeLang: (lang: string) => void,
  onChangeTargetLang: (lang: string) => void,
  userResources: ?UserResourceStore,
  youtubeLanguages: YouTube$i18nLanguageListResponse
};

export default function AboutPage(props: Props) {
  const strings = stringsForLocale(props.nativeLang);
  const videoProps = useVideoPosition();

  const demoFlashCardsPromise = usePromise(() => getDemoDeckLanguages(), []);
  const favoritesCount = useFavoritesCount(props.userResources);

  const [isMounted, setIsMounted] = React.useState(false);
  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  const languageAlternate = alternateLanguage(
    props.nativeLang,
    props.serverSideProps.countryCode
  );

  let languageAlternateName;
  if (languageAlternate == "es") {
    languageAlternateName = "Español";
  } else if (languageAlternate == "zh") {
    languageAlternateName = "中文";
  } else if (languageAlternate in nativeLanguageNames) {
    languageAlternateName = nativeLanguageNames[languageAlternate]();
  } else {
    languageAlternateName = "";
  }

  const languageNames = languageNamesFromResponse(props.youtubeLanguages);

  const [
    str1,
    str2
  ] = strings.about_page.get_started.my_native_language_is().split("LANGUAGE");

  return (
    <React.Fragment>
      <PageHeader>
        {isMounted ? (
          <React.Fragment>
            {/*
                WARNING: anything that depends on country code can't be included until the page
                is mounted. This is because countrycode isn't included in the properties when
                the SSR script runs.
             */}
            <a href={"/?nl=" + languageAlternate}>{languageAlternateName}</a>
            <MyLoginButton
              nativeLang={props.nativeLang}
              isLoggedIn={props.isLoggedIn}
              onLogout={props.onLogout}
              onLogin={props.onLogin}
            />
          </React.Fragment>
        ) : (
          <MyLoginButtonPlaceholder />
        )}
      </PageHeader>

      <MyBranding size={36} />

      <CenteredContentBox>
        <H1>{strings.about_page.headline()}</H1>

        <SwooshBody swooshIndex={0}>
          <DemoImageAndFeatureList>
            <DemoImageContainer>
              <BrowserChrome childAspectRatio={694 / 834} maxWidth={405}>
                <img src={demoScreenshotUrl} />
              </BrowserChrome>
            </DemoImageContainer>

            <FeatureListContainer>
              <MainFeatureList>
                {
                  // strings.about_page.language_list should be an Array, but instead
                  // it's an Object with numeric keys. It's not worth diving into
                  // messagepack-loader to fix, so I'm just using the lodash
                  // mapper function
                }
                {map(strings.about_page.feature_list, (str, i) => (
                  <li key={i}>
                    <svg viewBox="0 0 20 20" width="20px" height="20px">
                      <circle
                        cx="10"
                        cy="10"
                        r="9"
                        stroke={colors.highlight}
                        strokeWidth={1}
                        fill="none"
                      />
                    </svg>
                    <span>{str()}</span>
                  </li>
                ))}
              </MainFeatureList>
            </FeatureListContainer>
          </DemoImageAndFeatureList>

          <GetStartedLink>
            {strings.about_page.get_started_header()}
          </GetStartedLink>
        </SwooshBody>

        <H2>{strings.flash_cards.splash_page.introduction()}</H2>

        <SwooshBody swooshIndex={1}>
          <DemoVideoAndFeatureList>
            <DemoImageContainer>
              <DemoVideo ref={videoProps.ref} />
            </DemoImageContainer>

            <FeatureListContainer>
              <FlashCardFeatureList
                sampleTime={videoProps.sampleTime}
                onSeek={videoProps.onSeek}
                nativeLang={props.nativeLang}
                backgroundColor="#EDFCF6"
              />
            </FeatureListContainer>
          </DemoVideoAndFeatureList>

          <FlashCardCallsToActionWrapper>
            <FlashCardCallsToAction
              nativeLang={props.nativeLang}
              youtubeLanguages={props.youtubeLanguages}
              favoritesCount={favoritesCount}
              demoLanguages={demoFlashCardsPromise}
              searchHref="#get-started-section"
            />
          </FlashCardCallsToActionWrapper>
        </SwooshBody>

        <ClosingSection>
          <StayInTouch>
            <H2>{strings.about_page.leave_feedback_header()}</H2>
            <p>{strings.about_page.leave_feedback_prompt()}</p>
            <ButtonLink
              primary
              target="_blank"
              href="https://goo.gl/forms/230G2KZO6xBA8R6H3"
            >
              {strings.about_page.leave_feedback_action()}
            </ButtonLink>
          </StayInTouch>

          <p>{strings.about_page.closing_paragraph()}</p>

          <p>
            <img src={jonProfileUrl} />
            <br />
            Cheers,
            <br />
            Jonathan
          </p>

          <p>
            {strings.about_page.postscript()}{" "}
            <a href="mailto:jon@captionpop.com">jon@captionpop.com</a>
          </p>
        </ClosingSection>
      </CenteredContentBox>
      <GetStartedContainer id="get-started-section">
        <H2>{strings.about_page.get_started_header()}</H2>

        <GetStartedPrompt>
          <span>{str1}</span>{" "}
          {isMounted ? (
            <NativeLanguageSelect
              css={`
                display: inline-block;
                width: 200px;
              `}
              onChangeNativeLang={props.onChangeNativeLang}
              instanceId="get-started-native-lang"
              nativeLang={props.nativeLang}
              youtubeLanguages={props.youtubeLanguages}
            />
          ) : (
            languageNames[props.nativeLang]
          )}{" "}
          <span>{str2}</span>
        </GetStartedPrompt>

        <Row>
          {Object.keys(featuredLanguagePaths).map((targetLang, i) => (
            <ColumnSpan columns={6} md={4} lg={3} xl={2} key={i}>
              <a
                href={urlForSearch({}, props.nativeLang, targetLang)}
                onClick={() => props.onChangeTargetLang(targetLang)}
              >
                <LanguageSquareImage
                  title={languageNames[targetLang]}
                  targetLang={targetLang}
                />
              </a>
            </ColumnSpan>
          ))}
        </Row>

        <div css="line-height: 150%;">
          {strings.about_page.get_started.target_select_prompt({
            LENGTH: props.youtubeLanguages.items.length
          })}

          <div css="margin-top: 2em;">
            {isMounted ? (
              <TargetLanguageSelect
                css={`
                  display: inline-block;
                  width: 350px;
                  margin-right: 0.5em;
                `}
                includeFeatured={false}
                instanceId="get-started-target-lang"
                nativeLang={props.nativeLang}
                onChange={props.onChangeTargetLang}
                targetLang={props.targetLang}
                youtubeLanguages={props.youtubeLanguages}
                placeholder={strings.about_page.get_started.target_select_placeholder()}
              />
            ) : (
              <SelectBoxPlaceholder>&nbsp;</SelectBoxPlaceholder>
            )}

            {props.targetLang == null ? (
              <ButtonLink primary disabled>
                {strings.about_page.find_videos_action()}
              </ButtonLink>
            ) : (
              <ButtonLink
                primary
                href={urlForSearch({}, props.nativeLang, props.targetLang)}
              >
                {strings.about_page.find_videos_action()}
              </ButtonLink>
            )}
          </div>
        </div>
      </GetStartedContainer>
      <PageFooter nativeLang={props.nativeLang} />
    </React.Fragment>
  );
}

const GetStartedContainer = styled(Container)`
  margin-top: 3em;

  // Make the xl container behave like a lg container
  @media (${breakpoints.xl}) {
    max-width: ${containerMaxWidths.lg};
  }
`;

const GetStartedPrompt = styled.div`
  text-align: center;
  span {
    display: block;
  }
  > span,
  > div {
    margin-bottom: 0.5em;
  }

  margin-bottom: 2em;

  @media (${breakpoints.sm}) {
    span {
      display: inline;
    }
  }
`;

const LanguageTitle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Shade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  opacity: 0.3;
  background-color: #333;
`;

function LanguageSquareImage(props) {
  let imgEl;
  if (languageSquares.keys().indexOf(`./${props.targetLang}.jpg`) === -1) {
    imgEl = null;
  } else {
    const img1 = languageSquares(`./${props.targetLang}.jpg`);
    const img2 = languageSquares(`./${props.targetLang}@2x.jpg`);

    imgEl = <img srcSet={`${img1} 1x, ${img2} 2x`} src={img1} />;
  }

  const splitAt = props.title.indexOf("(");
  let titleText;
  if (splitAt === -1) {
    titleText = props.title;
  } else {
    titleText = (
      <React.Fragment>
        {props.title.slice(0, splitAt)}
        <br />
        {props.title.slice(splitAt)}
      </React.Fragment>
    );
  }

  return (
    <LanguageSquareImageStyles aspectRatio={1}>
      {imgEl}
      <Shade />
      <LanguageTitle>{titleText}</LanguageTitle>
    </LanguageSquareImageStyles>
  );
}

const LanguageSquareImageStyles = styled(GreyRectangle)`
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 30px;
  transition: transform 150ms, box-shadow 150ms;
  transform: scale(1);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: scale(1.05);
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.5);
  }
`;

function alternateLanguage(currentLang: string, countryCode: string): string {
  const primaryLang = primaryLanguageForCountry(countryCode);
  if (primaryLang != currentLang) {
    return primaryLang;
  } else {
    if (currentLang === "en") {
      return "es";
    } else {
      return "en";
    }
  }
}

// iPad in landscape mode. This should be wide enough to fit the feature list next to the demo images
const mobileBreakpoint = "(max-width: 1024px)";
const desktopBreakpoint = `not all and ${mobileBreakpoint}`;

const centeredContentBox = css`
  box-sizing: border-box;
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
`;

const whiteBox = css`
  background-color: white;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
`;
const MyBranding = styled(Branding)`
  text-align: center;
  @media ${mobileBreakpoint} {
    margin-bottom: 1em;
  }
`;

const loginButtonHeight = 41;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem 0;

  a {
    text-decoration: none;
    color: #333;
    padding: 0 2em;
    height: ${loginButtonHeight}px;
    line-height: ${loginButtonHeight}px;
  }
`;

const MyLoginButton = styled(LoginButton)`
  display: inline-block;
  border: 1px solid #fafafa00;
  border-radius: 4px;
  box-sizing: border-box;
  height: ${loginButtonHeight}px;
  line-height: ${loginButtonHeight}px;

  &:hover {
    background-color: white;
    border-color: #333;
  }
`;

const MyLoginButtonPlaceholder = styled.div`
  box-sizing: border-box;
  display: inline-block;
  height: ${loginButtonHeight}px;
  line-height: ${loginButtonHeight}px;
`;

const headerStyles = `
  text-align: center;
  font-size: 24px;
  font-family: Foco, sans-serif;
  font-weight: normal;
  line-height: 150%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0 0.5em;
`;

const H1 = styled.h1`
  ${centeredContentBox}
  ${headerStyles}
`;

const H2 = styled.h2`
  ${centeredContentBox}
  ${headerStyles}
`;

const MainFeatureList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    display: flex;
    flex-direction: row;

    & > svg {
      flex: 0;
      flex-basis: 20px;
      display: block;
      margin-top: 2px;
    }
    & > span {
      flex: 1;
      margin-left: 2rem;
    }

    margin-bottom: 25px;
  }

  line-height: 1.5;
`;

const FeatureListContainer = styled.div``;
const DemoImageContainer = styled.div`
  flex-basis: 480px;
  img {
    width: 100%;
  }
`;

const FlexContainerForFeatureList = styled.div`
  @media ${desktopBreakpoint} {
    max-width: 55em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;

    display: flex;
    justify-content: space-between;

    ${FeatureListContainer} {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @media ${mobileBreakpoint} {
    ${FeatureListContainer} {
      max-width: 400px;
      margin: 2em auto;
    }
  }
`;

const DemoImageAndFeatureList = styled(FlexContainerForFeatureList)`
  @media ${desktopBreakpoint} {
    ${FeatureListContainer} {
      order: 0;
    }
    ${DemoImageContainer} {
      order: 1;
    }
  }
`;

const DemoVideoAndFeatureList = styled(FlexContainerForFeatureList)`
  margin-top: 0;

  @media ${desktopBreakpoint} {
    margin-top: 4rem;
    ${FeatureListContainer} {
      margin-left: 4rem;
    }
  }
`;

const CenteredContentBox = styled.div`
  @media ${mobileBreakpoint} {
    ${whiteBox} ${centeredContentBox};
  }
`;

const FlashCardCallsToActionWrapper = styled.div`
  ${centeredContentBox} text-align: center;
  line-height: 1.5;
`;

const swooshGradient = `
<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
  <stop stop-color="#EDFCF6" offset="0%"></stop>
  <stop stop-color="#F8FEFB" offset="100%"></stop>
</linearGradient>
`;

const narrowSwooshUrl = dataUrlForSvg(`
<svg width="640px" height="912px" viewBox="0 0 640 912" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
      ${swooshGradient}
  </defs>
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path d="M0.996879875,2 C118.528304,-2.34375 255.301958,1.67447917 411.317841,14.0546875 C567.333724,26.4348958 643.227777,32.0833333 639,31 L639,65 L0.996879875,65 L0.996879875,2 Z" fill="#B6FCE0"></path>
    <path d="M1,41.4290535 C194.550182,27.7506286 310.728567,22.3055275 349.535156,25.09375 C448.592448,32.2109375 545.081769,38.4019406 639.00312,43.6667593 L639.00312,911.769531 L1,911.769531 L1,41.4290535 Z" fill="url(#linearGradient-1)"></path>
  </g>
</svg>
`);

const wideSwooshUrls = [
  `
<svg width="1158px" height="803px" viewBox="0 0 1158 803" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        ${swooshGradient}
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-2">
            <path d="M0.59375,3.69140625 C368.470213,-3.3883128 617.092609,2.07491207 746.460937,20.0810809 C875.829266,38.0872497 1013.00895,64.5742046 1158,99.5419458 L1158,803 L6,803 L0.59375,3.69140625 Z" id="dark" fill="#B6FCE0"></path>
            <path d="M0.6328125,24.15625 C371.539484,21.6439305 621.676984,28.7959236 751.045312,45.6122292 C880.413641,62.4285348 1016.0652,85.8981976 1158,116.021218 L1158,803 L6,803 L0.6328125,24.15625 Z" id="light" fill="url(#linearGradient-1)"></path>
        </g>
    </g>
</svg>
`,
  `
<svg width="1156px" height="659px" viewBox="0 0 1156 659" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
         ${swooshGradient}
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-3">
            <path d="M0,39.9091069 C120.888519,28.0589568 290.769446,17.2152413 509.64278,7.37796058 C728.516115,-2.45932019 943.968521,-2.45932019 1156,7.37796058 L1156,659 L0,659 L0,39.9091069 Z" id="dark" fill="#B6FCE0" transform="translate(578.000000, 329.500000) scale(-1, 1) translate(-578.000000, -329.500000) "></path>
            <path d="M0,74.7521369 C120.171304,49.9950311 289.693623,33.0350386 508.566957,23.8721594 C727.440292,14.7092802 943.251306,14.7092802 1156,23.8721594 L1156,659 L0,659 L0,74.7521369 Z" id="light" fill="url(#linearGradient-1)" transform="translate(578.000000, 338.000000) scale(-1, 1) translate(-578.000000, -338.000000) "></path>
        </g>
    </g>
</svg>`
].map(dataUrlForSvg);

const SwooshBody = styled.div`
  @media ${mobileBreakpoint} {
    background: url(${narrowSwooshUrl}) no-repeat;
    background-position-y: 60px;
    margin-bottom: 2rem;
  }
  margin-bottom: 4rem;
  background: url(${props => wideSwooshUrls[props.swooshIndex]}) no-repeat;
  background-position-y: 0px;

  background-size: cover;

  padding: 20px 2em;

  img {
    margin: 0 auto;
  }
`;

const ClosingSection = styled.div`
  padding: 0 2em;

  @media ${desktopBreakpoint} {
    ${whiteBox} ${centeredContentBox};
    padding: 1em 4em;
  }
  p {
    line-height: 1.5;
  }
`;

const StayInTouch = styled.div`
  text-align: center;
`;

const SelectBoxPlaceholder = styled.div`
  width: 350px;
  display: inline-block;
  margin-right: 0.5em;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 6px;
  background-color: #eee;
  content: " ";
`;

function GetStartedLink(props) {
  return (
    <div css="text-align: center; margin-top: 3em;">
      <ButtonLink
        css="font-size: 20px;"
        primary
        href="#get-started-section"
        data-skip-navigation={true}
      >
        {props.children}
      </ButtonLink>
    </div>
  );
}
