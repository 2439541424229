var en = function(n, ord
) {
  var s = String(n).split('.'), v0 = !s[1], t0 = Number(s[0]) == n,
      n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2);
  if (ord) return (n10 == 1 && n100 != 11) ? 'one'
      : (n10 == 2 && n100 != 12) ? 'two'
      : (n10 == 3 && n100 != 13) ? 'few'
      : 'other';
  return (n == 1 && v0) ? 'one' : 'other';
};
var number = function (value, name, offset) {
  if (!offset) return value;
  if (isNaN(value)) throw new Error("Can't apply offset:" + offset + ' to argument `' + name + '` with non-numerical value ' + JSON.stringify(value) + '.');
  return value - offset;
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};

module.exports = {
  about_page: {
    headline: function(d) { return "CaptionPop is the best way to experience YouTube as a language learner."; },
    feature_list: {
      "0": function(d) { return "Watch with multiple subtitles simultaneously."; },
      "1": function(d) { return "Repeat the last sentence with a single keystroke."; },
      "2": function(d) { return "Slow down the video."; },
      "3": function(d) { return "Bookmark subtitles to study later."; },
      "4": function(d) { return "Works with any YouTube video that has subtitles."; }
    },
    get_started_header: function(d) { return "Get Started"; },
    leave_feedback_header: function(d) { return "Stay in touch!"; },
    leave_feedback_prompt: function(d) { return "Want to be notified of updates or leave some feedback?"; },
    leave_feedback_action: function(d) { return "Say Hello!"; },
    find_videos_action: function(d) { return "Find Videos"; },
    closing_paragraph: function(d) { return "I built CaptionPop to help me improve my own Korean listening comprehension skills. It has helped me greatly and I hope it will help you as well."; },
    postscript: function(d) { return "PS: E-mail me anytime with questions, comments, or feature requests. I love hearing from language learners."; },
    get_started: {
      target_select_prompt: function(d) { return "Don't see your target language? CaptionPop works with any of the " + d.LENGTH + " languages that are supported by YouTube. Select one below!"; },
      target_select_placeholder: function(d) { return "Select any language"; },
      my_native_language_is: function(d) { return "My native language is LANGUAGE and I want to learn:"; }
    }
  },
  choose_languages: {
    select_native_lang_prompt: function(d) { return "What is your native language?"; },
    select_target_lang_prompt: function(d) { return "What language do you want to learn?"; },
    update_action: function(d) { return "Update"; },
    featured_languages: function(d) { return "Featured languages"; },
    all_languages: function(d) { return "All languages"; },
    select_placeholder: function(d) { return "Select..."; }
  },
  play_videos_page: {
    library_icon_label: function(d) { return "Library"; },
    add_to_library_action: function(d) { return "Add to Library"; },
    remove_from_library_action: function(d) { return "Remove from Library"; },
    pause_after_caption_label: function(d) { return "Pause after each caption"; },
    blur_captions_label: function(d) { return "Blur captions"; },
    no_available_subtitles: function(d) { return "Unfortunately, there are no subtitles available for this video."; },
    only_one_subtitle_language: function(d) { return "Subtitles for this video are only available in " + d.LANGUAGE + "."; },
    translation_upgrade_prompt: function(d) { return "With CaptionPop Premium, any word or phrase that you highlight will be instantly translated with Google Translate."; },
    translation_upgrade_call_to_action: function(d) { return "Upgrade"; }
  },
  search_page: {
    page_title: function(d) { return "Search for " + d.TARGET_LANG + " videos on YouTube"; },
    meta_description: function(d) { return "Search YouTube for videos with both " + d.NATIVE_LANG + " and " + d.TARGET_LANG + " subtitles. Watch with both subtitles simultaneously."; },
    meta_description_generic: function(d) { return "Search YouTube for videos with multiple subtitles. Watch with both subtitles simultaneously."; },
    filter_header: function(d) { return "Filter by subtitle language."; },
    show_all: function(d) { return "Show All"; },
    more_action: function(d) { return "More"; },
    end_of_results: function(d) { return "End of results"; },
    empty_search_results: {
      no_videos: function(d) { return "No videos were found."; },
      singular_video_dual_filter: function(d) { return "1 video was found, but it doesn't have subtitles in " + d.FILTER_LANGUAGE_A + " and " + d.FILTER_LANGUAGE_B + "."; },
      singular_video_single_filter: function(d) { return "1 video was found, but it doesn't have subtitles in " + d.FILTER_LANGUAGE + "."; },
      plural_videos_single_filter: function(d) { return d.VIDEO_COUNT + " videos were found, but none have subtitles in " + d.FILTER_LANGUAGE + "."; },
      plural_videos_dual_filter: function(d) { return d.VIDEO_COUNT + " videos were found, but none have subtitles in " + d.FILTER_LANGUAGE_A + " and " + d.FILTER_LANGUAGE_B + "."; }
    }
  },
  search_form: {
    search_header: function(d) { return "Search all videos on YouTube"; },
    filter_by_subtitle_header: function(d) { return "filter by subtitle"; },
    filter_by_city_header: function(d) { return "filter by city"; },
    suggested_cities: function(d) { return "Suggested Cities"; },
    all_cities: function(d) { return "All Cities"; },
    search_action: function(d) { return "Search"; },
    tutorials_header: function(d) { return "CaptionPop Tutorials"; },
    tutorials_caption: function(d) { return "Need help getting started?"; },
    change_language_link: function(d) { return "more"; },
    chrome_extension_banner: {
      header: function(d) { return "Get the CaptionPop experience while browsing videos on YouTube.com."; },
      line1: function(d) { return "Install the CaptionPop browser extension for Chrome."; },
      line2: function(d) { return "After installing, each video on YouTube will be shown with a list of subtitles that are available for that video. Clicking on the subtitle list will link you to the CaptionPop player, so you can watch with multiple subtitles at once."; },
      install_action: function(d) { return "Install"; }
    },
    premium_cia: function(d) { return "Accelerate your learning process with CaptionPop Premium."; },
    premium_button: function(d) { return "Sign up for Premium"; },
    placeholder: function(d) { return "Search term in " + d.LANGUAGE + " or YouTube URL"; },
    featured_channels_header: function(d) { return "Browse featured " + d.LANGUAGE + " channels"; },
    featured_playlists_header: function(d) { return "Browse featured " + d.LANGUAGE + " playlists"; }
  },
  favorites_page: {
    blur_captions_label: function(d) { return "Blur " + d.LANGUAGE + " captions"; }
  },
  keyboard_shortcuts: {
    next_line_tooltip: function(d) { return "Next Line"; },
    previous_line_tooltip: function(d) { return "Previous Line"; },
    toggle_playback_tooltip: function(d) { return "Play / Pause"; },
    show_translation_tooltip: function(d) { return "Show Translation"; },
    repeat_tooltip: function(d) { return "Repeat"; }
  },
  page_header: {
    home_action: function(d) { return "Home"; },
    login_action: function(d) { return "Log in"; },
    logout_action: function(d) { return "Log out"; },
    premium_action: function(d) { return "Go Premium"; }
  },
  navigation: {
    favorite_captions_link: function(d) { return "Favorite Captions"; },
    video_library_link: function(d) { return "Video Library"; },
    account_link: function(d) { return "Account"; },
    flash_cards_link: function(d) { return "Flash Cards"; }
  },
  caption_popup_options: {
    edit: function(d) { return "Edit Caption"; },
    jump_to: function(d) { return "Jump to Original"; },
    remove: function(d) { return "Remove from Favorites"; }
  },
  edit_caption_form: {
    header: function(d) { return "Edit Caption"; },
    labels: {
      start_time: function(d) { return "start time"; },
      end_time: function(d) { return "end time"; },
      transcription: function(d) { return "transcription"; },
      translations: function(d) { return "translations"; }
    },
    actions: {
      save_changes: function(d) { return "Save Changes"; },
      nevermind: function(d) { return "Nevermind"; }
    }
  },
  form_validations: {
    required: function(d) { return "required"; },
    too_big: function(d) { return "should be before the end of the video"; },
    comes_after_start: function(d) { return "should come after the start time"; }
  },
  page_footer: {
    about_link: function(d) { return "About"; },
    contact_link: function(d) { return "Contact"; },
    terms_of_service_link: function(d) { return "Terms of Service"; },
    privacy_policy_link: function(d) { return "Privacy Policy"; }
  },
  page_not_found: function(d) { return "Page not found"; },
  library_page: {
    empty_header: function(d) { return "Your library is empty."; },
    empty_description: function(d) { return "You can save your favorite videos, channels, and playlists here."; },
    search_action: function(d) { return "Search for Videos"; },
    headers: {
      playlists: function(d) { return "Playlists"; },
      channels: function(d) { return "Channels"; },
      videos: function(d) { return "Videos"; }
    }
  },
  snackbar: {
    video_added_to_library: function(d) { return "This video has been added to your library."; },
    video_removed_from_library: function(d) { return "This video has been removed from your library."; },
    playlist_added_to_library: function(d) { return "This playlist has been added to your library."; },
    playlist_removed_from_library: function(d) { return "This playlist has been removed from your library."; },
    channel_added_to_library: function(d) { return "This channel has been added to your library."; },
    channel_removed_from_library: function(d) { return "This channel has been removed from your library."; },
    network_error: function(d) { return "Please check your internet connection."; },
    added_to_favorite_captions: function(d) { return "The caption was added to your list of favorite captions."; },
    removed_from_favorite_captions: function(d) { return "The caption was removed from your list of favorite captions."; },
    updated_favorite_caption: function(d) { return "Your changes have been saved."; }
  },
  flash_cards: {
    instructions: {
      finish_pass: function(d) { return "Try again later."; },
      finish_complete: function(d) { return "Good job!"; },
      playing_state: function(d) { return "Listen carefully."; },
      quiz_state: function(d) { return "Type what you heard."; },
      before_playback: function(d) { return "Press play and listen."; }
    },
    paywall_overlay: {
      header: function(d) { return "Your free trial has ended for today."; },
      text: function(d) { return "Wait for the timer to reset or upgrade to CaptionPop Premium for unlimited access."; },
      call_to_action: function(d) { return "Upgrade Now"; }
    },
    finished: {
      edit_action: function(d) { return "edit"; },
      previous_attempts_header: function(d) { return "Previous attempts"; },
      time_taken: function(d) { return "Time taken"; },
      hints_used: function(d) { return "Hints used"; },
      due_again: function(d) { return "Due again"; },
      score: function(d) { return "Score"; }
    },
    days_count: function(d) { return plural(d.COUNT, 0, en, { one: "1 day", other: number(d.COUNT, "COUNT") + " days" }); },
    logged_out_warning: function(d) { return "Log in to CaptionPop to save your progress. Otherwise, it will be lost when you close your browser."; },
    splash_page: {
      title: function(d) { return "CaptionPop: Interactive Flash Cards"; },
      introduction: function(d) { return "Improve your listening comprehension with interactive flash cards."; },
      feature_list: {
        "0": function(d) { return "Review flash cards based on captions you've saved from YouTube videos."; },
        "1": function(d) { return "Get color-coded feedback as you type."; },
        "2": function(d) { return "Ask for hints to guide you through sticking points."; },
        "3": function(d) { return "Flash cards are automatically scored."; },
        "4": function(d) { return "A spaced repetition algorithm will schedule the card to be reviewed again in the future."; }
      },
      calls_to_action: {
        header: function(d) { return "Ready to get started?"; },
        demo_decks_text: function(d) { return "Try one of these demo flash card decks."; },
        no_favorites: {
          text: function(d) { return "Or create your own deck from captions you find on YouTube."; },
          button: function(d) { return "Search for Videos"; }
        },
        logged_in_with_favorites: {
          text: function(d) { return "You've already saved " + plural(d.COUNT, 0, en, { "0": "No captions", one: "1 caption", other: number(d.COUNT, "COUNT") + " captions" }) + " from YouTube. You can review them now as interactive flash cards!"; },
          button: function(d) { return "Review Now"; }
        }
      }
    },
    dashboard: {
      header: function(d) { return "Flash cards"; },
      actions: {
        review_all: function(d) { return "Review All"; },
        review_random: function(d) { return "Review a random card"; },
        review_deck: function(d) { return "Review"; },
        create_custom_deck: function(d) { return "Create a custom deck"; },
        edit_deck: function(d) { return "Edit deck"; },
        search: function(d) { return "Search for Videos"; }
      },
      card_count: function(d) { return d.REVIEWED_COUNT + " out of " + plural(d.TOTAL_COUNT, 0, en, { one: "1 flash card", other: number(d.TOTAL_COUNT, "TOTAL_COUNT") + " flash cards" }) + " have been recently reviewed."; },
      next_card_due: function(d) { return "The next card is due to be reviewed in " + d.TIMER + "."; },
      empty_deck: function(d) { return "This deck is empty."; },
      saved_captions_deck: function(d) { return "Captions you've saved from YouTube"; },
      free_demo_deck_header: function(d) { return "Free demo flash card deck"; },
      custom_deck_header: function(d) { return "Custom flash card deck"; },
      tip_header: function(d) { return "Tip"; },
      search_videos_tip: function(d) { return "You can create flash cards from any videos on CaptionPop."; },
      custom_deck_tip: function(d) { return "You can organize your captions into custom study decks."; },
      login_tip: function(d) { return "Log in to your CaptionPop account to create flash cards from any video on YouTube."; }
    },
    form: {
      create_header: function(d) { return "Create a new flash card deck"; },
      update_header: function(d) { return "Update your flash card deck"; },
      name_of_deck_header: function(d) { return "Name of Deck"; },
      select_captions_header: function(d) { return "Select Captions"; },
      selected_count: function(d) { return plural(d.COUNT, 0, en, { "0": "No captions", one: "1 caption", other: number(d.COUNT, "COUNT") + " captions" }) + " selected"; },
      select_multiple_captions: function(d) { return "Select " + d.COUNT + " Captions"; },
      unselect_multiple_captions: function(d) { return "Unselect " + d.COUNT + " Captions"; },
      actions: {
        update: function(d) { return "Update deck"; },
        create: function(d) { return "Create deck"; },
        "delete": function(d) { return "Delete deck"; }
      },
      confirmation: function(d) { return "Are you sure?"; },
      snackbar: {
        created: function(d) { return "Your new deck has been created."; },
        deleted: function(d) { return "Your deck has been deleted."; },
        updated: function(d) { return "Your deck has been updated."; },
        error: function(d) { return "There was an error saving your deck."; }
      }
    },
    hint_tip: function(d) { return "Click the grey boxes for hints"; },
    actions: {
      skip: function(d) { return "Skip"; },
      finish: function(d) { return "Finish"; },
      more_to_review: function(d) { return d.COUNT + " more to review"; },
      replay: function(d) { return "Replay"; }
    },
    previous_attempts_list: {
      header: function(d) { return "Previous attempts"; },
      hints_used: function(d) { return "Hints used"; },
      time_takes: function(d) { return "Time taken"; },
      score: function(d) { return "Score"; }
    },
    free_trial_banner: {
      flash_cards_remaining: function(d) { return "You have " + plural(d.COUNT, 0, en, { one: "1 free flash card", other: number(d.COUNT, "COUNT") + " free flash cards" }) + " remaining in your free trial today."; },
      upgrade_action: function(d) { return "Upgrade"; }
    },
    premium_subscription: {
      header: function(d) { return "Subscribe to CaptionPop Premium"; },
      product_description: function(d) { return "Improve your listening comprehension skills with interactive flash cards constructed from your favorite YouTube videos."; },
      errors: {
        banner_header: function(d) { return "Your purchase couldn't be completed."; },
        network_error: function(d) { return "Your purchase couldn't be completed because of a problem with the Internet connection."; },
        unexpected_error: function(d) { return "There was an unexpected problem completing your purchase. Your credit card hasn't been charged. The developer of CaptionPop has been notified. Please try again later."; }
      },
      form_headers: {
        create_an_account: function(d) { return "Create an account"; },
        plan: function(d) { return "Choose a plan"; },
        credit_card: function(d) { return "Credit card"; }
      },
      available_currencies: function(d) { return "Also available in " + d.CURRENCY_LIST + "."; },
      account: {
        choose_password: function(d) { return "Choose a password"; },
        provider_signup: function(d) { return "or signup with..."; },
        already_have_account: function(d) { return "Already have an account?"; },
        logged_in_as: function(d) { return "You are logged in as " + d.EMAIL + "."; },
        not_you: function(d) { return "Not you?"; }
      },
      calls_to_action: {
        choose: function(d) { return "Choose"; },
        purchase: function(d) { return "Purchase"; }
      },
      plans: {
        month: {
          title: function(d) { return "Monthly"; },
          description: function(d) { return "Billed monthly. Lowest price!"; },
          long_description: function(d) { return "When you click purchase, your credit card will be charged " + d.AMOUNT + " today, and then " + d.AMOUNT + " every month on this date. You can cancel at any time with a single click."; }
        },
        year: {
          title: function(d) { return "Yearly"; },
          description: function(d) { return "Billed yearly. Save " + d.PERCENT + "% over monthly!"; },
          long_description: function(d) { return "When you click purchase, your credit card will be charged " + d.AMOUNT + " today, and then " + d.AMOUNT + " every year on this date. You can cancel at any time with a single click."; }
        },
        lifetime: {
          title: function(d) { return "Lifetime"; },
          description: function(d) { return "Billed once. Use forever!"; },
          long_description: function(d) { return "When you click purchase, your credit card will be charged " + d.AMOUNT + " today, and then never again."; }
        }
      },
      confirmation: {
        header: function(d) { return "Thank you!"; },
        paragraph1: function(d) { return "You have been signed up for <b>CaptionPop Premium!</b"; },
        paragraph2: function(d) { return "You now have unlimited access to all of CaptionPop's premium features.  These features have personally helped me improve my listening comprehension ability, and I hope they will for you as well."; },
        paragraph3: function(d) { return "You can cancel your subscription at any time from your <a href=\"/account\">account page</a>."; },
        creator_title: function(d) { return "Creator of CaptionPop"; },
        videos_action: function(d) { return "Watch Videos"; },
        flash_cards_action: function(d) { return "Study Flash Cards"; }
      }
    }
  },
  premium_splash_page: {
    page_title: function(d) { return "Subscribe to CaptionPop Premium"; },
    header1: function(d) { return "Accelerate your learning process with these advanced features!"; },
    free_column_header: function(d) { return "Free"; },
    premium_column_header: function(d) { return "Premium"; },
    feature1: function(d) { return "Watch with multiple subtitles"; },
    feature2: function(d) { return "Filter YouTube searches by subtitle language"; },
    feature3: function(d) { return "Highlight text to translate"; },
    feature4: function(d) { return "Interactive Flash Cards"; },
    free_flash_card_limit: function(d) { return "5 per day"; },
    header2: function(d) { return "Highlight text to translate."; },
    google_translate_description: function(d) { return "Highlight any word or phrase to translate it into your native language. Translations are done through Google Translate."; },
    header3: function(d) { return "Unlimited listening comprehension flash cards."; },
    call_to_action: function(d) { return "Sign up for Premium"; }
  },
  login_form: {
    library_prompt: function(d) { return "Please log in so you can save items to your library."; },
    favorite_prompt: function(d) { return "Please log in so you can save your favorite captions."; },
    placeholders: {
      email: function(d) { return "E-mail"; },
      password: function(d) { return "Password"; },
      password_confirmation: function(d) { return "Password confirmation"; },
      current_password: function(d) { return "Current password"; }
    },
    forgot_password_link: function(d) { return "Forgot your password?"; },
    or: function(d) { return "or"; },
    actions: {
      login: function(d) { return "Log in"; },
      signup: function(d) { return "Sign up"; },
      login_with_provider: function(d) { return "Log in with " + d.PROVIDER; },
      signup_with_provider: function(d) { return "Sign up with " + d.PROVIDER; }
    },
    errors: {
      invalid_credentials: function(d) { return "Incorrect e-mail or password."; },
      email_conflict: function(d) { return "An account with this e-mail already exists."; },
      unexpected_login_api_error: function(d) { return "There was a problem logging in."; },
      unexpected_signup_api_error: function(d) { return "There was a problem creating your account."; },
      network_error: function(d) { return "Check your Internet connection."; }
    }
  },
  password_reset_form: {
    instructions: function(d) { return "Please enter your e-mail address. Instructions to recover your account will be sent to you in an e-mail."; },
    actions: {
      send_email: function(d) { return "Send e-mail"; },
      ok: function(d) { return "OK"; }
    },
    finished_status: {
      instructions_sent: function(d) { return "Instructions have been sent to " + d.EMAIL_ADDRESS + "."; },
      rate_limited: function(d) { return "Instructions have already been sent to " + d.EMAIL_ADDRESS + ". Please wait at least one hour before requesting again."; },
      network_error: function(d) { return "There was a problem connecting to the server. Please check your Internet connection."; },
      delivery_error: function(d) { return "There was a problem sending instructions to " + d.EMAIL_ADDRESS + ". Please confirm this is a valid e-mail address."; }
    }
  },
  password_reset_invalid_page: {
    header: function(d) { return "Invalid password reset link"; },
    text: function(d) { return "Password reset links are only valid for 24 hours."; },
    actions: {
      home: function(d) { return "Home"; },
      request_new: function(d) { return "Request a new link"; }
    }
  },
  change_password_page: {
    password_reset_instructions: function(d) { return "Please choose a new password to regain access to your account."; },
    change_password_header: function(d) { return "Change your password"; },
    change_password_action: function(d) { return "Change password"; },
    password_updated_confirmation: function(d) { return "Your password has been updated"; },
    network_error: function(d) { return "Your password could not be updated because of a problem with the Internet connection."; }
  },
  account_snackbar_messages: {
    logged_in: function(d) { return "You're logged in."; },
    logged_out: function(d) { return "You're logged out."; },
    signed_up: function(d) { return "Your account has been created."; },
    subscription_cancelled: function(d) { return "Your subscription has been cancelled."; },
    subscription_reactivated: function(d) { return "Your subscription has been re-activated."; }
  },
  account_page: {
    page_header: function(d) { return "My Account"; },
    current_plan_header: function(d) { return "Current Plan"; },
    free_plan: function(d) { return "Free"; },
    monthly_plan_pricing: function(d) { return d.AMOUNT + " per month"; },
    yearly_plan_pricing: function(d) { return d.AMOUNT + " per year"; },
    subscription_ends_on: function(d) { return "Your subscription will end on " + d.END_DATE; },
    subscription_renews_on: function(d) { return "Your subscription will renew automatically on " + d.RENEW_DATE; },
    cancel_subscription_action: function(d) { return "Cancel subscription"; },
    reactivate_subscription_action: function(d) { return "Re-Activate subscription"; },
    subscription_type_basic: function(d) { return "CaptionPop Basic"; },
    subscription_type_premium: function(d) { return "CaptionPop Premium"; },
    subscription_type_lifetime: function(d) { return "CaptionPop Lifetime"; },
    language_settings_header: function(d) { return "Language Settings"; },
    native_language_header: function(d) { return "Native Language"; },
    target_language_header: function(d) { return "Target Language"; },
    native_language_update_confirmation: function(d) { return "Your native language has been updated."; },
    target_language_update_confirmation: function(d) { return "Your target language has been updated."; },
    payment_history_header: function(d) { return "Payment History"; },
    receipt_link: function(d) { return "Receipt"; },
    credit: function(d) { return "Credit " + d.AMOUNT; },
    credit_card_description: function(d) { return d.CREDIT_CARD_BRAND + " ending in " + d.LAST4; },
    credit_card_expires: function(d) { return "Expires " + d.MONTH + "/" + d.YEAR; },
    payment_method_header: function(d) { return "Payment Method"; },
    update_payment_method_action: function(d) { return "Update Credit Card"; },
    payment_method_update_confirmation: function(d) { return "Your payment method has been updated."; },
    cancel_action: function(d) { return "Cancel"; },
    update_action: function(d) { return "Update"; },
    none: function(d) { return "None"; }
  },
  cancel_subscription_page: {
    page_header: function(d) { return "Your subscription has been cancelled"; },
    confirmation: function(d) { return "Thank you! Your feedback will help us make CaptionPop better.\""; },
    paragraph1: function(d) { return "Your subscription to CaptionPop Premium has been cancelled. You will still have access to all of CaptionPop Premium's features until the end of your current billing period."; },
    paragraph2: function(d) { return "If you change your mind, you can re-activate your subscription at any time from your Account page."; },
    feedback_header: function(d) { return "Why did you decide to cancel?"; },
    feedback_prompt: function(d) { return "Help us make CaptionPop better by providing some feedback on your decision to cancel."; },
    reason_too_expensive: function(d) { return "The price is too expensive."; },
    reason_not_useful: function(d) { return "I don't find CaptionPop Premium useful anymore"; },
    reason_other: function(d) { return "Another reason"; },
    comments_header: function(d) { return "Do you have any other comments or suggestions?"; },
    cancel_action: function(d) { return "No, thank you"; },
    submit_action: function(d) { return "Submit Feedback"; },
    reason_required: function(d) { return "Please choose a reason."; }
  }
}