/* @flow */
/*eslint no-console: ["error", { allow: ["warn"] }] */

import * as React from "react";

import { safeLocalStorage as storage } from "./safeStorage";

export default function useLocalStorage(
  storageKey: string
): [string | null, (string) => void, () => void, boolean] {
  const [value, setState] = React.useState<{
    value: string | null,
    loaded: boolean
  }>({ value: null, loaded: false });

  function setStateWrapper(value: ?string) {
    setState({ value: value || null, loaded: true });
  }

  // Load the initial value from storage
  React.useEffect(() => {
    setStateWrapper(storage.getItem(storageKey));
  }, [storageKey]);

  // Listen for change events from the storage layer
  React.useEffect(() => {
    function handler(event: StorageEvent) {
      try {
        if (event.storageArea === localStorage && event.key === storageKey) {
          setStateWrapper(event.newValue);
        }
      } catch (e) {
        // Ignore security related error
        console.warn(e);
      }
    }

    window.addEventListener("storage", handler);

    return () => {
      window.removeEventListener("storage", handler);
    };
  }, [storageKey]);

  const setValue = React.useCallback(
    (value: string) => {
      storage.setItem(storageKey, value);
      setStateWrapper(value);
    },
    [storageKey]
  );

  const clearValue = React.useCallback(() => {
    storage.removeItem(storageKey);
    setStateWrapper(null);
  }, [storageKey]);

  return [value.value, setValue, clearValue, value.loaded];
}
