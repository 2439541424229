/* @flow */
import * as React from "react";

import styled from "styled-components";
import { colors } from "./theme";

import Popup from "./Popup";

type Props = {
  options: PopupMenuOptions,
  targetEl: Element
};

type IconProps = {
  color: string,
  size: number,
  className: string
};

export type PopupMenuOptions = Array<
  [React.ComponentType<IconProps>, string, Object]
>;

const menuBackgroundColor = "#fff";
const menuPadding = 10;
const menuOptionHeight = 27;

const MenuUL = styled.ul`
  font-size: 13px;
  padding: ${menuPadding}px 0;
  background-color: ${menuBackgroundColor};
  letter-spacing: 0.2px;

  // TODO: This min-width is only necessary because the popupmenu is a child of the ActionLink
  // element, which is narrow. If we refactored the DOM a bit, this might not be necessary.
  min-width: 200px;

  padding: 0;
  margin: 0;
  list-style-type: none;

  .icon {
    margin-right: 15px;
    margin-top: 5px;
    float: left;
  }

  li {
    padding: 0;
    margin: 0;
    height: ${menuOptionHeight}px;
  }

  li > a {
    display: block;
    text-decoration: none;
    color: ${colors.greyishBrown};
    padding: 0 15px;
    height: ${menuOptionHeight}px;
    line-height: ${menuOptionHeight}px;

    &:hover {
      background-color: ${colors.paleGrey};
    }
  }
`;

export default function PopupMenu(props: Props) {
  const popupProps = {
    defaultWidth: 174,
    defaultHeight: menuPadding * 2 + menuOptionHeight * props.options.length,
    color: menuBackgroundColor,
    targetEl: props.targetEl
  };

  return (
    <Popup {...popupProps}>
      <MenuUL>
        {props.options.map((option, i) => (
          <li key={i}>
            <a {...option[2]}>
              {React.createElement(option[0], {
                className: "icon",
                size: 16,
                color: "#333"
              })}
              {option[1]}
            </a>
          </li>
        ))}
      </MenuUL>
    </Popup>
  );
}
