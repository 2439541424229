/* @flow */
import * as React from "react";

import parseSearchString from "./parseSearchString";

import { updateLocation } from "./parseSearchString";
import useLocalStorage from "./useLocalStorage";
import { closestYouTubeLocale } from "./matchLanguage";

import { urlForSearch } from "./urlForRoute";
import { pathnameToRoute } from "./router";

type RenderProps = {|
  targetLang: ?string,
  nativeLang: string,
  onChangeNativeLang: (lang: string) => void,
  onChangeTargetLang: (lang: string) => void
|};

import type { DocumentLocation } from "./useNavigation";

type Props = {
  serverLang: string,
  location: DocumentLocation,
  onReplaceLocation: (string, ?Object, ?string) => void
};

export function useLocale(props: Props): RenderProps {
  const route = pathnameToRoute(props.location.pathname);

  const [
    nativeLang,
    setNativeLang,
    _ignore,
    nativeLangLoaded
  ] = useLocalStorage("nativeLang");

  const [targetLang, setTargetLang] = useLocalStorage("targetLang");
  const navigatorLanguages = useNavigatorLanguages();

  const params = parseSearchString(props.location.search);

  // These will get triggered when the user sets a language from the UI. It should get propogated
  // into the URL and into local storage
  const [nativeLangOverride, setNativeLangOverride] = React.useState(null);
  const [targetLangOverride, setTargetLangOverride] = React.useState(null);

  function pickNativeLang() {
    if (nativeLangOverride != null) {
      return nativeLangOverride;
    } else if ("nl" in params) {
      return params.nl;
    } else if (nativeLang) {
      return nativeLang;
    } else if (navigatorLanguages.length > 0) {
      return navigatorLanguages[0];
    } else {
      return props.serverLang;
    }
  }

  function pickTargetLang() {
    if (targetLangOverride != null) {
      return targetLangOverride;
    } else if (route != null && typeof route.params.targetLang === "string") {
      return route.params.targetLang;
    } else if ("tl" in params) {
      return params.tl;
    } else if (targetLang != null) {
      return targetLang;
    } else {
      return null;
    }
  }

  const chosenNativeLang = closestYouTubeLocale(pickNativeLang());
  const chosenTargetLang = pickTargetLang();

  usePathnameSynchronization({
    ready: nativeLangLoaded,
    nativeLang: chosenNativeLang,
    targetLang: chosenTargetLang,
    onReplaceLocation: props.onReplaceLocation,
    location: props.location
  });

  const onChangeTargetLang = React.useCallback(
    lang => {
      setTargetLang(lang);
      setTargetLangOverride(lang);
    },
    [setTargetLang]
  );

  const onChangeNativeLang = React.useCallback(
    lang => {
      setNativeLang(lang);
      setNativeLangOverride(lang);
    },
    [setNativeLang]
  );

  return {
    nativeLang: chosenNativeLang,
    targetLang: chosenTargetLang,
    onChangeTargetLang,
    onChangeNativeLang
  };
}

function useNavigatorLanguages() {
  const [navigatorLanguages, setNavigatorLanguages] = React.useState([]);

  React.useEffect(() => {
    if (navigator.languages) {
      setNavigatorLanguages(navigator.languages);
    }
  }, []);

  return navigatorLanguages;
}

type Props2 = {
  ready: boolean,
  location: DocumentLocation,
  nativeLang: ?string,
  targetLang: ?string,
  onReplaceLocation: (string, ?Object, ?string) => void
};

// Synchronize the current location with nativeLang and targetLang
export function usePathnameSynchronization(props: Props2) {
  const { onReplaceLocation, location, nativeLang, targetLang } = props;

  const navigateTo = props.ready
    ? shouldReplaceLocationWith(location, nativeLang, targetLang)
    : null;
  const currentPathname = location.pathname;

  React.useEffect(() => {
    if (navigateTo != null) {
      onReplaceLocation(navigateTo, null, currentPathname);
    }
  }, [navigateTo, currentPathname, onReplaceLocation]);
}

// This function determines whether the current location should be updated, based on the
// current values of nativeLang and targetLang
export function shouldReplaceLocationWith(
  location: DocumentLocation,
  nativeLang: ?string,
  targetLang: ?string
): string | null {
  if (nativeLang == null) {
    return null;
  }

  const params = parseSearchString(location.search);

  const route = pathnameToRoute(location.pathname);

  // Update a vanity language url. This route is a little special because the targetLang
  // can be embedded inside a vanity route (/korean, /japanese, etc..)
  if (route != null && route.name === "search") {
    if (typeof route.params.targetLang === "string") {
      if (route.params.targetLang != targetLang || params.nl != nativeLang) {
        return urlForSearch(params, nativeLang, targetLang);
      }
    } else {
      if (params.tl != targetLang || params.nl != nativeLang) {
        return urlForSearch(params, nativeLang, targetLang);
      }
    }
  } else {
    // Update any non-search url
    const update = {};

    if (nativeLang != null && params.nl != nativeLang) {
      update.nl = nativeLang;
    }

    if (targetLang != null && params.tl != targetLang) {
      update.tl = targetLang;
    }

    if (Object.keys(update).length > 0) {
      return updateLocation(location, update);
    }
  }

  return null;
}
