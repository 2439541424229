/* @flow */

import * as geometry from "./geometry";

// Don't allow the video to take up more than 60% of the vertical screen
export const maxHeightPercentage = 60;

export const playerZIndex = 2;

// This is the aspect ratio that should trigger rules which constrain the
// video sized based on the height of the screen.
export const triggerAspectRatio = `${(geometry.videoWidth *
  maxHeightPercentage) /
  100}/${geometry.videoHeight}`;

export function widthCssFromHeight(height: string): string {
  return `calc(
    ${height} * ${geometry.videoWidth} / ${geometry.videoHeight}
  )`;
}

// Include this CSS in things that should be the width of the player
export const widthCss = `
  max-width: ${geometry.videoWidth}px;
  width: 100vw;

  @media (min-aspect-ratio: ${triggerAspectRatio}) {
    width: ${widthCssFromHeight(`${maxHeightPercentage}vh`)}
  }
`;

// Include this CSS in things that should be the height of the player
export const heightCss = `
  max-height: ${geometry.videoHeight}px;
  height: calc(100vw * ${geometry.videoHeight / geometry.videoWidth});

  @media (min-aspect-ratio: ${triggerAspectRatio}) {
    height: ${maxHeightPercentage}vh;
  }
`;

// In some cases (such as mobile landscape) the height is so constrainted that we can't see the subtitle text.
// In this case, we should allow the page header to cover up the player a bit.
export const allowPageHeaderOverlap = `(min-aspect-ratio: ${triggerAspectRatio}) and (max-height: 400px)`;
