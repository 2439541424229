/* @flow */

import * as React from "react";
import styled from "styled-components";

import WithPromise from "./WithPromise";
import WithDerivedState from "./WithDerivedState";
import WithLoginRequiredPage from "./WithLoginRequiredPage";
import PageHeader from "./PageHeader";

import { serially } from "./serially";
import { ButtonLink } from "./Buttons";
import type { UserResourceStore } from "./WithUserResourceStore";
import type { LibraryItem } from "./libraryItems";
import { chunk } from "lodash";
import {
  getChannelSnippets,
  getPlaylistSnippets,
  getVideos
} from "./YoutubeScraperClient";

import LoadingPage from "./LoadingPage";

import * as geometry from "./geometry";

import { stringsForLocale } from "../lang/web";

import {
  MediaCardGrid,
  PlaylistMediaCard,
  ChannelMediaCard,
  VideoMediaCard
} from "./MediaCard";

type Props = {
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  onLogin: Function,
  onLogout: Function,
  isInitialized: boolean,
  targetLang: ?string,
  nativeLang: string,
  userResources: ?UserResourceStore
};

export default function VideoLibraryPage(props: Props) {
  return (
    <WithLoginRequiredPage
      onLogin={props.onLogin}
      isInitialized={props.isInitialized}
      userResources={props.userResources}
      nativeLang={props.nativeLang}
      render={userResources => (
        <WithPromise
          promise={userResources.videoLibrary.libraryItems}
          renderRejected={() => null}
          renderPending={() => <LoadingPage />}
          renderResolved={libraryItems => (
            <VideoLibraryPageView
              onLogin={props.onLogin}
              onLogout={props.onLogout}
              nativeLang={props.nativeLang}
              targetLang={props.targetLang}
              userResources={userResources}
              libraryItems={libraryItems}
            />
          )}
        />
      )}
    />
  );
}

type ViewProps = {
  libraryItems: Array<LibraryItem>,
  onLogin: Function,
  onLogout: Function,
  nativeLang: string,
  targetLang: ?string,
  userResources: UserResourceStore
};

export function VideoLibraryPageView(props: ViewProps) {
  const strings = stringsForLocale(props.nativeLang);
  const videoIds = mediaIdsForType(props.libraryItems, "video");
  const channelIds = mediaIdsForType(props.libraryItems, "channel");
  const playlistIds = mediaIdsForType(props.libraryItems, "playlist");

  function langsForLibraryItem(mediaId, mediaType) {
    var item = props.libraryItems.find(
      iter => mediaId === iter.mediaId && mediaType === iter.mediaType
    );

    if (item == null) {
      return { nativeLang: props.nativeLang, targetLang: props.targetLang };
    } else {
      return {
        nativeLang: item.nativeLang || props.nativeLang,
        targetLang: item.targetLang || props.targetLang
      };
    }
  }

  return (
    <React.Fragment>
      <PageHeader
        onLogin={props.onLogin}
        onLogout={props.onLogout}
        isLoggedIn={true}
        nativeLang={props.nativeLang}
        targetLang={props.targetLang}
        userResources={props.userResources}
      >
        {strings.navigation.video_library_link()}
      </PageHeader>

      <PageContent>
        {props.libraryItems.length === 0 ? (
          <EmptyBox>
            <h2>{strings.library_page.empty_header()}</h2>
            <p>{strings.library_page.empty_description()}</p>
            <ButtonLink href="/search">
              {strings.library_page.search_action()}
            </ButtonLink>
          </EmptyBox>
        ) : (
          <React.Fragment>
            {videoIds.length == 0 ? null : (
              <Box>
                <BoxHeader>{strings.library_page.headers.videos()}</BoxHeader>

                <WithDerivedState
                  controller={fetchChunkedVideoItems}
                  controllerProps={videoIds}
                  render={promise => (
                    <MediaCardGrid>
                      <WithPromise
                        promise={promise}
                        renderPending={() =>
                          videoIds.map(videoId => (
                            <VideoMediaCard
                              {...langsForLibraryItem(videoId, "video")}
                              key={videoId}
                              videoId={videoId}
                              snippet={null}
                              linkState={{ library: true }}
                            />
                          ))
                        }
                        renderRejected={() => null}
                        renderResolved={items =>
                          videoIds.map(videoId =>
                            videoId in items ? (
                              <VideoMediaCard
                                key={videoId}
                                videoId={videoId}
                                snippet={items[videoId]}
                                {...langsForLibraryItem(videoId, "video")}
                                linkState={{ library: true }}
                              />
                            ) : null
                          )
                        }
                      />
                    </MediaCardGrid>
                  )}
                />
              </Box>
            )}

            {channelIds.length == 0 ? null : (
              <Box>
                <BoxHeader>{strings.library_page.headers.channels()}</BoxHeader>
                <WithDerivedState
                  controller={fetchChunkedChannelItems}
                  controllerProps={channelIds}
                  render={promise => (
                    <MediaCardGrid>
                      <WithPromise
                        promise={promise}
                        renderPending={() =>
                          channelIds.map(channelId => (
                            <ChannelMediaCard
                              {...langsForLibraryItem(channelId, "channel")}
                              key={channelId}
                              channelId={channelId}
                              snippet={null}
                              linkState={{ library: true }}
                            />
                          ))
                        }
                        renderRejected={() => null}
                        renderResolved={items =>
                          channelIds.map(channelId =>
                            items[channelId] == null ? null : (
                              <ChannelMediaCard
                                {...langsForLibraryItem(channelId, "channel")}
                                key={channelId}
                                channelId={channelId}
                                snippet={items[channelId]}
                                linkState={{ library: true }}
                              />
                            )
                          )
                        }
                      />
                    </MediaCardGrid>
                  )}
                />
              </Box>
            )}

            {playlistIds.length == 0 ? null : (
              <Box>
                <BoxHeader>
                  {strings.library_page.headers.playlists()}
                </BoxHeader>
                <WithDerivedState
                  controller={fetchChuckedPlaylists}
                  controllerProps={playlistIds}
                  render={promise => (
                    <MediaCardGrid>
                      <WithPromise
                        promise={promise}
                        renderPending={() =>
                          playlistIds.map(playlistId => (
                            <PlaylistMediaCard
                              {...langsForLibraryItem(playlistId, "playlist")}
                              key={playlistId}
                              playlistId={playlistId}
                              snippet={null}
                              linkState={{ library: true }}
                            />
                          ))
                        }
                        renderRejected={() => null}
                        renderResolved={items =>
                          playlistIds.map(playlistId =>
                            items[playlistId] == null ? null : (
                              <PlaylistMediaCard
                                {...langsForLibraryItem(playlistId, "playlist")}
                                key={playlistId}
                                playlistId={playlistId}
                                snippet={items[playlistId]}
                                linkState={{ library: true }}
                              />
                            )
                          )
                        }
                      />
                    </MediaCardGrid>
                  )}
                />
              </Box>
            )}
          </React.Fragment>
        )}
      </PageContent>
    </React.Fragment>
  );
}

function mediaIdsForType(
  list: Array<LibraryItem>,
  mediaType: string
): Array<string> {
  return list
    .filter(item => item.mediaType === mediaType)
    .map(item => item.mediaId);
}

function fetchChucked<OUT>(
  input: Array<string>,
  chunkSize: number,
  processFn: (Array<string>) => Promise<{ [string]: OUT }>
): Promise<{ [string]: OUT }> {
  const chunks = chunk(input, chunkSize);
  return serially(chunks, processFn).then(results =>
    Object.assign({}, ...results)
  );
}
const YOUTUBE_CHUNK_SIZE = 50;

function fetchChunkedVideoItems(ids) {
  return fetchChucked(ids, YOUTUBE_CHUNK_SIZE, getVideos);
}

function fetchChunkedChannelItems(ids) {
  return fetchChucked(ids, YOUTUBE_CHUNK_SIZE, getChannelSnippets);
}

function fetchChuckedPlaylists(ids) {
  return fetchChucked(ids, YOUTUBE_CHUNK_SIZE, getPlaylistSnippets);
}

const PageContent = styled.div`
  padding: 0 15px;
  padding-top: ${geometry.pageHeaderHeight + 15}px;

  line-height: 1.5;
  text-align: left;
  margin: 0 auto;
  max-width: 640px;
`;

const Box = styled.div`
  background-color: #fff;
  border: 1px solid rgba(178, 183, 189, 0.23999999463558197);
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 1em;
`;

const BoxHeader = styled.div`
  font-weight: bold;
  margin: 1em 0;
  &:first-child {
    margin-top: 0;
  }
`;

const EmptyBox = styled(Box)`
  padding: 2em 0;
  text-align: center;
  h2,
  p {
    margin: 0 0 1em 0;
  }
  h2 {
    font-weight: bold;
    font-size: 24px;
  }
`;
