/* @flow */

/*
This creates a callback that is called:
  - when playback starts
  - when playback stops
  - at every interval inbetween
*/
export default class PlaybackInterval {
  constructor(callback: Function, interval: number) {
    this.callback = callback;
    this.interval = interval;
  }

  destroy() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
      delete this.intervalID;
    }
  }

  callback: Function;
  interval: number;
  intervalID: ?IntervalID;

  onPlayerStateChange(event: YT$StateEvent) {
    if (event.data === YT.PlayerState.PLAYING && !this.intervalID) {
      this.callback();
      this.intervalID = setInterval(this.callback, this.interval);
    } else {
      if (this.intervalID) {
        this.callback();
        this.destroy();
      }
    }
  }
}
