/* @flow */

import * as React from "react";

import PopupMenu from "./PopupMenu";
import type { PopupMenuOptions } from "./PopupMenu";

import { fromEvent, merge } from "rxjs";
import { filter, take } from "rxjs/operators";
import type { Subscription } from "rxjs";

import { findWrappingLink } from "./domutils";

type Props = {
  options: PopupMenuOptions,
  className: string,
  children: React.Node
};

type State = {
  open: boolean,
  targetEl: ?Element
};

export default class PopupMenuTrigger extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = { open: false, targetEl: null };

    this.trigger = this.trigger.bind(this);
  }

  trigger: MouseEvent => void;
  subscription: ?Subscription;

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  trigger(event: MouseEvent) {
    event.preventDefault();

    if (this.state.targetEl) {
      close();
    } else {
      const target = event.target;
      if (!(target instanceof Element)) return;

      const el = findWrappingLink(target);

      if (el) {
        this.setState({ targetEl: el });
      }

      const documentClick$ = fromEvent(document, "click");
      const escapeKeys$ = fromEvent(document, "keydown").pipe(
        filter(event => event.keyCode === 27)
      );

      this.subscription = merge(documentClick$, escapeKeys$)
        .pipe(take(1))
        .subscribe(this.close.bind(this));
    }
  }

  close() {
    this.setState({ targetEl: null });

    if (this.subscription) {
      this.subscription.unsubscribe();
      delete this.subscription;
    }
  }

  render() {
    let popup;
    if (this.state.targetEl) {
      popup = (
        <PopupMenu
          options={this.props.options}
          targetEl={this.state.targetEl}
        />
      );
    }

    return (
      <div>
        <a href="#" onClick={this.trigger} className={this.props.className}>
          {this.props.children}
        </a>
        {popup}
      </div>
    );
  }
}
