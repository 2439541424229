/* @flow */

export type Cancellable<T> = {
  cancel: Function,
  promise: Promise<T>
};

// See https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
export function makeCancellable<T>(promise: Promise<T>): Cancellable<T> {
  let hasCancelled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCancelled_ ? reject({ isCancelled: true }) : resolve(val)),
      error => (hasCancelled_ ? reject({ isCancelled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCancelled_ = true;
    }
  };
}
