/* @flow */

import * as React from "react";
import styled, { css } from "styled-components";
import { colors } from "./theme";
import { lighten } from "polished";
import distanceInWords from "date-fns/distance_in_words";
import parseSearchString from "./parseSearchString";
import { getDemoFlashCards, getFlashCardDecks } from "./apiClient";
import FrontBackVideoPlayer, {
  backPanelZIndex,
  instructionsInside,
  flashCardWidth,
  flashCardSize
} from "./FrontBackVideoPlayer";

import type { RenderProps as QuizAttemptRenderProps } from "./WithQuizAttempts";
import type { SnackbarMessage } from "./useSnackbarQueue";

import usePromise from "./usePromise";

import { map, unescape, round, reverse, times } from "lodash";

import ErrorPage, { ActionBar } from "./ErrorPage";
import CloseLink from "./CloseLink";
import ActionLink from "./ActionLink";
import { ActionButton, ButtonLink, SubmitButton } from "./Buttons";
import { PlayTriangle, Close } from "./SvgAssets";
import { WithMostRecentPromises } from "./WithPromises";
import { useSampledInterval } from "./WithInterval";
import useBoundedPlayback from "./useBoundedPlayback";
import WithLoginRequiredPage from "./WithLoginRequiredPage";
import WithRedirect from "./WithRedirect";
import { stateForCard, findDueCards, qualityOfAttempt } from "./quizAttempts";
import PayWallOverlay from "./PayWallOverlay";
import { normalizeLanguage } from "./matchLanguage";
import StarsSVG from "./StarsSVG";

import TranslationPopup from "./TranslationPopup";
import { useTranslationPopup } from "./useTranslationPopup";

import type { DocumentLocation } from "./useNavigation";
import type { FlashCardDeck } from "./api";
import type { UserResourceStore } from "./WithUserResourceStore";
import type {
  Favorite,
  QuizAttempt,
  UnsavedQuizAttempt,
  QuizAttemptResult
} from "./api";
import type { PlayerInitParams } from "./WithPlayerController";
import type { FlashCardFilter } from "./FlashCardsDashboardPage";

import {
  judge,
  diff as calculateDiff,
  inputBackgroundFromDiff,
  normalize
} from "./judgeListening";

import { secondsToString, isValidFormat } from "./mediaTimestampFormat";

type Props = {
  userResources: ?UserResourceStore,
  isInitialized: boolean,
  isLoggedIn: boolean,
  onNavigate: string => void,
  nativeLang: string,
  onLogin: () => void,
  quizAttemptsProps: QuizAttemptRenderProps,
  location: DocumentLocation,
  onAddSnackbarMessage: SnackbarMessage => void
};

import { stringsForLocale } from "../lang/web";

import { useFormState, useFormUtils, useFormNormalizer } from "./WithFormState";
import { useFormValidations } from "./useFormValidations";
import type { FormState } from "./WithFormState";
import { FormField } from "./Forms";

import {
  subtitleFromFormValues,
  formValuesFromSubtitle,
  validationsWithDuration,
  normalizeTimestamp
} from "./editSubtitle";
import type { FormValues } from "./editSubtitle";

export default function FlashCardsReviewPage(props: Props) {
  const filter = filterForLocation(props.location);

  const [now: number] = React.useState(() => Date.now());

  // TODO: Maybe this uses the filter to trigger it
  const demoFlashCardsPromise = usePromise(() => getDemoFlashCards(), []);

  let withIdToken = props.userResources && props.userResources.withIdToken;

  const flashCardDecksPromise = usePromise(() => {
    if (withIdToken) {
      return getFlashCardDecks(withIdToken);
    } else {
      return Promise.resolve([]);
    }
  }, [withIdToken]);

  if ("demo" in filter) {
    return (
      <WithMostRecentPromises
        promises={[props.quizAttemptsProps.quizAttempts, demoFlashCardsPromise]}
        renderPending={() => null}
        renderRejected={() => null}
        renderResolved={([quizAttempts, demoFlashCards]) => (
          <Quiz
            filter={filter}
            isPremium={false}
            favorites={[]}
            isLoggedIn={props.isLoggedIn}
            onLogin={props.onLogin}
            demoFlashCards={demoFlashCards}
            flashCardDecks={[]}
            attemptHistory={quizAttempts}
            onFinishAttempt={props.quizAttemptsProps.onAddQuizAttempt}
            pendingAttempt={props.quizAttemptsProps.pendingAttempt}
            now={now}
            isEntitled={true}
            nativeLang={props.nativeLang}
            onNavigate={props.onNavigate}
            onAddSnackbarMessage={props.onAddSnackbarMessage}
            onUpdateFavorite={favorite => /* no-op */ Promise.resolve(favorite)}
            withIdToken={withIdToken}
          />
        )}
      />
    );
  } else {
    return (
      <WithLoginRequiredPage
        onLogin={props.onLogin}
        loggedOutView={
          <LoggedOutView
            onLogin={props.onLogin}
            nativeLang={props.nativeLang}
          />
        }
        userResources={props.userResources}
        isInitialized={props.isInitialized}
        nativeLang={props.nativeLang}
        render={userResources => (
          <WithMostRecentPromises
            promises={[
              props.quizAttemptsProps.quizAttempts,
              userResources.premiumSubscription.subscription,
              userResources.favorites.favorites,
              demoFlashCardsPromise,
              flashCardDecksPromise
            ]}
            renderPending={() => null}
            renderRejected={() => null}
            renderResolved={([
              quizAttempts,
              subscription,
              favorites,
              demoFlashCards,
              flashCardDecks
            ]) => {
              const isPremium =
                subscription.active || subscription.complimentary;

              return (
                <WithEntitlements
                  quizAttempts={quizAttempts}
                  isPremium={isPremium}
                  render={entitlementProps => (
                    <Quiz
                      filter={filter}
                      onLogin={props.onLogin}
                      isPremium={isPremium}
                      isLoggedIn={props.isLoggedIn}
                      favorites={favorites}
                      demoFlashCards={demoFlashCards}
                      flashCardDecks={flashCardDecks}
                      attemptHistory={quizAttempts}
                      onFinishAttempt={props.quizAttemptsProps.onAddQuizAttempt}
                      pendingAttempt={props.quizAttemptsProps.pendingAttempt}
                      now={entitlementProps.now}
                      isEntitled={entitlementProps.isEntitled}
                      nativeLang={props.nativeLang}
                      onNavigate={props.onNavigate}
                      onAddSnackbarMessage={props.onAddSnackbarMessage}
                      onUpdateFavorite={
                        userResources.favorites.onUpdateFavorite
                      }
                      withIdToken={withIdToken}
                    />
                  )}
                />
              );
            }}
          />
        )}
      />
    );
  }
}

function filterForLocation(location: DocumentLocation): FlashCardFilter {
  const search = parseSearchString(location.search);
  const filter: FlashCardFilter = {};
  if ("lang" in search) {
    filter.lang = search.lang;
  }
  if ("random" in search) {
    filter.random = search.random;
  }
  if ("deck" in search) {
    filter.deck = parseInt(search.deck);
  }
  if ("demo" in search) {
    filter.demo = search.demo;
  }
  return filter;
}

function LoggedOutView(props: { nativeLang: string, onLogin: Function }) {
  const strings = stringsForLocale(props.nativeLang);

  return (
    <ErrorPage nativeLang={props.nativeLang}>
      <p>
        {LocalizeMe(
          "To start reviewing Flash Cards, please sign into your CaptionPop account."
        )}
      </p>

      <ActionBar>
        <ActionButton onActivated={props.onLogin}>
          {strings.page_header.login_action()}
        </ActionButton>
      </ActionBar>
    </ErrorPage>
  );
}

function filterFlashCards(
  favorites: Array<Favorite>,
  demoFlashCards: Array<Favorite>,
  flashCardDecks: Array<FlashCardDeck>,
  attemptHistory: Array<QuizAttempt>,
  filter: FlashCardFilter,
  randomness: number,
  now: number
): Array<Favorite> {
  let list = favorites;

  if (filter.demo) {
    list = demoFlashCards.filter(
      card => card.transcriptionLang === filter.demo
    );
  }

  if (filter.deck) {
    const deck = flashCardDecks.find(deck => deck.id === filter.deck);
    if (deck) {
      list = favorites.filter(favorite =>
        deck.favoriteIds.includes(favorite.id)
      );
    }
  }

  if (filter.lang) {
    const lang = filter.lang;
    list = list.filter(
      i =>
        i.transcriptionLang &&
        normalizeLanguage(i.transcriptionLang) === normalizeLanguage(lang)
    );
  }

  if (filter.random) {
    list = list.splice(Math.floor(randomness * list.length), 1);
  } else {
    list = findDueCards(now, list, attemptHistory);
  }
  return list;
}

type WithEntitlementsProps = {
  isPremium: boolean,
  quizAttempts: Array<QuizAttempt>,
  render: WithEntitlementsRenderProps => React.Node
};

type WithEntitlementsState = {
  now: number
};

type WithEntitlementsRenderProps = {
  isEntitled: boolean,
  now: number
};

class WithEntitlements extends React.Component<
  WithEntitlementsProps,
  WithEntitlementsState
> {
  constructor(props) {
    super(props);

    const now = Date.now();

    this.state = { now };

    if (!props.isPremium && !freeTrialRemaining(now, props.quizAttempts)) {
      this.startInterval();
    }

    this.intervalId = null;
  }

  intervalId: ?IntervalID;

  componentDidUpdate(prevProps) {
    if (prevProps.quizAttempts.length !== this.props.quizAttempts.length) {
      this.tick();
    }
  }

  startInterval() {
    this.stopInterval();
    this.intervalId = setInterval(this.tick.bind(this), 1000);
  }

  stopInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  tick() {
    const now = Date.now();
    this.setState({ now });

    if (this.intervalId) {
      if (freeTrialRemaining(now, this.props.quizAttempts)) {
        this.stopInterval();
      }
    } else {
      if (
        !this.props.isPremium &&
        !freeTrialRemaining(now, this.props.quizAttempts)
      ) {
        this.startInterval();
      }
    }
  }

  componentWillUnmount() {
    this.stopInterval();
  }

  render() {
    const isEntitled =
      this.props.isPremium ||
      freeTrialRemaining(this.state.now, this.props.quizAttempts);

    return this.props.render({
      isEntitled: isEntitled,
      now: this.state.now
    });
  }
}

const FREE_TRIAL_ATTEMPTS = 5;

const slowPlaybackRate = 0.5;

function countAttemptsToday(
  now: number,
  quizAttempts: Array<QuizAttempt>
): number {
  const cutoff = startOfDay(now);
  return quizAttempts.filter(
    attempt => !attempt.free && Date.parse(attempt.createdAt) > cutoff
  ).length;
}

function freeAttemptsRemainingToday(now, quizAttempts): number {
  return FREE_TRIAL_ATTEMPTS - countAttemptsToday(now, quizAttempts);
}

function freeTrialRemaining(
  now: number,
  quizAttempts: Array<QuizAttempt>
): boolean {
  return freeAttemptsRemainingToday(now, quizAttempts) > 0;
}

const dashboardHref = "/flash-cards";

type QuizProps = {
  nativeLang: string,
  onFinishAttempt: UnsavedQuizAttempt => void,
  isLoggedIn: boolean,
  onLogin: () => void,
  now: number,
  favorites: Array<Favorite>,
  demoFlashCards: Array<Favorite>,
  filter: FlashCardFilter,
  attemptHistory: Array<QuizAttempt>,
  flashCardDecks: Array<FlashCardDeck>,
  pendingAttempt: ?UnsavedQuizAttempt,
  isPremium: boolean,
  isEntitled: boolean,
  onNavigate: string => void,
  onUpdateFavorite: (favorite: Favorite) => Promise<Favorite>,
  onAddSnackbarMessage: SnackbarMessage => void,
  withIdToken: ?() => Promise<string>
};

export function Quiz(props: QuizProps) {
  const [random] = React.useState(() => Math.random());

  const [
    showQuizResultsForAttempt,
    setShowQuizResultsForAttempt
  ] = React.useState<UnsavedQuizAttempt | null>(null);

  function findFavorite(favoritesForThisSession: Array<Favorite>): ?Favorite {
    if (showQuizResultsForAttempt != null) {
      const favoriteId = showQuizResultsForAttempt.favoriteId;
      function matcher(f) {
        return f.id === favoriteId;
      }
      return (
        props.favorites.find(matcher) || props.demoFlashCards.find(matcher)
      );
    } else {
      return favoritesForThisSession[0];
    }
  }

  function onNext() {
    setShowQuizResultsForAttempt(null);
  }

  function onFinish(attempt: UnsavedQuizAttempt) {
    setShowQuizResultsForAttempt(attempt);
    props.onFinishAttempt(attempt);
  }

  const optimisticAttemptHistory = makeOptimisticAttemptHistory(
    props.pendingAttempt,
    props.now,
    props.attemptHistory
  );

  const favoritesForThisSession = filterFlashCards(
    props.favorites,
    props.demoFlashCards,
    props.flashCardDecks,
    optimisticAttemptHistory,
    props.filter,
    random,
    props.now
  );

  const favorite = findFavorite(favoritesForThisSession);
  // Uncomment to play with the text matching algorithms
  /*
    const favorite = {
      id: 1,
      videoId: "Tnr4EyTegcs",
      transcriptionLang: 'fr',
      transcriptionLang: 'en',
      createdAt: "Thu, 05 Sep 2019 22:49:42 GMT",
      original: {
        start: 1,
        duration: 1,
      },
      subtitle: {
        start: 1,
        duration: 1,
        transcription: "what's up ",
        translations: []
      }
    }
    */

  if (favorite == null) {
    return (
      <WithRedirect href={dashboardHref} onNavigate={props.onNavigate}>
        <SmallContent>
          <a href={dashboardHref}>return to dashboard</a>
        </SmallContent>
      </WithRedirect>
    );
  } else
    return (
      <QuizWithFoundFavorite
        {...props}
        favorite={favorite}
        showQuizResultsForAttempt={showQuizResultsForAttempt}
        optimisticAttemptHistory={optimisticAttemptHistory}
        onNext={onNext}
        onFinish={onFinish}
        favoritesForThisSession={favoritesForThisSession}
      />
    );
}

type QuizWithFoundFavoriteProps = {
  favorite: Favorite,
  optimisticAttemptHistory: Array<QuizAttempt>,
  favoritesForThisSession: Array<Favorite>,
  showQuizResultsForAttempt: UnsavedQuizAttempt | null,
  onNext: () => void,
  nativeLang: string,
  onFinish: UnsavedQuizAttempt => void,
  isLoggedIn: boolean,
  onLogin: () => void,
  now: number,
  favorites: Array<Favorite>,
  filter: FlashCardFilter,
  isPremium: boolean,
  isEntitled: boolean,
  onUpdateFavorite: (favorite: Favorite) => Promise<Favorite>,
  onAddSnackbarMessage: SnackbarMessage => void,
  withIdToken: ?() => Promise<string>
};

function QuizWithFoundFavorite(props: QuizWithFoundFavoriteProps) {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [startedAt, setStartedAt] = React.useState<number | null>(null);

  // Reset the state when the favorite changes
  React.useEffect(() => {
    // Reset the state
    setIsPlaying(false);
    setStartedAt(null);
    setEditMode(false);
  }, [props.favorite.id]);

  const editFormState = useFormState({
    values: formValuesFromSubtitle(props.favorite.subtitle),
    filterFunctions: {
      start: isValidFormat,
      end: isValidFormat,
      transcription: () => true,
      translations: () => true
    }
  });

  let bannerEl;

  if (props.isLoggedIn) {
    bannerEl = (
      <FreeTrialBanner
        nativeLang={props.nativeLang}
        show={!props.isPremium && props.filter.demo == null}
        attemptsToday={countAttemptsToday(
          props.now,
          props.optimisticAttemptHistory
        )}
      />
    );
  } else {
    bannerEl = (
      <LoggedOutBanner nativeLang={props.nativeLang} onLogin={props.onLogin} />
    );
  }

  const subtitle = editMode
    ? subtitleFromFormValues(editFormState.values)
    : props.favorite.subtitle;

  const playerProps = useBoundedPlayback({
    videoId: props.favorite.videoId,
    timeBlock: subtitle
  });

  const onPlay = (playbackRate: number) => {
    // We don't want to wait for a playbackStateChange event before bringing the player back into
    // view. For this reason, we call setState directly here.
    setIsPlaying(true);

    playerProps.onPlay(playbackRate);
  };

  // Packages the result into a QuizAttempt and passes it to the parrent
  function onFinish(
    favoriteId: number,
    result: QuizAttemptResult,
    hints: Array<number>
  ) {
    const finishedAt = Date.now();

    let duration;
    if (startedAt) {
      duration = finishedAt - startedAt;
    } else {
      duration = 0;
    }

    const attempt = {
      duration,
      hints,
      favoriteId,
      result,
      showTranslation: false
    };

    props.onFinish(attempt);
  }

  function onPlayerStateChange(event: YT$StateEvent) {
    setStartedAt(value => {
      if (event.data === YT.PlayerState.PLAYING && value == null) {
        mixpanel.track("Played a Flash Card");
        return Date.now();
      } else {
        return value;
      }
    });

    setIsPlaying(
      event.data === YT.PlayerState.PLAYING ||
        event.data === YT.PlayerState.BUFFERING
    );
  }

  // XXX: Duplicated inside QuizInner
  const answer = cleanWhitespace(
    unescape(props.favorite.subtitle.transcription)
  );

  const strings = stringsForLocale(props.nativeLang);

  function onSubmit() {
    const subtitle = subtitleFromFormValues(editFormState.values);
    const update = { ...props.favorite, subtitle };
    const promise = props.onUpdateFavorite(update);
    setEditMode(false);

    promise.then(
      () => {
        // LocalizeMe
        props.onAddSnackbarMessage({
          body: strings.snackbar.updated_favorite_caption(),
          level: "message"
        });
      },
      error => {
        // LocalizeMe
        props.onAddSnackbarMessage({
          body: "There was an error saving the update to your caption.",
          level: "error"
        });
      }
    );

    setEditMode(false);
  }

  return (
    <PageContent>
      <MyCloseLink href={dashboardHref} />

      <WithForm
        answer={answer}
        onFinish={onFinish.bind(null, props.favorite.id)}
        render={formProps => {
          let modeProps: QuizModeProps;

          if (editMode) {
            modeProps = {
              mode: "editing",
              onSubmit: onSubmit,
              onCancel: () => {
                setEditMode(false);
              },
              formState: editFormState
            };
          } else {
            if (props.showQuizResultsForAttempt == null) {
              modeProps = {
                ...formProps,
                mode: "unfinished",
                onPass: formProps.onPass
              };
            } else {
              modeProps = {
                mode: "finished",
                showQuizResultsForAttempt: props.showQuizResultsForAttempt,
                onNext: props.onNext,
                onEdit: () => {
                  setEditMode(true);
                  editFormState.onReset();
                },
                now: props.now,
                dueCount: props.filter.random
                  ? 0
                  : props.favoritesForThisSession.length,
                attemptHistory: props.optimisticAttemptHistory
              };
            }
          }

          const initParams = {
            ...playerProps.initParams,
            onPlayerStateChange: event => {
              playerProps.initParams.onPlayerStateChange(event);
              onPlayerStateChange(event);
            }
          };

          return (
            <React.Fragment>
              <QuizInner
                onPlay={onPlay}
                initParams={initParams}
                favorite={props.favorite}
                isPlaying={isPlaying}
                startedAt={startedAt}
                editable={!("demo" in props.filter)}
                nativeLang={props.nativeLang}
                canPlaySlow={playerProps.availablePlaybackRates.includes(
                  slowPlaybackRate
                )}
                videoDuration={playerProps.duration}
                modeProps={modeProps}
                withIdToken={props.withIdToken}
              />

              {!props.isEntitled && !props.showQuizResultsForAttempt ? (
                <PayWallOverlay
                  now={props.now}
                  startOfDay={startOfDay(props.now)}
                  nativeLang={props.nativeLang}
                />
              ) : null}
            </React.Fragment>
          );
        }}
      />

      {bannerEl}
    </PageContent>
  );
}

// Creates an optimistic view of the attempt history, assuming that the pending attempt is
// successfully written to the API.
function makeOptimisticAttemptHistory(
  pendingAttempt: ?UnsavedQuizAttempt,
  now: number,
  attemptHistory: Array<QuizAttempt>
): Array<QuizAttempt> {
  if (pendingAttempt) {
    return attemptHistory.concat({
      ...pendingAttempt,
      id: 0,
      createdAt: new Date(now).toISOString(),
      free: false
    });
  } else {
    return attemptHistory;
  }
}

type QuizInnerProps = {
  onPlay: (playbackRate: number) => void,
  initParams: PlayerInitParams,
  favorite: Favorite,
  isPlaying: boolean,
  startedAt: ?number,
  editable: boolean,
  nativeLang: string,
  canPlaySlow: boolean,
  videoDuration: number,
  modeProps: QuizModeProps,
  withIdToken: ?() => Promise<string>
};

// QuizInner takes different props, depending on the mode it's in
type QuizModeProps =
  | {
      mode: "finished",
      showQuizResultsForAttempt: UnsavedQuizAttempt,
      onNext: () => void,
      now: number,
      dueCount: number,
      attemptHistory: Array<QuizAttempt>,
      onEdit: () => void
    }
  | {
      mode: "unfinished",
      onPass: () => void,
      value: string,
      hints: Array<number>,
      onChangeEvent: (e: Event) => void,
      onRequestHint: (index: number) => void
    }
  | {
      mode: "editing",
      formState: FormState<FormValues>,
      onSubmit: () => void,
      onCancel: () => void
    };

export function QuizInner(props: QuizInnerProps) {
  const playerSectionProps = {
    key: props.favorite.id,
    startedAt: props.startedAt,
    initParams: props.initParams,
    isPlaying: props.isPlaying,
    onPlay: props.onPlay
  };

  const answer = cleanWhitespace(
    unescape(props.favorite.subtitle.transcription)
  );

  if (props.modeProps.mode === "unfinished") {
    const unfinishedProps = props.modeProps;

    const instructionsText = instructionsTextForUnfinished(
      props.startedAt,
      props.isPlaying,
      props.nativeLang
    );

    const timerEl =
      props.startedAt != null ? <Timer startedAt={props.startedAt} /> : null;

    return (
      <React.Fragment>
        <Instructions>{instructionsText}</Instructions>
        <FrontBackVideoPlayer
          {...playerSectionProps}
          headerEl={
            <FlashCardHeader
              cornerEl={timerEl}
              instructionText={instructionsText}
            />
          }
        >
          {props.startedAt ? (
            <FlashCardForm
              onPlay={props.onPlay}
              startedAt={props.startedAt}
              onPass={unfinishedProps.onPass}
              answer={answer}
              isPlaying={props.isPlaying}
              nativeLang={props.nativeLang}
              value={unfinishedProps.value}
              hints={unfinishedProps.hints}
              onRequestHint={unfinishedProps.onRequestHint}
              onChangeEvent={unfinishedProps.onChangeEvent}
              canPlaySlow={props.canPlaySlow}
            />
          ) : null}
        </FrontBackVideoPlayer>
      </React.Fragment>
    );
  } else if (props.modeProps.mode === "finished") {
    const finishedProps = props.modeProps;
    const mostRecentAttempt = finishedProps.showQuizResultsForAttempt;

    const attemptsForThisFavorite = finishedProps.attemptHistory.filter(
      attempt => attempt.favoriteId === mostRecentAttempt.favoriteId
    );

    const strings = stringsForLocale(props.nativeLang).flash_cards;
    const instructionsText = instructionsTextForFinished(
      mostRecentAttempt.result,
      props.nativeLang
    );

    return (
      <React.Fragment>
        <Instructions>{instructionsText}</Instructions>
        <FrontBackVideoPlayer
          {...playerSectionProps}
          headerEl={
            <FlashCardHeader
              cornerEl={
                props.editable ? (
                  <EditCaptionButton
                    nativeLang={props.nativeLang}
                    onActivated={finishedProps.onEdit}
                  />
                ) : null
              }
              instructionText={instructionsText}
            />
          }
        >
          <QuizResults
            attempt={mostRecentAttempt}
            translations={props.favorite.subtitle.translations}
            answer={answer}
            nativeLang={props.nativeLang}
            withIdToken={props.withIdToken}
            dueAgain={stateForCard(attemptsForThisFavorite).interval}
          />

          <FlashCardActionBar
            onPlay={props.onPlay}
            canPlaySlow={props.canPlaySlow}
            isFinished={true}
            nativeLang={props.nativeLang}
            actionElement={
              finishedProps.dueCount === 0 ? (
                <FinishButton primary href={dashboardHref}>
                  {strings.actions.finish()}
                </FinishButton>
              ) : (
                <NextButton primary onActivated={finishedProps.onNext}>
                  {strings.actions.more_to_review({
                    COUNT: finishedProps.dueCount
                  })}
                </NextButton>
              )
            }
          />
        </FrontBackVideoPlayer>
        <PreviousAttemptsList
          attempts={attemptsForThisFavorite.slice(0, -1)}
          answer={answer}
          now={finishedProps.now}
          nativeLang={props.nativeLang}
        />
      </React.Fragment>
    );
  } else if (props.modeProps.mode === "editing") {
    const editingProps = props.modeProps;
    const strings = stringsForLocale(props.nativeLang);
    const instructionsText = strings.edit_caption_form.header();

    return (
      <React.Fragment>
        <Instructions>{instructionsText}</Instructions>
        <FrontBackVideoPlayer {...playerSectionProps} headerEl={null}>
          <EditCaptionForm
            videoDuration={props.videoDuration}
            onSubmit={editingProps.onSubmit}
            onCancel={editingProps.onCancel}
            onPlay={props.onPlay}
            canPlaySlow={props.canPlaySlow}
            formState={editingProps.formState}
            nativeLang={props.nativeLang}
          />
        </FrontBackVideoPlayer>
      </React.Fragment>
    );
  } else {
    // Shouldn't happen unless an invalid "mode" is passed
    return null;
  }
}

// We need to style the form so that is stretched the height of the flash card. Alternatively,
// we could use "display: contents" and use the parent's flex layout.
const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FormFields = styled.div`
  margin-top: 20px;
  padding: 0 10px;
  input,
  textarea {
    width: 100%;
    box-sizing: border-box;
  }
`;

const EditFormActions = styled.div`
  a {
    margin-right: 1em;
  }
`;

const TimestampRow = styled.div`
  display: flex;
  > div:first-child {
    margin-right: 1em;
  }
`;

type EditCaptionFormProps = {
  formState: FormState<FormValues>,
  videoDuration: number,
  nativeLang: string,
  canPlaySlow: boolean,
  onPlay: (playbackRate: number) => void,
  onCancel: () => void,
  onSubmit: () => void
};

function EditCaptionForm(props: EditCaptionFormProps) {
  const formUtils = useFormUtils(props.formState);

  const onBlur = useFormNormalizer({
    values: props.formState.values,
    normalizeFunctions: {
      start: normalizeTimestamp,
      end: normalizeTimestamp
    },
    onChange: props.formState.onChange
  });

  const [errorMessages, formEventHandlers] = useFormValidations({
    locale: props.nativeLang,
    values: props.formState.values,
    validations: validationsWithDuration(props.videoDuration),
    onSubmit: props.onSubmit
  });

  const strings = stringsForLocale(props.nativeLang);

  return (
    <Form {...formEventHandlers}>
      <FlashCardContent>
        <FormFields>
          <TimestampRow onBlur={onBlur}>
            <FormField
              {...formUtils.propsForInput("start")}
              errorMessages={errorMessages.start}
              label={strings.edit_caption_form.labels.start_time()}
              renderInput={props => <input {...props} type="text" />}
            />

            <FormField
              {...formUtils.propsForInput("end")}
              errorMessages={errorMessages.end}
              label={strings.edit_caption_form.labels.end_time()}
              renderInput={props => <input {...props} type="text" />}
            />
          </TimestampRow>

          <FormField
            {...formUtils.propsForInput("transcription")}
            errorMessages={errorMessages.transcription}
            label={strings.edit_caption_form.labels.transcription()}
            renderInput={props => <input {...props} type="text" />}
          />
          <FormField
            {...formUtils.propsForInput("translations")}
            errorMessages={errorMessages.translations}
            label={strings.edit_caption_form.labels.translations()}
            renderInput={props => <textarea {...props} type="text" />}
          />
        </FormFields>
      </FlashCardContent>

      <FlashCardActionBar
        onPlay={props.onPlay}
        canPlaySlow={props.canPlaySlow}
        isFinished={true}
        nativeLang={props.nativeLang}
        actionElement={
          <EditFormActions>
            <ActionLink onActivated={props.onCancel}>
              {strings.edit_caption_form.actions.nevermind()}
            </ActionLink>
            <SaveButton
              primary
              value={strings.edit_caption_form.actions.save_changes()}
            />
          </EditFormActions>
        }
      />
    </Form>
  );
}

const PageContent = styled.div`
  padding-top: 30px;
  @media ${instructionsInside} {
    padding-top: 0;
  }
`;

const MyCloseLink: typeof CloseLink = styled(CloseLink)`
  @media ${instructionsInside} {
    display: none;
  }
`;

const showBlankCss = `
  position: relative;
  &::after {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    content: "␣"; // &blank;
  }
`;

const SpaceWithBlank = styled.span`
  ${props => (props.showBlank ? showBlankCss : "")};
`;

const HintInstructions = styled.div`
  font-size: 1rem;
  text-align: center;
  margin: 10px 0;
`;

const SmallContent = styled.div`
  ${flashCardSize} margin: 25px auto;
  background-color: white;
  padding: 15px;
  padding-top: 125px;
  box-sizing: border-box;
  border: 1px solid #eee;
  line-height: 1.5;
  box-shadow: 2px 2px 5px #888;
  text-align: center;
`;

function instructionsTextForFinished(
  result: QuizAttemptResult,
  nativeLang: string
) {
  const strings = stringsForLocale(nativeLang).flash_cards.instructions;

  if (result === "pass") {
    return strings.finish_pass();
  } else {
    return strings.finish_complete();
  }
}

function instructionsTextForUnfinished(
  startedAt: ?number,
  isPlaying: boolean,
  nativeLang: string
): string {
  const strings = stringsForLocale(nativeLang).flash_cards.instructions;

  if (startedAt != null) {
    if (isPlaying) {
      return strings.playing_state();
    } else {
      return strings.quiz_state();
    }
  } else {
    return strings.before_playback();
  }
}

function startOfDay(now: number): number {
  const nowDate = new Date(now);
  return +new Date(
    nowDate.getFullYear(),
    nowDate.getMonth(),
    nowDate.getDate(),
    0,
    0,
    0
  );
}

function hintListAsString(hints: Array<number>, answer: string): string {
  if (hints.length === 0) {
    return " - ";
  } else {
    return hints.map(i => answer[i]).join(",");
  }
}

const ReviewStats = styled.ul`
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    li {
      min-width: 50%;
    }
  }

  li {
    flex-grow: 1;
    margin-bottom: 1em;
  }
`;
const StatValue = styled.div`
  font-size: 200%;
`;
const StatHeader = styled.div`
  font-weight: bold;
`;

const FinishedContent = styled.div`
  color: #333;
  text-align: left;
  margin: 0 auto;
  margin-top: 25px;
  ${flashCardWidth};

  table {
    width: 100%;
  }
`;

const Instructions = styled.div`
  margin: 15px 0;
  color: #333;
  font-size: 200%;
  font-weight: bold;
  text-align: center;

  display: block;
  @media ${instructionsInside} {
    display: none;
  }
`;

const FinishedTranscription = styled.div`
  font-size: 200%;
  margin-bottom: 0.5em;
  border-radius: 2px;
  color: #333;

  display: inline-block;

  ${props =>
    props.success
      ? css`
          padding: 15px;
          background-color: ${lighten(0.5, colors.highlight)};
          box-shadow: 0px 0px 2px 2px ${colors.highlight};
        `
      : null};
`;

function PreviousAttemptsList(props: {
  attempts: Array<QuizAttempt>,
  answer: string,
  now: number,
  nativeLang: string
}) {
  const strings = stringsForLocale(props.nativeLang).flash_cards.finished;

  if (props.attempts.length > 0) {
    return (
      <FinishedContent>
        <h2>{strings.previous_attempts_header()}</h2>
        <table>
          <thead>
            <tr>
              <td />
              <td>{strings.hints_used()}</td>
              <td>{strings.time_taken()}</td>
              <td>{strings.score()}</td>
            </tr>
          </thead>
          <tbody>
            {reverse(props.attempts).map((attempt, i: number) => (
              <tr key={i}>
                <td>{distanceInWords(attempt.createdAt, props.now)}</td>
                <td>{hintListAsString(attempt.hints, props.answer)}</td>
                <td>{secondsToString(Math.floor(attempt.duration / 1000))}</td>
                <td>
                  <StarsTableItem score={qualityOfAttempt(attempt)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </FinishedContent>
    );
  } else {
    return null;
  }
}

// Match any space or punctuation
const FREE_CHARACTERS = /[\d\s“”‘’« »„ “„ ”» «"\\\?\!\@\#\$\%\^\&\*\(\)\-\_\=\+\<\>\?\,\.\/\[\]\{\}\|\:\;`。、¡]+/g;

function isFreeCharacter(character: string): boolean {
  return character[0].match(FREE_CHARACTERS) != null;
}

function cleanWhitespaceAndFreeCharacters(input: string): string {
  return input.replace(FREE_CHARACTERS, " ").trim();
}

export function cleanWhitespace(input: string): string {
  return input.replace(/\s+/g, " ").trim();
}

function stylesForScore(props) {
  if (props.score === "correct") {
    return css`
      background-color: ${lighten(0.5, colors.highlight)};
      color: #333;
      > span {
        visibility: visible;
      }
    `;
  } else if (props.showHint) {
    return css`
      background: none;

      color: ${props => (props.score === "correct" ? "#333" : "#ddd")};

      > span {
        visibility: visible;
      }
    `;
  } else if (props.free) {
    return css`
      background: none;
      color: #ddd;
      > span {
        visibility: visible;
      }
    `;
  } else {
    return css`
      background-color: #ddd;

      cursor: pointer;
      &:hover {
        background-color: #ccc;
      }

      > span {
        visibility: hidden;
      }
    `;
  }
}

function millisecondsToString(milliseconds) {
  return secondsToString(Math.floor(milliseconds / 1000));
}

function Timer(props: { startedAt: number }) {
  const now = useSampledInterval({
    interval: 1000,
    sample: React.useCallback(() => Date.now(), [])
  });

  return (
    <span>
      {timerSvg} {millisecondsToString(now - props.startedAt)}
    </span>
  );
}

const PassLink: typeof ActionLink = styled(ActionLink)`
  color: #333;
  font-size: 75%;
  line-height: 50px;
`;

const buttonStyles = `
  height: 50px;
  line-height: 50px;
  padding: 0px 15px;
  box-sizing: border-box;
`;

const SaveButton: typeof SubmitButton = styled(SubmitButton)`
  ${buttonStyles};
`;

const NextButton: typeof ActionButton = styled(ActionButton)`
  ${buttonStyles};
`;

const FinishButton: typeof ButtonLink = styled(ButtonLink)`
  ${buttonStyles};
`;

const inputPadding = 15;
const inputBackgroundZIndex = 1;
const inputFontSize = 32;
const inputLineHeight = 1.15;

// There is a lot of pixel perfect matching of font-rendering going on.
// We use this block to make sure everything is rendering the same.
const textLayoutCss = css`
  letter-spacing: 0;
  white-space: pre;
  font-family: monospace;
  font-size: ${inputFontSize}px;
  line-height: ${inputLineHeight};
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-numeric: normal;

  // Kerning and ligatures can cause text to be rendering slimmer than when we wrap
  // everything in <span>s.
  font-kerning: none;
  font-variant-ligatures: none;
`;

const CharacterWrapper = styled.span`
  ${textLayoutCss};
  white-space: pre-wrap;
  line-height: 1.5em;
  padding: 0 ${inputPadding}px;
`;

const InputBackgroundPosition = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding-top: ${inputPadding}px;
  padding-bottom: ${inputPadding}px;
  ${textLayoutCss};

  // Override "white-space: pre" from textLayoutCss. This is overridden again on the background spans.
  white-space: nowrap;
`;

const InputBackgroundPadding = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${inputPadding}px;
  right: ${inputPadding}px;
  overflow: hidden;
`;

const InputBackgroundWrapper = styled.div`
  position: absolute;

  // Ideally, this height should be calculated by the browser layout engine somehow. Hopefully, this calculation
  // works in most browsers.
  height: ${Math.floor(inputFontSize * inputLineHeight) + inputPadding * 2}px;

  box-sizing: border-box;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${inputBackgroundZIndex};
  background-color: white;
`;

const Character = styled.span`
  padding: 2px 0;
  ${props => stylesForScore(props)};
`;

const InputBackgroundCharacter = styled.span`
  display: inline-block;
  white-space: pre;

  background-color: ${props => (props.correct ? "#fff" : colors.warning)};
  color: ${props =>
    process.env["NODE_ENV"] === "development"
      ? "blue"
      : props.correct
      ? "#fff"
      : colors.warning};
`;

const answerSideMargin = 10;
const Answer = styled.div`
  display: inline-block;
  margin: 0 ${answerSideMargin}px;
  max-width: calc(100% - ${answerSideMargin * 2}px);
  text-align: left;

  position: relative;
  padding-top: 80px;
`;

const Input = styled.input`
  display: block;
  padding: 13px;
  border-width: 2px;
  margin-bottom: 10px;
  position: absolute;
  top: 0;
  z-index: ${inputBackgroundZIndex + 1};
  width: 100%;
  box-sizing: border-box;
  background: none;
  ${textLayoutCss};
`;

const TranslationStyles = styled.div`
  margin-bottom: 1em;
`;

function Translation(props: { translations: Array<string> }): React.Node {
  return (
    <TranslationStyles>
      {props.translations.map((translation, i: number) => (
        <div key={i} dangerouslySetInnerHTML={{ __html: translation }} />
      ))}
    </TranslationStyles>
  );
}

const timerSvg = (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    height="15px"
    width="15px"
    enableBackground="new 0 0 100 100"
    xmlSpace="preserve"
  >
    <path d="M50.266,89.868c21.989,0,39.878-17.889,39.878-39.878S72.254,10.112,50.266,10.112c-1.657,0-3,1.343-3,3v15.371  c0,1.657,1.343,3,3,3s3-1.343,3-3V16.244c17.28,1.524,30.878,16.077,30.878,33.746c0,18.68-15.198,33.878-33.878,33.878  S16.388,68.67,16.388,49.99c0-10.78,5.205-21.015,13.924-27.381c1.338-0.977,1.631-2.854,0.654-4.192  c-0.977-1.338-2.854-1.631-4.192-0.654c-10.26,7.491-16.386,19.538-16.386,32.227C10.388,71.979,28.277,89.868,50.266,89.868z" />
    <path d="M30.173,38.723c-0.834,1.432-0.35,3.268,1.083,4.102l16.75,9.757c0.475,0.277,0.994,0.408,1.507,0.408  c1.033,0,2.038-0.534,2.595-1.491c0.834-1.432,0.35-3.268-1.083-4.102l-16.75-9.757C32.845,36.806,31.007,37.291,30.173,38.723z" />
  </svg>
);

const slowMotionSvg = (
  <svg
    width="20px"
    viewBox="0 0 24 24"
    stroke="#333"
    style={{ left: 13, top: 7 }}
  >
    <path d="M13.05 9.79L10 7.5v9l3.05-2.29L16 12zm0 0L10 7.5v9l3.05-2.29L16 12zm0 0L10 7.5v9l3.05-2.29L16 12zM11 4.07V2.05c-2.01.2-3.84 1-5.32 2.21L7.1 5.69c1.11-.86 2.44-1.44 3.9-1.62zM5.69 7.1L4.26 5.68C3.05 7.16 2.25 8.99 2.05 11h2.02c.18-1.46.76-2.79 1.62-3.9zM4.07 13H2.05c.2 2.01 1 3.84 2.21 5.32l1.43-1.43c-.86-1.1-1.44-2.43-1.62-3.89zm1.61 6.74C7.16 20.95 9 21.75 11 21.95v-2.02c-1.46-.18-2.79-.76-3.9-1.62l-1.42 1.43zM22 12c0 5.16-3.92 9.42-8.95 9.95v-2.02C16.97 19.41 20 16.05 20 12s-3.03-7.41-6.95-7.93V2.05C18.08 2.58 22 6.84 22 12z" />
  </svg>
);

const FlashCardActionBarStyles = styled.div`
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  flex: 0;
  flex-basis: 50px;
  overflow: hidden;
`;

const PlayButtons = styled.div`
  flex: 0;
  display: flex;
  flex-direction: row;

  .play-normal {
    margin-right: 5px;
  }
`;

const RightSizeAction = styled.div`
  flex: 1;
  text-align: right;
`;

type FlashCardActionBarProps = {
  actionElement: React.Node,
  isFinished: boolean,
  onPlay: (playbackRate: number) => void,
  nativeLang: string,
  canPlaySlow: boolean
};

function FlashCardActionBar(props: FlashCardActionBarProps) {
  const strings = stringsForLocale(props.nativeLang).flash_cards;

  return (
    <FlashCardActionBarStyles>
      <PlayButtons>
        <PlayButton
          primary={!props.isFinished}
          onActivated={props.onPlay.bind(null, 1.0)}
          className="play-normal"
        >
          <PlayTriangle
            width={10}
            color={props.isFinished ? "#333" : "white"}
          />
          <span>{strings.actions.replay()}</span>
        </PlayButton>

        {props.canPlaySlow ? (
          <PlayButton onActivated={props.onPlay.bind(null, slowPlaybackRate)}>
            {slowMotionSvg}
            <span>{Math.floor(100 * slowPlaybackRate)}%</span>
          </PlayButton>
        ) : null}
      </PlayButtons>

      <RightSizeAction>{props.actionElement}</RightSizeAction>
    </FlashCardActionBarStyles>
  );
}

const PlayButton = styled(ActionButton)`
  display: block;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  position: relative;

  svg {
    position: absolute;
    left: 19px;
    top: 10px;
  }

  span {
    display: block;
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    font-size: 75%;
  }
`;

function LocalizeMe(string) {
  return string;
}

const FreeTrialBannerStyles = styled.div`
  text-align: left;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${props => (props.show ? "block" : "none")};

  overflow: hidden;
  box-sizing: border-box;

  .wrapper {
    background-color: #eee;
    border-top: 1px solid #dedede;
    line-height: 1.5em;
    padding: 0.5em 15px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
  }

  .text {
    flex-grow: 1;
  }

  .text,
  .call-to-action {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .progress {
    display: flex;
    > svg {
      margin-right: 5px;
    }
  }
`;

type FreeTrialBannerProps = {
  show: boolean,
  attemptsToday: number,
  nativeLang: string
};

function FreeTrialBanner(props: FreeTrialBannerProps) {
  const remainingCount = Math.max(FREE_TRIAL_ATTEMPTS - props.attemptsToday, 0);
  const strings = stringsForLocale(props.nativeLang).flash_cards
    .free_trial_banner;

  return (
    <FreeTrialBannerStyles show={props.show}>
      <div className="wrapper">
        <div className="text">
          {strings.flash_cards_remaining({ COUNT: remainingCount })}

          <div className="progress">
            {times(remainingCount).map(i => (
              <ProgressBlock
                key={i}
                on={true}
                text={i === remainingCount - 1 ? String(remainingCount) : null}
              />
            ))}
            {times(props.attemptsToday).map(i => (
              <ProgressBlock key={i} on={false} text={null} />
            ))}
          </div>
        </div>

        <div className="call-to-action">
          <ButtonLink href="/premium-subscription" primary>
            {strings.upgrade_action()}
          </ButtonLink>
        </div>
      </div>
    </FreeTrialBannerStyles>
  );
}

function LoggedOutBanner(props: { onLogin: Function, nativeLang: string }) {
  const strings = stringsForLocale(props.nativeLang);

  return (
    <FreeTrialBannerStyles show={true}>
      <div className="wrapper">
        <div className="text">{strings.flash_cards.logged_out_warning()}</div>

        <div className="call-to-action">
          <ActionButton onActivated={props.onLogin} primary>
            {strings.page_header.login_action()}
          </ActionButton>
        </div>
      </div>
    </FreeTrialBannerStyles>
  );
}

function ProgressBlock(props: { text: ?string, on: boolean }) {
  return (
    <svg width="25px" height="25px" viewBox="0 0 100 100">
      <circle
        cx="50"
        cy="50"
        r="50"
        fill={props.on ? colors.highlight : "#dedede"}
        stroke={props.on ? null : "#555"}
      />
      {props.text ? (
        <text
          x="50"
          y="55"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="400%"
          fill="#fff"
        >
          {props.text}
        </text>
      ) : null}
    </svg>
  );
}

const FlashCardContent = styled.div`
  flex-grow: 1;
`;

function QuizResults(props: {
  attempt: UnsavedQuizAttempt,
  translations: Array<string>,
  answer: string,
  nativeLang: string,
  dueAgain: number,
  withIdToken: ?() => Promise<string>
}) {
  const translationPopupProps = useTranslationPopup({
    nativeLang: props.nativeLang,
    withIdToken: props.withIdToken
  });

  const translationPopupEl =
    translationPopupProps != null ? (
      <TranslationPopup
        {...translationPopupProps}
        nativeLang={props.nativeLang}
        zIndex={backPanelZIndex + 1}
      />
    ) : null;

  const strings = stringsForLocale(props.nativeLang).flash_cards.finished;

  return (
    <FlashCardContent>
      <QuizResultsContent>
        <FinishedTranscription
          className="subtitle-transcription"
          success={props.attempt.result === "completed"}
        >
          {props.answer}
        </FinishedTranscription>
        <Translation translations={props.translations} />
      </QuizResultsContent>

      <ReviewStats>
        <li>
          <StatHeader>{strings.time_taken()}</StatHeader>
          <StatValue>
            {secondsToString(Math.floor(props.attempt.duration / 1000))}
          </StatValue>
        </li>
        <li>
          <StatHeader>{strings.hints_used()}</StatHeader>
          <StatValue>
            {hintListAsString(props.attempt.hints, props.answer)}
          </StatValue>
        </li>

        <li>
          <StatHeader>{strings.due_again()}</StatHeader>
          <StatValue>
            {stringsForLocale(props.nativeLang).flash_cards.days_count({
              COUNT: props.dueAgain
            })}
          </StatValue>
        </li>

        <li>
          <StatHeader>{strings.score()}</StatHeader>
          <StatValueWithStars score={qualityOfAttempt(props.attempt)} />
        </li>
      </ReviewStats>
      {translationPopupEl}
    </FlashCardContent>
  );
}

const QuizResultsContent = styled.div`
  text-align: center;
  padding: 0 20px;
`;

type FlashCardFormProps = {
  startedAt: number,
  canPlaySlow: boolean,
  answer: string,
  isPlaying: boolean,
  value: string,
  hints: Array<number>,
  onPass: () => void,
  onPlay: number => void,
  onChangeEvent: (e: Event) => void,
  onRequestHint: (index: number) => void,
  nativeLang: string
};

type FlashCardFormState = {
  inputScrollLeft: number
};

class FlashCardForm extends React.Component<
  FlashCardFormProps,
  FlashCardFormState
> {
  constructor(props) {
    super(props);
    this.state = {
      inputScrollLeft: 0,
      value: "",
      hints: []
    };
    this.inputRef = React.createRef();
  }

  inputRef: { current: ?HTMLInputElement };

  componentDidUpdate(prevProps: FlashCardFormProps) {
    if (prevProps.isPlaying && !this.props.isPlaying && this.inputRef.current) {
      // Firefox 63.0.1 seems to have an issue when transferring focus from an iframe to something on the
      // main document. If we don't blur the IFRAME first, we can an invisible cursor in the INPUT element.
      // I think it's due to this really old bug: https://bugzilla.mozilla.org/show_bug.cgi?id=554039
      if (
        document.activeElement &&
        document.activeElement.nodeName === "IFRAME"
      ) {
        document.activeElement.blur();
      }

      this.inputRef.current.focus();
    }
  }

  syncScroll() {
    // For onBlur in Chrome we need to wait until the next tick before scrollLeft is updated. This
    // is probably the case for other events as well.
    setTimeout(() => {
      if (this.inputRef.current) {
        this.setState({ inputScrollLeft: this.inputRef.current.scrollLeft });
      }
    }, 0);
  }

  render() {
    // Attach this to any event that might cause the value of scrollLeft in the INPUT element to change
    const syncScroll = this.syncScroll.bind(this);

    const diff = calculateDiff(this.props.value, this.props.answer);
    const correctness = judge(diff);

    const strings = stringsForLocale(this.props.nativeLang).flash_cards;

    return (
      <FlashCardLayout>
        <FlashCardContent>
          <Answer>
            <Input
              ref={this.inputRef}
              value={this.props.value}
              onChange={e => {
                this.props.onChangeEvent(e);
                syncScroll();
              }}
              onKeyUp={syncScroll}
              onBlur={syncScroll}
              onFocus={syncScroll}
              onMouseDown={syncScroll}
              onMouseUp={syncScroll}
              onSelect={syncScroll}
              onWheel={syncScroll}
            />

            <InputBackgroundWrapper>
              <InputBackgroundPadding>
                <InputBackgroundPosition
                  style={{
                    left: -this.state.inputScrollLeft,
                    right: this.state.inputScrollLeft
                  }}
                >
                  {inputBackgroundFromDiff(diff).map((item, i) => (
                    <InputBackgroundCharacter key={i} correct={item.correct}>
                      {this.props.value[i]}
                    </InputBackgroundCharacter>
                  ))}
                </InputBackgroundPosition>
              </InputBackgroundPadding>
            </InputBackgroundWrapper>

            <CharacterWrapper>
              {map(this.props.answer, (character, i: number) => (
                <Character
                  key={i}
                  score={correctness[i]}
                  free={isFreeCharacter(character)}
                  showHint={this.props.hints.includes(i)}
                  onClick={
                    correctness[i] === "correct" ||
                    isFreeCharacter(character) ||
                    this.props.hints.includes(i)
                      ? null
                      : this.props.onRequestHint.bind(null, i)
                  }
                >
                  <SpaceWithBlank
                    showBlank={
                      character === " " && correctness[i] !== "correct"
                    }
                  >
                    {character}
                  </SpaceWithBlank>
                </Character>
              ))}
            </CharacterWrapper>
          </Answer>
          <HintInstructions>{strings.hint_tip()}</HintInstructions>
        </FlashCardContent>
        <FlashCardActionBar
          onPlay={this.props.onPlay}
          isFinished={false}
          nativeLang={this.props.nativeLang}
          canPlaySlow={this.props.canPlaySlow}
          actionElement={
            <PassLink onActivated={this.props.onPass}>
              {strings.actions.skip()}
            </PassLink>
          }
        />
      </FlashCardLayout>
    );
  }
}

type WithFormProps = {
  render: WithFormRenderProps => React.Node,
  answer: string,
  onFinish: (result: QuizAttemptResult, hints: Array<number>) => void
};

type WithFormState = {
  value: string,
  hints: Array<number>
};

type WithFormRenderProps = {
  value: string,
  hints: Array<number>,
  onChangeEvent: (e: Event) => void,
  onRequestHint: (index: number) => void,
  onPass: () => void
};

class WithForm extends React.Component<WithFormProps, WithFormState> {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      hints: []
    };
  }

  onFinish(result: QuizAttemptResult) {
    this.setState({ value: "", hints: [] });
    this.props.onFinish(result, this.state.hints);
  }

  onPass() {
    this.onFinish("pass");
  }

  onChangeEvent(e: Event) {
    if (e.target instanceof HTMLInputElement) {
      const newValue = e.target.value;
      if (
        normalize(cleanWhitespaceAndFreeCharacters(newValue)) ==
        normalize(cleanWhitespaceAndFreeCharacters(this.props.answer))
      ) {
        this.onFinish("completed");
      } else {
        this.setState({ value: newValue });
      }
    }
  }

  onRequestHint(index: number) {
    this.setState(state => {
      if (!state.hints.includes(index)) {
        return { hints: state.hints.concat(index) };
      } else {
        return null;
      }
    });
  }

  render() {
    return this.props.render({
      value: this.state.value,
      hints: this.state.hints,
      onRequestHint: this.onRequestHint.bind(this),
      onChangeEvent: this.onChangeEvent.bind(this),
      onPass: this.onPass.bind(this)
    });
  }
}

const StatValueWithStarsStyles = styled(StatValue)`
  svg {
    display: block;
    margin: 0 auto;
  }
`;

function StatValueWithStars(props: { score: number }) {
  return (
    <StatValueWithStarsStyles>
      {round(props.score, 1)}
      <StarsSVG score={props.score} height={16} />
    </StatValueWithStarsStyles>
  );
}

const ScoreTableItem = styled.span`
  margin-left: 10px;
`;

function StarsTableItem(props: { score: number }) {
  return (
    <React.Fragment>
      <StarsSVG score={props.score} height={16} />
      <ScoreTableItem>{round(props.score, 1)}</ScoreTableItem>
    </React.Fragment>
  );
}

const FlashCardLayout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;

  // This is the height of an iPhone 6/7/8 in landscape
  @media (orientation: landscape) and (max-height: 375px) {
    flex-direction: row;
    padding: 0px 20px;

    ${FlashCardContent} {
      order: 1;
      flex: 1;
    }

    ${FlashCardActionBarStyles} {
      display: contents;
    }

    ${PlayButtons}, ${RightSizeAction} {
      flex: 0;
      flex-basis: 50px;
    }

    ${PlayButtons} {
      order: 0;
      flex-direction: column;

      .play-normal {
        margin-bottom: 5px;
      }
    }

    ${RightSizeAction} {
      order: 2;
    }
  }
`;

const EditCaptionButtonStyles: typeof ActionLink = styled(ActionLink)`
  display: block;
  margin-top: 1em;
  text-align: center;
  text-decoration: none;
  color: #333;
  width: 32px;
  line-height: 1em;

  svg {
    display: block;
    margin: 0 auto;
    margin-bottom: 0.25em;
  }
`;

function EditCaptionButton(props: {
  onActivated: Function,
  nativeLang: string
}) {
  const strings = stringsForLocale(props.nativeLang);

  return (
    <EditCaptionButtonStyles onActivated={props.onActivated}>
      {pencilSvgEl}
      {strings.flash_cards.finished.edit_action()}
    </EditCaptionButtonStyles>
  );
}

const pencilSvgEl = (
  <svg width="16px" height="16px" viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="#000000" fillRule="nonzero">
        <path
          d="M9.94919518,2.67019387 L13.2039571,5.94086502 L4.96525588,14.2199138 L1.71233929,10.9492427 L9.94919518,2.67019387 Z M15.6736919,1.88138298 L14.2221933,0.422775946 C13.6612392,-0.140925315 12.7503657,-0.140925315 12.1875058,0.422775946 L10.7971153,1.81997563 L14.0518772,5.09067717 L15.6736919,3.46091994 C16.1087694,3.02368135 16.1087694,2.31859118 15.6736919,1.88138298 Z M0.00905723451,15.5464342 C-0.050175258,15.8143162 0.190505902,16.0543521 0.457112621,15.9892056 L4.08399861,15.1055173 L0.831082015,11.8348461 L0.00905723451,15.5464342 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

const FlashCardHeaderWrapper = styled.div`
  height: 4em;
  line-height: 4em;
  position: relative;

  .timer {
    position: absolute;
    top: 0;
    right: 1.5em;
  }
  .close {
    display: none;
  }
  .centered-text {
    display: none;
  }

  @media ${instructionsInside} {
    .timer {
      position: absolute;
      top: 0;
      left: 20px;
      right: auto;
      display: block;
    }
    .close {
      position: absolute;
      top: 0;
      right: 20px;
      left: auto;
      display: block;
    }
    .centered-text {
      text-align: center;
      font-weight: bold;
      display: block;
    }
  }
`;

function FlashCardHeader(props: {
  instructionText: string,
  cornerEl: React.Node
}) {
  return (
    <FlashCardHeaderWrapper>
      <div className="timer">{props.cornerEl}</div>

      <div className="centered-text">{props.instructionText}</div>

      <div className="close">
        <a href={dashboardHref}>
          <Close color="#333" size={15} className="" />
        </a>
      </div>
    </FlashCardHeaderWrapper>
  );
}
