/* @flow */

import * as React from "react";

import type { FlashCardDeck } from "./api";
import { getFlashCardDecks } from "./apiClient";

import usePromise from "./usePromise";

type Props = {
  withIdToken: () => Promise<string>,
  render: (Promise<Array<FlashCardDeck>> | null) => React.Node
};

export default function WithFlashCardDecks(props: Props) {
  const promise = usePromise(() => getFlashCardDecks(props.withIdToken), [
    props.withIdToken
  ]);
  return props.render(promise);
}
