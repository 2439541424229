/* @flow */

// These are utility functions and validations that are necessary to build
// an edit caption form.

import { escape, unescape } from "lodash";

import { stringToSeconds, secondsToString } from "./mediaTimestampFormat";

import { required } from "./useFormValidations";

import { stringsForLocale } from "../lang/web";

import type { Validation, ValidationMap } from "./useFormValidations";
import type { MergedText } from "./mergeSubtitles";

export type FormValues = {
  start: string,
  end: string,
  transcription: string,
  translations: string
};

function isBlank(str) {
  return str.trim() == "";
}

export function subtitleFromFormValues(values: FormValues): MergedText {
  const start = stringToSeconds(values.start);
  const end = stringToSeconds(values.end);

  const translations = values.translations
    .split("\n")
    .filter(text => !isBlank(text))
    .map(escape);

  return {
    start: start,
    duration: end - start,
    transcription: escape(values.transcription),
    translations: translations
  };
}

function timestampToString(timestamp: number): string {
  return secondsToString(Math.floor(timestamp * 1000) / 1000);
}

export function formValuesFromSubtitle(subtitle: MergedText): FormValues {
  return {
    start: timestampToString(subtitle.start),
    end: timestampToString(subtitle.start + subtitle.duration),
    transcription: unescape(subtitle.transcription),
    translations: subtitle.translations.map(unescape).join("\n")
  };
}

export function validationsWithDuration(
  duration: number
): $Exact<ValidationMap<FormValues>> {
  const validations: $Exact<ValidationMap<FormValues>> = {
    start: [required],
    end: [required, comesAfter("start")],
    transcription: [required],
    translations: []
  };

  if (duration != 0) {
    validations.end.push(lessThan(duration));
  }
  return validations;
}

function comesAfter<T: { [string]: string }>(
  otherKey: $Keys<T>
): Validation<T> {
  return {
    validate(value: string, key: $Keys<T>, values: T) {
      return stringToSeconds(value) > stringToSeconds(values[otherKey]);
    },
    message(locale: string): string {
      return stringsForLocale(locale).form_validations.comes_after_start();
    }
  };
}

function lessThan(upperBound: number) {
  return {
    validate(value: string, _key: $Keys<FormValues>, _values: FormValues) {
      return stringToSeconds(value) < upperBound;
    },
    message(locale: string): string {
      return stringsForLocale(locale).form_validations.too_big();
    }
  };
}

export function normalizeTimestamp(value: string): string {
  if (value.trim() === "") return "";
  else return secondsToString(stringToSeconds(value));
}
