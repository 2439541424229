/* @flow */

import React from "react";

export type FlatPromise<T> =
  | {
      value: T,
      state: "resolved"
    }
  | {
      error: Error,
      state: "rejected"
    }
  | {
      state: "pending"
    };

// This is a simple hook that converts a Promise into a "flat" datastructure that is easy to
// render inside of a component.
export function useFlatPromise<T>(
  promise: Promise<T> | null
): FlatPromise<T> | null {
  const [result, setResult] = React.useState<FlatPromise<T> | null>(null);

  React.useEffect(() => {
    let subscribed = true;

    if (promise == null) {
      setResult(null);
    } else {
      setResult({ state: "pending" });

      promise.then(
        value => {
          if (subscribed) {
            setResult({ value, state: "resolved" });
          }
        },
        error => {
          if (subscribed) {
            setResult({ error, state: "rejected" });
          }
        }
      );

      return () => {
        subscribed = false;
      };
    }
  }, [promise]);

  return result;
}

// This is a simpler version of useFlatPromise that only exposes the resolved value
export function usePromiseValue<T>(promise: Promise<T> | null): T | null {
  const [result, setResult] = React.useState<T | null>(null);

  React.useEffect(() => {
    let subscribed = true;

    if (promise == null) {
      setResult(null);
    } else {
      promise.then(value => {
        if (subscribed) {
          setResult(value);
        }
      });

      return () => {
        subscribed = false;
      };
    }
  }, [promise]);

  return result;
}

export function usePromiseSubscription<T>(
  promise: Promise<T> | null,
  resolve: T => void,
  reject: any => void
) {
  const resolveRef = React.useRef(resolve);
  const rejectRef = React.useRef(reject);

  React.useEffect(() => {
    resolveRef.current = resolve;
  }, [resolve]);

  React.useEffect(() => {
    rejectRef.current = reject;
  }, [reject]);

  React.useEffect(() => {
    let subscribed = true;

    if (promise != null) {
      promise.then(
        value => {
          if (subscribed) {
            resolveRef.current(value);
          }
        },
        error => {
          if (subscribed) {
            rejectRef.current(error);
          }
        }
      );

      return () => {
        subscribed = false;
      };
    }
  }, [promise]);
}
