/* @flow */

import * as React from "react";

import type { TimeBlock } from "./mergeSubtitles";

// First string is videoId
type Playlist = Array<[string, Array<TimeBlock>]>;

type Props = {
  playlist: Playlist,
  render: RenderProps => React.Node
};

type State = {
  playerState: number,
  duration: number,
  cursor: PlayerCursor,
  mostRecentLoad: LoadParams,
  availablePlaybackRates: Array<number>
};

type RenderProps = {
  initParams: PlayerInitParams,
  play: (playbackRate: number) => void,
  pause: () => void,
  onSeek: (cursor: PlayerCursor) => void,
  onAction: (action: PlayerAction) => void,
  playerState: number,
  duration: number,
  cursor: PlayerCursor,
  availablePlaybackRates: Array<number>
};

export type PlayerInitParams = {
  onPlayerReady: (event: YT$Event) => void,
  onPlayerDestroy?: () => void,
  onPlayerStateChange: (event: YT$StateEvent) => void,
  videoId: string,
  startSeconds?: number,
  endSeconds?: number
};

type LoadParams = {
  videoId: string,
  startSeconds: number,
  endSeconds: number
};

export type PlayerAction = "toggle-playback" | "previous" | "next" | "repeat";

export type PlayerCursor = {
  videoIndex: number,
  subtitleIndex: number
};

export default class WithPlayerController extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.timeoutId = null;

    this.state = {
      playerState: -1, // UNSTARTED,
      duration: 0,
      cursor: { videoIndex: 0, subtitleIndex: 0 },
      mostRecentLoad: this.getInitialLoadParams(),
      availablePlaybackRates: []
    };
  }

  timeoutId: ?TimeoutID;
  player: YT$Player;

  getInitialLoadParams(): LoadParams {
    // Initialize the player with the first item in the playlist. Subsequent
    // changes will be done through the iframe player API, not here.
    const playlistItem = this.props.playlist[0];

    return {
      videoId: playlistItem[0],
      startSeconds: playlistItem[1][0].start,
      endSeconds: playlistItem[1][0].start + playlistItem[1][0].duration
    };
  }

  onAction(action: PlayerAction) {
    switch (action) {
      case "toggle-playback":
        this.togglePlayback();
        break;
      case "repeat":
        this.playAtCursor(this.state.cursor, 1.0);
        break;

      case "next":
        this.moveNext();
        break;

      case "previous":
        this.movePrevious();
        break;
    }
  }

  movePrevious() {
    const cursor = this.previousCursor();
    if (cursor) {
      this.onSeek(cursor);
    }
  }

  moveNext() {
    const cursor = this.nextCursor();
    if (cursor) {
      this.onSeek(cursor);
    }
  }

  togglePlayback() {
    if (!this.player) return;
    const p = this.player;
    if (p.getPlayerState() !== YT.PlayerState.PLAYING) {
      p.playVideo();
    } else {
      p.pauseVideo();
    }
  }

  onPlayerReady(event: YT$Event) {
    this.player = event.target;
    this.setState({
      availablePlaybackRates: this.player.getAvailablePlaybackRates()
    });
  }

  onPlayerStateChange(event: YT$StateEvent) {
    this.setState({ playerState: event.data });

    if (this.player) {
      this.setState({ duration: this.player.getDuration() });
    }
  }

  onSeek(cursor: PlayerCursor) {
    this.playAtCursor(cursor, 1.0);

    // This needs to be called after playAtCursor, so it can be smart enough
    // to trigger a new video. TODO: This isn't very clear. Reconsider this.
    this.setState({ cursor });
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  play(playbackRate: number) {
    this.playAtCursor(this.state.cursor, playbackRate);
  }

  pause() {
    if (this.player) {
      this.player.pauseVideo();
    }
  }

  videoIdForIndex(videoIndex: number) {
    return this.props.playlist[videoIndex][0];
  }

  timeBlockForCursor(cursor: PlayerCursor): TimeBlock {
    const timeBlock = this.props.playlist[cursor.videoIndex][1][
      cursor.subtitleIndex
    ];

    if (timeBlock == null) {
      // This is an attempt to track down this error:
      // https://sentry.io/organizations/jonathan-baudanza/issues/93525326
      throw new Error(
        `Invalid cursor: ${cursor.videoIndex} ${cursor.subtitleIndex}`
      );
    } else {
      return timeBlock;
    }
  }

  // TODO: Consider merging this with useBoundedPlayback in EditFavoritesPage
  playAtCursor(cursor: PlayerCursor, playbackRate: number) {
    const timeBlock = this.timeBlockForCursor(cursor);

    if (this.player) {
      this.player.setPlaybackRate(playbackRate);

      const oldId = this.videoIdForIndex(this.state.cursor.videoIndex);
      const newId = this.videoIdForIndex(cursor.videoIndex);

      const loadParams = {
        videoId: newId,
        startSeconds: timeBlock.start,
        endSeconds: timeBlock.start + timeBlock.duration
      };

      if (
        oldId !== newId ||
        loadParams.endSeconds > this.state.mostRecentLoad.endSeconds
      ) {
        // This spread is necessary to keep flow happy.
        this.player.loadVideoById({ ...loadParams });
        this.setState({ mostRecentLoad: loadParams });
      } else {
        this.player.seekTo(timeBlock.start, true);
        this.player.playVideo();
      }

      const timeout = (timeBlock.duration / playbackRate) * 1000;

      this.timeoutId = setTimeout(this.checkPlayback.bind(this), timeout);
    }
  }

  nextCursor(): ?PlayerCursor {
    const subtitleIndex = this.state.cursor.subtitleIndex + 1;

    if (
      subtitleIndex <
      this.props.playlist[this.state.cursor.videoIndex][1].length
    ) {
      return {
        videoIndex: this.state.cursor.videoIndex,
        subtitleIndex: subtitleIndex
      };
    } else {
      const videoIndex = this.state.cursor.videoIndex + 1;
      if (videoIndex < this.props.playlist.length) {
        return { videoIndex: videoIndex, subtitleIndex: 0 };
      } else {
        null;
      }
    }
  }

  previousCursor(): ?PlayerCursor {
    if (this.state.cursor.subtitleIndex === 0) {
      if (this.state.cursor.videoIndex === 0) {
        return null;
      } else {
        const videoIndex = this.state.cursor.videoIndex - 1;
        return {
          subtitleIndex: this.props.playlist[videoIndex][1].length - 1,
          videoIndex: videoIndex
        };
      }
    } else {
      return {
        subtitleIndex: this.state.cursor.subtitleIndex - 1,
        videoIndex: this.state.cursor.videoIndex
      };
    }
  }

  checkPlayback() {
    this.timeoutId = null;

    const timeBlock = this.timeBlockForCursor(this.state.cursor);

    if (this.player) {
      const endSeconds = timeBlock.start + timeBlock.duration;
      const currentTime = this.player.getCurrentTime();
      if (currentTime > endSeconds) {
        this.player.pauseVideo();
      } else {
        const playbackRate = this.player.getPlaybackRate();
        const timeout = ((endSeconds - currentTime) / playbackRate) * 1000;

        this.timeoutId = setTimeout(this.checkPlayback.bind(this), timeout);
      }
    }
  }

  render() {
    const initParams = {
      ...this.getInitialLoadParams(),
      onPlayerReady: this.onPlayerReady.bind(this),
      onPlayerStateChange: this.onPlayerStateChange.bind(this)
    };

    return this.props.render({
      initParams: initParams,
      play: this.play.bind(this),
      pause: this.pause.bind(this),
      duration: this.state.duration,
      playerState: this.state.playerState,
      onSeek: this.onSeek.bind(this),
      onAction: this.onAction.bind(this),
      cursor: this.state.cursor,
      availablePlaybackRates: this.state.availablePlaybackRates
    });
  }
}
