/* @flow */

import * as React from "react";
import type { PlayerInitParams } from "./WithPlayerController";

import type { TimeBlock } from "./mergeSubtitles";

type Props = {
  timeBlock: TimeBlock | null,
  videoId: string
};

export type PlayerProps = {
  initParams: PlayerInitParams,
  playerState: number,
  duration: number,
  availablePlaybackRates: Array<number>,
  onPlay: (playbackRate: number) => void,
  getCurrentTime(): number | null
};

export type PlayerControllerProps = {
  initParams: PlayerInitParams,
  playerState: number,
  duration: number,
  availablePlaybackRates: Array<number>,
  getCurrentTime(): number | null
};

type PlayerRef = {| current: YT$Player | null |};

export default function useBoundedPlayback(props: Props): PlayerProps {
  const playerRef = React.useRef<YT$Player | null>(null);
  const controllerProps = usePlayerController(
    props.videoId,
    props.timeBlock,
    playerRef
  );

  const startPauseTimer = usePauseTimeout(playerRef);

  const { startSeconds, endSeconds } = unpackTimeBlock(props.timeBlock);

  const onPlay = React.useCallback(
    (playbackRate: number) => {
      if (playerRef.current && startSeconds != null && endSeconds != null) {
        const player = playerRef.current;
        player.setPlaybackRate(playbackRate);
        if (player.getPlayerState() != YT.PlayerState.CUED) {
          player.seekTo(startSeconds, true);
        }
        player.playVideo();

        startPauseTimer(startSeconds, endSeconds, playbackRate);
      }
    },
    [
      startSeconds,
      endSeconds,
      startPauseTimer /* startPauseTimer for the sake of eslint */
    ]
  );

  let initParams;
  if (props.timeBlock == null) {
    initParams = controllerProps.initParams;
  } else {
    initParams = {
      ...controllerProps.initParams,
      startSeconds: props.timeBlock.start,
      endSeconds: props.timeBlock.start + props.timeBlock.duration
    };
  }

  return {
    ...controllerProps,
    initParams,
    onPlay
  };
}

type Props2 = {
  videoId: string
};

type PlayerProps2 = {
  initParams: PlayerInitParams,
  playerState: number,
  duration: number,
  availablePlaybackRates: Array<number>,
  onPlay: (playbackRate: number, timeBlock: TimeBlock) => void,
  getCurrentTime(): number | null
};

// This works similiar to useBoundedPlayback, except it doesn't cue the video to any particular point.
export function useBoundedPlayback2(props: Props2): PlayerProps2 {
  const playerRef = React.useRef<YT$Player | null>(null);
  const controllerProps = usePlayerController(props.videoId, null, playerRef);

  const startPauseTimer = usePauseTimeout(playerRef);

  // This works by seeking to the appropriate point and then starting a playback timer.
  // Alternatively, it could work by calling loagVideoById({videoId, startSeconds, endSeconds}), but
  // this method seems faster.
  const onPlay = React.useCallback(
    function(playbackRate: number, timeBlock: TimeBlock) {
      if (playerRef.current != null) {
        const player = playerRef.current;
        player.setPlaybackRate(playbackRate);
        player.seekTo(timeBlock.start, true);
        player.playVideo();
        startPauseTimer(
          timeBlock.start,
          timeBlock.start + timeBlock.duration,
          playbackRate
        );
      }
    },
    [startPauseTimer /* startPauseTimer for the sake of eslint */]
  );

  return {
    ...controllerProps,
    onPlay
  };
}

function usePlayerController(
  videoId: string,
  timeBlock: TimeBlock | null,
  playerRef: PlayerRef
): PlayerControllerProps {
  const [playerState, setPlayerState] = React.useState<number>(-1);
  const [duration, setDuration] = React.useState<number>(0);
  const [availablePlaybackRates, setAvailablePlaybackRates] = React.useState<
    Array<number>
  >([]);

  const getCurrentTime = React.useCallback(() => {
    if (playerRef.current == null) {
      return null;
    } else {
      return playerRef.current.getCurrentTime();
    }
  }, [playerRef /* playerRef for the sake of eslint */]);

  const onPlayerStateChange = React.useCallback((event: YT$StateEvent) => {
    setDuration(event.target.getDuration());
    setPlayerState(event.data);
  }, []);

  const { startSeconds, endSeconds } = unpackTimeBlock(timeBlock);

  const onPlayerReady = React.useCallback(
    (event: YT$Event) => {
      const player = event.target;

      setAvailablePlaybackRates(player.getAvailablePlaybackRates());

      /*
        The player init params that are sent to the iframe can only cue to even integers.
        So we use the cueVideoId API to get a more precise cueing.

        It may be tempting to add player to state and do this as a dependency array. This won't work,
        because updates to state are asychronous, and we need to guarantee that no operations are triggered
        on the player after it is destroyed (through a child unmount)
      */
      if (startSeconds != null && endSeconds != null) {
        player.cueVideoById({ videoId, startSeconds, endSeconds });
      }

      playerRef.current = player;
    },
    [
      videoId,
      startSeconds,
      endSeconds,
      playerRef /* playerRef for the sake of eslint */
    ]
  );

  React.useEffect(() => {
    if (playerRef.current != null) {
      const player = playerRef.current;

      if (startSeconds != null && endSeconds != null) {
        player.cueVideoById({ videoId, startSeconds, endSeconds });
      } else {
        player.cueVideoById({ videoId });
      }
    }
  }, [
    startSeconds,
    endSeconds,
    videoId,
    playerRef /* playerRef for the sake of eslint */
  ]);

  const onPlayerDestroy = React.useCallback(() => {
    playerRef.current = null;
  }, [playerRef /* playerRef for the sake of eslint */]);

  const initParams = {
    videoId,
    onPlayerReady,
    onPlayerStateChange,
    onPlayerDestroy
  };

  return {
    initParams,
    playerState,
    duration,
    availablePlaybackRates,
    getCurrentTime
  };
}

//
// This effect runs a timer that calls pauseVideo at the appropriate point in the timeline.
//
export function usePauseTimeout(
  playerRef: PlayerRef
): (number, number, playbackRate: number) => void {
  // We can't rely on the playbackRate in the player because it seems to be updated asynchronously (and slowly)
  const [playbackRate, setPlaybackRate] = React.useState<number>(1);
  const [currentTime, setCurrentTime] = React.useState<number | null>(null);
  const [endSeconds, setEndSeconds] = React.useState<number | null>(null);

  // We cannot guarantee that the currentTime will advance in-between when a timeout is scheduled and
  // when a timeout is triggered. For this reason, we used a counter in the dependency array to retrigger timeouts.
  const [counter, setCounter] = React.useState<number>(0);

  function increment() {
    setCounter(i => i + 1);
  }

  const start = React.useCallback(function(
    currentTime,
    endSeconds,
    playbackRate
  ) {
    // Assume that when this is called, currentTime = startTime. It might feel cleaner to
    // call player.getCurrentTime instead, but there's no guarantee the player will be ready
    // with the correct time.
    setPlaybackRate(playbackRate);
    setCurrentTime(currentTime);
    setEndSeconds(endSeconds);
    increment();
  },
  []);

  React.useEffect(() => {
    if (currentTime != null && endSeconds != null && playerRef.current) {
      const player = playerRef.current;
      const timeout = endSeconds - currentTime;

      let timeoutId: ?TimeoutID = setTimeout(() => {
        timeoutId = null;

        const currentTime = player.getCurrentTime();
        if (currentTime > endSeconds) {
          player.pauseVideo();
          setCurrentTime(null);
          setEndSeconds(null);
          increment();
        } else {
          // If the player is in a paused state, give up trying to extend the timer.
          if (player.getPlayerState() !== YT.PlayerState.PAUSED) {
            // This will trigger another timer
            setCurrentTime(currentTime);
            increment();
          }
        }
      }, (timeout / playbackRate) * 1000);

      return () => {
        if (timeoutId != null) {
          clearTimeout(timeoutId);
        }
      };
    }
  }, [
    counter,
    currentTime,
    endSeconds,
    playbackRate,
    playerRef /* playerRef for the sake of eslint */
  ]);

  return start;
}

function unpackTimeBlock(timeBlock: TimeBlock | null) {
  if (timeBlock == null) {
    return {
      startSeconds: null,
      endSeconds: null
    };
  } else {
    return {
      startSeconds: timeBlock.start,
      endSeconds: timeBlock.start + timeBlock.duration
    };
  }
}
