/* @flow */

import * as React from "react";
import styled from "styled-components";
import { Box as _Box, PageContent } from "./FormStyles";
import { ButtonLink } from "./Buttons";
import PageHeader from "./PageHeader";

import { stringsForLocale } from "../lang/web";
import {
  useVideoPosition,
  //  useFavoritesCount,
  DemoVideo as FlashCardDemoVideo,
  FeatureList as FlashCardFeatureList
} from "./FlashCardsSplashPage";

type Props = {
  userResources: ?UserResourceStore,
  nativeLang: string,
  targetLang: ?string,
  onLogin: (flashMessage?: string) => void,
  onLogout: () => void,
  isLoggedIn: boolean
};

import PositioningWrapper from "./PositioningWrapper";
import useDynamicScrollPosition from "./useDynamicScrollPosition";

import type { UserResourceStore } from "./WithUserResourceStore";

import mp4Url from "../assets/google-translate-demo.mp4";
import webmUrl from "../assets/google-translate-demo.webm";

import { GreyRectangle } from "./LoadingTextPlaceholder";

const videoSize = { width: 1068, height: 238 };

export default function PremiumSplashPage(props: Props) {
  const strings = stringsForLocale(props.nativeLang).premium_splash_page;

  const scrollProps = useDynamicScrollPosition();

  React.useEffect(() => {
    if (typeof mixpanel === "object") {
      mixpanel.track("Viewed Premium Splash Page");
    }
  }, []);

  const callToActionEl = (
    <CallToActionWrapper>
      <ButtonLink href="/premium-subscription" primary>
        {strings.call_to_action()}
      </ButtonLink>
    </CallToActionWrapper>
  );

  return (
    <React.Fragment>
      <PositioningWrapper headerRetracted={scrollProps.headerRetracted}>
        <PageHeader
          fixed={false}
          onLogin={props.onLogin}
          onLogout={props.onLogout}
          isLoggedIn={props.isLoggedIn}
          nativeLang={props.nativeLang}
          targetLang={props.targetLang}
          userResources={props.userResources}
          hidePremiumButton={true}
        />
      </PositioningWrapper>
      <PageContent>
        <PageTitle>{strings.page_title()}</PageTitle>

        <Box>
          <Header>{strings.header1()}</Header>

          <FeatureList>
            <tbody>
              <Row>
                <Column />
                <Column>{strings.free_column_header()}</Column>
                <Column>{strings.premium_column_header()}</Column>
              </Row>

              <Row>
                <Column>{strings.feature1()}</Column>
                <Column>{checkMarkSvg}</Column>
                <Column>{checkMarkSvg}</Column>
              </Row>

              <Row>
                <Column>{strings.feature2()}</Column>
                <Column>{checkMarkSvg}</Column>
                <Column>{checkMarkSvg}</Column>
              </Row>

              <Row>
                <Column>{strings.feature3()}</Column>
                <Column>{emptyCircleCvg}</Column>
                <Column>{checkMarkSvg}</Column>
              </Row>

              <Row>
                <Column>{strings.feature4()}</Column>
                <Column>{strings.free_flash_card_limit()}</Column>
                <Column>{checkMarkSvg}</Column>
              </Row>
            </tbody>
          </FeatureList>

          {callToActionEl}
        </Box>

        <Box>
          <Header>{strings.header2()}</Header>

          <p>{strings.google_translate_description()}</p>

          <TranslateDemoVideo>
            <video autoPlay muted loop playsInline>
              <source src={mp4Url} type='video/mp4;codecs="avc1.64002A"' />
              <source src={webmUrl} type='video/webm;codecs="vp8"' />
            </video>
          </TranslateDemoVideo>

          {callToActionEl}
        </Box>

        <Box>
          <Header>{strings.header3()}</Header>
          <FlashCardDemo nativeLang={props.nativeLang} />
          {callToActionEl}
        </Box>
      </PageContent>
    </React.Fragment>
  );
}

function FlashCardDemo(props: { nativeLang: string }) {
  const videoProps = useVideoPosition();

  return (
    <React.Fragment>
      <DemoVideoWrapper>
        <FlashCardDemoVideo ref={videoProps.ref} />
      </DemoVideoWrapper>
      <FlashCardFeatureList
        sampleTime={videoProps.sampleTime}
        onSeek={videoProps.onSeek}
        nativeLang={props.nativeLang}
        backgroundColor="#fff"
      />
    </React.Fragment>
  );
}

const DemoVideoWrapper = styled.div`
  margin-bottom: 2em;
`;

const TranslateDemoVideo = styled(GreyRectangle).attrs({
  aspectRatio: videoSize.width / videoSize.height
})`
  margin-bottom: 2em;
  max-width: ${videoSize.width / 2}px;
  border: 1px solid #eee;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 5px 5px 5px rgb(204, 204, 204);
`;

const CallToActionWrapper = styled.div`
  margin: 0.5em 0;
`;

const FeatureList = styled.table`
  text-align: left;
  margin: 1em auto;
  border-spacing: 0;

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const Row = styled.tr`
  line-height: 2em;
`;

const Column = styled.td`
  &:nth-child(1) {
    padding: 0.5em;
  }
  &:nth-child(2),
  &:nth-child(3) {
    text-align: center;
    white-space: nowrap;
  }
`;

const Box = styled(_Box)`
  text-align: center;
  background-color: white;
  margin-bottom: 2em;
  padding-top: 0;
  border: 1px solid #979797;

  h2 {
    color: #333;
    font-size: 1.5em;
    font-weight: bold;
    line-height: inherit;
    text-transform: none;
    margin: 1em 0;
  }
`;

const PageTitle = styled.h1`
  text-align: center;
  font-size: 30px;
  margin-top: 3em;
`;

const Header = styled.h2`
  font-size: 24px;
`;

const checkMarkSvg = (
  <svg width="21px" height="21px" viewBox="0 0 21 21">
    <circle fill="#18BC9C" cx="10.5" cy="10.5" r="10.5" />
    <path d="M4.5,11.5 L8.5,14.5" stroke="#FFFFFF" strokeLinecap="square" />
    <path d="M15.5,7.5 L8.5,14.5" stroke="#FFFFFF" strokeLinecap="square" />
  </svg>
);

const emptyCircleCvg = (
  <svg width="21px" height="21px" viewBox="0 0 21 21">
    <circle fill="none" stroke="#979797" cx="10.5" cy="10.5" r="10" />
  </svg>
);
