/* @flow */

import { normalize } from "polished";
import { createGlobalStyle } from "styled-components";
import { colors } from "./theme";

import type { Route } from "./router";
import * as React from "react";
import patternUrl from "../assets/pattern.svg";

type Props = {
  route: Route | null
};

const GlobalStyles: React.AbstractComponent<Props> = createGlobalStyle`
  ${normalize()}

  @import url('https://fonts.googleapis.com/css?family=Roboto');
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600');

  html, input, select, textarea, button {
    font-family: 'Roboto', sans-serif;
  }

  body {
    ${props => backgroundForRoute(props.route)}
  }

  @font-face {
    font-family: Foco;
    src: url(${require("../assets/fonts/Foco-Bold.ttf")}) format("truetype"),
         url(${require("../assets/fonts/Foco-Bold.otf")}) format("opentype");
    font-weight: bold;
  }

  @font-face {
    font-family: Foco;
    src: url(${require("../assets/fonts/Foco-Regular.ttf")}) format("truetype"),
         url(${require("../assets/fonts/Foco-Regular.otf")}) format("opentype");
    font-weight: normal;
  }
`;

function backgroundForRoute(route: Route | null): string {
  if (route == null || route.name === "screenshots") {
    return "";
  } else {
    if (shouldRouteHaveBackground(route)) {
      return `
        background: url('${patternUrl}');
        background-size: 300px 150px;
      `;
    } else {
      return `background-color: ${colors.pageBackground};`;
    }
  }
}

export default GlobalStyles;

function shouldRouteHaveBackground(route: Route | null): boolean {
  if (route == null) return false;

  switch (route.name) {
    case "account":
    case "premium-subscription":
    case "auth-error":
    case "flash-cards-review":
    case "flash-cards-dashboard":
    case "flash-cards-splash":
    case "flash-cards-decks-new":
    case "flash-cards-decks-edit":
    case "search":
    case "root":
    case "change-password":
    case "about":
    case "premium-splash":
    case "password-reset":
    case "video-library":
    case "create-subtitle":
    case "recommendations":
      return true;
    default:
      return false;
  }
}
