/* @flow */

import * as React from "react";

import { fetchRouteResources } from "./fetchRouteResources";

import SearchPage from "./SearchPage";
import PlayVideoPage from "./PlayVideoPage";
import ShowFavoritesPage from "./ShowFavoritesPage";
import HomePage from "./HomePage";
import FlashCardsReviewPage from "./FlashCardsReviewPage";
import FlashCardsDashboardPage from "./FlashCardsDashboardPage";
import FlashCardsSplashPage from "./FlashCardsSplashPage";
import ChangePasswordPage from "./ChangePasswordPage";
import AboutPage from "./AboutPage";
import AdminPageLoader from "./AdminPageLoader";
import EditFavoritePage from "./EditFavoritePage";
import NewFlashCardsDeckPage from "./NewFlashCardsDeckPage";
import EditFlashCardsDeckPage from "./EditFlashCardsDeckPage";
import AccountPage from "./AccountPage";
import PremiumSubscriptionPage from "./PremiumSubscriptionPage";
import VideoLibraryPage from "./VideoLibraryPage";
import PremiumSplashPage from "./PremiumSplashPage";
import OAuth2RedirectPage from "./OAuth2RedirectPage";
import CreateSubtitlePage from "./CreateSubtitlePage";
import BlogPostPage from "./BlogPostPage";
import PasswordResetPage from "./PasswordResetPage";
import { TermsOfServicePage, PrivacyPolicyPage } from "./LegalesePage";
import { AuthCallbackPage, AuthErrorPage } from "./AuthFlowPages";
import CancelSubscriptionPage from "./CancelSubscriptionPage";

import type { Route } from "./router";
import type { ServerSideProps } from "./sendSPA";
import type { DocumentLocation } from "./useNavigation";
import type { ReferralSource } from "./useReferralSource";

type Props = {
  nativeLang: string,
  route: Route,
  pageProps: PageProps
};

export type PageProps = {
  location: DocumentLocation,
  onLogin: (flashMessage?: string) => void,
  onLogout: () => void,
  withIdToken: ?() => Promise<string>,
  idToken: string | null,
  onIdToken: string => void,
  onReplaceLocation: (
    href: string,
    state: ?Object,
    currentLocation: string
  ) => void,
  onNavigate: (href: string) => void,
  onAddSnackbarMessage: SnackbarMessage => void,
  targetLang: ?string,
  nativeLang: string,
  isLoggedIn: boolean,
  isInitialized: boolean,
  searchProps: SearchProps,
  onChangeNativeLang: (lang: string) => void,
  onChangeTargetLang: (lang: string) => void,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  userResources: ?UserResourceStore,
  quizAttemptsProps: QuizAttemptRenderProps,
  serverSideProps: ServerSideProps,
  referralSource: ReferralSource | null
};

import type { UserResourceStore } from "./WithUserResourceStore";
import type { SnackbarMessage } from "./useSnackbarQueue";
import type { SearchProps } from "./WithSearchResults";
import type { RenderProps as QuizAttemptRenderProps } from "./WithQuizAttempts";

import { useHeadTitle } from "./useHeadTitle";

export default function PageComponentForRoute(props: Props) {
  // TODO: Replace this with some variant of useEffect
  const routeWithResources = React.useMemo(
    () => fetchRouteResources(props.route, props.nativeLang),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.route.name, props.nativeLang]
  );

  // TODO: This is a little awkward.. but it works. We are taking somethign that was fetched
  // asynchronously and then placing it into a resolved promise.
  const languagePromise = React.useMemo(
    () => Promise.resolve(props.pageProps.youtubeLanguages),
    [props.pageProps.youtubeLanguages]
  );

  useHeadTitle(
    routeWithResources,
    props.pageProps.location,
    languagePromise,
    props.nativeLang
  );

  if (routeWithResources.type === "youtube-video") {
    return (
      <PlayVideoPage
        {...props.pageProps}
        {...routeWithResources.route.params}
        pageResources={routeWithResources.promise}
      />
    );
  } else {
    switch (routeWithResources.route.name) {
      case "root":
        return <HomePage {...props.pageProps} />;
      case "auth-callback":
        return <AuthCallbackPage {...props.pageProps} />;
      case "auth-error":
        return <AuthErrorPage {...props.pageProps} />;
      case "show-favorites":
        return (
          <ShowFavoritesPage
            {...props.pageProps}
            {...routeWithResources.route.params}
          />
        );
      case "edit-favorite":
        return (
          <EditFavoritePage
            {...props.pageProps}
            {...routeWithResources.route.params}
          />
        );
      case "flash-cards-decks-new":
        return <NewFlashCardsDeckPage {...props.pageProps} />;
      case "flash-cards-decks-edit":
        return (
          <EditFlashCardsDeckPage
            {...props.pageProps}
            {...routeWithResources.route.params}
          />
        );
      case "flash-cards-dashboard":
        return <FlashCardsDashboardPage {...props.pageProps} />;
      case "flash-cards-splash":
        return <FlashCardsSplashPage {...props.pageProps} />;
      case "flash-cards-review":
        return <FlashCardsReviewPage {...props.pageProps} />;
      case "blog-post":
        return (
          <BlogPostPage
            {...props.pageProps}
            postId={routeWithResources.route.params.id}
          />
        );
      case "search":
        return <SearchPage {...props.pageProps} />;
      case "about":
        return <AboutPage {...props.pageProps} />;
      case "terms-of-service":
        return <TermsOfServicePage {...props.pageProps} />;
      case "privacy-policy":
        return <PrivacyPolicyPage {...props.pageProps} />;
      case "video-library":
        return <VideoLibraryPage {...props.pageProps} />;
      case "premium-subscription":
        return <PremiumSubscriptionPage {...props.pageProps} />;
      case "account":
        return <AccountPage {...props.pageProps} />;
      case "change-password":
        return <ChangePasswordPage {...props.pageProps} />;
      case "premium-splash":
        return <PremiumSplashPage {...props.pageProps} />;
      case "password-reset":
        return <PasswordResetPage {...props.pageProps} />;
      case "oauth2-redirect":
        return <OAuth2RedirectPage {...props.pageProps} />;
      case "create-subtitle":
        return <CreateSubtitlePage {...props.pageProps} />;
      case "cancel-subscription":
        return <CancelSubscriptionPage {...props.pageProps} />;

      default:
        if (
          routeWithResources.route.name === "admin" ||
          routeWithResources.route.name.startsWith("admin-")
        ) {
          return <AdminPageLoader {...props.pageProps} />;
        } else {
          return null;
        }
    }
  }
}
