/* @flow */

const acceptLanguageParser = require("accept-language-parser");

function chooseLocale(
  req /*: express$Request */,
  availableLocales /*: Array<string>*/
) /*:string */ {
  const countryCode = req.get("CF-IPCountry") || null;

  const nl = req.query["nl"];
  if (typeof nl === "string" && availableLocales.includes(nl)) {
    return nl;
  }

  return (
    acceptLanguageParser.pick(
      availableLocales,
      req.headers["accept-language"]
    ) || primaryLanguageForCountry(countryCode)
  );
}

const table = {
  FR: "fr", // France
  LU: "fr", // Luxembourg

  US: "en", // United States
  GB: "en", // United Kingdom
  CA: "en", // Canada

  CN: "zh", // China
  TW: "zh", // Taiwan
  HK: "zh", // Hong Kong
  MO: "zh", // Macao

  JP: "ja",
  KR: "ko",

  ES: "es", // Span
  MX: "es", // Mexico
  CO: "es", // Colombia
  AR: "es", // Argentina
  PE: "es", // Peru
  VE: "es", // Venezuela
  CL: "es", // Chile
  EC: "es", // Ecuador
  GT: "es", // Guatemala
  CU: "es", // Cuba
  BO: "es", // Bolivia
  DO: "es", // Dominican Republic
  HN: "es", // Honduras
  PY: "es", // Paraguay
  SV: "es", // El Salvador
  NI: "es", // Nicaragua
  CR: "es", // Costa Rica
  PA: "es", // Panama
  UY: "es", // Uruguay
  GQ: "es" // Equatorial Guinea
};

function primaryLanguageForCountry(
  countryCode /*:string | null*/
) /*:string */ {
  if (typeof countryCode == "string") {
    return table[countryCode.toUpperCase()] || "en";
  } else {
    return "en";
  }
}

module.exports = { chooseLocale, primaryLanguageForCountry };
