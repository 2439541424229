/* @flow */
export default function focusHack() {
  // If the IFRAME has focus, it won't bubble up click events. This is
  // somewhat of a hack to prevent that. I would like to find a better way,
  // because in a proper UI, the IFRAME elements should be able to keep focus.
  if (document.activeElement && document.activeElement.nodeName === "IFRAME") {
    document.activeElement.blur();

    // If the IFRAME still has focus (Sarari 9), try moving the focus to a
    // button on the page. This has an unforunate visual side effect, but
    // it's better than having the user get stuck.
    if (
      document.activeElement &&
      document.activeElement.nodeName === "IFRAME" &&
      document.body
    ) {
      const el = document.body.querySelector("a");
      if (el) el.focus();
    }
  }
}
