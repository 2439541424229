/* @flow */

import { useEffect } from "react";
import { configureScope } from "@sentry/browser";
import type { UserIdentity } from "./api";

function indentify(userIdentity: UserIdentity) {
  mixpanel.identify(userIdentity.sub);
  mixpanel.people.set({
    $email: userIdentity.email
  });

  configureScope(scope => {
    scope.setUser({
      email: userIdentity.email,
      id: userIdentity.sub
    });
  });
}

export default function useMixpanelIdentity(
  userIdentity: Promise<UserIdentity> | null
) {
  useEffect(() => {
    let subscribed = true;

    if (userIdentity != null) {
      userIdentity.then(i => {
        if (subscribed) {
          indentify(i);
        }
      });
    }

    return () => {
      subscribed = false;
    };
  }, [userIdentity]);
}
