/* @flow */

import * as React from "react";

import WithPromise from "./WithPromise";
import WithDerivedState from "./WithDerivedState";

type ExtractType = <T>(Promise<T> | null) => T;

type Props<T> = {
  promises: T,
  renderPending: (lastValue: ?$TupleMap<T, ExtractType>) => React.Node,
  renderResolved: (value: $TupleMap<T, ExtractType>) => React.Node,
  renderRejected: any => React.Node
};

function promiseAll(list) {
  return Promise.all(list);
}

export default function WithPromises<T: Iterable<Promise<mixed> | null>>(
  props: Props<T>
) {
  if (Array.from(props.promises).findIndex(p => p == null) === -1) {
    return (
      <WithDerivedState
        controller={promiseAll}
        controllerProps={props.promises}
        render={promise => (
          <WithPromise
            promise={promise}
            renderResolved={props.renderResolved}
            renderRejected={props.renderRejected}
            renderPending={props.renderPending}
          />
        )}
      />
    );
  } else {
    return props.renderPending();
  }
}

type WithMostRecentPromisesProps<T> = {
  promises: T,
  renderPending: () => React.Node,
  renderResolved: (value: $TupleMap<T, ExtractType>) => React.Node,
  renderRejected: any => React.Node
};

export function WithMostRecentPromises<T: Iterable<Promise<mixed> | null>>(
  props: WithMostRecentPromisesProps<T>
) {
  return (
    <WithPromises
      promises={props.promises}
      renderRejected={props.renderRejected}
      renderResolved={value => props.renderResolved(value)}
      renderPending={lastValue =>
        lastValue ? props.renderResolved(lastValue) : props.renderPending()
      }
    />
  );
}
