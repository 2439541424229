/* @flow */

import { stringify as qsStringify } from "querystring";
import * as React from "react";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import styled from "styled-components";
import { GreyRectangle } from "./LoadingTextPlaceholder";
import { PlayerContainer } from "./PlayerContainer";
import { useBoundedPlayback2 } from "./useBoundedPlayback";
import { ActionButton, ButtonLink } from "./Buttons";

import { PlayTriangle } from "./SvgAssets";
import { includes } from "lodash";
import { Box, PageContent } from "./FormStyles";
import PositioningWrapper from "./PositioningWrapper";

import useDynamicScrollPosition from "./useDynamicScrollPosition";

import type { TimeBlock } from "./mergeSubtitles";
import type { Favorite } from "./api";
import type { UserResourceStore } from "./WithUserResourceStore";
import type { DocumentLocation } from "./useNavigation";
import NotFoundPage from "./NotFoundPage";

type Props = {
  location: DocumentLocation,
  onLogin: Function,
  onLogout: Function,
  isLoggedIn: boolean,
  nativeLang: string,
  targetLang: ?string,
  userResources: ?UserResourceStore,
  postId: string
};

/*
Consider:
    Consider having one YouTube player that is fixed up top, like the favorites page.
    Link to full video with lyrics
    maybe add a "bookmark" or "favorite" link next to the lyrics
    Link to demo flash card deck
    Include romanization
*/

export default function BlogPostPage(props: Props) {
  if (props.postId == "learn-korean-with-kpop-from-4minute") {
    return <KPopPost {...props} />;
  } else {
    return <NotFoundPage {...props} />;
  }
}

function KPopPost(props: Props) {
  const list = JSON.parse(json);
  const videoId = list[0].videoId;
  const videoId2 = list[3].videoId;

  const playerProps = useBoundedPlayback2({
    videoId: videoId
  });

  const playerProps2 = useBoundedPlayback2({
    videoId: videoId2
  });

  const slowPlaybackSupported = includes(
    playerProps.availablePlaybackRates,
    0.5
  );

  const scrollProps = useDynamicScrollPosition();

  return (
    <React.Fragment>
      <PositioningWrapper headerRetracted={scrollProps.headerRetracted}>
        <PageHeader
          onLogin={props.onLogin}
          onLogout={props.onLogout}
          fixed={false}
          isLoggedIn={props.isLoggedIn}
          nativeLang={props.nativeLang}
          targetLang={props.targetLang}
          userResources={props.userResources}
        />
      </PositioningWrapper>

      <PageContent>
        <MyBox>
          <BlogContent>
            <h1>Learn Korean with K-Pop from 4Minute</h1>

            <p>
              Listening to music is a catchy and fun way to learn a language.
              The challenge with studying music is that it is often too poetic,
              or the grammar isn't realistic. Fortunately, the K-Pop group
              4Minute has us covered with their hit songs 오늘 뭐해 (Whatcha
              doing?) and 이름이 뭐예요 (What's your name?). These songs read
              like a conversation in a beginners Korean textbook.
            </p>
          </BlogContent>

          <PlayerSizer aspectRatio={1920 / 1080}>
            <PlayerContainer
              {...playerProps.initParams}
              videoId={videoId}
              playerVars={{}}
            />
          </PlayerSizer>

          <BlogContent>
            <Snippet
              snippet={list[0]}
              onPlay={playerProps.onPlay}
              slowPlaybackSupported={slowPlaybackSupported}
            />

            <p>
              This is very simple question in Korean. And it's something you
              could use in a real life conversation with a friend.
            </p>
            <p>
              오늘 = today
              <br />
              뭐 = what
              <br />
              해 = present-tense conjugation of the verb 하다, which means "to
              do"
              <br />
            </p>

            <p>
              Notice that this sentance is missing the 요 at the end. This means
              the sentance is in 반말 (informal speach). You should only use
              this form of speach with someone you are familiar with, like your
              close friends.
            </p>

            <Snippet
              snippet={list[1]}
              onPlay={playerProps.onPlay}
              slowPlaybackSupported={slowPlaybackSupported}
            />

            <p>
              After asking you what you are doing today, 4Minute follows up with
              another simple question.
            </p>

            <p>이따 - later</p>

            <p>
              Listen to the way they pronounce 이따. Do you hear the emphasis on
              the second syllable, 따? This is not just musical phrasing. This
              is a double consonant and is very common in the Korean language. A
              double consonant is pronounced with slightly more tightness than a
              regular consonant. One way to way to practice this is to insert a
              small pause before you speak the double consonant. 이 [pause] 따.
            </p>

            <p>
              If you watch a lot of K-Dramas, you're probably familiar with the
              word 오빠 (brother or older male friend). This word also has a
              double consonant in the second syllable.
            </p>

            <Snippet
              snippet={list[2]}
              onPlay={playerProps.onPlay}
              slowPlaybackSupported={slowPlaybackSupported}
            />

            <p>
              Now that 4Minute knows what you are doing today, and later, they
              are asking a final question about your weekend plans.
            </p>

            <p>
              주말 = the weekend
              <br />에 = location or time marking particle
            </p>

            <p>
              "particles" in Korean are markers that come after words to
              indicate the parts of speach. 에 is used to indicate that the
              question is about a certain time. In this case, they are asking
              about the weekend. 에 can also be used to indicate locations.
            </p>

            <p>
              Some other common particles are 은/는 (topic marker), 가/이
              (subject markers) and 을/를 (object) markers. Particles are a core
              building block of Korean grammar.
            </p>
          </BlogContent>

          <PlayerSizer aspectRatio={1920 / 1080}>
            <PlayerContainer
              {...playerProps2.initParams}
              videoId={videoId2}
              playerVars={{}}
            />
          </PlayerSizer>

          <BlogContent>
            <Snippet
              snippet={list[3]}
              onPlay={playerProps2.onPlay}
              slowPlaybackSupported={slowPlaybackSupported}
            />

            <p>
              이름 = name
              <br />
              이 = subject marking particle
              <br />
              요 = politice sentence ending
              <br />
            </p>

            <p>
              In this song, 4Minute is asking your name. Since they don't know
              the listener's name, we can assume they don't yet have a familiar
              relationship with the listener and they must end their question
              with the polite "요". This polite form of speach is called 존댓말,
              and it should be used with strangers or people older than you.
            </p>

            <p>
              The subject of this sentance is "이름" (name). We know this,
              because it is followed by the subject marking particle 이. 이 is
              only used for words that end in consonants. For words that end
              with vowals, use 가.
            </p>

            <Snippet
              snippet={list[4]}
              onPlay={playerProps2.onPlay}
              slowPlaybackSupported={slowPlaybackSupported}
            />

            <p>
              전화 = phone
              <br />
              번호 = number
            </p>

            <p>
              This one is a tounge twister, if you're not used to speaking
              Korean syllables. It might help to listen to this playback at a
              slower speed.
            </p>

            <h2>Want to study more Korean videos on YouTube?</h2>

            <p>
              YouTube is a great resource as a Korean language learner. You can
              find videos about everything from{" "}
              <a href="/search?channelId=UC3lz95UPhKJW1aS3_za6SuQ&nl=en&tl=ko">
                K-Pop
              </a>
              ,{" "}
              <a href="/search?playlistId=PLMf7VY8La5RHAudFunjeV_6tP8YjNFBw4&nl=en&tl=ko">
                K-Drama
              </a>
              , <a href="/search?q=먹방&nl=en&tl=ko">먹방</a>, and anything else
              you might find interesting.
            </p>

            <p>
              CaptionPop provides tools that help you best use YouTube as a
              language learner. It allows you to{" "}
              <a href="/search?tl=ko">search all of YouTube</a> and filter for
              videos that have subtitles in both Korean and English. (or any
              other language!)
            </p>

            <p>
              CaptionPop also allows you to watch videos with multiple subtitles
              at once, bookmark subtitles, and even build{" "}
              <a href="/flash-cards/splash/">interactive flash cards</a> from
              video snippets!
            </p>

            <p style={{ textAlign: "center" }}>
              <ButtonLink href="/search?tl=ko" primary>
                Search for Videos
              </ButtonLink>
            </p>

            <ShareLinks location={props.location} />

            <p style={{ textAlign: "center" }}>
              <i>
                Published <time dateTime="2020-02-12 12:00">Feb 12, 2020</time>.
              </i>
            </p>
          </BlogContent>
        </MyBox>
      </PageContent>

      <PageFooter nativeLang={props.nativeLang} />
    </React.Fragment>
  );
}

function ShareLinks(props: { location: DocumentLocation }) {
  const link = "https://www.captionpop.com" + props.location.pathname;

  // https://developers.facebook.com/docs/sharing/reference/share-dialog
  const facebookLink =
    "https://www.facebook.com/dialog/share?" +
    qsStringify({
      app_id: process.env["FACEBOOK_OAUTH2_CLIENT_ID"],
      display: "popup",
      href: link,
      redirect_uri: link
    });

  const twitterLink =
    "https://twitter.com/intent/tweet?" + qsStringify({ text: link });

  return (
    <ShareLinksStyle>
      Share this article
      <p>
        <a href={twitterLink} target="_blank">
          {twitterShareIcon}
        </a>
        <a href={facebookLink} target="_blank">
          {facebookShareIcon}
        </a>
      </p>
    </ShareLinksStyle>
  );
}

const ShareLinksStyle = styled.div`
  margin-top: 3em;
  text-align: center;
  a:hover svg {
    fill: #666;
    transition: fill 0.3s ease-in-out;
  }
`;

function Snippet(props: {
  snippet: Favorite,
  onPlay: (number, TimeBlock) => void,
  slowPlaybackSupported: boolean
}) {
  return (
    <SnippetStyle>
      <h2>{props.snippet.subtitle.transcription}</h2>
      <p>{props.snippet.subtitle.translations.join(" ")}</p>
      <PreviewButton1
        slowPlaybackSupported={props.slowPlaybackSupported}
        onActivated={() => {
          props.onPlay(1, props.snippet.subtitle);
        }}
        disabled={false}
      >
        <PlayTriangle width={10} color="#333" />
        Play snippet
      </PreviewButton1>

      <PreviewButton2
        slowPlaybackSupported={props.slowPlaybackSupported}
        onActivated={() => {
          props.onPlay(0.5, props.snippet.subtitle);
        }}
        disabled={false}
      >
        Slowly
      </PreviewButton2>
    </SnippetStyle>
  );
}

const SnippetStyle = styled.div`
  h2 {
    margin-top: 0;
  }

  margin-top: 2em;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 1em;
`;

const json =
  '[{"id":10206,"createdAt":"2020-02-11T03:04:04.354Z","videoId":"74Pru-JQzRk","translationLang":"en","transcriptionLang":"ko","original":{"start":150.7,"duration":1.3000000000000114},"subtitle":{"start":150.7,"duration":1.3000000000000114,"translations":["Whatcha doin today?"],"transcription":"오늘 뭐해"}},{"id":10207,"createdAt":"2020-02-11T03:05:47.666Z","videoId":"74Pru-JQzRk","translationLang":"en","transcriptionLang":"ko","original":{"start":152.4,"duration":1.1999999999999886},"subtitle":{"start":152.5,"duration":1.0999999999999943,"translations":["Whatcha doin later"],"transcription":"이따 뭐해"}},{"id":10208,"createdAt":"2020-02-11T03:07:28.223Z","videoId":"74Pru-JQzRk","translationLang":"ko","transcriptionLang":"ko","original":{"start":154,"duration":1.3000000000000114},"subtitle":{"start":154,"duration":1.3000000000000114,"translations":["Whatcha doin on the weekend?"],"transcription":"주말에 뭐해"}},{"id":10209,"createdAt":"2020-02-11T03:14:21.966Z","videoId":"H-IJWqIHioA","translationLang":"en","transcriptionLang":"ko","original":{"start":84,"duration":1.4000000000000057},"subtitle":{"start":84,"duration":1.4000000000000057,"translations":["What’s your name?"],"transcription":"이름이 뭐예요? "}},{"id":10210,"createdAt":"2020-02-11T03:16:52.249Z","videoId":"H-IJWqIHioA","translationLang":"en","transcriptionLang":"ko","original":{"start":85.8,"duration":1.7999999999999972},"subtitle":{"start":85.8,"duration":1.7999999999999972,"translations":["What’s your number?"],"transcription":"전화번호 뭐예요?"}}]';

const BlogContent = styled.div`
  padding: 1em;
`;

const MyBox = styled(Box)`
  background-color: white;
  padding: 0;

  h2 {
    color: #3a3a3a;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    text-transform: none;
  }
`;

const PlayerSizer = styled(GreyRectangle)`
  //box-shadow: 2px 2px 5px #888;
  position: sticky;
  top: -150px;
  transition: 300ms width;

  iframe {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
`;

const PreviewButton1 = styled(ActionButton)`
  svg {
    margin-right: 0.25em;
  }
  ${props =>
    props.slowPlaybackSupported
      ? `
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    `
      : null}
`;

const PreviewButton2 = styled(ActionButton)`
  ${props =>
    props.slowPlaybackSupported
      ? `
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;`
      : `display: none;`}
`;

// Taken from https://help.twitter.com/en/using-twitter/add-twitter-share-button
const twitterShareIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <desc>Twitter</desc>
    <path opacity="0" d="M0 0h24v24H0z" />
    <path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z" />
  </svg>
);

const facebookShareIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" alt="Facebook">
    <desc>Facebook</desc>
    <path d="M16.75,9H13.5V7a1,1,0,0,1,1-1h2V3H14a4,4,0,0,0-4,4V9H8v3h2v9h3.5V12H16Z" />
  </svg>
);
