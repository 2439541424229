/* @flow */

import * as React from "react";

import styled from "styled-components";
import type { TimedTextTrack } from "./timedtext2";
import { stringsForLocale } from "../lang/web";
import { widthCss } from "./playerGeometry";

import BaseSelect from "react-select";

import { SwapArrows } from "./SvgAssets";
import { ActionButton } from "./Buttons";

type Props = {
  transcriptionEntry: ?TimedTextTrack,
  translationEntry: ?TimedTextTrack,
  timedTextList: Array<TimedTextTrack>,
  onChangeTranslation: (entry: TimedTextTrack) => void,
  onChangeTranscription: (entry: TimedTextTrack) => void,
  onSwapLanguages: () => void,
  blurTranslation: boolean,
  blurTranscription: boolean,
  onChangeBlurTranslation: boolean => void,
  onChangeBlurTranscription: boolean => void,
  pauseAfterSubtitles: boolean,
  onChangePauseAfterSubtitles: boolean => void,
  nativeLang: string
};

export default function SubtitleConfigForm(props: Props) {
  const strings = stringsForLocale(props.nativeLang);

  if (props.timedTextList.length == 0) {
    return (
      <Form>
        <WarningMessage>
          {strings.play_videos_page.no_available_subtitles()}
        </WarningMessage>
      </Form>
    );
  }

  const pauseAfterCheckboxEl = (
    <label>
      <input
        type="checkbox"
        checked={props.pauseAfterSubtitles}
        onChange={event =>
          props.onChangePauseAfterSubtitles(event.target.checked)
        }
      />
      {strings.play_videos_page.pause_after_caption_label()}
    </label>
  );

  return (
    <Form>
      {props.timedTextList.length == 1 ? (
        <React.Fragment>
          <WarningMessage>
            {strings.play_videos_page.only_one_subtitle_language({
              LANGUAGE: props.timedTextList[0].name.simpleText
            })}
          </WarningMessage>

          {pauseAfterCheckboxEl}

          <BlurLabel>
            <input
              type="checkbox"
              checked={props.blurTranscription}
              onChange={event =>
                props.onChangeBlurTranscription(event.target.checked)
              }
            />
            {strings.play_videos_page.blur_captions_label()}
          </BlurLabel>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {pauseAfterCheckboxEl}
          <LanguageSelectors>
            {props.transcriptionEntry != null ? (
              <Half
                instanceId="transcription-select"
                options={props.timedTextList}
                value={props.transcriptionEntry}
                onChange={props.onChangeTranscription}
                onChangeBlur={props.onChangeBlurTranscription}
                blur={props.blurTranscription}
                nativeLang={props.nativeLang}
              />
            ) : null}

            <ActionBarButton onActivated={props.onSwapLanguages}>
              <SwapArrows size={15} />
            </ActionBarButton>

            {props.translationEntry != null ? (
              <Half
                instanceId="translation-select"
                options={props.timedTextList}
                value={props.translationEntry}
                onChange={props.onChangeTranslation}
                onChangeBlur={props.onChangeBlurTranslation}
                blur={props.blurTranslation}
                nativeLang={props.nativeLang}
              />
            ) : null}
          </LanguageSelectors>
        </React.Fragment>
      )}
    </Form>
  );
}

function Half(
  props: SelectProps & {
    instanceId: string,
    blur: boolean,
    onChangeBlur: boolean => void,
    nativeLang: string
  }
) {
  function onChange(event: SyntheticEvent<HTMLInputElement>) {
    props.onChangeBlur(event.currentTarget.checked);
  }

  const strings = stringsForLocale(props.nativeLang);

  return (
    <HalfStyles>
      <TimedTextSelect
        instanceId={props.instanceId}
        options={props.options}
        value={props.value}
        onChange={props.onChange}
      />
      <BlurLabel>
        <input type="checkbox" checked={props.blur} onChange={onChange} />
        {strings.play_videos_page.blur_captions_label()}
      </BlurLabel>
    </HalfStyles>
  );
}

const WarningMessage = styled.div`
  margin-bottom: 1em;
`;

const BlurLabel = styled.label`
  display: block;
  margin: 0.5em 0;
  input {
    margin-right: 0.5em;
  }
`;

const HalfStyles = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 150px;
  margin: 0;

  &:first-child {
    margin-right: 0.5em;
  }
  &:last-child {
    margin-left: 0.5em;
  }
`;

type SelectProps = {
  value: TimedTextTrack,
  instanceId: string,
  onChange: TimedTextTrack => void,
  options: Array<TimedTextTrack>
};

const customStyles = {
  control: (base, _state) => ({
    ...base,
    backgroundColor: "white",
    borderColor: "#ddd"
  }),
  container: base => ({
    ...base
  }),
  indicatorSeparator: (base, _) => ({
    ...base
    //display: "none"
  })
};

class TimedTextSelect extends React.Component<SelectProps> {
  render() {
    // TODO: For some reason, if I convert this to a functional component, there is a bug
    // where multiple items get selected at once.
    return (
      <BaseSelect
        value={this.props.value}
        instanceId={this.props.instanceId}
        onChange={this.props.onChange}
        options={this.props.options}
        isClearable={false}
        backspaceRemovesValue={false}
        styles={customStyles}
        getOptionLabel={(option: TimedTextTrack) => option.name.simpleText}
        isOptionSelected={(option: TimedTextTrack) =>
          option.vssId === this.props.value.vssId
        }
      />
    );
  }
}

const Form = styled.form`
  margin: 0 auto;
  ${widthCss};

  input[type="checkbox"] {
    margin-right: 0.5em;
  }
  label {
    display: block;
    margin-bottom: 1em;
  }
`;

const LanguageSelectors = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 300px;
  // justify-content: center;
  margin: 5px 0;

  svg.icon {
    flex-shrink: 0;
    margin-top: 11px;
  }
`;

const ActionBarButton: typeof ActionButton = styled(ActionButton)`
  padding: 0 10px;
  height: 38px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  line-height: 38px;
`;
