/* @flow */

import styled from "styled-components";
import * as React from "react";

import { ActionButton, buttonBackgroundColorWithHover } from "./Buttons";

type Props = {
  label: string,
  onChange: (value: boolean) => void,
  on: boolean,
  className?: string
};

export default function ToggleButton(props: Props) {
  return (
    <ToggleButtonStyles
      onActivated={props.onChange.bind(null, !props.on)}
      on={props.on}
      className={props.className}
    >
      <ToggleSquare on={props.on} />
      {props.label}
    </ToggleButtonStyles>
  );
}

const padding = 10;

const ToggleSquare = styled.div`
  position: absolute;
  left: ${padding}px;
  top: 12px;
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  display: block;
  ${props =>
    props.on
      ? `
  background-color: #626262;
  border: 1px solid #626262;
  `
      : `
  background-color: white;
  border: 1px solid #D7D7D7;
  `};
`;

const ToggleButtonStyles: typeof ActionButton = styled(ActionButton)`
  padding: ${padding}px ${padding}px ${padding}px ${padding * 3}px;
  line-height: 1;
  position: relative;
  border-color: #d7d7d7;
  ${props =>
    buttonBackgroundColorWithHover(
      props.on ? "#f8f8f8" : "white",
      props.disabled
    )};
`;
