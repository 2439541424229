/* @flow */

import * as React from "react";
import styled from "styled-components";
import { captureException } from "@sentry/browser";

import { useFormState, useFormUtils } from "../WithFormState";
import {
  useFormValidations,
  required,
  confirmationOf
} from "../useFormValidations";
import { spacers } from "../theme";
import { RoundedFormField } from "../Forms";
import { TransparentRoundedButton } from "../Buttons";

import { APIError } from "../APIError";
import { putPasswordUpdate } from "../apiClient";

import { stringsForLocale } from "../../lang/web";
import type { SnackbarMessage } from "../useSnackbarQueue";
import type { UserResourceStore } from "../WithUserResourceStore";

function usePutPassword(props: {
  nativeLang: string,
  withIdToken: () => Promise<string>,
  onAddSnackbarMessage: SnackbarMessage => void,
  onReset: () => void,
  setVisitedKey: (key: any, value: boolean) => void,
  setSubmitAttempted: boolean => void
}) {
  const [inProgress, setInProgress] = React.useState(false);

  function onChangePassword(values: {
    currentPassword: string,
    password: string,
    passwordConfirmation: string
  }) {
    setInProgress(true);

    const promise = putPasswordUpdate(props.withIdToken, values);
    const strings = stringsForLocale(props.nativeLang).change_password_page;

    promise.then(
      () => {
        setInProgress(false);
        props.onReset();
        props.setSubmitAttempted(false);
        props.setVisitedKey("currentPassword", false);
        props.setVisitedKey("password", false);
        props.setVisitedKey("passwordConfirmation", false);

        props.onAddSnackbarMessage({
          level: "message",
          body: strings.password_updated_confirmation()
        });
      },
      error => {
        setInProgress(false);

        if (error instanceof APIError) {
          // Only send exceptions to Sentry if not user-related
          // (like if their current password doesn't match)
          if (error.code !== 422) {
            captureException(error);
          }

          props.onAddSnackbarMessage({
            level: "error",
            body: error.message
          });
        } else {
          props.onAddSnackbarMessage({
            level: "error",
            body: strings.network_error()
          });
        }
      }
    );
  }

  return { inProgress, onChangePassword };
}

type Props = {
  nativeLang: string,
  userResources: UserResourceStore,
  onAddSnackbarMessage: SnackbarMessage => void
};

export default function ChangePasswordForm(props: Props) {
  const strings = stringsForLocale(props.nativeLang).change_password_page;
  const placeholder_strings = stringsForLocale(props.nativeLang).login_form
    .placeholders;

  const initialValues = {
    currentPassword: "",
    password: "",
    passwordConfirmation: ""
  };

  const validations = {
    currentPassword: [required],
    password: [required],
    passwordConfirmation: [required, confirmationOf("password")]
  };

  const formProps = useFormState({ values: initialValues });
  const formUtils = useFormUtils(formProps);

  const [
    validationErrors,
    formEventHandlers,
    setVisitedKey,
    setSubmitAttempted
  ] = useFormValidations({
    values: formProps.values,
    locale: props.nativeLang,
    validations,
    onSubmit
  });

  function onSubmit(event) {
    api.onChangePassword(formProps.values);
  }

  const api = usePutPassword({
    nativeLang: props.nativeLang,
    withIdToken: props.userResources.withIdToken,
    onAddSnackbarMessage: props.onAddSnackbarMessage,
    onReset: formProps.onReset,
    setVisitedKey,
    setSubmitAttempted
  });

  return (
    <React.Fragment>
      <h2>{strings.change_password_header()}</h2>

      <form {...formEventHandlers}>
        <MyFormField
          {...formUtils.propsForInput("currentPassword")}
          errorMessages={validationErrors.currentPassword}
          label={placeholder_strings.current_password()}
          renderInput={props => (
            <input
              {...props}
              type="password"
              placeholder={placeholder_strings.current_password()}
            />
          )}
        />

        <MyFormField
          {...formUtils.propsForInput("password")}
          errorMessages={validationErrors.password}
          label={placeholder_strings.password()}
          renderInput={props => (
            <input
              {...props}
              type="password"
              placeholder={placeholder_strings.password()}
            />
          )}
        />

        <MyFormField
          {...formUtils.propsForInput("passwordConfirmation")}
          errorMessages={validationErrors.passwordConfirmation}
          label={placeholder_strings.password_confirmation()}
          renderInput={props => (
            <input
              {...props}
              type="password"
              placeholder={placeholder_strings.password_confirmation()}
            />
          )}
        />

        <FormActions>
          <TransparentRoundedButton disabled={api.inProgress}>
            {strings.change_password_action()}
          </TransparentRoundedButton>
        </FormActions>
      </form>
    </React.Fragment>
  );
}

const MyFormField = styled(RoundedFormField)`
  margin-bottom: ${spacers[3]};
`;

const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
