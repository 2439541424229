/* @flow */

import * as React from "react";
import styled from "styled-components";
import { colors } from "./theme";

import type { UserResourceStore } from "./WithUserResourceStore";
import PageHeader from "./PageHeader";
import { Box, PageContent } from "./FormStyles";
import { getDemoFlashCards, getFlashCardDecks } from "./apiClient";

import { stringsForLocale } from "../lang/web";

import WithInterval from "./WithInterval";
import {
  ButtonLink,
  ActionButton,
  horPadding as buttonPadding
} from "./Buttons";
import usePromise from "./usePromise";

import { normalizeLanguage, matchLanguage } from "./matchLanguage";

import { languageNameFromResponse } from "./youtubeUtils";

import TextWithBoldNumbers from "./TextWithBoldNumbers";
import { WithMostRecentPromises } from "./WithPromises";
import { findDueCards, nextCardDueAt } from "./quizAttempts";
import { secondsToString } from "./mediaTimestampFormat";

import { uniq, sum, groupBy, map } from "lodash";
import { stringify as qsStringify } from "querystring";

import type { FlashCardDeck, Favorite, QuizAttempt } from "./api";
import type { RenderProps as QuizAttemptRenderProps } from "./WithQuizAttempts";

type Props = {
  userResources: ?UserResourceStore,
  isInitialized: boolean,
  nativeLang: string,
  targetLang: ?string,
  onLogin: () => void,
  onLogout: () => void,
  isLoggedIn: boolean,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  quizAttemptsProps: QuizAttemptRenderProps
};

export default function FlashCardsDashboardPage(props: Props) {
  const demoFlashCardsPromise = usePromise(() => getDemoFlashCards(), []);

  let withIdToken = props.userResources && props.userResources.withIdToken;
  const flashCardDecksPromise: Promise<
    Array<FlashCardDeck>
  > | null = usePromise(() => {
    if (withIdToken) {
      return getFlashCardDecks(withIdToken);
    } else {
      return Promise.resolve([]);
    }
  }, [withIdToken]);

  const favoritesPromise = props.userResources
    ? props.userResources.favorites.favorites
    : emtpyList;

  return (
    <WithMostRecentPromises
      promises={[
        favoritesPromise,
        props.quizAttemptsProps.quizAttempts,
        flashCardDecksPromise,
        demoFlashCardsPromise
      ]}
      renderRejected={() => null}
      renderPending={() => null}
      renderResolved={([
        favorites,
        quizAttempts,
        flashCardDecks,
        demoFlashCards
      ]) => (
        <WithInterval
          interval={1000}
          sample={() => Date.now()}
          render={now => (
            <FlashCardsDashboardView
              now={now}
              nativeLang={props.nativeLang}
              targetLang={props.targetLang}
              onLogin={props.onLogin}
              onLogout={props.onLogout}
              isLoggedIn={props.isLoggedIn}
              favoriteCount={favorites.length}
              userResources={props.userResources}
              decks={makeAutoDecks(
                now,
                favorites,
                quizAttempts,
                props.youtubeLanguages
              )
                .concat(
                  makeFlashCardDecks(
                    now,
                    quizAttempts,
                    flashCardDecks,
                    favorites
                  )
                )
                .concat(
                  makeDemoDecks(
                    now,
                    quizAttempts,
                    demoFlashCards.filter(caption =>
                      filterDemoFlashCard(
                        caption,
                        props.nativeLang,
                        props.targetLang
                      )
                    ),
                    props.youtubeLanguages
                  )
                )}
            />
          )}
        />
      )}
    />
  );
}

const emtpyList = Promise.resolve([]);

function filterDemoFlashCard(
  caption: Favorite,
  nativeLang: string,
  targetLang: ?string
): boolean {
  if (targetLang == null) return true;

  if (caption.transcriptionLang != null) {
    return (
      matchLanguage(caption.transcriptionLang, targetLang) &&
      (caption.translationLang == null ||
        matchLanguage(caption.translationLang, nativeLang))
    );
  } else {
    return false;
  }
}

function filterNull<T>(list: Array<T>): Array<$NonMaybeType<T>> {
  return list.filter(i => i != null);
}

function makeFlashCardDecks(
  now: number,
  quizAttempts: Array<QuizAttempt>,
  decks: Array<FlashCardDeck>,
  favorites: Array<Favorite>
) {
  return decks.map(deck =>
    makeComputedDeck(
      now,
      deck.name,
      { deck: deck.id },
      favorites.filter(favorite => deck.favoriteIds.includes(favorite.id)),
      quizAttempts
    )
  );
}

function makeDemoDecks(
  now: number,
  quizAttempts,
  demoFlashCards: Array<Favorite>,
  youtubeLanguages: YouTube$i18nLanguageListResponse
): Array<ComputedFlashCardDeck> {
  const groups = groupBy(demoFlashCards, caption => caption.transcriptionLang);

  return map(groups, (captions, lang) =>
    makeComputedDeck(
      now,
      languageNameFromResponse(lang, youtubeLanguages),
      { demo: lang },
      captions,
      quizAttempts
    )
  );
}

function makeAutoDecks(
  now: number,
  favorites: Array<Favorite>,
  quizAttempts: Array<QuizAttempt>,
  youtubeLanguages: YouTube$i18nLanguageListResponse
): Array<ComputedFlashCardDeck> {
  const langs: Array<string> = uniq(
    filterNull(
      favorites.map(f =>
        f.transcriptionLang ? normalizeLanguage(f.transcriptionLang) : null
      )
    )
  ).sort();

  return langs.map(lang => {
    const filtered = favorites.filter(
      f =>
        f.transcriptionLang &&
        normalizeLanguage(f.transcriptionLang) === normalizeLanguage(lang)
    );
    return makeComputedDeck(
      now,
      languageNameFromResponse(lang, youtubeLanguages),
      { lang: lang },
      filtered,
      quizAttempts
    );
  });
}

function makeComputedDeck(
  now: number,
  name: string,
  filter: FlashCardFilter,
  favorites: Array<Favorite>,
  quizAttempts: Array<QuizAttempt>
): ComputedFlashCardDeck {
  return {
    filter: filter,
    name: name,
    count: favorites.length,
    due: findDueCards(now, favorites, quizAttempts).length,
    nextCardDueAt: nextCardDueAt(favorites, quizAttempts)
  };
}

export type FlashCardFilter = {
  lang?: string,
  deck?: number,
  random?: string,
  demo?: string
};

type ComputedFlashCardDeck = {
  name: string,
  count: number,
  due: number,
  filter: FlashCardFilter,
  nextCardDueAt: number
};

type ViewProps = {
  nativeLang: string,
  targetLang: ?string,
  onLogin: () => void,
  onLogout: () => void,
  isLoggedIn: boolean,
  now: number,
  decks: Array<ComputedFlashCardDeck>,
  favoriteCount: number,
  userResources: ?UserResourceStore
};

export function FlashCardsDashboardView(props: ViewProps): React.Node {
  const strings = stringsForLocale(props.nativeLang).flash_cards.dashboard;

  const dueCount = sum(props.decks.map(d => d.due));

  let tipEl;
  if (props.isLoggedIn) {
    if (props.favoriteCount > 0) {
      tipEl = <CustomDeckTip nativeLang={props.nativeLang} />;
    } else {
      tipEl = <SearchVideosTip nativeLang={props.nativeLang} />;
    }
  } else {
    tipEl = <LoginTip onLogin={props.onLogin} nativeLang={props.nativeLang} />;
  }

  return (
    <React.Fragment>
      <PageHeader
        onLogin={props.onLogin}
        onLogout={props.onLogout}
        nativeLang={props.nativeLang}
        targetLang={props.targetLang}
        isLoggedIn={props.isLoggedIn}
        userResources={props.userResources}
        enableMiniHeader={true}
      />
      <PageContent>
        <Box>
          <DecksHeader>
            <h1>{strings.header()}</h1>

            {dueCount > 0 && props.decks.length > 2 ? (
              <HeaderAction>
                <ButtonLink count={dueCount} href="/flash-cards/review">
                  {strings.actions.review_all()}
                  <ButtonCount color="#333">{dueCount}</ButtonCount>
                </ButtonLink>
              </HeaderAction>
            ) : null}
          </DecksHeader>

          {props.decks.map((deck, i) => (
            <DeckView
              key={i}
              deck={deck}
              nativeLang={props.nativeLang}
              now={props.now}
            />
          ))}

          {tipEl}
        </Box>
      </PageContent>
    </React.Fragment>
  );
}

const ButtonCount = styled.span`
  &::before {
    position: relative;
    top: 2px;
    margin: 0 ${buttonPadding}px;
    opacity: 0.4;
    width: 1px;
    height: 1em;
    display: inline-block;
    background-color: ${props => props.color};
    content: "";
  }
`;

const DecksHeader = styled.div`
  margin-bottom: 1em;
  overflow: hidden;
  display: flex;

  h1 {
    margin: 0;
    flex-grow: 1;
    font-size: 24px;
  }
`;

const HeaderAction = styled.div`
  flex: 0;
  a {
    white-space: nowrap;
  }
`;

type DashboardItemProps = {
  title1: string,
  title2: string,
  children: React.Node
};

const TitleRow = styled.div`
  margin-bottom: 2em;
`;
const ActionRow = styled.div`
  overflow: hidden;
`;

const TitleText = styled.div`
  font-weight: ${props => (props.bold ? "bold" : "normal")};
`;

const PrimaryButtonLink: typeof ButtonLink = styled(ButtonLink)`
  float: right;
`;

function DashboardTip(props: {
  text: string,
  nativeLang: string,
  actionEl: React.Node
}) {
  const strings = stringsForLocale(props.nativeLang);

  return (
    <DashboardTipStyles>
      <LightBulbIconSVG width="25" />
      <TipText>
        <b>{strings.flash_cards.dashboard.tip_header()}: </b>
        {props.text}
      </TipText>
      <TipAction>{props.actionEl}</TipAction>
    </DashboardTipStyles>
  );
}

function SearchVideosTip(props: { nativeLang: string }) {
  const strings = stringsForLocale(props.nativeLang);
  return (
    <DashboardTip
      text={strings.flash_cards.dashboard.search_videos_tip()}
      nativeLang={props.nativeLang}
      actionEl={
        <ButtonLink href="/search">
          {strings.flash_cards.dashboard.actions.search()}
        </ButtonLink>
      }
    />
  );
}

function CustomDeckTip(props: { nativeLang: string }) {
  const strings = stringsForLocale(props.nativeLang);

  return (
    <DashboardTip
      text={strings.flash_cards.dashboard.custom_deck_tip()}
      nativeLang={props.nativeLang}
      actionEl={
        <ButtonLink href="/flash-cards/decks/new">
          {strings.flash_cards.dashboard.actions.create_custom_deck()}
        </ButtonLink>
      }
    />
  );
}

function LoginTip(props: { onLogin: Function, nativeLang: string }) {
  const strings = stringsForLocale(props.nativeLang);

  return (
    <DashboardTip
      text={strings.flash_cards.dashboard.login_tip()}
      nativeLang={props.nativeLang}
      actionEl={
        <ActionButton onActivated={props.onLogin}>
          {strings.page_header.login_action()}
        </ActionButton>
      }
    />
  );
}

function DashboardItem(props: DashboardItemProps) {
  return (
    <DashboardItemStyles>
      <TitleRow>
        <TitleText bold>{props.title1}</TitleText>
        <TitleText>{props.title2}</TitleText>
      </TitleRow>
      {props.children}
    </DashboardItemStyles>
  );
}

const DashboardItemStyles = styled.div`
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 1em;
  margin-bottom: 1em;
  background-color: white;
  b {
    font-weight: bold;
  }
`;

const DashboardTipStyles = styled(DashboardItemStyles)`
  display: flex;
  a {
    white-space: nowrap;
  }
`;

const TipText = styled.div`
  margin-left: 1em;
  flex: 1;
`;
const TipAction = styled.div`
  flex: 0;
`;

const DeckMessage = styled.div`
  margin: 0;
  margin-bottom: 2em;
  text-align: center;
`;

export function hrefForFilter(filter: FlashCardFilter): string {
  const base = "/flash-cards/review";
  if (!filter.lang && !filter.random && !filter.deck && !filter.demo)
    return base;
  else return base + "?" + qsStringify(filter);
}

function timerString(milliseconds: number, nativeLang: string) {
  const strings = stringsForLocale(nativeLang).flash_cards;

  const seconds = Math.floor(milliseconds / 1000);
  const ONE_DAY = 24 * 60 * 60;
  if (seconds < ONE_DAY) {
    return secondsToString(seconds);
  } else {
    return strings.days_count({ COUNT: Math.floor(seconds / ONE_DAY) });
  }
}

function DeckView(props: {
  deck: ComputedFlashCardDeck,
  nativeLang: string,
  now: number
}) {
  const strings = stringsForLocale(props.nativeLang).flash_cards.dashboard;
  const reviewedCount = props.deck.count - props.deck.due;

  let editEl;
  if (props.deck.filter.deck) {
    const deckId = props.deck.filter.deck;
    editEl = (
      <ButtonLink href={`/flash-cards/decks/${deckId}`}>
        {strings.actions.edit_deck()}
      </ButtonLink>
    );
  }

  let title;
  if (props.deck.filter.lang) {
    title = strings.saved_captions_deck();
  } else if (props.deck.filter.demo) {
    title = strings.free_demo_deck_header();
  } else if (props.deck.filter.deck) {
    title = strings.custom_deck_header();
  } else {
    // This shouldn't happen. For the sake of flow
    title = "";
  }

  return (
    <DashboardItem title1={props.deck.name} title2={title}>
      {props.deck.due === 0 ? (
        <React.Fragment>
          <DeckMessage>
            {props.deck.count > 0 ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: strings.next_card_due({
                    TIMER: makeBold(
                      timerString(
                        props.deck.nextCardDueAt - props.now,
                        props.nativeLang
                      )
                    )
                  })
                }}
              />
            ) : (
              strings.empty_deck()
            )}
          </DeckMessage>
          <ActionRow>
            {editEl}

            {props.deck.count > 0 ? (
              <PrimaryButtonLink
                primary
                href={hrefForFilter({ ...props.deck.filter, random: "1" })}
              >
                {strings.actions.review_random()}
              </PrimaryButtonLink>
            ) : null}
          </ActionRow>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <DeckMessage>
            <ProgressBar progress={reviewedCount / props.deck.count} />

            <TextWithBoldNumbers
              text={strings.card_count({
                REVIEWED_COUNT: reviewedCount,
                TOTAL_COUNT: props.deck.count
              })}
            />
          </DeckMessage>

          <ActionRow>
            {editEl}
            <PrimaryButtonLink primary href={hrefForFilter(props.deck.filter)}>
              {strings.actions.review_deck()}
              <ButtonCount color="white">{props.deck.due}</ButtonCount>
            </PrimaryButtonLink>
          </ActionRow>
        </React.Fragment>
      )}
    </DashboardItem>
  );
}

function makeBold(innerText: number | string) {
  return `<b>${String(innerText)}</b>`;
}

function ProgressBar(props: { progress: number }) {
  return (
    <svg height="10px" width="100%">
      <rect x="0" y="0" width="100%" height="100%" fill="#eee" rx="4" />
      <rect
        x="0"
        y="0"
        rx="4"
        width={String(props.progress * 100) + "%"}
        height="100%"
        fill={colors.highlight}
      />
    </svg>
  );
}

function LightBulbIconSVG(props) {
  return (
    <svg x="0px" y="0px" viewBox="0 0 50 50" {...props}>
      <path d="M28,49v-4h6v-5v-1v-6.593c4.949-3.099,8-8.565,8-14.407c0-9.374-7.626-17-17-17S8,8.626,8,18c0,5.841,3.051,11.308,8,14.407  V39v1v5h6v4H28z M26,47h-2v-2h2V47z M10,18c0-8.271,6.729-15,15-15s15,6.729,15,15c0,5.324-2.875,10.296-7.502,12.977L32,31.266V38  h-6V20.414l5.707-5.707l-1.414-1.414L25,18.586l-5.293-5.293l-1.414,1.414L24,20.414V38h-6v-6.734l-0.499-0.289  C12.874,28.296,10,23.324,10,18z M18,40h14v3H18V40z" />
    </svg>
  );
}
