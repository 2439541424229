/* @flow */

import * as React from "react";

import styled from "styled-components";
import spinnerUrl from "../assets/spinner.svg";

const PageContent = styled.div`
  text-align: center;
`;

export default function LoadingPage(props: {}) {
  return (
    <PageContent>
      <img src={spinnerUrl} width="64px" />
    </PageContent>
  );
}
