/* @flow */

import * as React from "react";

import type { ServerSideProps } from "./sendSPA";
import parseSearchString from "./parseSearchString";
import ErrorPage, { ActionBar, Header } from "./ErrorPage";
import { ButtonLink, ActionButton } from "./Buttons";

import { showReportDialog } from "@sentry/browser";

type Props = {
  nativeLang: string,
  serverSideProps: ServerSideProps
};

export default function OAuth2RedirectPage(props: Props) {
  function onClickReport() {
    // https://docs.sentry.io/learn/user-feedback/?platform=javascript
    showReportDialog({
      eventId: props.serverSideProps.eventId,
      lang: props.nativeLang
    });
  }

  const [backLink, setBackLink] = React.useState("/");

  React.useEffect(() => {
    const params = parseSearchString(document.location.search);
    if ("state" in params) {
      setBackLink(params.state);
    }
  }, []);

  if (typeof props.serverSideProps.eventId === "string") {
    return (
      <ErrorPage nativeLang={props.nativeLang}>
        <Header color="#888">Sorry, there was a problem logging in.</Header>

        <p>
          A notification has been sent to Jon (the maker of CaptionPop) and he
          will investigate.
        </p>

        <p>
          You can try to login again, or fill out crash report to help Jon
          resolve the issue faster.
        </p>

        <ActionBar>
          <ButtonLink href={backLink}>Go back</ButtonLink>
          <ActionButton onActivated={onClickReport}>File report</ActionButton>
          <ButtonLink primary href="#login">
            Try to login again
          </ButtonLink>
        </ActionBar>
      </ErrorPage>
    );
  } else {
    // In this case, useAuthentication should immediately replace the location
    // TODO: Consider adding oauth-callback to the SSR list, and then rending some kind of waiting
    // message here.
    // The challenge will be only rendering SSR when there isn't an error to report
    return null;
  }
}
