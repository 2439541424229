/* @flow */

import * as React from "react";
import styled from "styled-components";

import { stringsForLocale } from "../lang/web";

import { SubmitButton } from "./Buttons";

import { NativeLanguageSelect, TargetLanguageSelect } from "./LanguageSelect";

type Props = {
  targetLang: ?string,
  nativeLang: string,
  onChangeNativeLang: string => void,
  onChangeTargetLang: string => void,
  submitLabel: string,
  className?: string,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  defaultOpen?: boolean // This is only used for storybook screenshots
};

type State = {
  targetLang: ?string
};

export default class ChooseLanguagesForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      targetLang: props.targetLang
    };
  }

  onSubmit(event: Event) {
    event.preventDefault();
    if (this.state.targetLang) {
      this.props.onChangeTargetLang(this.state.targetLang);
    }
  }

  render() {
    const strings = stringsForLocale(this.props.nativeLang);

    const isDisabled = isBlank(this.state.targetLang);

    return (
      <FormStyles
        onSubmit={this.onSubmit.bind(this)}
        className={this.props.className}
      >
        <LanguageSelectsWrapper>
          <label>{strings.choose_languages.select_native_lang_prompt()}</label>

          <NativeLanguageSelect
            instanceId="choose-lang-form-native-lang"
            nativeLang={this.props.nativeLang}
            youtubeLanguages={this.props.youtubeLanguages}
            onChangeNativeLang={this.props.onChangeNativeLang}
          />

          <label>{strings.choose_languages.select_target_lang_prompt()}</label>

          <TargetLanguageSelect
            includeFeatured
            instanceId="choose-lang-form-target-lang"
            nativeLang={this.props.nativeLang}
            targetLang={this.state.targetLang}
            youtubeLanguages={this.props.youtubeLanguages}
            defautlOpen={this.props.defaultOpen}
            onChange={targetLang => this.setState({ targetLang })}
          />
        </LanguageSelectsWrapper>

        <StyledSubmitButton
          disabled={isDisabled}
          primary
          value={this.props.submitLabel}
        />
      </FormStyles>
    );
  }
}

function isBlank(s: ?string) {
  return s == null || s.trim() === "";
}

const LanguageSelectsWrapper = styled.div`
  // iPhone 6/7/8 Plus
  @media (max-width: 414px) {
    display: block;
  }

  display: grid;
  grid-column-gap: 2em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;

  label {
    grid-row-start: 1;
  }

  select {
    grid-row-start: 2;
  }

  label {
    display: block;
    margin: 0.5em 0;
    line-height: 1.865;
  }

  select {
    height: 1.865em;
  }
`;

const FormStyles = styled.form``;

const StyledSubmitButton: typeof SubmitButton = styled(SubmitButton)`
  margin: 1em auto;
  display: block;
`;
