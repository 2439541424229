/* @flow */

import * as React from "react";

import SentryErrorPage from "./SentryErrorPage";
import type { ServerSideProps } from "./sendSPA";

import { stringsForLocale } from "../lang/web";

type Props = {
  nativeLang: string,
  serverSideProps: ServerSideProps
};

import ErrorPage, { ActionBar, Header } from "./ErrorPage";
import { ButtonLink } from "./Buttons";

export default function PasswordResetPage(props: Props) {
  if (typeof props.serverSideProps.eventId === "string") {
    return (
      <SentryErrorPage
        nativeLang={props.nativeLang}
        eventId={props.serverSideProps.eventId}
        error={null}
      />
    );
  } else if (props.serverSideProps.result === "invalid-link") {
    const strings = stringsForLocale(props.nativeLang)
      .password_reset_invalid_page;

    return (
      <ErrorPage nativeLang={props.nativeLang}>
        <Header>{strings.header()}</Header>
        <p>{strings.text()}</p>

        <ActionBar>
          <ButtonLink href="/">{strings.actions.home()}</ButtonLink>
          <ButtonLink href="#forgot-password">
            {strings.actions.request_new()}
          </ButtonLink>
        </ActionBar>
      </ErrorPage>
    );
  } else {
    // In this case, useAuthentication should immediately replace the location
    return null;
  }
}
