/* @flow */

import * as React from "react";

import { ButtonLink } from "./Buttons";
import ErrorPage, { ActionBar, Header } from "./ErrorPage";
import PageHeader from "./PageHeader";
import type { UserResourceStore } from "./WithUserResourceStore";

type Props = {
  onLogin: Function,
  onLogout: Function,
  isLoggedIn: boolean,
  nativeLang: string,
  targetLang: ?string,
  error: YouTube$Error,
  userResources: ?UserResourceStore
};

export default class YouTubeErrorPage extends React.Component<Props> {
  render() {
    return (
      <React.Fragment>
        <PageHeader
          onLogin={this.props.onLogin}
          onLogout={this.props.onLogout}
          isLoggedIn={this.props.isLoggedIn}
          nativeLang={this.props.nativeLang}
          targetLang={this.props.targetLang}
          userResources={this.props.userResources}
        />
        <ErrorPage nativeLang={this.props.nativeLang} withHeader>
          <Header color="#888">
            CaptionPop had a problem searching YouTube.
          </Header>

          <p>{this.props.error.message}</p>

          <ActionBar>
            <ButtonLink href="/search">Go Back</ButtonLink>
          </ActionBar>
        </ErrorPage>
      </React.Fragment>
    );
  }
}
