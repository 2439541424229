/* @flow */

import * as React from "react";

import FlashCardsDeckForm from "./FlashCardsDeckForm";

import type { UserResourceStore } from "./WithUserResourceStore";
import type { Favorite, UnsavedFlashCardDeck } from "./api";
import type { SnackbarMessage } from "./useSnackbarQueue";

import WithLoginRequiredPage from "./WithLoginRequiredPage";
import WithPromise from "./WithPromise";
import WithDerivedState from "./WithDerivedState";

import { postJSON } from "./apiFetch";

import { stringsForLocale } from "../lang/web";

import { PageContent } from "./FormStyles";
import { SubmitButton } from "./Buttons";

import { getVideoTitles } from "./YoutubeScraperClient";

type Props = {
  userResources: ?UserResourceStore,
  isInitialized: boolean,
  nativeLang: string,
  onLogin: () => void,
  onLogout: () => void,
  isLoggedIn: boolean,
  onNavigate: string => void,
  onAddSnackbarMessage: SnackbarMessage => void,
  youtubeLanguages: YouTube$i18nLanguageListResponse
};

type State = {
  isSubmitted: boolean
};

export default class NewFlashCardsDeckPage extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = { isSubmitted: false };
  }
  onSubmit(withIdToken: () => Promise<string>, deck: UnsavedFlashCardDeck) {
    const strings = stringsForLocale(this.props.nativeLang).flash_cards.form;

    this.setState({ isSubmitted: true });

    withIdToken().then(idToken =>
      postJSON(idToken, "/api/flash-cards/decks", deck).then(
        () => {
          this.props.onAddSnackbarMessage({
            level: "message",
            body: strings.snackbar.created()
          });
          this.props.onNavigate("/flash-cards");
        },
        error => {
          this.props.onAddSnackbarMessage({
            level: "error",
            body: strings.snackbar.error()
          });
          this.setState({ isSubmitted: false });
        }
      )
    );
  }

  render() {
    return (
      <WithLoginRequiredPage
        onLogin={this.props.onLogin}
        userResources={this.props.userResources}
        nativeLang={this.props.nativeLang}
        isInitialized={this.props.isInitialized}
        render={userResources => (
          <WithPromise
            promise={userResources.favorites.favorites}
            renderPending={() => null}
            renderRejected={error => null}
            renderResolved={favorites => (
              <WithDerivedState
                controllerProps={favorites.map(f => f.videoId)}
                controller={getVideoTitles}
                render={videoTitlesPromise => (
                  <NewFlashCardsDeckView
                    favorites={favorites}
                    onSubmit={this.onSubmit.bind(
                      this,
                      userResources.withIdToken
                    )}
                    isSubmitted={this.state.isSubmitted}
                    youtubeLanguages={this.props.youtubeLanguages}
                    videoTitlesPromise={videoTitlesPromise}
                    nativeLang={this.props.nativeLang}
                  />
                )}
              />
            )}
          />
        )}
      />
    );
  }
}

type ViewProps = {
  onSubmit: UnsavedFlashCardDeck => void,
  favorites: Array<Favorite>,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  videoTitlesPromise: Promise<{ [string]: string }>,
  nativeLang: string,
  isSubmitted: boolean
};

export class NewFlashCardsDeckView extends React.Component<ViewProps> {
  render() {
    const strings = stringsForLocale(this.props.nativeLang).flash_cards.form;

    return (
      <PageContent>
        <h1>{strings.create_header()}</h1>

        <FlashCardsDeckForm
          {...this.props}
          renderActions={isSubmitDisabled => (
            <SubmitButton
              disabled={isSubmitDisabled}
              primary
              value={strings.actions.create()}
            />
          )}
        />
      </PageContent>
    );
  }
}
