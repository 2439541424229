/* @flow */

import * as React from "react";
import styled, { css } from "styled-components";
import { colors } from "./theme";

export type FormEvent =
  | SyntheticInputEvent<HTMLInputElement>
  | SyntheticInputEvent<HTMLTextAreaElement>;

type Props = {
  errorMessages?: Array<string>,
  name: string,
  value: string,
  renderInput: (props: InputProps) => React.Node,
  label?: string,
  id: string,
  onChange: FormEvent => void,
  onFocus?: FormEvent => void,
  onBlur?: FormEvent => void,
  className?: string
};

type InputProps = {
  name: string,
  value: string,
  id: string,
  "aria-invalid": string,
  onChange: FormEvent => void,
  onFocus?: FormEvent => void,
  onBlur?: FormEvent => void
};

export function FormField(props: Props) {
  let isInvalid;
  let validationErrorEl;
  if (props.errorMessages != null && props.errorMessages.length > 0) {
    isInvalid = true;
    validationErrorEl = (
      <ValidationError>{props.errorMessages[0]}</ValidationError>
    );
  } else {
    isInvalid = false;
    validationErrorEl = null;
  }

  return (
    <FormFieldStyles className={props.className} error={isInvalid}>
      {props.label ? <Label htmlFor={props.id}>{props.label}</Label> : null}

      <InputWithValidationMessage>
        {props.renderInput({
          id: props.id,
          name: props.name,
          value: props.value,
          onBlur: props.onBlur,
          onFocus: props.onFocus,
          onChange: props.onChange,
          "aria-invalid": isInvalid ? "true" : "false"
        })}

        {validationErrorEl}
      </InputWithValidationMessage>
    </FormFieldStyles>
  );
}

const InputIcon = styled.label`
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none !important;
  svg {
    display: inline;
    vertical-align: text-top;
  }
`;

const InputWithIconStyles = styled.div`
  display: flex;

  input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
`;

type InputWithIconProps = {
  type: string,
  id: string,
  name: string,
  icon: React.Element<"svg">,
  onChange: (event: FormEvent) => void,
  value: string,
  placeholder?: string,
  className?: string
};

export function InputWithIcon(props: InputWithIconProps) {
  return (
    <InputWithIconStyles className={props.className}>
      <InputIcon htmlFor={props.id}>
        {React.cloneElement(props.icon, { width: 16, height: 16 })}
      </InputIcon>
      <input
        type={props.type}
        placeholder={props.placeholder}
        name={props.name}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
      />
    </InputWithIconStyles>
  );
}

import { buttonSize } from "./Buttons";

export const inputStyle = css`
  ${buttonSize}
  border: 1px solid ${props => (props.error ? colors.warning : "#ddd")};
  border-radius: 4px;

  &:disabled {
    background-color: #eee;
  }
`;

const FormFieldStyles = styled.div`
  ${InputIcon},
  input,
  textarea {
    ${inputStyle}
  }
`;

const Label = styled.label`
  margin-bottom: 0.25em;
  display: block;
`;

const InputWithValidationMessage = styled.div`
  position: relative;
  padding-bottom: 1.25em;
`;

export const ValidationError = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  color: ${colors.warning};
  white-space: nowrap;
`;

export const RoundedFormField = styled(FormField)`
  input {
    border-radius: 100px;
  }
`;
