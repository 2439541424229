/* @flow */

import * as React from "react";
import BaseSelect from "react-select";

// $FlowFixMe - YAML isn't supported
import nativeLanguageNames from "../lang/nativeLanguageNames";

import { stringsForLocale } from "../lang/web";
import { sortBy, includes } from "lodash";

type OptionValue = { value: string, label: string };

type OptionGroup = {
  label: string,
  options: Array<OptionValue>
};

type Props = {
  value: ?OptionValue,
  onChange: OptionValue => void,
  options: Array<OptionValue> | Array<OptionGroup>,
  instanceId: string,
  defaultOpen: boolean,
  placeholder: string,
  className?: string,
  controlStyles?: Object
};

export default function LanguageSelect(props: Props) {
  const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(
    props.defaultOpen
  );
  const customStyles = {
    control: styles => ({ ...styles, ...props.controlStyles }),
    option: (base, _state) => ({
      ...base,
      padding: "0px 12px" // Default: 8px 12px
    })
  };

  const placeholder = menuIsOpen ? "" : props.placeholder;

  return (
    <BaseSelect
      className={props.className}
      value={props.value}
      instanceId={props.instanceId}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
      onChange={props.onChange}
      options={props.options}
      placeholder={placeholder}
      isClearable={false}
      styles={customStyles}
      backspaceRemovesValue={false}
    />
  );
}

type NativeLanguageSelectProps = {
  nativeLang: string,
  onChangeNativeLang: string => void,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  instanceId: string,
  className?: string,
  controlStyles?: Object
};

export function NativeLanguageSelect(props: NativeLanguageSelectProps) {
  const strings = stringsForLocale(props.nativeLang);

  const placeholder = strings.choose_languages.select_placeholder();

  const originalOptions = optionsForNativeLanguageSelect(
    props.youtubeLanguages
  );
  const groupedOptions = groupOptions(
    props.nativeLang,
    optionsForNativeLanguageSelect(props.youtubeLanguages)
  );

  return (
    <LanguageSelect
      className={props.className}
      defaultOpen={false}
      instanceId={props.instanceId}
      controlStyles={props.controlStyles}
      placeholder={placeholder}
      value={originalOptions.find(o => o.value === props.nativeLang)}
      onChange={option => {
        if (option) {
          props.onChangeNativeLang(option.value);
        }
      }}
      options={groupedOptions}
    />
  );
}

type TargetLanguageSelectProps = {
  nativeLang: string,
  targetLang: ?string,
  onChange: string => void,
  youtubeLanguages: YouTube$i18nLanguageListResponse,
  instanceId: string,
  placeholder?: string,
  includeFeatured: boolean,
  defaultOpen?: boolean,
  className?: string,
  controlStyles?: Object
};

export function TargetLanguageSelect(props: TargetLanguageSelectProps) {
  const strings = stringsForLocale(props.nativeLang);
  const originalOptions = optionsForLanguageSelect(props.youtubeLanguages);

  let options: Array<OptionGroup> | Array<OptionValue>;
  if (props.includeFeatured) {
    options = groupOptions(props.nativeLang, originalOptions);
  } else {
    options = originalOptions;
  }

  const placeholder =
    props.placeholder == null
      ? strings.choose_languages.select_placeholder()
      : props.placeholder;

  return (
    <LanguageSelect
      className={props.className}
      defaultOpen={props.defaultOpen || false}
      instanceId={props.instanceId}
      controlStyles={props.controlStyles}
      placeholder={placeholder}
      value={originalOptions.find(o => o.value === props.targetLang)}
      onChange={option => {
        if (option) {
          props.onChange(option.value);
        }
      }}
      options={options}
    />
  );
}

function groupOptions(
  nativeLang: string,
  options: Array<OptionValue>
): Array<OptionGroup> {
  const strings = stringsForLocale(nativeLang);

  const featuredLanguages = ["en", "ja", "ko", "es", "fr"];

  const featuredLanguageOptions: Array<OptionValue> = options
    .filter(o => includes(featuredLanguages, o.value))
    .map(o => ({ ...o })); // Clone the options, so we don't confuse react-select

  return [
    {
      label: strings.choose_languages.featured_languages(),
      options: featuredLanguageOptions
    },
    {
      label: strings.choose_languages.all_languages(),
      options: options
    }
  ];
}

function sortOptions(list: Array<{ label: string }>): Array<Object> {
  return sortBy(list, i => i.label);
}

//
// Creates select options that include each language's native name
//
export function optionsForNativeLanguageSelect(
  youtubeLanguages: YouTube$i18nLanguageListResponse
): Array<OptionValue> {
  return sortOptions(
    youtubeLanguages.items.map(item => ({
      value: item.id,
      label: combineLanguageNames(
        nativeLanguageNames[item.id],
        item.snippet.name
      )
    }))
  );
}

export function optionsForLanguageSelect(
  youtubeLanguages: YouTube$i18nLanguageListResponse
): Array<OptionValue> {
  return sortOptions(
    youtubeLanguages.items.map(item => ({
      value: item.id,
      label: item.snippet.name
    }))
  );
}

function combineLanguageNames(nativeLangFn: ?Function, name2: string) {
  if (nativeLangFn == null) return name2;

  const name1 = nativeLangFn();

  if (name1 === name2) {
    return name1;
  } else {
    return name1 + " - " + name2;
  }
}
