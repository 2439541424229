/* @flow */
import * as React from "react";

import { addBreadcrumb, captureException } from "@sentry/browser";

import type { TimedText, TimedTextTrack } from "./timedtext2";

import mergeSubtitles, { findActiveSubtitle } from "./mergeSubtitles";
import type { MergedText } from "./mergeSubtitles";

import playerActionForKeyboardEvent from "./playerActionForKeyboardEvent";
import focusHack from "./focusHack";

import PlayVideoPageView from "./PlayVideoPageView";
import TranslationPopup from "./TranslationPopup";

import * as YouTube from "./youtube";

import { makeCancellable } from "./Cancellable";
import type { Cancellable } from "./Cancellable";
import type { UserResourceStore } from "./WithUserResourceStore";
import type { SnackbarMessage } from "./useSnackbarQueue";
import type { DocumentLocation } from "./useNavigation";
import type { YouTubeVideo } from "./youtubeScraper";

import PlaybackInterval from "./PlaybackInterval";
import { fetchTimedText, fetchTimedTextList } from "./timedtext2";

import { stringsForLocale } from "../lang/web";

import { useTranslationPopup } from "./useTranslationPopup";
import { usePauseTimeout } from "./useBoundedPlayback";
import useLocalStorage from "./useLocalStorage";
import type { ReferralSource } from "./useReferralSource";

import type { ServerSideProps } from "./sendSPA";

type Props = {
  videoId: string,
  location: DocumentLocation,
  onLogin: (flashMessage?: string) => void,
  onLogout: () => void,
  withIdToken: ?() => Promise<string>,
  idToken: string | null,
  onReplaceLocation: (
    href: string,
    state: ?Object,
    currentPathnane: string
  ) => void,
  onAddSnackbarMessage: SnackbarMessage => void,
  targetLang: ?string,
  nativeLang: string,
  referralSource: ReferralSource | null,
  isLoggedIn: boolean,
  pageResources: Promise<YouTubeVideo>,
  userResources: ?UserResourceStore,
  serverSideProps: ServerSideProps
};

type State = {|
  activeSubtitleIndex: number,
  showTranslation: boolean,
  subtitles: ?Array<MergedText>,
  transcriptionEntry: ?TimedTextTrack,
  translationEntry: ?TimedTextTrack,
  timedTextList: ?Array<TimedTextTrack>,
  availablePlaybackRates: Array<number>,
  playbackRate: number
|};

type SerializedPageState = {
  videoId: string,
  serializedAt: number,
  scrollY: number,
  currentTime: number,
  playerState: number,
  action: SerializedAction
};

type SerializedAction =
  | {
      type: "add-favorite",
      subtitle: Object,
      translationLang?: string,
      transcriptionLang?: string
    }
  | { type: "add-to-library" };

export default class PlayVideoPage extends React.Component<Props, State> {
  constructor() {
    super();

    this.state = {
      activeSubtitleIndex: 0,
      subtitles: null,
      showTranslation: false,
      transcriptionEntry: null,
      translationEntry: null,
      timedTextList: null,
      availablePlaybackRates: [1],
      playbackRate: 1
    };

    this.playbackInterval = new PlaybackInterval(this.tick.bind(this), 250);

    this.onSeek = this.onSeek.bind(this);
    this.move = this.move.bind(this);
    this.onToggleTranslation = this.onToggleTranslation.bind(this);
    this.onAddFavorite = this.onAddFavorite.bind(this);
    this.onRemoveFavorite = this.onRemoveFavorite.bind(this);
    this.transcriptionPromise = makeCancellable(Promise.resolve([]));
    this.translationPromise = makeCancellable(Promise.resolve([]));
  }

  transcriptionPromise: Cancellable<Array<TimedText>>;
  translationPromise: Cancellable<Array<TimedText>>;
  timedTextListPromise: Cancellable<Array<TimedTextTrack>>;
  move: number => void;

  componentDidMount() {
    // TODO: Move this into WithPlaybackTracker
    let trackOptions: Object = { videoId: this.props.videoId };
    const refMatch = document.location.search.match(/\bref=(\w+)/);
    if (refMatch) {
      trackOptions.ref = refMatch[1];
    }
    mixpanel.track("Played Video", trackOptions);

    this.timedTextListPromise = makeCancellable(
      fetchTimedTextList(this.props.videoId)
    );

    this.timedTextListPromise.promise.then(list => {
      const translationEntry = pickTimedTextTrack(this.props.nativeLang, list);

      // If the user hasn't specified a target lang, pick the first subtitle
      // that isn't the native language
      let transcriptionLang;
      if (
        this.props.videoId === "_ygaIOsw_XE" ||
        this.props.videoId === "P5453AQpsKc"
      ) {
        transcriptionLang = "en";
      } else {
        transcriptionLang = pickTranscriptionLang(
          list,
          this.props.targetLang,
          this.props.nativeLang
        );
      }

      const transcriptionEntry = pickTimedTextTrack(
        transcriptionLang,
        list.filter(i => i.name.simpleText.indexOf("Romanized") === -1)
      );

      this.loadTranscriptions(transcriptionEntry);
      this.loadTranslations(translationEntry);
      this.subscribeToSubtitles();

      this.setState({
        translationEntry,
        transcriptionEntry,
        timedTextList: list
      });
    });
  }

  onChangeTranscription(entry: TimedTextTrack) {
    this.setState({ transcriptionEntry: entry });
    this.loadTranscriptions(entry);
    this.subscribeToSubtitles();
  }

  onChangeTranslation(entry: TimedTextTrack) {
    this.setState({ translationEntry: entry });
    this.loadTranslations(entry);
    this.subscribeToSubtitles();
  }

  onSwapLanguages() {
    this.setState({
      translationEntry: this.state.transcriptionEntry,
      transcriptionEntry: this.state.translationEntry
    });

    const tmp = this.transcriptionPromise;
    this.transcriptionPromise = this.translationPromise;
    this.translationPromise = tmp;

    this.subscribeToSubtitles();
  }

  loadTranscriptions(entry: ?TimedTextTrack) {
    this.transcriptionPromise.cancel();
    this.transcriptionPromise = makeCancellable(
      entry ? fetchTimedText(entry.baseUrl) : Promise.resolve([])
    );
  }

  loadTranslations(entry: ?TimedTextTrack) {
    this.translationPromise.cancel();
    this.translationPromise = makeCancellable(
      entry ? fetchTimedText(entry.baseUrl) : Promise.resolve([])
    );
  }

  subscribeToSubtitles() {
    this.setState({ subtitles: null });
    Promise.all([
      this.transcriptionPromise.promise,
      this.translationPromise.promise
    ]).then(
      ([transcriptions, translations]) => {
        this.setState({
          subtitles: mergeSubtitles(transcriptions, translations)
        });
      },
      error => {
        if (!error.isCancelled) {
          captureException(error);
        }
      }
    );
  }

  componentWillUnmount() {
    this.translationPromise.cancel();
    this.transcriptionPromise.cancel();
    this.timedTextListPromise.cancel();

    this.playbackInterval.destroy();
  }

  playbackInterval: PlaybackInterval;
  player: ?YT$Player;

  setPlayerContainer: (el: ?HTMLElement) => void;
  onSeek: number => void;
  onToggleTranslation: boolean => void;
  onAddFavorite: Object => void;
  onRemoveFavorite: (time: number) => void;

  move(offset: number) {
    if (this.player && this.player.getCurrentTime && this.state.subtitles) {
      const subtitles = this.state.subtitles;
      const currentTime = this.player.getCurrentTime();

      const index = findActiveSubtitle(0, subtitles, currentTime);
      this.moveToSubtitle(index + offset);
    }
  }

  moveToSubtitle(index: number) {
    if (this.state.subtitles) {
      const subtitles = this.state.subtitles;
      const item = subtitles[index];
      if (item && this.player) {
        this.player.seekTo(item.start, true);

        // TODO: This should probably be debounced and cleaned up on unmount
        setTimeout(() => this.tick(), 250);
      }
    }
  }

  onPrevious() {
    this.move(-1);
  }

  onNext() {
    this.move(+1);
  }

  onSeek(index: number) {
    this.moveToSubtitle(index);
    if (this.player) {
      this.player.playVideo();
    }
  }

  onToggleTranslation(showTranslation: boolean) {
    this.setState({ showTranslation });
  }

  onPlayerReady(event: YT$Event) {
    addBreadcrumb({
      message: "YouTube player ready",
      category: "youtube",
      level: "info",
      data: {}
    });

    const player = event.target;

    this.player = player;

    this.setState({
      availablePlaybackRates: player
        .getAvailablePlaybackRates()
        .filter(n => n <= 1),
      playbackRate: player.getPlaybackRate()
    });

    // This happens after a login
    if (this.restorePageState(player)) return;

    if (this.props.location.hash) {
      const seekTo = parseFloat(this.props.location.hash.substr(1));
      if (isFinite(seekTo)) {
        player.seekTo(seekTo, true);

        // Remove the hash from the current location
        this.props.onReplaceLocation(
          this.props.location.pathname,
          null,
          this.props.location.pathname
        );
      }
    }
  }

  tick() {
    this.setState(state => {
      if (this.player && state.subtitles) {
        const subtitles = state.subtitles;
        const currentSubtitle = subtitles[state.activeSubtitleIndex];
        const currentTime = this.player.getCurrentTime();

        // Optimization to reduce the number of subtitles to search
        let startIndex;
        if (currentSubtitle && currentSubtitle.start < currentTime) {
          startIndex = state.activeSubtitleIndex;
        } else {
          startIndex = 0;
        }

        const index = findActiveSubtitle(startIndex, subtitles, currentTime);
        if (index !== state.activeSubtitleIndex) {
          return { activeSubtitleIndex: index };
        }
      }
    });

    focusHack();
  }

  savePageState(action: SerializedAction) {
    if (this.player && this.player.getCurrentTime) {
      const player = this.player;
      const pageState: SerializedPageState = {
        videoId: this.props.videoId,
        serializedAt: Date.now(),
        scrollY: window.scrollY,
        currentTime: player.getCurrentTime(),
        playerState: player.getPlayerState(),
        action: action
      };

      sessionStorage.setItem(
        "PlayVideoPage-pageState",
        JSON.stringify(pageState)
      );
    }
  }

  withSnackbarResponse(promise: Promise<any>, successMessage: string) {
    const strings = stringsForLocale(this.props.nativeLang);
    promise
      .then(
        () => ({
          level: "message",
          body: successMessage
        }),
        () => ({
          level: "error",
          body: strings.snackbar.network_error()
        })
      )
      .then(msg => this.props.onAddSnackbarMessage(msg));
  }

  restorePageState(player: YT$Player) {
    if (!this.props.isLoggedIn) return false;

    const str = sessionStorage.getItem("PlayVideoPage-pageState");
    if (!str) return false;

    sessionStorage.removeItem("PlayVideoPage-pageState");
    const pageState: SerializedPageState = JSON.parse(str);

    if (pageState.videoId !== this.props.videoId) return false;

    // The page state is only supposed to surive the login refresh, so if it's
    // older than 10 minutes, it should be ignored
    if (Date.now() - pageState.serializedAt > 10 * 60 * 1000) return false;

    window.scrollTo(0, pageState.scrollY);

    if (pageState.playerState === YT.PlayerState.PLAYING) {
      player.seekTo(pageState.currentTime, true);
      player.playVideo();
    }

    if (this.props.userResources) {
      const userResources = this.props.userResources;
      switch (pageState.action.type) {
        case "add-favorite":
          this.withSnackbarResponse(
            userResources.favorites.onAddFavorite({
              videoId: this.props.videoId,
              subtitle: pageState.action.subtitle,
              translationLang: pageState.action.translationLang,
              transcriptionLang: pageState.action.transcriptionLang,
              source: "subtitle"
            }),

            stringsForLocale(
              this.props.nativeLang
            ).snackbar.added_to_favorite_captions()
          );
          break;
        case "add-to-library":
          this.onAddToVideoLibrary();
          break;
      }
    }

    return true;
  }

  onAddToVideoLibrary() {
    const strings = stringsForLocale(this.props.nativeLang);

    if (this.props.userResources) {
      this.withSnackbarResponse(
        this.props.userResources.videoLibrary.onAddToVideoLibrary({
          mediaId: this.props.videoId,
          mediaType: "video",
          nativeLang: this.props.nativeLang,
          targetLang: this.props.targetLang
        }),

        strings.snackbar.video_added_to_library()
      );
    } else {
      this.savePageState({ type: "add-to-library" });
      this.props.onLogin(strings.login_form.library_prompt());
    }
  }

  onAddFavorite(subtitle: Object) {
    const strings = stringsForLocale(this.props.nativeLang);

    if (this.props.userResources) {
      this.withSnackbarResponse(
        this.props.userResources.favorites.onAddFavorite({
          ...this.getLangs(),
          videoId: this.props.videoId,
          subtitle: subtitle,
          source: "subtitle"
        }),
        strings.snackbar.added_to_favorite_captions()
      );
    } else {
      this.savePageState({
        ...this.getLangs(),
        type: "add-favorite",
        subtitle
      });

      this.props.onLogin(strings.login_form.favorite_prompt());
    }
  }

  getLangs(): { translationLang?: string, transcriptionLang?: string } {
    const obj = {};
    if (this.state.translationEntry) {
      obj.translationLang = this.state.translationEntry.languageCode;
    }
    if (this.state.transcriptionEntry) {
      obj.transcriptionLang = this.state.transcriptionEntry.languageCode;
    }
    return obj;
  }

  onRemoveFavorite(id: number) {
    if (this.props.userResources) {
      this.withSnackbarResponse(
        this.props.userResources.favorites.onRemoveFavorite(id),
        stringsForLocale(
          this.props.nativeLang
        ).snackbar.removed_from_favorite_captions()
      );
    }
  }

  onRemoveFromVideoLibrary() {
    if (this.props.userResources) {
      this.withSnackbarResponse(
        this.props.userResources.videoLibrary.onRemoveFromVideoLibrary(
          this.props.videoId,
          "video"
        ),

        stringsForLocale(
          this.props.nativeLang
        ).snackbar.video_removed_from_library()
      );
    }
  }

  render() {
    const pendingLibraryItems = this.props.userResources
      ? this.props.userResources.videoLibrary.pendingItems
      : [];

    return (
      <PlayVideoPageInner
        referralSource={this.props.referralSource}
        location={this.props.location}
        videoId={this.props.videoId}
        onLogin={this.props.onLogin}
        onLogout={this.props.onLogout}
        nativeLang={this.props.nativeLang}
        targetLang={this.props.targetLang}
        withIdToken={this.props.withIdToken}
        move={this.move}
        isLoggedIn={this.props.isLoggedIn}
        restricted={this.props.serverSideProps["restricted"] == "true"}
        userResources={this.props.userResources}
        pageResources={this.props.pageResources}
        onSwapLanguages={this.onSwapLanguages.bind(this)}
        onChangeTranscription={this.onChangeTranscription.bind(this)}
        onChangeTranslation={this.onChangeTranslation.bind(this)}
        pendingLibraryItems={pendingLibraryItems}
        onAddToVideoLibrary={this.onAddToVideoLibrary.bind(this)}
        onRemoveFromVideoLibrary={this.onRemoveFromVideoLibrary.bind(this)}
        onPlayerReady={this.onPlayerReady.bind(this)}
        onPlayerStateChange={event => {
          this.playbackInterval.onPlayerStateChange(event);
        }}
        onToggleTranslation={this.onToggleTranslation}
        onSeek={this.onSeek}
        onAddFavorite={this.onAddFavorite}
        onRemoveFavorite={this.onRemoveFavorite}
        {...this.state}
      />
    );
  }
}

type InnerProps = $Diff<
  React.ElementProps<typeof PlayVideoPageView>,
  {
    blurTranslation: boolean,
    blurTranscription: boolean,
    onChangeBlurTranslation: boolean => void,
    onChangeBlurTranscription: boolean => void,
    onPrevious: () => void,
    onNext: () => void,
    onRepeat: () => void,
    onTogglePlayback: () => void,
    pauseAfterSubtitles: boolean,
    onChangePauseAfterSubtitles: boolean => void
  }
> & {
  move: number => void
};

// TODO: This just exists as a place to mount hooks. Ideally, if we refactator all the PageVideoPage
// functionality into hooks, we can remove this extra component.
function PlayVideoPageInner(props: InnerProps) {
  const popupProps = useTranslationPopup({
    nativeLang: props.nativeLang,
    withIdToken: props.withIdToken
  });

  const [blurTranslation, onChangeBlurTranslation] = React.useState(true);
  const [blurTranscription, onChangeBlurTranscription] = React.useState(false);

  const playerRef = React.useRef<YT$Player | null>(null);

  const [
    pauseAfterSubtitlesString,
    setPauseAfterSubtitlesString
  ] = useLocalStorage("pause-after-playback");
  const pauseAfterSubtitles = pauseAfterSubtitlesString == "true";
  function setPauseAfterSubtitles(value) {
    setPauseAfterSubtitlesString(String(!!value));
  }

  function onPlayerReady(event: YT$Event) {
    playerRef.current = event.target;
    props.onPlayerReady(event);
  }

  const startPauseTimer = usePauseTimeout(playerRef);

  const [playbackCounter, setPlaybackCounter] = React.useState<number>(0);
  function increment() {
    setPlaybackCounter(value => value + 1);
  }

  const [playerState, setPlayerState] = React.useState<number>(-1);

  React.useEffect(() => {
    // 1 = YT.PlayerState.PLAYING
    if (
      playerState === 1 &&
      pauseAfterSubtitles &&
      props.subtitles != null &&
      playerRef.current != null
    ) {
      const player = playerRef.current;
      const subtitle = props.subtitles[props.activeSubtitleIndex];

      // Subtitle can be null in the case of a video with no subtiles
      if (subtitle != null) {
        const currentTime = player.getCurrentTime();
        const playbackRate = player.getPlaybackRate();
        const endTime = subtitle.start + subtitle.duration;

        if (currentTime != null && currentTime < endTime) {
          startPauseTimer(currentTime, endTime, playbackRate);
        }
      }
    }
  }, [
    startPauseTimer,
    props.activeSubtitleIndex,
    props.subtitles,
    playbackCounter,
    playerState,
    pauseAfterSubtitles
  ]);

  const { move } = props;
  const onRepeat = React.useCallback(() => {
    if (playerRef.current) {
      const p = playerRef.current;

      move(0);

      if (p.getPlayerState() !== 1) {
        // YT.PlayerState.PLAYING
        p.playVideo();
      }
      increment();
    }
  }, [move]);

  const onTogglePlayback = React.useCallback(() => {
    if (!playerRef.current) return;
    const p = playerRef.current;
    if (p.getPlayerState() !== YT.PlayerState.PLAYING) {
      p.playVideo();
    } else {
      p.pauseVideo();
    }
  }, []);

  const moveAndPlay = React.useCallback(
    offset => {
      move(offset);

      if (pauseAfterSubtitles && playerRef.current != null) {
        const player = playerRef.current;
        if (player.getPlayerState() !== YT.PlayerState.PLAYING) {
          player.playVideo();
        }
      }
    },
    [move, pauseAfterSubtitles]
  );

  const onNext = React.useCallback(() => {
    moveAndPlay(1);
  }, [moveAndPlay]);

  const onPrevious = React.useCallback(() => {
    moveAndPlay(-1);
  }, [moveAndPlay]);

  // Player keyboard handlers
  React.useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      const action = playerActionForKeyboardEvent(event);
      if (action == null) return;

      event.preventDefault();
      event.stopPropagation();

      switch (action) {
        case "toggle-playback":
          onTogglePlayback();
          break;
        case "repeat":
          onRepeat();
          break;

        case "next":
          onNext();
          break;

        case "previous":
          onPrevious();
          break;
      }
    }

    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onPrevious, onRepeat, onNext, onTogglePlayback]);

  const viewEl = (
    <PlayVideoPageView
      {...props}
      onPlayerStateChange={event => {
        props.onPlayerStateChange(event);
        setPlayerState(event.data);
      }}
      onPlayerReady={onPlayerReady}
      onRepeat={onRepeat}
      onPrevious={onPrevious}
      onNext={onNext}
      onTogglePlayback={onTogglePlayback}
      restricted={props.restricted}
      blurTranscription={blurTranscription}
      blurTranslation={blurTranslation}
      onChangeBlurTranscription={onChangeBlurTranscription}
      onChangeBlurTranslation={onChangeBlurTranslation}
      pauseAfterSubtitles={pauseAfterSubtitles}
      onChangePauseAfterSubtitles={setPauseAfterSubtitles}
    />
  );

  if (popupProps) {
    return (
      <React.Fragment>
        {viewEl}
        <TranslationPopup
          {...popupProps}
          nativeLang={props.nativeLang}
          withIdToken={props.withIdToken}
        />
      </React.Fragment>
    );
  } else {
    return viewEl;
  }
}

function pickTimedTextTrack(
  lang: string,
  list: Array<TimedTextTrack>
): ?TimedTextTrack {
  return (
    list.find(i => matchLangCodeStrict(i.languageCode, lang)) ||
    list.find(i => matchLangCode(i.languageCode, lang))
  );
}

function matchLangCode(langCode: string, targetLang: string): boolean {
  return YouTube.stripLocale(langCode) === YouTube.stripLocale(targetLang);
}

function matchLangCodeStrict(langCode: string, targetLang: string): boolean {
  return langCode === targetLang;
}

function stripRegion(lang: string): string {
  return lang.split("-")[0];
}

function pickTranscriptionLang(
  list: Array<TimedTextTrack>,
  targetLang: ?string,
  nativeLang: string
): string {
  let match;

  // If there are subs for the user's selected targetLanguage, use those
  if (targetLang) {
    const stripped = stripRegion(targetLang);
    match = list.find(i => stripRegion(i.languageCode) === stripped);
    if (match) {
      return match.languageCode;
    }
  }

  // Otherwise, use the first subtitles that ARE NOT the user's native language
  // and hope it's right.
  match = list.find(
    i => stripRegion(i.languageCode) !== stripRegion(nativeLang)
  );
  if (match) {
    return match.languageCode;
  }

  // Otherwise, go with Japanese, because I get a lot of Japanese traffic.
  return "ja";
}
