/* @flow */

function isAdmin(userId /*: string */) {
  return (
    userId === "auth0|297" ||
    userId === "auth0|277" ||
    userId === "google-oauth2|106104130748896893096"
  );
}

module.exports = isAdmin;
