/* @flow */

import * as React from "react";
import type { Observable, Subscription } from "rxjs";

export default class WithObservable<T> extends React.Component<
  {
    observable: Observable<T>,
    renderValue: T => React.Node,
    renderError?: any => React.Node
  },
  { value: ?T, error: any }
> {
  constructor() {
    super();

    this.state = {
      error: null,
      value: null
    };
  }

  subscription: Subscription;

  componentDidMount() {
    this.subscription = this.props.observable.subscribe({
      next: value => this.setState({ value }),
      error: error => this.setState({ error })
    });
  }

  // componentDidUpdate(prevProps: T) {
  //   // TODO: resubscribe if observable changes
  // }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  render() {
    if (this.state.error) {
      if (this.props.renderError) {
        return this.props.renderError(this.state.error);
      } else {
        return null;
      }
    }

    if (this.state.value != null) {
      return this.props.renderValue(this.state.value);
    } else {
      // TODO: Maybe we need a renderInitial?
      return null;
    }
  }
}
