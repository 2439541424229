/* @flow string */

import { sum, every } from "lodash";

function pad(number) {
  if (number < 10) return `0${number}`;
  else return number;
}

export function secondsToString(durationInSeconds: number): string {
  let hours, minutes, seconds;

  if (durationInSeconds > 0) {
    hours = Math.floor(durationInSeconds / (60 * 60));
    minutes = Math.floor(durationInSeconds / 60) % 60;

    // Converting to a string and back to a float will remove trailing zeros
    seconds = parseFloat((durationInSeconds % 60).toFixed(3));
  } else {
    hours = minutes = seconds = 0;
  }

  if (hours > 0) {
    return `${hours}:${pad(minutes)}:${pad(seconds)}`;
  } else {
    return `${minutes}:${pad(seconds)}`;
  }
}

export function stringToSeconds(input: string): number {
  const parts = input.split(":");
  return sum(
    parts.map(
      (part, i) => parseFloat(part) * Math.pow(60, parts.length - i - 1)
    )
  );
}

export function isValidFormatStrict(input: string): boolean {
  const parts = input.split(":");
  if (parts.length > 3) return false;

  return every(parts, (part: string, i: number) => {
    if (i === parts.length - 1) {
      return part.match(/^[\d.]+$/) && part.split(".").length < 3;
    } else {
      return part.match(/^\d+$/);
    }
  });
}

// This is less strict than isValidFormatStrict and allows for transitional
// states that might exist while the user is typing
export function isValidFormat(input: string): boolean {
  const parts = input.split(":");
  if (parts.length > 3) return false;

  return every(parts, (part: string, i: number) => {
    if (i === parts.length - 1) {
      return part.match(/^[\d.]*$/) && part.split(".").length < 3;
    } else {
      return part.match(/^\d*$/);
    }
  });
}
