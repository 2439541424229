/* @flow */

import * as React from "react";
import styled, { css } from "styled-components";

import { playerZIndex } from "./playerGeometry";
import * as geometry from "./geometry";
import { PlayerContainer } from "./PlayerContainer";
import type { PlayerInitParams } from "./WithPlayerController";

type Props = {
  startedAt: ?number,
  isPlaying: boolean,
  children: React.Node,
  initParams: PlayerInitParams,
  headerEl: React.Node
};

export default function FrontBackVideoPlayer(props: Props) {
  const showFront = props.startedAt != null && !props.isPlaying;

  const [playerReady, setPlayerReady] = React.useState(false);

  const initParams = {
    ...props.initParams,
    onPlayerReady: (event: YT$Event) => {
      props.initParams.onPlayerReady(event);
      setPlayerReady(true);
    }
  };

  return (
    <FrontBackPlayerStyles>
      <FrontPanel front={!showFront}>
        <FlashCardPlayerOverlay show={!showFront && playerReady}>
          {props.headerEl}
        </FlashCardPlayerOverlay>

        <PlayerRelativeParent>
          <MyPlayerContainer
            {...initParams}
            blurred={showFront}
            playerVars={{ controls: 0 }}
          />
        </PlayerRelativeParent>
      </FrontPanel>

      <BackPanel front={showFront}>
        {props.headerEl}
        {props.children}
      </BackPanel>
    </FrontBackPlayerStyles>
  );
}

export const flashCardWidth = css`
  width: 100vw;
  max-width: ${geometry.videoWidth}px;
`;

export const flashCardSize = css`
  ${flashCardWidth} min-height: ${geometry.videoHeight}px;

  @media (max-width: ${geometry.videoWidth}px) {
    min-height: calc(100vw * ${geometry.videoHeight / geometry.videoWidth});
  }
`;

// This is the media query that determines when the instructions should be pulled inside of the flash card
// instead of floating above the flashcard in a large header.
export const instructionsInside = `(max-width: 812px)`; // 812 = iPhoneX in landscape mode

const PlayerRelativeParent = styled.div`
  ${flashCardSize} position: relative;
`;

const MyPlayerContainer: typeof PlayerContainer = styled(PlayerContainer)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: ${playerZIndex};

  iframe {
    filter: blur(${props => (props.blurred ? "5px" : "0")});
    transition: filter 200ms;
  }
`;

const Panel = styled.div`
  position: ${props => (props.front ? "relative" : "absolute")};
  top: 0;
  left: 0;
  right: 0;
`;

const FrontBackPlayerStyles = styled.div`
  ${flashCardWidth};
  position: relative;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 2px 2px 5px #888;
`;

const FrontPanel = styled(Panel)`
  z-index: ${playerZIndex};

  // To hide the blur effect from the player.
  overflow: hidden;
  background-color: #eee;
`;

export const backPanelZIndex = playerZIndex + 1;
const BackPanel = styled(Panel)`
  z-index: ${backPanelZIndex};

  ${flashCardSize} display: ${props => (props.front ? "flex" : "none")};
  flex-direction: column;

  background-color: rgba(255, 255, 255, 0.9);
  @media (orientation: portrait) {
    // Remove transparent background in portrait mode, because the player probably isn't tall enough.
    background-color: #eee;
  }
`;

const FlashCardPlayerOverlay = styled.div`
  @media ${instructionsInside} {
    display: ${props => (props.show ? "block" : "none")};
  }
  display: none;

  position: relative;
  z-index: ${playerZIndex + 1};
  background-color: #fff;
  opacity: 0.9;

  @media (orientation: landscape) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`;
