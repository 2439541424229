/* @flow */

// See https://stripe.com/docs/currencies for more currencies
const currencyFormats = {
  usd: {
    symbol: "$",
    zeroDecimal: false
  },
  jpy: {
    symbol: "¥",
    zeroDecimal: true
  },
  eur: {
    symbol: "€",
    zeroDecimal: false
  },
  krw: {
    symbol: "₩",
    zeroDecimal: true
  },
  gbp: {
    symbol: "£",
    zeroDecimal: false
  },
  cny: {
    symbol: "¥",
    zeroDecimal: false
  },
  cad: {
    symbol: "C$",
    zeroDecimal: false
  }
};

function numberPart(amount: number, zeroDecimal: boolean): string {
  if (zeroDecimal) {
    return String(amount);
  } else {
    const float = amount / 100;
    const floor = Math.floor(float);
    if (floor === float) {
      return String(floor);
    } else {
      return (amount / 100).toFixed(2);
    }
  }
}

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
function numberWithCommas(x: string): string {
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// TODO: Investigate if Number.toLocaleString(locale, {style: "currency", currency: "usd"}) is a better option.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
export default function formatCurrency(
  amount: number,
  currency: string
): string {
  const format = currencyFormats[currency.toLowerCase()];
  if (format) {
    return (
      format.symbol + numberWithCommas(numberPart(amount, format.zeroDecimal))
    );
  } else {
    return String(amount);
  }
}
