/* @flow */

import * as React from "react";

import styled from "styled-components";
import { colors } from "./theme";

import type { SnackbarMessage } from "./useSnackbarQueue";

// In practice, this will be more like 150ms, because we use a max-height hack to proxy transition the height.
const transitionMs = 300;
const lineHeight = 35;

const SnackbarBase = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  color: white;
  line-height: ${lineHeight}px;
  text-align: center;
  transition: max-height ${transitionMs}ms ease-out;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

type Props = {
  message: ?SnackbarMessage
};

type State = {
  lastMessage: ?SnackbarMessage
};

export default class Snackbar extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      lastMessage: null
    };
    this.timeoutId = null;
  }

  timeoutId: ?TimeoutID;

  componentDidUpdate(prevProps: Props) {
    if (this.props.message == null && prevProps.message != null) {
      this.setState({ lastMessage: prevProps.message });

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      this.timeoutId = setTimeout(() => {
        this.setState({ lastMessage: null });
        this.timeoutId = null;
      }, transitionMs);
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  render() {
    const message = this.state.lastMessage || this.props.message;

    const style = {
      // Since we don't know the height exactly, we animate max-height instead to a value that is probably greater
      // that height, but not so much greater that it messes up the transition timing.
      maxHeight: this.props.message == null ? 0 : lineHeight * 2,
      backgroundColor:
        message && message.level === "error" ? colors.warning : colors.highlight
    };

    return (
      <SnackbarBase style={style}>{message ? message.body : null}</SnackbarBase>
    );
  }
}
